import { makeRequest } from 'services/api'
import { AuditLogsQueryParams, AuditLogsResponse } from 'views/AdminSettings/AuditLogs/types'

const makeRequestWrapper = (url: string, token: string, queryParams?: AuditLogsQueryParams) => {
    return makeRequest({
        url,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
        params: queryParams,
    })
}

export async function getAuditLogs(
    companyID: string,
    token: string,
    auditLogsQueryParams: AuditLogsQueryParams,
): Promise<AuditLogsResponse> {
    const filteredQueryParams = Object.fromEntries(
        Object.entries(auditLogsQueryParams).filter(
            (param) => typeof param[1] === 'boolean' || param[1],
        ),
    )
    const getAuditLogsUrl = `audit-service/companies/${companyID}/audit-logs`

    const { data } = await makeRequestWrapper(
        getAuditLogsUrl,
        token,
        filteredQueryParams as AuditLogsQueryParams,
    )
    return data
}

export async function getSettingNames(companyID: string, token: string): Promise<string[]> {
    const getSettingNamesUrl = `/audit-service/companies/${companyID}/audit-logs/filters/settings`

    const { data } = await makeRequestWrapper(getSettingNamesUrl, token)
    return data
}

export async function getUsersList(companyID: string, token: string): Promise<string[]> {
    const getSettingNamesUrl = `/audit-service/companies/${companyID}/audit-logs/filters/users`

    const { data } = await makeRequestWrapper(getSettingNamesUrl, token)
    return data
}

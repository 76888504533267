import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IExternalDirectoryRecord } from '../externalDirectory/state'
import { IRestructuredDirectoryContactsState } from './state'

const initialState: IRestructuredDirectoryContactsState = {
    contactsWithKeyAsPhoneNumber: {},
    isProcessing: undefined,
}

export const restructuredDirectoryContactsSlice = createSlice({
    name: 'restructuredDirectoryContacts',
    initialState,
    reducers: {
        setRestructuredContactsByPhoneNumber(
            state,
            action: PayloadAction<Record<string, IExternalDirectoryRecord>>,
        ) {
            return { ...state, contactsWithKeyAsPhoneNumber: action.payload }
        },
        setRestructuredContactsByPhoneNumberProcessing(state, action: PayloadAction<boolean>) {
            return { ...state, isProcessing: action.payload }
        },
    },
})

export const {
    setRestructuredContactsByPhoneNumber,
    setRestructuredContactsByPhoneNumberProcessing,
} = restructuredDirectoryContactsSlice.actions

export type RestructuredDirectoryContactsAction = ReturnType<
    (typeof restructuredDirectoryContactsSlice.actions)[keyof typeof restructuredDirectoryContactsSlice.actions]
>

export default restructuredDirectoryContactsSlice.reducer

import { Box } from '@missionlabs/smartagent-app-components'
import { Loader } from 'components'
import useSmartAgentAPI from 'hooks/useSmartAgentAPI'
import searchIcon from 'images/search.svg'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getChatTranscript } from 'services/api/api.contact'
import { IChatMessage } from 'store/chat/chat.state'
import { CTR } from 'store/contact/contact.state'
import RootState from 'store/state'
import { removeBannerMessages } from 'utils'
import { renderMessage } from 'views/Chat/ChatMessenger/ChatMessages'
import './chat-transcript.scss'

export interface IChatTranscript extends connect.GetTranscriptResult {
    Version: string
    AWSAccountId: string
    InstanceId: string
    ContactId: string
    Participants: {
        ParticipantId: string
    }[]
}
interface Props {
    contact?: CTR
}

export const transformChatMessage = (
    data: connect.ChatTranscriptItem,
    language?: string | undefined,
): IChatMessage => {
    let content
    try {
        const parsed = JSON.parse((data.Content || '{"key":"value"}').replace('\n', ''))

        if (language && parsed[language]) content = parsed[language]
        else if (parsed.en) content = parsed.en
        // "*"" is added here to catch the case where a SILENT_STATE_UPDATE message is sent from connect but the language does not need to be changed and the app won't render this message.
        else if (parsed['*']) content = parsed['*']
        else if (parsed.type) content = parsed
        else content = data.Content
    } catch (error) {
        content = data.Content
    }

    return {
        ...data,
        content,
    }
}

export const ChatHistoryTranscript: React.FC<Props> = ({ contact }) => {
    const [isFirstOpen, setIsFirstOpen] = useState(true)
    const [lookupFailed, setLookupFailed] = useState(false)

    let pollTimeout: NodeJS.Timeout
    const pollStartTimeRef = useRef<null | Date>(null)
    const pollIntervalInSeconds = 5
    const pollTimeLimitInSeconds = 120

    const resolvedContact = useSelector<RootState, CTR>(
        (state) => state?.contact ?? (contact as CTR),
    )

    const [error, loading, response, request, clear] = useSmartAgentAPI<
        IChatTranscript | undefined,
        RootState
    >(
        async ({ app, auth }) =>
            await getChatTranscript(app.ID, app.instance!.ID, auth.token!, resolvedContact.ID),
        false,
    )

    const reset = () => {
        pollStartTimeRef.current = null
        clearTimeout(pollTimeout)
        clear()
    }

    const showFailedLookup = () => {
        reset()
        return setLookupFailed(true)
    }

    const pollEndpoint = () => {
        if (!pollStartTimeRef.current) pollStartTimeRef.current = new Date()
        else {
            const now = new Date()
            const diff = Math.round((now.getTime() - pollStartTimeRef.current.getTime()) / 1000)
            if (diff >= pollTimeLimitInSeconds) return showFailedLookup()
        }

        setTimeout(() => {
            if (resolvedContact) request()
        }, pollIntervalInSeconds * 1000)
    }

    useEffect(() => {
        return () => reset()
    }, [])

    useEffect(() => {
        if (error) {
            if (error.status === 404) {
                pollEndpoint()
            } else showFailedLookup()
        }
        clearTimeout(pollTimeout)
    }, [error])

    const onToggle = (open: boolean) => {
        if (!open && isFirstOpen) {
            setIsFirstOpen(false)
            if (resolvedContact) request()
        }
    }

    const renderFailedLookup = () => (
        <div className="sa-no-chat-history-container">
            <img src={searchIcon} height="22" width="22" alt="No data" title="No data" />
            We were unable to transcribe this conversation.
        </div>
    )

    const renderContent = () => {
        const transcript = response?.data?.Transcript

        if (loading || error || isFirstOpen || !transcript) return <Loader />

        const transcriptWithoutBanners = removeBannerMessages(transcript)

        const contactLanguage = resolvedContact?.attributes['sa-language']

        const transcriptTransformed = transcriptWithoutBanners
            .map((message) => transformChatMessage(message, contactLanguage))
            .map((message) => {
                if (typeof message.content !== 'string' && message.content?.type === 'BATCH') {
                    return message.content.messages.map((batchMessage, i) => ({
                        ...message,
                        content: batchMessage,
                        Id: message.Id + i,
                    }))
                }

                return message
            })
            .flat()

        return transcriptTransformed.map((message) => {
            const translatedFromOriginal = message.ParticipantRole === 'CUSTOMER' ? true : false
            return (
                <div key={message.Id}>
                    {renderMessage(message, { showDisplayName: true, translatedFromOriginal })}
                </div>
            )
        })
    }

    return (
        <Box
            className="sa-chat-history-transcript"
            collapse
            alt
            boxLabel="Chat History Transcript"
            header={<h1>Transcript</h1>}
            hidden
            onToggle={onToggle}
        >
            {lookupFailed ? renderFailedLookup() : renderContent()}
        </Box>
    )
}

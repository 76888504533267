import gql from 'graphql-tag'
import { ListMessagesResponse } from 'graphql/queries/listMessages'
import { Message } from 'graphql/types'

const document = gql`
    subscription onNewMessage($conversationId: ID!) {
        onNewMessage(conversationId: $conversationId) {
            __typename
            id
            content
            timestamp
            conversationId
            user
        }
    }
`

export default document

export interface OnNewMessageResponse {
    onNewMessage: {
        __typename: string
        id: string
        content: string
        timestamp: number
        conversationId: string
        user: string
    }
}

export const subscription = (conversationId: string, cb: (msg: Message) => void) => ({
    document,
    variables: { conversationId },
    updateQuery: (
        prev: ListMessagesResponse,
        { subscriptionData }: { subscriptionData: { data: OnNewMessageResponse } },
    ) => {
        if (!subscriptionData.data) return prev

        const message = subscriptionData.data.onNewMessage
        cb(message)

        if (prev.listMessages.items.some((i) => i.id === message.id))
            return {
                __typename: 'ListMessages',
                listMessages: {
                    ...prev.listMessages,
                    items: prev.listMessages.items.map((i) => (i.id === message.id ? message : i)),
                },
            }
        else
            return {
                __typename: 'ListMessages',
                listMessages: {
                    ...prev.listMessages,
                    items: [message, ...prev.listMessages.items],
                },
            }
    },
})

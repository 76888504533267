// these attributes define what is used in the Contact History tab switcher
export const contactHistoryAttributeKey = 'sa-contactHistorySearchKey'
export const contactHistoryAttributeValue = 'sa-contactHistorySearchValue'
export const contactHistoryAttributeDisplayLabel = 'sa-contactHistoryDisplayLabel'

export const socialChannelTypes = {
    'TWITTER TWEET': 'TWITTER TWEET',
    'TWITTER DM': 'TWITTER DM',
    'FACEBOOK MESSENGER': 'FACEBOOK MESSENGER',
    'FACEBOOK POST': 'FACEBOOK POST',
    'INSTAGRAM IG-DM': 'INSTAGRAM IG-DM',
    'INSTAGRAM IG-COMMENT': 'INSTAGRAM IG-COMMENT',
    'SMS OUTBOUND': 'SMS OUTBOUND',
    'SMS CAMPAIGN-MESSAGE': 'SMS CAMPAIGN-MESSAGE',
    'SMS MARKETING-MESSAGE': 'SMS MARKETING-MESSAGE',
    'SMS CONTACT-FLOW-INITIATED': 'SMS CONTACT-FLOW-INITIATED',
} as const

export const messagingChannelTypes = {
    'WHATSAPP WHATSAPP-DM': 'WHATSAPP WHATSAPP-DM',
    'SMS SMS-DM': 'SMS SMS-DM',
} as const

export const voiceChannelTypes = {
    'VOICE TRANSFER': 'VOICE TRANSFER',
    'VOICE INBOUND': 'VOICE INBOUND',
    'VOICE OUTBOUND': 'VOICE OUTBOUND',
    'VOICE DISCONNECT': 'VOICE DISCONNECT',
    'VOICE MONITOR': 'VOICE MONITOR',
} as const

export const webchatChannelTypes = {
    WEBCHAT: 'WEBCHAT',
} as const

export const taskChannelTypes = {
    'TASK INBOUND': 'TASK INBOUND',
    'TASK OUTBOUND': 'TASK OUTBOUND',
    'TASK CCREPLY': 'TASK CCREPLY',
    TASK: 'TASK',
} as const

export const unknownChannelTypes = {
    UNKNOWN: 'UNKNOWN',
} as const

export const channelTypes = {
    ...socialChannelTypes,
    ...messagingChannelTypes,
    ...voiceChannelTypes,
    ...webchatChannelTypes,
    ...taskChannelTypes,
    ...unknownChannelTypes,
} as const

export const socialChannels = Object.keys(socialChannelTypes)
export const messagingChannels = Object.keys(messagingChannelTypes)
export const voiceChannels = Object.keys(voiceChannelTypes)
export const webchatChannels = Object.keys(webchatChannelTypes)
export const taskChannels = Object.keys(taskChannelTypes)
export const unknownChannels = Object.keys(unknownChannelTypes)

export const channelsByCategory = {
    Social: socialChannels,
    Messaging: messagingChannels,
    Voice: voiceChannels,
    Webchat: webchatChannels,
    Task: taskChannels,
} as const

type ChannelTypeFormat<T> = keyof T
export type ChannelType =
    | SocialChannelType
    | VoiceChannelType
    | WebchatChannelType
    | TaskChannelType
    | MessagingChannelType
    | UnknownChannelType
export type SocialChannelType = ChannelTypeFormat<typeof socialChannelTypes>
export type VoiceChannelType = ChannelTypeFormat<typeof voiceChannelTypes>
export type WebchatChannelType = ChannelTypeFormat<typeof webchatChannelTypes>
export type TaskChannelType = ChannelTypeFormat<typeof taskChannelTypes>
export type MessagingChannelType = ChannelTypeFormat<typeof messagingChannelTypes>
export type UnknownChannelType = ChannelTypeFormat<typeof unknownChannelTypes>
export type ChannelCategory = ChannelTypeFormat<typeof channelsByCategory>

export type ChannelCategoryMap = {
    Social: SocialChannelType
    Voice: VoiceChannelType
    Webchat: WebchatChannelType
    Task: TaskChannelType
    Messaging: MessagingChannelType
}

export const socialChannelFormatMapping: Record<SocialChannelType, string> = {
    'FACEBOOK MESSENGER': 'Facebook DM',
    'FACEBOOK POST': 'Facebook Comments',
    'INSTAGRAM IG-COMMENT': 'Instagram Comments',
    'INSTAGRAM IG-DM': 'Instagram DM',
    'TWITTER TWEET': 'Twitter Tweets',
    'TWITTER DM': 'Twitter DM',
    'SMS OUTBOUND': 'Outbound SMS',
    'SMS CAMPAIGN-MESSAGE': 'SMS Campaign Message',
    'SMS MARKETING-MESSAGE': 'SMS Marketing Message',
    'SMS CONTACT-FLOW-INITIATED': 'SMS Contact Flow Initiated',
}

export const messagingChannelFormatMapping: Record<MessagingChannelType, string> = {
    'WHATSAPP WHATSAPP-DM': 'WhatsApp DM',
    'SMS SMS-DM': 'SMS DM',
}
export const taskChannelFormatMapping: Record<TaskChannelType, string> = {
    TASK: 'Email Channel',
    'TASK INBOUND': 'Inbound Email',
    'TASK OUTBOUND': 'Outbound Email',
    'TASK CCREPLY': 'Reply from Cc',
}

export const voiceChannelFormatMapping: Record<VoiceChannelType, string> = {
    'VOICE DISCONNECT': 'Voice Disconnect',
    'VOICE INBOUND': 'Inbound Call',
    'VOICE MONITOR': 'Monitored Call',
    'VOICE OUTBOUND': 'Outbound Call',
    'VOICE TRANSFER': 'Transferred Call',
}

export const webchatChannelFormatMapping: Record<WebchatChannelType, string> = {
    WEBCHAT: 'Webchat',
}

export const unknownChannelFormatMapping: Record<UnknownChannelType, string> = {
    UNKNOWN: 'Unknown Channel',
}

export const channelFormatMapping = {
    ...socialChannelFormatMapping,
    ...messagingChannelFormatMapping,
    ...voiceChannelFormatMapping,
    ...taskChannelFormatMapping,
    ...webchatChannelFormatMapping,
    ...unknownChannelFormatMapping,
}

import { PureComponent } from 'react'

import './header.scss'

interface Props {
    logoURL: string
    name: string
}

class Header extends PureComponent<Props> {
    render() {
        return (
            <header className="header lg-mar-bottom">
                <img className="header-logo" alt={this.props.name} src={this.props.logoURL} />
            </header>
        )
    }
}

export default Header

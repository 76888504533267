import { Tab, Tabs, TriggeredPopup } from '@missionlabs/smartagent-app-components'
import type { Channel } from '@missionlabs/smartagent-lib-shared/build/form-templates/form-template.schema'
import { DialerControlButton } from 'components'
import useHasFeature, { AppFeatures } from 'hooks/useHasFeature'
import ContactBtn from 'images/directory-icon.svg'
import React from 'react'
import { getBodyWidth } from 'utils'
import Connects from './Connects'
import Directory from './Directory'
import './quick-dialler.scss'
interface Props {
    channel: Channel
    onSelect?: (connect: connect.Endpoint) => void
    quickConnectOpened?: boolean
    onOpen?: () => void
    onClose?: () => void
}
interface IDiallerContext {
    closeQuickDialler?(): void
}
export const QuickDiallerContext = React.createContext<IDiallerContext>({})

const QuickDialler: React.FC<Props> = ({
    channel,
    onSelect,
    onOpen,
    onClose,
    quickConnectOpened,
}) => {
    const hasFeature = useHasFeature()

    return (
        <TriggeredPopup
            onOpen={onOpen}
            onClose={onClose}
            active={quickConnectOpened}
            noShadow
            className="dialler-popup sa-quick-dialler"
            fromCenter={getBodyWidth() > 600}
            trigger={
                <DialerControlButton
                    isFocused={quickConnectOpened}
                    className="md-mar-right"
                    type="button"
                >
                    <img src={ContactBtn} alt="Quick connect" title="Quick Connect" />
                </DialerControlButton>
            }
            render={(closeQuickDialler) => (
                <QuickDiallerContext.Provider value={{ closeQuickDialler }}>
                    <Tabs>
                        <Tab title="Quick Connects">
                            <Connects channel={channel} onSelect={onSelect} />
                        </Tab>
                        {hasFeature(AppFeatures.EXTERNAL_DIRECTORY) ||
                        hasFeature(AppFeatures.MS_TEAMS_DIRECTORY) ? (
                            <Tab title="Directory">
                                <Directory />
                            </Tab>
                        ) : (
                            <></>
                        )}
                    </Tabs>
                </QuickDiallerContext.Provider>
            )}
        />
    )
}
export default QuickDialler

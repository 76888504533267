import { makeRequest } from 'services/api'
import { IGlobalLine, Line, PatchConnect } from 'store/settings/settings.state'

export async function getConnectLines(companyID: string, token: string) {
    const response = await makeRequest<Line[]>({
        url: `/line-service/companies/${companyID}/lines`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response.data
}

export async function getConnectGlobalLine(companyID: string, token: string) {
    const response = await makeRequest<IGlobalLine>({
        url: `/line-service/companies/${companyID}/lines/global-line`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })
    return response.data
}

export async function updateConnectLines(
    companyID: string,
    token: string,
    lineID: string,
    data: PatchConnect,
) {
    try {
        const response = await makeRequest({
            url: `/line-service/companies/${companyID}/lines/${lineID}`,
            method: 'put',
            data,
            headers: {
                'X-Amz-Security-Token': token,
            },
        })
        return response.data
    } catch (err) {
        console.log('error updating connect lines', err)
    }
}

export async function updateConnectGlobalLine(
    companyID: string,
    token: string,
    data: PatchConnect,
) {
    try {
        const response = await makeRequest({
            url: `/line-service/companies/${companyID}/lines/${companyID}-global`,
            method: 'put',
            data,
            headers: {
                'X-Amz-Security-Token': token,
            },
        })
        return response.data
    } catch (err) {
        console.log('error updating connect global line', err)
    }
}

import type { Middleware } from '@reduxjs/toolkit'
import { initAppSuccess } from 'store/app/app.reducer'
import RootState from 'store/state'
import { authenticateUser, authError } from 'store/user/user.actions'
import { login } from 'store/user/user.reducer'

//@ts-ignore
import OktaAuth from '@okta/okta-auth-js'

import { isAnyAction } from 'utils'

interface Okta {
    domain: string
    clientId: string
}

let okta: any

function isOkta(identityManagement?: string) {
    return identityManagement === 'okta'
}

const oktaMiddleware: Middleware<{}, RootState> = (store) => (next) => async (action) => {
    if (!isAnyAction(action)) return

    switch (action.type) {
        case initAppSuccess.type:
            if (isOkta(action.payload.identityManagement)) {
                const oktaConfig = action.payload.okta!
                okta = new OktaAuth({
                    issuer: oktaConfig.domain,
                    clientId: oktaConfig.clientId,
                })
            }
            return next(action)
        case login.type:
            if (!isOkta(store.getState().app.identityManagement)) {
                return next(action)
            }
            next(action)
            if (!okta) {
                console.log('no okta')
                return
            }
            try {
                const token = await okta.token.parseFromUrl()
                if (token) {
                    console.log('got token', token)
                    return store.dispatch(authenticateUser(token.idToken, true) as any)
                }
            } catch (ex) {
                console.log('no token in url', ex)
            }
            if (okta.session.exists()) {
                const session = await okta.session.get()
                try {
                    const token = await okta.getWithoutPrompt({
                        responseType: 'id_token',
                        sessionToken: session?.sessionToken,
                    })
                    return store.dispatch(authenticateUser(token.idToken, true) as any)
                } catch (ex) {
                    console.log('not logged in so show popup')
                }
            }

            try {
                const token = await okta.token.getWithPopup({
                    responseType: 'id_token',
                })
                return store.dispatch(authenticateUser(token.idToken, true) as any)
            } catch (ex) {
                if (ex.errorCode === 'user_cancelled') {
                    return store.dispatch(authError('User cancelled login'))
                }
                console.log('error from okta', ex)
                return store.dispatch(
                    authError(
                        ex.message ||
                            'There is a problem with the okta directory setup, please contact your administrator',
                    ),
                )
            }

        default:
            return next(action)
    }
}

export default oktaMiddleware

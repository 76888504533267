import { ReactComponent as Arrow } from 'images/icon-arrow-green-8x14.svg'
import styles from './QuickReplyItem.module.scss'

interface Props {
    text: string
    onClick: () => void
    isCategory: boolean
}

const QuickReplyItem = ({ text, isCategory, onClick }: Props) => (
    <button className={styles.wrapper} onClick={onClick}>
        <p>{text}</p>

        {isCategory && <Arrow className={styles.arrow} />}
    </button>
)

export default QuickReplyItem

import Rollbar from 'rollbar'

let rollbar: Rollbar

interface User {
    id: string | undefined
    username: string
}

export default {
    init: () => {
        if (!rollbar) {
            rollbar = new Rollbar({
                accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
                captureUncaught: true,
                captureUnhandledRejections: true,
                enabled: true,
                payload: {
                    environment: process.env.REACT_APP_ENV,
                    code_version: process.env.BITBUCKET_COMMIT || 'local',
                },
            })
        }
    },

    addCompanyId: (companyId: string) => {
        rollbar.configure({ payload: { companyId } })
    },

    addUser: ({ id, username }: User) => {
        rollbar.configure({
            payload: {
                person: {
                    id: id ?? null,
                    username,
                },
            },
        })
    },

    info: (key: string, data?: object) => {
        if (!rollbar) {
            throw new Error('Logger must be initialised first')
        }
        data ? rollbar.info(key, data) : rollbar.info(key)
    },
    warning: (key: string, data?: object) => {
        if (!rollbar) {
            throw new Error('Logger must be initialised first')
        }
        data ? rollbar.warning(key, data) : rollbar.warning(key)
    },
    error: (key: string, data?: object) => {
        if (!rollbar) {
            throw new Error('Logger must be initialised first')
        }
        data ? rollbar.error(key, data) : rollbar.error(key)
    },
}

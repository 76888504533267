import './agents.scss'

import { UserConversation } from 'graphql/types'
import { AppFeatures } from 'hooks/useHasFeature'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { BeatLoader } from 'react-spinners'
import { selectConversation } from 'store/internal-messaging/internal-messaging.actions'
import RootState from 'store/state'

import { ViewEvent } from '@missionlabs/analytics-react'
import { Button, Input } from '@missionlabs/smartagent-app-components'

import ChatList from '../../components/InternalMessaging/ChatList'
import ChatWindow from '../../components/InternalMessaging/ChatView'
import { getAgents, showAgents } from '../../store/agents/agents.actions'

interface Props extends ReturnType<typeof mapStateToProps> {
    getAgents: () => void
    showAgents: () => void
    agents: any[]
    userConversations: UserConversation[]
    selectedConversationId?: string
}

const Conversations: React.FC<Props> = ({
    agentsError,
    agentsLoading,
    userConversations,
    selectedConversationId,
    getAgents,
}) => {
    const [search, setSearch] = useState('')

    const dispatch = useDispatch()

    const input: React.RefObject<HTMLInputElement | HTMLTextAreaElement | null> | null | undefined =
        React.createRef()

    useEffect(() => {
        dispatch(selectConversation(selectedConversationId))
        // eslint-disable-next-line
    }, [selectedConversationId])

    const backScreen = () => {
        dispatch(selectConversation(undefined))
    }

    const getFilteredResults = () => {
        return userConversations?.filter((item) => {
            return item.conversation?.lastMessageUser?.toLowerCase().includes(search.toLowerCase())
        })
    }

    const conversationName =
        userConversations?.find((uc) => uc.conversationId === selectedConversationId)?.name ?? ''
    const coversationUnread =
        userConversations?.find((uc) => uc.conversationId === selectedConversationId)?.unread ?? 0

    return (
        <ViewEvent
            type="interface"
            eventData={{
                feature: AppFeatures.INTERNAL_MESSAGING,
                label: 'View list of chats',
            }}
        >
            <aside title="Agent Messages" className="agents">
                {selectedConversationId ? (
                    <ChatWindow
                        conversationId={selectedConversationId}
                        conversationName={conversationName}
                        conversationUnread={coversationUnread}
                        onBack={backScreen}
                    />
                ) : (
                    <div>
                        <div className="agents-filterdiv">
                            <div className="agents-filters">
                                <Input
                                    ref={input}
                                    name="Message search"
                                    placeholder="Search messages..."
                                    onChange={(search: any) => setSearch(search)}
                                    value={search}
                                    className="agents-search-input"
                                />
                            </div>
                        </div>

                        {agentsError ? (
                            <div className="agents-error">
                                There was an error <br />
                                <Button elastic onClick={() => getAgents()}>
                                    <u>Try again</u>
                                </Button>
                            </div>
                        ) : agentsLoading && !userConversations.length ? (
                            <div className="agents-loading">
                                <BeatLoader color="#212121" />
                            </div>
                        ) : !userConversations.length ? (
                            <div className="no-convos-found">
                                <span>No conversations have been found</span>
                            </div>
                        ) : (
                            <div className="message-table">
                                <ChatList
                                    userConversations={
                                        search ? getFilteredResults() : userConversations
                                    }
                                />
                            </div>
                        )}
                    </div>
                )}
            </aside>
        </ViewEvent>
    )
}

function mapStateToProps(state: RootState) {
    return {
        ...state.agents,
    }
}

export default connect(mapStateToProps, { getAgents, showAgents })(Conversations)

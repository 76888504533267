import WebsocketWrapper, {
    IWebSocketParameters,
} from '@missionlabs/smartagent-lib-shared/build/clients/websocket/websocket'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import WebsocketState from './websocket.state'
import { addBaseListeners } from './websocket.utils'

type State = WebsocketState | null
const initialState = null as State

export const websocketSlice = createSlice({
    name: 'websocket',
    initialState,
    reducers: {
        createWebsocketConnection(_state, action: PayloadAction<IWebSocketParameters>) {
            let websocket: State = null
            try {
                websocket = new WebsocketWrapper(action.payload)
                addBaseListeners(websocket)
            } catch (error) {
                console.error(error)
            }
            return websocket
        },
    },
})

export const { createWebsocketConnection } = websocketSlice.actions

export type WebsocketAction = ReturnType<
    (typeof websocketSlice.actions)[keyof typeof websocketSlice.actions]
>

export default websocketSlice.reducer

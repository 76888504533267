import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    deleteCustomerData,
    fetchContacts,
    setChannel,
    setDeleteStatus,
    setEmail,
} from './dataRetention.actions'
import {
    DataRetentionChannel,
    DataRetentionChannelDropdown,
    DataRetentionColumnGroup,
    DataRetentionDeleteStatus,
    DataRetentionState,
} from './dataRetention.state'

const initialState: DataRetentionState = {
    loading: false,
    deleting: false,
    deleteStatus: DataRetentionDeleteStatus.IDLE,
    searchFailed: false,
    results: null,
    formParams: {
        email: '',
        channel: {
            label: 'Email/Webform',
            data: DataRetentionChannel.EMAIL_WEBFORM,
            group: ['EMAIL', 'WEBFORM'] as DataRetentionColumnGroup[],
        },
    },
}

const dataRetentionSlice = createSlice({
    name: 'dataRetention',
    initialState,
    reducers: {
        resetStatus(state) {
            state.deleteStatus = DataRetentionDeleteStatus.IDLE
            state.searchFailed = false
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(setEmail, (state, action: PayloadAction<string>) => {
                state.formParams.email = action.payload
            })
            .addCase(setChannel, (state, action: PayloadAction<DataRetentionChannelDropdown>) => {
                state.formParams.channel = action.payload
            })
            .addCase(setDeleteStatus, (state, action: PayloadAction<DataRetentionDeleteStatus>) => {
                state.deleteStatus = action.payload
            })
            .addCase(fetchContacts.pending, (state) => {
                state.loading = true
            })
            .addCase(fetchContacts.fulfilled, (state, action) => {
                state.loading = false
                state.results = action.payload!
            })
            .addCase(fetchContacts.rejected, (state, { payload }) => {
                console.log('Failed to fetch contacts: ', payload)
                state.loading = false
                state.results = []
                state.searchFailed = true
            })
            .addCase(deleteCustomerData.pending, (state) => {
                state.deleting = true
                state.deleteStatus = DataRetentionDeleteStatus.IDLE
            })
            .addCase(deleteCustomerData.fulfilled, (state) => {
                state.deleting = false
                state.deleteStatus = DataRetentionDeleteStatus.SUCCESS
            })
            .addCase(deleteCustomerData.rejected, (state, { payload }) => {
                console.log('Failed to delete customer data: ', payload)
                state.deleteStatus = DataRetentionDeleteStatus.FAILURE
                state.deleting = false
            })
    },
})

export type DataRetentionAction = ReturnType<
    (typeof dataRetentionSlice.actions)[keyof typeof dataRetentionSlice.actions]
>

export default dataRetentionSlice.reducer

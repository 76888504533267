import classnames from 'classnames'
import React from 'react'
import { BeatLoader, ClipLoader } from 'react-spinners'
import styles from './loader.module.scss'

interface Props {
    color?: string
    disableBackground?: boolean
    className?: string
    fullHeight?: boolean
    backgroundClassName?: string
    loaderType?: 'clip' | 'beat'
    size?: number
}

const Loader: React.FC<Props> = ({
    color,
    disableBackground = false,
    className,
    fullHeight = true,
    backgroundClassName,
    loaderType,
    size,
}) => {
    const loaderClassNames = classnames('md-pad row middle center', styles['sa-loader'], className)
    const frontLoaderClassNames = classnames(
        {
            [styles['wrapper']]: true,
            [styles['wrapper--fullHeight']]: fullHeight,
        },
        className,
    )

    const renderLoader = () => (
        <div data-testid="sa-loader" className={loaderClassNames}>
            {loaderType === 'clip' ? (
                <ClipLoader color={color} size={size} />
            ) : (
                <BeatLoader color={color} size={size} />
            )}
        </div>
    )

    const renderLoaderWithBackground = () => (
        <div data-testid="sa-loader" className={frontLoaderClassNames}>
            <div className={styles['inner-wrapper']}>
                <div className={classnames(styles['background'], backgroundClassName)} />

                <div className={styles['loader-wrapper']}>
                    {loaderType === 'clip' ? (
                        <ClipLoader color={color} size={size} />
                    ) : (
                        <BeatLoader color={color} size={size} />
                    )}
                </div>
            </div>
        </div>
    )

    const render = () => (!disableBackground ? renderLoader() : renderLoaderWithBackground())

    return <>{render()}</>
}

export default Loader

import { combineReducers } from '@reduxjs/toolkit'

import chatSettingsReducer, { ChatSettingsAction } from './chat/chat.reducer'
import emailSettingsReducer, { EmailSettingsAction } from './email/email.reducer'
import messagingSettingsReducer, { MessagingSettingsAction } from './messaging/messaging.reducer'
import socialSettingsReducer, { SocialSettingsAction } from './social/social.reducer'
import type { ChannelSettingsReducers } from './state'
import webFormSettingsReducer, { WebFormSettingsAction } from './webform/webform.reducer'

export type ChannelSettingsAction =
    | EmailSettingsAction
    | ChatSettingsAction
    | SocialSettingsAction
    | MessagingSettingsAction
    | WebFormSettingsAction

const channelSettingsReducer = combineReducers<ChannelSettingsReducers>({
    chat: chatSettingsReducer,
    email: emailSettingsReducer,
    messaging: messagingSettingsReducer,
    social: socialSettingsReducer,
    webform: webFormSettingsReducer,
})

export default channelSettingsReducer

import useHasFeature, { AppFeatures } from 'hooks/useHasFeature'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getExternalDirectoryContacts } from 'store/directory/externalDirectory/actions'

const loadExternalDirectoryContacts = () => {
    const dispatch = useDispatch()
    const hasFeature = useHasFeature()

    useEffect(() => {
        if (hasFeature(AppFeatures.EXTERNAL_DIRECTORY)) {
            dispatch(getExternalDirectoryContacts())
        }
    }, [])
}

export default loadExternalDirectoryContacts

import { Attribute, ContactFlowSummary, ContactFlowType } from '@aws-sdk/client-connect'

import { makeRequest } from 'services/api'

import { paramsToQueryString } from 'utils'

export async function getIsEnhancedMonitoringEnabled(
    companyID: string,
    instanceID: string,
): Promise<Attribute> {
    const response = await makeRequest<Attribute>({
        url: `sa-connect-api/companies/${companyID}/instances/${instanceID}/check-enhanced-monitoring`,
    })

    return response.data
}

export async function getContactFlows(contactFlowType?: ContactFlowType) {
    const params = paramsToQueryString({ contactFlowType })
    const response = await makeRequest<ContactFlowSummary[]>({
        url: `/sa-connect-api/companies/{companyID}/instances/{instanceID}/contact-flows?${params}`,
        method: 'get',
    })
    return response.data
}

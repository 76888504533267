import React, { useCallback, useState } from 'react'

interface Props {
    to: number
    onSeek(e: React.MouseEvent<HTMLElement>, seek?: boolean): number | undefined
}

export const Seeker: React.FC<Props> = ({ to, onSeek }) => {
    const [tempLeft, setTempLeft] = useState<number | undefined>(undefined)

    const getSeekPosition = useCallback((to: number) => ({ left: to + '%' }), [])

    const onDrag = (e: React.DragEvent<HTMLDivElement>) => {
        const to = onSeek(e, false)
        setTempLeft(to)
    }

    const onDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
        onSeek(e)
        setTempLeft(undefined)
    }

    return (
        <div onDrag={onDrag} onDragEnd={onDragEnd} className="seeker-wrapper">
            <div draggable="true" style={getSeekPosition(tempLeft ?? to)} className="seeker" />
        </div>
    )
}

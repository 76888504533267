import { Button, TriggeredPopup } from '@missionlabs/smartagent-app-components'
import useDirectoryOwners from 'hooks/redux/useDirectoryOwners'
import React, { useMemo } from 'react'
import { ClipLoader } from 'react-spinners'

import './directory-popups.scss'

interface Props {
    className?: string
    triggerClassName?: string
    onSelect(owner?: string): void
    currentOwnerId?: string
}

const DirectoryOwnerPopup: React.FC<Props> = ({
    className,
    triggerClassName,
    currentOwnerId,
    onSelect,
}) => {
    const [owners, loading] = useDirectoryOwners()

    const currentOwner = useMemo(
        () => owners?.find((owner) => owner.id === currentOwnerId),
        [owners, currentOwnerId],
    )

    return (
        <TriggeredPopup
            className={className ?? 'sa-directory-popup'}
            closeOnClickOutside
            closeOnClick
            stopPropagation
            trigger={
                <Button
                    small
                    elastic
                    disabled={loading}
                    className={triggerClassName ?? 'sa-directory-popup-button'}
                    styling="secondary"
                >
                    <div className="sa-directory-popup-button__content">
                        <span>Owner: </span>
                        <span>
                            {loading ? <ClipLoader size={12} /> : currentOwner?.value ?? 'All'}
                        </span>
                    </div>
                </Button>
            }
        >
            <div className="row column">
                {owners?.map((owner) => (
                    <Button key={owner.id} onClick={() => onSelect(owner.id)}>
                        {owner.value}
                    </Button>
                ))}

                <Button onClick={() => onSelect()}>All</Button>
            </div>
        </TriggeredPopup>
    )
}

export default DirectoryOwnerPopup

import { DataProxy, FetchResult, MutationFunctionOptions } from '@apollo/client'
import gql from 'graphql-tag'
import LIST_USER_CONVERSATIONS_, {
    ListUserConversations,
} from 'graphql/queries/listUserConversations'
import { TypeName, UserConversation } from 'graphql/types'

export default gql`
    mutation createUserConversation($user: ID!, $userConversation: userConversationInput!) {
        createUserConversation(user: $user, userConversation: $userConversation) {
            __typename
            user
            conversationId
            unread
            name
            conversation {
                __typename
                id
                lastMessageContent
                lastMessageTimestamp
                lastMessageUser
                recipientList
            }
        }
    }
`

export interface CreateUserConversationResponse {
    __typename: string
    createUserConversation: UserConversation & TypeName
}

interface UserConversationInput {
    user: any
    conversationId: string
    unread: number
    name: string
}

export const mutation = (
    user: string,
    userConversation: UserConversationInput,
    recipientList: string[],
    username: string,
    conversation: { id: string; __typename: string },
): MutationFunctionOptions<CreateUserConversationResponse, Record<string, any>> => ({
    variables: {
        user,
        userConversation,
    },
    optimisticResponse: {
        __typename: 'Mutation',
        createUserConversation: {
            __typename: 'UserConversation',
            ...userConversation,
            conversation: {
                ...conversation,
                recipientList,
            },
        },
    },
    update: (
        proxy: DataProxy,
        result: FetchResult<
            CreateUserConversationResponse,
            Record<string, any>,
            Record<string, any>
        >,
    ) => {
        if (!result.data || result.data.createUserConversation.user !== username) return
        // Read the data from our cache for this query.
        let data = proxy.readQuery<ListUserConversations>({
            query: LIST_USER_CONVERSATIONS_,
        })
        if (!data)
            data = {
                listUserConversations: {
                    items: [result.data.createUserConversation],
                },
            }

        if (
            data.listUserConversations.items.some(
                (uc) =>
                    uc.user === result.data?.createUserConversation.user &&
                    uc.conversationId === result.data?.createUserConversation.conversationId,
            )
        ) {
            data.listUserConversations.items = data.listUserConversations.items.map((uc) => {
                if (
                    uc.user === result.data?.createUserConversation.user &&
                    uc.conversationId === result.data?.createUserConversation.conversationId
                )
                    return result.data?.createUserConversation
                else return uc
            })
        } else {
            data.listUserConversations.items = [
                ...data.listUserConversations.items,
                result.data.createUserConversation,
            ]
        }

        // Write our data back to the cache.
        proxy.writeQuery({
            query: LIST_USER_CONVERSATIONS_,
            data,
        })
    },
})

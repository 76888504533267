import { jsx } from "react/jsx-runtime";
import classNames from "classnames";
const Tag = ({ type = "inherit", small = false, square = false, text, title, children }) => {
  return /* @__PURE__ */ jsx(
    "div",
    {
      title,
      className: classNames({
        tag: true,
        [type]: true,
        square,
        small
      }),
      children: text ?? children
    }
  );
};
const Tag$1 = Tag;
export {
  Tag$1 as default
};

export const voiceChannels = ['VOICE']

export const chatChannels = ['CHAT']

export const taskChannels = ['TASK', 'EMAIL']

export const socialChannels = [
    'SOCIAL',
    'Twitter Tweets',
    'Twitter DM',
    'Facebook DM',
    'Facebook Comments',
    'Instagram DM',
    'Instagram Comments',
]

export const messagingChannels = ['WhatsApp DM', 'SMS DM', 'CAMPAIGN-MESSAGE']

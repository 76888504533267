import React from 'react'

import FrequentContact from './FrequentContact'
import SortBy from './SortBy'
import TabsContainer from './TabsContainer'

import './header.scss'

const Header: React.FC<{}> = () => {
    return (
        <div className="contact-history-panel-header" data-testid="contact-history-header">
            <div>
                <SortBy />
                <FrequentContact />
            </div>
            <TabsContainer />
        </div>
    )
}

export default Header

import * as React from 'react'
import './index.scss'

interface Props {
    number: number
}

const Badge: React.FC<Props> = (props) => {
    return (
        <div className="message-badge">
            <div className="message-badge-circle">{props.number} </div>
        </div>
    )
}

export default Badge

import { Box, CopyClipboard } from '@missionlabs/smartagent-app-components'
import useContact from 'hooks/redux/useContact'

import copyIcon from 'images/icon-copy-outline-white.svg'

import React from 'react'
import './customer-inputs.scss'

interface Props {}

const CustomerInputs: React.FC<Props> = () => {
    const contact = useContact()

    const customerInputName = contact?.attributes?.['sa-customer-inputs-display-name']

    return (
        contact?.customerInputs && (
            <section className="sa-customer-inputs">
                <Box
                    collapse
                    header={customerInputName ? customerInputName : <h4>Customer Input</h4>}
                >
                    <ul>
                        {contact.customerInputs.map((custInput, i) => (
                            <li className="row between middle" key={i}>
                                <p>
                                    <span>{custInput.label}:&nbsp;</span>
                                    <span>{custInput.value}</span>
                                </p>
                                <CopyClipboard icon={copyIcon} copyStr={custInput.value} />
                            </li>
                        ))}
                    </ul>
                </Box>
            </section>
        )
    )
}

export default CustomerInputs

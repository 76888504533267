import type { Middleware } from '@reduxjs/toolkit'
import {
    openPhonebook,
    selectConversation,
} from 'store/internal-messaging/internal-messaging.actions'
import { newMessageNotification } from 'store/internal-messaging/internal-messaging.reducer'
import RootState from 'store/state'
import { NotificationControl, isAnyAction } from 'utils'

export const internalMessaging: Middleware<{}, RootState> = (store) => (next) => (action) => {
    if (!isAnyAction(action)) return

    switch (action.type) {
        case newMessageNotification.type: {
            const { content, user, conversationId } = action.payload
            const { user: loggedInUser, internalMessaging } = store.getState()

            if (
                loggedInUser?.username === user ||
                (internalMessaging?.selectedConversationId === conversationId &&
                    internalMessaging.phoneBookOpen &&
                    !document.hidden)
            )
                return next(action)

            const notificationLength = 20
            const message =
                content.length > notificationLength
                    ? `${content.substring(0, notificationLength)}...`
                    : content

            new NotificationControl().send(`Message from ${user}`, message, () => {
                store.dispatch(selectConversation(action.payload.conversationId))
                store.dispatch(openPhonebook(true))
            })
            return next(action)
        }
        default:
            return next(action)
    }
}

export default internalMessaging

import type { ThunkDispatch } from '@reduxjs/toolkit'
import { getContact as APIGetContact, postParkContact } from 'services/api/api.contact'
import RootState from 'store/state'

import * as ContactActions from './contact.reducer'
import { ICallContactAttributes } from './contact.state'
import { objectToContactAttributes } from './contact.utils'

export const {
    clearContact,
    customerUpdated: updateCustomer,
    setContact,
    updateAgentDPA: updateAgentDPAResult,
    updateContact,
    updateDPA,
    updateDPAResult,
} = ContactActions

export function parkCallContact(initialContactId?: string) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, ContactActions.ContactAction>,
        getState: () => RootState,
    ) => {
        const state = getState()
        if (!state.contact) {
            throw Error(`Couldn't find contact`)
        }

        try {
            const response = await postParkContact(
                initialContactId || state.contact.ID,
                state.app.ID,
                state.app.instance!.ID,
                state.auth.token!,
            )

            if (response.status === 200) {
                dispatch(ContactActions.parkCallContact(response.data.ParkBay!))
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export function removeContactAttributes(ID: string, attributes: string[]) {
    return ContactActions.removeContactAttributes({
        ID,
        attributes: objectToContactAttributes(attributes),
    })
}

export function updateContactAttributes(
    ID: string,
    attributes: Partial<ICallContactAttributes>,
    commitUpdate?: boolean,
) {
    return ContactActions.updateContactAttributes({
        ID,
        attributes: objectToContactAttributes(attributes),
        commitUpdate,
    })
}

export function getContact(contactID: string) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, ContactActions.ContactAction>,
        getState: () => RootState,
    ) => {
        try {
            const { contact } = getState()
            const response = await APIGetContact(contactID)

            dispatch(ContactActions.getContact(response))

            // Is this contact still selected?
            if (contact?.ID !== response.ID) return

            dispatch(ContactActions.setContact(response))
        } catch (error) {
            console.log(error)
        }
    }
}

export function updateACWAttributes(ID: string, acwAttributes: ICallContactAttributes) {
    return ContactActions.updateACWAttributes({ acwAttributes, ID })
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ClipLoader } from 'react-spinners'

import { IChatMessage, ISocialChatMessage } from 'store/chat/chat.state'
import { fetchContactHistoryInteractionTranscript } from 'store/contactHistory/contactHistory.actions'
import { webchatChannels } from 'store/contactHistory/contactHistory.constants'
import { selectContactHistoryInstance } from 'store/contactHistory/contactHistory.selectors'
import ChatMessages from 'views/Chat/ChatMessenger/ChatMessages'
import SocialChatMessages from 'views/Chat/ChatMessenger/SocialChatMessages'
import { IInteraction } from 'views/Metrics/HistoricMetrics/types'
import { ContactDetails } from 'widgets/ContactDetails'

import { isChannelOfCategory } from 'store/contactHistory/contactHistory.utils'
import './chat-widgets.scss'

interface Props {
    interaction: IInteraction
}

const ChatWidgets: React.FC<Props> = ({ interaction }) => {
    const dispatch = useDispatch()
    const { attributeSearchName, attributeSearchValue } =
        useSelector(selectContactHistoryInstance) ?? {}

    const transcript = interaction?.transcript?.map((transcriptItem) => ({
        ...transcriptItem,
        contactID: interaction.ID,
    }))

    useEffect(() => {
        if (interaction && !transcript && attributeSearchName && attributeSearchValue) {
            dispatch(
                fetchContactHistoryInteractionTranscript(
                    interaction,
                    attributeSearchName,
                    attributeSearchValue,
                ),
            )
        }
    }, [interaction, transcript])

    return (
        <div data-testid="chat-widgets">
            {!transcript ? (
                <div
                    data-testid="chat-widgets-transcript-loading"
                    className="contact-history-chat-widgets-transcript-loading"
                >
                    <ClipLoader />
                </div>
            ) : (
                <>
                    {transcript.length === 0 ? (
                        <div className="contact-history-chat-widgets-transcript-empty">
                            <p className="contact-history-chat-widgets-transcript-empty-message">
                                No transcript found
                            </p>
                        </div>
                    ) : (
                        <div
                            className="contact-history-chat-widgets-transcript"
                            data-testid="chat-widget-transcript"
                        >
                            {(isChannelOfCategory(interaction.channelType, 'Social') ||
                                isChannelOfCategory(interaction.channelType, 'Messaging')) && (
                                <SocialChatMessages
                                    chatMessagesProp={transcript as ISocialChatMessage[]}
                                />
                            )}
                            {webchatChannels.includes(interaction.channelType) && (
                                <ChatMessages messages={transcript as IChatMessage[]} />
                            )}
                        </div>
                    )}
                </>
            )}
            <div className="contact-history-chat-widgets">
                <ContactDetails contact={interaction} widgetTitle="Additional info" />
            </div>
        </div>
    )
}

export default ChatWidgets

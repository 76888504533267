import { DataProxy } from '@apollo/client'
import gql from 'graphql-tag'
import LIST_USER_CONVERSATIONS_, {
    ListUserConversations,
} from 'graphql/queries/listUserConversations'
import { TypeName } from '../types'

export default gql`
    mutation readUserConversation($user: ID!, $conversationId: ID!) {
        readUserConversation(user: $user, conversationId: $conversationId) {
            __typename
            user
            conversationId
            unread
            name
            conversation {
                __typename
                id
                lastMessageContent
                lastMessageTimestamp
                lastMessageUser
                recipientList
            }
        }
    }
`

export interface ReadUserConversationResponse {
    __typename: string
    readUserConversation: {
        __typename: string
        user: string
        conversationId: string
        unread: number
        name: string
    } & TypeName
}

export const mututation = (username: string, conversationId: string) => ({
    variables: {
        user: username,
        conversationId,
    },
    // Disabled since the update fails due to the mandatory "name" field being missing
    // optimisticResponse: {
    //   __typename: 'Mutation',
    //   readUserConversation: {
    //       __typename: 'UserConversation',
    //       user: username,
    //       conversationId,
    //       unread: 0
    //   },
    // },
    update: (proxy: DataProxy) => {
        const cacheData = proxy.readQuery<ListUserConversations>({
            query: LIST_USER_CONVERSATIONS_,
        })
        if (!cacheData) return

        proxy.writeQuery({
            query: LIST_USER_CONVERSATIONS_,
            data: {
                ...cacheData,
                listUserConversations: cacheData && {
                    ...cacheData.listUserConversations,
                    items: cacheData.listUserConversations.items.map((uc) => {
                        if (uc.conversationId === conversationId && uc.user === username)
                            return {
                                ...uc,
                                unread: 0,
                            }
                        else return uc
                    }),
                },
            },
        })
    },
})

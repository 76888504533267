import { AlertBanner } from '@missionlabs/smartagent-app-components'
import type { Channel } from '@missionlabs/smartagent-lib-shared/build/form-templates/form-template.schema'
import useContact from 'hooks/redux/useContact'

interface Props {
    channel: Channel
}

const StaticReminder = ({ channel }: Props) => {
    const contact = useContact()

    if (channel !== contact?.channel) return null

    const reminder = contact?.attributes['sa-reminder']

    return reminder ? <AlertBanner>{reminder}</AlertBanner> : null
}

export default StaticReminder

import { Button } from '@missionlabs/smartagent-app-components'
import type { Attachment, SocialAttachment } from '@missionlabs/smartagent-service-thread'
import logger from 'logger'
import prettyBytes from 'pretty-bytes'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ClipLoader } from 'react-spinners'
import { getS3AttachmentUrl } from 'services/api/api.chat'
import { getDownloadURL } from 'services/api/api.thread'
import RootState from 'store/state'

export interface Props {
    messageID?: string
    threadID?: string
    attachment: Attachment | SocialAttachment
    isSocialAttachment?: boolean
}

const DownloadAttachment: React.FC<Props> = ({
    threadID,
    messageID,
    attachment,
    isSocialAttachment,
}) => {
    const [downloadUrl, setDownloadUrl] = useState<string | undefined>(undefined)
    const [loading, setLoading] = useState<boolean>(false)
    const companyID = useSelector<RootState>((state) => state.app.ID) as string
    const isAttachmentVirus = (attachment as Attachment).virusScanResult === 'FAIL'

    const onDownload = async (attachment: Attachment | SocialAttachment) => {
        const link = document.createElement('a')
        link.target = '_blank'
        if (isSocialAttachment) {
            link.href = (attachment as SocialAttachment).url
            link.click()
        } else {
            try {
                setLoading(true)
                const s3Key = (attachment as Attachment).s3Key!
                const url =
                    downloadUrl ||
                    (threadID
                        ? (await getDownloadURL(threadID!, messageID!, encodeURI(s3Key))).url
                        : await getS3AttachmentUrl(encodeURIComponent(s3Key), companyID))
                setDownloadUrl(url)
                setLoading(false)
                link.href = url
                link.click()
            } catch (ex) {
                logger.error('DOWNLOAD-ATTACHMENT', ex)
            } finally {
                setLoading(false)
            }
        }
    }

    const getType = (contentType?: string) => {
        if (!contentType) return
        const parts = contentType.split('/')
        return parts[1] ? parts[1].toUpperCase() : contentType
    }

    return (
        <Button
            onClick={() => onDownload(attachment)}
            styling="blank"
            title="Download"
            className="sa-thread-attachment"
            disabled={isAttachmentVirus}
        >
            <div className="row column">
                {loading ? (
                    <ClipLoader color="#fff" />
                ) : (
                    <>
                        <span className="sa-thread-attachment__filename">
                            {attachment.filename}
                        </span>
                        <span className="sa-thread-attachment__details">
                            {isAttachmentVirus ? (
                                <p className="sa-thread-attachment__details--virus">
                                    Virus detected - File removed
                                </p>
                            ) : (
                                `${getType(attachment.contentType)} • ${prettyBytes(
                                    attachment.size,
                                )}`
                            )}
                        </span>
                    </>
                )}
            </div>
        </Button>
    )
}

export default DownloadAttachment

import useHasFeature, { AppFeatures } from 'hooks/useHasFeature'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getTeamsContactsStatuses } from 'store/directory/teams/actions'

const loadTeamsContactsStatuses = () => {
    const dispatch = useDispatch()
    const hasFeature = useHasFeature()

    useEffect(() => {
        if (hasFeature(AppFeatures.MS_TEAMS_DIRECTORY)) {
            dispatch(getTeamsContactsStatuses())
        }
    }, [])
}

export default loadTeamsContactsStatuses

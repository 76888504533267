import { formatDateTime } from '@missionlabs/smartagent-app-components'
import { SAMessageType } from '@missionlabs/smartagent-chat-components-lib/dist/ChatMessage/chat-message.types'
import useChatConnections from 'hooks/redux/useChatConnections'
import useUser from 'hooks/redux/useUser'

export default function useCopyChatTranscript() {
    const chat = useChatConnections('selected')
    const agent = useUser()

    const getMessageText = (message: SAMessageType) => {
        let usefulString: string | null = null

        switch (message.type) {
            case 'CARD':
                {
                    let cardsText = ''
                    const buttons = message.cards[0].buttons
                    const title = message.cards[0]?.title
                    if (title) cardsText = title

                    if (buttons) {
                        buttons.map((button) => {
                            if (button.title) cardsText += `\n${button.title}`
                        })
                    }

                    usefulString = cardsText
                }
                break
            case 'TEXT_QUICKREPLIES':
                {
                    if (message.text) usefulString = message?.text
                }
                break
            default:
                // don't try to get text from the other message types like file upload etc
                usefulString = null
        }
        return usefulString
    }

    const copyChatToClipboard = async () => {
        if (!chat) return
        let chatTranscriptString = `Chat Transcript \n \nChat id: ${chat.id} \nAgent name: ${agent?.name} \nCustomer name: ${chat.customerName} \nStarted at: ${formatDateTime(chat.started, { date: true, time: true, seconds: true })} \n`

        chat.messages.map((message) => {
            const { ParticipantRole, AbsoluteTime, content, ContentType } = message
            if (!content || ContentType !== 'text/plain') return

            let messageBody: string | null = null

            if (typeof content === 'string') messageBody = content

            // if the content field is not a string the useful text will be elsewhere on the object
            if (typeof content !== 'string') {
                const usefulString = getMessageText(content)
                messageBody = usefulString
            }

            if (messageBody !== null)
                chatTranscriptString += `\n${ParticipantRole} \n${messageBody} \n${formatDateTime(new Date(AbsoluteTime), { date: false, time: true })}\n`
        })

        // copy the transcript to the agents clipboard
        try {
            if (navigator.clipboard && typeof chatTranscriptString === 'string') {
                await navigator.clipboard.writeText(chatTranscriptString)
            }
        } catch (err) {
            console.error('Failed to copy: ', err)
        }
    }

    return copyChatToClipboard
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import ICustomMessagingState, {
    ICustomMessagingMessage,
    ICustomMessagingTag,
} from './customMessaging.state'

const initialState: ICustomMessagingState = {
    customMessageLoading: false,
    singleMessageLoading: '',
    tagsLoading: false,
    customMessages: undefined,
    customMessagingTags: undefined,
}

const removeExistingTags = (
    CustomMessages?: ICustomMessagingMessage[],
    tags?: ICustomMessagingTag[],
) => {
    if (!tags || !CustomMessages) return []
    const tagsIds = tags.map((t) => t.key)
    return CustomMessages.map((m) => {
        const tags = m.tags.filter((t) => !tagsIds.includes(t.key))
        return { ...m, tags }
    })
}

export const customMessagingSlice = createSlice({
    name: 'customMessaging',
    initialState,
    reducers: {
        customMessagingMessagesLoading(state, action: PayloadAction<boolean | undefined>) {
            return {
                ...state,
                customMessageLoading: action.payload ?? true,
            }
        },
        getCustomMessagingMessages(state, action: PayloadAction<ICustomMessagingMessage[]>) {
            return {
                ...state,
                customMessages: action.payload,
                customMessageLoading: false,
            }
        },
        editCustomMessagingMessage(state, action: PayloadAction<Partial<ICustomMessagingMessage>>) {
            return {
                ...state,
                customMessageLoading: false,
                customMessages:
                    removeExistingTags(state.customMessages, action.payload.tags).map((message) => {
                        if (action.payload?.id === message.id) {
                            return { ...message, ...action.payload }
                        }
                        return message
                    }) ?? [],
                singleMessageLoading: '',
            }
        },
        deleteCustomMessagingMessage(state, action: PayloadAction<string>) {
            return {
                ...state,
                customMessageLoading: false,
                customMessages:
                    state.customMessages?.filter((message) => message.id !== action.payload) ?? [],
                singleMessageLoading: '',
            }
        },
        addCustomMessagingMessage(state, action: PayloadAction<ICustomMessagingMessage>) {
            return {
                ...state,
                customMessageLoading: false,
                customMessages: state.customMessages
                    ? [
                          ...removeExistingTags(state.customMessages, action.payload.tags),
                          action.payload,
                      ]
                    : [action.payload],
                singleMessageLoading: '',
            }
        },
        customMessagingTagsLoading(state) {
            return {
                ...state,
                tagsLoading: true,
            }
        },
        customMessagingSingleMessageLoading(state, action: PayloadAction<string>) {
            return {
                ...state,
                singleMessageLoading: action.payload,
            }
        },
        getCustomMessagingTags(state, action: PayloadAction<ICustomMessagingTag[]>) {
            return {
                ...state,
                customMessagingTags: action.payload,
                tagsLoading: false,
            }
        },
    },
})

export const {
    customMessagingMessagesLoading,
    getCustomMessagingMessages,
    editCustomMessagingMessage,
    deleteCustomMessagingMessage,
    addCustomMessagingMessage,
    customMessagingTagsLoading,
    customMessagingSingleMessageLoading,
    getCustomMessagingTags,
} = customMessagingSlice.actions

export type CustomMessagingAction = ReturnType<
    (typeof customMessagingSlice.actions)[keyof typeof customMessagingSlice.actions]
>

export default customMessagingSlice.reducer

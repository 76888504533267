import React from 'react'
import sanitiseHTML from 'sanitize-html'
import './editor-view.scss'

interface Props {
    rawHTML: string
    className?: string
}

const EditorView: React.FC<Props> = ({ rawHTML, className }) => (
    <div
        dangerouslySetInnerHTML={{
            __html: sanitiseHTML(rawHTML, {
                allowedTags: sanitiseHTML.defaults.allowedTags.concat(['u']),
            }),
        }}
        className={`sa-form-editor ${className ?? ''}`}
    />
)

export default EditorView

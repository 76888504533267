import ContactState from 'store/contact/contact.state'

export const getReplacedQuickReplayText = (text: string, contact: ContactState) => {
    const regForVariable = /{(.*?)}/g
    const variablesWithBrackets = [...(text?.matchAll(regForVariable) || [])].map((el) => el[0])
    const variables = [...(text?.matchAll(regForVariable) || [])].map((el) => el[1])
    const attributes = contact?.attributes
    const variablesEqualToAttributes = []
    for (const variable of variables) {
        variablesEqualToAttributes.push(attributes?.[variable])
    }
    let textReplacedWithVariable = ''
    if (variablesWithBrackets.length && variablesEqualToAttributes.length && text) {
        textReplacedWithVariable = text
        for (let i = 0; i < variablesEqualToAttributes.length; i++) {
            if (variablesWithBrackets[i] && variablesEqualToAttributes[i]) {
                textReplacedWithVariable = textReplacedWithVariable.replace(
                    variablesWithBrackets[i],
                    variablesEqualToAttributes[i],
                )
            }
        }
    }
    return { textReplacedWithVariable, variablesWithBrackets, variablesEqualToAttributes }
}

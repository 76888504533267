import React from 'react'

import { TimeElapsed } from '@missionlabs/smartagent-app-components'

import SmallSelectedClockIcon from 'images/icon-clock-small-selected.svg'

import styles from './duration.module.scss'

export type Props = {
    date?: Date
}

const Duration: React.FC<Props> = ({ date }) => {
    return (
        <div className={styles.container}>
            <img src={SmallSelectedClockIcon} alt="" />
            <span className="only-visible-to-screen-readers">Duration:</span>
            {date ? <TimeElapsed date={date} /> : <p>Unknown</p>}
        </div>
    )
}

export default Duration

import {
    WebSocketCompanionRoutes,
    WebSocketCompanionTargets,
} from 'store/websocketCompanion/websocketCompanion.state'

import WebsocketWrapper, {
    IWebSocketParameters,
} from '@missionlabs/smartagent-lib-shared/build/clients/websocket/websocket'

export default class WSCompanionClient extends WebsocketWrapper {
    constructor(config: IWebSocketParameters) {
        super(config)
    }

    sendMessage(action: string, data: Record<string, any> = {}) {
        return this.send(WebSocketCompanionRoutes.COMPANION_SEND_MESSAGE, {
            data: {
                action,
                data,
            },
            target: WebSocketCompanionTargets.COMPANION,
        })
    }
}

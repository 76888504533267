import './user-announcement.scss'

import { ReactComponent as AnnouncementIcon } from 'images/icon-announcement.svg'
import { ReactComponent as BackIcon } from 'images/icon-arrow-left.svg'
import React from 'react'
import { UserAnnouncement } from 'store/announcement/announcement.state'
import { humanFriendlyDateTime } from 'utils'
import EditorView from 'views/Common/EditorView'

import { Button } from '@missionlabs/smartagent-app-components'

interface Props {
    userAnnouncement: UserAnnouncement
    onBack: () => void
}

const ViewUserAnnouncement: React.FC<Props> = ({ userAnnouncement: ua, onBack }) => {
    return (
        <div className="sa-user-announcement">
            <div className="sa-user-announcement-header">
                <Button onClick={onBack}>
                    <BackIcon height={12} className="sa-user-announcement-header-icon" />
                    Back
                </Button>
            </div>

            <div className="sa-user-announcement-metadata">
                {/* Metadata */}
                <div>
                    <span className="sa-user-announcement-metadata-prefix">Published by:</span>{' '}
                    {ua.sentByName ?? ua.lastUpdatedByName}
                </div>
                <div>
                    <span className="sa-user-announcement-metadata-prefix">Published date:</span>{' '}
                    {humanFriendlyDateTime(new Date(ua.lastSentDate ?? new Date()))}
                </div>
            </div>

            <div className="sa-user-announcement-main">
                {/* Icon */}
                <AnnouncementIcon />

                <div className="sa-user-announcement-main-content">
                    {/* Title */}
                    <div className="sa-user-announcement-main-content-title">{ua.title}</div>
                    {/* Message */}
                    <EditorView rawHTML={ua.messageHTML} />
                </div>
            </div>
        </div>
    )
}

export default ViewUserAnnouncement

import { DataProxy, FetchResult } from '@apollo/client'
import gql from 'graphql-tag'
import LIST_MESSAGES_, { ListMessagesResponse } from 'graphql/queries/listMessages'
import { Message, TypeName } from '../types'

export default gql`
    mutation createMessage($conversationId: String!, $content: String!) {
        createMessage(conversationId: $conversationId, content: $content) {
            __typename
            id
            content
            conversationId
            timestamp
            user
        }
    }
`

export interface CreateMessageResponse {
    __typename: string
    createMessage: Message & TypeName
}

export const mutation = (
    createMessageInput: { conversationId: string; content: string },
    username: string,
) => ({
    variables: createMessageInput,
    // optimisticResponse: {
    //     __typename: 'Mutation',
    //     createMessage: {
    //         __typename: 'Message',
    //         id: '-1',
    //         timestamp: new Date().getTime(),
    //         user: username,
    //         ...createMessageInput,
    //     },
    // },
    update: (
        proxy: DataProxy,
        result: FetchResult<CreateMessageResponse, Record<string, any>, Record<string, any>>,
    ) => {
        if (!result.data) return
        // Read the data from our cache for this query.
        const data = proxy.readQuery<ListMessagesResponse>({
            query: LIST_MESSAGES_,
            variables: { conversationId: createMessageInput.conversationId },
        })
        console.log('data', data)
        if (!data?.listMessages) throw new Error('Invalid query')

        const newData = {
            ...data,
            listMessages: {
                ...data.listMessages,
                items: [result.data.createMessage, ...data.listMessages.items],
            },
        }

        console.log('new data', newData)
        // Write our data back to the cache.
        proxy.writeQuery({ query: LIST_MESSAGES_, data: newData })
    },
})

import { Button, formatDateTime } from '@missionlabs/smartagent-app-components'
import classnames from 'classnames'
import useAppConfig from 'hooks/redux/useAppConfig'
import OutboundIcon from 'images/icon-outbound-call.svg'
import React from 'react'
import { ICallLog } from 'store/callLog/callLog.state'
import { IDirectoryRecord } from 'store/directory/directory.state'
import { formatPhoneNumber, formatStartOfIntNumbers } from 'utils'
import './call-log.scss'

interface Props {
    contact: ICallLog
    onClick: (contact: ICallLog) => void
    selected?: boolean
    directoryContact: IDirectoryRecord | null
}

const CallLogComponent: React.FC<Props> = ({ contact, onClick, selected, directoryContact }) => {
    const appConfig = useAppConfig()
    const internalNumberLength = appConfig.internalNumberLength

    const name = (directoryContact?.firstName ?? '') + ' ' + (directoryContact?.lastName ?? '')

    const getCallLength = (duration: number) => {
        const minutes = Math.floor(duration / 60)
        const seconds = duration % 60
        return `Duration: ${minutes > 0 ? `${minutes}m ` : ''}${seconds}s`
    }
    const isName = (name: string) => {
        if (name.length <= 1) {
            return contact.attributes['sa-customer-endpoint-address'] ?? 'Customer'
        }
        return name
    }

    const getCallTimeAsDate = (start: Date | number) => {
        if (!start) return new Date(0) // return epoch time if start is not defined
        if (!(start instanceof Date)) {
            start = new Date(start)
        }
        return start
    }

    const renderPhoneNumber = () => {
        const number = contact.attributes['sa-dialled-number'] || contact.customerEndpointAddress
        return formatPhoneNumber(formatStartOfIntNumbers(number, internalNumberLength))
    }

    //Number is either the dialled-number attribute, contact number or fallback to call number;
    return (
        <li>
            <Button className="full-width call-log-button" onClick={() => onClick(contact)}>
                <div
                    className={classnames({
                        'call-log': true,
                        'call-log-chat': contact.channel === 'CHAT',
                        'row between': true,
                        selected,
                    })}
                >
                    {contact.channel === 'VOICE' && (
                        <div className="call-log-icon">
                            {contact.initiationMethod === 'OUTBOUND' ? (
                                <img src={OutboundIcon} title="Outbound" alt="Outbound" />
                            ) : (
                                ' '
                            )}
                        </div>
                    )}
                    <div
                        className={classnames({
                            'call-log-details': true,
                            'call-log-task': contact.channel === 'TASK',
                        })}
                    >
                        <div className="call-log-main row">
                            <span className="call-log-name">{isName(name)}</span>
                            <span className="call-log-number">
                                {contact.channel === 'VOICE' && renderPhoneNumber()}
                            </span>
                        </div>
                        <div className="call-log-type">
                            <time>{getCallLength(contact.agentInteractionDuration ?? 0)}</time>
                            <time className="call-log-time">
                                {formatDateTime(
                                    getCallTimeAsDate(
                                        contact.connectedToAgentTimestamp ??
                                            contact.initiationTimestamp,
                                    ),
                                    { date: true, time: true },
                                )}
                            </time>
                        </div>
                    </div>
                </div>
            </Button>
        </li>
    )
}

export default CallLogComponent

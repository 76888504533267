import useSmartAgentAPI from 'hooks/useSmartAgentAPI'
import { useState } from 'react'
import { postPluckContact } from 'services/api/api.contact'

export function useRequestCall() {
    const [contactIDLoading, setContactIDLoading] = useState('')

    const [contactIDError, , contactIDResponse, request] = useSmartAgentAPI(
        async (state, contactID) => {
            setContactIDLoading(contactID)
            return postPluckContact(
                contactID,
                state.app.ID,
                state.app.instance!.ID,
                state.auth.token!,
            )
        },
        false,
        () => setContactIDLoading(''),
    )

    const requestCall = (contactID: string) => {
        request(contactID)
    }

    return {
        contactIDLoading,
        contactIDError,
        contactIDResponse,
        requestCall,
    }
}

import type { Reducer } from '@reduxjs/toolkit'
import { IExternalDirectory, IExternalDirectoryRecord } from './externalDirectory/state'
import { IOwners } from './owners/state'
import { IRestructuredDirectoryContactsState } from './restructuredContactsByPhoneNumber/state'
import { IDirectorySearchParams } from './searchParams/state'
import { ITeams, ITeamsRecord } from './teams/state'

export interface IDirectoryState {
    externalDirectory: IExternalDirectory
    owners: IOwners
    restructuredDirectoryContacts: IRestructuredDirectoryContactsState
    searchParams: IDirectorySearchParams
    teams: ITeams
}

export type DirectoryReducers = {
    [key in keyof IDirectoryState]: Reducer<IDirectoryState[key]>
}

export type binary = 0 | 1

export interface IDirectoryRecord {
    id: string
    sourceType?: string
    firstName?: string
    lastName?: string
    emailAddresses?: IEmailAddress[]
    phoneNumbers?: IPhoneNumber[]
    owner?: string
    socialMediaHandles?: ISocialMediaHandle[]
    role?: string
    description?: string
    department?: string
    priority?: boolean
    isBlocked?: boolean | binary
    isDeprioritised?: boolean | binary
}

export interface ISocialMediaHandle {
    type: string
    value: string
}

export interface IPhoneNumber {
    type: string
    value: string
}

export interface IEmailAddress {
    type: string
    value: string
}

export interface IRealTimeQueueContact {
    Agent?: {
        name: string
        Id: string
        ARN: string
        username: string
    }
    ContactId: string
    PreviousContactId: string
    InitiationMethod: string
    Channel: string
    InitialContactId: string
    References: {}
    Description: null
    InstanceId: string
    State: 'ERROR' | 'ENDED' | 'PARKED' | 'PLUCKED' | 'IN_QUEUE' | 'CONNECTING' | 'CONNECTED'
    QueueId: string
    CustomerEndpoint: {
        Address: string
        Type: string
    }
    LanguageCode: string
    Queue: {
        ARN: string
    }
    PluckAgent?: {
        Id: string
        name: string
        username: string
        ARN: string
    }
    ParkAgent?: this['PluckAgent']
    ParkBay?: number
    ParkedTimestamp?: number
    EnqueueTimestamp: number
    InstanceARN: string
    SystemEndpoint: connect.Endpoint
    Attributes: {}
    MediaStreams: any
    Name: null
    directoryRecord?: DirectoryRecordType
}

export interface IStatusInfo {
    availability: string
}

export enum DirectorySourceTypes {
    TEAMS = 'teams',
    EXTERNAL_DIRECTORY = 'external-directory',
}

export enum FullProfileViewFieldNames {
    PHONE_NUMBER = 'Phone Number',
    EMAIL = 'Email',
    OFFICE = 'Office',
    DEPRIORITISED = 'Deprioritised',
    BLOCKED = 'Blocked',
}

export type DirectoryRecordType = IExternalDirectoryRecord | ITeamsRecord

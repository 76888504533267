import { PayloadAction, combineReducers, createSlice } from '@reduxjs/toolkit'

import type {
    CampaignManagementReducers,
    CampaignsState,
    UploadFile,
} from './campaignManagement.state'

type State = CampaignsState | null
const campaignsInitialState = null as State

export const campaignsSlice = createSlice({
    name: 'campaigns',
    initialState: campaignsInitialState,
    reducers: {
        getCampaignsSuccess(_, action: PayloadAction<any[]>) {
            return [...action.payload]
        },
        getCampaignsFailure(state) {
            return state ? [...state] : []
        },
        createCampaignSuccess(state, action: PayloadAction<any>) {
            return [action.payload, ...(state || [])]
        },
    },
})

export const { getCampaignsSuccess, getCampaignsFailure, createCampaignSuccess } =
    campaignsSlice.actions

const uploadInitialState: UploadFile = {}

export const uploadSlice = createSlice({
    name: 'upload',
    initialState: uploadInitialState,
    reducers: {
        createS3URLFailure(state, action: PayloadAction<boolean>) {
            return { ...state, error: action.payload }
        },
        createS3URLSuccess(state, action: PayloadAction<UploadFile>) {
            return { ...state, ...action.payload }
        },
        createS3URLUploading(state, action: PayloadAction<boolean>) {
            return { ...state, uploading: action.payload, error: false }
        },
        removeS3URLUploading() {
            return {}
        },
    },
})

export const {
    createS3URLFailure,
    createS3URLSuccess,
    createS3URLUploading,
    removeS3URLUploading,
} = uploadSlice.actions

const isCreatingInitialState: boolean = false

export const isCreatingSlice = createSlice({
    name: 'isCreating',
    initialState: isCreatingInitialState,
    reducers: {
        createCampaign() {
            return true
        },
        editCampaign() {
            return true
        },
        createCampaignFailure() {
            return false
        },
        editCampaignSuccess() {
            return false
        },
        editCampaignFailure() {
            return false
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(createCampaignSuccess, () => false)
    },
})

export const {
    createCampaign,
    editCampaign,
    createCampaignFailure,
    editCampaignSuccess,
    editCampaignFailure,
} = isCreatingSlice.actions

export type CampaignManagementAction =
    | ReturnType<(typeof campaignsSlice.actions)[keyof typeof campaignsSlice.actions]>
    | ReturnType<(typeof uploadSlice.actions)[keyof typeof uploadSlice.actions]>
    | ReturnType<(typeof isCreatingSlice.actions)[keyof typeof isCreatingSlice.actions]>

const campaignManagementReducer = combineReducers<CampaignManagementReducers>({
    campaigns: campaignsSlice.reducer,
    s3Url: uploadSlice.reducer,
    isCreating: isCreatingSlice.reducer,
})

export default campaignManagementReducer

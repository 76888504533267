import { MS_IN_HOUR } from 'constants/index'
import { isToday } from 'date-fns'
import { initAppSuccess, selectInstance } from 'store/app/app.reducer'
import { getContact, updateContactAttributes } from 'store/contact/contact.reducer'
import { getSettings } from 'store/settings/settings.reducer'
import { userAuthenticated } from 'store/user/user.reducer'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { mergeAttributesIntoContact } from '../contact/contact.utils'
import CallLogState, { GetCallLogAction, ICallLog } from './callLog.state'

const initialState: CallLogState = []

export const callLogSlice = createSlice({
    name: 'callLog',
    initialState,
    reducers: {
        addToCallLog(state, action: PayloadAction<ICallLog>) {
            if (state.map((contact) => contact.ID).includes(action.payload.ID)) {
                // Check if this contact has already been added to the store
                return state
            }
            if (!action.payload.ID) {
                return state //Calls that didn't connect needn't go in the call log.
            }
            return [action.payload, ...state]
        },
        // //Can this action be used to override some of our local call log entries?
        // case MetricTypes.GET_CONTACTS:
        //     return state.map(contact=>{
        //         if(contact.source !== 'LOCAL') return contact;
        //         const apiContact = action.payload.find(c=>c.ID===contact.ID);
        //         if(!apiContact) return contact;
        //         return {
        //             ...apiContact,
        //             type: "phone",
        //             source:'API',
        //         }
        //     });
        getCallLog(state, action: PayloadAction<GetCallLogAction>) {
            const localHistory = Array.isArray(state) ? state : []

            return mergeCallLogs(localHistory, action.payload.callLog)
                .filter((callLogItem) => getTimestamp(callLogItem) >= action.payload.fromTime)
                .sort((c1, c2) => getTimestamp(c2) - getTimestamp(c1))
        },
        flushOldCallLogs(state) {
            const localHistory = Array.isArray(state) ? state : []
            return localHistory.filter(({ initiationTimestamp }: ICallLog) => {
                const initiationDate = new Date(initiationTimestamp)
                return isToday(initiationDate)
            })
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(initAppSuccess, (state, action) => {
            const { callHistoryPeriodInHours } = action.payload.appConfig
            //Dont keep local call state for longer than callHistoryPeriodInHours or 48hours - local storage *should* be overwritten by api call
            const expiry = new Date(Date.now() - (callHistoryPeriodInHours ?? 48) * MS_IN_HOUR)
            return Array.isArray(state)
                ? state.filter((call) => {
                      return (
                          new Date(call?.connectedToAgentTimestamp ?? call?.initiationTimestamp) >
                          expiry
                      )
                  })
                : []
        }),
            addCase(getSettings, (state, action) => {
                const { appConfigurations } = action.payload
                //Dont keep local call state for longer than callHistoryPeriodInHours or 48hours - local storage *should* be overwritten by api call
                if (typeof appConfigurations?.callHistoryHours === 'number') {
                    const expiry = new Date(
                        Date.now() - appConfigurations?.callHistoryHours * MS_IN_HOUR,
                    )
                    return Array.isArray(state)
                        ? state.filter((call) => {
                              return (
                                  new Date(
                                      call?.connectedToAgentTimestamp ?? call?.initiationTimestamp,
                                  ) > expiry
                              )
                          })
                        : []
                }
                return state
            }),
            addCase(selectInstance, (state, action) => {
                //Incase local storage is from a different login
                return state.filter((call) => call?.instanceID === action.payload.instance.ID)
            }),
            addCase(updateContactAttributes, (state, action) => {
                return state.map((c) => {
                    if (c.ID !== action.payload.ID) return c
                    return mergeAttributesIntoContact(c, action.payload.attributes) as ICallLog
                })
            }),
            addCase(getContact, (state, action) => {
                return state.map((contact) => {
                    if (contact.ID === action.payload.ID) {
                        return {
                            ...action.payload,
                            source: 'API',
                        }
                    }
                    return contact
                })
            }),
            addCase(userAuthenticated, (state, action) => {
                //Fixes the case where local storage
                if (!Array.isArray(state)) return []
                return state.filter((callHistory) => callHistory.agentID === action.payload.agentID)
            })
    },
})

const mergeCallLogs = (...callLogHistories: Array<Array<ICallLog>>): Array<ICallLog> => {
    const callLogsWithID: Array<[string, ICallLog]> = callLogHistories
        .flat()
        .map((logItem) => [logItem.ID, logItem])

    const mergedCallLogs = new Map(callLogsWithID)

    return Array.from(mergedCallLogs.values())
}

const getTimestamp = (callLog: ICallLog): number => {
    return callLog.connectedToAgentTimestamp ?? callLog.initiationTimestamp
}

export const { addToCallLog, getCallLog, flushOldCallLogs } = callLogSlice.actions

export type CallLogAction = ReturnType<
    (typeof callLogSlice.actions)[keyof typeof callLogSlice.actions]
>

export default callLogSlice.reducer

import { Button } from '@missionlabs/smartagent-app-components'
import classNames from 'classnames'
import React from 'react'

export interface SATab {
    id: string
    content?: React.ReactNode
}
interface TabsProps {
    className?: string
    tabClassName?: string
    tabs: SATab[]
    selected: string
    onClick(tab: string): void
}

const Tabs: React.FC<TabsProps> = ({ tabs, tabClassName, className, selected, onClick }) => {
    return (
        <div
            className={classNames({
                'sa-tabs': true,
                [className!]: !!className,
            })}
        >
            {tabs.map((tab) => (
                <Button
                    onClick={() => onClick(tab.id)}
                    className={classNames({
                        'sa-tabs__button': true,
                        'sa-tabs__button--selected': tab.id === selected,
                        [tabClassName!]: tabClassName!!,
                    })}
                    key={tab.id}
                >
                    {tab.content ?? tab.id}
                </Button>
            ))}
        </div>
    )
}

export default Tabs

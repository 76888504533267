import { makeRequest } from 'services/api'

export async function getRuleGroups(companyId: string, instanceId: string) {
    return await makeRequest({
        url: `/thread-service/companies/${companyId}/instances/${instanceId}/rules/groups`,
        method: 'get',
    })
}

export async function createRuleGroup(ruleGroup: any, companyId: string, instanceId: string) {
    return await makeRequest({
        url: `/thread-service/companies/${companyId}/instances/${instanceId}/rules/groups`,
        method: 'post',
        data: ruleGroup,
    })
}

export async function deleteRuleGroup(groupID: string, companyId: string, instanceId: string) {
    return await makeRequest({
        url: `/thread-service/companies/${companyId}/instances/${instanceId}/rules/groups/${groupID}`,
        method: 'delete',
    })
}

export async function updateRuleGroup(ruleGroup: any, companyId: string, instanceId: string) {
    return await makeRequest({
        url: `/thread-service/companies/${companyId}/instances/${instanceId}/rules/groups/${ruleGroup.ID}`,
        method: 'put',
        data: ruleGroup,
    })
}

export async function getRulesByGroupId(companyId: string, instanceId: string, groupID: string) {
    return await makeRequest({
        url: `/thread-service/companies/${companyId}/instances/${instanceId}/rules/groups/${groupID}`,
        method: 'get',
    })
}

export async function createRule(rule: any, companyId: string, instanceId: string) {
    return await makeRequest({
        url: `/thread-service/companies/${companyId}/instances/${instanceId}/rules`,
        method: 'post',
        data: rule,
    })
}

export async function updateRule(rule: any, companyId: string, instanceId: string) {
    return await makeRequest({
        url: `/thread-service/companies/${companyId}/instances/${instanceId}/rules/${rule.ID}`,
        method: 'put',
        data: rule,
    })
}

export async function deleteRule(ruleID: string, companyId: string, instanceId: string) {
    return await makeRequest({
        url: `/thread-service/companies/${companyId}/instances/${instanceId}/rules/${ruleID}`,
        method: 'delete',
    })
}

export async function evaluateRule(
    rule: any,
    numberOfDays: number,
    companyId: string,
    instanceId: string,
) {
    return await makeRequest({
        url: `/thread-service/companies/${companyId}/instances/${instanceId}/rules/evaluate`,
        method: 'post',
        data: {
            rule,
            numberOfDays,
        },
    })
}

export async function getActionAudit(
    companyId: string,
    instanceId: string,
    token: string,
    actionAuditId: string,
) {
    return await makeRequest({
        url: `/thread-service/companies/${companyId}/instances/${instanceId}/rules/audit/${actionAuditId}`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })
}

import useContact from 'hooks/redux/useContact'
import WarningTriangle from 'images/icon-hazard-triangle.svg'
import WarningCircle from 'images/icon-hazard.svg'
import { useCallback, useEffect, useState } from 'react'
import { count as getSMSLengthInfo } from 'sms-length'

const DEFAULT_MAX_LENGTH = 1024
const MAX_NUMBER_OF_SMS_MESSAGES = 10
const SMS_MAX_LENGTHS: Record<ReturnType<typeof getSMSLengthInfo>['encoding'], number> = {
    GSM_7BIT: 1024,
    GSM_7BIT_EXT: 1024,
    UTF16: 630, // From Pinpoint docs
}

const WHATSAPP_MAX_LENGTH = 1023

const useSocialChatReplyFunctionality = (socialMediaReply: string, subChannel?: string) => {
    const contact = useContact()
    const isSMS =
        subChannel === 'SMS-DM' || contact?.attributes?.['sa-social-media-platform'] === 'SMS'
    const isWhatsApp = contact?.attributes?.['sa-social-media-platform'] === 'WHATSAPP'
    const isTwitter = subChannel === 'TWEET' || contact?.attributes?.['sa-sub-channel'] === 'TWEET'
    const { messages: numberOfSMSMessages } = getSMSLengthInfo(socialMediaReply)

    const calculateTextLimit = () => {
        if (isTwitter) {
            return 260
        }
        if (isSMS) {
            return SMS_MAX_LENGTHS.GSM_7BIT
        }
        if (isWhatsApp) {
            return WHATSAPP_MAX_LENGTH
        }

        return DEFAULT_MAX_LENGTH
    }
    const [textLimit, setTextLimit] = useState<number>(calculateTextLimit())

    const getSocialReplyMaxLength = useCallback(() => {
        if (isSMS) {
            return undefined
        }

        return textLimit
    }, [socialMediaReply, isSMS, textLimit])

    const isSocialReplyTextLimitReached = useCallback(() => {
        if (isSMS) {
            const { encoding, length, messages } = getSMSLengthInfo(socialMediaReply)
            const isSMSCharacterLimitReached =
                length > SMS_MAX_LENGTHS[encoding] || messages > MAX_NUMBER_OF_SMS_MESSAGES
            return isSMSCharacterLimitReached
        }

        return socialMediaReply.length > textLimit
    }, [isSMS, socialMediaReply])

    const smsIsTooLongToSend = isSMS && isSocialReplyTextLimitReached()
    const willSendMultipleSMSMessages = numberOfSMSMessages > 1 && !isSocialReplyTextLimitReached()

    const getSMSAlertBannerIconDiv = () =>
        document.querySelector('.sa-alert-banner--icon') as HTMLElement
    const getSMSAlertBannerImg = () => document.querySelector('.sa-alert-banner--icon img')

    useEffect(() => {
        setTextLimit(calculateTextLimit())
    }, [contact?.attributes?.['sa-sub-channel'], subChannel])

    useEffect(() => {
        if (smsIsTooLongToSend) {
            const alertBannerBox = getSMSAlertBannerIconDiv()
            const alertBannerImg = getSMSAlertBannerImg()
            if (alertBannerBox) {
                alertBannerBox.style.background = '#D06C00'
            }
            if (alertBannerImg) {
                alertBannerImg.setAttribute('src', WarningTriangle)
            }
        }
    }, [smsIsTooLongToSend])

    useEffect(() => {
        if (willSendMultipleSMSMessages) {
            const alertBannerImg = getSMSAlertBannerImg()
            if (alertBannerImg) {
                alertBannerImg.setAttribute('src', WarningCircle)
            }
        }
    }, [willSendMultipleSMSMessages])

    return {
        smsIsTooLongToSend,
        willSendMultipleSMSMessages,
        isSMS,
        getSocialReplyMaxLength,
        isSocialReplyTextLimitReached,
        textLimit,
        numberOfSMSMessages,
    }
}

export default useSocialChatReplyFunctionality

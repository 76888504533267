import { Box } from '@missionlabs/smartagent-app-components'
import { ToolBar } from 'components'
import QueuesPopup from 'components/Popups/QueuesPopup/QueuesPopup'
import React, { useState } from 'react'
import { Picker } from './Picker'

import useContactPanelContext from 'views/ContactPanel/useContactPanelContext'
import './realtime-contacts.scss'

interface Props {}

const RealTimeContacts: React.FC<Props> = (props) => {
    const [queues, setQueues] = useState<string[]>([])

    const { realtimeOpen, setRealtimeOpen } = useContactPanelContext()

    return (
        <>
            <Box
                onToggle={setRealtimeOpen}
                controlledHidden={realtimeOpen}
                alt
                collapse
                header={
                    <ToolBar
                        text="Realtime Contacts"
                        items={[<QueuesPopup key={0} selected={queues} onChange={setQueues} />]}
                    />
                }
            >
                <Picker queuesARNs={queues} shouldRefresh />
            </Box>
        </>
    )
}

export default RealTimeContacts

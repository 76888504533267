import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Box } from '@missionlabs/smartagent-app-components'

import Contact from 'views/Home/Contact'
import Controls from 'views/Home/Dialler/Controls'
import InCall from 'views/Home/Dialler/InCall'
import Incoming from 'views/Home/Dialler/Incoming'
import CallSummary from './CallSummary'

import useCall from 'hooks/redux/useCall'
import useContact from 'hooks/redux/useContact'

import { makeCall } from 'store/call/call.actions'

import { Channel } from '@aws-sdk/client-connect'
import { setContact } from 'store/contact/contact.actions'
import type { IEnabledQueue } from 'store/settings/settings.state'

import './dialler.scss'

export interface Props {
    showCallSummary: boolean
}

const Dialler: React.FC<Props> = ({ showCallSummary }) => {
    const dispatch = useDispatch()

    const contact = useContact()
    const { incoming = false, connections = [] } = useCall() ?? {}

    useEffect(() => {
        if (contact?.channel !== Channel.VOICE) {
            dispatch(setContact(null))
        }
    }, [])

    const [selectedOutboundQueue, setSelectedOutboundQueue] = useState<IEnabledQueue>()

    return (
        <Box boxLabel="dialler" className="dialler" header={showCallSummary && <CallSummary />}>
            {connections.length === 0 ? (
                <Controls
                    isOutboundQueueSelectorEnabled={true}
                    selectedOutboundQueue={selectedOutboundQueue}
                    onOutboundQueueSelected={setSelectedOutboundQueue}
                    makeCall={(number, name, endpoint, queueArn) =>
                        dispatch(makeCall(number, name, endpoint, queueArn))
                    }
                />
            ) : incoming ? (
                <Incoming />
            ) : (
                <InCall />
            )}
            {!!contact && <Contact contact={contact} />}
        </Box>
    )
}

export default Dialler

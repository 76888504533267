import React from 'react'
import { ClipLoader } from 'react-spinners'

import { Button } from '@missionlabs/smartagent-app-components'

import { EmailMessage } from 'components'
import { IEmailMessage } from 'components/EmailMessage/types'

import './email-messages.scss'

export interface Props {
    emailMessages?: IEmailMessage[]
    highlightedMessageId?: string
    viewPreviousEmailMessages?: boolean
    setViewPreviousEmailMessages: (...args: any[]) => void
}

const EmailMessages: React.FC<Props> = ({
    emailMessages,
    highlightedMessageId,
    viewPreviousEmailMessages = false,
    setViewPreviousEmailMessages,
}) => {
    return (
        <>
            {!emailMessages ? (
                <div className="email-messages-loading">
                    <ClipLoader />
                </div>
            ) : (
                (() => {
                    const viewableMessages = viewPreviousEmailMessages
                        ? emailMessages
                        : emailMessages.filter(
                              (message) => message.messageId === highlightedMessageId,
                          )

                    return (
                        <section className="email-messages">
                            {viewableMessages.map(
                                ({
                                    subject,
                                    to,
                                    from,
                                    externalFrom,
                                    forwardedTo,
                                    repliedTo,
                                    content,
                                    timestamp,
                                    type,
                                    cc,
                                    messageId,
                                    attachments,
                                }) => (
                                    <EmailMessage
                                        key={messageId}
                                        from={externalFrom || from}
                                        to={forwardedTo || repliedTo || to}
                                        subject={subject}
                                        content={content}
                                        timestamp={timestamp}
                                        type={type}
                                        cc={cc}
                                        highlight={
                                            highlightedMessageId === messageId &&
                                            viewPreviousEmailMessages
                                        }
                                        attachments={attachments}
                                    />
                                ),
                            )}
                            {!viewPreviousEmailMessages && emailMessages.length > 1 && (
                                <Button
                                    className="view-previous-messages"
                                    styling="white"
                                    onClick={setViewPreviousEmailMessages}
                                >
                                    Show all emails
                                </Button>
                            )}
                        </section>
                    )
                })()
            )}
        </>
    )
}

export default EmailMessages

import classNames from 'classnames'
import React from 'react'

import { Box, Popup, Table } from '@missionlabs/smartagent-app-components'
import type { IContactAudit } from '@missionlabs/smartagent-service-contact/dist/lib/entities/contact-audit'
import type { IAutomatedActionAudit } from '@missionlabs/smartagent-service-thread/dist/entities/action-audit'

import { automatedRulesColumns, queuedTasksColumns } from './columns'

import './task-audit-dialog.scss'

export interface Props {
    automatedRulesAudit: IAutomatedActionAudit[]
    queuedTasksAudit: IContactAudit[]
    hasAutoReply: boolean
    setOpenTasksAuditPopup: (isOpen: boolean) => void
}

const TaskAuditDialog: React.FC<Props> = ({
    automatedRulesAudit,
    queuedTasksAudit,
    hasAutoReply,
    setOpenTasksAuditPopup,
}) => {
    return (
        <Popup
            center
            className="task-audit-dialog"
            close
            closeOnClickOutside
            onClose={() => setOpenTasksAuditPopup(false)}
        >
            <h3 className="task-audit-dialog-header">Email audit</h3>
            <Box
                alt
                className="task-audit-dialog-box"
                header={<h3>Automated rules ({automatedRulesAudit.length})</h3>}
            >
                <Table
                    caption="Automated rules audit"
                    cols={automatedRulesColumns}
                    data={automatedRulesAudit}
                    noData="No automated rules audit data available"
                />
            </Box>
            <Box
                alt
                className="task-audit-dialog-box"
                header={<h3>Queued tasks ({queuedTasksAudit.length})</h3>}
            >
                <Table
                    caption="Queued tasks audit"
                    cols={queuedTasksColumns}
                    data={queuedTasksAudit}
                    noData="No queued tasks audit data available"
                />
            </Box>
            <strong>Automatic replies:</strong>{' '}
            <span
                data-testid="autoreply-indicator"
                className={classNames({
                    'task-audit-dialog-autoreply-indicator': true,
                    'task-audit-dialog-autoreply-indicator-sent': hasAutoReply,
                })}
            >
                {hasAutoReply ? 'Sent' : 'N/A'}
            </span>
        </Popup>
    )
}

export default TaskAuditDialog

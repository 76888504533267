import { Input } from '@missionlabs/smartagent-app-components'
import React from 'react'

interface Props {
    search: string
    onInputChange(value: any): void
    triggerClassName?: string
}

const DirectorySearchInput: React.FC<Props> = ({ triggerClassName, search, onInputChange }) => (
    <Input
        className={triggerClassName ?? 'sa-directory-popup-button'}
        value={search}
        onChange={onInputChange}
        placeholder="Search"
    />
)

export default DirectorySearchInput

import './index.scss'

import useHasFeature, { AppFeatures } from 'hooks/useHasFeature'
import { ReactComponent as HeadphonesIcon } from 'images/headphones.svg'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { createNotification } from 'store/notification/notification.actions'
import { setUserHasDisabledWebHID, setUserHasEnabledWebHID } from 'store/settings/settings.actions'
import RootState from 'store/state'
import { listConnectedWebHIDHeadsets } from 'utils'

import { webHidPairing } from '@gnaudio/jabra-js'
import { Button } from '@missionlabs/smartagent-app-components'

const WebHIDNotificationBanner: React.FC<{}> = (props) => {
    const dispatch = useDispatch()

    const handleAllow = useCallback(async () => {
        try {
            const connectedHeadsets = await listConnectedWebHIDHeadsets()
            if (!connectedHeadsets?.length) {
                await webHidPairing()
            }
            dispatch(setUserHasEnabledWebHID())
        } catch (error) {
            let errorMessage = 'Error connecting to your headset'
            if (error instanceof Error && typeof error?.message === 'string') {
                errorMessage = error.message
            }
            dispatch(
                createNotification({
                    header: 'Error',
                    text: errorMessage,
                    type: 'error',
                }),
            )
        }
    }, [dispatch])

    const handleIgnore = useCallback(() => {
        dispatch(setUserHasDisabledWebHID())
    }, [dispatch])

    const appState = useSelector<RootState, RootState>((state) => state)
    const hasFeature = useHasFeature()
    const webHIDFeature = hasFeature(AppFeatures.SUPPORT_WEBHID_CONTROL)
    const showWebHIDPairButton = appState.webHIDState.showWebHIDPairButton
    const callIsRinging = !!appState.call?.incoming
    const { userHasEnabledWebHID } = appState.settings

    const showWebHIDNotificationBanner =
        webHIDFeature && userHasEnabledWebHID !== false && showWebHIDPairButton

    const prefix = 'sa-webhid-notification-banner'

    return showWebHIDNotificationBanner && !callIsRinging ? (
        <div className={`${prefix}`}>
            <div className={`${prefix}-left`}>
                <HeadphonesIcon className={`${prefix}-left-icon`} height={15} width={20} />
                <span className={`${prefix}-left-text`}>
                    To deliver enhanced functionality, please allow Smart Agent to control your
                    headset. You can change this anytime from your &nbsp;
                    <Link
                        to={{ pathname: '/settings/audio', search: location.search }}
                        tabIndex={0}
                    >
                        <span className={`${prefix}-link`}>settings</span>
                    </Link>
                </span>
            </div>
            <div className={`${prefix}-right`}>
                <Button
                    styling="white"
                    curved={true}
                    onClick={handleIgnore}
                    aria-label="Ignore Advanced Headset Control"
                >
                    Ignore
                </Button>
                <Button
                    styling="white"
                    className={`${prefix}-right-allow`}
                    curved={true}
                    id="webhid"
                    onClick={handleAllow}
                    aria-label="Allow Smart Agent to control your headset"
                >
                    Allow
                </Button>
            </div>
        </div>
    ) : null
}

export default WebHIDNotificationBanner

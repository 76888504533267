import { jsx } from "react/jsx-runtime";
import classNames from "classnames";
const Dot = ({ type, ...props }) => {
  const dotClasses = classNames({
    "sa-dot": true,
    [type]: true
  });
  return /* @__PURE__ */ jsx("span", { className: dotClasses, ...props });
};
export {
  Dot as default
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { BrandData, BrandDataIsLoading, WebFormSettingsState } from './webform.state'

const initialState: WebFormSettingsState = {}

export const webFormSettingsSlice = createSlice({
    name: 'webFormSettings',
    initialState,
    reducers: {
        setWebFormConfigs(state, action: PayloadAction<BrandData>) {
            return {
                ...state,
                [action.payload.brand]: {
                    webformConfigs: action.payload.webformConfigs,
                    isLoading: false,
                },
            }
        },
        setWebFormConfigsLoading(state, action: PayloadAction<BrandDataIsLoading>) {
            return {
                ...state,
                [action.payload.brand]: {
                    ...state[action.payload.brand],
                    isLoading: action.payload.isLoading,
                },
            }
        },
    },
})

export const { setWebFormConfigs, setWebFormConfigsLoading } = webFormSettingsSlice.actions

export type WebFormSettingsAction = ReturnType<
    (typeof webFormSettingsSlice.actions)[keyof typeof webFormSettingsSlice.actions]
>

export default webFormSettingsSlice.reducer

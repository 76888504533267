import classNames from 'classnames'
import styles from './ContactName.module.scss'

interface Props {
    name: string
    className?: string
}

const ContactName = ({ name, className = '' }: Props) => (
    <h3 title={name} className={classNames(className, styles.contactName)}>
        {name}
    </h3>
)

export default ContactName

import RootState from '../../store/state'
import loadTeamsContacts from './loadTeamsContacts'
import useDirectory from './useDirectory'

type Returns = [
    RootState['directory']['teams']['contacts'],
    RootState['directory']['teams']['isTeamsContactsLoading'],
]

const useTeamsContacts = (): Returns => {
    const { teams } = useDirectory()
    const { contacts, isTeamsContactsLoading } = teams

    loadTeamsContacts()

    return [contacts, isTeamsContactsLoading]
}

export default useTeamsContacts

import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import * as ContactAPI from 'services/api/api.contact'
import RootState from 'store/state'
import { getThreadMessageGroups, transformTaskContacts } from 'views/Metrics/HistoricMetrics/utils'
import { DataRetentionChannelDropdown, DataRetentionDeleteStatus } from './dataRetention.state'
export const setEmail = createAction<string>('dataRetention/setEmail')
export const setChannel = createAction<DataRetentionChannelDropdown>('dataRetention/setChannel')
export const setDeleteStatus = createAction<DataRetentionDeleteStatus>(
    'dataRetention/setDeleteStatus',
)
export const resetStatus = createAction('dataRetention/resetStatus')

export const fetchContacts = createAsyncThunk(
    'dataRetention/fetchContacts',
    async (queryString: string, { getState }) => {
        const state = getState() as RootState
        const { app, auth } = state

        const { ID: companyID, instance: { ID: instanceID } = {} } = app
        const { token } = auth

        if (!instanceID) throw new Error('No instance ID found')
        if (!token) throw new Error('No token found')

        const { data } = await ContactAPI.getContacts(companyID, instanceID, token, queryString)
        const threadMessageGroups = await getThreadMessageGroups(data)
        const taskInteractions = transformTaskContacts(data, threadMessageGroups)

        return taskInteractions
    },
)

export const deleteCustomerData = createAsyncThunk(
    'dataRetention/deleteCustomerData',
    async (email: string, { getState }) => {
        const state = getState() as RootState
        const { app, auth } = state

        const { instance: { ID: instanceID } = {} } = app
        const { token } = auth

        if (!instanceID) throw new Error('No instance ID found')
        if (!token) throw new Error('No token found')

        await ContactAPI.deleteCustomerData(email)
    },
)

import { jsx } from "react/jsx-runtime";
import classNames from "classnames";
import React from "react";
import { ClipLoader as ClipLoader$1, PulseLoader as PulseLoader$1 } from "react-spinners";
const ClipLoader = React.forwardRef(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx(ClipLoader$1, { ref, className: classNames("sa-loader", className), ...props });
});
ClipLoader.displayName = "ClipLoader";
const PulseLoader = React.forwardRef(({ className, ...props }, ref) => {
  return /* @__PURE__ */ jsx(PulseLoader$1, { ref, className: classNames("sa-loader", className), ...props });
});
PulseLoader.displayName = "PulseLoader";
export {
  ClipLoader as C,
  PulseLoader as P
};

import { FC, useEffect, useState } from 'react'

import useMPC from 'hooks/redux/useMPC'

import Duration from 'components/Duration'
import MultiPartyControls from 'components/ParticipantList/MultiPartyControls'
import TransferControls from 'components/ParticipantList/MultiPartyControls/TransferControls'
import Tags from 'components/ParticipantList/Tags'
import type { ColourClass, Status, Tag, TypeMapping } from 'components/ParticipantList/types'

import { shouldHoldOtherAgent } from 'components/ParticipantList/utils'
import { useSelector } from 'react-redux'

import type { ConnectionType, Controls } from 'store/callV2/callV2.state'

import styles from './participant.module.scss'

import altDeclineIcon from 'images/button-decline-alt.svg'
import { selectActiveConnections } from 'store/callV2/callV2.reducer'

export type Props = {
    id: string
    type?: ConnectionType
    name?: string
    status?: Status
    initiationDate?: Date | null
    isMuted?: boolean
    isOnHold?: boolean
    controls: Controls
    colourClass?: ColourClass
    tags?: Tag[]
}

const typeMapping: TypeMapping = {
    AGENT: 'Agent',
    QC_TRANSFER: 'Agent',
    OUTBOUND_TRANSFER: 'Third party',
    CUSTOMER: 'Customer',
    YOU: 'Your call controls',
    UNKNOWN: 'Unknown',
}

const Participant: FC<Props> = ({
    id,
    type = 'UNKNOWN',
    name,
    status = 'Unknown',
    initiationDate,
    isMuted = false,
    isOnHold = false,
    controls,
    colourClass = 'default',
    tags = [],
}) => {
    const formattedType = typeMapping[type]

    const { isMPC } = useMPC()

    const [isTransferCallOpen, setIsTransferCallOpen] = useState<boolean>(false)

    const activeConnections = useSelector(selectActiveConnections)

    const { mute, onMute, hold, onHold, transfer, onTransfer, end, onEnd, keypad, onKeypadButton } =
        controls

    const endIcon = type !== 'YOU' ? altDeclineIcon : undefined

    function handleHold() {
        // place other agent on hold if MPC and customer has been disconnected
        if (isMPC && shouldHoldOtherAgent(activeConnections)) {
            const otherAgent = activeConnections.find((c) => c.type === 'AGENT')
            if (otherAgent) onHold(otherAgent.id)
        } else {
            onHold(id)
        }
    }

    function handleTransfer(endpoint: connect.Endpoint) {
        onTransfer(endpoint)
        setIsTransferCallOpen(false)
    }

    useEffect(() => {
        if (!transfer) setIsTransferCallOpen(false)
    }, [transfer])

    const sendDTMFEntry = (digit: string) => {
        onKeypadButton(id, digit)
    }

    return (
        <>
            <div data-testid="participant" className={`${styles.container} ${styles[colourClass]}`}>
                <h4 id={id}>
                    {formattedType}
                    {name && `: ${name}`}
                </h4>
                <div className={styles['container-top']}>
                    <div className={styles['container-top-left']}>
                        {initiationDate !== null && <Duration date={initiationDate} />}
                        <p>{status}</p>
                    </div>
                    <div className={styles['container-top-right']}>
                        <MultiPartyControls
                            canMute={mute}
                            onMute={() => onMute(id)}
                            isMuted={isMuted}
                            canHold={hold}
                            onHold={handleHold}
                            isOnHold={isOnHold}
                            canEnd={end}
                            onEnd={() => onEnd(id)}
                            endIcon={endIcon}
                            canTransfer={transfer}
                            isTransferCallOpen={isTransferCallOpen}
                            setIsTransferCallOpen={setIsTransferCallOpen}
                            onKeypadButton={sendDTMFEntry}
                            canUseKeypad={keypad}
                        />
                    </div>
                </div>
                {tags.length > 0 && <Tags tags={tags} />}
            </div>
            {isTransferCallOpen && (
                <div className={styles['container-bottom']}>
                    <TransferControls onTransfer={handleTransfer} />
                </div>
            )}
        </>
    )
}

export default Participant

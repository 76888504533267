import { SADropdownOption } from '@missionlabs/smartagent-app-components/dist/Dropdown/index'
import { IInteraction } from 'views/Metrics/HistoricMetrics/types'

export interface DataRetentionState {
    loading: boolean
    deleting: boolean
    deleteStatus: DataRetentionDeleteStatus
    searchFailed: boolean
    results: IInteraction[] | null
    formParams: DataRetentionForm
}

export enum DataRetentionDeleteStatus {
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
    IDLE = 'IDLE',
}

export interface DataRetentionForm {
    email: string
    channel: DataRetentionChannelDropdown
}

export enum DataRetentionChannel {
    EMAIL_WEBFORM = 'EMAIL/WEBFORM',
}

export enum DataRetentionColumnGroup {
    EMAIL = 'EMAIL',
    WEBFORM = 'WEBFORM',
}

export type DataRetentionChannelDropdown = SADropdownOption<DataRetentionChannel> & {
    group: DataRetentionColumnGroup[]
}

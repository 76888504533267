import './agents.scss'

import { UserConversation } from 'graphql/types'
import useHasFeature, { AppFeatures } from 'hooks/useHasFeature'
import useInterval from 'hooks/useInterval'
import ChatIcon from 'images/message-bubbles.svg'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useStore } from 'react-redux'
import { BeatLoader } from 'react-spinners'
import { AgentInfo } from 'store/agents/agents.state'
import { selectConversation } from 'store/internal-messaging/internal-messaging.actions'
import { IHierarchyFilter } from 'store/metrics/metrics.state'
import RootState from 'store/state'

import { ViewEvent } from '@missionlabs/analytics-react'
import { Button, Input } from '@missionlabs/smartagent-app-components'
import Tabs, { Tab } from '@missionlabs/smartagent-app-components/dist/Tabs'

import HierarchiesDropdown from '../../components/Dropdowns/hierarchies'
import RoutingProfileDropdown from '../../components/Dropdowns/routing-profiles'
import ChatWindow from '../../components/InternalMessaging/ChatView'
import Status from '../../components/Status'
import { getAgents, setAgentsFilter } from '../../store/agents/agents.actions'
import TimeInState from './TimeInState'

interface Props extends ReturnType<typeof mapStateToProps> {
    userConversations?: UserConversation[]
    onCreateConversation?(users: string[]): Promise<string> //TODO Use actual return type
}

const Agents: React.FC<Props> = ({
    agents,
    states,
    agentExpiry,
    agentsError,
    agentsLoading,
    userConversations,
    onCreateConversation,
    agentsFilter,
}) => {
    const dispatch = useDispatch()

    const username = useStore().getState().user.username

    const [search, setSearch] = useState('')

    const hasFeature = useHasFeature()
    const hasInternalMessaging = hasFeature(AppFeatures.INTERNAL_MESSAGING)

    const [newConversationId, setNewConversationId] = useState<string | undefined>(undefined)
    const [newConversationName, setNewConversationName] = useState('')

    const input = React.createRef<HTMLInputElement | HTMLTextAreaElement>()

    const conversationExists = (user: string) =>
        userConversations?.find((uc) => uc.conversation?.recipientList.includes(user))
            ?.conversationId

    const createConversation = async (agent: AgentInfo) => {
        if (!onCreateConversation) return
        let conversationId = conversationExists(agent.username)
        if (conversationId) {
            chatScreen(conversationId, agent.name!)
        } else {
            conversationId = await onCreateConversation([agent.username])
            chatScreen(conversationId, agent.name!)
        }

        dispatch(selectConversation(conversationId))
    }

    useEffect(() => {
        dispatch(getAgents())

        setTimeout(() => {
            input?.current?.focus() //Focus on the input after the slide has fully opened.
        }, 500)

        // eslint-disable-next-line
    }, [dispatch])

    const onRefresh = () => {
        dispatch(getAgents())
    }

    useInterval(onRefresh, 10000)

    const onRouting = (routingProfileID?: string) => {
        dispatch(
            setAgentsFilter({
                routingProfileIDs: routingProfileID,
            }),
        )
        dispatch(getAgents())
    }

    const onHierarchySelect = (data?: IHierarchyFilter) => {
        dispatch(
            setAgentsFilter({
                hierarchyStructure: data?.hierarchyStructureIDs.join('/'),
            }),
        )
        dispatch(getAgents())
    }

    const chatScreen = (conversationId: string, name: string) => {
        setNewConversationName(name)
        setNewConversationId(conversationId)
    }

    const backScreen = () => {
        setNewConversationId(undefined)
    }

    const sortByAgentState = (theArray: any) => {
        const sortedStates = states.filter((s) => s.name !== 'Available' && s.type !== 'offline')
        sortedStates.unshift(states.find((s) => s.name === 'Available')!)
        sortedStates.push(states.find((s) => s.type === 'offline')!)

        return theArray
            .sort((a: any, b: any) => {
                const aIndex = sortedStates.findIndex((s) => s.name === a.state)
                const bIndex = sortedStates.findIndex((s) => s.name === b.state)
                return aIndex > bIndex ? 1 : -1
            })
            .filter((agent: AgentInfo) => username !== agent.username)
    }

    const sortAgents = (sortType: string) => {
        let allAgents = agents
        if (search) {
            allAgents = allAgents.filter((agent: AgentInfo) =>
                agent?.name?.toLowerCase().includes(search.toLowerCase()),
            )
        }

        if (sortType === 'name') {
            return [...allAgents]
                .sort((a: any, b: any) => {
                    return (a.name?.toLowerCase() ?? '') > (b.name?.toLowerCase() ?? '') ? 1 : -1
                })
                .filter((agent: AgentInfo) => username !== agent.username)
        } else {
            const available = sortByAgentState(
                allAgents.filter(
                    (agent: AgentInfo) =>
                        agent.state === 'Available' && agent.call_state !== 'MISSED',
                ),
            )
            const missed = sortByAgentState(
                allAgents.filter((agent: AgentInfo) => agent.call_state === 'MISSED'),
            )
            const online = [...available, ...missed]
            const offline = sortByAgentState(
                allAgents.filter((agent: AgentInfo) => agent.state !== 'Available'),
            )
            return [...online, ...offline]
        }
    }

    const renderTab = (tabTitle: string, sortType: string) => {
        return (
            <Tab title={tabTitle}>
                {agentsError ? (
                    <div className="agents-error">
                        There was an error <br />
                        <Button elastic onClick={getAgents}>
                            <u>Try again</u>
                        </Button>
                    </div>
                ) : agentsLoading && !agents.length ? (
                    <div className="agents-loading">
                        <BeatLoader color="#212121" />
                    </div>
                ) : (
                    sortAgents(sortType).map((agent) => (
                        <div className="agent-status" key={agent.agent_id}>
                            <div className="agent-details">
                                <span className="agent-details-name">
                                    {agent.name || agent.username.split(':').slice(-1)[0]}
                                </span>
                                {agent.state_start_timestamp && (
                                    <span className="agent-details-time">
                                        <Status
                                            isAvailable={
                                                agent.state === 'Available' &&
                                                agent.call_state !== 'MISSED'
                                            }
                                            statusName={
                                                agent.call_state === 'ENDED'
                                                    ? 'After contact work'
                                                    : agent.call_state === 'MISSED'
                                                      ? 'Missed Call'
                                                      : agent.on_call
                                                        ? 'On contact'
                                                        : agent.state
                                            }
                                        />
                                        <TimeInState
                                            beenInStateFor={
                                                agent.on_call || agent.call_state === 'MISSED'
                                                    ? agent.call_state_start_timestamp
                                                    : agent.state_start_timestamp
                                            }
                                        />
                                    </span>
                                )}
                            </div>
                            {hasInternalMessaging && (
                                <Button elastic onClick={() => createConversation(agent)}>
                                    <img src={ChatIcon} alt="Chat" title="Chat" />
                                </Button>
                            )}
                        </div>
                    ))
                )}
            </Tab>
        )
    }

    return (
        <ViewEvent
            type="interface"
            eventData={{
                feature: AppFeatures.INTERNAL_MESSAGING,
                label: 'View list of agents',
            }}
        >
            <>
                {newConversationId ? (
                    <ChatWindow
                        conversationId={newConversationId}
                        conversationName={newConversationName}
                        onBack={backScreen}
                        conversationUnread={0}
                    />
                ) : (
                    <aside title="Agent Directory" className="agents">
                        <div className="agents-filterdiv">
                            <div className="agents-filters">
                                <Input
                                    ref={input}
                                    name="Agent search"
                                    placeholder="Search agents..."
                                    onChange={(val: any) => setSearch(val)}
                                    value={search}
                                    className="agents-search-input"
                                />
                            </div>

                            <div className="agents-filters">
                                <HierarchiesDropdown
                                    onClear={onHierarchySelect}
                                    onSelect={onHierarchySelect}
                                    selectParents
                                    autoValue
                                    selectedHierarchyID={agentsFilter?.hierarchyStructure}
                                />
                            </div>

                            <div className="agents-filters">
                                <RoutingProfileDropdown
                                    blankOption
                                    onSelect={(option) => onRouting(option?.Id)}
                                    selectedRoutingProfileID={agentsFilter?.routingProfileIDs}
                                />
                            </div>
                        </div>

                        <Tabs>
                            {renderTab('Sort by name', 'name')}
                            {renderTab('Sort by status', 'status')}
                        </Tabs>
                    </aside>
                )}
            </>
        </ViewEvent>
    )
}

function mapStateToProps(state: RootState) {
    return {
        ...state.agents,
        routingProfile: state.user?.routingProfile,
        states: state.user?.states ?? [],
        agentExpiry: state.app?.appConfig.agentExpiry,
        userHierarchy: state.settings?.userHierarchy,
    }
}

export default connect(mapStateToProps)(Agents)

import { emojis } from '@missionlabs/smartagent-app-components/dist/WYSIWYG'
import Images from 'components/Editor/images/index'
import React, { useEffect, useRef, useState } from 'react'
import './emoji-picker.scss'

export interface Props {
    onSelectEmoji: (emoji: string) => void
}

const EmojiPicker: React.FC<Props> = ({ onSelectEmoji }) => {
    const [modalVisible, setModalVisible] = useState(false)

    const emojiPicker = useRef<HTMLDivElement>(null)

    const onSelect = (emoji: string) => {
        onSelectEmoji(emoji)
        setModalVisible(false)
    }

    useEffect(() => {
        function closeIfEscapeKeyPressed(e: KeyboardEvent) {
            if (e.key === 'Escape') {
                setModalVisible(false)
            }
        }

        document.addEventListener('keydown', closeIfEscapeKeyPressed)

        return () => document.removeEventListener('keydown', closeIfEscapeKeyPressed)
    }, [])

    useEffect(() => {
        function closeIfClickOutsidePicker(event: MouseEvent) {
            if (emojiPicker.current && !emojiPicker.current.contains(event.target as Node)) {
                setModalVisible(false)
            }
        }

        document.addEventListener('mousedown', closeIfClickOutsidePicker)

        return () => document.removeEventListener('mousedown', closeIfClickOutsidePicker)
    }, [emojiPicker])

    return (
        <div
            className="sa-emoji-picker rdw-emoji-wrapper"
            ref={emojiPicker}
            aria-haspopup="true"
            aria-expanded={modalVisible}
            title="Emojis"
        >
            <SelectEmojiButton onClick={() => setModalVisible((isVisible) => !isVisible)} />
            {modalVisible && (
                <div className="rdw-emoji-modal above">
                    {emojis.map((emoji, i) => (
                        <Emoji
                            i={i}
                            key={emoji.toString()}
                            emoji={emoji}
                            onSelectEmoji={onSelect}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

const SelectEmojiButton = ({ onClick }: { onClick: () => void }) => (
    <button
        type="button"
        aria-label="Select emoji"
        onClick={onClick}
        className="sa-editor-toolbar rdw-option-wrapper select-emoji"
    >
        <img src={Images.Emoji} alt="Select emoji" />
    </button>
)

const Emoji = ({
    emoji,
    onSelectEmoji,
    i,
}: {
    emoji: string
    onSelectEmoji: Props['onSelectEmoji']
    i: number
}) => {
    return (
        <span
            aria-label={`emoji-${i}`}
            onClick={() => onSelectEmoji(emoji)}
            className="rdw-emoji-icon"
        >
            {emoji}
        </span>
    )
}

export default EmojiPicker

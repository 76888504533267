import { useDispatch } from 'react-redux'
import { updateUserAnnouncement } from 'store/announcement/announcement.actions'
import useAnnouncements from './useAnnouncements'

interface SystemMessage {
    type: 'announcement' | 'error'
    title: string
    message?: string
    rawMessage?: string
    action: string
    onAction: () => void
}

function useSystemMessages() {
    let systemMessages: SystemMessage[] = []

    const announcements = useAnnouncements()

    const dispatch = useDispatch()

    systemMessages = [...(announcements?.unreadUserAnnouncements ?? [])]
        .sort((a, b) => a.created - b.created)
        .map((ua) => ({
            type: 'announcement',
            title: ua.title,
            message: ua.message,
            rawMessage: ua.messageHTML,
            action: 'OK',
            onAction: () => {
                dispatch(
                    updateUserAnnouncement(ua.ID, {
                        read: 1,
                    }),
                )
            },
        }))

    // TODO: Add errors here

    return systemMessages
}

export default useSystemMessages

import type { Middleware } from '@reduxjs/toolkit'
import { Configuration, UserAgentApplication } from 'msal'
import { initAppSuccess } from 'store/app/app.reducer'
import RootState from 'store/state'
import { authError, authenticateUser } from 'store/user/user.actions'
import { login } from 'store/user/user.reducer'
import { getHashParameterByName, getParameterByName, isAnyAction } from 'utils'

let msal: UserAgentApplication

const initMSAL = async (config: any) => {
    const msalConfig: Configuration = {
        auth: {
            redirectUri: getRedirectUri(),
            ...config.auth /*{ clientId: '7a8174fa-8475-4e35-a981-8de92ca70512', //This is your client IDauthority:'https://login.microsoftonline.com/3139cca3-4376-41a5-b83b-c6efc8d329e1', //This is your tenant info},*/,
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: true,
        },
    }

    //If we're not in prod that calculate redirect uri
    if (process.env.NODE_ENV !== 'production') {
        msalConfig.auth.redirectUri = getRedirectUri()
    }

    msal = new UserAgentApplication(msalConfig)
}

const getRedirectUri = () => {
    const { protocol, hostname, search, port } = window.location
    return protocol + '//' + hostname + `${port ? `:${port}` : ''}` + search
}

function isActiveDirectory(identityManagement?: string) {
    return identityManagement === 'ad'
}

const adMiddleware: Middleware<{}, RootState> = (store) => (next) => async (action) => {
    if (!isAnyAction(action)) return

    switch (action.type) {
        case initAppSuccess.type: {
            const { payload } = action
            if (isActiveDirectory(payload.identityManagement) && payload.ad?.type !== 'openid') {
                initMSAL(payload.ad)
            }
            return next(action)
        }
        case login.type: {
            const identityManagement = store.getState().app.identityManagement
            if (!isActiveDirectory(identityManagement)) {
                return next(action)
            }
            next(action)
            const { ad } = store.getState().app
            if (!ad) {
                return store.dispatch(
                    authError(
                        'There is a problem with the active directory setup, please contact your administrator',
                    ),
                )
            }
            if (ad.type === 'code') {
                const code = getParameterByName('code')
                const error = getParameterByName('error')
                if (code) {
                    store.dispatch(authenticateUser(code, true) as any)
                    //Remove code param from query string so on refresh we don't use it again
                    window.history.replaceState(null, '', ad.auth.redirectUri || getRedirectUri())
                } else if (error) {
                    store.dispatch(
                        authError(
                            getParameterByName('error_description') || 'Oops something went wrong',
                        ),
                    )
                } else {
                    const url =
                        ad.auth.authority +
                        `/oauth2/authorize?
                    client_id=${ad.auth.clientId}&
                    response_type=code&
                    redirect_uri=${ad.auth.redirectUri || getRedirectUri()}&
                    response_mode=query&resource=https://graph.microsoft.com`
                    //Redirect to ad
                    window.location.href = url
                }
                return
            } else if (ad.type === 'openid') {
                const code = getHashParameterByName('code')
                const error = getHashParameterByName('error')
                if (code) {
                    store.dispatch(authenticateUser(code, true) as any)
                    //Remove code param from query string so on refresh we don't use it again
                    window.history.replaceState(null, '', ad.auth.redirectUri || getRedirectUri())
                } else if (error) {
                    store.dispatch(
                        authError(
                            getHashParameterByName('error_description') ||
                                'Oops something went wrong',
                        ),
                    )
                } else {
                    const url =
                        ad.auth.authority +
                        '/oauth2/v2.0/authorize?' +
                        'scope=openid&' +
                        `client_id=${ad.auth.clientId}&` +
                        'response_type=code&' +
                        `redirect_uri=${ad.auth.redirectUri || getRedirectUri()}&` +
                        'response_mode=fragment'
                    //Redirect to ad
                    window.location.href = url
                }
                return
            }
            const requestObj = {
                scopes: ['user.read'],
            }
            if (!msal) {
                console.log('no msal')
                return
            }
            if (msal.getAccount()) {
                try {
                    const token = await msal.acquireTokenSilent(requestObj)
                    return store.dispatch(authenticateUser(token.accessToken, true) as any)
                } catch (ex) {
                    console.log('not logged in so show popup')
                }
            }
            try {
                if (msal.getLoginInProgress()) {
                    console.log('already in progress')
                    return
                }
                await msal.loginPopup(requestObj)
                const token = await msal.acquireTokenSilent(requestObj)
                return store.dispatch(authenticateUser(token.accessToken, true) as any)
            } catch (ex) {
                if (ex.errorCode === 'user_cancelled') {
                    return store.dispatch(authError('User cancelled login'))
                }
                console.log('error from ad', ex)
                return store.dispatch(
                    authError(
                        'There is a problem with the active directory setup, please contact your administrator',
                    ),
                )
            }
        }
        default:
            return next(action)
    }
}

export default adMiddleware

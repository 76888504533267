import { DirectorySourceTypes, IEmailAddress } from '../directory.state'
import {
    ITeamsContactResponse,
    ITeamsRecord,
    ITeamsStatusInfo,
    ITeamsStatusResponse,
} from './state'

export const teamsContactParser = (contact: ITeamsContactResponse): ITeamsRecord => ({
    sourceType: DirectorySourceTypes.TEAMS,
    firstName: contact.givenName,
    lastName: contact.surname,
    phoneNumbers: mapTeamsPhones(contact.businessPhones, contact.mobilePhone),
    emailAddresses: mapTeamsEmailAddress(contact.mail),
    id: contact.id,
    role: contact.jobTitle,
    officeLocation: contact.officeLocation,
    displayName: contact.displayName,
    userPrincipalName: contact.userPrincipalName,
    preferredLanguage: contact.preferredLanguage,
    department: contact.department,
})

export const teamsParser = (contacts: ITeamsContactResponse[]) =>
    contacts.reduce((acc: ITeamsRecord[], contact) => {
        if (!contact.surname && !contact.givenName) {
            return acc
        }

        return [...acc, teamsContactParser(contact)]
    }, [])

const mapTeamsPhones = (
    businessPhones: string[] | null | undefined,
    mobilePhone: string | null | undefined,
) => {
    const phones = []

    if (Array.isArray(businessPhones) && businessPhones.length > 0) {
        businessPhones.forEach((phoneNumber) => {
            phones.push({ type: 'work', value: phoneNumber })
        })
    }

    if (mobilePhone) {
        phones.push({ type: 'mobile', value: mobilePhone })
    }

    return phones
}

const mapTeamsEmailAddress = (email: string | null | undefined): IEmailAddress[] => {
    const emails: IEmailAddress[] = []

    if (email) {
        return [
            {
                type: 'email',
                value: email,
            },
        ]
    }

    return emails
}

export const teamsStatusesParser = (teamsStatuses: ITeamsStatusResponse[]) => {
    const mappedStatuses = teamsStatuses.reduce((acc: ITeamsStatusInfo, statusData) => {
        const id = statusData.id

        acc[id] = {
            availability: statusData.availability,
        }

        return acc
    }, {})

    return mappedStatuses
}

import { makeRequest } from 'services/api'
import { IChatConfig, IInstanceConfig } from 'store/channelSettings/chat/chat.state'
import { IChatQuickReply } from 'store/chat/chat.state'
import { EndpointConfigurationForm } from 'views/AdminSettings/ChannelSettings/ChatTab/AddContactEndpoint'

import { SocialChatOutboundMessage } from '@missionlabs/smartagent-service-chat/dist/types/socialChatMessage'

export async function getChatQuickReplies(companyID: string, facia: string, token: string) {
    const response = await makeRequest<IChatQuickReply[]>({
        url: `/chat-service/companies/${companyID}/facia/${facia}/quickreplies`,
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

export async function getWebsocketUrl(companyID: string) {
    const res = await makeRequest<{ websocketUrl: string; apiKey: string }>({
        url: `/chat-service/companies/${companyID}/websocketurl`,
    })

    return res.data
}

export async function postSocialChatMessage(socialChatMessage: SocialChatOutboundMessage) {
    await makeRequest({
        url: `/chat-service/companies/{companyID}/instance/{instanceID}/social-message`,
        method: 'post',
        data: socialChatMessage,
    })
}

export async function getS3AttachmentUrl(s3Key: string, companyID: string, facia?: string) {
    const res = await makeRequest<{ objectUrl: string }>({
        url: `/chat-service/companies/{companyID}/facia/${facia || companyID}/attachment/${s3Key}`,
    })

    return res.data.objectUrl
}

export async function getChatChannelConfig(companyId: string, facia: string) {
    const res = await makeRequest<IChatConfig>({
        url: `/chat-service/companies/${companyId}/facia/${facia}/chatChannelConfig`,
    })

    return res.data
}

export async function putChatChannelConfig(
    companyId: string,
    facia: string,
    previousName: string,
    data: IInstanceConfig,
    token: string,
) {
    console.log('putting that sweet config in', data)

    const res = await makeRequest<IChatConfig>({
        url: `/chat-service/companies/${companyId}/facia/${facia}/chatInstanceConfig/${previousName}`,
        method: 'put',
        data,
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return res.data
}

export async function putCustomerConfig(
    companyId: string,
    facia: string,
    data: Partial<EndpointConfigurationForm>,
    token: string,
    action: 'create' | 'update',
) {
    const res = await makeRequest<IChatConfig>({
        url: `/chat-service/companies/${companyId}/facia/${facia}/chatconfig/${action}`,
        method: 'put',
        data,
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return res.data
}

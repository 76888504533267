import type { Middleware } from '@reduxjs/toolkit'
import { appError, initAppSuccess } from 'store/app/app.reducer'
import RootState from 'store/state'
import { userAuthError, userLoaded } from 'store/user/user.reducer'

import Logger from '../../logger'

import { isAnyAction } from 'utils'

let actionLog: string[] = []

const LoggerMiddleware: Middleware<{}, RootState> = (store) => (next) => async (action) => {
    if (!isAnyAction(action)) return

    actionLog = [action.type, ...actionLog.slice(0, 9)]
    switch (action.type) {
        case userLoaded.type:
            Logger.addUser({
                id: action.payload.agentID,
                username: `${store.getState().app.ID}:${action.payload.username}`,
            })

            return next(action)

        case initAppSuccess.type:
            Logger.addCompanyId(action.payload.ID)
            return next(action)

        case appError.type:
            Logger.error(action.type, store.getState())
            return next(action)

        case userAuthError.type:
            Logger.warning(action.type, { actionLog })
            return next(action)

        default:
            return next(action)
    }
}

export default LoggerMiddleware

import React, { useState } from 'react'

import { Button } from '@missionlabs/smartagent-app-components'
import type { IContactAudit } from '@missionlabs/smartagent-service-contact/dist/lib/entities/contact-audit'
import type { IAutomatedActionAudit } from '@missionlabs/smartagent-service-thread/dist/entities/action-audit'

import TaskAuditDialog from './TaskAuditDialog'

import './task-audit.scss'

export interface Props {
    automatedRulesAudit?: IAutomatedActionAudit[]
    queuedTasksAudit?: IContactAudit[]
    hasAutoReply: boolean
}

const TaskAudit: React.FC<Props> = ({ automatedRulesAudit, queuedTasksAudit, hasAutoReply }) => {
    const [openTasksAuditPopup, setOpenTasksAuditPopup] = useState<boolean>(false)

    return (
        <div className="task-audit">
            {!automatedRulesAudit || !queuedTasksAudit ? (
                <>Loading audit data...</>
            ) : (
                <>
                    <Button onClick={() => setOpenTasksAuditPopup(true)}>View email audit: </Button>
                    Automated rules ({automatedRulesAudit.length}) - Queued tasks (
                    {queuedTasksAudit.length})
                    {openTasksAuditPopup && (
                        <TaskAuditDialog
                            automatedRulesAudit={automatedRulesAudit}
                            queuedTasksAudit={queuedTasksAudit}
                            hasAutoReply={hasAutoReply}
                            setOpenTasksAuditPopup={setOpenTasksAuditPopup}
                        />
                    )}
                </>
            )}
        </div>
    )
}

export default TaskAudit

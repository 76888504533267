import React, { lazy } from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'
import { AppFeatures, AppSubFeatures } from 'store/app/app.features'
import useHasFeature from '../../hooks/useHasFeature'

interface Props {
    path: string | string[]
    exact?: boolean
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
    appFeature: AppFeatures
    appSubFeatures?: AppSubFeatures | AppSubFeatures[]
}

const accessDeniedMessage = lazy(() => import('components/AccessDeniedMessage'))

const RouteWithPermissionsCheck: React.FC<Props> = ({
    path,
    exact,
    component,
    appFeature,
    appSubFeatures,
}) => {
    const hasFeature = useHasFeature()
    const accessAllowed = hasFeature(appFeature, appSubFeatures)

    const content = accessAllowed ? component : accessDeniedMessage
    return <Route path={path} component={content} exact={exact ? true : false} />
}

export default RouteWithPermissionsCheck

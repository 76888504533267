import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import classNames from "classnames";
import { M as MarkdownWrapper } from "./MarkdownWrapper-BQ-Hbyxf.mjs";
const CardChatMessage = ({ ariaId, cardsMsg, sendMessage, isMarkdownEnabled }) => {
  const [disabled, setDisabled] = useState(false);
  const { collapseWhenSelected } = cardsMsg;
  return /* @__PURE__ */ jsx(
    "div",
    {
      id: ariaId,
      className: classNames({
        "sa-chat-message-cards": true,
        "collapse-when-selected": !!collapseWhenSelected,
        disabled: !!disabled
      }),
      children: cardsMsg.cards.map((card, i) => /* @__PURE__ */ jsxs(
        "div",
        {
          className: classNames({
            "sa-chat-message-cards-item": true,
            single: cardsMsg.cards.length === 1,
            disabled: !!disabled
          }),
          children: [
            !!card.title && /* @__PURE__ */ jsx("h2", { tabIndex: 0, children: card.title }),
            !!card.summary && /* @__PURE__ */ jsx("div", { className: "summary", id: (card == null ? void 0 : card.buttons) && "short", tabIndex: 0, children: isMarkdownEnabled ? /* @__PURE__ */ jsx(MarkdownWrapper, { children: card.summary }) : /* @__PURE__ */ jsx("p", { children: card.summary }) }),
            !!card.imgURL && /* @__PURE__ */ jsx("img", { src: card.imgURL, alt: card.title || "", title: card.title, tabIndex: 0 }),
            /* @__PURE__ */ jsx("ul", { children: card.buttons ? card.buttons.map((button, i2) => /* @__PURE__ */ jsx("li", { children: button.url && !disabled ? /* @__PURE__ */ jsx(
              "a",
              {
                "aria-label": "Visit website",
                title: button.title,
                rel: "noopener noreferrer",
                target: "_blank",
                href: button.url,
                children: /* @__PURE__ */ jsx("p", { children: button.title })
              }
            ) : /* @__PURE__ */ jsx(
              "button",
              {
                disabled,
                "aria-label": button.title,
                title: button.title,
                onClick: () => {
                  sendMessage(button.payload, button.title);
                  setDisabled(true);
                },
                children: /* @__PURE__ */ jsx("p", { children: button.title })
              }
            ) }, `${button.title}-${i2}`)) : null })
          ]
        },
        `${card.title}-${i}`
      ))
    }
  );
};
export {
  CardChatMessage as default
};

import { MultiStateCheckbox } from '@missionlabs/smartagent-app-components'
import { CheckState } from '@missionlabs/smartagent-app-components/dist/MultiStateCheckbox'
import classNames from 'classnames'
import useSettings from 'hooks/redux/useSettings'
import React from 'react'
import { DPACheck as DPACheckState } from '../../../../store/contact/contact.state'
import './dpaCheck.scss'

interface Props {
    check: DPACheckState
    onChange: (checkState: CheckState, DPA: DPACheckState) => void
    isAgentCheck?: boolean
    readOnly?: boolean
}

const DPACheck: React.FC<Props> = ({ check, isAgentCheck, readOnly, onChange }) => {
    const { settings } = useSettings()

    const getCheckState = (): CheckState => {
        // Agent check acts differently to defualt checks
        if (isAgentCheck) return !!check.passed ? CheckState.checked : CheckState.blank

        switch (check.passed) {
            case true:
                return CheckState.checked
            case null:
                return CheckState.pending
            case 'manual-check':
                return CheckState.blank
            case false:
            default:
                return CheckState.unChecked
        }
    }

    return (
        <div
            className={classNames({
                'dpa-check-chip row middle between': true,
                'dpa-check-green': check.passed === true,
                'dpa-check-red': check.passed === false,
                'dpa-check-amber': check.passed === null,
                'dpa-check-agent': !!isAgentCheck || check.passed === 'manual-check',
            })}
        >
            <span
                className={classNames('chip-title', {
                    'agent-title': !!isAgentCheck || check.passed === 'manual-check',
                })}
            >
                {check.label}
                {settings?.dpa?.showAnswers && <span>:&nbsp;{check.answer || 'Not answered'}</span>}
            </span>
            <MultiStateCheckbox
                square
                readOnly={settings?.dpa?.showAnswers ? false : readOnly}
                checkState={getCheckState()}
                ariaCheckLabel={check.label}
                onChange={(e) => onChange(e, check)}
            />
        </div>
    )
}

export default DPACheck

import { DataField, Tag } from '@missionlabs/smartagent-app-components'
import React from 'react'
import { IContactContext, withContactConsumerContext } from 'widgets/contexts/contact'
import BarChart from '../../components/Charts/BarChart'

export interface IComprehension {
    keywords: string[]
    sentiment: ISentiment
    context: IContactContext
}

interface ISentiment {
    Sentiment: string
    SentimentScore: ISentimentScore
    Source?: string
}
interface ISentimentScore {
    Positive: number
    Negative: number
    Neutral: number
    Mixed: number
}

const ComprehensionInfo: React.FC<IComprehension> = ({ keywords, sentiment, context }) => {
    const { customerSentimentPercentages } = context

    const chartData = [
        {
            color: 'red',
            percent: customerSentimentPercentages?.negative,
            label: 'Negative',
        },
        {
            color: 'green',
            percent: customerSentimentPercentages?.positive,
            label: 'Positive',
        },
        {
            color: 'gray',
            percent: customerSentimentPercentages?.neutral,
            label: 'Neutral',
        },
    ]

    return (
        <>
            <div data-testid="container" className="row call-comprehension">
                <DataField title="Keywords">
                    <div data-testid="keywords" className="row">
                        {keywords.length
                            ? keywords.map((keyword) => (
                                  <Tag key={keyword} type="grey">
                                      {keyword}
                                  </Tag>
                              ))
                            : 'None detected'}
                    </div>
                </DataField>
                <DataField
                    title={`${sentiment.Source === 'aws-contactlens' ? 'Customer Sentiment' : 'Sentiment'}`}
                >
                    <div data-testid="sentiment" className="sentiment">
                        <div className="call-chart">
                            {customerSentimentPercentages && (
                                <BarChart data={chartData} showKey={true} />
                            )}
                        </div>
                    </div>
                </DataField>
            </div>
        </>
    )
}

export default withContactConsumerContext(ComprehensionInfo)

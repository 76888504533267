import classnames from 'classnames'
import React from 'react'
import { ITeamsRecord } from 'store/directory/teams/state'

import styles from './styles.module.scss'

interface Props {
    statusInfo?: ITeamsRecord['statusInfo']
}

type statusConfig = {
    [key: string]: {
        statusName: string
        statusColor: string
    }
}

const statusConfig: statusConfig = {
    Available: {
        statusName: 'Available',
        statusColor: 'green',
    },
    AvailableIdle: {
        statusName: 'Available',
        statusColor: 'green',
    },
    Busy: {
        statusName: 'Busy',
        statusColor: 'red',
    },
    BusyIdle: {
        statusName: 'Busy',
        statusColor: 'red',
    },
    DoNotDisturb: {
        statusName: 'Do not disturb',
        statusColor: 'red',
    },
    BeRightBack: {
        statusName: 'Be right back',
        statusColor: 'yellow',
    },
    Away: {
        statusName: 'Away',
        statusColor: 'yellow',
    },
    Offline: {
        statusName: 'Offline',
        statusColor: 'grey',
    },
}

const TeamsContactStatus: React.FC<Props> = ({ statusInfo }) => {
    if (!statusInfo?.availability || !statusConfig[statusInfo.availability]) return null
    const { statusName, statusColor } = statusConfig[statusInfo.availability]
    const statusIndicatorClassName = classnames(
        styles.statusIndicator,
        styles[`statusIndicator--${statusColor}`],
    )

    return (
        <div data-testid="status-info" className={styles.statusInfo}>
            <div className={statusIndicatorClassName} />
            <div className={styles.statusName}>{statusName}</div>
        </div>
    )
}

export default TeamsContactStatus

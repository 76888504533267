import { getParameterByName } from 'utils'

import { combineReducers, configureStore, EnhancedStore, Middleware } from '@reduxjs/toolkit'

import agentsReducer from './agents/agents.reducer'
import agentsAssistanceReducer from './agentsAssistance/agentsAssistance.reducer'
import announcementReducer from './announcement/announcement.reducer'
import appReducer from './app/app.reducer'
import authReducer from './auth/auth.reducer'
import callReducer from './call/call.reducer'
import callLogReducer from './callLog/callLog.reducer'
import callV2Reducer from './callV2/callV2.reducer'
import campaignManagementReducer from './campaignManagement/campaignManagement.reducer'
import caseReducer from './case/case.reducer'
import channelSettingsReducer from './channelSettings/reducer'
import chatReducer from './chat/chat.reducer'
import contactReducer from './contact/contact.reducer'
import contactHistoryReducer from './contactHistory/contactHistory.reducer'
import contactsReducer from './contacts/contacts.reducer'
import contactSearchReducer from './contactSearch/contactSearch.reducer'
import customMessagingReducer from './customMessaging/customMessaging.reducer'
import dataRetentionReducer from './dataRetetion/dataRetention.reducer'
import directoryReducer from './directory/directory.reducer'
import globalReducer from './global/global.reducer'
import internalMessagingReducer from './internal-messaging/internal-messaging.reducer'
import kendraPluginReducer from './kendraPlugin/kendraPlugin.reducer'
import metricsReducer from './metrics/metrics.reducer'
import ad from './middleware/ad'
import audio from './middleware/audio'
import bchat from './middleware/bchat'
import browserNotification from './middleware/browserNotification'
import c4c from './middleware/c4c'
import ccp from './middleware/ccp'
import cognito from './middleware/cognito'
import internalMessaging from './middleware/internalMessaging'
import jabra from './middleware/jabra'
import kumodi from './middleware/kumodi'
import logger from './middleware/logger'
import okta from './middleware/okta'
import parentMessenger from './middleware/parentMessenger'
import plugins from './middleware/plugins'
import shortcuts from './middleware/shortcuts'
import notificationReducer from './notification/notification.reducer'
import qaReducer from './qa/qa.reducer'
import queuedTasksReducer from './queuedTasks/queuedTasks.reducer'
import settingsReducer from './settings/settings.reducer'
import RootState from './state'
import taskReducer from './tasks/tasks.reducer'
import userReducer from './user/user.reducer'
import webHIDReducer from './webHID/webHID.reducer'
import websocketReducer from './websocket/websocket.reducer'
import websocketCompanionReducer from './websocketCompanion/websocketCompanion.reducer'

const middlewares: Middleware[] = [
    ...ccp,
    ad,
    audio,
    bchat,
    browserNotification,
    cognito,
    internalMessaging,
    jabra,
    kumodi,
    logger,
    okta,
    parentMessenger,
    plugins,
    shortcuts,
]

//Add in integration middlewares
if (getParameterByName('integration') === 'c4c') {
    middlewares.push(c4c)
}

const rootReducer = {
    agents: agentsReducer,
    agentsAssistance: agentsAssistanceReducer,
    announcements: announcementReducer,
    app: appReducer,
    auth: authReducer,
    call: callReducer,
    callLog: callLogReducer,
    callV2: callV2Reducer,
    campaignManagement: campaignManagementReducer,
    case: caseReducer,
    channelSettings: channelSettingsReducer,
    chat: chatReducer,
    contact: contactReducer,
    contactHistory: contactHistoryReducer,
    contacts: contactsReducer,
    contactSearch: contactSearchReducer,
    customMessaging: customMessagingReducer,
    dataRetention: dataRetentionReducer,
    directory: directoryReducer,
    global: globalReducer,
    internalMessaging: internalMessagingReducer,
    kendraPlugin: kendraPluginReducer,
    metrics: metricsReducer,
    notification: notificationReducer,
    qa: qaReducer,
    queuedTasks: queuedTasksReducer,
    settings: settingsReducer,
    tasks: taskReducer,
    user: userReducer,
    webHIDState: webHIDReducer,
    websocket: websocketReducer,
    websocketCompanion: websocketCompanionReducer,
}

export type RootReducer = typeof rootReducer

export function createStore(initialState?: RootState): EnhancedStore {
    const store = configureStore({
        preloadedState: initialState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
                immutableCheck: false,
            }).concat(middlewares),
        devTools: { serialize: true },
        reducer: combineReducers(rootReducer),
    })

    store.subscribe(() => {
        if (store.getState().app?.error) return

        const { auth, settings, callLog, metrics } = store.getState()
        const state = {
            auth: {
                logout: auth.logout,
                refreshToken: auth.refreshToken,
            },
            metrics: {
                filters: metrics.filters,
                monitoringAgent: metrics.monitoringAgent,
            },
            callLog: callLog,
            settings: settings
                ? {
                      ringingDevice: settings.ringingDevice,
                      userHasEnabledWebHID: settings.userHasEnabledWebHID,
                  }
                : undefined,
        }

        localStorage.setItem('state', JSON.stringify(state))
    })

    return store
}

import { ViewEvent } from '@missionlabs/analytics-react'
import { Loader } from 'components'
import { EditorState } from 'draft-js'
import useApp from 'hooks/redux/useApp'
import useChat from 'hooks/redux/useChat'
import { AppFeatures } from 'hooks/useHasFeature'
import { ReactComponent as QuickReplyIcon } from 'images/icon-chat-quick-reply.svg'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getQuickReplies } from 'store/chat/chat.actions'
import { SocialMediaPlatform } from 'store/chat/chat.utils'
import { isNilOrEmpty } from 'utils'
import { sendEvent } from '../../analytics/GoogleAnalytics'
import styles from './QuickReplies.module.scss'
import QuickRepliesPopup from './QuickRepliesPopup'

interface Props {
    channel:
        | 'Chat'
        | 'Tasks'
        | 'QueuedTasksRichText'
        | 'QueuedTasksPlainText'
        | 'OutboundEmail'
        | 'OutboundSMS'
        | SocialMediaPlatform
    title?: boolean
    analyticsEnabled?: boolean
    messageSent?: boolean
    qrChannel?: string
    subChannel?: string
    setMessageSent?: (bool: boolean) => void
    setIsQuickReplyOn?: (bool: boolean) => void
    outboundEmailReply?: EditorState
    setOutboundEmailReply?: Dispatch<SetStateAction<EditorState>>
    message?: string
    setMessage?: Dispatch<SetStateAction<string>> | ((message: string) => void)
}

export enum QuickReplyChannels {
    SOCIAL = 'FACEBOOK,TWITTER,INSTAGRAM,TRUSTPILOT',
    MAIL = 'EMAIL,WEBFORM',
    ALL = 'ALL',
}

const QuickReplies = ({
    channel,
    title,
    analyticsEnabled,
    messageSent,
    qrChannel,
    subChannel,
    setMessageSent,
    setIsQuickReplyOn,
    setOutboundEmailReply,
    outboundEmailReply,
    message,
    setMessage,
}: Props) => {
    const { quickReplies, quickRepliesLoading } = useChat() || {}
    const dispatch = useDispatch()
    const [isPopupShown, setIsPopupShown] = useState(false)
    const [isQuickReply, setIsQuickReply] = useState<boolean>(false)
    const app = useApp()
    const ID = app?.ID
    const isWebChat = qrChannel === undefined && channel === 'Chat'

    const quickRepliesForChannel = quickReplies
        ? quickReplies[isWebChat ? 'WEBCHAT' : qrChannel || 'ALL'] || []
        : []

    useEffect(() => {
        if (!quickRepliesLoading) {
            const channelsParam = isWebChat ? 'WEBCHAT' : qrChannel
            dispatch(getQuickReplies(channelsParam))
        }
    }, [qrChannel])

    const sendGoogleAnalyticsEvent = (eventName: string): void => {
        if (analyticsEnabled) {
            sendEvent({
                eventName,
                eventParams: {
                    company_name: ID,
                    environment: process.env.REACT_APP_ENV,
                    channel: qrChannel || '',
                    subChannel: subChannel || '',
                },
            })
        }
    }

    useEffect(() => {
        if (messageSent && isQuickReply) sendGoogleAnalyticsEvent('use_qr_inserted_sent')
        setIsQuickReply(false)
        if (setMessageSent) setMessageSent(false)
    }, [messageSent])

    const renderContent = () => {
        if (quickRepliesLoading || quickRepliesLoading === undefined) {
            return <Loader />
        }

        if (isNilOrEmpty(quickRepliesForChannel)) {
            return <p>No categories configured</p>
        }

        return (
            <ViewEvent
                type="interface"
                eventData={{
                    feature: AppFeatures.QUICK_REPLY_MANAGEMENT,
                    label: 'View quick replies pop up',
                }}
            >
                <QuickRepliesPopup
                    quickReplies={quickRepliesForChannel!}
                    onClose={() => setIsPopupShown(false)}
                    channel={channel}
                    setIsQuickReply={setIsQuickReply}
                    sendAnalyticsEvent={sendGoogleAnalyticsEvent}
                    setIsQuickReplyOn={setIsQuickReplyOn}
                    outboundEmailReply={outboundEmailReply}
                    setOutboundEmailReply={setOutboundEmailReply}
                    message={message}
                    setMessage={setMessage}
                />
            </ViewEvent>
        )
    }

    const togglePopup = () => setIsPopupShown((prevValue) => !prevValue)

    return (
        <div className={`${styles.wrapper}  sa-wysiwyg-toolbar__quick-replies`}>
            {isPopupShown && <div className={styles.popup}>{renderContent()}</div>}
            <button
                type="button"
                className={`${styles.button} ${title && 'quick-replies-title'}`}
                onClick={togglePopup}
            >
                {title && 'Quick Replies'}
                <QuickReplyIcon className={styles.icon} />
            </button>
        </div>
    )
}

export default QuickReplies

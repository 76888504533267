import { useCallback, useEffect, useLayoutEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { clearUnread, setUnreadChats } from 'store/chat/chat.actions'
import { IChatMessage, ISocialChatMessage } from 'store/chat/chat.state'

import {
    SocialChatJsonMessage,
    SocialChatJsonMessageType,
} from '@missionlabs/smartagent-service-chat/dist/types/socialChatJsonMessage'

const isNearOrAtBottom = (container: HTMLElement) => {
    const scrollAllowance = 200
    const isScrollable = container.scrollHeight > container.clientHeight
    const isNearBottom =
        container.scrollTop >= container.scrollHeight - container.offsetHeight - scrollAllowance
    return !isScrollable || isNearBottom
}

const scrollToBottom = (container: HTMLElement) => {
    container.scrollTop = container.scrollHeight
}

export const useUnreadMessages = (id: string, messages: IChatMessage[], isTyping: boolean) => {
    const messagesContainer = useRef<HTMLDivElement>(null)
    const lastReadLength = useRef(messages.length)

    const dispatch = useDispatch()

    useEffect(() => {
        const container = messagesContainer.current
        if (!container) return

        const checkClearUnread = () => {
            if (isNearOrAtBottom(container) && document.hasFocus()) {
                dispatch(clearUnread(id))
                lastReadLength.current = messages.length
            }
        }

        checkClearUnread()

        container.addEventListener('scroll', checkClearUnread)
        window.addEventListener('focus', checkClearUnread)

        return () => {
            container.removeEventListener('scroll', checkClearUnread)
            window.removeEventListener('focus', checkClearUnread)
        }
    }, [messagesContainer, messages])

    // scroll to bottom if we're near bottom, otherwise set unread messages count
    useLayoutEffect(() => {
        const container = messagesContainer.current!

        if (isNearOrAtBottom(container)) return scrollToBottom(container)

        if (lastReadLength.current === messages.length) return

        const newMessageCount = messages
            .slice(lastReadLength.current)
            .filter((msg) => msg.ParticipantRole !== 'AGENT')
            .filter(
                (msg) =>
                    ((msg as ISocialChatMessage)?.content as SocialChatJsonMessage)?.type !==
                    SocialChatJsonMessageType.MESSAGE_READ,
            ).length

        dispatch(setUnreadChats({ id, unread: newMessageCount }))
    }, [messages, lastReadLength.current])

    // handle scrolling to bottom if is typing changes
    useLayoutEffect(() => {
        const container = messagesContainer.current!
        if (isNearOrAtBottom(container)) scrollToBottom(container)
    }, [isTyping])

    useLayoutEffect(() => {
        const container = messagesContainer.current!
        scrollToBottom(container)
    }, [id])

    const onClickNewMessageIndicator = useCallback(() => {
        const container = messagesContainer.current!
        scrollToBottom(container)
    }, [])

    return {
        messagesContainer,
        onClickNewMessageIndicator,
    }
}

import { useEffect } from 'react'
import { AutoSizer, CellMeasurer, CellMeasurerCache, List, ListRowProps } from 'react-virtualized'
import 'react-virtualized/styles.css'
import { IDirectoryRecord } from 'store/directory/directory.state'
import styles from './styles.module.scss'

interface Props {
    openedCardId?: string | null
    contactsList: IDirectoryRecord[]
    search?: string
    renderShortProfile: (contact: IDirectoryRecord, measure?: () => void) => JSX.Element | undefined
    clearOpenedCardId: () => void
}

const VirtualizedList = ({
    openedCardId,
    contactsList,
    search,
    renderShortProfile,
    clearOpenedCardId,
}: Props) => {
    const cache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 100,
    })

    useEffect(() => {
        cache.clearAll()
    }, [openedCardId])

    useEffect(() => {
        if (openedCardId) {
            clearOpenedCardId()
        }
    }, [search])

    const rowRenderer = ({ index, key, parent, style }: ListRowProps) => {
        const contact = contactsList[index]
        return (
            contact &&
            (contact.firstName || contact.lastName) && (
                <CellMeasurer
                    key={key}
                    cache={cache}
                    parent={parent}
                    columnIndex={0}
                    rowIndex={index}
                >
                    {({ measure }) => (
                        <div className={styles.directoryList__item} key={key} style={style}>
                            {renderShortProfile(contact, measure)}
                        </div>
                    )}
                </CellMeasurer>
            )
        )
    }

    return (
        <div className={styles.directoryList}>
            <AutoSizer>
                {({ height, width }) => (
                    <List
                        height={height}
                        width={width}
                        rowCount={contactsList.length}
                        rowHeight={cache.rowHeight}
                        rowRenderer={rowRenderer}
                        deferredMeasurementCache={cache}
                        overscanRowCount={10}
                    />
                )}
            </AutoSizer>
        </div>
    )
}

export default VirtualizedList

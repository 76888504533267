import { jsx, jsxs, Fragment } from "react/jsx-runtime";
import classNames from "classnames";
import _ReactPlayer from "react-player";
import { ClipLoader } from "react-spinners";
import Button from "./Button.mjs";
import { u as useMediaPlayback, f as format } from "./date-time-CWJXVusb.mjs";
const ReactPlayer = _ReactPlayer;
const VideoPlayer = ({
  filename,
  timestamp,
  getUrl,
  reactPlayerClassName,
  styles,
  videoHeight = "auto",
  videoWidth = "100%",
  playerWidth = "100%"
}) => {
  const {
    isLoading,
    error,
    setError,
    secondsPlayed,
    setSecondsPlayed,
    durationInSeconds,
    setDurationInSeconds,
    playbackImgInfo,
    handlePlaybackClick,
    isPlaying,
    setIsPlaying,
    mediaPlayer,
    isMuted,
    formattedTimeInfo,
    mediaUrl,
    hasEnded,
    setHasEnded,
    reactPlayerId
  } = useMediaPlayback(getUrl);
  const renderVideoTitle = () => /* @__PURE__ */ jsx("h3", { className: "heading", children: filename ?? "Video" });
  const renderPlaceholder = () => /* @__PURE__ */ jsxs("div", { className: "video placeholder", "aria-label": "video placeholder", children: [
    isLoading && /* @__PURE__ */ jsx(ClipLoader, { color: "#7E7E7E", size: 28 }),
    !!error && renderErrorMessage(),
    !isLoading && !hasEnded && !error && /* @__PURE__ */ jsx(Button, { onClick: handlePlaybackClick, "aria-label": "Start Video", disabled: isLoading, children: /* @__PURE__ */ jsx("img", { src: playbackImgInfo.largeSrc, alt: playbackImgInfo.alt }) })
  ] });
  const renderProgressInfo = () => /* @__PURE__ */ jsxs("div", { className: "seek-bar-container", children: [
    /* @__PURE__ */ jsx("div", { className: "time-elapsed", children: formattedTimeInfo.elapsed }),
    /* @__PURE__ */ jsx("div", { className: "seek-bar", children: /* @__PURE__ */ jsx(
      "div",
      {
        className: "elapsed-duration",
        style: {
          width: durationInSeconds ? `${100 * secondsPlayed / durationInSeconds}%` : 0
        }
      }
    ) }),
    /* @__PURE__ */ jsx("div", { className: "total-time", children: formattedTimeInfo.total })
  ] });
  const renderLoadingSpinner = () => /* @__PURE__ */ jsx(ClipLoader, { color: "#458DC9", size: 16 });
  const renderTimestamp = () => /* @__PURE__ */ jsx("div", { className: "timestamp-container", children: format(new Date(timestamp), { date: false, time: true }) });
  const renderErrorMessage = () => /* @__PURE__ */ jsx("p", { className: "error", children: error });
  const renderPlayPauseReplayButton = () => /* @__PURE__ */ jsx(
    Button,
    {
      onClick: handlePlaybackClick,
      "aria-label": playbackImgInfo.alt,
      disabled: !!error || isLoading,
      children: /* @__PURE__ */ jsx(
        "img",
        {
          src: playbackImgInfo.src,
          alt: playbackImgInfo.alt,
          style: { marginLeft: !isPlaying ? 4 : 0 }
        }
      )
    }
  );
  const Player = /* @__PURE__ */ jsx(
    ReactPlayer,
    {
      ref: mediaPlayer,
      className: classNames(reactPlayerClassName ?? "", "react-player"),
      id: reactPlayerId,
      url: mediaUrl,
      playing: isPlaying,
      controls: false,
      muted: isMuted,
      progressInterval: 250,
      onProgress: (state) => setSecondsPlayed(state.playedSeconds),
      onPlay: () => {
        setHasEnded(false);
        setIsPlaying(true);
      },
      onDuration: (seconds) => setDurationInSeconds(seconds),
      onBuffer: () => {
      },
      onBufferEnd: () => {
      },
      onSeek: () => {
      },
      onEnded: () => setHasEnded(true),
      onError: () => {
        setError("A playback error occurred");
        setIsPlaying(false);
      },
      width: "100%",
      height: videoHeight,
      style: styles ?? {}
    }
  );
  const shouldRenderPlayer = !isLoading && mediaUrl && !error;
  const renderControls = () => /* @__PURE__ */ jsxs("div", { className: "controls", children: [
    /* @__PURE__ */ jsx("div", { className: "play-pause", children: isLoading ? renderLoadingSpinner() : renderPlayPauseReplayButton() }),
    renderProgressInfo()
  ] });
  return /* @__PURE__ */ jsx("div", { className: "sa-video-player-wrapper", style: { width: playerWidth }, children: /* @__PURE__ */ jsxs("div", { className: "sa-video-player", style: { width: videoWidth }, children: [
    renderVideoTitle(),
    /* @__PURE__ */ jsxs(Fragment, { children: [
      shouldRenderPlayer ? Player : renderPlaceholder(),
      renderControls()
    ] }),
    renderTimestamp()
  ] }) });
};
export {
  VideoPlayer as default
};

import classNames from 'classnames'
import { ChatInfo, ContactItem, ContactName, UnreadMessagesIndicator } from 'components'
import ContactCloseButton from 'components/ContactItem/ContactCloseButton'
import React from 'react'
import { useDispatch } from 'react-redux'
import { removeChatConnection, setSelectedChat } from 'store/chat/chat.actions'
import { IChat } from 'store/chat/chat.state'
import { setRedirect } from 'store/global/global.actions'

import styles from './ChatListItem.module.scss'

interface Props {
    chat: IChat
    selected: boolean
    index: number
    channel?: string
    subChannel?: string
}

const ChatsListItem: React.FC<Props> = ({ chat, selected, index, channel, subChannel }) => {
    const dispatch = useDispatch()

    const getLastMessage = () =>
        chat.messages.length ? new Date(chat.messages.slice(-1)[0].AbsoluteTime) : new Date()
    const isChatMissed = () =>
        chat.status === connect.ContactStateType.MISSED ||
        chat.status === connect.ContactStateType.ERROR

    //Cannot click on chat if email and not connected
    const isIncomingEmail = chat.channel === 'EMAIL'

    const onSelectChat = () => {
        dispatch(setRedirect('/chat'))
        dispatch(setSelectedChat(chat.id))
    }

    const onCloseChat = () => {
        dispatch(removeChatConnection(chat.id))
    }

    const getUnreadMessagesAmount = () => {
        if (!!chat.unread && chat.channel !== 'EMAIL') {
            return chat.unread
        }

        return 0
    }

    const handleKeyPress = (evt: React.KeyboardEvent) => {
        evt.key === 'Enter' && onCloseChat()
    }

    const renderUnreadMessages = (view: string) => {
        return (
            <>
                {getUnreadMessagesAmount() > 0 && (
                    <div className={styles[`messages--${view}`]}>
                        <UnreadMessagesIndicator unreadMessages={getUnreadMessagesAmount()} />
                    </div>
                )}
            </>
        )
    }

    return (
        <ContactItem
            onSelect={onSelectChat}
            acw={chat.acw}
            selected={selected}
            index={index}
            disabled={isIncomingEmail && chat.status === connect.ContactStateType.CONNECTING}
            className={isChatMissed() ? styles.missedContact : ''}
            isMissed={isChatMissed()}
            bodyClassName={isChatMissed() ? styles.missedChatBody : ''}
            CloseButton={() => (
                <div className={styles.closeButton}>
                    <ContactCloseButton onClick={onCloseChat} onKeyPress={handleKeyPress} />
                </div>
            )}
        >
            <div
                className={classNames(styles.wrapper, {
                    [styles[`wrapper--missed`]]: isChatMissed(),
                })}
            >
                <div className={styles.contactName}>
                    <ContactName name={isChatMissed() ? 'Missed Chat' : chat.customerName} />
                </div>

                <div className={styles.infoWithUnreadMessages}>
                    <ChatInfo
                        isSelected={selected}
                        isMissed={isChatMissed()}
                        timeFromLastMessage={getLastMessage()}
                        chatTimeStarted={chat.started}
                        acw={chat.acw}
                        channel={channel}
                        subChannel={subChannel}
                    />

                    {renderUnreadMessages('mobile')}
                </div>
                {renderUnreadMessages('desktop')}
            </div>
        </ContactItem>
    )
}

export default ChatsListItem

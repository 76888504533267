import * as TaskActions from './tasks.reducer'

export const {
    acceptTask,
    addTaskConnection,
    clearTaskConnection,
    clearTaskReplyText,
    completeTaskACW,
    declineTask,
    deleteTaskAttachment: deleteAttachment,
    endTask,
    recoverTasks,
    removeTaskConnection,
    sendTaskAttachment: sendAttachment,
    setIncludeCCInReply,
    setSelectedTask,
    setTaskAttachment: setAttachment,
    setTaskReplyText,
    taskACW,
    taskConnected,
    taskDisconnected,
    taskMissed,
    transferTask,
    updateConnectionCCAddressesList,
    updateConnectionEditorContent,
} = TaskActions

import Success from 'images/confirm-icon-white.svg'
import Warning from 'images/icon-hazard.svg'
import { useEffect, useRef } from 'react'
import './popup-message.scss'

interface Props {
    type: 'success' | 'error'
    text: string
    clearMessage: () => void
}

const PopupMessage = ({ type, text, clearMessage }: Props) => {
    const elementRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        setTimeout(clearMessage, 8000)
        elementRef?.current?.focus()
        // eslint-disable-next-line
    }, [])

    const capitalizedType = type.charAt(0).toUpperCase() + type.slice(1)
    const messageIcon = (
        <img
            src={type === 'error' ? Warning : Success}
            alt={type}
            title={capitalizedType}
            height="24"
            width="24"
        />
    )

    return (
        <div className="popup-message-container">
            <div
                ref={elementRef}
                tabIndex={-1}
                role="dialog"
                title={capitalizedType}
                aria-describedby={`${type}-text`}
                className={`popup-message flash-animate ${type}`}
            >
                {messageIcon}
                <p id={`${type}-text`} className="text">
                    {text}
                </p>
            </div>
        </div>
    )
}

PopupMessage.defaultProps = {
    type: 'error',
}

export default PopupMessage

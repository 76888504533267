import type { EnhancedStore } from '@reduxjs/toolkit'
import { CONTACT_LOG, MS_IN_HOUR } from 'constants/index'
import { isToday, isYesterday, startOfDay } from 'date-fns'
import { getTimezoneOffset, utcToZonedTime } from 'date-fns-tz'
import PhoneLib from 'google-libphonenumber'
import icon from 'images/sa-logo-blue.png'
import { CountryCode, getCountryCallingCode, isSupportedCountry } from 'libphonenumber-js'
import AnyAction from 'store/anyActions'
import {
    AppFeatures,
    AppSubFeatures,
    DEPRECATED_FEATURE_FLAGS_WITH_PERMISSIONS,
    IFeature,
} from 'store/app/app.features'
import AppState, { FormField, FormState, Option } from 'store/app/app.state'
import { IPermissionFeature } from 'store/auth/auth.state'
import { ISocialChatMessage } from 'store/chat/chat.state'
import { CTR, ICallContactAttributes } from 'store/contact/contact.state'
import { IDirectoryRecord } from 'store/directory/directory.state'
import { IHierarchyFilter, QueueMetricDataType } from 'store/metrics/metrics.state'
import { IGroup, IUserHierarchy, IUserHierarchyLevel } from 'store/settings/settings.state'
import RootState, { LoadingStates } from 'store/state'
import { EContactLogFieldType } from 'views/AdminSettings/ContactLogs/types'

import { formatDateTime } from '@missionlabs/smartagent-app-components'
import { SADropdownOption } from '@missionlabs/smartagent-app-components/dist/Dropdown'
import { isJSONString } from '@missionlabs/smartagent-chat-components-lib/dist/utils'

export function setCSSVar(name: string, val: string) {
    document.documentElement.style.setProperty(name, val)
}

export function unsetCSSVar(name: string) {
    document.documentElement.style.removeProperty(name)
}

export function getParameterByName(name: string, url: string = window.location.href) {
    return new URL(url).searchParams.get(name)
}

export function getHashParameterByName(name: string, url: string = window.location.href) {
    // eslint-disable-next-line
    name = name.replace(/[\[\]]/g, '\\$&')
    const regex = new RegExp('[#&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export function convertDate(date: any) {
    if (!(date instanceof Date)) {
        date = new Date(date)
    }
    return date.toDateString()
    //return `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`;
}

export const parseStringDate = (date: string | Date | number, time: string, endOfDay?: boolean) => {
    try {
        const d = new Date(date)
        if (time) {
            d.setHours(Number(time.split(':')[0]))
            d.setMinutes(Number(time.split(':')[1]))
        } else if (endOfDay) {
            d.setHours(23)
            d.setMinutes(59)
        }
        return d.toISOString()
    } catch (ex) {
        console.log('invalid date')
        return
    }
}

export interface IStrObj {
    [key: string]: string | undefined
}

export function paramsToQueryString(params?: IStrObj) {
    if (!params) return ''
    const obj = Object.fromEntries(
        Object.entries(params).filter(([_, v]) => typeof v !== 'undefined' && v !== ''),
    ) as Record<string, string>
    return new URLSearchParams(obj).toString()
}

export function convertTime(date: any) {
    if (!(date instanceof Date)) {
        date = new Date(date)
    }
    return `${date.getHours()}:${(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()}`
}

export const matchPattern = (regexPattern?: string, string?: string) => {
    if (!string || !regexPattern) return false
    return string.match(regexPattern)
}

export function convertDateTime(date: Date | number) {
    if (!(date instanceof Date)) {
        date = new Date(date)
    }
    return `${date.getHours()}:${(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()} ${date.getDate()}/${
        date.getMonth() + 1
    }/${date.getFullYear()}`
}

export function getNumberInfo(phoneNumber: string) {
    const phoneUtil = PhoneLib.PhoneNumberUtil.getInstance()
    const numberInfo = {
        countryCode: '',
        formattedNumber: '',
    }

    if (!isUKNumber(phoneNumber)) {
        numberInfo.formattedNumber = phoneNumber.replace(/^0+/, '+')

        let internationalNumber
        try {
            internationalNumber = phoneUtil.parseAndKeepRawInput(numberInfo.formattedNumber)
        } catch (error) {
            console.log(
                'error for international number, original then formatted = ',
                phoneNumber,
                numberInfo.formattedNumber,
                error,
            )
        }
        if (internationalNumber && phoneUtil.isValidNumber(internationalNumber)) {
            numberInfo.countryCode = phoneUtil.getRegionCodeForNumber(internationalNumber) || 'GB'
        }
    } else {
        numberInfo.formattedNumber = phoneNumber || ''
        numberInfo.countryCode = 'GB'
    }
    return numberInfo
}

export const getNumberToDialThroughConnect = (
    number: string,
    countryAbbreviation?: CountryCode,
) => {
    const defaultCountryCode = 'GB'
    let { countryCode, formattedNumber } = getNumberInfo(number)

    if (countryAbbreviation && isSupportedCountry(countryAbbreviation) && number.startsWith('0')) {
        formattedNumber = formattedNumber.replace(
            /^0+/,
            `+${getCountryCallingCode(countryAbbreviation)}`,
        )
        countryCode = countryAbbreviation
    } else {
        countryCode = defaultCountryCode
    }

    return { countryCode, formattedNumber }
}

export const getConnectionDisplayNumber = (number: string, countryCode?: CountryCode) => {
    if (countryCode && isSupportedCountry(countryCode)) {
        const countryCallingCode = getCountryCallingCode(countryCode)

        if (isUKNumber(number) && !number.startsWith('0')) {
            if (!number.includes(`+${countryCallingCode}`)) {
                return number
            }
        }

        if (number.startsWith('+')) {
            return number.replace(`+${countryCallingCode}`, '0')
        }

        return number.startsWith('0') ? number : `0${number}`
    }

    return formatPhoneNumber(number)
}

export function removeInvalidCharacters(phoneNumber: string) {
    // removes invalid characters from a phone number. removes the following characters  () [ ] - . /
    const cleanNumber = phoneNumber?.replace(/[^0-9+]/g, '').trimStart()

    return cleanNumber
}

export function formatPhoneNumber(
    phoneNumberString?: string,
    format: PhoneLib.PhoneNumberFormat = PhoneLib.PhoneNumberFormat.NATIONAL,
    country: string = 'GB',
) {
    if (!phoneNumberString || phoneNumberString === 'anonymous') return ''
    const cleanNumber = removeInvalidCharacters(phoneNumberString)

    if (!isUKNumber(phoneNumberString)) return phoneNumberString

    const phoneUtil = PhoneLib.PhoneNumberUtil.getInstance()

    try {
        const phoneNumber = phoneUtil.parse(cleanNumber, country)
        return phoneUtil.format(phoneNumber, format)
    } catch (ex) {
        console.log('phone number format error', ex)
        return phoneNumberString
    }
}

export function validateE164PhoneNumber(phoneNumberString?: string, country: string = 'GB') {
    if (!phoneNumberString || phoneNumberString === 'anonymous') return false
    const phoneUtil = PhoneLib.PhoneNumberUtil.getInstance()
    try {
        const phoneNumber = phoneUtil.parse(phoneNumberString, country)

        return !!(
            (
                phoneUtil.isValidNumberForRegion(phoneNumber, country) &&
                /^\+[1-9]\d{1,14}$/.test(phoneNumberString)
            ) // Is E.164 format
        )
    } catch (ex) {
        return false
    }
}

export function prettyfyTime(time: number) {
    const minutes = Math.floor(time / 60)
    const seconds = time - minutes * 60
    const prettySeconds = seconds < 10 ? '0' + seconds : seconds
    const prettyMinutes = minutes < 10 ? '0' + minutes : minutes
    return prettyMinutes + ':' + prettySeconds
}

export function isUKNumber(phoneNumberString: string) {
    // remove invalid characters first as they could make the logic below fail on uk numbers
    const cleanNumber = removeInvalidCharacters(phoneNumberString)

    return (
        (cleanNumber.indexOf('0') === 0 && cleanNumber.indexOf('00') !== 0) ||
        cleanNumber.indexOf('44') === 0 ||
        cleanNumber.indexOf('+44') === 0
    )
}

export function secondsFrom(timestamp: number | string) {
    if (typeof timestamp === 'string') {
        timestamp = new Date(timestamp).getTime()
    }
    return Math.round((Date.now() - timestamp) / 1000)
}

export function reduceToUnique<T>(arr: Array<T>, compare?: (x: T, y: T) => boolean): Array<T> {
    if (!compare) {
        compare = (x: T, y: T) => {
            return x === y
        }
    }
    return arr.filter((element: T, index: number, array: Array<T>) => {
        if (index === 0) return true
        const duplicateExists = array.slice(0, index).find((x: T) => {
            return compare!(element, x)
        })
        return !duplicateExists
    })
}

export function getSubdomain() {
    const parts = window.location.hostname.split('.')
    if (parts.length < 3) return

    if (parts[0].startsWith('dev-')) {
        // i.e. dev-tw.smartagent.app
        return parts[0].split('-')[1]
    }

    if (parts[0] && parts[0].startsWith('beta-')) {
        //i.e. beta-mycompany.smartagent.app
        return parts[0].split('-')[1]
    }
    if (parts[0] && parts[0].startsWith('uat-')) {
        //i.e. uat-mycompany.smartagent.app
        return parts[0].split('-')[1]
    }
    return parts[0]
}

export function isDev() {
    return process.env.REACT_APP_ENV === 'dev' || process.env.NODE_ENV === 'development'
}

export class NotificationControl {
    supported: boolean = false
    permission: boolean = false
    constructor() {
        // eslint-disable-next-line no-extra-semi
        ;[this.supported, this.permission] = NotificationControl.getPermission()
    }

    static getPermission(): [boolean, boolean] {
        let supported: boolean = false
        let permission: boolean = false

        if ('Notification' in window) {
            supported = true
        } else {
            return [supported, permission]
        }

        const perm = Notification.permission
        const isGranted = (p: string) => p === 'granted'

        if (perm === 'default') {
            Notification.requestPermission().then((p) => {
                permission = isGranted(p)
            })
        } else {
            permission = isGranted(perm)
        }

        return [supported, permission]
    }

    send(title: string = '', body: string = '', onClick?: () => void, notificationIcon = icon) {
        if (this.supported && this.permission) {
            const n = new Notification(title, {
                body,
                icon: notificationIcon,
            })

            n.onclick = () => {
                window.focus()
                onClick?.()
            }
        }
    }
}

export function areDatesEqual(date1: Date, date2: Date) {
    return (
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear()
    )
}

export function getMonthName(date: Date) {
    switch (date.getMonth()) {
        case 0:
            return 'Jan'
        case 1:
            return 'Feb'
        case 2:
            return 'Mar'
        case 3:
            return 'Apr'
        case 4:
            return 'May'
        case 5:
            return 'June'
        case 6:
            return 'July'
        case 7:
            return 'Aug'
        case 8:
            return 'Sep'
        case 9:
            return 'Oct'
        case 10:
            return 'Nov'
        case 11:
            return 'Dec'
    }
}

export function secondsToTime(seconds: number) {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor(seconds / 60 - hours * 60)
    const secs = Math.floor(seconds - hours * 3600 - minutes * 60)

    const displayHours = hours.toString().padStart(2, '0')
    const displayMinutes = minutes.toString().padStart(2, '0')
    const displaySecs = secs.toString().padStart(2, '0')

    return `${displayHours}:${displayMinutes}:${displaySecs}`
}

export function capitaliseStr(str: string, separator = ' ', joiner = ' ') {
    return str
        .toLowerCase()
        .split(separator)
        .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
        .join(joiner)
}

export function userHierarchyToDropdown(userHierarchy?: IUserHierarchy) {
    if (!userHierarchy) return []
    return addGroupToHierarchy(userHierarchy, 0)
}

export function addGroupToHierarchy(
    userHierarchy: IUserHierarchy,
    groupIndex: number,
    parents?: IGroup[],
): SADropdownOption<IHierarchyFilter>[] {
    const groupNames = ['LevelOne', 'LevelTwo', 'LevelThree', 'LevelFour', 'LevelFive']
    const groupName = (groupNames as any)[groupIndex]
    if (!groupName) return []
    return (
        ((userHierarchy as any)[groupName] as IUserHierarchyLevel)?.groups
            .filter((group) => {
                const parentID = parents?.[parents.length - 1].Id
                return !(parentID && group.Parent?.Id !== parentID)
            })
            .map<SADropdownOption<IHierarchyFilter>>((group) => {
                const newParents = [...(parents || []), group]
                return {
                    label: group.Name,
                    key: group.Id ?? group.Name,
                    data: {
                        name: group.Name,
                        key: group.Id,
                        hierarchyStructureIDs: newParents.map((p) => p.Id),
                        hierarchyStructureNames: newParents.map((p) => p.Name),
                    },
                    children: addGroupToHierarchy(userHierarchy, groupIndex + 1, [
                        ...(parents || []),
                        group,
                    ]),
                }
            })
            .sort((a, b) => (a.label > b.label ? 1 : -1)) ?? []
    )
}

export const getMidnight = (timeZone?: string) => {
    const today = new Date()
    today.setUTCHours(0, 0, 0, 0)
    if (timeZone) {
        const timeZoneOffset = getTimezoneOffset(timeZone) / MS_IN_HOUR
        today.setUTCHours(-timeZoneOffset, 0, 0, 0)
    }

    return today.toISOString()
}

export const roundDateDownToNearestMinute = (date: Date) => {
    date.setSeconds(0)
    date.setMilliseconds(0)
    return date
}

export const getIdFromARN = (arn: string) => {
    return arn.split('/').slice(-1).join('')
}

export const timestampToYYYYMMDD = (timestamp: number) => {
    const iso = new Date(timestamp).toISOString()

    const [year, month, day] = iso.split('-')

    return year + month + day.substring(0, 2)
}

export const getBodyWidth = () => {
    return document.body.clientWidth
}

export const getOS = () => {
    const userAgent = navigator.userAgent,
        platform = navigator.platform,
        macOsPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iOsPlatforms = ['iPhone', 'iPad', 'iPod']
    let os = null

    if (macOsPlatforms.indexOf(platform) !== -1) {
        os = 'Mac'
    } else if (iOsPlatforms.indexOf(platform) !== -1) {
        os = 'iOS'
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Win'
    } else if (/Android/.test(userAgent)) {
        os = 'Android'
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux'
    }

    return os
}

interface IDebouncerArgs {
    onCall: (...args: any) => void
    debounceTimeMs?: number
}
export const debouncer = ({ onCall, debounceTimeMs = 2000 }: IDebouncerArgs) => {
    let debounced = false

    const callable = (...args: any) => {
        if (debounced) {
            return
        }

        onCall(...args)
        debounced = true
        setTimeout(() => {
            debounced = false
        }, debounceTimeMs)
    }

    return callable
}

export const isNil = (value: any): boolean => value == null

export const saveStoreInWindowOnNonProd = (appStore: EnhancedStore<RootState>) => {
    interface CustomWindow extends Window {
        appStore?: EnhancedStore<RootState>
    }

    const custWindow: CustomWindow = window
    const REACT_APP_ENV = process.env.REACT_APP_ENV || 'dev'

    if (isDev() || (REACT_APP_ENV !== 'prod' && REACT_APP_ENV !== 'beta')) {
        custWindow.appStore = appStore
    }
}

export const alphabetiseArray = (array: any[], label?: string) => {
    if (label) {
        return array.sort((a, b) => a[label].localeCompare(b[label]))
    }
    return array.sort()
}

export const isEmpty = (value: any) => {
    if (value == null) {
        return false
    }

    if (Array.isArray(value)) {
        return value.length === 0
    }

    if (typeof value === 'object') {
        return !(Object.keys(value).length > 0)
    }

    if (typeof value === 'string') {
        return value.length === 0
    }

    if (typeof value === 'number') {
        return false
    }

    if (typeof value === 'boolean') {
        return false
    }
}

export const isFunction = (value: any) => typeof value === 'function'

export const constructName = (
    firstName: string | undefined | null,
    lastName: string | undefined | null,
) => {
    if (firstName && lastName) {
        return `${firstName} ${lastName}`
    }

    if (firstName) {
        return `${firstName}`
    }

    if (lastName) {
        return `${lastName}`
    }
}

export const constructJobInfo = (
    role: string | undefined | null,
    department: string | undefined | null,
) => {
    if (role && department) {
        return `${role} | ${department}`
    }

    if (role) {
        return role
    }

    if (department) {
        return department
    }
}

export const canUserUseFeature = (
    appFeatures: IFeature<AppFeatures>[],
    featurePermissions: IPermissionFeature[],
) => {
    const hasFeature = (
        feature: AppFeatures,
        subfeature?: AppSubFeatures | AppSubFeatures[],
    ): boolean => {
        // check if feature is turned on for company if it hasn't already been removed from company config
        // (some features will no longer exist as feature flags, but still have permission requirements)
        if (!DEPRECATED_FEATURE_FLAGS_WITH_PERMISSIONS.includes(feature)) {
            const appFeature = appFeatures.some((appFeature) => appFeature.ID === feature)
            if (!appFeature) return false
        }

        // check if user has `ALL` feature permission override
        const hasAllFeatures = featurePermissions.some(
            (featurePermission) => featurePermission.ID === AppFeatures.ALL,
        )
        if (hasAllFeatures) return true

        // check if user has feature permission
        const featurePermission = featurePermissions.find(
            (featurePermission) => featurePermission.ID === feature,
        )
        if (!featurePermission) return false

        if (featurePermission && !subfeature) return true

        // check if user has subfeature(s) permission
        if (Array.isArray(subfeature)) {
            return (
                featurePermission.subfeatures?.some((subfeaturePermission) =>
                    subfeature.some((subfeature) => subfeature === subfeaturePermission),
                ) ?? false
            )
        } else {
            return featurePermission.subfeatures?.includes(subfeature!) ?? false
        }
    }

    return hasFeature
}

export const isObject = (object: any) => object instanceof Object && object.constructor === Object

export const removeEmptyOrNilFields = (data: any) => {
    if (isObject(data)) {
        return Object.keys(data).reduce((acc, key) => {
            const value = data[key]

            if (isNil(value) || isEmpty(value)) {
                return acc
            }

            return { ...acc, [key]: value }
        }, {})
    }

    console.error('Only objects are supported!')

    return data
}

export const isNilOrEmpty = (value: any) => isNil(value) || isEmpty(value)

export const sortContacts = (contacts: IDirectoryRecord[]) =>
    contacts?.sort((a, b) =>
        ((a.firstName ?? '') + (a.lastName ?? ''))?.toLowerCase() >
        ((b.firstName ?? '') + (b.lastName ?? ''))?.toLowerCase()
            ? 1
            : -1,
    )

export const getDirectoryContactByPhoneNumber = (
    contacts: Record<string, IDirectoryRecord>,
    phoneNumber?: string,
) => {
    if (!phoneNumber) {
        return null
    }

    const contact = contacts[phoneNumber]

    if (contact) {
        return contact
    }

    return null
}

const zeroPad = (num: number): string => (num < 10 ? `0${num}` : num.toString())

export const humanFriendlyDateTime: (date: Date) => string = (date) => {
    const relativeDate = humanFriendlyRelativeDate(date)

    let formattedTime
    if (relativeDate === 'Today' || relativeDate === 'Yesterday') {
        formattedTime = formatDateTime(date, { date: false, time: true })
    } else {
        formattedTime = formatDateTime(date, { date: true, time: false })
    }

    return `${relativeDate ? relativeDate + ', ' : ''}${formattedTime}`
}

const humanFriendlyRelativeDate = (from: Date) => {
    const now = new Date()
    const timeDiff = startOfDay(now).getTime() - startOfDay(from).getTime()
    const diffInDays = timeDiff / (1000 * 60 * 60 * 24)

    if (diffInDays < 1) return 'Today'
    else if (diffInDays < 2) return 'Yesterday'
    else return ''
}

export const timeFromDate = (date: Date) =>
    `${zeroPad(date.getHours())}:${zeroPad(date.getMinutes())}`

const getAmPmTime = (date: Date) => {
    let hours = date.getHours()
    let minutes: string | number = date.getMinutes()
    const ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12
    minutes = minutes < 10 ? `0${minutes}` : minutes
    return `${hours}:${minutes} ${ampm}`
}

export const getHumanFriendlyAmPmTime = (date: any) => {
    const convertedDate = date instanceof Date ? date : new Date(date)
    return `${humanFriendlyRelativeDate(convertedDate)}, ${getAmPmTime(convertedDate)}`
}

export const getRequiredFields = (
    contactLog: FormState,
    attributes: ICallContactAttributes,
): string[] => {
    const requiredFields = contactLog.form?.fields?.reduce(
        (acc: string[], formField: FormField) => {
            const conditionField = formField?.condition?.field
            let conditionValueCheck
            if (conditionField) {
                conditionValueCheck = attributes?.[conditionField]
            }
            if (formField.required && formField.condition?.value === conditionValueCheck) {
                acc.push(formField.name)
            }
            return acc
        },
        [],
    )
    return requiredFields ?? []
}

export const isContactLogEmpty = (contactLog: FormState) => {
    const noContactLogForm = contactLog?.state !== LoadingStates.LOADED
    const emptyContactLog = Boolean(isEmpty(contactLog?.form)) && Boolean(contactLog?.form)
    return noContactLogForm || emptyContactLog
}

export const getFilteredHierarchyField = (field: FormField): FormField => {
    if (field.type !== EContactLogFieldType.hierarchy) return field
    const options = [...field.options!]
    if (options.length === 0) return field

    const removeOptionsWithNoSelectableOptions = (
        option: Option,
        parentOptions: Option[],
        idx: number,
    ): void => {
        if (!option.children) return
        if (option.children.length === 0) {
            parentOptions.splice(idx, 1)
        } else {
            // Has to be in reverse in order to keep the idx and the index of the item in the parent array aligned.
            for (let i = option.children.length - 1; i >= 0; i--) {
                removeOptionsWithNoSelectableOptions(option.children[i], option.children, i)
            }
        }
        if (option.children.length === 0) {
            parentOptions.splice(idx, 1)
        }
    }

    // Has to be in reverse in order to keep the idx and the index of the item in the parent array aligned.
    for (let i = options.length - 1; i >= 0; i--) {
        removeOptionsWithNoSelectableOptions(options[i], options, i)
    }

    return { ...field, options }
}

export const getFormName = (logGroup: string | undefined, app: AppState) => {
    if (app?.features?.find((feature) => feature.ID === 'contact-logs')) {
        return logGroup ? `${CONTACT_LOG}-${logGroup}` : `${CONTACT_LOG}-default`
    } else {
        return logGroup ? `${CONTACT_LOG}-${logGroup}` : `${CONTACT_LOG}`
    }
}

export const isAllRequiredFieldsFilled = (
    attributes: ICallContactAttributes,
    requiredFields: string[],
) => {
    const actualFields = Object.keys(attributes).filter((attr) => {
        //check for multiple values
        if (Array.isArray(attributes[attr])) return Boolean(attributes[attr].length)
        return Boolean(attributes[attr])
    })
    //isAllRequiredFieldsFilled will be "true" if requiredFields is empty array
    const isAllRequiredFieldsFilled = requiredFields.every((requiredField) => {
        return actualFields.includes(`sa-acw-${requiredField}`)
    })
    return isAllRequiredFieldsFilled
}
export interface IHIDHeadset {
    sessionId: string
    opened: boolean
    productId: number
    productName: string
    vendorId: number
}

export const listConnectedWebHIDHeadsets = async (): Promise<IHIDHeadset[]> => {
    let response = []
    try {
        // @ts-ignore   hid is not found in the navigator ts type yet as the technology is still new
        response = await navigator?.hid?.getDevices?.()
        console.log(`There are ${response?.length} hid headsets attached.`)
    } catch (err) {
        console.log('Error calling navigator.hid.getDevices', err)
    }

    return response
}

export const getTime = (time: string) => {
    if (!time) return []
    return time.split(':').map((it) => Number(it))
}

export const getDate = (date: Date | string, timeArray: number[]) => {
    if (!timeArray.length) return new Date(date).getTime()
    return new Date(date).setHours(timeArray[0], timeArray[1])
}
export const formatStartOfNumber = (number: string) =>
    number && typeof number === 'string' && number.charAt(0) !== '+' ? `+${number}` : number

export const formatStartOfIntNumbers = (number: string, internalNumberLength?: number) => {
    return !isUKNumber(number) && number.charAt(0) !== '+' && number.length !== internalNumberLength
        ? `+${number}`
        : number
}

export const isValidRegex = (text: string): boolean => {
    try {
        new RegExp(text)
        return true
    } catch (e) {
        return false
    }
}

export const calculateTimeZoneOffsetInHours = (utcDate: string, timeZone: string) => {
    const zonedDate = utcToZonedTime(utcDate, timeZone)
    const offsetInMs = getTimezoneOffset(timeZone, zonedDate)
    const offsetInHours = offsetInMs / 1000 / 60 / 60

    return offsetInHours
}

export const constructTimeZoneOffsetSign = (offset: number) => {
    return offset < 0 ? '-' : offset === 0 ? '' : '+'
}

export const getContactDurationSeconds = (contact: CTR): number => {
    return contact.disconnectTimestamp && contact.connectedToAgentTimestamp
        ? (contact.disconnectTimestamp - contact.connectedToAgentTimestamp) / 1000
        : contact.agentInteractionDuration ?? 0
}

export const conditionalPluralise = (noun: string, amount = 0, suffix = 's') => {
    return `${noun}${amount !== 1 ? suffix : ''}`
}

export const getChatUrlsPreview = (data: Record<string, string>, key: string): string => {
    // domain + subdomain regex
    const urlRegex = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/i
    const urls = Object.keys(data)
        .filter((url) => data[url] === key)
        .reduce((acc, url) => {
            const matchedUrls = url.match(urlRegex)
            matchedUrls?.[1] && acc.push(matchedUrls?.[1] as string)

            return acc
        }, [] as string[])
    if (!urls.length) return 'No URLs'

    const uniqueUrls = [...new Set(urls)]
    return uniqueUrls.length < 3
        ? uniqueUrls.join(', ')
        : `${uniqueUrls.slice(0, 2).join(', ')} and ${uniqueUrls.length - 2} other URLs`
}

export const isValidEmailAddress = (email: string): boolean => {
    const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(email)
}

export const isWholeNumber = (text: string) => {
    const regex = /^\d+$/
    return regex.test(text)
}

export const convertFeatureToWebSocketAction = (feature: string) => {
    return feature.toUpperCase().replace(/-/g, '_')
}

export const saAlreadyOpen = () => {
    const saSessionExpirationItem = localStorage.getItem('SASessionExpiration')
    const currentTime = new Date().getTime()
    if (saSessionExpirationItem) {
        const saSessionExpiration = parseInt(saSessionExpirationItem)
        if (currentTime < saSessionExpiration) return true
    }
    return false
}

export const setOneTabSessionExpirationItem = () => {
    const expiration = new Date().getTime() + 10000
    localStorage.setItem('SASessionExpiration', expiration.toString())
}

export const setEnforceOneTabItem = (enforceSAOneTab: boolean) => {
    localStorage.setItem('EnforceSAOneTabSession', enforceSAOneTab.toString())
}

export const deleteSATabSession = () => {
    localStorage.removeItem('EnforceSAOneTabSession')
    localStorage.removeItem('SASessionExpiration')
}

export const wordToUpperCamelCase = (str: string) => {
    return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1)
}

export const formatInteractionDate = (interactionDateTimestamp: number): string => {
    let interactionDate
    const timeOptions = { time: true, date: false }

    if (isToday(interactionDateTimestamp)) {
        interactionDate = `Today, ${formatDateTime(interactionDateTimestamp, timeOptions)}`
    } else if (isYesterday(interactionDateTimestamp)) {
        interactionDate = `Yesterday, ${formatDateTime(interactionDateTimestamp, timeOptions)}`
    } else {
        const dateAndTimeOptions = { date: true, time: true }
        interactionDate = formatDateTime(interactionDateTimestamp, dateAndTimeOptions)
    }

    return interactionDate
}

export const getTimeRangeString = (value: number | string) => {
    switch (value) {
        case 0.5:
            return QueueMetricDataType['HISTORIC-0.5']
        case 1:
            return QueueMetricDataType['HISTORIC-1']
        case 2:
            return QueueMetricDataType['HISTORIC-2']
        case 'midnight':
            return QueueMetricDataType['HISTORIC']
    }
}

export const removeBannerMessages = (
    message: ISocialChatMessage[] | connect.ChatTranscriptItem[],
) => {
    return message.filter((m) => {
        if (m.Content && isJSONString(m.Content)) {
            const parsedContent = JSON.parse(m.Content)
            const contentLanguage = Object.keys(parsedContent)[0]
            const messageType = parsedContent[contentLanguage]?.type
            return messageType !== 'INFORMATION_BANNER' && messageType !== 'WARNING_BANNER'
        }
        return true
    })
}

export const constructTags = (attributes: ICallContactAttributes) => {
    const tags = []

    for (const attributeKey in attributes) {
        if (attributeKey.match(/^sa-tag-/) && !attributeKey.match(/-colour$/)) {
            tags.push({
                value: attributes[attributeKey],
                colour: attributes[`${attributeKey}-colour`] ?? 'default',
            })
        }
    }

    return tags
}

export const isAnyAction = (action: unknown): action is AnyAction => {
    return (action as AnyAction).type !== undefined
}

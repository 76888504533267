import { CONTACT_LOG, LOG_GROUP_ATTRIBUTE_NAME } from 'constants/index'
import useApp from 'hooks/redux/useApp'
import useContacts from 'hooks/redux/useContacts'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { loadForm } from 'store/app/app.actions'
import { FormState } from 'store/app/app.state'
import { getFormName } from 'utils'

const useContactLogForm = (contactLogGroup?: string): FormState => {
    const app = useApp()
    const contacts = useContacts() // using useContacts instead of useContact, because useContact becomes null briefly during ACW causing errors
    const dispatch = useDispatch()

    const logGroup: string | undefined = contactLogGroup
        ? contactLogGroup
        : useMemo(() => contacts?.[0]?.attributes?.[LOG_GROUP_ATTRIBUTE_NAME], [contacts?.[0]])

    const formName: string = useMemo(() => {
        return getFormName(logGroup, app)
    }, [logGroup])
    const contactLog: FormState | undefined = useMemo(
        () => app?.forms[formName],
        [app?.forms, formName],
    )

    useEffect(() => {
        if (!contactLog) {
            dispatch(loadForm(CONTACT_LOG, logGroup))
        }
    }, [contactLog, dispatch])
    return contactLog || {}
}

export default useContactLogForm

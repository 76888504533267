import { AnalyticsAttributes } from 'components'
import { Component } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { saveStoreInWindowOnNonProd } from 'utils'

import { SmartagentChatWebsocketType } from '@missionlabs/smartagent-bchat'

import Logger from './logger'
import { createStore } from './store/createStore'
import MainBase from './views/MainBase'

declare global {
    interface Window {
        bchat: SmartagentChatWebsocketType
    }
}

Logger.init()

const persistedState = localStorage.getItem('state')
    ? JSON.parse(localStorage.getItem('state') || '')
    : {}

const store = createStore(persistedState)

saveStoreInWindowOnNonProd(store)

function getBaseName() {
    return process.env.PUBLIC_URL || process.env.BASE_PATH || ''
}
class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <BrowserRouter basename={getBaseName()}>
                    <AnalyticsAttributes>
                        <MainBase />
                    </AnalyticsAttributes>
                </BrowserRouter>
            </Provider>
        )
    }
}

export default App

import React from 'react'

import { IInteraction } from 'views/Metrics/HistoricMetrics/types'
import { CallComprehension } from 'widgets/CallComprehension'
import { CallRecording } from 'widgets/CallRecording'
import CallTranscription from 'widgets/CallTranscription'
import { ContactDetails } from 'widgets/ContactDetails'

import { Widgets } from 'views/Home/Widgets'

import './voice-widgets.scss'

interface Props {
    interaction: IInteraction
}

const VoiceWidgets: React.FC<Props> = ({ interaction }) => {
    return (
        <Widgets
            render={(getWidget) => (
                <div data-testid="voice-widgets" className="contact-history-voice-widgets">
                    <CallTranscription contactID={interaction.ID} />
                    <CallComprehension
                        contactID={interaction.ID}
                        widgetTitle="Sentiment analysis"
                    />
                    <CallRecording
                        contactID={interaction.ID}
                        recordingDate={interaction.initiationTimestamp}
                        widgetTitle="Audio"
                    />
                    <ContactDetails contact={interaction} widgetTitle="Additional info" />
                </div>
            )}
        />
    )
}

export default VoiceWidgets

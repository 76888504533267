import { Box } from '@missionlabs/smartagent-app-components'
import useHasFeature, { AppFeatures, AppSubFeatures } from 'hooks/useHasFeature'
import useSmartAgentAPI from 'hooks/useSmartAgentAPI'
import Search from 'images/search.svg'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getCallRecording } from 'services/api/api.contact'
import RootState from 'store/state'
import { AudioPlayer, AudioPlayerError, ICallRecordingData } from './AudioPlayer'

interface Props {
    contactID?: string
    recordingDate?: number
    widgetTitle?: string
}

export const CallRecording: React.FC<Props> = (props) => {
    const [haveDoneInitialLoad, setHaveDoneInitialLoad] = useState<boolean>(false)
    const [expirationErrorCount, setExpirationErrorCount] = useState<number>(0)

    const { contactID, recordingDate } = useSelector<
        RootState,
        { contactID: string; recordingDate: number }
    >((state) => ({
        contactID: props.contactID ?? state.contact?.ID ?? '',
        recordingDate: props.recordingDate ?? state.contact?.initiationTimestamp ?? 0,
    }))

    const hasFeature = useHasFeature()

    const [error, loading, response, request] = useSmartAgentAPI<ICallRecordingData, RootState>(
        async (state) =>
            getCallRecording(
                state.app.ID,
                state.app.instance!.ID,
                state.auth.token!,
                contactID,
                recordingDate,
            ),
        false,
    )

    useEffect(() => {
        request()
        // eslint-disable-next-line
    }, [contactID, recordingDate])

    const onAudioError = (err: AudioPlayerError) => {
        const signedURLHasProbablyExpired = err.statusCode === 401 || err.statusCode === 403
        if (signedURLHasProbablyExpired) {
            setExpirationErrorCount(expirationErrorCount + 1)
            if (expirationErrorCount < 3) {
                //Prevent an endless loop if URL invalid.
                request()
            }
        }
    }

    if (loading && !haveDoneInitialLoad) {
        //Avoid unmounting the player on URL refreshes.
        return <p>{loading}</p>
    } else if (!haveDoneInitialLoad) {
        setHaveDoneInitialLoad(true)
    }

    const headerTitle = props.widgetTitle ? props.widgetTitle : 'Call Recording'

    return !!error || expirationErrorCount >= 3 ? (
        <Box alt collapse hidden={!!error} header={<h1>{headerTitle}</h1>}>
            <div className="sa-loading no-data">
                <img src={Search} alt="Search" width="24px" height="24px" />
                <p>Unable to retrieve call recording data</p>
            </div>
        </Box>
    ) : response?.status === 200 ? (
        <AudioPlayer
            {...response.data}
            contactID={contactID}
            recordingDate={recordingDate}
            canDownload={hasFeature(
                AppFeatures.CALL_RECORDING,
                AppSubFeatures.CALL_RECORDING_DOWNLOAD,
            )}
            onAudioError={onAudioError}
            widgetTitle={headerTitle}
        />
    ) : null
}

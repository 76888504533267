import './directory-list.scss'

import {
    ExternalDirectoryShortCard,
    ShortCardContainer,
    TeamsShortCard,
    VirtualizedList,
} from 'components'
import React, { memo, useMemo, useState } from 'react'
import {
    DirectoryRecordType,
    DirectorySourceTypes,
    FullProfileViewFieldNames,
    IDirectoryRecord,
    IEmailAddress,
    IPhoneNumber,
    ISocialMediaHandle,
} from 'store/directory/directory.state'
import { ITeamsRecord } from 'store/directory/teams/state'
import { sortContacts } from 'utils'

interface Props {
    search: string
    owner?: string
    priority?: boolean
    contacts?: IDirectoryRecord[]
}

const mapPhoneNumbers = (phoneNumbers: IPhoneNumber[] | undefined) =>
    phoneNumbers?.map((phoneNumber) => ({
        fieldName: FullProfileViewFieldNames.PHONE_NUMBER,
        fieldValue: phoneNumber.value,
    })) ?? []

const mapEmailAddresses = (emailAddresses: IEmailAddress[] | undefined) =>
    emailAddresses?.map((emailAddress) => ({
        fieldName: FullProfileViewFieldNames.EMAIL,
        fieldValue: emailAddress.value,
    })) ?? []

const mapSocialMedia = (socialMediaHandles: ISocialMediaHandle[] | undefined) =>
    socialMediaHandles?.map((socialMedia) => ({
        fieldName: socialMedia.type,
        fieldValue: socialMedia.value,
    })) ?? []

const teamsFullProfileMapperFn = (contact: ITeamsRecord) => {
    const phoneNumbersFullProfile = contact?.phoneNumbers ? contact.phoneNumbers.slice(1) : []
    return [
        ...mapPhoneNumbers(phoneNumbersFullProfile),
        ...mapEmailAddresses(contact.emailAddresses),
        {
            fieldName: FullProfileViewFieldNames.OFFICE,
            fieldValue: contact.officeLocation,
        },
    ]
}

const directoryFullProfileMapperFn = (contact: DirectoryRecordType) => {
    const phoneNumbersFullProfile = contact?.phoneNumbers ? contact.phoneNumbers.slice(1) : []
    return [
        ...mapPhoneNumbers(phoneNumbersFullProfile),
        ...mapEmailAddresses(contact.emailAddresses),
        ...mapSocialMedia(contact.socialMediaHandles),
        {
            fieldName: FullProfileViewFieldNames.BLOCKED,
            fieldValue: contact.isBlocked ? 'Yes' : null,
        },
        {
            fieldName: FullProfileViewFieldNames.DEPRIORITISED,
            fieldValue: contact.isDeprioritised ? 'Yes' : null,
        },
    ]
}

const DirectoryList: React.FC<Props> = ({ search, owner, priority, contacts }) => {
    const [openedCardId, setIdOfOpenedCard] = useState<string | null>(null)

    const filteredContacts = useMemo(
        () =>
            contacts?.filter((record) => {
                if (
                    search &&
                    ![record.firstName, record.lastName, record.role, record.department]
                        .join(' ')
                        .toLowerCase()
                        .includes(search.toLowerCase())
                )
                    return false

                if (owner && owner !== record.owner) return false

                if (priority === undefined || priority === !!record.priority) return true

                return false
            }) ?? [],
        [contacts, search, owner, priority],
    )

    const sortedContacts = useMemo(() => sortContacts(filteredContacts) ?? [], [filteredContacts])

    const setClickedCardId = (id: string) => {
        if (id === openedCardId) {
            return setIdOfOpenedCard(null)
        }

        return setIdOfOpenedCard(id)
    }

    const clearOpenedCardId = () => {
        setIdOfOpenedCard(null)
    }

    const renderDirectoryShortProfile = (contact: IDirectoryRecord, measure?: () => void) => (
        <ShortCardContainer
            openedCardId={openedCardId}
            ShortCard={ExternalDirectoryShortCard}
            contact={contact}
            mapperFn={directoryFullProfileMapperFn}
            onClick={setClickedCardId}
            measure={measure}
        />
    )

    const renderTeamsShortProfile = (contact: ITeamsRecord, measure?: () => void) => (
        <ShortCardContainer
            openedCardId={openedCardId}
            ShortCard={TeamsShortCard}
            contact={contact}
            mapperFn={teamsFullProfileMapperFn}
            onClick={setClickedCardId}
            measure={measure}
        />
    )

    const renderShortProfile = (contact: IDirectoryRecord, measure?: () => void) => {
        if (contact.sourceType === DirectorySourceTypes.EXTERNAL_DIRECTORY) {
            return renderDirectoryShortProfile(contact, measure)
        }

        if (contact.sourceType === DirectorySourceTypes.TEAMS) {
            return renderTeamsShortProfile(contact, measure)
        }
    }

    if (sortedContacts.length === 0) {
        return <p>No records found</p>
    }

    return sortedContacts.length < 100 ? (
        <div className="sa-directory-list">
            {sortedContacts.map((contact) => (
                <div className="directory-list__item" key={contact.id}>
                    {renderShortProfile(contact)}
                </div>
            ))}
        </div>
    ) : (
        <VirtualizedList
            openedCardId={openedCardId}
            contactsList={sortedContacts}
            search={search}
            renderShortProfile={renderShortProfile}
            clearOpenedCardId={clearOpenedCardId}
        />
    )
}

export default memo(DirectoryList)

import React from 'react'
import { useSelector } from 'react-redux'

import WarningIcon from 'images/icon-warning.svg'
import RootState from 'store/state'

import styles from './frequent-contact.module.scss'

const FrequentContact: React.FC = () => {
    const contactFrequencyMessage: string | undefined = useSelector(
        (state: RootState) => state.contact?.attributes?.['sa-tag-contactCount'],
    )

    return contactFrequencyMessage?.length ? (
        <div className={styles.container}>
            <img className={styles.icon} src={WarningIcon} alt="" />
            <span className={styles.text}>{contactFrequencyMessage}</span>
        </div>
    ) : null
}

export default FrequentContact

import React from 'react'

import { Button } from '@missionlabs/smartagent-app-components'

import styles from 'components/ControlsButtons/controls-button.module.scss'

import { ControlsButton } from 'components/ControlsButtons/types'

type Props = { controls: ControlsButton[] }

const ControlsButtons: React.FC<Props> = ({ controls }) => {
    return (
        <div className={styles.container}>
            {controls.map(({ styling, handleClick, text }, index) => (
                <Button key={index} styling={styling} type="button" onClick={handleClick}>
                    {text}
                </Button>
            ))}
        </div>
    )
}

export default ControlsButtons

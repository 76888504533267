import { SocialChatJsonMessageType } from '@missionlabs/smartagent-service-chat/dist/types/socialChatJsonMessage'

export enum MessageSources {
    TWILIO_SMS = 'TWILIO_SMS',
    KUMODI_SMS = 'KUMODI_SMS',
    TELEGRAM = 'TELEGRAM',
    WEBSOCKET = 'WEBSOCKET',
    WHATSAPP = 'WHATSAPP',
    EMAIL = 'EMAIL',
}

export enum SocialMediaPlatform {
    TWITTER = 'TWITTER',
    FACEBOOK = 'FACEBOOK',
    INSTAGRAM = 'INSTAGRAM',
    SMS = 'SMS',
    WHATSAPP = 'WHATSAPP',
}

export const systemMessageTypes: (
    | SocialChatJsonMessageType
    | connect.ChatTranscriptItem['Type']
)[] = [
    'EVENT',
    'CONNECTION_ACK',
    SocialChatJsonMessageType.MESSAGE_READ,
    SocialChatJsonMessageType.UNSEND,
]

export const isSystemMessage = (
    messageType: SocialChatJsonMessageType | connect.ChatTranscriptItem['Type'],
) => {
    return systemMessageTypes.includes(messageType)
}

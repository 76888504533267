import { SADropdownOption } from '@missionlabs/smartagent-app-components/dist/Dropdown'
import { Sort } from 'store/contactHistory/contactHistory.state'

const options: SADropdownOption<Sort>[] = [
    {
        label: 'Date/Time (Oldest)',
        data: 'date-time-asc',
    },
    {
        label: 'Date/Time (Newest)',
        data: 'date-time-desc',
    },
    {
        label: 'Agent Name (A-Z)',
        data: 'agent-name-asc',
    },
    {
        label: 'Agent Name (Z-A)',
        data: 'agent-name-desc',
    },
    {
        label: 'Queue (A-Z)',
        data: 'queue-asc',
    },
    {
        label: 'Queue (Z-A)',
        data: 'queue-desc',
    },
    {
        label: 'Duration (Shortest)',
        data: 'duration-asc',
    },
    {
        label: 'Duration (Longest)',
        data: 'duration-desc',
    },
    {
        label: 'ACW Duration (Shortest)',
        data: 'acw-duration-asc',
    },
    {
        label: 'ACW Duration (Longest)',
        data: 'acw-duration-desc',
    },
]

export default options

import { PayloadAction, combineReducers, createSlice } from '@reduxjs/toolkit'

import type {
    Address,
    Case,
    CaseReducers,
    CaseSearchState,
    CurrentCaseState,
    Fascia,
    FasciasState,
    Message,
    NewCaseState,
    UploadAttachment,
    UploadAttachmentsState,
} from './case.state'

const initialState: CurrentCaseState = {}

export const currentCaseSlice = createSlice({
    name: 'currentCase',
    initialState,
    reducers: {
        getCaseSuccess(_, action: PayloadAction<Case>) {
            return { ...action.payload }
        },
        updateCaseSuccess(state, action: PayloadAction<Case>) {
            return { ...state, ...action.payload }
        },
        createMessageSuccess(state, action: PayloadAction<Message>) {
            return {
                ...state,
                messages: [action.payload, ...(state.messages || [])],
            }
        },
        getCase(state, action: PayloadAction<string>) {
            // Clear state if getting a different case
            return action.payload === state.ID ? state : {}
        },
        exitCase() {
            return {}
        },
    },
})

export const { getCaseSuccess, updateCaseSuccess, createMessageSuccess, getCase, exitCase } =
    currentCaseSlice.actions

type State = CaseSearchState | null
const caseSearchInitialState = null as State

export const caseSearchSlice = createSlice({
    name: 'caseSearch',
    initialState: caseSearchInitialState,
    reducers: {
        searchCasesSuccess(_, action: PayloadAction<Partial<Case>[]>) {
            return [...action.payload]
        },
        searchCases() {
            return null
        },
        searchCasesReset() {
            return null
        },
        clear() {
            return null
        },
    },
})

export const { searchCasesSuccess, searchCases, searchCasesReset, clear } = caseSearchSlice.actions

const isSearchLoadingInitialState: boolean = false

export const isSearchLoadingSlice = createSlice({
    name: 'isSearchLoading',
    initialState: isSearchLoadingInitialState,
    reducers: {
        searchCasesFailure() {
            return false
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(searchCases, () => true),
            addCase(searchCasesSuccess, () => false),
            addCase(searchCasesReset, () => false)
    },
})

export const { searchCasesFailure } = isSearchLoadingSlice.actions

const getContactByCaseIDInitialState = null as State

export const getContactByCaseIDSlice = createSlice({
    name: 'getContactByCaseID',
    initialState: getContactByCaseIDInitialState,
    reducers: {
        getContactByCaseIDSuccess(_, action: PayloadAction<any>) {
            return action.payload
        },
        getContactByCaseIDFailure() {
            return null
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(clear, () => null)
    },
})

export const { getContactByCaseIDSuccess, getContactByCaseIDFailure } =
    getContactByCaseIDSlice.actions

const isContactLoadingInitialState: boolean = false

export const isContactLoadingSlice = createSlice({
    name: 'isContactLoading',
    initialState: isContactLoadingInitialState,
    reducers: {
        getContactByCaseID() {
            return true
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(getContactByCaseIDSuccess, () => false),
            addCase(getContactByCaseIDFailure, () => false)
    },
})

export const { getContactByCaseID } = isContactLoadingSlice.actions

type newCaseState = NewCaseState | null
const newCaseInitialState = null as newCaseState

export const newCaseSlice = createSlice({
    name: 'newCase',
    initialState: newCaseInitialState,
    reducers: {
        startNewCase(state, action: PayloadAction<Address | undefined>) {
            if (!action.payload) return { title: '' }
            return {
                ...state,
                fromName: action.payload.name,
                fromAddress: action.payload.address,
            }
        },
        createNewCaseSuccess(state, action: PayloadAction<Partial<Case>>) {
            return { ...state, ...action.payload }
        },
        updateNewCase(state, action: PayloadAction<Partial<Case>>) {
            return { ...state, ...action.payload }
        },
        exitNewCase() {
            return null
        },
    },
})

export const { startNewCase, createNewCaseSuccess, updateNewCase, exitNewCase } =
    newCaseSlice.actions

const uploadAttachmentsInitialState: UploadAttachmentsState = []

export const uploadAttachmentsSlice = createSlice({
    name: 'uploadAttachments',
    initialState: uploadAttachmentsInitialState,
    reducers: {
        uploadAttachmentAsk(state) {
            return [...state, undefined]
        },
        uploadAttachmentAdd(state, action: PayloadAction<UploadAttachment>) {
            // TODO: check if last item is undefined
            const attachments = state.slice(0, state.length - 1) // Remove undefined created by uploadAttachmentAsk
            return [...attachments, action.payload]
        },
        uploadAttachmentSuccess(state, action: PayloadAction<UploadAttachment>) {
            const attachments = [...state],
                attachmentIndex = attachments.findIndex(
                    (attachment) => attachment?.filename === action.payload?.filename,
                )
            if (attachmentIndex > -1) attachments[attachmentIndex] = action.payload
            return attachments
        },
        uploadAttachmentRemove(state, action: PayloadAction<string>) {
            const attachments = [...state],
                attachmentIndex = attachments.findIndex(
                    (attachment) => attachment?.filename === action.payload,
                )

            attachments.splice(attachmentIndex, 1)
            return attachments
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(getCase, () => []), addCase(exitCase, () => []), addCase(exitNewCase, () => [])
    },
})

export const {
    uploadAttachmentAsk,
    uploadAttachmentAdd,
    uploadAttachmentSuccess,
    uploadAttachmentRemove,
} = uploadAttachmentsSlice.actions

const fasciasInitialState: FasciasState = []

export const fasciasSlice = createSlice({
    name: 'fascias',
    initialState: fasciasInitialState,
    reducers: {
        getFasciasSuccess(_, action: PayloadAction<Fascia[]>) {
            return action.payload
        },
    },
})

export const { getFasciasSuccess } = fasciasSlice.actions

export type CaseAction =
    | ReturnType<(typeof currentCaseSlice.actions)[keyof typeof currentCaseSlice.actions]>
    | ReturnType<(typeof caseSearchSlice.actions)[keyof typeof caseSearchSlice.actions]>
    | ReturnType<(typeof isSearchLoadingSlice.actions)[keyof typeof isSearchLoadingSlice.actions]>
    | ReturnType<
          (typeof getContactByCaseIDSlice.actions)[keyof typeof getContactByCaseIDSlice.actions]
      >
    | ReturnType<(typeof isContactLoadingSlice.actions)[keyof typeof isContactLoadingSlice.actions]>
    | ReturnType<
          (typeof uploadAttachmentsSlice.actions)[keyof typeof uploadAttachmentsSlice.actions]
      >
    | ReturnType<(typeof newCaseSlice.actions)[keyof typeof newCaseSlice.actions]>
    | ReturnType<(typeof fasciasSlice.actions)[keyof typeof fasciasSlice.actions]>

const caseReducer = combineReducers<CaseReducers>({
    currentCase: currentCaseSlice.reducer,
    searchResults: caseSearchSlice.reducer,
    isSearchLoading: isSearchLoadingSlice.reducer,
    contactResult: getContactByCaseIDSlice.reducer,
    isContactLoading: isContactLoadingSlice.reducer,
    uploadAttachments: uploadAttachmentsSlice.reducer,
    newCase: newCaseSlice.reducer,
    fascias: fasciasSlice.reducer,
})

export default caseReducer

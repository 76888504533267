import gql from 'graphql-tag'
import { Conversation } from '../types'

export default gql(`
query listUserConversations {
  listUserConversations{
    items {
      user
      conversationId
      unread
      name
      conversation {
        id
        lastMessageContent
        lastMessageTimestamp
        lastMessageUser
        recipientList
      }
    }
    nextToken
  }
}
`)

export interface ListUserConversations {
    listUserConversations: {
        items: {
            user: string
            conversationId: string
            unread: number
            name: string
            conversation: Conversation
        }[]
        nextToken?: string
    }
}

import { ApolloProvider } from '@apollo/client'
import useHasFeature, { AppFeatures, useGetFeatureConfig } from 'hooks/useHasFeature'
import React from 'react'
import { useSelector } from 'react-redux'
import createClient from 'services/Apollo/ApolloClient'
import RootState from 'store/state'

interface Props {
    children: React.ReactNode
}

const ApolloWrapper: React.FC<Props> = React.memo(({ children }) => {
    const hasFeature = useHasFeature()

    const getFeature = useGetFeatureConfig()
    const { appSyncURL, appSyncRegion, apiKey } = getFeature(AppFeatures.INTERNAL_MESSAGING)

    const renderInner = () => <>{children}</>

    const token = useSelector<RootState, RootState['auth']['token']>(
        (state) => state.auth.token || '',
    )

    return hasFeature(AppFeatures.INTERNAL_MESSAGING) ? (
        <ApolloProvider
            client={createClient({
                url: appSyncURL,
                region: appSyncRegion,
                apiKey,
                token: token || '',
            })}
        >
            {renderInner()}
        </ApolloProvider>
    ) : (
        renderInner()
    )
})

export default ApolloWrapper

import React from 'react'
import { useSelector } from 'react-redux'

import ChatBubbleIcon from 'images/chat-bubble-icon.svg'

import { selectContactHistoryInstance } from 'store/contactHistory/contactHistory.selectors'

import { formatInteractionDate } from 'utils'

import InteractionInformation from '../InteractionInformation'
import { constructDisplayName, constructTags, formatInteractionDuration } from '../utils'

import ChatWidgets from './ChatWidgets'
import TaskWidgets from './TaskWidgets'
import VoiceWidgets from './VoiceWidgets'

import { isChannelOfCategory } from 'store/contactHistory/contactHistory.utils'
import './main-view.scss'

const MainView: React.FC<{}> = () => {
    const { selectedInteraction } = useSelector(selectContactHistoryInstance) ?? {}

    return (
        <div className="contact-history-main-view">
            {selectedInteraction ? (
                (() => {
                    const {
                        agentName,
                        connectedToAgentTimestamp,
                        connectedToSystemTimestamp,
                        interactionDuration,
                        channelType,
                        emailMessages,
                        messageId,
                        disconnectReason,
                        queueName,
                        initiationMethod,
                        customerEndpointAddress,
                        attributes,
                    } = selectedInteraction

                    const endpointAddress = constructDisplayName(
                        channelType,
                        customerEndpointAddress,
                        attributes,
                    )
                    const agentDisplayName = agentName ?? 'No name found'
                    const connectedTimestamp =
                        connectedToAgentTimestamp ?? connectedToSystemTimestamp
                    const interactionDate = connectedTimestamp
                        ? formatInteractionDate(connectedTimestamp)
                        : 'No date found'
                    const interactionDisplayDuration = channelType.startsWith('TASK')
                        ? undefined
                        : interactionDuration
                          ? formatInteractionDuration(interactionDuration)
                          : 'No duration found'
                    const { subject, attachments } =
                        emailMessages?.find(
                            (emailMessage) => emailMessage.messageId === messageId,
                        ) || {}

                    return (
                        <>
                            <InteractionInformation
                                endpointAddress={endpointAddress}
                                queueName={queueName}
                                role="group"
                                agentName={agentDisplayName}
                                date={interactionDate}
                                channelType={channelType}
                                tags={constructTags(selectedInteraction)}
                                duration={interactionDisplayDuration}
                                subject={subject}
                                disabled
                                hasAttachments={!!attachments?.length}
                                disconnectWithNoAgentReason={
                                    !agentName ? disconnectReason : undefined
                                }
                                initiationMethod={initiationMethod!}
                            />
                            {isChannelOfCategory(channelType, 'Voice') && (
                                <VoiceWidgets interaction={selectedInteraction} />
                            )}
                            {(isChannelOfCategory(channelType, 'Social') ||
                                isChannelOfCategory(channelType, 'Webchat') ||
                                isChannelOfCategory(channelType, 'Messaging')) && (
                                <ChatWidgets interaction={selectedInteraction} />
                            )}
                            {isChannelOfCategory(channelType, 'Task') && (
                                <TaskWidgets interaction={selectedInteraction} />
                            )}
                        </>
                    )
                })()
            ) : (
                <div className="contact-history-main-view-no-selected-interaction">
                    <img src={ChatBubbleIcon} alt="Chat bubble" />
                    <p className="contact-history-main-view-no-selected-interaction-message">
                        Nothing is selected, please select an item
                        <br />
                        from the left panel to see more
                    </p>
                </div>
            )}
        </div>
    )
}

export default MainView

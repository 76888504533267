import { makeRequest } from 'services/api'
import {
    IWebFormConfig,
    IWebformConfigUpdateData,
} from 'store/channelSettings/webform/webform.state'

const basePath = '/webforms-message-service/companies/{companyID}/instances/{instanceID}'

export const getWebFormMessageConfigs = async (brand: string): Promise<IWebFormConfig[]> => {
    const { data: webformConfigs } = await makeRequest({
        url: `${basePath}/brands/${brand}/webform-configs`,
        method: 'get',
    })

    return webformConfigs
}

export const updateWebformConfig = async (ID: string, data: IWebformConfigUpdateData) => {
    const { data: webformConfigs } = await makeRequest({
        url: `${basePath}/webform-config/${ID}`,
        method: 'put',
        data,
    })

    return webformConfigs
}

export const createWebformConfig = async (data: IWebformConfigUpdateData) => {
    const { data: webformConfigs } = await makeRequest({
        url: `${basePath}/webform-config`,
        method: 'post',
        data,
    })

    return webformConfigs
}

export const getFileTypes = async (): Promise<Record<string, string>> => {
    const { data: fileTypes } = await makeRequest({
        url: `${basePath}/webform/file-types`,
        method: 'get',
    })

    return fileTypes
}

import { Button } from '@missionlabs/smartagent-app-components'
import { DialButton } from 'components'
import React from 'react'
import { connect, useSelector } from 'react-redux'
import { addConnection, makeCall } from 'store/call/call.actions'
import { setNumberDialling } from 'store/global/global.actions'
import RootState from 'store/state'
import useDialButton from '../../hooks/useDialButton'
import styles from './styles.module.scss'

interface Props {
    phoneNumber?: string | null
    additionalNumbersAmount?: number
    showMoreNumbersSign?: boolean
    showButton?: boolean
    makeCall: typeof makeCall
    setNumberDialling: typeof setNumberDialling
    addConnection: typeof addConnection
}

const ContactPhoneNumber = ({
    phoneNumber,
    additionalNumbersAmount,
    showMoreNumbersSign,
    showButton,
    makeCall,
    setNumberDialling,
    addConnection,
}: Props) => {
    const call = useSelector<RootState, RootState['call']>(({ call }) => call)

    const { isDialButtonShown, showDialButton, hideDialButton } = useDialButton(false)

    const handleKeyPress = (evt: React.KeyboardEvent) => {
        evt.key === 'Enter' && phoneNumber && setNumberDialling(phoneNumber)
    }

    const handlePhoneNumberClick = (evt: React.MouseEvent) => {
        evt.stopPropagation()
        phoneNumber && setNumberDialling(phoneNumber)
    }

    const handleOnDialNumberClick = (evt: React.MouseEvent) => {
        evt.stopPropagation()
        if (phoneNumber) {
            if (call && !call.incoming && call.connections.length === 1) {
                addConnection(phoneNumber)
            } else {
                setNumberDialling(phoneNumber)
                makeCall(phoneNumber)
            }
        }
    }

    const manageDialButton = () => {
        if (typeof showButton === 'boolean') {
            return showButton
        }
        return isDialButtonShown
    }

    return (
        <>
            {phoneNumber && (
                <div
                    className={styles.phoneNumberWrapper}
                    onMouseOver={showDialButton}
                    onFocus={showDialButton}
                    onMouseLeave={hideDialButton}
                >
                    <div className="row no-stretch">
                        <Button
                            className={styles.phoneNumber}
                            onClick={handlePhoneNumberClick}
                            onKeyPress={handleKeyPress}
                        >
                            {phoneNumber}
                        </Button>

                        {showMoreNumbersSign && additionalNumbersAmount && (
                            <span className={styles.morePhoneNumbers}>
                                +{additionalNumbersAmount}
                            </span>
                        )}
                    </div>
                    <DialButton
                        isDialButtonShown={manageDialButton()}
                        onClick={handleOnDialNumberClick}
                    />
                </div>
            )}
        </>
    )
}

export default connect(null, {
    makeCall,
    setNumberDialling,
    addConnection,
})(ContactPhoneNumber)

import { Participant } from 'components'

import { getColourClass, getStatus } from 'components/ParticipantList/utils'

import type { CallConnection } from 'store/callV2/callV2.state'

export type Props = {
    connection: CallConnection
}

const YouConnection = ({ connection }: Props) => {
    const { id, name, type = 'UNKNOWN', status: connectionStatus, isMuted, controls } = connection

    const { type: statusType, timestamp } = connectionStatus

    const isOnHold = statusType === connect.ConnectionStateType.HOLD

    const status = getStatus(type, statusType)

    const colourClass = getColourClass(type, statusType)

    return (
        <Participant
            id={id}
            name={name}
            status={status}
            type={type}
            initiationDate={timestamp}
            isMuted={isMuted}
            isOnHold={isOnHold}
            controls={controls}
            colourClass={colourClass}
        />
    )
}

export default YouConnection

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IDirectoryRecordOwner, IOwners } from './state'

const initialState: IOwners = {
    data: [],
    getOwnersLoading: undefined,
}

export const ownersDirectorySlice = createSlice({
    name: 'ownersDirectory',
    initialState,
    reducers: {
        getOwners(state, action: PayloadAction<IDirectoryRecordOwner[]>) {
            return { ...state, data: action.payload }
        },
        getOwnersLoading(state, action: PayloadAction<boolean>) {
            return { ...state, getOwnersLoading: action.payload }
        },
    },
})

export const { getOwners, getOwnersLoading } = ownersDirectorySlice.actions

export type OwnersDirectoryAction = ReturnType<
    (typeof ownersDirectorySlice.actions)[keyof typeof ownersDirectorySlice.actions]
>

export default ownersDirectorySlice.reducer

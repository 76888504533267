import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { Box } from '@missionlabs/smartagent-app-components'

import useAppConfig from 'hooks/redux/useAppConfig'
import useMetrics from 'hooks/redux/useMetrics'
import useMonitoring from 'hooks/redux/useMonitoring'
import useHasFeature, { AppFeatures, AppSubFeatures } from 'hooks/useHasFeature'

import Controls from 'views/Home/Monitoring/Controls'
import YouConnection from 'views/Home/YouConnection'

import { ParticipantControls, ParticipantList } from 'components'
import CallSummary from 'views/Home/Dialler/CallSummary'

import { leaveCall, updateMonitorStatus } from 'store/callV2/callV2.actions'
import { monitorAgent } from 'store/metrics/metrics.actions'

import type { CallConnection } from 'store/callV2/callV2.state'

import { getActiveConnections, getConnectionList } from 'views/Home/utils'

import styles from './monitoring.module.scss'

export type Props = {
    connections: CallConnection[]
}

const Monitoring: React.FC<Props> = ({ connections }) => {
    const dispatch = useDispatch()

    const hasFeature = useHasFeature()
    const { isBarging } = useMonitoring()

    const { allowBarge = false } = useAppConfig()
    const hasBargeSubFeature = hasFeature(AppFeatures.LIVE_MONITORING, AppSubFeatures.ALLOW_BARGE)

    const [youConnection, ...otherConnections] = useMemo(
        () => getConnectionList(connections),
        [connections],
    )

    const canEndMonitoring = !isBarging || getActiveConnections(otherConnections).length === 1
    const canReturnToMonitoring = isBarging && getActiveConnections(otherConnections).length > 1
    const canBarge = !isBarging && allowBarge && hasBargeSubFeature

    const { monitoringAgent } = useMetrics()

    const onReturnToMonitoring = () => {
        dispatch(updateMonitorStatus('SILENT_MONITOR'))
    }

    const onBarge = () => {
        dispatch(updateMonitorStatus('BARGE'))
    }

    const onEndMonitoring = () => {
        dispatch(monitorAgent(false, monitoringAgent?.agentID))
        dispatch(leaveCall())
    }

    return (
        <>
            <Box
                className={styles.participants}
                boxLabel="Other participants"
                header={<CallSummary />}
            >
                <ParticipantList connections={otherConnections} />
                <ParticipantControls connections={connections} />
            </Box>
            <section aria-labelledby={youConnection.id} className={styles.controls}>
                <YouConnection connection={youConnection} />
                <Controls
                    canBarge={canBarge}
                    onBarge={onBarge}
                    canReturnToMonitoring={canReturnToMonitoring}
                    canEndMonitoring={canEndMonitoring}
                    onReturnToMonitoring={onReturnToMonitoring}
                    onEndMonitoring={onEndMonitoring}
                />
            </section>
        </>
    )
}

export default Monitoring

import { Tag } from '@missionlabs/smartagent-app-components'
import React from 'react'
import { CalibrationStatus } from 'store/qa/qa.state'
import { capitaliseStr } from 'utils'
import { QAStatus } from 'views/QA/ScoreView/FormBuilderQA/FormBuilderQA.types'

interface Props {
    percent?: number
    status?: QAStatus | CalibrationStatus
    failType?: string
}

const QAScoreTag: React.FC<Props> = ({ percent, status, failType }) => {
    const getPercent = () => {
        if (percent === undefined) return null
        if (percent < 0) return 0
        if (percent > 100) return 100
        return Math.floor(Math.round((percent + Number.EPSILON) * 100) / 100)
    }

    if (!status) return null

    return (
        <Tag
            small
            type={
                status === QAStatus.PASS
                    ? 'green'
                    : status === QAStatus.FAIL || status === CalibrationStatus.CLOSED
                      ? 'red'
                      : 'white'
            }
        >
            <span>{!!failType ? failType : capitaliseStr(status, '_')}</span>
            {status !== QAStatus.IN_PROGRESS && percent !== undefined && (
                <span>: {getPercent()}%</span>
            )}
        </Tag>
    )
}

export default QAScoreTag

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { jsxs, Fragment, jsx } from "react/jsx-runtime";
import React, { PureComponent } from "react";
import classNames from "classnames";
import ReactDOM from "react-dom";
const body = document.getElementsByTagName("body")[0];
class Tooltip extends PureComponent {
  constructor() {
    super(...arguments);
    __publicField(this, "state", {
      show: false
    });
    __publicField(this, "tooltip");
    __publicField(this, "trigger");
    __publicField(this, "setTooltip", () => {
      if (!this.tooltip || !this.trigger)
        return;
      const triggerPos = this.trigger.getBoundingClientRect();
      const tooltipPos = this.tooltip.getBoundingClientRect();
      this.tooltip.style.left = triggerPos.left + triggerPos.width / 2 - tooltipPos.width / 2 + "px";
      const tooltipArrowHeight = 8;
      const topOffset = this.props.rotate ? triggerPos.bottom + tooltipArrowHeight : triggerPos.top - tooltipPos.height - tooltipArrowHeight;
      this.tooltip.style.top = `${topOffset}px`;
    });
    __publicField(this, "onHide", () => {
      this.setState({ show: false });
    });
    __publicField(this, "onShow", () => {
      this.setState({ show: true });
    });
  }
  componentDidUpdate() {
    if (!this.tooltip)
      return;
    this.setTooltip();
  }
  render() {
    const { className, text, children } = this.props;
    if (!this.props.text)
      return children;
    if (this.props.customWidth && !className)
      return children;
    const containerClasses = classNames({
      "tooltipContainer": true,
      "tooltipRotated": this.props.rotate,
      "defaultWidth": !this.props.customWidth,
      [className]: !!className
    });
    const tooltipBody = /* @__PURE__ */ jsx("div", { className: containerClasses, ref: (r) => this.tooltip = r, children: /* @__PURE__ */ jsx("div", { role: "tooltip", className: "tooltip", children: /* @__PURE__ */ jsx("div", { className: "tooltip-inner", children: text }) }) });
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      React.cloneElement(children, {
        onMouseEnter: this.onShow,
        onMouseLeave: this.onHide,
        onFocus: this.onShow,
        onBlur: this.onHide,
        ref: (r) => this.trigger = r
      }),
      this.props.openOnFocus ? /* @__PURE__ */ jsxs(
        "div",
        {
          tabIndex: 0,
          onFocus: this.onShow,
          onBlur: this.onHide,
          children: [
            text && /* @__PURE__ */ jsxs("div", { className: "tooltipScreenReaderOnly", children: [
              "Tooltip content: ",
              text
            ] }),
            this.state.show && text && ReactDOM.createPortal(
              tooltipBody,
              body
            )
          ]
        }
      ) : this.state.show && text && ReactDOM.createPortal(
        tooltipBody,
        body
      )
    ] });
  }
}
export {
  Tooltip as default
};

import classNames from 'classnames'
import React, { useMemo } from 'react'

import { Tag } from '@missionlabs/smartagent-app-components'

import AttachmentIcon from 'images/attachment.svg'
import { ChannelType } from '../../types'
import { ITag } from '../types'
import { formatDisconnectReason, getChannelIcon } from '../utils'

import './interaction-information.scss'

export interface Props {
    agentName: string
    channelType: ChannelType
    tags: ITag[]
    date: string
    initiationMethod: string
    subject?: string
    duration?: string
    disabled?: boolean
    focus?: boolean
    role?: React.AriaRole
    id?: React.HTMLAttributes<Element>['id']
    onFocus?: React.FocusEventHandler<Element>
    onKeyDown?: React.KeyboardEventHandler<Element>
    tabIndex?: React.HTMLAttributes<Element>['tabIndex']
    'aria-label'?: React.AriaAttributes['aria-label']
    hasAttachments?: boolean
    disconnectWithNoAgentReason?: string
    endpointAddress?: string
    queueName?: string
}

const InteractionInformation: React.FC<Props> = ({
    agentName,
    channelType,
    tags,
    date,
    initiationMethod,
    subject,
    duration,
    disabled = false,
    focus = false,
    role = 'none',
    id,
    onFocus,
    onKeyDown,
    tabIndex,
    hasAttachments = false,
    disconnectWithNoAgentReason,
    endpointAddress,
    queueName,
}) => {
    const getQueueName = () => {
        if (queueName) {
            return queueName
        } else if (initiationMethod === 'TRANSFER') {
            return 'Transferred contact'
        } else {
            return 'No queue name found'
        }
    }

    // Constructs a label for screen readers based of the available properties.
    const label = useMemo(() => {
        const labels = new Array(3)

        // Let screen readers announce a list of associated tags (will be an empty string if array is empty).
        const contactTypes = tags.map((tag) => tag.text).join(', ')

        labels[0] = `Contact from: ${endpointAddress || 'Unknown endpoint'}`
        if (contactTypes) labels[1] = `Contact type: ${contactTypes}`
        labels[2] = `Date: ${date}`

        return labels.filter((label) => !!label).join(', ')
    }, [date, endpointAddress, tags])

    return (
        <div
            id={id}
            aria-labelledby={id ? `${id}--label` : undefined}
            tabIndex={tabIndex}
            role={role}
            className={classNames({
                'contact-history-interaction-information-outer-flex': true,
                disabled,
                focus,
            })}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
        >
            {id && (
                <span id={`${id}--label`} className="only-visible-to-screen-readers">
                    {label}
                </span>
            )}
            <img
                src={getChannelIcon(channelType)}
                alt={`Via "${channelType}" channel`}
                className="contact-history-interaction-information-channel-image"
            />
            <div className="contact-history-interaction-information-inner-flex">
                <div className="contact-history-interaction-information-inner-flex-top">
                    <div className="contact-history-interaction-information-inner-flex-top-left">
                        <strong>{endpointAddress || 'Unknown endpoint'}</strong>
                        <span className="contact-history-interaction-information-detail">
                            {disconnectWithNoAgentReason ? (
                                <b>{formatDisconnectReason(disconnectWithNoAgentReason)}</b>
                            ) : (
                                <>
                                    <b>Agent:</b> {agentName}
                                </>
                            )}
                        </span>
                        {subject && (
                            <span className="contact-history-interaction-information-detail">
                                <b>Subject:</b> {subject}
                            </span>
                        )}
                        <span className="contact-history-interaction-information-detail">
                            <b>Queue:</b> {getQueueName()}
                        </span>
                    </div>
                    <div className="contact-history-interaction-information-inner-flex-top-right">
                        <div className="contact-history-interaction-information-date">{date}</div>
                        {duration && (
                            <div className="contact-history-interaction-information-duration">
                                Duration: {duration}
                            </div>
                        )}
                        {hasAttachments && (
                            <img
                                src={AttachmentIcon}
                                alt="Email attachment icon"
                                className="contact-history-interaction-information-attachment-icon"
                            />
                        )}
                    </div>
                </div>
                <div className="contact-history-interaction-information-inner-flex-bottom">
                    <ul className="contact-history-interaction-information-tags">
                        {tags.map(({ colour, text }, index) => (
                            <li key={index}>
                                <Tag type={colour} text={text} small square />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default InteractionInformation

import WSCompanionClient from 'services/Companion/Websocket'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import WebsocketCompanionState from './websocketCompanion.state'

const initialState: WebsocketCompanionState = {
    client: null,
}

export const websocketCompanionSlice = createSlice({
    name: 'websocketCompanion',
    initialState,
    reducers: {
        createWebsocketCompanionConnection(state, action: PayloadAction<WSCompanionClient>) {
            return { ...state, client: action.payload }
        },
    },
})

export const { createWebsocketCompanionConnection } = websocketCompanionSlice.actions

export type WebsocketCompanionAction = ReturnType<
    (typeof websocketCompanionSlice.actions)[keyof typeof websocketCompanionSlice.actions]
>

export default websocketCompanionSlice.reducer

import { v4 } from 'uuid'

import type { ColourClass, Status, Tag } from 'components/ParticipantList/types'

import type { CallConnection, ConnectionType } from 'store/callV2/callV2.state'
import type { ICallContactAttributes } from 'store/contact/contact.state'

import {
    IGNORED_TAG_ATTRIBUTES,
    TRACKED_TAG_ATTRIBUTES,
} from 'components/ParticipantList/constants'

export const getStatus = (
    type: ConnectionType,
    statusType: connect.ConnectionStateType,
): Status => {
    let status: Status = 'Unknown'

    switch (statusType) {
        case connect.ConnectionStateType.CONNECTING:
            status = 'Invited to join...'
            break
        case connect.ConnectionStateType.SILENT_MONITOR:
            status = 'Monitoring'
            break
        case connect.ConnectionStateType.BARGE:
            status = 'Barging'
            break
        case connect.ConnectionStateType.HOLD:
            status = 'On hold'
            break
        case connect.ConnectionStateType.DISCONNECTED:
            status = 'Disconnected'
            break
    }

    if (status !== 'Unknown') return status

    switch (type) {
        case 'AGENT':
        case 'YOU':
            status = 'Connected agent'
            break
        case 'QC_TRANSFER':
            status = 'Transferred agent'
            break
        case 'OUTBOUND_TRANSFER':
            status = 'External outbound'
            break
        case 'CUSTOMER':
            status = 'Call started'
            break
    }

    return status
}

export const getColourClass = (
    type: ConnectionType,
    status: connect.ConnectionStateType,
): ColourClass => {
    switch (status) {
        case connect.ConnectionStateType.CONNECTING:
            return 'connecting'
        case connect.ConnectionStateType.HOLD:
            return 'hold'
        case connect.ConnectionStateType.DISCONNECTED:
            return 'disconnected'
        default:
            return type === 'YOU' ? 'you' : 'default'
    }
}

export function shouldHoldOtherAgent(connections: CallConnection[]): boolean {
    return connections.length === 2 && connections.every(({ type }) => type !== 'CUSTOMER')
}

export const buildTagAttributeFilter = (
    attributeKey: string,
    trackedAttributes: string[] = [],
    ignoredAttributes: string[] = [],
): boolean => {
    const isTagColourAttribute = attributeKey.endsWith('colour')
    const isTrackedAttribute = trackedAttributes.includes(attributeKey)
    const isIgnoredAttribute = ignoredAttributes.includes(attributeKey)
    const isTagAttribute = attributeKey.startsWith('sa-tag')

    return (isTagAttribute && !isTagColourAttribute && !isIgnoredAttribute) || isTrackedAttribute
}

export const constructTags = (contactAttributes: ICallContactAttributes | undefined): Tag[] => {
    if (!contactAttributes) return []

    const tags: Partial<Tag>[] = []

    const filteredTextTagAttributes = Object.fromEntries(
        Object.entries(contactAttributes).filter(([key]) =>
            buildTagAttributeFilter(key, TRACKED_TAG_ATTRIBUTES, IGNORED_TAG_ATTRIBUTES),
        ),
    )

    for (const key in filteredTextTagAttributes) {
        const text = contactAttributes[key]
        const colour = contactAttributes[`${key}-colour`] ?? 'blue-grey'

        tags.push({
            text,
            colour,
        })
    }

    const tagName = contactAttributes['sa-tag-name']

    if (tagName) {
        tags.push({ text: tagName })
    }

    const tagImage = contactAttributes['sa-tag-image']

    if (tagImage) {
        tags.push({ text: tagName, src: tagImage })
    }

    // initialise the id property for all tags in a single map
    return tags.map<Tag>((tag) => ({ ...tag, id: v4() }))
}

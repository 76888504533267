import { jsx } from "react/jsx-runtime";
import { isValid } from "date-fns";
import { forwardRef, useState, useEffect } from "react";
import { Input } from "./Input.mjs";
const ReactDatePicker = forwardRef(
  ({ handleChange: _handleChange, onChange, value: _value, ...props }, ref) => {
    const [value, setValue] = useState(() => {
      let v = /* @__PURE__ */ new Date();
      if (_value instanceof Date && isValid(_value))
        v = _value;
      else if (!!_value)
        v = new Date(_value);
      return v.toISOString().split("T")[0];
    });
    function handleChange(value2) {
      const handler = _handleChange ?? onChange;
      const dateValue = new Date(value2);
      if (isValid(dateValue))
        handler == null ? void 0 : handler(dateValue);
      setValue(value2);
    }
    useEffect(() => {
      handleChange(value);
    }, []);
    return /* @__PURE__ */ jsx(
      Input,
      {
        ...props,
        ref,
        type: "date",
        style: { ...props.style ?? {}, height: "40px" },
        value,
        onChange: handleChange
      }
    );
  }
);
ReactDatePicker.displayName = "ReactDatePicker";
export {
  ReactDatePicker as default
};

import type { ICTRSummary } from '@missionlabs/smartagent-lib-shared/build/ctr-summary'

export interface CustomerState {
    email?: string
    phoneNumber: string
    name?: string
    ID?: string
}

export enum DPAResult {
    'no-check' = 'no-check',
    'bot-passed' = 'bot-passed',
    'failed' = 'failed',
    'agent-passed' = 'agent-passed',
    'agent-overall-passed' = 'agent-overall-passed',
}

export interface DPACheck {
    label: string
    passed: boolean | null | 'manual-check'
    answer: string | null
}

export interface DPAState {
    checks: DPACheck[]
    agentCheck?: DPACheck
    result: DPAResult
    passesRequired?: number
}

export interface ICallContactAttributes {
    [key: string]: any
}

export interface CTR extends ICTRSummary {
    customerEndpointAddress?: string
    attributes: ICallContactAttributes
    originalContactID?: string
}

export type ICustomerInputs = Array<{
    label: string
    value: string
}>

export interface IOriginalContactState {
    Id: string
    Arn: string
    InitiationMethod: string
    Channel: string
    QueueInfo: {
        Id: string
        EnqueueTimestamp: string
    }
    AgentInfo: {
        Id: string
        ConnectedToAgentTimestamp: string
    }
    InitiationTimestamp: string
    LastUpdateTimestamp: string
}

export interface UpdateACWAttributesAction {
    acwAttributes: ICallContactAttributes
    ID: string
}

export interface ContactUpdatedAction {
    ID: string
    attributes: ICallContactAttributes
    commitUpdate?: boolean
}

export default interface ContactState extends CTR {
    DPA?: DPAState
    customerInputs?: ICustomerInputs
    tags?: string[]
    customer?: CustomerState
    parkBay?: number
    plugins?: {
        [key: string]: 'show' | 'hide'
    }
    acwAttributes?: ICallContactAttributes
    initiationMethodDisplay?: CTR['initiationMethod'] | `${CTR['initiationMethod']} - BARGED`
}

import React from 'react'
import { IWaveformChannel } from './AudioPlayer'
import { Blip } from './Blip'

interface Props {
    channel?: IWaveformChannel
    currentTimePercentage: number
    onSeek(e: React.MouseEvent<HTMLDivElement>, seek: Boolean): void
    width: number
    id: number
}

export const Waveform: React.FC<Props> = ({
    channel,
    currentTimePercentage,
    onSeek,
    width,
    id,
}) => {
    if (!channel) {
        return (
            <div className="track-player-container">
                <div
                    className="track-player no-waveform"
                    onClick={(e) => onSeek(e, true)}
                    role="presentation"
                >
                    <div className="line-container">
                        <div className="line" style={{ width: `${currentTimePercentage}%` }} />
                    </div>
                </div>
            </div>
        )
    }

    const blipWidth = width >= 1600 ? 2 : 1.5
    const blipMargin = width / channel.data.length - blipWidth

    return (
        <div className="waveform" onClick={(e) => onSeek(e, true)}>
            {channel.data.map((value, i) => (
                <Blip
                    id={id}
                    width={blipWidth}
                    marginRight={blipMargin}
                    key={i}
                    value={value}
                    max={channel.max}
                />
            ))}
        </div>
    )
}

import { PureComponent } from 'react'
import { ClipLoader } from 'react-spinners'

import { Button } from '@missionlabs/smartagent-app-components'

interface Props {
    loading?: boolean
    onClick?(): void
}

export default class LoginSplash extends PureComponent<Props> {
    render() {
        return (
            <div className="row column auto">
                <p>Let's get started</p>
                <Button
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    className="md-mar-top"
                    round
                    large
                    onClick={this.props.onClick}
                    styling="primary"
                >
                    {this.props.loading ? <ClipLoader color="#fff" size={20} /> : 'Login'}
                </Button>
            </div>
        )
    }
}

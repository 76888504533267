import { Button, DataField } from '@missionlabs/smartagent-app-components'
import useHasFeature, { AppFeatures, AppSubFeatures } from 'hooks/useHasFeature'
import QAIcon from 'images/icon-qa-dark.svg'
import React from 'react'
import { IScoreDetails } from 'store/qa/qa.state'
import { QAStatus } from 'views/QA/ScoreView/FormBuilderQA/FormBuilderQA.types'
import {
    getSectionMaxScore,
    getSectionScore,
} from 'views/QA/ScoreView/FormBuilderQA/FormBuilderQA.util'

interface Props {
    data: IScoreDetails | undefined
    goToScore(): void
    hideLink?: boolean
    scoreID?: string
}

const Evaluation: React.FC<Props> = ({ data, goToScore, hideLink, scoreID }) => {
    const hasFeature = useHasFeature()

    if (!scoreID)
        return (
            <div className="sa-qa-status-widget row column middle evenly md-pad ">
                <img src={QAIcon} alt="" />
                <p>This contact has not been evaluated.</p>
                {hasFeature(AppFeatures.QA, AppSubFeatures.QA_CREATE) && !hideLink && (
                    <Button round onClick={goToScore} styling="primary">
                        Create evaluation
                    </Button>
                )}
            </div>
        )
    //If they dont have permissions to view the score
    if (!data)
        return (
            <div className="sa-qa-status-widget row column middle evenly md-pad ">
                <img src={QAIcon} alt="" />
                <p>The contact has been evaluated</p>
            </div>
        )

    if (data.status === QAStatus.IN_PROGRESS)
        return (
            <div className="sa-qa-status-widget row column middle evenly md-pad ">
                <img src={QAIcon} alt="" />
                <p>The contact is currently being evaluated by {data.evaluatorName} </p>
                {hasFeature(AppFeatures.QA, AppSubFeatures.QA_CREATE) && !hideLink && (
                    <Button round onClick={goToScore} styling="dark">
                        Open evaluation
                    </Button>
                )}
            </div>
        )

    const isDefined = (a: any) => a !== null && a !== undefined

    return (
        <div className="sa-qa-status-widget row column">
            <div className="row wrap">
                {data.form.sections.map((s) => {
                    const maxScore = getSectionMaxScore(s)
                    const score = getSectionScore(s, data.results?.fields ?? [])
                    return (
                        <DataField basis={25} title={s.name} key={s.id}>
                            <p>
                                {score} {isDefined(maxScore) && `out of ${maxScore}`}
                            </p>
                        </DataField>
                    )
                })}
            </div>
            {hasFeature(AppFeatures.QA, AppSubFeatures.QA_READ) && !hideLink && (
                <div className="md-pad row auto">
                    <Button round onClick={goToScore} styling="dark">
                        Open evaluation
                    </Button>
                </div>
            )}
        </div>
    )
}

export default Evaluation

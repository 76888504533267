import classNames from 'classnames'
import React, { Fragment } from 'react'
import sanitize from 'sanitize-html'
import { v4 } from 'uuid'

import { Tag } from '@missionlabs/smartagent-app-components'

import { formatInteractionDate } from 'utils'
import { EmailMessageType, IEmailAttachment, TagMap } from './types'

import './email-message.scss'

export interface Props {
    timestamp: number
    from: string
    to: string
    content: string
    subject: string
    type: EmailMessageType
    cc: string
    attachments: IEmailAttachment[]
    highlight?: boolean
}

const EmailMessage: React.FC<Props> = ({
    subject,
    to,
    from,
    content,
    timestamp,
    type,
    cc,
    highlight,
    attachments,
}) => {
    const tagColourMap: TagMap = {
        CUSTOMER: {
            text: 'Customer',
            colour: 'orange',
        },
        AGENT: {
            text: 'Agent',
            colour: 'sa-blue',
        },
        SYSTEM: {
            text: 'System',
            colour: 'green',
        },
        EXTERNAL: {
            text: 'External',
            colour: 'sa-grey',
        },
        CCREPLY: {
            text: 'Cc',
            colour: 'sa-grey',
        },
    }

    const { text = 'Unknown', colour = 'sa-grey' } = tagColourMap[type] ?? {}

    const sanitisedContent = sanitize(content)

    const headerId = v4()

    return (
        <section
            className={classNames({
                'email-message': true,
                highlight,
            })}
            aria-labelledby={headerId}
        >
            <header id={headerId} className="email-message-header">
                <div className="email-message-header-title">
                    <h2 className="email-message-header-title-name">
                        <b>{from}</b>
                    </h2>
                    <Tag type={colour} text={text} square small />
                </div>
                <time className="email-message-header-date">
                    {formatInteractionDate(timestamp)}
                </time>
            </header>
            <div className="email-message-details">
                <h3 className="email-message-details-item">
                    <b>To:</b> {to}
                </h3>
                <h3 className="email-message-details-item">
                    <b>Cc:</b> {cc}
                </h3>
                <h3 className="email-message-details-item">
                    <b>Subject:</b> {subject}
                </h3>
                {attachments && attachments.length > 0 && (
                    <h3 className="email-message-details-item">
                        <b>Attachments ({attachments.length}): </b>
                        {attachments.map(({ s3URL, filename }, index) => (
                            <Fragment key={index}>
                                {index > 0 && ', '}
                                <a href={s3URL} rel="noreferrer" target="_blank">
                                    {filename}
                                </a>
                            </Fragment>
                        ))}
                    </h3>
                )}
            </div>
            <div
                className="email-message-content"
                dangerouslySetInnerHTML={{ __html: sanitisedContent }}
            />
        </section>
    )
}

export default EmailMessage

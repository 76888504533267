import Bold from './toolbar-bold.svg'
import Italic from './toolbar-italic.svg'
import Underline from './toolbar-underline.svg'

import Ordered from './toolbar-ordered.svg'
import Unordered from './toolbar-unordered.svg'

import Emoji from './toolbar-emoji.svg'

import Attachment from './toolbar-attachment.svg'
import Hyperlink from './toolbar-hyperlink.svg'

const images = {
    // Inline
    Bold,
    Italic,
    Underline,

    // List
    Ordered,
    Unordered,

    Emoji,

    Hyperlink,
    Attachment,
}

export default images

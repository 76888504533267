import { TriggeredPopup } from '@missionlabs/smartagent-app-components'
import classNames from 'classnames'
import { DialerControlButton } from 'components'
import useApp from 'hooks/redux/useApp'
import useAuth from 'hooks/redux/useAuth'
import QueueListButtton from 'images/queue-list-icon.svg'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getEnabledQueuesWithOutboundCallerIdNumber } from 'services/api/api.queue'
import { IEnabledQueue } from 'store/settings/settings.state'
import RootState from 'store/state'
import { getBodyWidth, getIdFromARN } from 'utils'
import { SearchList } from '../../../../components'
import { Option } from '../../../../components/SearchList'
import styles from './OutboundQueueSelector.module.scss'

interface Props {
    onOutboundQueueSelect: (queueData: IEnabledQueue) => void
    selectedOutboundQueue?: IEnabledQueue
    isQueueListShown?: boolean
    onOpen: () => void
    onClose: () => void
}

const OutboundQueueSelector = ({
    onOutboundQueueSelect,
    selectedOutboundQueue,
    isQueueListShown,
    onOpen,
    onClose,
}: Props) => {
    const disabled = useSelector((state: RootState) => !state.user)
    const user = useSelector((state: RootState) => state.user)

    const app = useApp()
    const auth = useAuth()

    const [outboundQueues, setOutboundQueues] = useState<IEnabledQueue[]>([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const mapQueues = (queues?: connect.Queue[]) => {
            return queues && queues?.length > 0
                ? queues.map(({ queueId }) => getIdFromARN(queueId)).filter((queue) => !!queue)
                : []
        }

        if (isQueueListShown) {
            getEnabledQueuesWithOutboundCallerIdNumber(
                app.ID,
                app.instance!.ID,
                auth.token!,
                mapQueues(user?.queues),
            )
                .then((data) => {
                    setOutboundQueues(data)
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }, [isQueueListShown])

    const itemsToRender = useMemo(() => {
        const mapQueue = (queue: IEnabledQueue) => ({
            label: queue.name,
            value: queue,
        })

        return outboundQueues
            .map(mapQueue)
            .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1))
    }, [outboundQueues])

    const selectedOueue = useMemo(() => {
        if (selectedOutboundQueue) {
            return {
                label: selectedOutboundQueue.name,
                value: selectedOutboundQueue,
            }
        }
    }, [selectedOutboundQueue])

    const handleOnItemSelected = (item: Option) => {
        onOutboundQueueSelect(item.value as IEnabledQueue)
        onClose()
    }

    return (
        <TriggeredPopup
            active={isQueueListShown}
            closeOnClickOutside
            onOpen={onOpen}
            onClose={onClose}
            noShadow
            fromCenter={getBodyWidth() > 500}
            className={classNames(styles.popup, {
                [`${styles['popup--visible']}`]: isQueueListShown,
                [`${styles['popup--hidden']}`]: !isQueueListShown,
            })}
            trigger={
                <DialerControlButton
                    disabled={disabled}
                    onClick={onClose}
                    className="keypad"
                    isFocused={isQueueListShown}
                    type="button"
                >
                    <img
                        src={QueueListButtton}
                        alt="Outbound queue list"
                        title="Outbound queue list"
                    />
                </DialerControlButton>
            }
        >
            <div className={styles.wrapper}>
                <SearchList
                    headerName="Select Queue"
                    isLoading={isLoading}
                    selectedItem={selectedOueue}
                    itemsList={itemsToRender}
                    onItemSelected={handleOnItemSelected}
                    className={styles.searchList}
                />
            </div>
        </TriggeredPopup>
    )
}

export default OutboundQueueSelector

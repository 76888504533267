function getObjectSubset(data, prefix) {
  return Object.keys(data).filter((key) => {
    return key.indexOf(prefix) === 0;
  }).reduce((fresh, key) => {
    fresh[key] = data[key];
    return fresh;
  }, {});
}
export {
  getObjectSubset as g
};

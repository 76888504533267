import React from 'react'
import { IContactContext, withContactConsumerContext } from 'widgets/contexts/contact'
import { ITranscript } from '.'
import Message from './Message'
import './transcript.scss'

interface Props extends ITranscript {
    context: IContactContext
}

const Transcript: React.FC<Props> = ({ messages, context }) => {
    const isMessageFocused = (
        sp: number | undefined,
        cur: string | undefined,
        next: string | undefined,
    ) => {
        if (!sp || !cur) return false // context doesnt exist or info missing so bail

        const parsedCur = parseInt(cur)

        if (sp < parsedCur) return false // we are behind this message

        if (!next) return true // we are on the last message

        const parsedNext = parseInt(next)

        if (sp < parsedNext) return true // we are in front of current but behind next

        return false // next must also be higher
    }

    return (
        <div className="row column sa-transcript">
            {messages.map((message, i, arr) => (
                <Message
                    key={i}
                    focused={isMessageFocused(
                        context.seekProgress,
                        message.startTime,
                        arr[i + 1]?.startTime,
                    )}
                    message={message}
                    seekTo={context.updateSeekTo}
                />
            ))}
        </div>
    )
}

export default withContactConsumerContext(Transcript)

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { EmailConfigsAction, EmailConfigsLoadingAction, EmailSettingsState } from './email.state'

const initialState: EmailSettingsState = {}

export const emailSettingsSlice = createSlice({
    name: 'emailSettings',
    initialState,
    reducers: {
        getEmailConfigs(state, action: PayloadAction<EmailConfigsAction>) {
            return {
                ...state,
                [action.payload.brand]: {
                    emailConfigs: action.payload.emailConfigs,
                    isLoading: false,
                },
            }
        },
        getEmailConfigsLoading(state, action: PayloadAction<EmailConfigsLoadingAction>) {
            return {
                ...state,
                [action.payload.brand]: {
                    ...state[action.payload.brand],
                    isLoading: action.payload.isLoading,
                },
            }
        },
    },
})

export const { getEmailConfigs, getEmailConfigsLoading } = emailSettingsSlice.actions

export type EmailSettingsAction = ReturnType<
    (typeof emailSettingsSlice.actions)[keyof typeof emailSettingsSlice.actions]
>

export default emailSettingsSlice.reducer

import CloseIcon from 'images/icon-cross-grey.svg'
import React from 'react'
import Draggable from 'react-draggable'
import { useDispatch, useSelector } from 'react-redux'
import { setQueuedTasksProcessingShowModal } from 'store/queuedTasks/queuedTasks.actions'
import { ProcessStatuses } from 'store/queuedTasks/queuedTasks.state'
import RootState from 'store/state'
import './process-modal.scss'

interface Props {}

const ProcessModalDraggable: React.FC<Props> = () => {
    const dispatch = useDispatch()
    const { completed, processing, errored, actioned } = useSelector<RootState, ProcessStatuses>(
        (state) => state.queuedTasks.queuedTasksProcessingStatuses,
    )
    const contacts = useSelector<RootState, string[]>(
        (state) => state.queuedTasks.queuedTasksProcessingContacts,
    )

    const complete = contacts.length === completed
    const completedContacts = `Completed ${contacts.length} contacts have been processed`
    const processingContacts = `Processing ${contacts.length} contacts`
    return (
        <Draggable bounds="parent">
            <div className="drag-progress-move-popup">
                <div className="drag-progress-move-popup-inner">
                    <div className="drag-progress-move-popup-header">
                        <span className="drag-progress-move-popup-p1">Queued Tasks</span>

                        <span className="drag-progress-move-popup-p2">
                            {complete ? completedContacts : processingContacts}
                        </span>
                    </div>

                    <div className="circles">
                        <div className="circles-process-wrapper">
                            <div className="circles-process" style={{ backgroundColor: '#007D69' }}>
                                <span className="circles-process-number">{completed}</span>
                            </div>

                            <span className="circles-process-status">Successful</span>
                        </div>

                        <div className="circles-process-wrapper">
                            <div className="circles-process" style={{ backgroundColor: '#F4F5F6' }}>
                                <span className="circles-process-numberBlack">
                                    {processing || 0}
                                </span>
                            </div>

                            <span className="circles-process-status">In Progress</span>
                        </div>

                        <div className="circles-process-wrapper">
                            <div className="circles-process" style={{ backgroundColor: '#D23219' }}>
                                <span className="circles-process-number">{errored}</span>
                            </div>

                            <span className="circles-process-status">Failed</span>
                        </div>

                        <div className="circles-process-wrapper">
                            <div className="circles-process" style={{ backgroundColor: '#1D6098' }}>
                                <span className="circles-process-number">{actioned}</span>
                            </div>

                            <span className="circles-process-status">Already Actioned</span>
                        </div>
                    </div>

                    <p className="text-processing">This may take up to 60 seconds to complete</p>
                </div>
                <img
                    src={CloseIcon}
                    alt="Close Button"
                    className="drag-progress-move-popup-closeIcon"
                    onClick={() => dispatch(setQueuedTasksProcessingShowModal(false))}
                />
            </div>
        </Draggable>
    )
}

export default ProcessModalDraggable

import type { Middleware } from '@reduxjs/toolkit'
import logger from 'logger'
import * as CallActions from 'store/call/call.actions'
import * as CallReducer from 'store/call/call.reducer'
import * as ChatReducer from 'store/chat/chat.reducer'
import RootState from 'store/state'
import { afterCallWork } from 'store/user/user.reducer'
import { formatStartOfNumber, isAnyAction } from 'utils'

import {
    parseChatMessage,
    parseToPlainText,
} from '@missionlabs/smartagent-chat-components-lib/dist/utils'

type EventTypes =
    | 'INBOUND'
    | 'UPDATEACTIVITY'
    | 'TRANSFER'
    | 'OUTBOUND'
    | 'CALLBACK'
    | 'API'
    | 'QUEUE_TRANSFER'
type Action = 'NOTIFY' | 'ACCEPT' | 'END'
type ConnectionType = 'CALL' | 'CHAT'

enum connectionTypes {
    CALL = 'CALL',
    CHAT = 'CHAT',
}
type C4CBase = {
    Type: ConnectionType
    EventType: EventTypes
    CID: string // 'BCM1234';
    Action?: Action
    ExternalReferenceID: string
}

interface C4Call extends C4CBase {
    ANI: string
}
interface C4CChat extends C4CBase {
    Email?: string
    Transcript?: string
}

interface OutboundCall {
    PhoneNumber: string
    LoggedInUserID: string
    ActivityUUID: string
}
class C4C {
    constructor() {
        window.addEventListener('message', this.onMessage)
    }

    onMessage = (event: MessageEvent) => {
        //Ignore ccp messages
        if (event.origin.includes('awsapps.com') || event.origin.includes('connect.aws')) return
        if (event.data?.Direction === 'OUT' && event.data?.PhoneNumber) {
            this.onOutboundCall(event.data)
        }
    }

    sendEventToC4C = (payload: C4Call | C4CChat) => {
        console.log('jupiter-log sendEventToC4C payload === ', payload)
        if (window.parent !== window) {
            window.parent.postMessage({ payload }, '*')
        }
    }

    onOutboundCall = (event: OutboundCall) => {}

    constructPayload = ({
        type,
        eventType,
        action,
        customerId,
        contactId,
        transcript,
    }: {
        type: ConnectionType
        eventType: EventTypes
        action?: Action
        customerId: string
        contactId: string
        transcript?: string
    }): C4Call | C4CChat => {
        const payload: C4Call | C4CChat = {
            Type: type,
            CID: 'BCM1234',
            EventType: eventType,
            Action: action,
            ExternalReferenceID: contactId.replaceAll('-', '').toUpperCase(),
        }

        if (type === 'CALL') return { ...payload, ANI: customerId }
        if (transcript) payload.Transcript = transcript
        return { ...payload, Email: customerId }
    }

    handleMissingEmail = () => {
        const message = 'No email present to send chat event!'
        console.log(message)

        logger.warning('SEND-CHAT-EVENT', {
            message,
            target: 'c4c',
        })
    }
}

const c4cMiddleware: Middleware<{}, RootState> = (store) => {
    const c4c = new C4C()
    c4c.onOutboundCall = (event: OutboundCall) => {
        store.dispatch(CallActions.makeCall(event.PhoneNumber))
    }

    return (next) => (action) => {
        if (!isAnyAction(action)) return

        switch (action.type) {
            // Call Listeners
            case CallReducer.callStarted.type: {
                const contact = store.getState().contact
                c4c.sendEventToC4C(
                    c4c.constructPayload({
                        type: 'CALL',
                        eventType: (contact?.initiationMethod as EventTypes) ?? 'INBOUND',
                        action: 'ACCEPT',
                        customerId: formatStartOfNumber(
                            store.getState().contact?.customerEndpointAddress!,
                        ),
                        contactId: contact?.ID ?? '',
                    }),
                )
                return next(action)
            }
            case afterCallWork.type: {
                c4c.sendEventToC4C(
                    c4c.constructPayload({
                        type: 'CALL',
                        eventType: 'UPDATEACTIVITY',
                        action: 'END',
                        customerId: formatStartOfNumber(
                            store.getState().contact?.customerEndpointAddress!,
                        ),
                        contactId: store.getState().contact?.ID ?? '',
                    }),
                )
                return next(action)
            }
            case CallReducer.transferCall.type: {
                c4c.sendEventToC4C(
                    c4c.constructPayload({
                        type: 'CALL',
                        eventType: 'TRANSFER',
                        customerId: formatStartOfNumber(
                            store.getState().contact?.customerEndpointAddress!,
                        ),
                        contactId: store.getState().contact?.ID ?? '',
                    }),
                )
                return next(action)
            }

            // Chat listeners
            case ChatReducer.addChatConnection.type: {
                const email = action.payload.customerEmail
                if (!email) {
                    c4c.handleMissingEmail()
                    return next(action)
                }

                c4c.sendEventToC4C(
                    c4c.constructPayload({
                        type: 'CHAT',
                        eventType: 'INBOUND',
                        action: 'NOTIFY',
                        customerId: email,
                        contactId: action.payload.id,
                    }),
                )
                return next(action)
            }
            case ChatReducer.acceptChat.type: {
                const email = store.getState().contact?.attributes['sa-customer-email']
                if (!email) {
                    c4c.handleMissingEmail()
                    return next(action)
                }

                c4c.sendEventToC4C(
                    c4c.constructPayload({
                        type: 'CHAT',
                        eventType: 'INBOUND',
                        action: 'ACCEPT',
                        customerId: email,
                        contactId: store.getState().contact?.ID ?? '',
                    }),
                )
                return next(action)
            }
            case ChatReducer.chatACW.type: {
                const email = store.getState().contact?.attributes['sa-customer-email']
                const storeState = store.getState()
                const contact = storeState.contact
                const connection = storeState.chat.connections.find((c) => c?.id === contact?.ID)
                if (!connection) return next(action)
                if (!email) {
                    c4c.handleMissingEmail()
                    return next(action)
                }

                const messages = connection?.messages
                    .map(({ DisplayName, content }) => {
                        if (
                            !content ||
                            !DisplayName ||
                            JSON.stringify(content)?.includes('SILENT_STATE_UPDATE')
                        )
                            return null
                        let parsed = content
                        if (typeof content === 'object') {
                            const contactLanguage =
                                store.getState().contact?.attributes['sa-language'] ?? 'en'
                            parsed = parseChatMessage(
                                JSON.stringify({ [contactLanguage]: content }),
                                parseToPlainText(),
                            )
                        }
                        const removedStrings = JSON.stringify(parsed).replace(/"/g, '')
                        return `${DisplayName}: ${removedStrings}`
                    })
                    .filter((value) => value !== null)
                    .join('\n')

                c4c.sendEventToC4C(
                    c4c.constructPayload({
                        type: 'CHAT',
                        eventType: 'UPDATEACTIVITY',
                        action: 'END',
                        customerId: email,
                        contactId: store.getState().contact?.ID ?? '',
                        transcript: messages,
                    }),
                )
                return next(action)
            }
            case ChatReducer.transferChat.type: {
                const email = store.getState().contact?.attributes['sa-customer-email']
                if (!email) {
                    c4c.handleMissingEmail()
                    return next(action)
                }

                c4c.sendEventToC4C(
                    c4c.constructPayload({
                        type: 'CHAT',
                        eventType: 'TRANSFER',
                        customerId: email,
                        contactId: store.getState().contact?.ID ?? '',
                    }),
                )
                return next(action)
            }

            default:
                return next(action)
        }
    }
}

export default c4cMiddleware

import { Tag as TagComponent } from '@missionlabs/smartagent-app-components'

import type { Tag } from 'components/ParticipantList/types'

import styles from './tags.module.scss'

type Props = {
    tags: Tag[]
}

const Tags = ({ tags }: Props) => {
    return (
        <ul className={styles.container} aria-label="Participant tags">
            {tags.map(({ id, text, colour, src }) => (
                <li key={id}>
                    {src ? (
                        <img src={src} alt={text} />
                    ) : (
                        <TagComponent type={colour} square small text={text} />
                    )}
                </li>
            ))}
        </ul>
    )
}

export default Tags

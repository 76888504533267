import { Dtmf, PhoneTonePlayer } from 'play-dtmf'

const audioContext = new AudioContext()
const dtmfPlayer = new PhoneTonePlayer(audioContext)

export const playDTMFTone = async (digits: string) => {
    for (const digit of digits) {
        await new Promise((res) => {
            const tone = dtmfPlayer.playDtmf(digit as Dtmf)

            setTimeout(() => {
                tone.stop()
            }, 200)

            setTimeout(res, 400)
        })
    }
}

import { useSelector } from 'react-redux'
import RootState from 'store/state'

export default function useNotification(): RootState['notification'] {
    const notification = useSelector<RootState, RootState['notification']>(
        ({ notification }) => notification,
    )

    return notification
}

import React, { useState } from 'react'

import { Form } from '@missionlabs/smartagent-app-components'

import styles from './transfer-controls.module.scss'

import DialerControlButton from 'components/DialerControlButton'
import QuickDialler from 'views/QuickDialler'

import answerIcon from 'images/button-answer.svg'

import { removeInvalidCharacters } from 'utils'

export type Props = {
    onTransfer: (endpoint: connect.Endpoint) => void
}

const TransferControls: React.FC<Props> = ({ onTransfer }) => {
    const [number, setNumber] = useState<string>('')

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNumber(event.target.value)
    }

    const onCall = () => {
        if (!number) return

        const cleanNumber = removeInvalidCharacters(number)
        if (!cleanNumber) return

        const endpoint = connect.Endpoint.byPhoneNumber(cleanNumber)
        onTransfer(endpoint)
    }

    return (
        <Form className={styles.container} onSubmit={onCall} aria-label="Transfer to another agent">
            <input
                aria-label="Enter number"
                type="text"
                placeholder="Enter number..."
                value={number}
                onChange={onChange}
                onBlur={onChange}
            />
            <div className={styles.controls}>
                <QuickDialler channel="VOICE" onSelect={onTransfer} />

                <DialerControlButton disabled={!number} elastic>
                    <img src={answerIcon} alt="Dial" title="Dial" width="40" height="40" />
                </DialerControlButton>
            </div>
        </Form>
    )
}

export default TransferControls

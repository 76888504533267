import type { ThunkDispatch } from '@reduxjs/toolkit'
import PhoneLib from 'google-libphonenumber'
import { formatPhoneNumber, isNilOrEmpty } from 'utils'

import RootState from '../../state'
import { IDirectoryRecord } from '../directory.state'
import * as RestructuredDirectoryContactsActions from './reducers'

export const getRestructuredDirectoryContactsByPhoneNumber =
    (directoryContacts: IDirectoryRecord[]) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            RestructuredDirectoryContactsActions.RestructuredDirectoryContactsAction
        >,
    ) => {
        dispatch(
            RestructuredDirectoryContactsActions.setRestructuredContactsByPhoneNumberProcessing(
                true,
            ),
        )

        const contactsWithPhoneNumbers = directoryContacts.filter((contact) => {
            return !isNilOrEmpty(contact.phoneNumbers)
        })

        const restructuredContacts = contactsWithPhoneNumbers.reduce(
            (acc, contact) => {
                contact.phoneNumbers!.forEach((phone) => {
                    if (phone.value) {
                        const formattedPhoneNumber = formatPhoneNumber(
                            phone.value,
                            PhoneLib.PhoneNumberFormat.E164,
                        )

                        acc[formattedPhoneNumber] = contact
                    }
                })

                return acc
            },
            {} as Record<string, IDirectoryRecord>,
        )

        dispatch(
            RestructuredDirectoryContactsActions.setRestructuredContactsByPhoneNumber(
                restructuredContacts,
            ),
        )

        dispatch(
            RestructuredDirectoryContactsActions.setRestructuredContactsByPhoneNumberProcessing(
                false,
            ),
        )
    }

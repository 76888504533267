import {
    SocialPage,
    SocialPageConfigs,
} from '@missionlabs/smartagent-service-social/dist/types/social-page'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { SocialLoginDetails, SocialSettingsState } from './social.state'

const initialState: SocialSettingsState = {
    socialPageConfigs: {},
}

export const socialSettingsSlice = createSlice({
    name: 'socialSettings',
    initialState,
    reducers: {
        setSocialPageConfigs(state, action: PayloadAction<SocialPageConfigs>) {
            return {
                ...state,
                socialPageConfigs: action.payload,
            }
        },
        socialPageConfigsLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                socialPageConfigsIsLoading: action.payload,
            }
        },
        setSelectedSocialPageConfig(state, action: PayloadAction<SocialPage | undefined>) {
            return {
                ...state,
                selectedSocialPageConfig: action.payload,
            }
        },
        setSocialLoginDetails(state, action: PayloadAction<SocialLoginDetails | undefined>) {
            if (!action.payload) {
                return { ...state, socialLoginDetails: undefined }
            }
            return {
                ...state,
                socialLoginDetails: {
                    ...state.socialLoginDetails,
                    ...action.payload,
                },
            }
        },
        socialLoginDetailsLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                socialLoginDetailsIsLoading: action.payload,
            }
        },
    },
})

export const {
    setSocialPageConfigs,
    socialPageConfigsLoading,
    setSelectedSocialPageConfig,
    setSocialLoginDetails,
    socialLoginDetailsLoading,
} = socialSettingsSlice.actions

export type SocialSettingsAction = ReturnType<
    (typeof socialSettingsSlice.actions)[keyof typeof socialSettingsSlice.actions]
>

export default socialSettingsSlice.reducer

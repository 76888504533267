import { useMemo } from 'react'

import { ITeamsRecord } from '../store/directory/teams/state'
import useTeamsContacts from './redux/useTeamsContacts'
import useTeamsContactsStatuses from './redux/useTeamsContactsStatuses'

type Returns = [ITeamsRecord[], boolean]

const useTeamsContactsWithFreshStatuses = () => {
    const [teamsContacts, isContactsLoading] = useTeamsContacts()

    const [teamsContactsStatuses, isTeamsStatusesLoading] = useTeamsContactsStatuses()

    const isLoading = isContactsLoading || isTeamsStatusesLoading

    const mappedContacts = useMemo(
        () =>
            teamsContacts.map((contact) => ({
                ...contact,
                statusInfo: teamsContactsStatuses[contact.id],
            })),
        [teamsContacts, teamsContactsStatuses],
    )

    return [mappedContacts, isLoading] as Returns
}

export default useTeamsContactsWithFreshStatuses

import { AppFeatures } from 'store/app/app.features'
import { setRedirect } from 'store/global/global.actions'

import * as callActions from '../../call/call.actions'

const setGeneralShortcuts = (store: any) => {
    document.addEventListener('keydown', (evt) => {
        const onInput: boolean | null | undefined =
            document.activeElement?.tagName === 'INPUT' ||
            document.activeElement?.tagName === 'TEXTAREA' ||
            document.activeElement?.hasAttribute('data-noshortcut')

        const hasFeature = (featureName: string) => {
            const agentFeatures = store.getState().auth?.features ?? []
            const appFeatures = store.getState().app.features ?? []
            return (
                appFeatures.some((f: any) => f.ID === featureName) &&
                agentFeatures.some((f: any) => f.ID === featureName || '*')
            )
        }

        function openNavElement(navElement: HTMLElement | null | undefined) {
            navElement?.querySelector('a')?.focus()
            navElement?.click()
        }

        switch (
            !(evt.metaKey || evt.shiftKey || evt.ctrlKey || evt.altKey) &&
            evt.key //NAVIGATION SHORTCUTS - only single keys
        ) {
            case 'd': // Navigates to dialler page
                return onInput ? null : store.dispatch(setRedirect('/'))
            case 'r': {
                // Navigates to real time metrics page
                if (hasFeature(AppFeatures.REALTIME_METRICS)) {
                    return onInput ? null : store.dispatch(setRedirect('/metrics/real-time'))
                }
                return
            }
            case 'h': {
                // Navigates to historic metrics page
                if (hasFeature(AppFeatures.HISTORIC_METRICS)) {
                    return onInput ? null : store.dispatch(setRedirect('/metrics/historic'))
                }
                return
            }
            case 'q': {
                // Focus/Open QA submenu
                const navMenu: HTMLElement | null = document.querySelector('.menu')
                const QA: HTMLElement | null | undefined = navMenu?.querySelector(
                    '#sa--nav-element-quality-assurance',
                )
                return onInput ? null : openNavElement(QA)
            }
            case 's': {
                // Focus/Open Settings submenu
                const navMenu: HTMLElement | null = document.querySelector('.menu')
                const Settings: HTMLElement | null | undefined =
                    navMenu?.querySelector('a[title="Settings"]')
                return onInput ? null : Settings?.focus()
            }
            case 'Enter': {
                // Answers a call if the user is in dialler page and there is an incoming call
                const incomingCall = store.getState().call?.incoming
                const diallerPath = window.location.pathname === '/'
                return incomingCall && diallerPath ? store.dispatch(callActions.acceptCall()) : null
            }

            case 'n': {
                // Opens the new score / new user slide
                const newScore: HTMLElement | null = document.querySelector(
                    'button[title="New score"]',
                )
                const newUser: HTMLElement | null = document.querySelector(
                    'button[title="New user"]',
                )
                const QAPath = window.location.pathname === '/qa/scores'
                const UsersPath = window.location.pathname === '/settings/users'
                if (QAPath && !onInput) return newScore?.click()
                if (UsersPath && !onInput) return newUser?.click()
            }
        }
    })
}

export default setGeneralShortcuts

import { SADropdownOption } from '@missionlabs/smartagent-app-components/dist/Dropdown'

// `isLogDefault` is configured to be a string on the back end because of SmartAgent mapper limitations - have it available in boolean for front end use
export interface IContactLogPreview {
    ID: string
    created: number
    updated?: number
    contactLogName: string
    isLogDefault: boolean | string
}

export interface IContactLog extends IContactLogPreview {
    companyID: string
    instanceID: string
    fields: ContactLogFields[]
    wrapTimeSeconds?: number
    extendWrapTime?: boolean
}

export interface IContactLogField {
    name: string
    text?: string
    comments?: boolean
    info?: string
    required?: boolean
    searchable?: boolean
    condition?: ICondition
    label?: string
}

interface ICondition {
    field: string
    comparator: 'exists' | '=' | '!=' | '<' | '>' | 'begins-with' | '!exists'
    value?: string
    compareField?: string
}

export type ContactLogFieldType =
    | 'dropdown'
    | 'text'
    | 'radio'
    | 'checkbox'
    | 'hierarchy'
    | 'datepicker'
    | 'timepicker'

export enum EContactLogFieldType {
    dropdown = 'dropdown',
    text = 'text',
    radio = 'radio',
    checkbox = 'checkbox',
    hierarchy = 'hierarchy',
    datepicker = 'datepicker',
    timepicker = 'timepicker',
}

export type FieldDropdownOptionType = SADropdownOption<EContactLogFieldType>

export interface IContactLogDropDownFields extends IContactLogField {
    type: EContactLogFieldType.dropdown | EContactLogFieldType.hierarchy
    options: IContactLogDropDownFieldsOptions[]
    multi?: boolean
    filter?: boolean
    placeholder?: string
    maxFilterOptions?: number
}

export interface IContactLogTextFields extends IContactLogField {
    type: EContactLogFieldType.text
    multiline: boolean
    placeholder?: string
    rows?: number
}

export interface IContactLogCheckboxRadioFields extends IContactLogField {
    type: EContactLogFieldType.checkbox | EContactLogFieldType.radio
    options: string[]
}

export interface IContactLogDatepickerFields extends IContactLogField {
    type: EContactLogFieldType.datepicker
}

export interface IContactLogTimepickerFields extends IContactLogField {
    type: EContactLogFieldType.timepicker
}

// `data` should always be a `string`, but made it `any` as we aren't 100% certain of how all of the legacy contact logs are set up
export interface IContactLogDropDownFieldsOptions {
    label: string
    data: any
    key?: string
    children?: IContactLogDropDownFieldsOptions[]
}

export interface IUpdateFrontendOptions {
    label?: string
    data?: string
}

export type ContactLogFields =
    | IContactLogDropDownFields
    | IContactLogTextFields
    | IContactLogCheckboxRadioFields
    | IContactLogDatepickerFields
    | IContactLogTimepickerFields

// dropdown options are objects, checkbox/radio options are strings
export type Option = IContactLogDropDownFieldsOptions | string
export type OptionFields = IContactLogCheckboxRadioFields | IContactLogDropDownFields

import {
    Box,
    Button,
    DataField,
    TimeElapsed,
    formatDateTime,
} from '@missionlabs/smartagent-app-components'
import useHasFeature, { AppFeatures, AppSubFeatures } from 'hooks/useHasFeature'
import CancelIcon from 'images/icon-cross.svg'
import EditIcon from 'images/icon-edit.svg'
import SaveIcon from 'images/tick.svg'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BeatLoader } from 'react-spinners'
import { updateContactAttributes } from 'store/contact/contact.actions'
import { CTR } from 'store/contact/contact.state'
import RootState from 'store/state'
import { formatPhoneNumber, formatStartOfIntNumbers, getContactDurationSeconds } from 'utils'
import { ChannelType } from 'views/Metrics/HistoricMetrics/types'

import './contact-details.scss'

interface ContactDetailsCTR extends CTR {
    channelType: ChannelType
}
export interface Props {
    contact?: ContactDetailsCTR
    whitelist?: string[]
    widgetTitle?: string
}

function chunkArray(arr: Array<[string, string]>, whitelist?: string[]) {
    const chunks = []
    let i = 0

    arr = whitelist ? arr.filter(([key]) => whitelist.includes(key)) : arr

    while (i < arr.length) {
        chunks.push(arr.slice(i, (i += 4)))
    }

    return chunks
}

export const ContactDetails: React.FC<Props> = (props) => {
    const contact = useSelector((state: RootState) => props.contact ?? state.contact)
    const callbacksEnabled = useSelector((state: RootState) => state.app.callbacksEnabled)
    const dispatch = useDispatch()
    const [externalID, setExternalID] = useState<string>('')
    const [initialExternalID, setInitialExternalID] = useState<string>('')
    const [editable, setEditable] = useState<boolean>(false)

    const inputRef = useRef<HTMLInputElement>(null)

    const hasAttributes = () => {
        if (contact?.attributes && Object.keys(contact.attributes).length > 0) return true
        return false
    }
    const hasFeature = useHasFeature()

    const canEditCall =
        contact?.channel === 'VOICE' &&
        hasFeature(AppFeatures.CALL_DETAILS, AppSubFeatures.CALL_DETAILS_EDIT)
    const canEditTask =
        contact?.channel === 'TASK' &&
        hasFeature(AppFeatures.TASK_DETAILS, AppSubFeatures.TASK_DETAILS_EDIT)
    const canEditAttributes = canEditCall || canEditTask

    const renderMore = () => {
        if (!contact?.attributes) return null

        const entries = Object.entries(contact.attributes)

        return chunkArray(entries, props.whitelist).map((chunk, i) => (
            <div key={i} className="row">
                {chunk.map(([key, value], j) => (
                    <DataField key={key + i} title={key.replace('sa-', '')} highlightContent>
                        {value}
                    </DataField>
                ))}
            </div>
        ))
    }

    const onCancel = () => {
        setEditable(false)
        setExternalID(initialExternalID)
    }

    const onSave = (contact: CTR) => {
        dispatch(updateContactAttributes(contact.ID, { externalID }, true))
        setEditable(false)
        setInitialExternalID(externalID)
    }

    const onEdit = () => {
        setEditable(true)
    }

    const timeOfCall = contact?.connectedToAgentTimestamp || contact?.initiationTimestamp

    useEffect(() => {
        if (editable) {
            inputRef.current?.focus()
        }
    }, [editable])

    useEffect(() => {
        const externalID = contact?.attributes['sa-externalID'] ?? ''
        setInitialExternalID(externalID)
        setExternalID(externalID)
    }, [contact?.ID, contact?.attributes?.['sa-externalID']])

    const headerTitle = props.widgetTitle
        ? props.widgetTitle
        : contact?.channel === 'TASK'
          ? 'Task Details'
          : 'Contact Details'

    const timeInQueue = contact?.inQueueDuration ?? 0

    return (
        <Box
            className="contact-details"
            alt
            collapse
            boxLabel="Contact details"
            header={<h1>{headerTitle}</h1>}
            more={
                (!props.whitelist || props.whitelist.length) && hasAttributes()
                    ? renderMore()
                    : undefined
            }
        >
            {contact ? (
                <>
                    <div className="row">
                        <DataField basis={50} title="Contact ID" highlightContent>
                            {contact.ID}
                        </DataField>
                        <DataField basis={25} title="Initiation Method" highlightContent>
                            {contact.initiationMethod}
                        </DataField>
                        <DataField basis={25} title="Duration" highlightContent>
                            <TimeElapsed
                                ariaLive="off"
                                date={TimeElapsed.from(getContactDurationSeconds(contact))}
                                displayOnZero="unknown"
                                annotate
                                noTick
                            />
                        </DataField>
                    </div>
                    <div className="row quarters">
                        <DataField title="Start time" highlightContent>
                            {timeOfCall
                                ? formatDateTime(timeOfCall, { date: true, time: true })
                                : 'Unknown'}
                        </DataField>
                        <DataField title="External ID" highlightContent>
                            <div className="data-field_editable row between">
                                {editable ? (
                                    <>
                                        <input
                                            ref={inputRef}
                                            value={externalID}
                                            onChange={(e) => setExternalID(e.target.value)}
                                            // Prevent event bubbling to DataField or else it deselects input on click
                                            onClick={(e) => {
                                                e.stopPropagation()
                                            }}
                                        />
                                        <div className="data-field_editable_buttons">
                                            <Button
                                                onClick={() => onSave(contact)}
                                                styling="blank"
                                                className="data-field_editable_button"
                                            >
                                                <img
                                                    width={20}
                                                    height={20}
                                                    src={SaveIcon}
                                                    alt="Save"
                                                    title="Save"
                                                />
                                            </Button>
                                            <Button
                                                onClick={onCancel}
                                                styling="blank"
                                                className="data-field_editable_button"
                                            >
                                                <img
                                                    width={20}
                                                    height={20}
                                                    src={CancelIcon}
                                                    alt="Cancel"
                                                    title="Cancel"
                                                />
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    canEditAttributes && (
                                        <>
                                            <span>{externalID || 'Unknown'}</span>
                                            <Button onClick={onEdit} styling="blank">
                                                <img
                                                    width={15}
                                                    height={15}
                                                    src={EditIcon}
                                                    alt="Edit"
                                                    title="Edit"
                                                />
                                            </Button>
                                        </>
                                    )
                                )}
                            </div>
                        </DataField>

                        {contact.channel === 'TASK' ? (
                            <>
                                <DataField title="Task" highlightContent>
                                    {contact.description ?? 'Unknown'}
                                </DataField>

                                <DataField title="‎‎‏‏‎ ‎" highlightContent>
                                    {' '}
                                </DataField>
                            </>
                        ) : (
                            <>
                                <DataField title="Customer Number" highlightContent>
                                    {[
                                        'WHATSAPP WHATSAPP-DM',
                                        'SMS SMS-DM',
                                        'SMS CAMPAIGN-MESSAGE',
                                        'SMS MARKETING-MESSAGE',
                                    ].includes((contact as ContactDetailsCTR).channelType)
                                        ? contact.attributes['sa-customer-endpoint-address']
                                        : formatStartOfIntNumbers(
                                              formatPhoneNumber(
                                                  contact.attributes['sa-dialled-number'] ??
                                                      contact.customerEndpointAddress,
                                              ),
                                          )}
                                </DataField>

                                <DataField title="System Number" highlightContent>
                                    {formatPhoneNumber(contact.systemEndpointAddress)}
                                </DataField>
                            </>
                        )}
                    </div>
                    <div className="row quarters">
                        <DataField title="Queue" highlightContent>
                            {contact.queueName}
                        </DataField>

                        <DataField title="Reason for contact" highlightContent>
                            {contact.attributes['sa-reason'] ?? 'Unknown'}
                        </DataField>

                        <DataField title="Time in Queue" highlightContent>
                            {isNaN(timeInQueue) ? (
                                'Unknown'
                            ) : (
                                <TimeElapsed
                                    ariaLive="off"
                                    date={TimeElapsed.from(timeInQueue)}
                                    displayOnZero="unknown"
                                    annotate
                                    noTick
                                />
                            )}
                        </DataField>

                        <DataField title="ACW Duration" highlightContent>
                            <TimeElapsed
                                ariaLive="off"
                                date={TimeElapsed.from(contact.afterContactWorkDuration ?? 0)}
                                displayOnZero="unknown"
                                annotate
                                noTick
                            />
                        </DataField>
                    </div>
                    <div className="row quarters">
                        <DataField title="Agent" highlightContent>
                            {contact.agentName || 'Unknown'}
                        </DataField>

                        <DataField title="Disconnect reason" highlightContent>
                            {contact.disconnectReason ?? 'Unknown'}
                        </DataField>

                        {callbacksEnabled ? (
                            <>
                                <DataField title="Callback" highlightContent>
                                    {contact.attributes?.callSource === 'outbound_callback_call'
                                        ? 'yes'
                                        : 'no'}
                                </DataField>

                                <DataField title="Attempt reached" highlightContent>
                                    {contact.attributes?.attemptsCount ?? 'Unknown'}
                                </DataField>
                            </>
                        ) : null}
                    </div>
                    <div className="row">
                        <DataField title="Notes" highlightContent>
                            <div>
                                {contact.attributes['sa-acw-notes'] ??
                                    contact.attributes['sa-notes'] ??
                                    'No notes'}
                            </div>
                        </DataField>
                    </div>
                </>
            ) : (
                <div data-testid="contact-details-loading" className="contact-details-loading">
                    <BeatLoader />
                </div>
            )}
        </Box>
    )
}

import React from 'react'
import './customer-journey.scss'

interface Props {
    breadcrumbs: string
}

export const CustomerJourney: React.FC<Props> = ({ breadcrumbs }) => {
    const lastSlash = breadcrumbs.lastIndexOf('/') + 1
    const first = breadcrumbs.slice(0, lastSlash)
    const last = breadcrumbs.slice(lastSlash)

    return (
        <div className="breadcrumbs-wrapper">
            <p>{first}</p>
            <p className="bold">{last}</p>
        </div>
    )
}

import { jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import classNames from "classnames";
const IframeMessage = ({ ariaId, title, url, cardview, sendMessage }) => {
  const iframeUrlOrigin = new URL(url).origin;
  const onMessageFromIframe = (event) => {
    if (event.origin !== iframeUrlOrigin)
      return;
    event.data && sendMessage(event.data.toString());
  };
  useEffect(() => {
    window.addEventListener("message", onMessageFromIframe);
    return () => window.removeEventListener("message", onMessageFromIframe);
  }, []);
  return /* @__PURE__ */ jsx(
    "div",
    {
      id: ariaId,
      className: classNames({
        "sa-message-iframe-cardview": cardview
      }),
      children: /* @__PURE__ */ jsx("iframe", { src: url, className: "sa-message-iframe", title, frameBorder: "0" })
    }
  );
};
export {
  IframeMessage as default
};

import gql from 'graphql-tag'
import { ListUserConversations } from 'graphql/queries/listUserConversations'
import { TypeName, UserConversation } from '../types'

const document = gql`
    subscription onUpdateUserConversation($user: ID!) {
        onUpdateUserConversation(user: $user) {
            __typename
            user
            conversationId
            unread
            name
            conversation {
                __typename
                id
                lastMessageContent
                lastMessageTimestamp
                lastMessageUser
                recipientList
            }
        }
    }
`

export default document

export interface UpdateUserConversation {
    __typename: string
    onUpdateUserConversation: UserConversation & TypeName
}

export const subscription = (username: string, cb: (msg: UserConversation) => void) => ({
    document,
    variables: { user: username },
    updateQuery: (
        prev: ListUserConversations,
        { subscriptionData }: { subscriptionData: { data: UpdateUserConversation } },
    ) => {
        if (!subscriptionData.data) return prev
        const update = subscriptionData.data.onUpdateUserConversation

        cb(update)
        if (
            prev.listUserConversations.items.some((i) => i.conversationId === update.conversationId)
        ) {
            return {
                listUserConversations: {
                    ...prev.listUserConversations,
                    items: prev.listUserConversations.items.map((i) => {
                        return i.conversationId === update.conversationId ? update : i
                    }),
                },
            }
        } else {
            return {
                listUserConversations: {
                    ...prev.listUserConversations,
                    items: [...(prev.listUserConversations.items ?? []), update],
                },
            }
        }
    },
})

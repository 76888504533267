import { ReactComponent as Cross } from 'images/icon-cross-xsmall.svg'
import { ReactComponent as SearchIcon } from 'images/icon-search.svg'
import styles from './SearchInput.module.scss'

interface Props {
    onSearchChange: (value: string) => void
    searchInputValue: string
    ariaLabel?: string
}

const SearchInput = ({ onSearchChange, searchInputValue, ariaLabel }: Props) => (
    <div className={styles.input}>
        <SearchIcon className={styles.inputIcon} />
        <input
            className={styles.search}
            value={searchInputValue}
            type="text"
            placeholder="Search..."
            onChange={(evt) => {
                onSearchChange(evt.target.value)
            }}
            aria-label={ariaLabel}
        />

        {searchInputValue && (
            <button
                data-testid="clearSearchButton"
                className={styles.clearSearchButton}
                onClick={() => onSearchChange('')}
                aria-label="Clear search"
            >
                <Cross />
            </button>
        )}
    </div>
)

export default SearchInput

import { Button } from '@missionlabs/smartagent-app-components'
import classNames from 'classnames'
import React from 'react'
import { ITranscriptMessage } from '.'

import iconMixed from './images/sentiment-mixed.svg'
import iconNegative from './images/sentiment-negative.svg'
import iconNeutral from './images/sentiment-neutral.svg'
import iconPositive from './images/sentiment-positive.svg'

interface Props {
    message: ITranscriptMessage
    focused: boolean
    seekTo?(s: number): void
}

const icons = {
    MIXED: iconMixed,
    NEGATIVE: iconNegative,
    NEUTRAL: iconNeutral,
    POSITIVE: iconPositive,
}

const Message: React.FC<Props> = ({ message, focused, ...actions }) => {
    const seekTo = () => {
        if (!message.startTime) return

        actions.seekTo?.(parseFloat(message.startTime))
    }

    return (
        <div
            className={classNames({
                message: true,
                outgoing: message.channel === 0,
                incoming: message.channel === 1,
            })}
        >
            <Button
                onClick={seekTo}
                className={classNames({
                    focused,
                })}
            >
                <span aria-label={message.channel ? 'agent says' : 'customer says'}></span>
                <span>{message.content}</span>
                <time className="message-timestamp">{message.startTime ?? '-'}</time>
            </Button>

            {!!message.sentiment && (
                <img
                    src={icons[message.sentiment]}
                    alt={message.sentiment}
                    className="message-sentiment"
                />
            )}
        </div>
    )
}

export default Message

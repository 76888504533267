import { Button, Dropdown, Radio } from '@missionlabs/smartagent-app-components'
import { PureComponent } from 'react'
import './time-range.scss'

interface Props {
    setTimeRange: (timeRange: 'midnight' | number) => void
    definedTime: number | string
    timeRangeOptions: Array<{ label: string; data: any }>
    defaultHours?: number
}

class TimeRange extends PureComponent<Props> {
    readonly state = {
        open: false,
    }

    setTodayTime = () => {
        this.props.setTimeRange('midnight')

        this.setState({
            open: false,
        })
    }

    setDefinedTime = () => {
        this.props.setTimeRange(this.props.defaultHours || 2)

        this.setState({
            open: false,
        })
    }

    selectTimeRange = (option: any) => {
        this.props.setTimeRange(option.data)

        this.setState({
            open: false,
        })
    }

    isTodaysTime = () => {
        const { definedTime } = this.props
        if (typeof definedTime === 'number') return false
        return true
    }

    render() {
        const { open } = this.state

        const { timeRangeOptions, defaultHours, definedTime } = this.props
        return (
            <div className="time-range">
                {open ? (
                    <div className="time-range-open">
                        <span className="time-range-open-label">Time range: </span>
                        <Radio
                            name="time range"
                            value="midnight"
                            label={'Midnight to now'}
                            checked={this.isTodaysTime()}
                            onChange={this.setTodayTime}
                        />

                        <div className="defined-time-range">
                            <Radio
                                ariaLabel={`The last ${definedTime} hours`}
                                name="time range"
                                value="defined time"
                                label={`The last`}
                                checked={!this.isTodaysTime()}
                                onChange={this.setDefinedTime}
                            />
                            <Dropdown
                                value={
                                    typeof definedTime === 'string'
                                        ? (defaultHours || 2).toString()
                                        : definedTime.toString()
                                }
                                onChange={this.selectTimeRange}
                                options={timeRangeOptions}
                            />
                            <span>hour(s)</span>
                        </div>
                    </div>
                ) : (
                    <Button
                        className="main-button"
                        styling="white"
                        elastic
                        onClick={() => this.setState({ open: true })}
                    >
                        <div className="time-range-closed">
                            <span className="time-range-closed-label">Time range: </span>
                            <span className="time-range-closed-selection">{`${
                                this.isTodaysTime()
                                    ? 'Midnight to now'
                                    : `Last ${definedTime} hour(s)`
                            }`}</span>
                        </div>
                    </Button>
                )}
            </div>
        )
    }
}

export default TimeRange

import { Option } from '..'
import { ReactComponent as CheckIcon } from './Icon-Toggle-Tick-Default.svg'
import styles from './ItemsList.module.scss'

interface Props {
    items: Option[]
    onItemClick: (data: Option) => void
    selectedItem?: Option
}

const ItemsList = ({ items, onItemClick, selectedItem }: Props) => {
    const handleItemClick = (data: Option) => () => {
        onItemClick(data)
    }

    return (
        <>
            {items.length > 0 ? (
                <ul className={styles.list}>
                    {items.map((item, index) => {
                        return (
                            <li
                                role="presentation"
                                key={`${index}-${item.label}`}
                                className={styles.item}
                            >
                                <button onClick={handleItemClick(item)} className={styles.button}>
                                    <p>{item.label}</p>
                                    {selectedItem?.label === item.label && (
                                        <CheckIcon className={styles.checkIcon} />
                                    )}
                                </button>
                            </li>
                        )
                    })}
                </ul>
            ) : (
                <p className={styles.noItemsText}>No records found.</p>
            )}
        </>
    )
}

export default ItemsList

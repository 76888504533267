import { Button } from '@missionlabs/smartagent-app-components'
import classNames from 'classnames'
import useHasFeature, { AppFeatures } from 'hooks/useHasFeature'
import React, { PropsWithChildren, useState } from 'react'
import './tabs.scss'

export interface TabsProps extends PropsWithChildren {
    index?: number
    selected?: number
    unreadMessages?: number
    onSelect: (index: number) => void
}

const ChatTabs: React.FC<TabsProps> = (props) => {
    const [index, setIndex] = useState(props.selected || 0)
    const getHeaders = () => {
        if (!props.children) return null
        const children = Array.isArray(props.children) ? props.children : [props.children]
        return React.Children.map(children, (child: any, i) => {
            return React.cloneElement(child, {
                onClick: selectTab,
                index: i,
                selected: i === index,
            })
        })
    }
    const selectTab = (index: number) => {
        setIndex(index)
        props.onSelect(index)
    }
    const getContent = () => {
        let children = Array.isArray(props.children) ? props.children : [props.children]
        if (!children) return null
        children = children.filter((c: any) => !!c)
        const content: any = children[index]
        return content ? content.props.children : null
    }
    const { unreadMessages } = props
    const hasFeature = useHasFeature()
    return (
        <div
            className={classNames({
                'sa-chat-tabs': true,
                'multiple-tabs': hasFeature(AppFeatures.INTERNAL_MESSAGING),
                'single-tab': !hasFeature(AppFeatures.INTERNAL_MESSAGING),
            })}
        >
            <div className="sa-chat-tab-headers">
                {getHeaders()}

                {!!unreadMessages && <span className="badge">{unreadMessages}</span>}
            </div>
            <div className="sa-chat-tab-content">{getContent()}</div>
        </div>
    )
}

export interface TabProps {
    selected?: boolean
    title: string
    onClick: (index: number) => void
    index: number
    badge?: boolean
    unread?: number
}

export const Tab: React.FC<TabProps> = (props) => {
    const onClick = () => {
        props.onClick(props.index)
    }
    return (
        <Button onClick={onClick} key={props.index}>
            {props.title}

            {props.badge ? <span className="badge">{props.unread} </span> : null}
        </Button>
    )
}

export default ChatTabs

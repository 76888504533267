import { IDevice } from '@gnaudio/jabra-js'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import WebHIDState from './webHID.state'

const initialState: WebHIDState = {
    showWebHIDPairButton: false,
    webHIDinCall: false,
    ignoreDeviceSignals: true,
    deviceIsMuted: false,
}

export const webHIDSlice = createSlice({
    name: 'webHID',
    initialState,
    reducers: {
        showWebHIDPairButton(state) {
            return { ...state!, showWebHIDPairButton: true }
        },
        hideWebHIDPairButton(state) {
            return { ...state!, showWebHIDPairButton: false }
        },
        setWebHIDInCall(state, action: PayloadAction<boolean>) {
            return { ...state!, webHIDinCall: action.payload }
        },
        setIgnoreDeviceSignals(state, action: PayloadAction<boolean>) {
            return { ...state!, ignoreDeviceSignals: action.payload }
        },
        setConnectedHeadset(state, action: PayloadAction<Partial<IDevice>>) {
            return { ...state!, connectedHeadset: action.payload }
        },
    },
})

export const {
    showWebHIDPairButton,
    hideWebHIDPairButton,
    setWebHIDInCall,
    setIgnoreDeviceSignals,
    setConnectedHeadset,
} = webHIDSlice.actions

export type WebHIDAction = ReturnType<
    (typeof webHIDSlice.actions)[keyof typeof webHIDSlice.actions]
>

export default webHIDSlice.reducer

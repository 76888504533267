import ReactDOM from 'react-dom/client'
// prettier-ignore
import './index.scss' // This line must be before the other imports
import '@missionlabs/smartagent-chat-components-lib/dist/index.css'
import '@missionlabs/smartagent-app-components/dist/index.css'
import App from './App'

import 'amazon-connect-streams'
import 'amazon-connect-taskjs'
import 'amazon-connect-chatjs'

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
;(async () => {
    if ('serviceWorker' in navigator) {
        addEventListener('load', async () => {
            const sw = await navigator.serviceWorker.register(
                `${process.env.BASE_PATH ?? ''}/sw.js`,
            )
            console.log('ONLOAD SERVICE WORKER', sw)
        })
    }
})()

import { Tooltip } from '@missionlabs/smartagent-app-components'
import classNames from 'classnames'
import Status from 'components/Status'
import useAnnouncements from 'hooks/redux/useAnnouncements'
import React from 'react'
import { useSelector } from 'react-redux'
import { IChat } from 'store/chat/chat.state'
import RootState from 'store/state'
import { ITask } from 'store/tasks/tasks.state'

interface Props {
    disabled?: boolean
    acwConnections?: (IChat | ITask)[]
}

const getStatusName = (name: string) => {
    switch (name.toLowerCase()) {
        case 'aftercallwork':
            return 'After Call Work'
        case 'callingcustomer':
            return 'Outbound Call'
        case 'busy':
            return 'In Call'
        case 'missedcallagent':
            return 'Missed Call'
        case 'failedconnectagent':
            return 'Rejected Call'
        case 'pendingbusy':
            return 'Inbound Call'
        case 'pending':
            return 'Customer Callback'
        case 'default':
            return 'Callback Missed'
        case 'agenthungup': // deskphone missed call
            return 'Missed Call (desk)'
        default:
            return name
    }
}

const UserStatus: React.FC<Props> = ({ disabled, acwConnections }) => {
    const statusProps = useSelector<RootState, any>((state) => ({
        isAvailable: state.user?.status.type === 'routable',
        statusName: getStatusName(state.user?.status.name ?? ''),
        inStateSince: state.user?.inStateSince,
        statusTooltipMessage: state.user?.statusControl?.statusTooltipMessage,
    }))
    const TOOLTIP_TEXT =
        statusProps.statusTooltipMessage ||
        'You can select next status only when you have filled in all the required fields in the Contact log.'
    const statusComponent = (
        <div className="sa-status-button" id="status">
            <Status disabled={disabled} {...statusProps} />
        </div>
    )

    const { unreadUserAnnouncements } = useAnnouncements()
    const tooltipClasses = classNames({
        forChats: acwConnections?.length,
        forAnnouncements: unreadUserAnnouncements?.length,
        forChatsAndAnnouncements: acwConnections?.length && unreadUserAnnouncements?.length,
        forCalls: !acwConnections?.length && !unreadUserAnnouncements?.length,
    })
    return (
        <>
            {disabled ? (
                <Tooltip text={TOOLTIP_TEXT} className={tooltipClasses} rotate>
                    {statusComponent}
                </Tooltip>
            ) : (
                statusComponent
            )}
        </>
    )
}

export default UserStatus

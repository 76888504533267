import React from 'react'

import { Tag } from '@missionlabs/smartagent-app-components'
import ContactState, { ICallContactAttributes } from 'store/contact/contact.state'

import './contact.scss'
interface Props {
    contact: ContactState
}

const Contact: React.FC<Props> = ({ contact }) => {
    const { attributes } = contact
    const tagImage = attributes?.['sa-tag-image']
    const tagName = attributes?.['sa-tag-name']
    const tagAttributes = Object.keys(attributes ?? {})
        .filter((key) => key !== 'sa-tag-image' && key !== 'sa-tag-name')
        .filter((key) => key.includes('sa-tag-') || key === 'sa-reason' || key === 'sa-tag')
        .filter((key) => !key.includes('-colour'))
        .map((k) => ({ [k]: attributes[k] }) as ICallContactAttributes)

    if (tagAttributes.length < 1) return null

    return (
        <div className="sa-contact-tags">
            {tagAttributes?.map((tagAttribute) => {
                const tagKey = Object.keys(tagAttribute)[0]
                const tagValue = tagAttribute[tagKey]
                const tagType = attributes?.[`${tagKey}-colour`]?.toLowerCase() ?? 'blue-grey'

                return (
                    <Tag key={tagKey} type={tagType} square small>
                        {tagValue}
                    </Tag>
                )
            })}

            {tagName && (
                <Tag square small>
                    {tagName}
                </Tag>
            )}
            {tagImage && <img src={tagImage} alt={tagName} title={tagName} />}
        </div>
    )
}

export default Contact

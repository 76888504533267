import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { conferenceConnections, toggleConnections } from 'store/callV2/callV2.actions'
import { CallConnection } from 'store/callV2/callV2.state'

import { getControlPermissions } from 'views/Home/utils'

import ControlsButtons from 'components/ControlsButtons'

import { ControlsButton } from 'components/ControlsButtons/types'

import styles from './participant-controls.module.scss'

type Props = {
    connections: CallConnection[]
}

const ParticipantControls = ({ connections }: Props) => {
    const dispatch = useDispatch()

    const swapButton: ControlsButton = {
        text: 'Swap',
        handleClick: () => dispatch(toggleConnections()),
        styling: 'white',
    }

    const joinButton: ControlsButton = {
        text: 'Join',
        handleClick: () => dispatch(conferenceConnections()),
        styling: 'primary',
    }

    const participantControls = useMemo(() => {
        const controls: ControlsButton[] = []

        const { swap, join } = getControlPermissions(connections)

        if (swap) controls.push(swapButton)
        if (join) controls.push(joinButton)

        return controls
    }, [connections])

    return (
        participantControls.length > 0 && (
            <div className={styles.container}>
                <ControlsButtons controls={participantControls} />
            </div>
        )
    )
}

export default ParticipantControls

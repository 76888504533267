import WSCompanionClient from 'services/Companion/Websocket'

type WebsocketCompanionState = {
    client: WSCompanionClient | null
}

export enum WebSocketCompanionActions {
    ON_CALL_REJECTED_SUCCESS = 'ON_CALL_REJECTED_SUCCESS',
    ON_CALL_REJECTED_FAILURE = 'ON_CALL_REJECTED_FAILURE',
    ON_CALL_ANSWERED_FAILURE = 'ON_CALL_ANSWERED_FAILURE',
    ON_CALL_CONNECTED_SUCCESS = 'ON_CALL_CONNECTED_SUCCESS',
    INCOMING_CALL = 'INCOMING_CALL',
    MISSED_CALL = 'MISSED_CALL',
    ON_CALL_ANSWERED = 'ON_CALL_ANSWERED',
    ON_CALL_REJECTED = 'ON_CALL_REJECTED',
    ON_CONNECTION_END_FAILURE = 'ON_CONNECTION_END_FAILURE',
    ON_CONNECTION_END_SUCCESS = 'ON_CONNECTION_END_SUCCESS',
    ON_CONNECTION_END = 'ON_CONNECTION_END',
    ON_CALL_END_SUCCESS = 'ON_CALL_END_SUCCESS',
    ON_CALL_END_FAILURE = 'ON_CALL_END_FAILURE',
    ON_CALL_HOLD = 'ON_CALL_HOLD',
    ON_CALL_HOLD_SUCCESS = 'ON_CALL_HOLD_SUCCESS',
    ON_CALL_HOLD_FAILURE = 'ON_CALL_HOLD_FAILURE',
    ON_CALL_RESUME = 'ON_CALL_RESUME',
    ON_CALL_RESUME_SUCCESS = 'ON_CALL_RESUME_SUCCESS',
    ON_CALL_RESUME_FAILURE = 'ON_CALL_RESUME_FAILURE',
    ON_CALL_MUTE = 'ON_CALL_MUTE',
    ON_CALL_MUTE_SUCCESS = 'ON_CALL_MUTE_SUCCESS',
    ON_CALL_MUTE_FAILURE = 'ON_CALL_MUTE_FAILURE',
    ON_CALL_UNMUTE = 'ON_CALL_UNMUTE',
    ON_CALL_UNMUTE_SUCCESS = 'ON_CALL_UNMUTE_SUCCESS',
    ON_CALL_UNMUTE_FAILURE = 'ON_CALL_UNMUTE_FAILURE',
}

export enum WebSocketCompanionTargets {
    COMPANION = 'companion',
}

export enum WebSocketCompanionRoutes {
    COMPANION_SEND_MESSAGE = 'companion-sendMessage',
}

export default WebsocketCompanionState

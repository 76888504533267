var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { jsx, jsxs } from "react/jsx-runtime";
import React, { PureComponent } from "react";
import classNames from "classnames";
import Button from "./Button.mjs";
const left = "data:image/svg+xml,%3csvg%20viewBox='0%200%206%2010'%20xmlns='http://www.w3.org/2000/svg'%20fill-rule='evenodd'%20clip-rule='evenodd'%20stroke-linejoin='round'%20stroke-miterlimit='1.414'%3e%3cpath%20d='M.008%204.895a.743.743%200%2001.238-.512L4.624.231A.823.823%200%20015.2%200c.44%200%20.8.342.8.759a.742.742%200%2001-.244.547L1.882%204.98l3.799%203.73a.741.741%200%2001.224.53c0%20.416-.361.76-.8.76a.823.823%200%2001-.577-.233l-4.3-4.224a.739.739%200%2001-.219-.65z'%20fill='%23959da7'/%3e%3c/svg%3e";
const right = "data:image/svg+xml,%3csvg%20viewBox='0%200%206%2010'%20xmlns='http://www.w3.org/2000/svg'%20fill-rule='evenodd'%20clip-rule='evenodd'%20stroke-linejoin='round'%20stroke-miterlimit='1.414'%3e%3cpath%20d='M5.992%204.895a.743.743%200%2000-.238-.512L1.376.231A.823.823%200%2000.8%200c-.44%200-.8.342-.8.759%200%20.206.088.403.244.547L4.118%204.98.32%208.711a.741.741%200%2000-.224.53c0%20.416.361.76.8.76a.823.823%200%2000.577-.233l4.3-4.224a.739.739%200%2000.219-.65z'%20fill='%23959da7'/%3e%3c/svg%3e";
class DataField extends PureComponent {
  constructor() {
    super(...arguments);
    __publicField(this, "state", {
      page: 0
    });
    __publicField(this, "hasArrayChildren", () => {
      const { pagination, children } = this.props;
      return pagination && Array.isArray(children) && children.length > 1;
    });
    __publicField(this, "sectionRef", React.createRef());
    __publicField(this, "handleSectionClick", (event) => {
      if (!this.props.highlightContent) {
        return;
      }
      const selection = window.getSelection();
      const range = document.createRange();
      if (event.target === this.sectionRef.current) {
        range.selectNodeContents(this.sectionRef.current);
      } else {
        range.selectNodeContents(event.target);
      }
      selection == null ? void 0 : selection.removeAllRanges();
      selection == null ? void 0 : selection.addRange(range);
    });
    __publicField(this, "renderChildren", () => {
      const { children, error } = this.props;
      if (!children)
        return null;
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        /* @__PURE__ */ jsx(
          "section",
          {
            ref: this.sectionRef,
            onClick: this.handleSectionClick,
            className: classNames({ "data-field_body": true, "error": error }),
            children: this.hasArrayChildren() ? children[this.state.page] : children
          }
        )
      );
    });
    __publicField(this, "prevPage", () => {
      var _a, _b, _c;
      const { page } = this.state;
      if (page === 0)
        return;
      this.setState({
        page: page - 1
      });
      (_c = (_b = this.props).onChangePage) == null ? void 0 : _c.call(
        _b,
        (_a = this.props.data) == null ? void 0 : _a[page - 1]
      );
    });
    __publicField(this, "nextPage", () => {
      var _a, _b, _c;
      const { children } = this.props;
      const { page } = this.state;
      if (children.length - 1 === page)
        return;
      this.setState({
        page: page + 1
      });
      (_c = (_b = this.props).onChangePage) == null ? void 0 : _c.call(
        _b,
        (_a = this.props.data) == null ? void 0 : _a[page + 1]
      );
    });
    __publicField(this, "renderHeaderSelect", () => {
      const { children, pagination } = this.props;
      if (!Array.isArray(children) || !pagination || children.length <= 1)
        return null;
      return /* @__PURE__ */ jsxs("div", { className: "data-field_select", children: [
        /* @__PURE__ */ jsx(Button, { type: "button", elastic: true, onClick: this.prevPage, children: /* @__PURE__ */ jsx("img", { height: "10", src: left, alt: "l" }) }),
        /* @__PURE__ */ jsx(Button, { type: "button", elastic: true, onClick: this.nextPage, children: /* @__PURE__ */ jsx("img", { height: "10", src: right, alt: "r" }) })
      ] });
    });
    __publicField(this, "copyToClipboard", (c) => {
    });
    __publicField(this, "getBasis", () => {
      if (!this.props.basis)
        return {};
      return {
        flexBasis: this.props.basis + "%"
      };
    });
  }
  componentDidMount() {
    var _a, _b, _c;
    (_c = (_b = this.props).onChangePage) == null ? void 0 : _c.call(
      _b,
      (_a = this.props.data) == null ? void 0 : _a[0]
    );
  }
  render() {
    const { children, pagination, title, headerRight, subtitle } = this.props;
    return /* @__PURE__ */ jsxs("div", { style: this.getBasis(), className: classNames({ "data-field": true, "grow": !!children }), children: [
      /* @__PURE__ */ jsxs("header", { className: "data-field_header", children: [
        /* @__PURE__ */ jsxs("div", { className: "data-field_headerLeft", children: [
          title && /* @__PURE__ */ jsx("span", { className: "data-field_title", children: title }),
          subtitle && /* @__PURE__ */ jsx("span", { className: "data-field_subtitle", children: subtitle }),
          this.hasArrayChildren() && /* @__PURE__ */ jsxs("span", { className: "data-field_pages", children: [
            this.state.page + 1,
            "/",
            children.length
          ] })
        ] }),
        headerRight && /* @__PURE__ */ jsx("div", { className: "data-field_headerRight", children: headerRight }),
        this.renderHeaderSelect()
      ] }),
      this.renderChildren()
    ] });
  }
}
export {
  DataField as default
};

import './contact-search-results.scss'

import { Table } from '@missionlabs/smartagent-app-components'
import NoDataMessage from 'components/NoDataMessage'
import { FC, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { setSelectedInteraction } from 'store/contactSearch/contactSearch.actions'
import { IContactSearchForm } from 'store/contactSearch/contactSearch.state'
import { IInteraction } from '../types'
import { convertInteractionToResult } from '../utils'
import { allTableColumns } from './columns'

export interface Props {
    interactions: IInteraction[]
    channel: IContactSearchForm['channel']
}

const ContactSearchResults: FC<Props> = ({ interactions, channel }) => {
    const dispatch = useDispatch()

    const cols = useMemo(() => allTableColumns[channel.group], [channel])

    const data = useMemo(() => interactions.map(convertInteractionToResult), [interactions])

    return (
        <section className="contact-search-results">
            <Table
                sort="initiationTimestamp"
                sortBy="descending"
                pagination
                rowsPerPage={30}
                selectable
                onSelect={(interaction: IInteraction) =>
                    dispatch(setSelectedInteraction(interaction))
                }
                cols={cols}
                data={data}
                noData={
                    <NoDataMessage text="There are no records to view. Use the search to retrieve contacts." />
                }
            />
        </section>
    )
}

export default ContactSearchResults

import { CTR } from 'store/contact/contact.state'
import { IScoreSearchForm } from 'views/QA/ScoreSearch/ScoreSearch.types'
import {
    IQAForm_Results,
    IQAForm_Template,
} from 'views/QA/ScoreView/FormBuilderQA/FormBuilderQA.types'

export interface QAState {
    scores?: IScoreSearch[]
    calibrations?: ICalibration[]
    searchCalibrationsLoading?: boolean
    searchCalibrationsError?: boolean
    currentScore?: IScoreDetails
    currentCalibration?: ICalibrationFull
    calibrationsBusy?: string
    getScoreLoading?: string
    updateScoreLoading?: boolean
    scoreCalculating?: boolean
    searchScoresLoading?: boolean
    searchScoresError?: boolean
    deleteScoreLoading?: boolean
    deletingScore?: string
    creatingScore?: boolean
    scoreSearchParams?: IScoreSearchForm
    showNewScoreForm: boolean
    myScores?: IScoreSearch[]
    myScoresLoading?: boolean
    myScoresError?: boolean
    qaTemplates?: IQATemplate[]
    evaluationConfig?: IEvaluationConfig
    unreadScoresCount?: number
}

export interface ICalibration {
    ID: string
    companyID: string
    evaluationID: string
    instanceID: string
    contactID: string
    queueID: string
    queueName: string
    agentID: string
    agentName: string
    calibrationStatus: CalibrationStatus
    channel: string
    createdBy: string
    evaluatorID: string
    evaluatorName: string
    reference: string
    form: IQAForm_Template
    templateID: string
    initiationTimestamp: number
    contact: CTR
    scoresSummary: Array<{
        score: number
        ID: string
        evaluatorID: string
        percent: number
        status: QAStatus
    }>
    originalScorePercentage: number
    responseAvg: number
    numInProgress: number
    numResponses: number
}

export interface ICalibrationFull extends ICalibration {
    scores: IScoreDetails[]
    originalScore: IScoreDetails
}

export enum CalibrationStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    CLOSED = 'CLOSED',
}

export enum QAStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    PASS = 'PASS',
    FAIL = 'FAIL',
}

export enum SAChannel {
    VOICE = 'VOICE',
    EMAIL = 'EMAIL',
    CHAT = 'CHAT',
    TASK = 'TASK',
}

export enum SAContactType {
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
}

export interface EvaluationConfig {
    channel: string[]
    customerJourney: string[]
}
interface IEvaluationConfig {
    evaluationConfigLoading: boolean
    config: EvaluationConfig
    evaluationConfigError: boolean
}
export interface IScore {
    ID?: string
    form: IQAForm_Template
    results?: IQAForm_Results
    status: QAStatus
    scorePercentage?: number
    channel: string
    contactID?: string
    externalID?: string
    agentName: string
    agentID: string
    initiationTimestamp: number
    managerName?: string
    managerID?: string
    hierarchy?: string
    customerJourney?: string
    type?: 'CALIBRATION' | 'EVALUATION'
    calibrationID?: string
    instanceID?: string
    templateID: string
    hasAgentViewed?: boolean
    failType?: string
}

export interface IQATemplate {
    ID: string
    channel: string
    companyID: string
    form: IQAForm_Template
    name: string
    type: 'type'
}

export interface IScoreSearch {
    ID: string
    created: number
    completed: number
    updated: number
    initiationTimestamp: number
    contactID: string
    status: QAStatus
    failType?: string
    type?: 'CALIBRATION' | 'EVALUATION'
    evaluatorID: string
    evaluatorName: string
    agentID: string
    agentName: string
    queueID: string
    externalID: string
    companyID: string
    instanceID: string
    templateID: string
    agentHierarchyStructure: string
    channel: string
    queueName: string
    calibrationStatus: CalibrationStatus
    scorePercentage: number
    reference: string
    calibrationID?: string
    customerJourney?: string
    hasAgentViewed?: boolean
}

export interface IScoreDetails extends IScoreSearch {
    form: IQAForm_Template
    contact?: CTR
    results?: IQAForm_Results
    calibrationID?: string
    managerName?: string
    managerID?: string
    name: string
    templateID: string
}

export interface StartCalibrationAction {
    scoreID: string
    evaluatorID: string
}

export interface NewScoreAction {
    contact?: CTR
    newScore: IScoreDetails
}

import { useMemo } from 'react'

import { Box } from '@missionlabs/smartagent-app-components'

import { ParticipantControls, ParticipantList } from 'components'
import CallSummary from 'views/Home/Dialler/CallSummary'

import type { CallConnection } from 'store/callV2/callV2.state'

import YouConnection from 'views/Home/YouConnection'

import { getConnectionList } from 'views/Home/utils'

import styles from './multi-party-calling.module.scss'

export type Props = {
    connections: CallConnection[]
}

const MultiPartyCalling = ({ connections }: Props) => {
    const [youConnection, ...otherConnections] = useMemo(
        () => getConnectionList(connections),
        [connections],
    )

    return (
        <>
            <Box
                className={styles.participants}
                boxLabel="Other participants"
                header={<CallSummary />}
            >
                <ParticipantList connections={otherConnections} />
                <ParticipantControls connections={connections} />
            </Box>
            <section aria-labelledby={youConnection.id} className={styles.controls}>
                <YouConnection connection={youConnection} />
            </section>
        </>
    )
}

export default MultiPartyCalling

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ITeams, ITeamsRecord, ITeamsStatusInfo } from './state'

const initialState: ITeams = {
    contacts: [],
    isTeamsContactsLoading: undefined,
    contactsStatuses: {},
    isTeamsStatusesLoading: undefined,
    errors: [],
}

export const teamsDirectorySlice = createSlice({
    name: 'teamsDirectory',
    initialState,
    reducers: {
        getTeamsContacts(state, action: PayloadAction<ITeamsRecord[]>) {
            return { ...state, isTeamsContactsLoading: false, contacts: action.payload }
        },
        teamsContactsLoading(state, action: PayloadAction<boolean>) {
            return { ...state, isTeamsContactsLoading: action.payload }
        },
        getTeamsStatuses(state, action: PayloadAction<ITeamsStatusInfo>) {
            return {
                ...state,
                isTeamsStatusesLoading: false,
                contactsStatuses: action.payload,
            }
        },
        teamsStatusesLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                isTeamsStatusesLoading: action.payload,
            }
        },
        teamsError(state, action: PayloadAction<string>) {
            return {
                ...state,
                errors: [...state.errors, action.payload],
            }
        },
    },
})

export const {
    getTeamsContacts,
    teamsContactsLoading,
    getTeamsStatuses,
    teamsStatusesLoading,
    teamsError,
} = teamsDirectorySlice.actions

export type TeamDirectoryAction = ReturnType<
    (typeof teamsDirectorySlice.actions)[keyof typeof teamsDirectorySlice.actions]
>

export default teamsDirectorySlice.reducer

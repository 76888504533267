import { combineReducers } from '@reduxjs/toolkit'

import type { DirectoryReducers } from './directory.state'
import externalDirectoryReducer, { ExternalDirectoryAction } from './externalDirectory/reducers'
import ownersReducer, { OwnersDirectoryAction } from './owners/reducers'
import restructuredDirectoryContactsReducer, {
    RestructuredDirectoryContactsAction,
} from './restructuredContactsByPhoneNumber/reducers'
import directorySearchParamsReducer, { DirectorySearchParamsAction } from './searchParams/reducers'
import teamsReducer, { TeamDirectoryAction } from './teams/reducers'

export type DirectoryAction =
    | DirectorySearchParamsAction
    | ExternalDirectoryAction
    | OwnersDirectoryAction
    | RestructuredDirectoryContactsAction
    | TeamDirectoryAction

const directoryReducer = combineReducers<DirectoryReducers>({
    externalDirectory: externalDirectoryReducer,
    owners: ownersReducer,
    restructuredDirectoryContacts: restructuredDirectoryContactsReducer,
    searchParams: directorySearchParamsReducer,
    teams: teamsReducer,
})

export default directoryReducer

export interface IQAForm_Template {
    title?: string
    allowSummary?: boolean
    sections: IQAForm_TemplateSection[]
    passScore: number
    passPercentage: number
}

export interface IQAForm_TemplateField_Condition {
    childId: string
    optionId: string
    type: 'section' | 'field' | 'end'
}

export interface IQAForm_TemplateField {
    id: string
    title: string
    questionType: string
    description?: string
    allowComments?: boolean
    condition?: IQAForm_TemplateField_Condition[]
    options: IQAForm_TemplateField_Option[]
    required?: boolean
    score: number
}

export interface IQAForm_TemplateSection {
    fields: IQAForm_TemplateField[]
    id: string
    name: string
}

export type IQAForm_TemplateField_Option_Next =
    | ({ type: 'field' } & IQAForm_TemplateField)
    | ({ type: 'section' } & IQAForm_TemplateSection)
    | undefined

export interface IQAForm_TemplateField_Option<T = any> {
    id: string
    weight: number
    label: string
    data: T
    questionType: string
    immediateFail?: boolean
    history?: IQAForm_FieldHistory[]
    updated?: IQAForm_UpdatedBy
    skipped?: boolean
    failType?: string
}

export enum QAStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    PASS = 'PASS',
    FAIL = 'FAIL',
}

export interface IQAForm_Export {
    scorePercentage?: number
    status: QAStatus
    results: IQAForm_Results
    failType?: string
}

export interface IQAForm_Results {
    fields: IQAForm_ExportField[]
    summary?: string
}

export interface IQAForm_ExportField<T = any> {
    id: string
    score: number
    comments?: string
    skipped?: boolean
    value: T
    immediateFail?: boolean
    history?: IQAForm_FieldHistory[]
    updated?: IQAForm_UpdatedBy
    failType?: string
}

export interface IQAForm_UpdatedBy {
    timestamp?: number
    agentID: string
    name: string
}

export interface IQAForm_FieldHistory<T = any> {
    value: T
    score: number
    comments?: string
    updated?: IQAForm_UpdatedBy
}

export interface IQAForm_State {
    fields: {
        [id: string]: IQAForm_TemplateField_Option[]
    }
    comments: {
        [id: string]: string
    }
    updatedFields: string[]
    summary?: string
    hasChanges?: boolean
    isFormAtEnd?: boolean
    editingCompleted: boolean
}

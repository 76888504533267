import { Button, Input } from '@missionlabs/smartagent-app-components'
import type { Channel } from '@missionlabs/smartagent-lib-shared/build/form-templates/form-template.schema'
import AgentIcon from 'images/agent.svg'
import QueueIcon from 'images/queue.svg'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { AppFeatures, FeatureConfig, IFilterQuickConnectsConfig } from 'store/app/app.features'
import RootState from 'store/state'
import { formatPhoneNumber } from 'utils'

interface Props {
    onClose: () => void
    onFilter: (value: any) => void
    filter: string
    connects: Array<connect.Endpoint>
    onSelect: (connect: any) => void
    channel: Channel
}

const ConnectsView: React.FC<Props> = (props) => {
    const [recentlyClicked, setRecentlyClicked] = useState<boolean>(false)
    let quickConnects = props.connects

    const handleOnSelect = (connect: connect.Endpoint) => {
        if (recentlyClicked) return
        setRecentlyClicked(true)
        setTimeout(() => setRecentlyClicked(false), 1000)
        props.onSelect(connect)
    }

    const filterQuickConnectsFeatureConfig = useSelector<RootState, RootState['app']['features']>(
        (state) => state.app?.features ?? [],
    ).find((feature) => feature.ID === 'filter-quick-connects')
        ?.config as FeatureConfig<AppFeatures.FILTER_QUICK_CONNECTS>

    const filterQuickConnectRegexes =
        filterQuickConnectsFeatureConfig?.[
            props.channel.toLowerCase() as keyof IFilterQuickConnectsConfig
        ]

    if (filterQuickConnectRegexes?.length) {
        quickConnects = quickConnects?.filter((connect) => {
            if (connect.type !== 'queue') return true
            return (
                connect?.name &&
                filterQuickConnectRegexes.some((regex) => new RegExp(regex).test(connect.name))
            )
        })
    }

    return (
        <div className="connects">
            <div className="connects-search">
                <Input
                    title="Search for users"
                    placeholder="Search for users"
                    onChange={props.onFilter}
                    value={props.filter}
                />
            </div>
            <ul className="connects-list">
                {quickConnects?.map((connect) => {
                    return (
                        <li key={connect.endpointId} className="connect">
                            <Button type="button" onClick={() => handleOnSelect(connect)}>
                                <div
                                    className={`connect-info row between stretch start${connect.type === 'phone_number' ? ' column' : ''}`}
                                >
                                    <span>{connect.name}</span>

                                    {connect.type === 'phone_number' ? (
                                        <span>{formatPhoneNumber(connect.phoneNumber)}</span>
                                    ) : connect.type === 'queue' ? (
                                        <img
                                            src={QueueIcon}
                                            width={20}
                                            height={20}
                                            alt="Queue"
                                            title="Queue"
                                        />
                                    ) : (
                                        <img
                                            src={AgentIcon}
                                            width={20}
                                            height={20}
                                            alt="Agent"
                                            title="Agent"
                                        />
                                    )}
                                </div>
                            </Button>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default ConnectsView

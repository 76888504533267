import type { Middleware } from '@reduxjs/toolkit'
import { sendEvent } from 'analytics/GoogleAnalytics'
import { ContentState, EditorState } from 'draft-js'
import PhoneLib from 'google-libphonenumber'
import { isValidNumberForRegion } from 'libphonenumber-js'
import { batch } from 'react-redux'
import { postSocialChatMessage } from 'services/api/api.chat'
import { getConnectChatAttachmentUrl } from 'services/api/api.contact'
import { sipGateway } from 'services/api/api.sipGateway'
import { sendWhatsAppReadReceipt } from 'services/api/api.social'
import * as appActions from 'store/app/app.actions'
import { selectInstance } from 'store/app/app.reducer'
import * as callActions from 'store/call/call.actions'
import * as CallReducer from 'store/call/call.reducer'
import { MakeCallAction } from 'store/call/call.state'
import * as callV2Actions from 'store/callV2/callV2.actions'
import * as CallV2Reducer from 'store/callV2/callV2.reducer'
import { endConnection, holdConnection, muteConnection } from 'store/callV2/callV2.utils'
import {
    clearChatConnection,
    sendChatMessage,
    setAttachmentLoading,
    updateConnectionEditorContent,
} from 'store/chat/chat.actions'
import * as ChatReducer from 'store/chat/chat.reducer'
import { ChatMessageEvent, IChatAttachmentFile } from 'store/chat/chat.state'
import { MessageSources } from 'store/chat/chat.utils'
import { parkCallContact, setContact, updateContactAttributes } from 'store/contact/contact.actions'
import { addContact } from 'store/contacts/contacts.actions'
import { addError, setRedirect } from 'store/global/global.actions'
import {
    MAX_VOICE_CONNECTIONS_WITH_BARGE,
    NOTIFICATION_TIMEOUT_IN_MILLISECONDS,
} from 'store/middleware/ccp/ccp.constants'
import { createNotification } from 'store/notification/notification.actions'
import RootState from 'store/state'
import * as taskActions from 'store/tasks/tasks.actions'
import * as TasksReducer from 'store/tasks/tasks.reducer'
import * as userActions from 'store/user/user.actions'
import { WebSocketCompanionActions } from 'store/websocketCompanion/websocketCompanion.state'
import {
    debouncer,
    formatPhoneNumber,
    getNumberToDialThroughConnect,
    isAnyAction,
    matchPattern,
} from 'utils'

import { SAMessageType } from '@missionlabs/smartagent-chat-components-lib/dist/ChatMessage/chat-message.types'

import { playDTMFTone } from './ccp.dtmf'
import { getContactState, monitorConnection } from './ccp.utils'
import listenToContact from './listeners/listeners.contact'

import type { SocialChatOutboundMessage } from '@missionlabs/smartagent-service-chat/dist/types/socialChatMessage'

import { isJSONRepresentingStructure } from '@missionlabs/smartagent-chat-components-lib/dist/utils'
import Logger from '../../../logger'

let getChatContact: (id: string) => connect.Contact
let getTaskContact: (id: string) => connect.Contact

let getContact: () => connect.Contact

const getConnection = <TType extends connect.BaseConnection>(connectionId: string) => {
    return getContact()
        .getConnections()
        .find((c) => c.getConnectionId() === connectionId) as TType | undefined
}

// replace all instances of '_' with '*' due to markdown rendering issues; escape the underscore if the content is an email
const convertMessage = (msg: string) => {
    const emails = msg.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi)
    msg = msg.replaceAll('_', '*')

    emails?.forEach((x) => {
        msg = msg.replace(x.replace('_', '*'), x.replace('_', '\\_'))
    })
    return msg
}

const getChatController = (id: string) =>
    (getChatContact(id).getAgentConnection() as connect.ChatConnection).getMediaController()

const ccpContactMiddleware: Middleware<{}, RootState> = (store) => (next) => {
    const sendTypingEvent = debouncer({
        onCall: (contactId: string) => {
            const connection = getChatContact(
                contactId,
            ).getAgentConnection() as connect.ChatConnection

            connection.getMediaController().then((controller) =>
                controller.sendEvent({
                    content: null,
                    contentType: ChatMessageEvent.TYPING,
                }),
            )
        },
    })

    const ID = store.getState().app.ID

    return async (action) => {
        if (!isAnyAction(action)) return

        if (store.getState().app?.callProvider === 'kumodi') {
            return next(action)
        }

        const { call, websocketCompanion } = store.getState()

        switch (action.type) {
            case selectInstance.type:
                ;[getContact, getChatContact, getTaskContact] = listenToContact(store)
                return next(action)
            case CallReducer.acceptCall.type:
                console.log('accepting call')
                if (getContact().isConnected()) {
                    console.log('contact already in state of answering call')
                    return next(action)
                }
                getContact().accept({
                    success: () => {
                        store.dispatch(userActions.getQuickConnects())
                        return next(action)
                    },
                    failure: (error: string) => {
                        console.error(new Error('Error at ACCEPT_CALL'))
                        websocketCompanion.client?.sendMessage(
                            WebSocketCompanionActions.ON_CALL_ANSWERED_FAILURE,
                            {
                                number: call?.number,
                            },
                        )

                        store.dispatch(
                            addError(
                                JSON.parse(error).message ||
                                    'Oops, there was an error while trying to connect the call',
                            ),
                        )
                    },
                })
                return
            case CallReducer.rejectCall.type:
                getContact()
                    .getInitialConnection()
                    .destroy({
                        success: () => {
                            websocketCompanion.client?.sendMessage(
                                WebSocketCompanionActions.ON_CALL_REJECTED_SUCCESS,
                                {
                                    number: call?.number,
                                },
                            )
                        },
                        failure: () => {
                            websocketCompanion.client?.sendMessage(
                                WebSocketCompanionActions.ON_CALL_REJECTED_FAILURE,
                                {
                                    number: call?.number,
                                },
                            )
                        },
                    })
                return next(action)

            case CallV2Reducer.addConnection.type:
            case CallReducer.addConnection.type: {
                const {
                    app: { appConfig },
                    auth: { token },
                } = store.getState()

                let number = action.payload.number?.replace(/\s/g, '')

                const gatewayConfig = appConfig?.SIPgatewayConfig
                const isGatewayNumber = gatewayConfig?.conditions.some((v) => {
                    return matchPattern(v.regexPattern, number)
                })
                const countryCodeExclusion = gatewayConfig?.exclusions?.countryCodes?.some(
                    (countryCode) => {
                        return isValidNumberForRegion(number!, countryCode)
                    },
                )
                const regexExclusion = gatewayConfig?.exclusions?.regexPatterns?.some(
                    (regexPattern) => {
                        return matchPattern(regexPattern, number)
                    },
                )
                console.log('TRANSFER: CountryCodeExclusion', countryCodeExclusion)
                console.log('TRANSFER: regexExclusion', regexExclusion)
                if (
                    isGatewayNumber &&
                    (!countryCodeExclusion || number?.length === 7) &&
                    !regexExclusion
                ) {
                    console.log(`TRANSFER: number ${number} hits sip gateway`)
                    const data = await sipGateway(gatewayConfig!, number!, token!)
                    number = `+${data.gatewayE164}`
                } else {
                    console.log(`TRANSFER: number ${number} hits default call logic`)
                }

                if (number) {
                    const countryAbbreviation = store.getState().app.appConfig?.defaultCountryCode

                    const { formattedNumber, countryCode } = getNumberToDialThroughConnect(
                        number,
                        countryAbbreviation,
                    )

                    number = formatPhoneNumber(
                        formattedNumber,
                        PhoneLib.PhoneNumberFormat.E164,
                        countryCode,
                    )
                }

                const new_endpoint = number
                    ? connect.Endpoint.byPhoneNumber(number)
                    : (action.payload.endpoint as connect.Endpoint)
                console.log('ENDPOINT', new_endpoint)
                getContact().addConnection(new_endpoint, {
                    success: () => {
                        //Monitor connection for changes as we don't get event listeners
                        let i = 0
                        const interval = setInterval(() => {
                            if (i === 10) {
                                console.log('cant get third party connection!')
                                store.dispatch(addError('Unable to transfer call'))
                                if (action.type === CallReducer.addConnection.type) {
                                    store.dispatch(
                                        callActions.endConnection(
                                            (action.payload as MakeCallAction).id,
                                        ),
                                    )
                                } else {
                                    // TODO CallV2Types.ADD_CONNECTION only has `endpoint` and `number` fields, what do we do?
                                }
                                clearInterval(interval)
                            }
                            i++
                            const connection =
                                getContact().getSingleActiveThirdPartyConnection() as connect.VoiceConnection
                            if (!connection) return
                            next({
                                ...action,
                                payload: {
                                    ...action.payload,
                                    destinationID: action.payload.endpoint
                                        ? action.payload.endpoint.endpointId
                                        : undefined,
                                    id: connection.getConnectionId(),
                                },
                            })
                            clearInterval(interval)
                            monitorConnection(store, connection)
                        }, 500)
                        return
                    },
                    failure: (error: string) => {
                        console.error(new Error('Error at ADD_CONNECTION'))

                        store.dispatch(
                            addError(
                                JSON.parse(error).message ||
                                    'Oops, there was an error while trying to connect the call',
                            ),
                        )
                        console.log(error)
                    },
                })
                return
            }
            case CallReducer.transferCall.type: {
                store.dispatch(
                    callActions.addConnection(
                        action.payload.number!,
                        action.payload.name,
                        action.payload.endpoint,
                    ),
                )
                return next(action)
            }
            case CallV2Reducer.muteConnection.type: {
                const connectionId = action.payload
                const connection = getConnection<connect.VoiceConnection>(connectionId)

                if (!connection) {
                    console.error(
                        `No connection found when attempting to mute connection ${connectionId}`,
                    )

                    return next(action)
                }

                muteConnection(connection)

                return next(action)
            }
            case CallV2Reducer.holdConnection.type: {
                const connectionId = action.payload
                const connection = getConnection<connect.VoiceConnection>(connectionId)

                if (!connection) {
                    console.error(
                        `No connection found when attempting to hold connection ${connectionId}`,
                    )

                    return next(action)
                }

                holdConnection(connection)

                return next(action)
            }
            case CallV2Reducer.endConnection.type: {
                const connectionId = action.payload
                const connection = getConnection<connect.VoiceConnection>(connectionId)

                if (!connection) {
                    console.error(
                        `No connection found when attempting to end connection ${connectionId}`,
                    )

                    return next(action)
                }

                endConnection(connection)

                return next(action)
            }
            case CallReducer.hold.type: {
                const connection = getConnection(action.payload)
                if (!connection) return

                if (connection.isOnHold()) {
                    return next(action)
                }

                console.log('TRYING TO HOLD', connection)
                connection.hold({
                    success: () => {
                        next(action)
                        //Need to mute agent if putting customer on hold and config is set
                        if (
                            connection.isInitialConnection() &&
                            store.getState().app.appConfig.muteOnHold
                        ) {
                            store.dispatch(callActions.mute())
                        }

                        websocketCompanion.client?.sendMessage(
                            WebSocketCompanionActions.ON_CALL_HOLD_SUCCESS,
                            {
                                connectionID: connection.connectionId,
                            },
                        )
                    },
                    failure: (error: string) => {
                        console.error(new Error('Error at HOLD'))

                        store.dispatch(
                            addError(
                                JSON.parse(error).message ||
                                    'Oops, there was an error while trying to connect the call',
                            ),
                        )
                        console.log(error)
                    },
                })
                return
            }
            case CallReducer.resume.type: {
                const connection = getConnection(action.payload)
                if (!connection) return
                if (!connection.isOnHold()) {
                    return next(action)
                }

                connection.resume({
                    success: () => {
                        next(action)
                        if (
                            connection.isInitialConnection() &&
                            store.getState().app.appConfig.muteOnHold
                        ) {
                            store.dispatch(callActions.unmute())
                        }

                        websocketCompanion.client?.sendMessage(
                            WebSocketCompanionActions.ON_CALL_RESUME_SUCCESS,
                            {
                                connectionID: connection.connectionId,
                            },
                        )
                    },
                    failure: (error: string) => {
                        console.error(new Error('Error at RESUME'))
                        store.dispatch(
                            addError(
                                JSON.parse(error).message ||
                                    'Oops, there was an error while trying to resume the call',
                            ),
                        )
                        console.log(error)

                        websocketCompanion.client?.sendMessage(
                            WebSocketCompanionActions.ON_CALL_RESUME_FAILURE,
                            {
                                connectionID: connection.connectionId,
                            },
                        )
                    },
                })
                return
            }
            case CallReducer.setSelectedCall.type: {
                try {
                    const callContact = getContact()
                    if (!callContact) return
                    const contactState = getContactState(store.getState(), callContact)
                    batch(() => {
                        store.dispatch(setContact(contactState))
                        store.dispatch(addContact(contactState))
                    })
                } catch (error) {
                    console.error('Error during setSelectedCall', error)
                }
                return next(action)
            }
            case CallReducer.endCall.type: {
                getContact()
                    .getConnections()
                    .forEach((c) =>
                        c.isActive()
                            ? c.destroy({
                                  success: () => {
                                      websocketCompanion.client?.sendMessage(
                                          WebSocketCompanionActions.ON_CALL_END_SUCCESS,
                                      )
                                  },
                                  failure: () => {
                                      websocketCompanion.client?.sendMessage(
                                          WebSocketCompanionActions.ON_CALL_END_FAILURE,
                                      )
                                  },
                              })
                            : null,
                    )

                return store.dispatch(callActions.callEnded())
            }
            case CallV2Reducer.updateMonitorStatus.type: {
                const status = action.payload
                const contact = getContact()

                const activeConnections =
                    getContact().getActiveConnections() as connect.VoiceConnection[]

                const hasExistingBarger = activeConnections.some((connection) =>
                    connection.isBarge(),
                )

                // Return early after emitting an error notification if there is already a barging connection
                if (status === connect.MonitoringMode.BARGE && hasExistingBarger) {
                    store.dispatch(
                        createNotification({
                            header: 'Error - Barge failed',
                            type: 'error',
                            text: 'Another manager is currently barged into this call.',
                            closeAfterMs: NOTIFICATION_TIMEOUT_IN_MILLISECONDS,
                        }),
                    )

                    return next(action)
                }
                // Return early after emitting an error notification if the maximum number of active
                // connections including barge has already been reached when attempting to barge
                if (
                    status === connect.MonitoringMode.BARGE &&
                    activeConnections.length > MAX_VOICE_CONNECTIONS_WITH_BARGE
                ) {
                    store.dispatch(
                        createNotification({
                            header: 'Error - Barge failed',
                            type: 'error',
                            text: 'Maximum room capacity reached, please try again later.',
                            closeAfterMs: NOTIFICATION_TIMEOUT_IN_MILLISECONDS,
                        }),
                    )

                    return next(action)
                }

                getContact().updateMonitorParticipantState(status as connect.MonitoringMode, {
                    success: function () {
                        store.dispatch(callV2Actions.setMonitorStatus(status))

                        if (status === connect.MonitoringMode.BARGE) {
                            store.dispatch(
                                updateContactAttributes(contact.contactId, { 'sa-barged': 'yes' }),
                            )
                            store.dispatch(
                                createNotification({
                                    header: 'Success - Barge started',
                                    type: 'success',
                                    text: 'You are now joined to the call.',
                                    closeAfterMs: NOTIFICATION_TIMEOUT_IN_MILLISECONDS,
                                }),
                            )
                        }
                    },
                    failure: function (error) {
                        console.error('An error occurred when updating the monitor status: ', error)
                    },
                })

                return next(action)
            }
            case CallReducer.callEnded.type: {
                const { contact, app } = store.getState()

                if (!contact) return next(action)
                //If the agent hasn't hung up then set the contact attribute to no
                if (!contact.attributes['sa-agent-hungup']) {
                    store.dispatch(updateContactAttributes(contact.ID, { 'sa-agent-hungup': 'no' }))
                }
                //If auto generate report then send off
                if (app.appConfig.autoPostReportIssue) {
                    store.dispatch(
                        appActions.reportIssue(
                            `Auto generated report for contact ${contact?.ID ? contact?.ID : 'unknown'}`,
                        ),
                    )
                }
                return next(action)
            }
            case CallV2Reducer.sendDTMF.type:
            case CallReducer.sendDTMF.type: {
                const connection = getConnection(action.payload.connectionId)
                if (!connection) return
                connection.sendDigits(action.payload.digit, {
                    success: () => {
                        playDTMFTone(action.payload.digit)
                        next(action)
                    },
                    failure: (error: string) => {
                        console.error(new Error('Error at SEND_DTMF'))
                        store.dispatch(
                            addError(
                                JSON.parse(error).message ||
                                    'Oops, there was an error while trying to send the dtmf tone',
                            ),
                        )
                        console.log(error)
                    },
                })
                return next(action)
            }

            case CallV2Reducer.leaveCall.type:
            case CallReducer.leaveCall.type: {
                const agentConnection = getContact().getAgentConnection() as connect.VoiceConnection
                agentConnection.destroy({
                    success: () => {
                        // If we are legacy monitoring then complete the call immediately and prevent ACW
                        if (agentConnection.getType() === 'monitoring') {
                            store.dispatch(callActions.completeCallACW())
                        }
                        return next(action)
                    },
                    failure: (error: string) => {
                        console.error(new Error('Error at LEAVE_CALL'))

                        store.dispatch(
                            addError(
                                JSON.parse(error).message ||
                                    'Oops, there was an error while trying to leave the call',
                            ),
                        )
                        console.log(error)
                    },
                })
                break
            }
            case callActions.endConnection.type: {
                const connection = getConnection(action.payload)
                const activeConnections = getContact()
                    .getConnections()
                    .filter((c) => c.isActive())
                const {
                    call,
                    app: { appConfig },
                } = store.getState()
                const initialConnection = call?.connections.find((c) => c.initialConnection)

                //Check if the main connection has come off hold from the transfer flow
                if (!getContact().getInitialConnection().isOnHold() && initialConnection?.hold) {
                    store.dispatch(callActions.resume(initialConnection.id))
                }
                if (!connection || !connection.isActive()) {
                    websocketCompanion.client?.sendMessage(
                        WebSocketCompanionActions.ON_CONNECTION_END_SUCCESS,
                        {
                            number: call?.number,
                        },
                    )

                    next(action)
                    if (activeConnections.length === 0) {
                        store.dispatch(callActions.callEnded())
                    }

                    return
                }

                //Adding a connection to the contact object using a quick connect ARN
                const transferToEndpoint = (endpoint: connect.Endpoint) => {
                    getContact().addConnection(endpoint, {
                        success: () => {
                            next(action)
                            getContact().getAgentConnection().destroy({})
                            if (activeConnections.length === 0) {
                                store.dispatch(callActions.callEnded())
                            }
                        },
                        failure: (error: string) => {
                            console.log('ERROR', error)
                            destroyCurrentConnection()
                        },
                    })
                }

                //Display errors on the console when they happen
                const endConnectionError = (error: string) => {
                    websocketCompanion.client?.sendMessage(
                        WebSocketCompanionActions.ON_CONNECTION_END_FAILURE,
                        {
                            number: call?.number,
                        },
                    )

                    console.error(new Error('Error at END_CONNECTION'))
                    store.dispatch(
                        addError(
                            JSON.parse(error).message ||
                                'Oops, there was an error while trying to end the call',
                        ),
                    )
                }

                //Generic function to end the current connection
                const destroyCurrentConnection = () => {
                    connection.destroy({
                        success: () => {
                            websocketCompanion.client?.sendMessage(
                                WebSocketCompanionActions.ON_CONNECTION_END_SUCCESS,
                                {
                                    number: call?.number,
                                },
                            )

                            next(action)
                            console.log(activeConnections.length, 'ACTIVE CONNECTIONS')
                            if (activeConnections.length === 0) {
                                store.dispatch(callActions.callEnded())
                            }
                        },
                        failure: (error: string) => {
                            endConnectionError(error)
                        },
                    })
                }

                // If onHangUp config is present and transfer is true (turned on) and the connection is not "INTERNAL-TRANSFER" or outbound
                if (
                    appConfig.onHangUp?.transfer &&
                    initialConnection?.activeConnection &&
                    (connection.getEndpoint().phoneNumber !== 'INTERNAL-TRANSFER' ||
                        connection.getType() !== 'outbound')
                ) {
                    const quickConnects = store.getState().user?.connects
                    const surveyEndpoint = quickConnects?.find(
                        (c) => c.name === appConfig.onHangUp?.transferName,
                    )!
                    // If there are more than 2 connections
                    if (activeConnections.length > 2) {
                        // If hanging up on the initial connection (customer) just destroy the agent connection and leave the customer with the other connection
                        if (
                            store.getState().call?.conferenced &&
                            initialConnection?.id === connection.getConnectionId()
                        ) {
                            return store.dispatch(callActions.leaveCall())
                        }

                        // Destroy the 2nd call first
                        activeConnections[2].destroy({
                            success: () => {
                                next(action)
                                // On success transfer the call to the survey and finish agent connection
                                transferToEndpoint(surveyEndpoint)
                            },
                            failure: (error: string) => {
                                endConnectionError(error)
                            },
                        })
                    } else {
                        // Checking the connection is the customer inbound call (this also catches the initial connection between Agent A / Agent B from Agent B side)
                        if (
                            connection.getType() === 'inbound' &&
                            initialConnection?.id === connection.getConnectionId()
                        ) {
                            transferToEndpoint(surveyEndpoint)
                        } else {
                            destroyCurrentConnection()
                        }
                    }
                    //If there is no transfer config or it's not the initialConnection just destroy the connection
                } else {
                    destroyCurrentConnection()
                }
                return
            }
            case CallReducer.toggleConnections.type:
            case CallV2Reducer.toggleConnections.type:
                getContact().toggleActiveConnections({
                    success: () => next(action),
                    failure: (error: string) => {
                        console.error(new Error('Error at TOGGLE_CONNECTIONS'))

                        store.dispatch(
                            addError(
                                JSON.parse(error).message ||
                                    'Oops, there was an error while trying to switch calls',
                            ),
                        )
                        console.log(error)
                    },
                })
                return
            case CallReducer.conferenceConnections.type:
            case CallV2Reducer.conferenceConnections.type:
                getContact().conferenceConnections({
                    success: () => {
                        const call = store.getState()?.call
                        if (call?.parking) {
                            const initialContactId = getContact().getOriginalContactId()

                            store.dispatch<any>(parkCallContact(initialContactId))
                            store.dispatch(callActions.leaveCall())
                            return
                        }

                        next(action)
                        //Is the connection set to autoHangup
                        const connection = call?.connections[1]
                        if (!connection) return
                        const { disallowedConferenceDestinations, disallowConferencing } =
                            store.getState().app.appConfig
                        if (disallowConferencing) {
                            store.dispatch(callActions.leaveCall())
                            return
                        }

                        if (disallowedConferenceDestinations && connection.destinationID) {
                            const destinationID =
                                connection.destinationID.split('/transfer-destination/')[1]
                            console.log('Checking %s', destinationID)
                            console.log('Against ', disallowedConferenceDestinations)
                            if (disallowedConferenceDestinations.indexOf(destinationID) > -1) {
                                store.dispatch(callActions.leaveCall())
                            }
                        }
                        return
                    },
                    failure: (error: string) => {
                        console.error(new Error('Error at CONFERENCE_CONNECTIONS'))
                        store.dispatch(
                            addError(
                                JSON.parse(error).message ||
                                    'Oops, there was an error while trying to conference the call',
                            ),
                        )
                        console.log(error)
                    },
                })
                return
            case CallReducer.connectionStarted.type: {
                next(action)

                if (store.getState().call?.parking) {
                    store.dispatch(callActions.conferenceConnections())
                }

                const connection = getConnection(action.payload)

                if (connection?.isInitialConnection()) {
                    websocketCompanion.client?.sendMessage(
                        WebSocketCompanionActions.ON_CALL_CONNECTED_SUCCESS,
                        {
                            contactConnectedTimestamp: new Date().toISOString(),
                        },
                    )
                }

                //Need to unmute agent if transferring and they've been put on mute
                if (
                    !connection?.isInitialConnection() &&
                    store.getState().app.appConfig.muteOnHold
                ) {
                    store.dispatch(callActions.unmute())
                }
                return
            }
            case CallReducer.completeCallACW.type: {
                getContact().clear({
                    success: () => {
                        store.dispatch(userActions.afterCallWorkEnd())
                    },
                    failure: (error: any) => console.error('error completing contact', error),
                })
                return next(action)
            }
            case ChatReducer.acceptChat.type: {
                return getChatContact(action.payload).accept({
                    success: () => {
                        next(action)
                        sendEvent({
                            eventName: 'contact_received',
                            eventParams: {
                                company_name: ID,
                                environment: process.env.REACT_APP_ENV,
                                channel: 'CHAT',
                            },
                        })
                    },
                    failure: () =>
                        console.log('failed to accept chat with contactID: ' + action.payload),
                })
            }
            case ChatReducer.declineChat.type: {
                return getChatContact(action.payload)
                    .getInitialConnection()
                    .destroy({
                        success: () => next(action),
                        failure: () => {
                            console.log('Error declining chat')
                            next(action)
                        },
                    })
            }
            case ChatReducer.sendChatMessage.type: {
                const contact = getChatContact(action.payload.id)
                const connection = contact.getAgentConnection() as connect.ChatConnection
                const message = action.payload.msg
                const attributes = contact.getAttributes()
                const socialMediaPlatform = attributes['sa-social-media-platform']
                // make call to service chat
                if (socialMediaPlatform) {
                    const socialChatMessage: SocialChatOutboundMessage = {
                        content: message,
                        channel: attributes['sa-social-media-platform'].value,
                        subChannel: attributes['sa-sub-channel'].value,
                        systemEndpointID: attributes['sa-system-endpoint-id'].value,
                        systemEndpointAddress: attributes['sa-system-endpoint-address'].value,
                        customerEndpointID: attributes['sa-customer-endpoint-id'].value,
                        customerEndpointAddress: attributes['sa-customer-endpoint-address'].value,
                        externalThreadID: attributes['sa-external-thread-id']?.value,
                        externalMessageID: attributes['sa-external-message-id']?.value,
                    }

                    await postSocialChatMessage(socialChatMessage)
                }

                if (!message.trim()) {
                    return next(action)
                }

                return connection
                    .getMediaController()
                    .then(async (controller) => {
                        await controller.sendMessage({
                            message: isJSONRepresentingStructure(message, 'object')
                                ? message
                                : convertMessage(message),
                            contentType: 'text/plain',
                        })
                    })
                    .then((_) => next(action))
            }
            case ChatReducer.receiveChatMessage.type: {
                const messages = store
                    .getState()
                    .chat.connections.find((c) => c.id === action.payload.id)?.messages
                const lastMessage = messages?.slice(-1).pop()

                if (
                    !lastMessage ||
                    (!Array.isArray(action.payload.msg) && lastMessage.Id !== action.payload.msg.Id)
                ) {
                    return next(action)
                }

                return
            }
            case ChatReducer.setSelectedChat.type:
                batch(() => {
                    if (!action.payload) {
                        return store.dispatch(setContact(null))
                    }
                    const { contacts } = store.getState()
                    const contactState =
                        contacts.find((contact) => contact.ID === action.payload) ??
                        getContactState(store.getState(), getChatContact(action.payload ?? ''))
                    store.dispatch(setContact(contactState))
                    store.dispatch(addContact(contactState))

                    const attributes = contactState.attributes
                    // Is email
                    if (attributes['sa-chat-channel']?.value === MessageSources.EMAIL.toString())
                        store.dispatch(setRedirect(`/case/${attributes['sa-case-id']?.value}`))
                    else store.dispatch(setRedirect('/chat'))
                })
                return next(action)
            case ChatReducer.endChat.type:
                return getChatContact(action.payload).getAgentConnection().destroy({}) // dont destroy the contact itself or we lose ACW
            case ChatReducer.completeChatACW.type: {
                store.dispatch(clearChatConnection(action.payload))
                getChatContact(action.payload).clear({
                    failure: (error: any) => console.error('error completing contact', error),
                })
                return next(action)
            }
            case ChatReducer.removeChatConnection.type:
                try {
                    getChatContact(action.payload)
                        ?.getConnections()
                        .forEach((c) =>
                            c.destroy({
                                success: (...info: any) =>
                                    console.log('Chat connection destroyed successfully', info),
                                failure: (error: any) =>
                                    console.error('Destroy chat connection error', error),
                            }),
                        )
                    //This happens if the connection has been ended through the CCP so suppress the error
                } catch (e) {
                    console.error('Chat connection destroy error', e)
                }
                return next(action)
            case ChatReducer.chatMissed.type:
                if (store.getState().contact?.ID === action.payload) {
                    store.dispatch(setContact(null))
                }

                return next(action)
            case ChatReducer.chatSendTypingEvent.type:
                sendTypingEvent(action.payload)
                return next(action)

            case ChatReducer.sendAttachment.type:
                try {
                    const [controller, chatConnections, contact] = await Promise.all([
                        getChatController(action.payload.id),
                        store.getState().chat.connections.find((c) => c.id === action.payload.id),
                        getChatContact(action.payload.id),
                    ])

                    const chatAttachments = chatConnections?.attachments.files ?? []

                    const sendAttachmentAsync = async (
                        attachment: IChatAttachmentFile,
                        index: number,
                    ) => {
                        const response = await controller.sendAttachment({
                            attachment: attachment.file,
                        })
                        store.dispatch(
                            setAttachmentLoading({
                                id: action.payload.id,
                                state: 'done',
                                index,
                            }),
                        )
                        return {
                            attachmentID: response.request.params.AttachmentIds[0],
                            type: attachment.file.type,
                        }
                    }

                    const attachmentsData = await Promise.all(
                        chatAttachments.map((attachment, i) => sendAttachmentAsync(attachment, i)),
                    )
                    const attachmentsToSend = [...attachmentsData]

                    const attributes = contact.getAttributes()
                    const socialMediaPlatform = attributes['sa-social-media-platform']

                    store.dispatch(
                        updateConnectionEditorContent({
                            id: action.payload.id,
                            content: EditorState.createWithContent(ContentState.createFromText('')),
                        }),
                    )

                    if (socialMediaPlatform.value === 'WHATSAPP') {
                        const socialChatMessage: SocialChatOutboundMessage = {
                            content: '',
                            attachmentsToSend,
                            channel: attributes['sa-social-media-platform'].value,
                            subChannel: attributes['sa-sub-channel'].value,
                            systemEndpointID: attributes['sa-system-endpoint-id'].value,
                            systemEndpointAddress: attributes['sa-system-endpoint-address'].value,
                            customerEndpointID: attributes['sa-customer-endpoint-id'].value,
                            customerEndpointAddress:
                                attributes['sa-customer-endpoint-address'].value,
                        }

                        await postSocialChatMessage(socialChatMessage)
                    }

                    if (action.payload.stringMessage.replaceAll('\n', '')) {
                        store.dispatch(
                            sendChatMessage({
                                id: action.payload.id,
                                msg: action.payload.stringMessage,
                                clearEditor: true,
                            }),
                        )
                    }

                    return next(action)
                } catch (err) {
                    console.log(err)
                    return next(action)
                }
            case ChatReducer.downloadAttachment.type:
                if (!store.getState().contact?.ID) {
                    return next(action)
                }

                try {
                    const downloadAttachmentResponse: Blob = await (
                        getChatContact(
                            store.getState().contact?.ID as string,
                        ).getAgentConnection() as connect.ChatConnection
                    )
                        .getMediaController()
                        .then((controller) =>
                            controller.downloadAttachment({
                                attachmentId: action.payload.attachmentId,
                            }),
                        )

                    const objectUrl = window.URL.createObjectURL(downloadAttachmentResponse)
                    const link = document.createElement('a')
                    link.href = objectUrl
                    link.download = action.payload.attachmentName
                    document.body.appendChild(link)

                    link.dispatchEvent(
                        new MouseEvent('click', {
                            bubbles: true,
                            cancelable: true,
                            view: window,
                        }),
                    )

                    if (link.parentNode) {
                        document.body.removeChild(link)
                    }
                    window.URL.revokeObjectURL(objectUrl)
                } catch (error) {
                    Logger.error('DOWNLOAD-LINK-REMOVAL-ERROR', error)
                    console.error('Error during download and link removal:', error)
                }

                return next(action)
            case ChatReducer.downloadConnectAttachmentFromTranscript.type:
                try {
                    const { attachmentId, contactID } = action.payload

                    const attachmentUrl = await getConnectChatAttachmentUrl(contactID, attachmentId)

                    const anchorTag = document.createElement('a')
                    anchorTag.href = attachmentUrl
                    anchorTag.target = '_blank'
                    anchorTag.download = attachmentId
                    document.body.appendChild(anchorTag)
                    anchorTag.click()
                    if (anchorTag.parentNode) {
                        document.body.removeChild(anchorTag)
                    }
                } catch (error) {
                    Logger.error('DOWNLOAD-LINK-REMOVAL-ERROR', error)
                    console.error('Error during download and link removal:', error)
                }
                return next(action)
            case ChatReducer.transferChat.type:
                getChatContact(action.payload.id).addConnection(action.payload.endpoint, {
                    success: console.log,
                    failure: console.log,
                })
                return next(action)
            case ChatReducer.clearUnread.type:
                const { messages, status } = store
                    .getState()
                    .chat.connections.find((c) => c.id === action.payload)!
                const unreadCustomerMessages = messages.filter(
                    (message) =>
                        message.ParticipantRole === 'CUSTOMER' &&
                        ['text/markdown', 'text/plain'].includes(message.ContentType!) &&
                        !message.readTimestamp,
                )
                if (!unreadCustomerMessages.length) {
                    return next(action)
                }

                // send read receipt if its a whatsapp message
                const lastUnreadMessageContent = unreadCustomerMessages[
                    unreadCustomerMessages.length - 1
                ].content as SAMessageType
                if (
                    lastUnreadMessageContent?.externalMessageID?.startsWith('wamid') &&
                    lastUnreadMessageContent?.systemEndpointID
                ) {
                    const contact = store.getState().contact
                    const systemEndpointAddress =
                        contact && contact.ID === action.payload
                            ? contact.systemEndpointAddress
                            : undefined
                    if (systemEndpointAddress && status === connect.ContactStateType.CONNECTED) {
                        sendWhatsAppReadReceipt({
                            messageID: lastUnreadMessageContent.externalMessageID,
                            whatsAppCustomerConfigID: systemEndpointAddress,
                            phoneNumberID: lastUnreadMessageContent.systemEndpointID,
                        })
                    }
                }

                const controller = await getChatController(action.payload)
                unreadCustomerMessages.forEach((message) => {
                    controller.sendEvent({
                        content: JSON.stringify({ messageId: message.Id }),
                        contentType: ChatMessageEvent.MESSAGE_READ,
                    })
                })

                return next(action)

            case TasksReducer.acceptTask.type: {
                return getTaskContact(action.payload).accept({
                    success: () => {
                        next(action)
                        sendEvent({
                            eventName: 'contact_received',
                            eventParams: {
                                company_name: ID,
                                environment: process.env.REACT_APP_ENV,
                                channel: 'TASK',
                            },
                        })

                        const tasks = store.getState().tasks?.connections
                        const hasActiveTask = tasks.find(
                            (c) => c.status === connect.ContactStateType.CONNECTED,
                        )

                        if (!hasActiveTask) {
                            store.dispatch(taskActions.setSelectedTask(action.payload))
                        }
                    },
                    failure: () =>
                        console.log('failed to accept task with contactID: ' + action.payload),
                })
            }

            case TasksReducer.declineTask.type: {
                return getTaskContact(action.payload)
                    .getInitialConnection()
                    .destroy({
                        success: () => next(action),
                        failure: () => {
                            console.log('Error declining task')
                            next(action)
                        },
                    })
            }
            case TasksReducer.setSelectedTask.type:
                batch(() => {
                    if (!action.payload) {
                        return store.dispatch(setContact(null))
                    }
                    const taskContact = getTaskContact(action.payload ?? '')
                    if (taskContact) {
                        const taskContactState = getContactState(store.getState(), taskContact)
                        store.dispatch(setContact(taskContactState))
                        store.dispatch(addContact(taskContactState))
                        store.dispatch(setRedirect(`/tasks`))
                    }
                })
                return next(action)
            case TasksReducer.endTask.type:
                return getTaskContact(action.payload).getAgentConnection().destroy({}) // dont destroy the contact itself or we lose ACW
            case TasksReducer.completeTaskACW.type: {
                store.dispatch(taskActions.clearTaskConnection(action.payload))
                getTaskContact(action.payload).clear({
                    failure: (error: any) => console.error('error completing contact', error),
                })
                return next(action)
            }
            case TasksReducer.removeTaskConnection.type:
                try {
                    getTaskContact(action.payload)
                        ?.getConnections()
                        .forEach((c) => c.destroy({}))
                } catch (ex) {
                    //This happens if the connection has been ended through the CCP so suppress the error
                    console.error('error removing connection', ex)
                }
                return next(action)
            case TasksReducer.taskMissed.type:
                if (store.getState().contact?.ID === action.payload) {
                    store.dispatch(setContact(null))
                }
                return next(action)
            case TasksReducer.transferTask.type:
                getTaskContact(action.payload.id).addConnection(action.payload.endpoint, {
                    success: console.log,
                    failure: (err: any) => {
                        console.log('ERROR', err)
                        store.dispatch(addError(err.message || 'Error while transferring a task'))
                    },
                })
                return next(action)
            default:
                return next(action)
        }
    }
}

export default ccpContactMiddleware

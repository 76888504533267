import type {
    CallConnection,
    CallProperties,
    ConnectionType,
    Controls,
    Handlers,
    InitiationMethod,
    MonitorInfo,
    MonitorStatus,
} from 'store/callV2/callV2.state'
import type { IOriginalContactState } from 'store/contact/contact.state'
import { MAX_VOICE_CONNECTIONS } from 'store/middleware/ccp/ccp.constants'

export const getConnectionType = (connection: connect.VoiceConnection): ConnectionType => {
    const type = connection.getType()

    const isQuickConnect = connection.getQuickConnectName() !== null

    const phoneNumber = connection.getAddress().phoneNumber

    const isInitialConnection = connection.isInitialConnection()

    const isOutboundTransfer =
        type === 'outbound' && phoneNumber !== 'INTERNAL-TRANSFER' && !isInitialConnection

    if (isQuickConnect) return 'QC_TRANSFER'

    if (isOutboundTransfer) return 'OUTBOUND_TRANSFER'

    if (type === 'agent') return 'YOU'

    if (phoneNumber === 'INTERNAL-TRANSFER') return 'AGENT'

    return 'CUSTOMER'
}

export const getConnectionName = (
    connection: connect.VoiceConnection,
    username?: string,
): string | undefined => {
    let name: string | undefined

    const connectionStatusType = connection.getStatus().type

    const type = getConnectionType(connection)

    switch (connectionStatusType) {
        case connect.ConnectionStateType.CONNECTING:
            return type === 'OUTBOUND_TRANSFER'
                ? connection.getEndpoint().phoneNumber
                : 'INTERNAL-TRANSFER'
        case connect.ConnectionStateType.SILENT_MONITOR:
        case connect.ConnectionStateType.BARGE:
            return
    }

    switch (type) {
        case 'QC_TRANSFER':
            name = connection.getQuickConnectName() ?? 'Unknown'
            break
        case 'OUTBOUND_TRANSFER':
        case 'CUSTOMER':
            name = connection.getEndpoint().phoneNumber ?? 'Unknown'
            break
        case 'AGENT':
            name = username ?? 'Unknown'
            break
        case 'YOU':
            break
        default:
            name = 'Unknown'
            break
    }

    return name
}

export const muteConnection = (connection: connect.VoiceConnection): void => {
    const isMuted = connection.isMute()

    if (isMuted) {
        connection.unmuteParticipant({
            failure: (error) => console.error('failed to unmute participant: ', error),
        })
    } else {
        connection.muteParticipant({
            failure: (error) => console.error('failed to mute participant: ', error),
        })
    }
}

export const holdConnection = (connection: connect.VoiceConnection): void => {
    const isOnHold = connection.isOnHold()

    if (isOnHold) {
        connection.resume({
            failure: (error) => console.error('failed to resume participant: ', error),
        })
    } else {
        connection.hold({
            failure: (error) => console.error('failed to hold participant: ', error),
        })
    }
}

export const endConnection = (connection: connect.VoiceConnection): void => {
    connection.destroy({
        failure: (error) => console.error('failed to end connection: ', error),
    })
}

export const checkConnectionLimit = (connections: connect.VoiceConnection[]): boolean => {
    const activeConnections = connections.filter((connection) => connection.isActive())
    return activeConnections.length >= MAX_VOICE_CONNECTIONS
}

export const getActiveConnections = (
    connections: connect.VoiceConnection[],
): connect.VoiceConnection[] => connections.filter((connection) => connection.isActive())

export const getCallProperties = (connections: connect.VoiceConnection[]): CallProperties => {
    let hasOnHoldYouConnection = false
    let hasActiveCustomerConnection = false
    let hasConnectingConnection = false
    let hasMonitoringConnection = false
    let hasBargerJoined = false
    let youAreBarging = false

    const activeConnections = getActiveConnections(connections)
    const hasReachedConnectionLimit = checkConnectionLimit(activeConnections)
    const hasOnlyTwoParticipants = activeConnections.length === 2

    for (const connection of activeConnections) {
        if (!hasOnHoldYouConnection) {
            hasOnHoldYouConnection =
                getConnectionType(connection) === 'YOU' && connection.isOnHold()
        }

        if (!hasActiveCustomerConnection) {
            hasActiveCustomerConnection =
                getConnectionType(connection) === 'CUSTOMER' && connection.isActive()
        }

        if (!hasConnectingConnection) {
            hasConnectingConnection = connection.isConnecting()
        }

        if (!hasMonitoringConnection) {
            hasMonitoringConnection =
                connection.getStatus().type === connect.ConnectionStateType.SILENT_MONITOR
        }

        if (!hasBargerJoined) {
            hasBargerJoined = connection.isBarge()
        }

        if (!youAreBarging) {
            youAreBarging = connection.isBarge() && getConnectionType(connection) === 'YOU'
        }

        if (
            hasOnHoldYouConnection &&
            hasActiveCustomerConnection &&
            hasConnectingConnection &&
            hasMonitoringConnection &&
            hasBargerJoined &&
            youAreBarging
        )
            break
    }

    return {
        hasOnHoldYouConnection,
        hasActiveCustomerConnection,
        hasConnectingConnection,
        hasReachedConnectionLimit,
        hasOnlyTwoParticipants,
        hasMonitoringConnection,
        hasBargerJoined,
        youAreBarging,
    }
}

export const buildControlsMapping = (
    connections: connect.VoiceConnection[],
    handlers: Handlers,
): Controls[] => {
    const callProperties = getCallProperties(connections)
    const {
        hasOnHoldYouConnection,
        hasActiveCustomerConnection,
        hasConnectingConnection,
        hasReachedConnectionLimit,
        hasOnlyTwoParticipants,
        hasMonitoringConnection,
        hasBargerJoined,
        youAreBarging,
    } = callProperties

    return connections.map<Controls>((connection) => {
        if (hasMonitoringConnection) {
            return {
                ...handlers,
                transfer: false,
                mute: false,
                hold: false,
                end: false,
                keypad: false,
            }
        }

        const isYouConnection = isConnectionYou(connection)
        const isActive = connection.isActive()
        const isConnected = connection.isConnected()
        const isOnHold = connection.isOnHold()
        const isForcedMute = connection.isForcedMute()

        //Transfer logic
        const baseCanTransferChecks =
            isYouConnection &&
            !isOnHold &&
            hasActiveCustomerConnection &&
            !hasReachedConnectionLimit

        const canTransferAsBarger = baseCanTransferChecks && youAreBarging

        const canTransferWithoutBarger = baseCanTransferChecks && !hasBargerJoined

        const canTransfer = canTransferAsBarger || canTransferWithoutBarger

        //Mute logic
        const baseCanMuteChecks =
            !hasOnHoldYouConnection && !isOnHold && isConnected && !hasConnectingConnection

        const canMuteAsBarger = baseCanMuteChecks && youAreBarging && !hasOnlyTwoParticipants

        const canMuteWithBarger =
            (baseCanMuteChecks && !youAreBarging && !isForcedMute && isYouConnection) ||
            (baseCanMuteChecks && isYouConnection && hasOnlyTwoParticipants)

        const canMuteWithoutBarger = baseCanMuteChecks && !hasBargerJoined

        const canMute = canMuteWithoutBarger || canMuteWithBarger || canMuteAsBarger

        //Hold logic
        const baseHoldChecks =
            ((!hasOnHoldYouConnection && (isConnected || isOnHold)) || isYouConnection) &&
            !hasConnectingConnection

        const canHoldWithoutBarger = baseHoldChecks && !hasBargerJoined

        const canHoldAsBarger = baseHoldChecks && youAreBarging && !hasOnlyTwoParticipants

        const canHoldWithBarger = baseHoldChecks && hasOnlyTwoParticipants && isYouConnection

        const canHold = canHoldWithoutBarger || canHoldWithBarger || canHoldAsBarger

        //End logic
        const baseEndChecks = (!hasOnHoldYouConnection && isActive) || isYouConnection

        const canEndWithoutBarger = baseEndChecks && !hasBargerJoined

        const canEndWithBarger = baseEndChecks && isYouConnection

        const canEndAsBarger = baseEndChecks && youAreBarging && !hasOnlyTwoParticipants

        const canEnd = canEndWithoutBarger || canEndWithBarger || canEndAsBarger

        const canUseKeypad = canConnectionUseKeypad(connection, callProperties)

        return {
            ...handlers,
            transfer: canTransfer,
            mute: canMute,
            hold: canHold,
            end: canEnd,
            keypad: canUseKeypad,
        }
    })
}

function canConnectionUseKeypad(
    connection: connect.VoiceConnection,
    callProperties: CallProperties,
): boolean {
    return (
        isConnectionYou(connection) &&
        !connection.isSilentMonitor() &&
        !isAnotherAgentBarging(connection, callProperties) &&
        !connection.isOnHold()
    )
}

function isConnectionYou(connection: connect.VoiceConnection): boolean {
    return getConnectionType(connection) === 'YOU'
}

function isAnotherAgentBarging(
    connection: connect.VoiceConnection,
    callProperties: CallProperties,
): boolean {
    return callProperties.hasBargerJoined && !connection.isBarge()
}

export const createConnection = (
    connection: connect.VoiceConnection,
    controls: Controls,
    username?: string,
): CallConnection => ({
    id: connection.getConnectionId(),
    status: connection.getStatus(),
    type: getConnectionType(connection),
    name: getConnectionName(connection, username),
    isMuted: connection.isMute(),
    isForceMuted: connection.isForcedMute(),
    // when a monitor joins, the status on the monitor side will be SILENT_MONITOR
    // however on the other agents side it will be BARGE, this property is a catch -
    // all for any case where monitoring is in progress
    isMonitoringOrBarging: connection.isBarge() || connection.isSilentMonitor(),
    isBarge: connection.isBarge(),
    isConnected: isConnected(connection.getStatus()),
    controls,
})

export const updateConnection = (
    previousConnection: CallConnection,
    newConnection: CallConnection,
): CallConnection => {
    const { status: previousStatus, type: previousType, name: previousName } = previousConnection
    const {
        status: newStatus,
        type: newType,
        name: newName,
        controls: newControls,
        isMuted: newIsMuted,
        isForceMuted: newIsForceMuted,
    } = newConnection

    const hasConnected =
        previousStatus.type === connect.ConnectionStateType.CONNECTING &&
        newStatus.type === connect.ConnectionStateType.CONNECTED

    const hasUpdatedToQCTransfer = previousType !== 'QC_TRANSFER' && newType === 'QC_TRANSFER'

    const shouldUpdateName = hasConnected || hasUpdatedToQCTransfer

    return {
        ...previousConnection,
        name: shouldUpdateName ? newName : previousName,
        type: newType,
        status: newStatus,
        isMuted: newIsMuted,
        isForceMuted: newIsForceMuted,
        controls: newControls,
        isConnected: isConnected(newStatus),
    }
}

const isConnected = (status: connect.ConnectionState): boolean => {
    const { DISCONNECTED, CONNECTING } = connect.ConnectionStateType

    return ![DISCONNECTED, CONNECTING].includes(status.type)
}

export const getMonitorInfo = (connection: connect.VoiceConnection): MonitorInfo => {
    let status: MonitorStatus | undefined

    if (connection.getType() === connect.ConnectionType.MONITORING) {
        status = 'SILENT_MONITOR'
    } else {
        status = connection.getMonitorStatus() || undefined
    }

    return { status }
}

// Temporary mapper function until we update the type of the direction property to match the initiation method values given by Connect
export const getInitiationMethod = (initiationMethod: string | undefined): InitiationMethod => {
    if (!initiationMethod) return 'unknown'

    switch (initiationMethod) {
        case 'INBOUND':
            return 'inbound'
        case 'OUTBOUND':
            return 'outbound'
        case 'TRANSFER':
            return 'transfer'
        case 'QUEUE_TRANSFER':
            return 'queue_transfer'
        case 'CALLBACK':
            return 'callback'
        case 'API':
            return 'api'
        case 'DISCONNECT':
            return 'disconnect'
        case 'MONITOR':
            return 'monitoring'
        case 'EXTERNAL_OUTBOUND':
            return 'external_outbound'
        default:
            return 'unknown'
    }
}

export function getTimestamp({
    AgentInfo,
    InitiationMethod,
    InitiationTimestamp,
}: IOriginalContactState) {
    let timestamp: string = new Date().toISOString()

    const initiationMethod = getInitiationMethod(InitiationMethod)
    if (initiationMethod === 'inbound') {
        timestamp = InitiationTimestamp
    } else if (initiationMethod === 'outbound') {
        timestamp = AgentInfo?.ConnectedToAgentTimestamp
    }

    return timestamp
}

import type { ThunkDispatch } from '@reduxjs/toolkit'
import logger from 'logger'
import * as DirectoryAPI from 'services/api/api.externalDirectory'

import RootState from '../../state'
import * as OwnersDirectoryActions from './reducers'

export const getDirectoryOwners = () => {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, OwnersDirectoryActions.OwnersDirectoryAction>,
        getState: () => RootState,
    ) => {
        try {
            dispatch(OwnersDirectoryActions.getOwnersLoading(true))

            const { app, auth } = getState()

            const response = await DirectoryAPI.getDirectoryOwners(app.ID, auth.token!)

            dispatch(OwnersDirectoryActions.getOwners(response.data))
            dispatch(OwnersDirectoryActions.getOwnersLoading(false))
        } catch (err) {
            logger.error('GET-DIRECTORY-OWNERS', err as object)
        }
    }
}

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRestructuredDirectoryContactsByPhoneNumber } from 'store/directory/restructuredContactsByPhoneNumber/actions'
import RootState from 'store/state'
import { isEmpty } from 'utils'

import useDirectoryContacts from './useDirectoryContacts'

const useRestructuredDirectoryContacts = () => {
    const [contacts, areDirectoryContactsLoading] = useDirectoryContacts()

    const dispatch = useDispatch()

    const restructuredContacts = useSelector<
        RootState,
        RootState['directory']['restructuredDirectoryContacts']
    >(({ directory }) => directory.restructuredDirectoryContacts)

    const { contactsWithKeyAsPhoneNumber, isProcessing } = restructuredContacts

    useEffect(() => {
        if (!isEmpty(contacts) && isEmpty(contactsWithKeyAsPhoneNumber)) {
            dispatch(getRestructuredDirectoryContactsByPhoneNumber(contacts))
        }
    }, [contacts])

    return [contactsWithKeyAsPhoneNumber, areDirectoryContactsLoading || isProcessing] as [
        typeof contactsWithKeyAsPhoneNumber,
        typeof isProcessing,
    ]
}

export default useRestructuredDirectoryContacts

/* eslint-disable jsx-a11y/media-has-caption */
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { Button, Tag, TimeCountdown, TimeElapsed } from '@missionlabs/smartagent-app-components'
import { Props as TagProps } from '@missionlabs/smartagent-app-components/dist/Tag'

import useContact from 'hooks/redux/useContact'
import useMonitoring from 'hooks/redux/useMonitoring'
import useContactLogForm from 'hooks/useContactLogForm'
import { AppConfig } from 'store/app/app.state'
import { completeCallACW } from 'store/call/call.actions'
import RootState, { LoadingStates } from 'store/state'
import { setState } from 'store/user/user.actions'
import { isContactLogEmpty } from 'utils'
import CompleteWrapUp from '../ContactLog/complete-wrap-up'

type TagType = TagProps['type']

interface Props extends RouteComponentProps {
    start: Date
    appConfig: AppConfig
    appConfigurations: any
    onTimeout: () => void
    hideWrapUpButton?: boolean
}

const AfterCallWork: React.FC<Props> = ({
    start,
    appConfigurations,
    appConfig,
    onTimeout,
    hideWrapUpButton,
}) => {
    const contactLog = useContactLogForm()
    const { isMonitoringOrBarging, isEnhancedMonitoringEnabled } = useMonitoring()

    const getACWTimeout = (appConfigurations: any, appConfig: any) => {
        if (appConfigurations.isAfterCallWorkLimited === false) {
            return false
        }
        const timeoutSeconds =
            contactLog?.form?.wrapTimeSeconds ?? appConfigurations.afterCallWorkTimeoutSeconds
        if (
            appConfig.afterCallWorkInitialTimeoutSeconds === 0 &&
            typeof timeoutSeconds === 'undefined'
        ) {
            return false
        }
        if (timeoutSeconds === 0 && appConfigurations.isAfterCallWorkLimited) {
            return true
        }
        return timeoutSeconds ?? appConfig.afterCallWorkInitialTimeoutSeconds
    }

    const [tagType, setTagType] = useState(
        getACWTimeout(appConfigurations, appConfig) ? 'red' : 'grey',
    )
    const [countdown, setCountdown] = useState(!!getACWTimeout(appConfigurations, appConfig))
    const [flashInterval, setFlashInterval] = useState<any>(undefined)
    const [flash, setFlash] = useState(false)
    const { parkBay } = useContact() ?? {}

    const nextAgentStatus = useSelector((state: RootState) => state.user?.afterCallStatus)

    const dispatch = useDispatch()

    useEffect(
        () => () => {
            clearInterval(flashInterval)
            // eslint-disable-next-line
        },
        [],
    )

    useEffect(() => {
        if (isEnhancedMonitoringEnabled && isMonitoringOrBarging) {
            dispatch(completeCallACW())
        }
    }, [isMonitoringOrBarging])

    let audio: HTMLAudioElement | null = null

    const onExtend = () => {
        if (appConfigurations?.allowAfterCallWorkExtension === false) return
        if (appConfig.hideExtendACW) return
        setCountdown(false)
        setTagType('grey')
        clearInterval(flashInterval)
    }

    const onTimeChange = (time: number) => {
        const newTagType = countdown ? 'red' : time < 60 ? 'grey' : time < 120 ? 'orange' : 'red'

        if (time === 0) clearInterval(flashInterval)
        if (time === 5 && countdown && appConfig.afterCallWorkPlayAlertTone) audio!.play()
        if (time === 10)
            setFlashInterval(
                setInterval(() => {
                    setFlash(!flash)
                }, 500),
            )
        if (newTagType !== tagType) setTagType(tagType)
    }

    const noContactLogOrEmpty = isContactLogEmpty(contactLog)
    const isContactLogLoading = contactLog?.state === LoadingStates.LOADING
    const showFinishWrapUpButton = !hideWrapUpButton && noContactLogOrEmpty && !isContactLogLoading
    const canUserExtendWrapTime =
        contactLog?.form?.extendWrapTime ??
        appConfigurations?.allowAfterCallWorkExtension ??
        !appConfig.hideExtendACW

    return (
        <>
            <section className="aftercall row middle between">
                <audio
                    ref={(r) => (audio = r)}
                    src="https://prod-cdn-12059162352.s3.eu-west-2.amazonaws.com/audio/rollover.mp3"
                />
                <div className="aftercall-timer">
                    <div>
                        <h3 className="title">Wrapping up...</h3>
                        {parkBay !== undefined && (
                            <p className="xsm-mar-top">Parked call to slot {parkBay}</p>
                        )}
                    </div>
                    <div className="aftercall-controls">
                        {canUserExtendWrapTime && (
                            <Button
                                onClick={onExtend}
                                className={classNames({
                                    'aftercall-controls-extend': true,
                                    flash: countdown && flash,
                                    disabled: !countdown,
                                })}
                            >
                                Extend
                            </Button>
                        )}
                        <Tag small title="wrap up" type={tagType as TagType}>
                            {countdown ? (
                                <TimeCountdown
                                    ariaLive="custom"
                                    startTime={getACWTimeout(appConfigurations, appConfig)}
                                    onZero={onTimeout}
                                    onChange={onTimeChange}
                                />
                            ) : (
                                <TimeElapsed ariaLive="off" date={start} onChange={onTimeChange} />
                            )}
                        </Tag>
                    </div>
                </div>
                {showFinishWrapUpButton && (
                    <CompleteWrapUp
                        onComplete={() => {
                            // Temporary batch wrap until we've fully migrated to React 18
                            batch(() => {
                                nextAgentStatus && dispatch(setState(nextAgentStatus))
                                dispatch(completeCallACW())
                            })
                        }}
                    />
                )}
            </section>
        </>
    )
}

export default withRouter(AfterCallWork)

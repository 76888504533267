import { makeRequest } from 'services/api'
import { IQueueMetrics, IQueueMetricsWithConfig } from 'store/metrics/metrics.state'
import { IContactFlow, IEnabledQueue, IQueue } from 'store/settings/settings.state'

export async function fetchQueueStats(
    companyID: string,
    token: string,
    instanceID: string,
    queues?: string,
    summary?: boolean,
) {
    const response = await makeRequest({
        url: `/queue-service/companies/${companyID}/instances/${instanceID}/queues/stats/?${summary ? 'summary=true&' : ''}metricTargets=true${queues ? `&queueIDs=${queues}` : ''}`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response.data
}

export async function getQueues(companyID: string, instanceID: string, token: string) {
    const response = await makeRequest<IQueue[]>({
        url: `/queue-service/companies/${companyID}/instances/${instanceID}/queues`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })
    return response.data
}

export async function getEnabledQueues(
    companyID: string,
    instanceID: string,
    tags: Record<string, string>,
    token: string,
) {
    const response = await makeRequest<IEnabledQueue[]>({
        url: `/queue-service/companies/${companyID}/instances/${instanceID}/realtime-metrics/enabled-queues-list`,
        method: 'post',
        headers: {
            'X-Amz-Security-Token': token,
        },
        data: {
            tags,
        },
    })

    return response.data
}

export async function getContactFlows(companyID: string, instanceID: string, token: string) {
    const response = await makeRequest<IContactFlow[]>({
        url: `/queue-service/companies/${companyID}/instances/${instanceID}/contact-flows?types=CONTACT_FLOW`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response.data
}

export async function getQueueMetricsV2({
    companyID,
    instanceID,
    token,
    fromDate,
    queues = [],
}: {
    companyID: string
    instanceID: string
    token: string
    fromDate: string
    queues: string[]
}) {
    const response = await makeRequest<IQueueMetrics[]>({
        url: `/queue-service/companies/${companyID}/instances/${instanceID}/metrics`,
        method: 'post',
        data: {
            fromDate,
            queues,
        },
        headers: {
            'X-Amz-Security-Token': token,
        },
    })
    return response.data
}

export async function getAllQueueMetrics({
    companyID,
    instanceID,
    token,
    dataTypes,
    tags,
}: {
    companyID: string
    instanceID: string
    token: string
    dataTypes: string[]
    tags: Record<string, string>
}) {
    const response = await makeRequest<IQueueMetricsWithConfig>({
        url: `/queue-service/companies/${companyID}/instances/${instanceID}/realtime-metrics/all`,
        method: 'post',
        data: {
            dataTypes,
            tags,
        },
        headers: {
            'X-Amz-Security-Token': token,
        },
    })
    return response.data
}

export async function getSpecificQueueMetrics({
    companyID,
    instanceID,
    token,
    dataTypes,
    queues,
    tags,
}: {
    companyID: string
    instanceID: string
    token: string
    dataTypes: string[]
    queues: string[]
    tags: Record<string, string>
}) {
    const response = await makeRequest<IQueueMetricsWithConfig>({
        url: `/queue-service/companies/${companyID}/instances/${instanceID}/realtime-metrics`,
        method: 'post',
        data: {
            dataTypes,
            queues,
            tags,
        },
        headers: {
            'X-Amz-Security-Token': token,
        },
    })
    return response.data
}

export async function getEnabledQueuesWithOutboundCallerIdNumber(
    companyID: string,
    instanceID: string,
    token: string,
    queueIds: string[],
) {
    const response = await makeRequest<IEnabledQueue[]>({
        url: `/queue-service/companies/${companyID}/instances/${instanceID}/enabled-queues-list`,
        method: 'post',
        headers: {
            'X-Amz-Security-Token': token,
        },
        data: {
            queueIDs: queueIds,
            hasOutboundCallerIdNumberId: true,
        },
    })

    return response.data
}

import { makeRequest } from 'services/api'
import { AuthProviderConfig, IAppFeature } from 'store/settings/settings.state'

export async function refreshToken(
    companyID: string,
    username: string,
    refreshToken: string,
    instanceID: string,
) {
    return await makeRequest({
        url: `/auth-service/companies/${companyID}/tokens/refresh`,
        method: 'post',
        data: {
            username: username,
            refreshTokenID: refreshToken,
            instanceID,
        },
    })
}

export function logout(companyID: string, token: string, username: string, instanceID: string) {
    makeRequest({
        url: `/auth-service/companies/${companyID}/tokens/${token}`,
        method: 'delete',
        data: {
            username: username,
            instanceID,
        },
    })
}

export async function authenticateUser(
    companyID: string,
    token: string,
    instanceID: string,
    username?: string,
    tokenInBody: boolean = false,
    identityManagement?: string,
) {
    if (tokenInBody)
        return await makeRequest({
            url: `/auth-service/companies/${companyID}/tokens`,
            method: 'put',
            data: {
                username,
                instanceID,
                tokenID: token,
                identityManagement,
            },
        })
    else
        return await makeRequest({
            url: `/auth-service/companies/${companyID}/tokens/${token}`,
            method: 'put',
            data: {
                username,
                instanceID,
            },
        })
}

export async function requestToken(companyID: string, username: string, instanceID: string) {
    return await makeRequest({
        url: `/auth-service/companies/${companyID}/tokens`,
        method: 'post',
        data: {
            username,
            instanceID,
        },
    })
}

export async function getPermissions(companyID: string, token: string) {
    const response = await makeRequest({
        url: `/auth-service/companies/${companyID}/permissions`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response.data
}

export async function putPermissions(companyID: string, token: string, permissions: any) {
    const response = await makeRequest({
        url: `/auth-service/companies/${companyID}/permissions`,
        method: 'put',
        headers: {
            'X-Amz-Security-Token': token,
        },
        data: permissions,
    })
    return response.data
}

export async function getAuthProviderConfig(
    companyID: string,
    instanceID: string,
): Promise<AuthProviderConfig> {
    const response = await makeRequest<AuthProviderConfig>({
        url: `/auth-service/companies/${companyID}/instances/${instanceID}/auth`,
        method: 'get',
    })

    return response.data
}

export async function getFeatureList() {
    const response = await makeRequest<IAppFeature[]>({
        url: `/auth-service/features`,
        method: 'get',
    })

    return response.data
}

import { jsx } from "react/jsx-runtime";
import { useState } from "react";
const QuickReplies = ({
  ariaId,
  quickReplies,
  sendMessage,
  showQuickRepliesOnMount = true,
  translations
}) => {
  const [disabled, setDisabled] = useState(false);
  const handleQuickReplyClick = (qr) => {
    sendMessage(qr.payload, qr.title);
    setDisabled(true);
  };
  if (!(quickReplies == null ? void 0 : quickReplies.length) || !showQuickRepliesOnMount)
    return null;
  return /* @__PURE__ */ jsx("div", { id: ariaId, role: "region", "aria-label": translations.quickRepliesAriaLabel, className: "sa-qr-wrapper", children: /* @__PURE__ */ jsx("ul", { className: "sa-qr-container-container", children: quickReplies.map((qr) => /* @__PURE__ */ jsx("li", { children: /* @__PURE__ */ jsx(
    "button",
    {
      className: "sa-qr-button",
      onClick: () => handleQuickReplyClick(qr),
      disabled,
      children: /* @__PURE__ */ jsx("span", { children: qr.title })
    }
  ) }, qr.title)) }) });
};
export {
  QuickReplies as default
};

import { ReactComponent as Arrow } from 'images/icon-arrow-green-8x14.svg'
import { ReactComponent as Cross } from 'images/icon-cross-xsmall.svg'
import { ReactComponent as InputIcon } from 'images/icon-search.svg'
import React from 'react'
import styles from './PopupInterface.module.scss'

interface Props {
    onBackButtonClick: (e: React.FormEvent) => void
    onCloseButtonClick: () => void
    showBackButton: boolean
    onSearchChange: (value: string) => void
    searchInputValue: string
}

const PopupInterface = ({
    onBackButtonClick,
    onCloseButtonClick,
    showBackButton,
    onSearchChange,
    searchInputValue,
}: Props) => (
    <div className={styles.wrapper}>
        <div className={styles.backButtonAndNameWrapper}>
            {showBackButton ? (
                <button className={styles.backButton} onClick={onBackButtonClick}>
                    <Arrow className={styles.icon} />
                    <span>Back</span>
                </button>
            ) : (
                <p className={styles.popupName}>Quick replies</p>
            )}
        </div>

        <div className={styles.input}>
            <InputIcon className={styles.inputIcon} />
            <input
                className={styles.search}
                value={searchInputValue}
                type="text"
                placeholder="Search..."
                onChange={(evt) => {
                    onSearchChange(evt.target.value)
                }}
            />
            {searchInputValue && (
                <button
                    data-testid="clearSearchButton"
                    className={styles.clearSearchButton}
                    onClick={() => onSearchChange('')}
                >
                    <Cross />
                </button>
            )}
        </div>

        <button
            data-testid="closePopupButton"
            className={styles.crossButton}
            onClick={onCloseButtonClick}
        >
            <Cross className={styles.cross} />
        </button>
    </div>
)

export default PopupInterface

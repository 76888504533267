import React from 'react'

interface Props {
    text: string
    hidden?: boolean
}

/**
 * A centralised component to control what notifications are provided to accessible technologies as an alert.
 */
export const AccessibleAlert: React.FC<Props> = ({ text, hidden = false }) => {
    if (hidden) return null

    return (
        <div role="alert" className="only-visible-to-screen-readers">
            <span>{text}</span>
        </div>
    )
}

import { Button, Popup, TriggeredPopup } from '@missionlabs/smartagent-app-components'
import { LOG_GROUP_ATTRIBUTE_NAME } from 'constants/index'
import { Component } from 'react'
import { batch, connect as reduxConnect } from 'react-redux'
import { FormState } from 'store/app/app.state'
import RootState from 'store/state'
import {
    logout,
    setAfterCallStatus,
    setAvailable,
    setNotAvailable,
    setShowNextStatus,
    setState,
} from 'store/user/user.actions'
import { getFormName, getRequiredFields, isAllRequiredFieldsFilled, isContactLogEmpty } from 'utils'
import HandsUp from 'views/HandsUp'
import PhoneBook from 'views/InternalMessaging'
import Announcements from 'views/UserAnnouncements'
import UserStatus from './UserStatus'
import './user.scss'
import UserStatusOptions from './userStatusOptions'

export interface UserProps {
    changingState: string
    blockStatusChange?: boolean
}

interface Props extends UserProps, ReturnType<typeof mapStateToProps> {
    setNotAvailable: () => void
    setAvailable: () => void
    setState: typeof setState
    logout: () => void
    setShowNextStatus: typeof setShowNextStatus
    setAfterCallStatus: typeof setAfterCallStatus
}

interface State {
    showConfirmation: boolean
    status?: connect.AgentStateDefinition
}
class UserDetails extends Component<Props, State> {
    private popup: TriggeredPopup | null = null
    private logout: TriggeredPopup | null = null

    constructor(props: Props) {
        super(props)
        this.state = {
            showConfirmation: false,
            status: props.currentStatus,
        }
    }
    componentDidUpdate(prevProps: Props) {
        const { nextStatus, currentStatusName } = this.props
        const isNextStatusNew = nextStatus !== prevProps.nextStatus
        const isUserStatusNameNew = currentStatusName !== prevProps.currentStatusName
        const shouldClosePopup = isNextStatusNew || isUserStatusNameNew

        if (shouldClosePopup && this.popup) {
            this.popup.closePopup()
        }
    }
    onChange = (checked: boolean) => {
        return checked ? this.props.setNotAvailable() : this.props.setAvailable()
    }
    setUserState = (status: connect.AgentStateDefinition, shouldOpenPopup: boolean) => {
        const { CALLING_CUSTOMER, BUSY } = connect.AgentAvailStates
        const {
            currentStatusName,
            showNextStatus,
            nextStatus,
            beforeStatus,
            setState,
            setAfterCallStatus,
            setShowNextStatus,
        } = this.props
        const statusesForCall = [CALLING_CUSTOMER, BUSY]

        const isUserNextStatusEqual = nextStatus === status.name && showNextStatus
        if (isUserNextStatusEqual) return

        const isOnCall =
            statusesForCall.includes(currentStatusName as connect.AgentAvailStates) &&
            status.name === beforeStatus
        //If on call then our current status will be "busy" so use beforeStatus as the current status
        const currentStatus = isOnCall ? beforeStatus : currentStatusName

        const isUserStatusEqual = currentStatus === status.name

        const { requiredFieldsFilled, acwConnectionsIDs, requiredFields } =
            this.getContactLogRequestedFields()
        //contactLog form exist

        const isAllContactLogsFilled = requiredFieldsFilled.every((el) => el)
        //without contactLog form
        const isMoreThanOneContactInACW =
            acwConnectionsIDs.length > 1 && !requiredFieldsFilled.length
        const showWarningMessage =
            requiredFields.length > 0 && (isAllContactLogsFilled || isMoreThanOneContactInACW)

        if (showWarningMessage && shouldOpenPopup) {
            this.setState({ showConfirmation: true })
            return
        }
        if (isUserStatusEqual && !showNextStatus) return
        if (isUserStatusEqual && showNextStatus) {
            batch(() => {
                setAfterCallStatus(status)
                setShowNextStatus(false)
            })
        }
        setState(status)
    }
    onCloseLogout = () => {
        this.logout!.closePopup()
    }

    getContactLogRequestedFields = () => {
        const { contacts, forms, acwConnections, afterCall, app } = this.props

        interface FormStateWithContactID extends FormState {
            id: string
        }
        const allContactLogs: FormStateWithContactID[] = []

        contacts.forEach((contact) => {
            const logGroup = contact?.attributes?.[LOG_GROUP_ATTRIBUTE_NAME]
            const formName = getFormName(logGroup, app)
            const formObject = forms[formName]
            if (!formObject?.form) return
            allContactLogs.push({ ...formObject, id: contact.ID })
        })

        const requiredFieldsFilled: boolean[] = []
        const acwConnectionsIDs = acwConnections.map((c) => c.id)
        if (afterCall) {
            acwConnectionsIDs.push(
                contacts.find((c) => c.channel === connect.ChannelType.VOICE)?.ID!,
            )
        }

        let requiredFields: string[] = []
        const acwContacts = contacts.filter((c) => acwConnectionsIDs.includes(c.ID))
        acwContacts.forEach((contact) => {
            const contactLogForm = allContactLogs.find((contactLog) => contactLog.id === contact.ID)
            if (!contactLogForm) return
            const noContactLogOrEmpty = isContactLogEmpty(contactLogForm)
            if (noContactLogOrEmpty) return
            const { acwAttributes } = contact
            if (acwAttributes) {
                requiredFields = getRequiredFields(contactLogForm, acwAttributes)
                requiredFieldsFilled.push(isAllRequiredFieldsFilled(acwAttributes, requiredFields))
            }
        })
        return { requiredFieldsFilled, acwConnectionsIDs, requiredFields }
    }
    onConfirm = () => {
        if (this.state.status) {
            this.setUserState(this.state.status, false)
        }
        this.setState({ showConfirmation: false })
    }
    onCancel = () => {
        this.setState({ showConfirmation: false })
    }
    render() {
        const {
            name,
            hasUser,
            currentStatusName = '',
            states = [],
            ccpURL,
            hideOfflineStatus,
            contact,
            acwConnections,
            blockStatusChange,
        } = this.props

        const { requiredFieldsFilled } = this.getContactLogRequestedFields()
        const isAllRequiredFieldsFilled =
            requiredFieldsFilled.length && requiredFieldsFilled.every((el) => el)
        const isStatusEnable =
            !blockStatusChange && (isAllRequiredFieldsFilled || !requiredFieldsFilled.length)
        return (
            <>
                {this.state.showConfirmation && (
                    <Popup center>
                        <div className="row column center middle">
                            <div className="messageText">
                                <p>
                                    Setting your next status will clear all your contacts currently
                                    in After Contact Work.
                                </p>
                                <p> Are you sure you want to do this?</p>
                            </div>
                            <div className="buttonWrapper">
                                <Button
                                    className="sm-mar-top"
                                    styling="primary"
                                    onClick={this.onConfirm}
                                >
                                    Yes
                                </Button>

                                <Button
                                    className="sm-mar-top"
                                    styling="primary"
                                    onClick={this.onCancel}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </Popup>
                )}

                <div className="user-details">
                    <h1 className="sa-username">{name || 'Loading...'}</h1>

                    <div className="row middle">
                        {hasUser ? (
                            !isStatusEnable ? (
                                <UserStatus disabled acwConnections={acwConnections} />
                            ) : (
                                <div className="sa-userheader-right">
                                    <TriggeredPopup
                                        className="user-status-popup"
                                        closeOnClickOutside
                                        ref={(r) => (this.popup = r)}
                                        noShadow
                                        trigger={
                                            <Button aria-labelledby="status">
                                                <UserStatus />
                                            </Button>
                                        }
                                    >
                                        <UserStatusOptions
                                            logout={this.props.logout}
                                            states={states}
                                            currentStatusName={currentStatusName}
                                            setUserState={(status) => {
                                                this.setState({ status })
                                                this.setUserState(status, true)
                                            }}
                                            changingState={this.props.changingState}
                                            hideOfflineStatus={hideOfflineStatus}
                                            hideLogout={
                                                !!contact && !Boolean(contact?.disconnectTimestamp)
                                            }
                                        />
                                    </TriggeredPopup>
                                </div>
                            )
                        ) : (
                            <Popup center>
                                <div className="sa-logout">
                                    <h2 className="md-mar-bottom">Checking for login...</h2>
                                    <p>
                                        A new tab should have opened allowing you to login. If not,
                                        click the link below and log in there.
                                    </p>
                                    <Button styling="primary">
                                        <a
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            href={ccpURL + '/changeStatus'}
                                        >
                                            LOG IN
                                        </a>
                                    </Button>
                                    <p>
                                        (If you have logged in and this message is still here, try
                                        refreshing the page)
                                    </p>
                                </div>
                            </Popup>
                        )}
                        {currentStatusName === 'End of Shift' ? (
                            <Popup center>
                                <div className="sa-logout">
                                    <h2 className="md-mar-bottom">End of Shift</h2>
                                    <h4>Hi {name}, your status is currently 'end of shift'</h4>
                                    <p>
                                        If you are done for the day, click the logout link below
                                        which opens in a new tab, then click 'logout' at the bottom
                                        of the new page.
                                    </p>
                                    <p>
                                        Click 'start shift' to start work today, your status will be
                                        set to 'available'.
                                    </p>
                                    <Button styling="primary" onClick={this.props.setAvailable}>
                                        Start Shift
                                    </Button>
                                    <span>&nbsp;</span>
                                    <Button styling="secondary">
                                        <a
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            href={ccpURL + '/changeStatus'}
                                        >
                                            LOGOUT
                                        </a>
                                    </Button>
                                    <p>
                                        (If the start shift button doesn't work, you may need to
                                        refresh this tab)
                                    </p>
                                </div>
                            </Popup>
                        ) : null}
                        <PhoneBook />
                        <Announcements />
                        <HandsUp />
                    </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state: RootState) {
    const { user, app, global, chat, tasks, contacts, contact, auth } = state
    const { status, name, states, afterCallStatus, showNextStatus, beforeCallStatus } = user ?? {}
    const acwChats = chat.connections.filter((c) => c.acw)
    const acwTasks = tasks.connections.filter((c) => c.acw)
    return {
        hasUser: !!status,
        currentStatusName: status?.name,
        currentStatus: status,
        name: name,
        afterCall: status?.name === connect.AgentAvailStates.AFTER_CALL_WORK,
        states: states,
        nextStatus: afterCallStatus?.name,
        beforeStatus: beforeCallStatus?.name,
        showNextStatus: showNextStatus,
        features: app.features,
        ccpURL: app.instance?.ccpURL ?? '',
        hideOfflineStatus: app.appConfig.hideOfflineStatus,
        changingState: global.changingState,
        acwConnections: [...acwChats, ...acwTasks],
        contacts: contacts,
        forms: app.forms,
        contact: contact,
        isAgent: auth.roles?.includes('AGENT'),
        app,
    }
}

export default reduxConnect(mapStateToProps, {
    setAvailable,
    setNotAvailable,
    setState,
    logout,
    setShowNextStatus,
    setAfterCallStatus,
})(UserDetails)

import VoiceInboundIcon from 'images/icon-voice-inbound-white.svg'
import VoiceOutboundIcon from 'images/icon-voice-outbound-white.svg'
import type { InitiationMethod } from 'store/callV2/callV2.state'

const getInitiationMethodIcon = (initiationMethod: InitiationMethod) => {
    let initiationMethodIcon: string | undefined

    switch (initiationMethod) {
        case 'inbound':
            initiationMethodIcon = VoiceInboundIcon
            break
        case 'outbound':
            initiationMethodIcon = VoiceOutboundIcon
            break
        case 'transfer':
            initiationMethodIcon = VoiceOutboundIcon
            break
    }

    return initiationMethodIcon
}

const getInitiationMethodText = (initiationMethod: InitiationMethod) => {
    let initiationMethodText: string | undefined

    switch (initiationMethod) {
        case 'inbound':
            initiationMethodText = 'Inbound Call'
            break
        case 'outbound':
            initiationMethodText = 'Outbound Call'
            break
        case 'transfer':
            initiationMethodText = 'Transfer Call'
            break
        case 'queue_transfer':
            initiationMethodText = 'Queue Transfer Call'
            break
        case 'callback':
            initiationMethodText = 'Callback Call'
            break
        case 'api':
            initiationMethodText = 'API Call'
            break
        case 'disconnect':
            initiationMethodText = 'Disconnect Call'
            break
        case 'monitoring':
            initiationMethodText = 'Monitoring Call'
            break
        case 'external_outbound':
            initiationMethodText = 'External Outbound Call'
            break
        case 'unknown':
            initiationMethodText = 'Unknown Call'
            break
    }

    return initiationMethodText
}

export { getInitiationMethodIcon, getInitiationMethodText }

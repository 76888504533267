import { ccpLogout } from 'store/app/app.reducer'
import * as UserReducer from 'store/user/user.reducer'

import { createSlice } from '@reduxjs/toolkit'

import AuthState from './auth.state'

const initialState: AuthState = {
    authenticated: false,
    logout: false,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(UserReducer.userLoaded, (state) => {
            return { ...state, logout: false }
        }),
            addCase(UserReducer.userAuthenticated, (_, action) => {
                return { authenticated: true, ...action.payload }
            }),
            addCase(UserReducer.userAuthError, (state, action) => {
                return {
                    ...state,
                    authenticating: false,
                    authenticated: false,
                    authError: action.payload.error,
                }
            }),
            addCase(UserReducer.authenticatingUser, (state, action) => {
                return { ...state, authenticating: true }
            }),
            addCase(UserReducer.validatingToken, (state) => {
                return {
                    roles: state.roles,
                    features: state.features,
                    token: state.token,
                    refreshToken: state.refreshToken,
                    validatingToken: true,
                    authenticated: state.authenticated,
                }
            }),
            addCase(UserReducer.requestingToken, () => {
                return { requestingToken: true }
            }),
            addCase(UserReducer.mfaRequired, (_, action) => {
                return { mfa: action.payload }
            }),
            addCase(UserReducer.tokenRequested, () => {
                return { tokenRequested: true }
            }),
            addCase(UserReducer.tokenError, () => {
                return { tokenError: true }
            }),
            addCase(UserReducer.login, (state) => {
                return { ...state, logout: false, authError: '' }
            }),
            addCase(UserReducer.logout, () => {
                return { authenticated: false, logout: true, token: undefined }
            }),
            addCase(ccpLogout, () => {
                return { authenticated: false, logout: true, token: undefined }
            }),
            addCase(UserReducer.newPasswordRequired, () => {
                return { newPasswordRequired: true }
            }),
            addCase(UserReducer.forgotPassword, () => {
                return { userForgotPassword: true }
            }),
            addCase(UserReducer.rememberedPassword, () => {
                return { userForgotPassword: false }
            }),
            addCase(UserReducer.forgotPasswordGetCode, () => {
                return { userForgotPassword: true, userForgotPasswordGetCode: true }
            }),
            addCase(UserReducer.forgotPasswordConfirm, () => {
                return { ...initialState }
            })
    },
})

export default authSlice.reducer

import './sort-by.scss'

import ArrowDownIcon from 'images/arrow-down.svg'
import ArrowUpIcon from 'images/arrow-up.svg'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sortInteractions } from 'store/contactHistory/contactHistory.actions'
import { selectContactHistoryInstance } from 'store/contactHistory/contactHistory.selectors'
import { Sort } from 'store/contactHistory/contactHistory.state'
import RootState from 'store/state'

import { Dropdown } from '@missionlabs/smartagent-app-components'

import { contactHasNoHistory } from '../../utils'
import options from './options'

const SortBy: React.FC<{}> = () => {
    const dispatch = useDispatch()

    const { sort, interactions, loaded } =
        useSelector((state: RootState) => selectContactHistoryInstance(state)) ?? {}

    const [isOpen, setIsOpen] = useState<boolean>(false)

    const currentIcon = isOpen ? ArrowUpIcon : ArrowDownIcon
    const currentIconAlt = isOpen ? 'up arrow' : 'down arrow'
    const currentOptionLabel =
        options.find((option) => option.data === sort)?.label ?? 'Select an option'

    const noContactHistory = contactHasNoHistory(interactions, loaded)

    return !noContactHistory ? (
        <div className="contact-history-sort-by-container">
            <p className="contact-history-sort-by-text">Sort by</p>
            <Dropdown
                className="contact-history-sort-by-dropdown"
                iconRight={<img alt={currentIconAlt} src={currentIcon} />}
                options={options}
                value={currentOptionLabel}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                onChange={({ data: sort }) => dispatch(sortInteractions(sort as Sort))}
            />
        </div>
    ) : null
}

export default SortBy

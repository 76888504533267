import RootState from 'store/state'
import { IStrObj } from 'utils'

import { ThunkDispatch } from '@reduxjs/toolkit'

import * as AnnouncementAPI from '../../services/api/api.announcement'
import * as AnnouncementActions from './announcement.reducer'
import { Announcement, UserAnnouncement } from './announcement.state'

export function getMyUserAnnouncements(filters?: IStrObj) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, AnnouncementActions.AnnouncementAction>,
        getState: () => RootState,
    ) => {
        try {
            const companyID = getState().app.ID
            const instanceID = getState().app.instance?.ID!
            const token = getState().auth.token!

            const userAnnouncements = await AnnouncementAPI.getMyUserAnnouncements(
                companyID,
                instanceID,
                token,
                filters,
            )

            dispatch(AnnouncementActions.getUserAnnouncements(userAnnouncements))
        } catch (error) {
            console.log('error getting user announcements')
        }
    }
}

export function getMyUnreadUserAnnouncements() {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, AnnouncementActions.AnnouncementAction>,
        getState: () => RootState,
    ) => {
        try {
            const companyID = getState().app.ID
            const instanceID = getState().app.instance?.ID!
            const token = getState().auth.token!

            const unreadUserAnnouncements = await AnnouncementAPI.getMyUserAnnouncements(
                companyID,
                instanceID,
                token,
                { read: '0' },
            )

            dispatch(AnnouncementActions.getUnreadUserAnnouncements(unreadUserAnnouncements))
        } catch (error) {
            console.log('error getting user announcements')
        }
    }
}

export function getAnnouncements() {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, AnnouncementActions.AnnouncementAction>,
        getState: () => RootState,
    ) => {
        try {
            dispatch(AnnouncementActions.getAnnouncementsLoading())

            const companyID = getState().app.ID
            const instanceID = getState().app.instance?.ID!
            const token = getState().auth.token!

            const announcements = await AnnouncementAPI.getAnnouncements(
                companyID,
                instanceID,
                token,
            )

            dispatch(AnnouncementActions.getAnnouncements(announcements.data))
        } catch (error) {
            console.log('error getting announcements')
        }
    }
}

export function updateAnnouncement(announcement: Partial<Announcement>) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, AnnouncementActions.AnnouncementAction>,
        getState: () => RootState,
    ) => {
        try {
            const companyID = getState().app.ID
            const instanceID = getState().app.instance?.ID!
            const token = getState().auth.token!

            const res = await AnnouncementAPI.putAnnouncement(
                companyID,
                instanceID,
                announcement,
                token,
            )

            dispatch(AnnouncementActions.updateAnnouncement(res))
            dispatch(AnnouncementActions.updateAnnouncementLoading(false))
        } catch (err) {
            console.log('error updating announcement')
        }
    }
}

export function deleteAnnouncement(announcementID: string) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, AnnouncementActions.AnnouncementAction>,
        getState: () => RootState,
    ) => {
        try {
            const companyID = getState().app.ID
            const instanceID = getState().app.instance?.ID!
            const token = getState().auth.token!

            dispatch(AnnouncementActions.deleteAnnouncement(announcementID))

            await AnnouncementAPI.deleteAnnouncement(companyID, instanceID, announcementID, token)
        } catch (err) {
            console.log('error deleting announcement')
        }
    }
}
export function updateUserAnnouncement(ID: string, userAnnouncement: Partial<UserAnnouncement>) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, AnnouncementActions.AnnouncementAction>,
        getState: () => RootState,
    ) => {
        try {
            const companyID = getState().app.ID
            const instanceID = getState().app.instance?.ID!
            const token = getState().auth.token!

            // The UserAnnouncement type is Partial as per it's usage, but all it's fields are mandatory
            dispatch(
                AnnouncementActions.updateUserAnnouncement({
                    ID,
                    ...userAnnouncement,
                } as UserAnnouncement),
            )

            await AnnouncementAPI.putUserAnnouncement(
                companyID,
                instanceID,
                ID,
                userAnnouncement,
                token,
            )

            dispatch(AnnouncementActions.updateUserAnnouncementLoading(false))
        } catch (err) {
            console.log('error updating announcement')
        }
    }
}

export function createAnnouncement(announcement: Partial<Announcement>) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, AnnouncementActions.AnnouncementAction>,
        getState: () => RootState,
    ) => {
        try {
            const companyID = getState().app.ID
            const instanceID = getState().app.instance?.ID!
            const token = getState().auth.token!
            const announcements = getState().announcements.announcements!

            const res = await AnnouncementAPI.postAnnouncement(
                companyID,
                instanceID,
                {
                    ...announcement,
                    ID: undefined,
                },
                token,
            )

            dispatch(AnnouncementActions.getAnnouncements([res, ...(announcements ?? [])]))
        } catch (err) {
            console.log('error creating announcement')
        }
    }
}

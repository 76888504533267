import {
    IQAForm_Export,
    IQAForm_ExportField,
    IQAForm_State,
    IQAForm_Template,
    IQAForm_TemplateField,
    IQAForm_TemplateField_Option,
    IQAForm_TemplateField_Option_Next,
    IQAForm_TemplateSection,
    QAStatus,
} from './FormBuilderQA.types'

export const createBlankQAForm = (): IQAForm_State => ({
    fields: {},
    comments: {},
    isFormAtEnd: false,
    editingCompleted: false,
    updatedFields: [],
})

interface IAgent {
    name: string
    ID: string
}

const weightCalculation = (fromState: IQAForm_TemplateField_Option<any>[]) => {
    let result = 0
    for (let i = 0; i < fromState.length; i++) {
        result += fromState[i].weight
    }
    return result
}

export const exportForm = (
    agent: IAgent,
    template: IQAForm_Template,
    state: IQAForm_State,
    visibleQuestions: Record<string, IQAForm_TemplateField>,
    completed: boolean = false,
    immediateFailThresh?: number,
    failTypeHierarchy?: string[],
): IQAForm_Export => {
    const fieldsProcessed = template.sections
        .reduce<IQAForm_TemplateField[]>((acc, section) => [...acc, ...section.fields], [])
        .map<IQAForm_ExportField | undefined>(({ id, score }) => {
            const fromState = state.fields[id]
            if (!fromState) return undefined
            const [firstExistingAnswer] = fromState

            const editedField = !!state.updatedFields.includes(id)

            let failTypeResult = null

            for (const failTypeCandidate of failTypeHierarchy || []) {
                if (fromState.some((el) => el.failType === failTypeCandidate)) {
                    failTypeResult = failTypeCandidate
                    break
                }
            }

            //If edited need to update history
            if (editedField) {
                return {
                    id,
                    value: fromState.map((el) => el.data),
                    score: weightCalculation(fromState) * score,
                    comments: state.comments[id],
                    immediateFail: fromState.map((el) => el.immediateFail).some((el) => el),
                    failType: failTypeResult || firstExistingAnswer?.failType,
                    skipped: firstExistingAnswer?.skipped,
                    updated: {
                        timestamp: Date.now(),
                        agentID: agent.ID,
                        name: agent.name,
                    },
                    history: [
                        firstExistingAnswer?.skipped
                            ? {
                                  value: 'SKIPPED',
                                  score: 0,
                                  comments: state.comments[id],
                                  updated: firstExistingAnswer?.updated,
                              }
                            : {
                                  value: firstExistingAnswer?.data,
                                  score: weightCalculation(fromState) * score,
                                  comments: state.comments[id],
                                  updated: firstExistingAnswer?.updated,
                              },
                        ...(firstExistingAnswer?.history || []),
                    ],
                }
            } else {
                return {
                    id,
                    score: weightCalculation(fromState) * score,
                    value: fromState.map((el) => el.data),
                    comments: state.comments[id],
                    immediateFail: fromState.map((el) => el.immediateFail).some((el) => el),
                    failType: failTypeResult || firstExistingAnswer?.failType,
                    skipped: firstExistingAnswer?.skipped,
                    history: firstExistingAnswer?.history
                        ? firstExistingAnswer?.history
                        : [
                              {
                                  value: fromState.map((el) => el.data),
                                  score: weightCalculation(fromState) * score,
                                  comments: state.comments[id],
                                  updated: firstExistingAnswer?.updated,
                              },
                          ],
                    updated: firstExistingAnswer?.updated,
                } as IQAForm_ExportField
            }
        })
        .filter((field) => !!field)

    // const sections = template.sections
    //     .map<IQAForm_ExportSection>(({ id, passScore, fields, name }) => {
    //         //@ts-ignore
    //         const score = fields.reduce((acc, f) => acc += fieldsProcessed.find(({ id }) => id === f.id)?.score, 0);
    //         const maxScore = fields.reduce((acc, f) => acc += getMaxFieldScore(f.options,f.score), 0);
    //         const scorePercent = Math.floor(maxScore ? ( Math.max(score, 0) / maxScore ) * 100 : 0);
    //         return {
    //             id,
    //             score,
    //             passScore,
    //             name,
    //             maxScore,
    //             scorePercent
    //         };
    //     })

    if (!completed) {
        return {
            status: QAStatus.IN_PROGRESS,
            results: {
                fields: fieldsProcessed as IQAForm_ExportField[],
                summary: state.summary,
            },
        }
    }

    const visibleProcessedFields = fieldsProcessed.filter((f) => {
        if (f?.id && visibleQuestions[f?.id]) {
            return f
        }
    })

    //if any score is less than 0 then the whole qa form fails
    const score = visibleProcessedFields.reduce((acc, f) => {
        if (f!.immediateFail) return acc
        if (isNaN(f!.score)) return acc
        return (acc += f!.score)
    }, 0)

    const immediateFail = fieldsProcessed.some((field) => field?.immediateFail)

    const failTypesTriggered = fieldsProcessed
        .filter((field) => field?.failType?.trim() !== '')
        .map((field) => field?.failType)

    const commonFailType = failTypeHierarchy
        ? failTypeHierarchy.find((failType) => failTypesTriggered.includes(failType))
        : ''

    const maxScore = getTemplateMaxScore(template, visibleQuestions)

    let percentage = Math.round(maxScore ? (Math.max(score, 0) / maxScore) * 100 : 0)

    if (immediateFailThresh && immediateFail && percentage > immediateFailThresh) {
        percentage = immediateFailThresh
    } else if (!immediateFailThresh && immediateFail) {
        percentage = 0
    }

    return {
        scorePercentage: percentage,
        status: percentage >= template.passPercentage ? QAStatus.PASS : QAStatus.FAIL,
        failType: commonFailType,
        results: {
            fields: fieldsProcessed as IQAForm_ExportField[],
            summary: state.summary,
        },
    }
}

export const getTemplateMaxScore = (
    template: IQAForm_Template,
    visibleQuestions: Record<string, IQAForm_TemplateField>,
) =>
    template.sections.reduce(
        (acc, s) =>
            (acc += s.fields.reduce(
                (acc, f) =>
                    (acc += visibleQuestions[f.id]
                        ? getMaxFieldScore(f.options, f.score ?? 0, f.questionType)
                        : 0),
                0,
            )),
        0,
    )

export const getSectionMaxScore = (section: IQAForm_TemplateSection) =>
    section.fields.reduce(
        (acc, f) => (acc += getMaxFieldScore(f.options, f.score ?? 0, f.questionType)),
        0,
    )

//Get the highest weighting option to work out max score
export const getMaxFieldScore = (
    options: IQAForm_TemplateField['options'],
    score: number,
    questionType: string,
) => {
    if (questionType === 'checkbox') {
        const maxCheckboxWeight = options
            .filter((o) => o.weight > 0)
            .reduce((previousValue, currentValue) => previousValue + currentValue.weight, 0)

        return maxCheckboxWeight * score
    }

    return Math.max(...options.map((o) => o.weight)) * score
}

export const getSectionScore = (
    section: IQAForm_TemplateSection,
    fields: IQAForm_ExportField[],
) => {
    return section.fields.reduce((score, field) => {
        score += fields.find((f) => f.id === field.id)?.score ?? 0
        return score
    }, 0)
}

export const importForm = (
    template: IQAForm_Template,
    imp: IQAForm_Export['results'],
): IQAForm_State => {
    const allTemplateFields = template.sections.reduce<IQAForm_TemplateField[]>(
        (acc, section) => [...acc, ...section.fields],
        [],
    )

    const fields = imp.fields.reduce<IQAForm_State['fields']>((acc, f) => {
        const [formData] = f.value || []
        const options = allTemplateFields
            .find((tf) => tf.id === f.id)!
            .options // find original question
            .filter((o) => {
                if (!o.data && !o.label) return true
                if (f.skipped) return true
                if (typeof f.value !== 'object') {
                    return f.value === o.data
                }
                if (!o.data && o.label) {
                    // for backward compatibility(checkbox option without 'data' field)
                    return f.value.find((v: string) => v === o.label)
                }
                return f.value.find((v: string) => v === o.data)
            })
            .map((o) => ({
                ...o,
                data: o.data ? o.data : formData,
                skipped: f.skipped,
                history: f.history,
                updated: f.updated,
            }))

        acc[f.id] = options
        return acc
    }, {})

    const comments = imp.fields.reduce<IQAForm_State['comments']>((acc, f) => {
        if (f.comments) {
            acc[f.id] = f.comments
        }

        return acc
    }, {})

    return {
        editingCompleted: true,
        updatedFields: [],
        fields,
        comments,
        summary: imp.summary,
    }
}

export function* formWalker(
    question: IQAForm_TemplateField,
    answeredFields: IQAForm_State['fields'],
    optionMap: Record<string, IQAForm_TemplateField_Option_Next>,
) {
    let qNum = 1

    do {
        yield {
            qNum,
            question,
        }

        qNum += 1

        const answer = answeredFields[question.id]

        if (!answer) {
            break
        }
        const [firstExistingAnswer] = answer

        const next = firstExistingAnswer?.skipped
            ? optionMap[`skip_${question.id}`]
            : optionMap[firstExistingAnswer?.id]

        if (!next) {
            break
        }

        if (next.type === 'section') {
            question = next.fields[0]
        } else {
            question = next
        }
    } while (question)

    return {
        qNum,
        question: undefined,
    }
}

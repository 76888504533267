import type { MiddlewareAPI } from '@reduxjs/toolkit'
import Logger from 'logger'
import { batch } from 'react-redux'
import * as callActions from 'store/call/call.actions'
import * as chatActions from 'store/chat/chat.actions'
import * as globalActions from 'store/global/global.actions'
import RootState from 'store/state'
import * as userActions from 'store/user/user.actions'

import { setWebsocketDisconnectedTime } from 'store/errors/errorsSlice'
import * as ccpUtils from '../ccp.utils'

export type MiddlewareStore = MiddlewareAPI<any, RootState>

const postMessageToSw = (type: string, data: any = {}) => {
    navigator.serviceWorker.ready.then((registration) => {
        registration.active?.postMessage({
            type,
            data,
        })
    })
}

const listenToAgent = (store: MiddlewareStore) => {
    let sharedAgent: connect.Agent
    let disallowedStatesTimeout: NodeJS.Timeout

    connect.agent((agent) => {
        sharedAgent = agent

        //Attach agent to window for devtools purposes
        ;(window as any).agent = sharedAgent

        store.dispatch(
            userActions.userLoaded({
                timeRange: 'midnight',
                agentID: store.getState().auth.agentID!,
                name: agent.getName(),
                status: agent.getState() as connect.AgentStateDefinition,
                states: agent.getAgentStates(),
                username: agent.getConfiguration().username,
                softphoneEnabled: agent.getConfiguration().softphoneEnabled,
                queues: agent.getConfiguration().routingProfile.queues.filter((q) => !!q.name),
                routingProfile: {
                    ID: agent.getRoutingProfile().routingProfileId,
                    name: agent.getRoutingProfile().name,
                },
                defaultOutboundQueueID: agent
                    .getRoutingProfile()
                    .defaultOutboundQueue.queueARN.split('/')
                    .slice(-1)[0],
                inStateSince: new Date(Date.now() - agent.getStateDuration()),
                hierarchyStructure: store.getState().auth.hierarchyStructure,
                softphoneAutoAccept: agent.getConfiguration().softphoneAutoAccept,
            }),
        )

        const auth = store.getState().auth
        const token = auth.refreshToken
        const { identityManagement } = store.getState().app
        if (!identityManagement || identityManagement === 'connect') {
            if (!token) {
                store.dispatch(userActions.setNotAvailable())
                store.dispatch(userActions.requestToken())
                window.postMessage({ type: 'READ_TOKEN' }, '*')
            } else if (!auth.authenticated) {
                store.dispatch(userActions.refreshToken())
            }
        }

        agent.onError((agent) => {
            console.log('Agent transferred into an error state')
            const state = agent.getState()
            console.log('Agent in state:', state)
            const { app } = store.getState()
            if (state.name === connect.AgentErrorStates.MISSED_CALL_AGENT) return
            // Check for an agent state explicitly forbidden in the app config
            if ((app.appConfig.disallowedStates || []).indexOf(state.name) > -1) {
                ccpUtils.setUserToPreviousState(store)
            } else {
                store.dispatch(userActions.userError())
            }
        })

        agent.onSoftphoneError((error) => {
            const agentId = store.getState().auth.agentID
            const contact = store.getState().contact
            const channel = contact?.channel

            Logger.warning('AWS_CONNECT_SOFTPHONE_ERROR', {
                store: store.getState(),
                agentId,
                channel,
                errorType: error?.errorType,
            })

            Logger.error('SOFTPHONE ERROR', error)

            if (contact) {
                store.dispatch(globalActions.addSoftphoneError(error.errorType))
                store.dispatch(
                    globalActions.addError(
                        `A potential headset issue has been detected. If this error persists contact your IT Helpdesk (Error code '${error.errorType}')`,
                    ),
                )
            }
        })

        agent.onStateChange(() => {
            const missedChat = store
                .getState()
                .chat.connections.find(
                    (c) =>
                        c.status === connect.ContactStateType.MISSED ||
                        c.status === connect.ContactStateType.ERROR,
                )

            const { appConfig } = store.getState().app
            if (appConfig.disallowedStates) {
                clearTimeout(disallowedStatesTimeout)
                disallowedStatesTimeout = setTimeout(() => {
                    const user = store.getState().user
                    if (!user) return

                    const { softphoneError } = store.getState().global

                    if (
                        appConfig?.disallowedStates?.includes(user.status.name) &&
                        !softphoneError
                    ) {
                        ccpUtils.setUserToPreviousState(store)
                    }
                }, 20_000)
            }

            if (missedChat) {
                store.dispatch(chatActions.removeChatConnection(missedChat.id))
            }
        })

        agent.onRefresh((agent) => {
            //TODO... if no agent, can we just assume it's a refresh error, and just do nothing.
            const {
                user,
                call,
                global: { softphoneError },
                app: { appConfig },
            } = store.getState()
            if (!user || !user.status) return
            //Clear contact if agent goes into Available or goes out of AfterCallWork
            if (
                ccpUtils.hasAgentChangedState(agent, user, 'Available') ||
                ccpUtils.hasAgentChangedState(agent, user, undefined, 'AfterCallWork')
            ) {
                if (call) {
                    store.dispatch(callActions.callEnded())
                }
            }

            if (ccpUtils.hasAgentChangedState(agent, user, 'Available', 'AfterCallWork')) {
                //After call work has ended and we've changed to available.
                store.dispatch(userActions.afterCallWorkEnd())
                store.dispatch(userActions.setAvailable())
            }
            if (ccpUtils.hasAgentChangedState(agent, user, 'AfterCallWork')) {
                //After call work is just starting, go into after call work.
                if (call && call.incoming) {
                    return store.dispatch(userActions.setAvailable())
                } else {
                    store.dispatch(userActions.afterCallWork())
                    //Navigate to dialler on after call work
                    store.dispatch(globalActions.setRedirect('/'))
                }
            }
            if (ccpUtils.hasAgentChangedState(agent, user, 'FailedConnectCustomer')) {
                console.log('Call failed to connect, this could be a headset issue')
                //TODO - despatch something to prompt them to replug the headset or at least refresh the page.
                store.dispatch(userActions.callIssue())
            }

            const agentQueues = user.queues || []
            //rebuild queue list
            const queues = agent.getConfiguration().routingProfile.queues.filter((q) => !!q.name)
            //attach existing data
            const userQueues = queues.map((queue) => {
                const currentQueue = agentQueues.find((q) => q.queueId === queue.queueId)
                if (!currentQueue) return queue
                return {
                    ...queue,
                    stats: currentQueue.stats,
                    agentStats: currentQueue.agentStats,
                }
            })

            let afterCallStatus = user.afterCallStatus
            let beforeCallStatus = user.beforeCallStatus
            const hasAgentChangedState = ccpUtils.hasAgentChangedState(agent, user)
            const status = agent.getAgentStates().find((s) => s.name === user.status.name)
            const isShowNextStatus = user.showNextStatus
            //Previous status needs to be a status an agent can change to
            if (hasAgentChangedState && status) {
                beforeCallStatus = status
            }
            if (hasAgentChangedState && status && !isShowNextStatus) {
                afterCallStatus = status
            }

            store.dispatch(
                userActions.userLoaded({
                    timeRange: user.timeRange || 'midnight',
                    agentID: store.getState().auth.agentID!,
                    name: agent.getName(),
                    status: agent.getState() as connect.AgentStateDefinition,
                    states: agent.getAgentStates(),
                    username: agent.getConfiguration().username,
                    softphoneEnabled: agent.getConfiguration().softphoneEnabled,
                    routingProfile: {
                        ID: agent.getRoutingProfile().routingProfileId,
                        name: agent.getRoutingProfile().name,
                    },
                    queues: userQueues,
                    afterCallStatus,
                    beforeCallStatus,
                    inStateSince: new Date(Date.now() - agent.getStateDuration()),
                    hierarchyStructure: store.getState().auth.hierarchyStructure,
                }),
            )
        })

        // NOTE - The typing of the callback function for this event handler is incorrect. It is currently
        // typed to provide the Connect Agent API object instance, but this is not the case in reality.
        // Expected callback function arguments - (agent: connect.Agent) => void
        // Actual callback function arguments are being provided by the websocket manager found in the amazon-connect-streams repository
        // URL to the websocket manager - https://github.com/amazon-connect/amazon-connect-streams/blob/master/src/lib/amazon-connect-websocket-manager.js
        // Further details can be found in the comments of SMAR-8992.
        agent.onWebSocketConnectionLost((error: any) => {
            const agentId = store.getState().auth.agentID
            const contact = store.getState().contact
            const channel = contact?.channel

            const errorCodeMessage = error ? `(Error code ${error.code})` : ''

            Logger.warning('AWS_CONNECT_WEBSOCKET_CONNECTION_LOST', {
                store: store.getState(),
                agentId,
                channel,
            })

            store.dispatch(setWebsocketDisconnectedTime({ websocketDisconnectedTime: Date.now() }))

            if (contact) {
                store.dispatch(
                    globalActions.addError(
                        `Your internet connection is unstable. If this error persists contact your IT Helpdesk ${errorCodeMessage}`,
                    ),
                )
            }
        })

        agent.onWebSocketConnectionGained(() => {
            const { websocketDisconnectedTime } = store.getState().errors.websocketError
            const currentTime = Date.now()
            // agentId and channel below will be undefined in the case of the initial app loading/reloading
            // as websocket connection is established before the data gets into store
            const agentId = store.getState().auth.agentID
            const channel = store.getState().contact?.channel

            Logger.info('AWS_CONNECT_WEBSOCKET_CONNECTION_GAINED', {
                agentId,
                channel,
            })

            if (websocketDisconnectedTime) {
                Logger.info('AWS_CONNECT_WEBSOCKET_CONNECTION_LOST_DURATION', {
                    websocketNoConnectionDurationMs: currentTime - websocketDisconnectedTime,
                    agentId,
                    channel,
                })

                store.dispatch(
                    setWebsocketDisconnectedTime({
                        websocketDisconnectedTime: undefined,
                    }),
                )
            }
        })

        agent.onEnqueuedNextState((agent) => {
            const nextStatus = agent.getNextState()
            const statuses = agent.getAgentStates()
            const nextStateWithARN = statuses.find((s) => s.name === nextStatus.name)

            batch(() => {
                store.dispatch(userActions.setAfterCallStatus(nextStateWithARN!))
                store.dispatch(userActions.setShowNextStatus(true))
            })
        })
    })

    return () => sharedAgent
}

export default listenToAgent

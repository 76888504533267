var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { jsx } from "react/jsx-runtime";
import classNames from "classnames";
import React from "react";
import { g as getObjectSubset } from "./index-Cjs3E3kR.mjs";
import { a as FormProvider } from "./context-jqLAdImf.mjs";
class Form extends React.PureComponent {
  constructor() {
    super(...arguments);
    __publicField(this, "inputs", []);
    __publicField(this, "removeFromForm", (input) => {
      const index = this.inputs.indexOf(input);
      if (index > -1) {
        this.inputs.splice(index, 1);
      }
    });
    __publicField(this, "onReset", (ev) => {
      var _a, _b;
      ev.preventDefault();
      (_b = (_a = this.props).onReset) == null ? void 0 : _b.call(_a, ev);
    });
    __publicField(this, "onSubmit", (ev) => {
      var _a, _b;
      ev.preventDefault();
      const invalid = this.inputs.filter((input) => !input.isValid());
      if (invalid.length) {
        this.inputs.forEach((input) => {
          input.onBlur();
        });
        (_b = (_a = this.props).onSubmitError) == null ? void 0 : _b.call(_a, invalid);
        return;
      }
      this.inputs.forEach((input) => input.reset());
      this.props.onSubmit(ev);
    });
    __publicField(this, "attachToForm", (input) => {
      if (this.inputs.indexOf(input) === -1) {
        this.inputs.push(input);
      }
    });
  }
  componentWillUnmount() {
    this.inputs = [];
  }
  isValid() {
    return !this.inputs.find((input) => !input.isValid());
  }
  focusOnInvalid() {
    const input = this.inputs.find((input2) => !input2.isValid());
    if (input)
      input.focus();
  }
  render() {
    const { className } = this.props;
    const ariaAttributes = getObjectSubset(this.props, "aria");
    const formClasses = classNames({
      "kui-form": true,
      "sa-form": true,
      [className || ""]: !!className
    });
    return /* @__PURE__ */ jsx(
      FormProvider,
      {
        value: { attachToForm: this.attachToForm, removeFromForm: this.removeFromForm },
        children: /* @__PURE__ */ jsx(
          "form",
          {
            ...ariaAttributes,
            onReset: this.onReset,
            onSubmit: this.onSubmit,
            className: formClasses,
            noValidate: true,
            autoComplete: this.props.autoComplete ? "on" : "off",
            children: this.props.children
          }
        )
      }
    );
  }
}
__publicField(Form, "defaultProps", {
  autoComplete: true
});
export {
  Form as default
};

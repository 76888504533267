import './connects.scss'

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeCall, transfer } from 'store/call/call.actions'
import { transferChat } from 'store/chat/chat.actions'
import { getQuickConnects, hideQuickConnects } from 'store/user/user.actions'
import ConnectsView from 'views/QuickDialler/Connects/ConnectsView'

import type { Channel } from '@missionlabs/smartagent-lib-shared/build/form-templates/form-template.schema'

import type RootState from 'store/state'

type Props = {
    channel: Channel
    onSelect?: (connect: connect.Endpoint) => void
}

const Connects = ({ channel, onSelect }: Props) => {
    const dispatch = useDispatch()

    const call = useSelector((state: RootState) => state.call)
    const contactId = useSelector((state: RootState) => state.contact?.ID)
    const chat = useSelector((state: RootState) =>
        state.chat.connections.find(({ id }) => contactId === id),
    )
    const connects = useSelector((state: RootState) => state.user?.connects)

    const [filter, setFilter] = useState<string>('')

    const inCall = !!call
    const canAddConnection = inCall ? call?.connections.length === 1 : false

    const displayedConnects =
        (inCall || !!chat ? connects : connects?.filter(({ type }) => type === 'phone_number')) ??
        []

    useEffect(() => {
        dispatch(getQuickConnects())
    }, [])

    const sortConnects = (connects: connect.Endpoint[]) => {
        const mutableConnects = [...connects]
        return mutableConnects && mutableConnects.sort((a, b) => (a.name > b.name ? 1 : -1))
    }

    const filterConnects = () => {
        if (!displayedConnects) return []

        if (!filter) return displayedConnects

        return sortConnects(
            displayedConnects.filter(
                ({ name, phoneNumber }) =>
                    name.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                    (phoneNumber && phoneNumber.indexOf(filter) > -1),
            ),
        )
    }

    const baseOnSelect = (connect: connect.Endpoint) => {
        if (channel === 'CHAT') {
            dispatch(transferChat({ id: chat!.id, endpoint: connect }))
        } else {
            if (inCall) {
                console.log('adding connection')
                if (canAddConnection) {
                    dispatch(transfer(connect.phoneNumber, connect.name, connect))
                } else {
                    console.log('Not in a state where we can add connection')
                }
                return
            }
            dispatch(makeCall(connect.phoneNumber, connect.name, connect))
        }
    }

    const onFilter = (filter: string) => {
        setFilter(filter)
    }

    const onClose = () => {
        dispatch(hideQuickConnects())
    }

    return (
        <ConnectsView
            connects={filter ? filterConnects() : sortConnects(displayedConnects)}
            onSelect={(connect: connect.Endpoint) =>
                onSelect ? onSelect(connect) : baseOnSelect(connect)
            }
            onFilter={onFilter}
            filter={filter}
            channel={channel}
            onClose={onClose}
        />
    )
}

export default Connects

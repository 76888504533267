import './external-directory.scss'

import { DirectorySearchInput, Loader } from 'components'
import DirectoryOwnerPopup from 'components/Popups/DirectoryPopups/DirectoryOwnerPopup'
import DirectoryPriorityPopup from 'components/Popups/DirectoryPopups/DirectoryPriorityPopup'
import useDirectory from 'hooks/redux/useDirectory'
import useDirectoryContacts from 'hooks/useDirectoryContacts'
import useHasFeature, { AppFeatures } from 'hooks/useHasFeature'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setSearchParam } from 'store/directory/searchParams/actions'

import { ViewEvent } from '@missionlabs/analytics-react'

import DirectoryList from './DirectoryList'

interface Props {}

const Directory: React.FC<Props> = () => {
    // this state is used to mitigate the blink of previous contacts after switching the tab
    const [isFirstRender, setIsFirstRender] = useState(true)

    const { searchParams } = useDirectory()

    const [contacts, contactsLoading] = useDirectoryContacts(true)

    const dispatch = useDispatch()

    useEffect(
        () => () => {
            const clearSearch = () => dispatch(setSearchParam({ search: '' }))

            clearSearch()
        },
        [],
    )

    useEffect(() => {
        setIsFirstRender(false)
    }, [])

    const hasFeature = useHasFeature()

    const areContactsAvailable = () => contacts && Array.isArray(contacts) && contacts.length > 0

    const renderContent = () => {
        if (isFirstRender || typeof contactsLoading === 'undefined' || contactsLoading) {
            return <Loader />
        }

        if (!areContactsAvailable()) {
            return <p>No profiles available</p>
        }

        return (
            <DirectoryList
                search={searchParams.search ?? ''}
                owner={searchParams.owner}
                priority={searchParams.priority}
                contacts={contacts}
            />
        )
    }

    return (
        <ViewEvent
            type="interface"
            eventData={{
                feature: AppFeatures.AGENTS_DIRECTORY,
                label: 'View a list of profiles',
            }}
        >
            <div className="row column sa-directory">
                <menu className="row">
                    <DirectorySearchInput
                        onInputChange={(search) => dispatch(setSearchParam({ search }))}
                        search={searchParams.search ?? ''}
                    />

                    {hasFeature(AppFeatures.EXTERNAL_DIRECTORY) && (
                        <DirectoryPriorityPopup
                            currentPriority={searchParams.priority}
                            onSelect={(priority) => dispatch(setSearchParam({ priority }))}
                        />
                    )}

                    {hasFeature(AppFeatures.EXTERNAL_DIRECTORY) && (
                        <DirectoryOwnerPopup
                            onSelect={(owner) => dispatch(setSearchParam({ owner }))}
                            currentOwnerId={searchParams.owner}
                        />
                    )}
                </menu>

                <main className="sa-directory__content">{renderContent()}</main>
            </div>
        </ViewEvent>
    )
}

export default Directory

import { createSelector } from 'reselect'

import type RootState from 'store/state'

const selectInstance = (state: RootState) => state.app.instance

const selectInstanceSettings = createSelector(selectInstance, (instance) => instance?.settings)

export const selectIsEnhancedMonitoringEnabled = createSelector(
    selectInstanceSettings,
    (settings) => settings?.isEnhancedMonitoringEnabled ?? false,
)

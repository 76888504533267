import { Button } from '@missionlabs/smartagent-app-components'
import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'

interface Props {
    disabled?: boolean
    elastic?: boolean
    className?: string
    isFocused?: boolean
    children: React.ReactNode
    onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void
    type?: 'button' | 'submit' | 'reset' | undefined
}

const DialerControlButton = ({
    disabled = false,
    elastic = false,
    className,
    isFocused = false,
    onClick,
    type,
    children,
}: Props) => {
    const [isClicked, setIsClicked] = useState(isFocused)

    useEffect(() => {
        if (isFocused !== undefined && isFocused !== isClicked) {
            setIsClicked(isFocused)
        }
    }, [isFocused])

    const buttonClassNames = classnames(className, {
        [styles['dialerControlButton--clicked']]: isClicked,
    })

    return (
        <Button
            className={buttonClassNames}
            disabled={disabled}
            elastic={elastic}
            styling="blank"
            type={type}
            onClick={(ev: React.MouseEvent<HTMLButtonElement>) => {
                setIsClicked(!isClicked)
                onClick && onClick(ev)
            }}
        >
            {children}
        </Button>
    )
}

export default DialerControlButton

import React from 'react'

interface ICustomerSentimentPercentages {
    positive: number
    negative: number
    neutral: number
}
export interface IContactContext {
    seekTo?: number
    seekProgress?: number
    updateSeekTo(to: number): void
    updateSeekProgress(at: number): void
    customerSentimentPercentages?: ICustomerSentimentPercentages
    setSentimentPercentages(newSentimentData: ICustomerSentimentPercentages): void
}

export const ContactContext = React.createContext<IContactContext | {}>({})

export const withContactConsumerContext = (Component: any) => {
    return class extends React.PureComponent<any> {
        render() {
            return (
                <ContactContext.Consumer>
                    {(v) => <Component {...this.props} context={v} />}
                </ContactContext.Consumer>
            )
        }
    }
}

export const withContactProviderContext = <T extends any>(Component: React.FC<T>) => {
    return class extends React.PureComponent<T> {
        readonly state: Partial<IContactContext> = {
            seekTo: undefined,
            seekProgress: undefined,
            customerSentimentPercentages: undefined,
        }

        //called by transcription to seek when msg is clicked
        updateSeekTo = (seekTo: number) => {
            this.setState({ seekTo }, () => this.setState({ seekTo: undefined }))
        }

        //called by call recording to focus on transcript msg
        updateSeekProgress = (seekProgress: number) => {
            this.setState({ seekProgress })
        }

        setSentimentPercentages = (newSentimentData: any) => {
            this.setState((prevState) => ({
                ...prevState,
                customerSentimentPercentages: newSentimentData,
            }))
        }

        render() {
            const actions = {
                updateSeekTo: this.updateSeekTo,
                updateSeekProgress: this.updateSeekProgress,
                setSentimentPercentages: this.setSentimentPercentages,
            }
            return (
                <ContactContext.Provider value={{ ...this.state, ...actions }}>
                    <Component {...this.props} />
                </ContactContext.Provider>
            )
        }
    }
}

import useHasFeature, { AppFeatures } from 'hooks/useHasFeature'
import React from 'react'
import ApolloWrapper from './ApolloWrapper'
import { PhoneBook, PhoneBookWrapper } from './PhoneBook'

const PB: React.FC = React.memo(() => {
    const hasFeature = useHasFeature()
    if (!hasFeature(AppFeatures.AGENTS_DIRECTORY)) return null

    const internalMessaging = hasFeature(AppFeatures.INTERNAL_MESSAGING)

    return internalMessaging ? (
        <ApolloWrapper>
            <PhoneBookWrapper />
        </ApolloWrapper>
    ) : (
        <PhoneBook />
    )
})

export default PB

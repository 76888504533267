import type { Middleware } from '@reduxjs/toolkit'
import RootState from 'store/state'
import { getOS, isAnyAction } from 'utils'

import setGeneralShortcuts from './general.shortcuts'
import setShortcuts from './os.shortcuts'

const shortcutMiddleware: Middleware<{}, RootState> = (store) => {
    setGeneralShortcuts(store) // Set single key navigation shortcuts

    // Set shortcuts based on running OS
    if (getOS() === 'Mac') setShortcuts(store, 'Mac')
    if (getOS() === 'Win') setShortcuts(store, 'Windows')

    return (next) => async (action) => {
        if (!isAnyAction(action)) return

        switch (action.type) {
            default:
                return next(action)
        }
    }
}

export default shortcutMiddleware

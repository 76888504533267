var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { jsxs, jsx } from "react/jsx-runtime";
import React, { PureComponent } from "react";
import classNames from "classnames";
class Radio extends PureComponent {
  constructor() {
    super(...arguments);
    __publicField(this, "state", {
      focused: false
    });
    __publicField(this, "radioButton", React.createRef());
    __publicField(this, "handleOnKeydown", (ev) => {
      if (ev.key === "Enter" || ev.key === " ") {
        if (!this.props.disabled)
          this.props.onChange(ev);
      }
    });
    __publicField(this, "handleOnChange", (ev) => {
      if (!this.props.disabled)
        this.props.onChange(ev, this.radioButton.current.value);
    });
    __publicField(this, "handleFocus", () => {
      this.setState({
        focused: true
      });
    });
    __publicField(this, "handleBlur", () => {
      this.setState({
        focused: false
      });
    });
  }
  render() {
    const { name, value, label, disabled, checked, ariaLabel } = this.props;
    const radioContainerClass = classNames({
      "sa-radio-container": true,
      "disabled": disabled
    });
    const radioClass = classNames({
      "sa-radio": true,
      "focused": this.state.focused,
      "disabled": disabled,
      "checked": checked
    });
    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      /* @__PURE__ */ jsxs("label", { onMouseUp: this.handleOnChange, className: radioContainerClass, children: [
        /* @__PURE__ */ jsx("span", { className: radioClass }),
        /* @__PURE__ */ jsx("input", { "aria-label": ariaLabel, ref: this.radioButton, type: "radio", name, value, checked, disabled, onChange: () => {
        }, onKeyDown: this.handleOnKeydown, onFocus: this.handleFocus, onBlur: this.handleBlur }),
        /* @__PURE__ */ jsx("span", { className: "radio-label", children: label ? label : null })
      ] })
    );
  }
}
export {
  Radio as default
};

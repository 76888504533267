import RootState from 'store/state'

import { ThunkDispatch } from '@reduxjs/toolkit'

import { ICallContactAttributes } from '../contact/contact.state'
import * as QueuedTasksActions from './queuedTasks.reducer'

export const {
    clearACWAttributesQueuedTasks,
    setQueuedTasksProcessing,
    setQueuedTasksProcessingContacts,
    setQueuedTasksProcessingShowModal,
    setQueuedTasksProcessStatuses,
} = QueuedTasksActions

export function setQueuedTasksReplyText(type: string, text: string, isProbablyJson: boolean) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, QueuedTasksActions.QueuedTasksAction>,
    ) => {
        if (type === 'plain')
            dispatch(QueuedTasksActions.setQuickReplyPlainText({ text, isProbablyJson }))
        else dispatch(QueuedTasksActions.setQuickReplyRichText({ text, isProbablyJson }))
    }
}

export function updateACWAttributesQueuedTasks(
    IDs: string[],
    acwAttributes: ICallContactAttributes,
) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, QueuedTasksActions.QueuedTasksAction>,
    ) => {
        dispatch(QueuedTasksActions.updateACWAttributesQueuedTasks({ acwAttributes, IDs }))
    }
}

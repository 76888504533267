import ErrorPopup from 'components/ErrorPopup'
import useUser from 'hooks/redux/useUser'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setLogoutCountdown } from 'store/user/user.actions'

const LoginTimerPopUp: React.FC = () => {
    const [logOutCountdown, setlogOutCountdown] = useState<number | null>(null)
    const user = useUser()
    const dispatch = useDispatch()

    const showError = () => {
        if (logOutCountdown === 60 || logOutCountdown === 30 || logOutCountdown === 15) {
            return false
        }
        return true
    }

    useEffect(() => {
        setlogOutCountdown(user?.logoutCountDown!)
    }, [user?.logoutCountDown])

    useEffect(() => {
        dispatch(setLogoutCountdown())
    }, [])

    return (
        <ErrorPopup
            online={showError()}
            text={`${logOutCountdown} minutes left until you are logged out of Connect`}
        />
    )
}

export default LoginTimerPopUp

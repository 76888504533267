import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AgentFilter, AgentsState } from './agents.state'

const initialState: AgentsState = {
    agents: [],
    agentsActive: false,
    agentsError: false,
    agentsLoading: false,
}

export const agentsSlice = createSlice({
    name: 'agents',
    initialState,
    reducers: {
        showAgents(state) {
            return { ...state, agentsActive: !state.agentsActive }
        },
        getAgents(state, action: PayloadAction<AgentsState['agents']>) {
            return { ...state, agents: [...action.payload], agentsLoading: false }
        },
        setAgentsError(state, action: PayloadAction<boolean>) {
            return { ...state, agentsError: action.payload }
        },
        setAgentsLoading(state, action: PayloadAction<boolean>) {
            return { ...state, agentsLoading: action.payload }
        },
        clearAgents(state) {
            return { ...state, agents: [] }
        },
        filterAgents(state, action: PayloadAction<AgentFilter | undefined>) {
            return {
                ...state,
                agentsFilter: { ...state.agentsFilter, ...action.payload },
                agents: [],
            }
        },
    },
})

export const {
    showAgents,
    getAgents,
    setAgentsError,
    setAgentsLoading,
    clearAgents,
    filterAgents,
} = agentsSlice.actions

export type AgentsAction = ReturnType<
    (typeof agentsSlice.actions)[keyof typeof agentsSlice.actions]
>

export default agentsSlice.reducer

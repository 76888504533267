import { formatDateTime } from '@missionlabs/smartagent-app-components'
import classNames from 'classnames'
import { Loader, NoDataMessage } from 'components'
import redArrowUp from 'images/icon-arrow-up-red.svg'
import greenPlusOutline from 'images/icon-plus-green-outline.svg'
import React, { useEffect, useMemo } from 'react'
import { ClipLoader } from 'react-spinners'
import { DirectoryRecordType, IRealTimeQueueContact } from 'store/directory/directory.state'

import { Button, TimeElapsed } from '@missionlabs/smartagent-app-components'
import Table, { SATableColumn } from '@missionlabs/smartagent-app-components/dist/Table'

import useRealTimeContactList from './useGetContactList'
import { useRequestCall } from './useRequestCall'

interface Props {
    queuesARNs?: string[]
    onClose?(): void
    shouldRefresh?: boolean
}

export const Picker: React.FC<Props> = ({ queuesARNs = [], onClose, shouldRefresh = false }) => {
    const {
        loading,
        error,
        currentContacts,
        // reloadContacts
    } = useRealTimeContactList(queuesARNs, shouldRefresh)

    const { contactIDLoading, contactIDResponse, requestCall } = useRequestCall()

    useEffect(() => {
        if (contactIDResponse?.status === 200) {
            onClose?.()
        }
    }, [contactIDResponse, onClose])

    const cols: SATableColumn[] = useMemo(
        () => [
            {
                name: 'Priority',
                key: 'priority',
                value: 'directoryRecord.priority',
                sort: true,
                render: (priority: boolean) => priority && <img src={redArrowUp} alt="High" />,
            },
            {
                name: 'Parking',
                key: 'ParkBay',
                value: 'ParkBay',
                render: (pb: number) =>
                    pb ? <span className="sa-row-parkbay">{'P' + pb}</span> : '',
                sort: true,
            },
            {
                name: 'Customer Number',
                value: 'CustomerEndpoint.Address',
            },
            {
                name: 'Customer Name',
                value: 'directoryRecord',
                sort: true,
                render: (record: DirectoryRecordType) =>
                    (record?.firstName ?? '') + ' ' + (record?.lastName ?? ''),
            },
            {
                name: 'Queue',
                sort: true,
                key: 'qn',
                value: 'Queue.Name',
                render: (name: string) => name,
            },
            {
                path: '*',
                name: 'Queue Time',
                sort: true,
                key: 'qt',
                render: (contact: IRealTimeQueueContact) => {
                    const parkedTimestamp = contact?.ParkedTimestamp
                    const enqueueTimestamp = contact?.EnqueueTimestamp

                    return parkedTimestamp ? (
                        <TimeElapsed
                            annotate
                            format="hh:mm:ss"
                            hours
                            date={new Date(parkedTimestamp)}
                        />
                    ) : (
                        <TimeElapsed
                            annotate
                            format="hh:mm:ss"
                            hours
                            date={new Date(enqueueTimestamp)}
                        />
                    )
                },
            },
            {
                name: 'Time Of Call',
                sort: true,
                key: 'toc',
                value: 'EnqueueTimestamp',
                render: (time: number) => (
                    <time>{formatDateTime(time, { date: false, time: true })}</time>
                ),
            },
            {
                path: '*',
                name: 'Request Contact',
                sort: true,
                render: (contact: IRealTimeQueueContact) => {
                    if (contactIDLoading === contact.ContactId) return <ClipLoader size={24} />
                    if (contact.PluckAgent) return <span>{contact.PluckAgent.name}</span>
                    if (contact.State === 'CONNECTING')
                        return <span>Ringing ({contact.Agent?.name})</span>
                    if (contact.State === 'CONNECTED')
                        return <span>In Call ({contact.Agent?.name})</span>

                    return (
                        <Button
                            disabled={!!contactIDLoading}
                            onClick={() => requestCall(contact.ContactId)}
                        >
                            <img src={greenPlusOutline} alt="" />
                        </Button>
                    )
                },
            },
        ],
        [contactIDLoading, requestCall],
    )

    if (error) return <div>error</div>
    if (loading && !currentContacts) return <Loader />

    return (
        <Table
            className="sa-table-rt-contacts"
            cols={cols}
            data={
                currentContacts?.map((d) => ({
                    ...d,
                    className: classNames({
                        'sa-row-disabled': d.State === 'CONNECTING' || d.State === 'CONNECTED',
                        'sa-row-priority': !!d.directoryRecord?.priority,
                    }),
                })) ?? []
            }
            noData={<NoDataMessage />}
        />
    )
}

import classNames from 'classnames'
import { ClickableLineWithArrow } from 'components'
import useCall from 'hooks/redux/useCall'
import useChatConnections from 'hooks/redux/useChatConnections'
import useContact from 'hooks/redux/useContact'
import useContacts from 'hooks/redux/useContacts'
import useTaskConnections from 'hooks/redux/useTaskConnections'
import useContactLogForm from 'hooks/useContactLogForm'
import useMedia from 'hooks/useMedia'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import CallState from 'store/call/call.state'
import { IChat } from 'store/chat/chat.state'
import { taskMissed } from 'store/tasks/tasks.actions'
import { ITask } from 'store/tasks/tasks.state'
import CallListItem from 'views/Home/Dialler/CallListItem'

import { Channel } from '@aws-sdk/client-connect'
import { useSelector } from 'react-redux'
import RootState from 'store/state'
import useInterval from '../../../hooks/useInterval'
import { getThreadsNewMessageCounts } from '../../../services/api/api.thread'
import ChatsListItem from '../../Chat/ChatListItem'
import TaskListItem from '../../Tasks/TaskListItem'
import styles from './ContactList.module.scss'

interface Props {}

interface ExtendedITask extends ITask {
    contactType: string
}

interface ExtendedIChat extends IChat {
    contactType: string
}

type ContactListTypes = Partial<CallState> | ExtendedIChat | ExtendedITask

interface ThreadIdWithNewMessageCount {
    threadID: string
    newMessageCount?: number
}

function expandWithTypeField<Type>(contactType: string, contactList: Type[]): Type[] {
    return contactList.length > 0
        ? contactList.map((contact) => {
              return { ...contact, contactType }
          })
        : contactList
}

const ContactList: React.FC<Props> = () => {
    //needs for loading forms if reload page not on contactLog page
    //uses for status dropdown validation
    useContactLogForm()

    const dispatch = useDispatch()

    const call = useCall()
    const showCall = call && !call.incoming
    const { AFTER_CALL_WORK } = connect.AgentAvailStates
    const itemsAmountInHeader = 2
    const chatsList = expandWithTypeField('chat', useChatConnections()) as ExtendedIChat[]
    const tasksList = expandWithTypeField('task', useTaskConnections()) as ExtendedITask[]
    const [threadsNewMessageCounts, setThreadsNewMessageCounts] = useState<
        ThreadIdWithNewMessageCount[]
    >([])

    const currentStatus = useSelector(
        (state: RootState) => state.user?.status.name,
    ) as connect.AgentAvailStates

    const [showPopup, setShowPopup] = useState(false)

    const [isMobile] = useMedia()

    const contact = useContact()

    const contacts = useContacts()

    const getTasksAndChatsLength = () => chatsList.length + tasksList.length + (showCall ? 1 : 0)

    const refreshThreadsNewMessageCount = async () => {
        if (tasksList.length > 1) {
            const taskListThreadIds = tasksList
                .map((task) => task.threadID)
                .filter(Boolean) as string[]
            if (taskListThreadIds.length) {
                setThreadsNewMessageCounts(await getThreadsNewMessageCounts(taskListThreadIds))
            }
        }
    }

    useInterval(() => {
        refreshThreadsNewMessageCount()
    }, 30000)

    useEffect(() => {
        setTimeout(() => refreshThreadsNewMessageCount(), 500)
    }, [])

    useEffect(() => {
        if (tasksList.find((task) => task.status === 'connecting')) {
            refreshThreadsNewMessageCount()
        }

        const rejectedTasks = tasksList.filter(({ status }) => status === 'rejected')
        rejectedTasks.forEach(({ id }) => {
            // Like the contact.onRefresh callback in the listeners.contact.ts middleware. Marks rejected tasks as missed.
            dispatch(taskMissed(id))
        })
    }, [tasksList.length])

    useEffect(() => {
        // When we select a contact, we should remove the newMessageCount
        if (contact && contact.attributes['sa-threadID']) {
            const threadsNewMessageCountsFiltered = threadsNewMessageCounts.filter(
                (t) => t.threadID !== contact.attributes['sa-threadID'],
            )
            if (threadsNewMessageCountsFiltered.length !== threadsNewMessageCounts.length) {
                setThreadsNewMessageCounts(threadsNewMessageCountsFiltered)
            }
        }
    }, [contact?.ID])

    const allItems = useMemo(() => {
        const tasksWithNewMessageCounts = tasksList.map((task) => {
            const newMessageCount = threadsNewMessageCounts.find(
                (threadAndNewMessageCount) => threadAndNewMessageCount.threadID === task.threadID,
            )?.newMessageCount
            return newMessageCount ? { ...task, newMessageCount } : task
        })
        const items: ContactListTypes[] = [...chatsList, ...tasksWithNewMessageCounts]
        if (showCall && items.length > 0) items.unshift(call)
        //Not in a call but agent is in ACW so still show the call in the list
        if (currentStatus === AFTER_CALL_WORK && items.length > 0) {
            const contact = contacts.find((contact) => contact.channel === Channel.VOICE)
            items.unshift({
                inCall: true,
                acw: true,
                number: contact?.customer?.phoneNumber,
            } as Partial<CallState>)
        }
        return items
    }, [chatsList, tasksList, threadsNewMessageCounts, currentStatus])

    const getItemsForHeader = () => allItems.slice(0, itemsAmountInHeader)

    const getItemsForPopup = () => allItems.slice(itemsAmountInHeader, allItems.length)

    const findChannelAttributes = (contactID: string, attribute: string) => {
        return contacts.find((contact) => contact.ID === contactID)?.attributes[attribute]
    }

    const renderItem = (item: ContactListTypes, index: number) => {
        if ((item as CallState).connections || (item as CallState).inCall) {
            const call = item as CallState
            return (
                <CallListItem
                    selected={contact?.channel === Channel.VOICE}
                    call={call}
                    index={index + 1}
                />
            )
        } else if ((item as ExtendedIChat).contactType === 'chat') {
            const chat = item as IChat
            return (
                <ChatsListItem
                    selected={chat.id === contact?.ID}
                    chat={chat}
                    index={index + 1}
                    channel={findChannelAttributes(chat.id, 'sa-social-media-platform')}
                    subChannel={findChannelAttributes(chat.id, 'sa-sub-channel')}
                />
            )
        } else if ((item as ExtendedITask).contactType === 'task') {
            const task = item as ITask
            return <TaskListItem selected={task.id === contact?.ID} task={task} index={index + 1} />
        }
    }

    const renderItemsList = (list: ContactListTypes[]) => {
        return (
            <ol className={styles.contactList} data-test-id="contactList">
                {list.map((item, i) => {
                    const key = (item as ExtendedIChat).id || 'call'
                    return <li key={key}>{renderItem(item, i)}</li>
                })}
            </ol>
        )
    }

    const listWrapperClassName = classNames(styles.listWrapper, {
        [styles['listWrapper--withPadding']]: getTasksAndChatsLength() <= itemsAmountInHeader,
    })

    const renderList = () => {
        if (isMobile) {
            const itemsForHeader = getItemsForHeader()
            const itemsForPopup = getItemsForPopup()

            return (
                <>
                    <div className={listWrapperClassName}>{renderItemsList(itemsForHeader)}</div>

                    <div className={styles.expandableSection}>
                        {getTasksAndChatsLength() > itemsAmountInHeader && (
                            <ClickableLineWithArrow
                                className={styles.line}
                                arrowClassName={styles.arrow}
                                onClick={() => setShowPopup(!showPopup)}
                            />
                        )}
                    </div>

                    {showPopup && itemsForPopup.length > 0 && (
                        <div className={styles.Popup}>
                            <div className={styles.Popup_wrapper}>
                                <div className={styles.Popup_content}>
                                    {renderItemsList(itemsForPopup)}
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )
        }

        return renderItemsList(allItems)
    }

    return <>{allItems.length > 0 && isMobile !== undefined && renderList()}</>
}

export default ContactList

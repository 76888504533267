import PhoneLib from 'google-libphonenumber'
import { formatPhoneNumber, getDirectoryContactByPhoneNumber } from 'utils'
import useRestructuredDirectoryContacts from './useRestructuredDirectoryContacts'

export default function useGetDirectoryContact(phoneNumber?: string) {
    const [contacts] = useRestructuredDirectoryContacts()

    const directoryContact = getDirectoryContactByPhoneNumber(
        contacts,
        formatPhoneNumber(phoneNumber, PhoneLib.PhoneNumberFormat.E164),
    )

    const name = (directoryContact?.firstName ?? '') + ' ' + (directoryContact?.lastName ?? '')

    return [directoryContact, name] as [typeof directoryContact, typeof name]
}

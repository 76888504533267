// this way of raising events is deprecated. Please, use packages,
// located in the 'missionlabs-analytics' repository

interface Props {
    eventName: string
    eventParams?: object
}

export const sendEvent = ({ eventName, eventParams }: Props) => {
    if (window && window.gtag) {
        window.gtag('event', eventName, eventParams)
    }
}

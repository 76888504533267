import { formatDuration } from 'date-fns'

import { IEmailAttachment } from 'components/EmailMessage/types'
import { AppSubFeatures } from 'hooks/useHasFeature'
import { channelFormatMapping } from 'store/contactHistory/contactHistory.constants'
import { ChannelType, IInteraction } from '../types'
import { ITag, TagColourMap } from './types'

import EmailInboundIcon from 'images/icon-email-inbound.svg'
import EmailOutboundIcon from 'images/icon-email-outbound.svg'
import EmailIcon from 'images/icon-email-outline.svg'
import EyeOutlineIcon from 'images/icon-eye-outline.svg'
import FacebookIcon from 'images/icon-facebook.svg'
import InstagramIcon from 'images/icon-instagram.svg'
import SMSIcon from 'images/icon-sms.svg'
import TwitterIcon from 'images/icon-twitter.svg'
import VoiceInboundIcon from 'images/icon-voice-inbound.svg'
import VoiceOutboundIcon from 'images/icon-voice-outbound.svg'
import WebchatIcon from 'images/icon-webchat.svg'
import WhatsappIcon from 'images/icon-whatsapp.svg'
import { ChannelSubFeatures, isChannelOfCategory } from 'store/contactHistory/contactHistory.utils'
import { ICallContactAttributes } from 'store/metrics/metrics.state'

const tagColourMap: TagColourMap = {
    'TWITTER DM': 'blue',
    'TWITTER TWEET': 'blue',
    'FACEBOOK MESSENGER': 'blue',
    'FACEBOOK POST': 'blue',
    'INSTAGRAM IG-DM': 'blue',
    'INSTAGRAM IG-COMMENT': 'blue',
    'WHATSAPP WHATSAPP-DM': 'blue',
    'SMS SMS-DM': 'blue',
    'SMS OUTBOUND': 'purple',
    'SMS CAMPAIGN-MESSAGE': 'purple',
    'SMS MARKETING-MESSAGE': 'purple',
    'VOICE INBOUND': 'blue',
    'VOICE OUTBOUND': 'purple',
    'VOICE MONITOR': 'orange',
    'VOICE TRANSFER': 'pink',
    WEBCHAT: 'blue',
    'TASK INBOUND': 'blue',
    'TASK OUTBOUND': 'purple',
    'TASK CCREPLY': 'blue',
    'sa-reason': 'sa-blue',
    'sa-barged': 'brown',
    UNKNOWN: 'dark',
}

export const tagChannelFormatMapping: Record<keyof typeof channelFormatMapping, string> = {
    ...channelFormatMapping,
    'FACEBOOK POST': 'Facebook Comment',
    'INSTAGRAM IG-COMMENT': 'Insta-Comment',
    'TWITTER TWEET': 'Twitter Tweet',
}

export const possibleEmailAttributes = [
    'sa-customer-email',
    'email',
    'customerEmail',
    'sa-customerEmail',
    'sa-email',
    'sa-customer-endpoint-address',
]

export const constructTags = (interaction: IInteraction): ITag[] => {
    const tags: ITag[] = []

    const { channelType } = interaction
    const formattedChannelType = tagChannelFormatMapping[channelType]

    const { 'sa-reason': reasonForContact, 'sa-barged': bargedAttribute } =
        interaction.attributes || {}

    formattedChannelType &&
        tags.push(
            getCustomTagProperties(interaction) || {
                text: formattedChannelType,
                colour: tagColourMap?.[channelType] || tagColourMap.UNKNOWN,
            },
        )
    reasonForContact && tags.push({ text: reasonForContact, colour: tagColourMap['sa-reason'] })
    bargedAttribute && tags.push({ text: 'Barged Call', colour: tagColourMap['sa-barged'] })

    return tags
}

export const getCustomTagProperties = (interaction: IInteraction): ITag | undefined => {
    const { channelType } = interaction
    const relatedMessage = interaction.emailMessages?.find(
        (msg) => msg.messageId === interaction.messageId,
    )

    if (channelType === 'TASK OUTBOUND' && relatedMessage?.forwardedTo) {
        return { text: 'Forwarded email', colour: 'sa-grey' }
    }

    if (channelType === 'TASK INBOUND' && relatedMessage?.externalFrom) {
        return { text: 'Reply to forward', colour: 'sa-grey' }
    }
}

export const constructDisplayName = (
    channelType: ChannelType,
    customerEndpointAddress?: string | undefined,
    attributes?: ICallContactAttributes,
    selectedMessageEndpointAddress?: string | undefined,
): string | undefined => {
    let displayName: string | undefined

    if (isChannelOfCategory(channelType, 'Voice')) {
        displayName = customerEndpointAddress
    } else if (
        isChannelOfCategory(channelType, 'Social') ||
        isChannelOfCategory(channelType, 'Messaging')
    ) {
        const socialHandle = attributes?.['sa-customer-endpoint-address']
        displayName = ['FACEBOOK', 'WHATSAPP', 'SMS'].some((channel) =>
            channelType.includes(channel),
        )
            ? socialHandle
            : `@${socialHandle}`
    } else if (
        isChannelOfCategory(channelType, 'Webchat') ||
        isChannelOfCategory(channelType, 'Task')
    ) {
        if (channelType === 'TASK CCREPLY' && selectedMessageEndpointAddress) {
            return selectedMessageEndpointAddress
        }

        displayName = getEmailFromContactAttributes(attributes)
    }

    return displayName
}

export const formatInteractionDuration = (duration: number): string => {
    let interactionDuration = 'Less than a second'

    const durationInSeconds = duration / 1000
    const durationInMinutes = durationInSeconds / 60
    const durationInHours = durationInMinutes / 60
    const durationInDays = durationInHours / 24

    if (durationInDays >= 1) {
        interactionDuration = 'Over a day'
    } else if (durationInSeconds >= 1) {
        const displaySeconds = Math.floor(durationInSeconds % 60)
        const displayMinutes = Math.floor(durationInMinutes % 60)
        const displayHours = Math.floor(durationInHours % 24)

        const durationObject: Duration = {
            hours: displayHours,
            minutes: displayMinutes,
            seconds: displaySeconds,
        }

        interactionDuration = formatDuration(durationObject)
    }
    return interactionDuration
}

export const getChannelSubFeature = (channelType: ChannelType): ChannelSubFeatures | undefined => {
    if (isChannelOfCategory(channelType, 'Social')) return AppSubFeatures.CONTACT_HISTORY_SOCIAL

    if (isChannelOfCategory(channelType, 'Voice')) return AppSubFeatures.CONTACT_HISTORY_VOICE

    if (isChannelOfCategory(channelType, 'Webchat')) return AppSubFeatures.CONTACT_HISTORY_WEBCHAT

    if (isChannelOfCategory(channelType, 'Task')) return AppSubFeatures.CONTACT_HISTORY_TASK

    if (isChannelOfCategory(channelType, 'Messaging'))
        return AppSubFeatures.CONTACT_HISTORY_MESSAGING
}

export const getEmailFromContactAttributes = (
    attributes?: ICallContactAttributes,
): string | undefined => {
    if (!attributes) return

    // Search through the contact's attributes and grab the first of the email attributes list above if found
    const emailAttribute = Object.keys(attributes).find((attribute) =>
        possibleEmailAttributes.includes(attribute),
    )
    return emailAttribute && attributes[emailAttribute]
}

export const getInteractionId = (interaction: IInteraction): string => {
    const { messageId, ID } = interaction
    return messageId || ID
}

export const attachmentsRequireTransformation = (attachments: IEmailAttachment[]): boolean => {
    return attachments.length > 0 && !('s3URL' in attachments[0])
}

export const formatDisconnectReason = (reason: string): string => {
    switch (reason) {
        case 'CONTACT_FLOW_DISCONNECT':
            return 'SYSTEM DISCONNECT'
        default:
            return reason
    }
}

export const getChannelIcon = (channelType: ChannelType): string | undefined => {
    let channelIcon

    if (channelType.includes('TWITTER')) {
        channelIcon = TwitterIcon
    } else if (channelType.includes('FACEBOOK')) {
        channelIcon = FacebookIcon
    } else if (channelType.includes('INSTAGRAM')) {
        channelIcon = InstagramIcon
    } else if (channelType.includes('WHATSAPP')) {
        channelIcon = WhatsappIcon
    } else if (channelType.includes('SMS')) {
        channelIcon = SMSIcon
    } else if (channelType === 'VOICE OUTBOUND' || channelType === 'VOICE TRANSFER') {
        channelIcon = VoiceOutboundIcon
    } else if (channelType === 'VOICE INBOUND' || channelType === 'VOICE DISCONNECT') {
        channelIcon = VoiceInboundIcon
    } else if (channelType === 'VOICE MONITOR') {
        channelIcon = EyeOutlineIcon
    } else if (channelType === 'WEBCHAT') {
        channelIcon = WebchatIcon
    } else if (channelType === 'TASK INBOUND' || channelType === 'TASK CCREPLY') {
        channelIcon = EmailInboundIcon
    } else if (channelType === 'TASK OUTBOUND') {
        channelIcon = EmailOutboundIcon
    } else if (channelType === 'TASK') {
        channelIcon = EmailIcon
    }

    return channelIcon
}

export const contactHasNoHistory = (
    interactions: IInteraction[] | undefined,
    loaded: boolean | undefined,
): boolean => {
    return !!((!interactions || interactions.length === 0) && loaded)
}

import {
    ITeamsContactResponse,
    ITeamsLinks,
    ITeamsStatusResponse,
} from 'store/directory/teams/state'

import { makeRequest } from './'

export const getContactsStatuses = async (companyID: string, instanceID: string, token: string) => {
    const { data } = await getLinksForContacts(companyID, instanceID, token)

    const { statusesLink, statusesMetadata } = data
    const { updateTimeDelta } = statusesMetadata

    const teamsStatuses = await makeRequest<ITeamsStatusResponse[]>({
        url: statusesLink,
        ignoreToken: true,
    })

    return { teamsStatuses, updateTimeDelta }
}

export const getLinksForContacts = (companyID: string, instanceID: string, token: string) =>
    makeRequest<ITeamsLinks>({
        url: `directory-service/companies/${companyID}/instances/${instanceID}/microsoft-teams-directory/s3-url`,
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

export const getContacts = async (companyID: string, instanceID: string, token: string) => {
    const { data: links } = await getLinksForContacts(companyID, instanceID, token)

    const { contactsLink } = links

    const { data: contacts } = await makeRequest<ITeamsContactResponse[]>({
        url: contactsLink,
        ignoreToken: true,
    })

    return contacts
}

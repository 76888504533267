import React from 'react'
import { useSelector } from 'react-redux'

import { Participant } from 'components'

import { constructTags, getColourClass, getStatus } from 'components/ParticipantList/utils'

import type { CallConnection } from 'store/callV2/callV2.state'
import { selectContactAttributes } from 'store/contact/contact.selectors'

import styles from './participant-list.module.scss'

export type Props = {
    connections: CallConnection[]
}

const ParticipantList: React.FC<Props> = ({ connections }) => {
    const contactAttributes = useSelector(selectContactAttributes)

    const tags = constructTags(contactAttributes)

    return (
        <ul aria-label="Participant list" className={styles.list}>
            {connections.map((connection) => {
                const {
                    id,
                    name,
                    type = 'UNKNOWN',
                    status: connectionStatus,
                    isMuted,
                    controls,
                } = connection

                const { type: statusType, timestamp } = connectionStatus

                const isOnHold = statusType === connect.ConnectionStateType.HOLD

                const status = getStatus(type, statusType)

                const colourClass = getColourClass(type, statusType)

                const participantTags = type === 'CUSTOMER' ? tags : []

                return (
                    <li key={id} aria-labelledby={id}>
                        <Participant
                            id={id}
                            name={name}
                            status={status}
                            type={type}
                            initiationDate={timestamp}
                            isMuted={isMuted}
                            isOnHold={isOnHold}
                            controls={controls}
                            colourClass={colourClass}
                            tags={participantTags}
                        />
                    </li>
                )
            })}
        </ul>
    )
}

export default ParticipantList

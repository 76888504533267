import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ChatConfigAction, ChatConfigLoadingAction, ChatSettingsState } from './chat.state'

const initialState: ChatSettingsState = {}

export const chatSettingsSlice = createSlice({
    name: 'chatSettings',
    initialState,
    reducers: {
        getChatConfig(state, action: PayloadAction<ChatConfigAction>) {
            return {
                ...state,
                [action.payload.brand]: {
                    isLoading: false,
                    chatConfig: action.payload.chatConfig,
                },
            }
        },
        getChatConfigLoading(state, action: PayloadAction<ChatConfigLoadingAction>) {
            return {
                ...state,
                [action.payload.brand]: {
                    ...state[action.payload.brand],
                    isLoading: action.payload.isLoading,
                },
            }
        },
    },
})

export const { getChatConfig, getChatConfigLoading } = chatSettingsSlice.actions

export type ChatSettingsAction = ReturnType<
    (typeof chatSettingsSlice.actions)[keyof typeof chatSettingsSlice.actions]
>

export default chatSettingsSlice.reducer

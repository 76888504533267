import { jsx } from "react/jsx-runtime";
const checkedIcon = /* @__PURE__ */ jsx("svg", { viewBox: "0 0 10 8", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: /* @__PURE__ */ jsx("path", { d: "M1.31316 3.72209C1.01276 3.42168 0.525706 3.42168 0.225302 3.72209C-0.0751008 4.02249 -0.0751008 4.50954 0.225302 4.80994L3.3062 7.89084L9.7747 1.42235C10.0751 1.12195 10.0751 0.634898 9.7747 0.334494C9.47429 0.0340911 8.98724 0.0340911 8.68684 0.334494L3.3062 5.71513L1.31316 3.72209Z", fill: "white", fillOpacity: "0.8" }) });
const doubleCheckedIcon = /* @__PURE__ */ jsx("svg", { viewBox: "0 0 15 8", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: /* @__PURE__ */ jsx("path", { d: "M1.31316 3.72209C1.01276 3.42168 0.525706 3.42168 0.225302 3.72209C-0.0751008 4.02249 -0.0751008 4.50954 0.225302 4.80994L3.3062 7.89084L9.7747 1.42235C10.0751 1.12195 10.0751 0.634898 9.7747 0.334494C9.47429 0.0340911 8.98724 0.0340911 8.68684 0.334494L3.3062 5.71513L1.31316 3.72209ZM6.51731 6.10195L8.3062 7.89084L14.7747 1.42235C15.0751 1.12195 15.0751 0.634898 14.7747 0.334494C14.4743 0.0340911 13.9872 0.0340911 13.6868 0.334494L8.3062 5.71513L7.56162 4.97055L6.51731 6.10195Z", fill: "white" }) });
const getReceiptStatus = (deliveredTimestamp, readTimestamp, statusLabels) => {
  return readTimestamp ? statusLabels.read : deliveredTimestamp ? statusLabels.delivered : statusLabels.sent;
};
const useMessageReceipt = ({ readTimestamp, deliveredTimestamp, receiptsActive, clientType, senderType, translations }) => {
  const showReceipt = clientType === senderType && !!receiptsActive;
  const receiptStatus = getReceiptStatus(deliveredTimestamp, readTimestamp, translations.statusLabels);
  const receiptIcon = readTimestamp ? doubleCheckedIcon : checkedIcon;
  return {
    showReceipt,
    receiptStatus,
    receiptIcon
  };
};
export {
  useMessageReceipt as u
};

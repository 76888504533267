import { formatDateTime } from '@missionlabs/smartagent-app-components'
import { isToday, isYesterday } from 'date-fns'
import React from 'react'
import { ISocialChatMessage } from 'store/chat/chat.state'

interface Props {
    messageTimestamp: number
    messages: ISocialChatMessage[]
    className?: string
}

const MessageDateGrouped: React.FC<Props> = ({ messageTimestamp, messages, className }) => {
    if (!messageTimestamp) return null
    const currentMessageDate = new Date(messageTimestamp).toUTCString().slice(0, -12)
    const formatedDate = formatDateTime(new Date(messageTimestamp), {
        date: false,
        shortMonthYear: true,
    })
    const messagesOnSameDate = messages.reduce<ISocialChatMessage[]>((results, msg) => {
        const msgDate: string = new Date(msg.AbsoluteTime).toUTCString().slice(0, -12)
        if (currentMessageDate === msgDate) {
            results.push(msg)
        }
        return results
    }, [])
    const earliestMessageTimestamp = Math.min(
        ...messagesOnSameDate.map((m) => new Date(m.AbsoluteTime).getTime()),
    )
    const isFirstMessageForDate = messageTimestamp === earliestMessageTimestamp

    return (
        <div className={className}>
            {isFirstMessageForDate ? (
                <p className="message-date xsm-mar">
                    {isToday(messageTimestamp)
                        ? 'Today'
                        : isYesterday(messageTimestamp)
                          ? 'Yesterday'
                          : formatedDate}
                </p>
            ) : null}
        </div>
    )
}

export default MessageDateGrouped

import RootState from 'store/state'
import loadExternalDirectoryContacts from './loadExternalDirectoryContacts'
import useDirectory from './useDirectory'

type Returns = [
    RootState['directory']['externalDirectory']['contacts'],
    RootState['directory']['externalDirectory']['isLoading'],
]

export default function useExternalDirectoryContacts() {
    const { externalDirectory } = useDirectory()

    const { contacts, isLoading } = externalDirectory

    loadExternalDirectoryContacts()

    return [contacts, isLoading] as Returns
}

import type { Channel } from '@missionlabs/smartagent-lib-shared/build/form-templates/form-template.schema'
import { StaticReminder } from 'components'
import useCall from 'hooks/redux/useCall'
import useChat from 'hooks/redux/useChat'
import useChatConnections from 'hooks/redux/useChatConnections'
import useContact from 'hooks/redux/useContact'
import useTaskConnections from 'hooks/redux/useTaskConnections'
import useHasFeature, { AppFeatures, AppSubFeatures } from 'hooks/useHasFeature'
import React from 'react'
import { Widget } from 'store/app/app.state'
import { ChatTranscripts } from 'views/Chat/ChatTranscripts'
import Plugins from 'views/Home/Plugins'
import Stats from 'views/Home/Stats'
import { PluginSwitcher } from 'views/Home/Switcher'
import ThreadMessages from 'views/Tasks/Messages'
import { ChatHistoryTranscript } from 'widgets/ChatHistoryTranscript'
import { ContactPanelProvider } from './context'

interface Props {
    channel: Channel
}

export const ContactPanel: React.FC<Props> = ({ channel }) => {
    const hasFeature = useHasFeature()
    const contact = useContact()
    const inCall = !!useCall()
    const inChat = !!useChat().connections.length
    const inTask = !!useTaskConnections().length

    const selectedChat = useChatConnections('selected')

    const chatAccepted = selectedChat?.status === connect.ContactStateType.CONNECTED
    const callAccepted = useCall()?.inCall

    const getWidgetsType = (): Widget['type'] | undefined => {
        if (hasFeature(AppFeatures.CALL_PLUCKING)) return 'realTime'

        if (
            (inCall && !hasFeature(AppFeatures.CONTACT_HISTORY)) ||
            (inChat && !hasFeature(AppFeatures.CONTACT_HISTORY)) ||
            (inTask && !hasFeature(AppFeatures.CONTACT_HISTORY)) ||
            (hasFeature(AppFeatures.SOCIAL_TASKS) &&
                !!selectedChat &&
                !hasFeature(AppFeatures.CONTACT_HISTORY))
        ) {
            return
        }

        if (channel === 'CHAT') return 'chatContact'
        if (channel === 'VOICE') return 'callContact'
        if (channel === 'TASK') return 'taskContact'
    }

    const showStats = hasFeature(AppFeatures.QUEUE_STATS) || hasFeature(AppFeatures.CALL_PLUCKING)
    const showChatHistoryTranscript =
        !inChat && hasFeature(AppFeatures.CHAT, [AppSubFeatures.CHAT_HISTORY_TRANSCRIPT])

    return (
        <ContactPanelProvider>
            <div className="panel fixed-height panel-contact alt">
                {(chatAccepted || callAccepted) && <StaticReminder channel={channel} />}
                {!!contact && channel === contact?.channel ? (
                    <>
                        <PluginSwitcher
                            type="call"
                            defaultView={contact.attributes?.['sa-selected-view']}
                            widgetsType={getWidgetsType()}
                            journey={contact.attributes?.['sa-customer-journey']}
                            showQueueTime={contact.attributes?.['sa-show-queue-time'] === 'show'}
                        />
                        {channel === 'TASK' && !hasFeature(AppFeatures.CONTACT_HISTORY) && (
                            <ThreadMessages contactID={contact?.ID} />
                        )}
                        {channel === 'CHAT' &&
                            !hasFeature(AppFeatures.CONTACT_HISTORY) &&
                            showChatHistoryTranscript &&
                            (contact.attributes?.['sa-social-media-platform'] ? (
                                <ChatTranscripts contactID={contact.ID} />
                            ) : (
                                <ChatHistoryTranscript contact={contact} />
                            ))}
                    </>
                ) : (
                    <>
                        {showStats && <Stats />}
                        <Plugins type="default" />
                    </>
                )}
            </div>
        </ContactPanelProvider>
    )
}

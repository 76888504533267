import { Box, Checkbox } from '@missionlabs/smartagent-app-components'
import type { GetThreadResponse, IMessage } from '@missionlabs/smartagent-service-thread'
import { ToolBar } from 'components'
import { AGENT_SEPARATE_LINE } from 'constants/regExp'
import useContact from 'hooks/redux/useContact'
import Search from 'images/search.svg'
import React, { useEffect, useMemo, useState } from 'react'
import { getThread } from 'services/api/api.thread'
import { CTR } from 'store/contact/contact.state'
import { cutStringByRegExp } from 'utils/cutStringByRegExp'
import ThreadBubble from './ThreadBubble'
import './thread-message.scss'

interface Props {
    contactID: string
    contact?: CTR
}

const ThreadMessages: React.FC<Props> = ({ contactID, contact }) => {
    const [thread, setThread] = useState<GetThreadResponse | undefined>(undefined)
    const [checked, setChecked] = useState<boolean>(false)
    const contactState = contact || useContact()
    const threadID = contactState?.attributes['sa-threadID']
    const channel = contactState?.attributes['sa-channel']

    const contactMessages = useMemo(
        () =>
            thread?.messages
                .filter((message) => message.channel === channel && message.contactID === contactID)
                .sort((a, b) => (b.timestamp < a.timestamp ? 1 : -1)),
        [thread?.messages],
    )

    const allMessages = useMemo(
        () =>
            thread?.messages
                ?.filter((arr) => arr.type !== 'AUTO_REPLY')
                .sort((a, b) => (b.timestamp < a.timestamp ? 1 : -1)),
        [thread?.messages],
    )

    const fetchThread = async () => {
        if (contactState?.channel !== 'VOICE') {
            setThread(await getThread(threadID))
        }
    }

    useEffect(() => {
        if (
            contact?.attributes?.['sa-initiation-method'] === 'outbound' &&
            thread?.messages?.length === 1
        ) {
            setChecked(true)
        }
    }, [contact?.attributes?.['sa-initiation-method'], thread?.messages?.length])

    useEffect(() => {
        fetchThread()
        // eslint-disable-next-line
    }, [contactID])

    const messages = (data?: IMessage[]) => {
        return data && data.length > 0 ? (
            data?.map((msg: IMessage) => {
                return (
                    <ThreadBubble
                        type={msg.direction}
                        date={msg.timestamp}
                        content={cutStringByRegExp(msg.content, AGENT_SEPARATE_LINE)}
                        attachments={msg.attachments}
                        messageID={msg.messageID}
                        threadID={threadID}
                        name={msg.agent?.name}
                        key={msg.messageID}
                    />
                )
            })
        ) : (
            <div className="no-thread-message">
                <img src={Search} alt="Search" width="24px" height="24px" />
                <p>Click 'Show all messages' to see all the thread messages</p>
            </div>
        )
    }

    return (
        <>
            {thread ? (
                <Box
                    alt
                    collapse
                    boxLabel="Messages"
                    className="thread-message"
                    header={
                        <ToolBar
                            text="Messages"
                            items={[
                                <Checkbox
                                    square
                                    label="Show all messages"
                                    key="0"
                                    checked={checked}
                                    onChange={(checked) => setChecked(checked)}
                                />,
                            ]}
                        />
                    }
                >
                    {checked ? messages(allMessages) : messages(contactMessages)}
                </Box>
            ) : (
                <div>No message(s) found</div>
            )}
        </>
    )
}

export default ThreadMessages

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { jsxs, jsx } from "react/jsx-runtime";
import { PureComponent } from "react";
import classNames from "classnames";
const Checked = "data:image/svg+xml,%3csvg%20width='18'%20height='18'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M7.707%2011.697a1%201%200%2000-1.414%201.414l4.005%204.005%208.41-8.409a1%201%200%2010-1.415-1.414l-6.995%206.995-2.59-2.591z'%20fill-rule='nonzero'%20fill='%23FFF'/%3e%3c/svg%3e";
class Checkbox extends PureComponent {
  constructor() {
    super(...arguments);
    __publicField(this, "state", {
      focused: false
    });
    __publicField(this, "onClick", (ev) => {
      if (!this.props.disabled)
        this.props.onChange(!this.props.checked, ev);
    });
    __publicField(this, "setFocus", () => {
      this.setState({
        focused: true
      });
    });
    __publicField(this, "unsetFocus", () => {
      this.setState({
        focused: false
      });
    });
  }
  render() {
    const { disabled, checked, square, error, label, required, title } = this.props;
    const checkboxClass = classNames({
      "checkbox": true,
      "focused": this.state.focused,
      "disabled": disabled,
      "checked": checked,
      "required": required,
      "unchecked": !checked,
      "square": !!square,
      "error": !!error
    });
    return /* @__PURE__ */ jsxs("label", { className: "sa-checkbox-container", children: [
      /* @__PURE__ */ jsx("span", { className: "checkbox-label", children: !!label && label }),
      /* @__PURE__ */ jsx("input", { onFocus: this.setFocus, onBlur: this.unsetFocus, type: "checkbox", checked, onChange: this.onClick, "aria-label": title }),
      /* @__PURE__ */ jsx("span", { className: checkboxClass, title, children: square && checked && /* @__PURE__ */ jsx("img", { src: Checked, alt: "checked" }) })
    ] });
  }
}
__publicField(Checkbox, "defaultProps", {
  checked: false,
  disabled: false,
  square: false,
  required: false,
  error: false,
  onChange: () => {
  }
});
export {
  Checkbox as default
};

import { jsx } from "react/jsx-runtime";
import parse from "html-react-parser";
import _Linkify from "react-linkify";
import { M as MarkdownWrapper } from "./MarkdownWrapper-BQ-Hbyxf.mjs";
const processLink = ({
  href,
  text,
  key
}, ignoreTransformText) => {
  if (ignoreTransformText && ignoreTransformText.includes(text)) {
    return text;
  }
  return /* @__PURE__ */ jsx(
    "a",
    {
      target: "_blank",
      rel: "noopener noreferrer",
      href,
      children: text
    },
    key
  );
};
const Linkify = _Linkify.default ? _Linkify.default : _Linkify;
const TransformContent = ({
  text,
  isMarkdownEnabled,
  isHTMLEnabled,
  ignoreTransformToLinkTexts
}) => {
  const shortUrl = (url) => {
    return url.replace(/(https?:\/\/)|(w+\.)/gi, "");
  };
  return isMarkdownEnabled ? /* @__PURE__ */ jsx(MarkdownWrapper, { children: text }) : /* @__PURE__ */ jsx(
    Linkify,
    {
      componentDecorator: (href, text2, key) => {
        return processLink({ href, text: shortUrl(text2), key }, ignoreTransformToLinkTexts);
      },
      children: isHTMLEnabled ? parse(text, { trim: true }) : /* @__PURE__ */ jsx("p", { children: text })
    }
  );
};
export {
  TransformContent as T
};

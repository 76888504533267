import React, { useMemo } from 'react'

import ControlsButtons from 'components/ControlsButtons'
import type { ControlsButton } from 'components/ControlsButtons/types'

import styles from './controls.module.scss'

export type Props = {
    canReturnToMonitoring?: boolean
    onReturnToMonitoring: () => void
    canBarge?: boolean
    canEndMonitoring?: boolean
    onBarge: () => void
    onEndMonitoring: () => void
}

const Controls: React.FC<Props> = ({
    canReturnToMonitoring = false,
    onReturnToMonitoring,
    canBarge = false,
    canEndMonitoring = false,
    onBarge,
    onEndMonitoring,
}) => {
    const endMonitoring: ControlsButton = {
        text: 'End monitoring',
        handleClick: onEndMonitoring,
        styling: 'red',
    }

    const returnToMonitoring: ControlsButton = {
        text: 'Return to monitoring',
        handleClick: onReturnToMonitoring,
        styling: 'white',
    }

    const barging: ControlsButton = {
        text: 'Barge',
        handleClick: onBarge,
        styling: 'white',
    }

    const monitoringControls = useMemo(() => {
        const controls: ControlsButton[] = []

        if (canEndMonitoring) controls.push(endMonitoring)
        if (canReturnToMonitoring) controls.push(returnToMonitoring)
        if (canBarge) controls.push(barging)

        return controls
    }, [canReturnToMonitoring, canBarge, canEndMonitoring])

    return (
        <div className={styles.container}>
            {monitoringControls && <ControlsButtons controls={monitoringControls} />}
        </div>
    )
}

export default Controls

import classNames from 'classnames'
import React, { ReactElement } from 'react'

import { Button } from '@missionlabs/smartagent-app-components'

import './tab.scss'

const attributeReplacements: Map<string, string> = new Map()
    .set('sa-caseId', 'Case ID')
    .set('sa-profileId', 'Profile ID')

export type TabButtonInfo = {
    id: string
    text: string
    shouldRender: boolean
    iconSrc?: string
}

type Props = {
    tabsToGenerate: TabButtonInfo[]
    selectedTabId: string
    handleTabClick: (e: any) => void
}

const Tabs: React.FC<Props> = ({ tabsToGenerate, selectedTabId, handleTabClick }) => {
    const generateButtons = (tabsInfo: TabButtonInfo[]): ReactElement[] => {
        const tabs: ReactElement[] = []

        tabsInfo.forEach((buttonInfo) => {
            const { id, text, shouldRender, iconSrc } = buttonInfo
            if (!shouldRender) return

            let renderedText: string = text
            attributeReplacements.forEach((replacement, attributeToReplace) => {
                if (renderedText.includes(attributeToReplace)) {
                    renderedText = renderedText.replace(attributeToReplace, replacement)
                }
            })

            const isSelected = id === selectedTabId

            tabs.push(
                <Button
                    id={id}
                    key={id}
                    className={classNames({
                        'contact-history-tabs-tab': true,
                        'contact-history-tabs-tab-selected': isSelected,
                    })}
                    onClick={handleTabClick}
                    disabled={isSelected}
                >
                    {!!iconSrc?.length && (
                        <img
                            src={iconSrc}
                            className="contact-history-tabs-tab-icon"
                            alt="Channel icon"
                        />
                    )}
                    {renderedText}
                </Button>,
            )
        })

        return tabs
    }

    const tabs = generateButtons(tabsToGenerate)

    return tabs.length ? <div className="contact-history-tabs">{tabs}</div> : null
}

export default Tabs

import { Button } from '@missionlabs/smartagent-app-components'
import React, { useEffect, useRef, useState } from 'react'
import styles from './SelectedOutboundQueue.module.scss'

interface Props {
    queueName?: string
    onCloseButtonClick: () => void
}

const SelectedOutboundQueue = ({ queueName, onCloseButtonClick }: Props) => {
    const wrapperRef: React.RefObject<HTMLDivElement> = useRef(null)
    const [screenReaderOnlyText, setScreenReaderOnlyText] = useState<null | string>(null)

    useEffect(() => {
        if (queueName) {
            setScreenReaderOnlyText(null)
            setTimeout(() => {
                wrapperRef.current?.focus()
            }, 0)
        }
    }, [queueName])

    const onClose = () => {
        onCloseButtonClick()
        setScreenReaderOnlyText('Outbound CLI cleared')
    }

    return (
        <>
            {queueName && (
                <div tabIndex={-1} className={styles.wrapper} ref={wrapperRef}>
                    <p>From:</p>

                    <div className={styles.innerWrapper}>
                        <div className={styles.textWrapper}>
                            <p className={styles.text}>{queueName}</p>
                        </div>

                        <Button
                            styling="secondary"
                            title="Clear"
                            onClick={onClose}
                            className={styles.button}
                            aria-label="Clear outbound CLI"
                        >
                            Clear
                        </Button>
                    </div>
                </div>
            )}

            <span tabIndex={-1} className={styles.screenReaderOnly} aria-live="polite">
                {screenReaderOnlyText}
            </span>
        </>
    )
}

export default SelectedOutboundQueue

import PhoneLib from 'google-libphonenumber'
import useUser from 'hooks/redux/useUser'
import useInterval from 'hooks/useInterval'
import useRestructuredDirectoryContacts from 'hooks/useRestructuredDirectoryContacts'
import useSmartAgentAPI from 'hooks/useSmartAgentAPI'
import { useMemo } from 'react'
import { getRealTimeContacts } from 'services/api/api.contact'
import { formatPhoneNumber, getDirectoryContactByPhoneNumber, getIdFromARN, isEmpty } from 'utils'

export default function useRealTimeContactList(queueFilter: string[], refresh?: boolean) {
    const [getContactsError, getContactsLoading, getContactsResponse, reloadContacts] =
        useSmartAgentAPI(async (state) =>
            getRealTimeContacts(state.app.ID, state.app.instance!.ID, state.auth.token!),
        )

    const { queues, defaultOutboundQueueID } = useUser() ?? {}

    const [restrContacts, areDirectoryContactsLoading] = useRestructuredDirectoryContacts()

    const currentContacts = useMemo(() => {
        const realTimeContacts = getContactsResponse?.data

        if (!realTimeContacts) {
            return undefined
        }

        const routingProfileQueueIds = queues?.map((q) => getIdFromARN(q.queueARN))

        const matches = realTimeContacts
            .filter((rt) => rt.State !== 'ENDED' && rt.State !== 'ERROR') // Not in invalid state
            .filter((rt) => (!!queueFilter.length ? queueFilter.includes(rt.Queue.ARN) : true)) // filter if we're asking for specific queue contacts
            .filter(
                (rt) =>
                    rt.QueueId === defaultOutboundQueueID ||
                    (!!routingProfileQueueIds?.length
                        ? routingProfileQueueIds.includes(rt.QueueId)
                        : true),
            ) // filter out all contacts NOT in our routing profile queues
            .map((rt) => {
                if (isEmpty(restrContacts)) return rt

                const rtPhone = formatPhoneNumber(
                    rt.CustomerEndpoint?.Address,
                    PhoneLib.PhoneNumberFormat.E164,
                )

                const directoryRecord = getDirectoryContactByPhoneNumber(restrContacts, rtPhone)

                if (directoryRecord) return { ...rt, directoryRecord }

                return rt
            })
            .sort((rt1, rt2) => {
                return (rt1.ParkedTimestamp ?? rt1.EnqueueTimestamp) >
                    (rt2.ParkedTimestamp ?? rt2.EnqueueTimestamp)
                    ? 1
                    : -1
            })

        return matches
    }, [restrContacts, getContactsResponse, queueFilter, queues])

    useInterval(() => {
        if (refresh) reloadContacts?.()
    }, 5000)

    return {
        error: getContactsError,
        loading: getContactsLoading || areDirectoryContactsLoading,
        currentContacts,
        reloadContacts,
    }
}

import type { ThunkDispatch } from '@reduxjs/toolkit'
import * as AgentsAssistAPI from 'services/api/api.agentAssistance'
import * as NotificationActions from 'store/notification/notification.reducer'
import RootState from 'store/state'

import * as AgentsAssistanceActions from './agentsAssistance.reducer'
import AgentsAssistanceState from './agentsAssistance.state'

export function getAgentsAssistByAgent() {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            AgentsAssistanceActions.AgentsAssistanceAction
        >,
        getState: () => RootState,
    ) => {
        const { auth, app, user } = getState()
        const companyID = app.ID
        const instanceID = app.instance?.ID
        const agentID = user?.agentID
        const token = auth.token

        try {
            if (!instanceID) throw new Error('Instance ID not found')
            if (!agentID) throw new Error('Agent ID not found')
            if (!token) throw new Error('Token not found')

            const response = await AgentsAssistAPI.getAgentAssistByAgent(
                companyID,
                instanceID,
                agentID,
                token,
            )
            if (response !== undefined) {
                dispatch(AgentsAssistanceActions.setAgentsAssistanceState(response))
            }
        } catch (error) {
            console.error('Error getting agents assist by agent : ', error)
        }
    }
}

export function updateAgentsAssist(
    newAgentsAssist: AgentsAssistanceState,
    isNewAssistEntry: boolean,
) {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            AgentsAssistanceActions.AgentsAssistanceAction | NotificationActions.NotificationAction
        >,
        getState: () => RootState,
    ) => {
        const { auth, app } = getState()
        const companyID = app.ID
        const instanceID = app.instance!.ID
        const token = auth.token!
        newAgentsAssist.instanceID = instanceID
        try {
            if (isNewAssistEntry) {
                await AgentsAssistAPI.addNewAgentAssist(
                    companyID,
                    instanceID,
                    newAgentsAssist,
                    token,
                )
            } else {
                await AgentsAssistAPI.updateAgentAssist(
                    companyID,
                    instanceID,
                    newAgentsAssist,
                    token,
                )
            }

            // reset status bar state to default if we're sending up an update to resolve - this will only affect agents
            if (isNewAssistEntry) {
                dispatch(AgentsAssistanceActions.setAgentsAssistanceState(newAgentsAssist))
            } else {
                dispatch(AgentsAssistanceActions.resetAgentsAssistanceState())
            }
        } catch (error) {
            console.error('Error adding/updating agents assist: ', error)

            dispatch(
                NotificationActions.createNotification({
                    type: 'error',
                    header: 'Error',
                    text: 'There was a problem updating the hands up status. Please refresh and try again.',
                }),
            )
        }
    }
}

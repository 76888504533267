import type { ThunkDispatch } from '@reduxjs/toolkit'
import { IEmailAttachment } from 'components/EmailMessage/types'
import { getActionAudit } from 'services/api/api.automated-rules'
import {
    getChatTranscript,
    getContact,
    getContactAudit,
    getContacts,
    getContactsById,
    getEventTraceRecords,
} from 'services/api/api.contact'
import { getDownloadURL } from 'services/api/api.thread'
import { AppFeatures } from 'store/app/app.features'
import ContactState from 'store/contact/contact.state'
import RootState from 'store/state'
import { paramsToQueryString } from 'utils'
import { ChannelType, IInteraction, InteractionMessage } from 'views/Metrics/HistoricMetrics/types'
import {
    getThreadMessageGroups,
    mergeAndMapData,
    transformContacts,
    transformTaskContacts,
} from 'views/Metrics/HistoricMetrics/utils'

import * as ContactHistoryActions from './contactHistory.reducer'
import {
    constructQueryStringByChannel,
    getContactHistoryIdsFromOtherParties,
    isVoiceContactWithoutPhoneNumber,
    sortContactHistoryInteractions,
    transformMessageContent,
} from './contactHistory.utils'

import type { SocialChatJsonMessage } from '@missionlabs/smartagent-service-chat/dist/types/socialChatJsonMessage'

export const {
    removeContactHistory,
    selectInteraction,
    sortInteractions,
    setViewPreviousEmailMessages,
    deselectContactHistoryInstance,
} = ContactHistoryActions

export function fetchContactHistoryByChannel(contact: ContactState, channelType: ChannelType) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, ContactHistoryActions.ContactHistoryAction>,
        getState: () => RootState,
    ) => {
        const { auth, app, contactHistory, call } = getState()
        const companyID = app.ID
        const instanceID = app.instance?.ID
        const token = auth.token

        if (!instanceID || !token || isVoiceContactWithoutPhoneNumber(contact)) return

        const instanceExists = contactHistory.some(
            (item) =>
                item.attributeSearchName === channelType &&
                item.attributeSearchValue === contact.ID,
        )

        if (instanceExists) {
            dispatch(
                ContactHistoryActions.setSelectedInstance({
                    attributeSearchName: channelType,
                    attributeSearchValue: contact.ID,
                }),
            )
            return
        }

        dispatch(
            ContactHistoryActions.addContactHistory({
                attributeSearchName: channelType,
                attributeSearchValue: contact.ID,
                interactions: [],
            }),
        )

        try {
            const hasRestrictQueueAccessFeature = !!app.features.find(
                (feature) => feature.ID === AppFeatures.RESTRICT_QUEUE_ACCESS,
            )
            const queryString = constructQueryStringByChannel(
                contact,
                channelType,
                hasRestrictQueueAccessFeature,
            )
            const contactsResponse = queryString
                ? (await getContacts(companyID, instanceID, token, queryString))?.data
                : undefined

            const etrResponse =
                contact?.attributes['sa-sub-channel'] === 'SMS-DM'
                    ? await getEventTraceRecords(queryString)
                    : undefined

            if (contactsResponse && channelType === 'TASK') {
                const threadMessageGroups = await getThreadMessageGroups(contactsResponse)
                const otherPartiesContactId = getContactHistoryIdsFromOtherParties(
                    threadMessageGroups,
                    contactsResponse,
                )
                if (otherPartiesContactId.length === 0) {
                    const interactions = transformTaskContacts(
                        contactsResponse,
                        threadMessageGroups,
                    )
                    dispatch(
                        ContactHistoryActions.setInteractions({
                            attributeSearchName: channelType,
                            attributeSearchValue: contact.ID,
                            interactions,
                        }),
                    )
                    return
                }
                const otherPartiesContact = await getContactsById(
                    otherPartiesContactId,
                    hasRestrictQueueAccessFeature,
                )
                const contacts = contactsResponse.concat(otherPartiesContact?.data)
                const interactions = transformTaskContacts(contacts, threadMessageGroups)
                dispatch(
                    ContactHistoryActions.setInteractions({
                        attributeSearchName: channelType,
                        attributeSearchValue: contact.ID,
                        interactions,
                    }),
                )
                return
            }

            const interactions = mergeAndMapData(contactsResponse, etrResponse)

            dispatch(
                ContactHistoryActions.setInteractions({
                    attributeSearchName: channelType,
                    attributeSearchValue: contact.ID,
                    interactions: interactions || [],
                }),
            )
        } catch (error) {
            console.error('Error getting contact history: ', error)
            dispatch(
                ContactHistoryActions.setInteractions({
                    attributeSearchName: channelType,
                    attributeSearchValue: contact.ID,
                    interactions: [],
                }),
            )
        }
    }
}

export function fetchContactHistoryByCustomAttribute(
    attributeSearchName: string,
    attributeSearchValue: string,
    displayLabel?: string,
) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, ContactHistoryActions.ContactHistoryAction>,
        getState: () => RootState,
    ) => {
        const { auth, app, contactHistory } = getState()
        const companyID = app.ID
        const instanceID = app.instance?.ID
        const token = auth.token

        if (!instanceID || !token) return

        const instanceExists = contactHistory.some(
            (item) =>
                item.attributeSearchName === attributeSearchName &&
                item.attributeSearchValue === attributeSearchValue,
        )

        if (instanceExists) {
            dispatch(
                ContactHistoryActions.setSelectedInstance({
                    attributeSearchName,
                    attributeSearchValue,
                }),
            )
            return
        }

        dispatch(
            ContactHistoryActions.addContactHistory({
                attributeSearchName,
                attributeSearchValue,
                interactions: [],
                displayLabel,
            }),
        )

        try {
            const queryString = paramsToQueryString({ attributeSearchName, attributeSearchValue })
            const response = queryString
                ? await getContacts(companyID, instanceID, token, queryString)
                : undefined
            if (!response) {
                dispatch(
                    ContactHistoryActions.setInteractions({
                        attributeSearchName,
                        attributeSearchValue,
                        interactions: [],
                    }),
                )
                return
            }

            const dataNotTaskChannel: ContactState[] = []
            const dataIsTaskChannel: ContactState[] = []
            response.data.forEach((contactState) => {
                contactState.channel === 'TASK'
                    ? dataIsTaskChannel.push(contactState)
                    : dataNotTaskChannel.push(contactState)
            })

            if (dataIsTaskChannel.length) {
                const threadMessageGroups = await getThreadMessageGroups(dataIsTaskChannel)
                const otherPartiesContactId = getContactHistoryIdsFromOtherParties(
                    threadMessageGroups,
                    dataIsTaskChannel,
                )

                if (otherPartiesContactId.length === 0) {
                    const interactionsNotTasks = transformContacts(dataNotTaskChannel)
                    const interactionsTasks = transformTaskContacts(
                        dataIsTaskChannel,
                        threadMessageGroups,
                    )
                    const interactions = sortContactHistoryInteractions(
                        interactionsNotTasks.concat(interactionsTasks),
                        'date-time-asc',
                    )

                    dispatch(
                        ContactHistoryActions.setInteractions({
                            attributeSearchName,
                            attributeSearchValue,
                            interactions,
                        }),
                    )
                    return
                }

                const otherPartiesContact = await getContactsById(otherPartiesContactId, false)
                const contacts = dataIsTaskChannel.concat(otherPartiesContact?.data)
                const interactionsNotTasks = transformContacts(dataNotTaskChannel)
                const interactionsTasks = transformTaskContacts(contacts, threadMessageGroups)
                const interactions = sortContactHistoryInteractions(
                    interactionsNotTasks.concat(interactionsTasks),
                    'date-time-asc',
                )

                dispatch(
                    ContactHistoryActions.setInteractions({
                        attributeSearchName,
                        attributeSearchValue,
                        interactions,
                    }),
                )
                return
            }

            const interactions = transformContacts(response.data)
            dispatch(
                ContactHistoryActions.setInteractions({
                    attributeSearchName,
                    attributeSearchValue,
                    interactions,
                }),
            )
        } catch (error) {
            console.error('Error getting contact history: ', error)
            dispatch(
                ContactHistoryActions.setInteractions({
                    attributeSearchName,
                    attributeSearchValue,
                    interactions: [],
                }),
            )
        }
    }
}

export function fetchContactHistoryInteractionTranscript(
    interaction: IInteraction,
    attributeSearchName: string,
    attributeSearchValue: string,
) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, ContactHistoryActions.ContactHistoryAction>,
        getState: () => RootState,
    ) => {
        const { auth, app } = getState()
        const companyID = app.ID
        const instanceID = app.instance?.ID
        const token = auth.token

        if (!instanceID || !token) return

        try {
            const response = await getChatTranscript(companyID, instanceID, token, interaction.ID)
            if (!response) {
                dispatch(
                    ContactHistoryActions.addInteractionTranscript({
                        attributeSearchName,
                        attributeSearchValue,
                        interactionId: interaction.ID,
                        transcript: [],
                    }),
                )
                return
            }

            const transcripts: connect.ChatTranscriptItem[] = response.data.Transcript

            const transcriptsAsChatMessages: InteractionMessage[] = []

            if (transcripts) {
                const transcriptsFilteredAndSorted = transcripts
                    .filter(
                        (transcript) =>
                            transcript?.Type === 'MESSAGE' || transcript?.Type === 'ATTACHMENT',
                    )
                    .sort(
                        (a, b) =>
                            new Date(a.AbsoluteTime).getTime() - new Date(b.AbsoluteTime).getTime(),
                    )

                transcriptsFilteredAndSorted.forEach((transcript) => {
                    const interactionLanguage: string | undefined =
                        interaction.attributes['sa-language']

                    // Only attempt to transform the content of the transcript if available, as this is not always
                    // the case depending on the transcript type (e.g. MESSAGE transcripts do, ATTACHMENT transcripts do not)
                    const message = transcript.Content
                        ? transformMessageContent(transcript.Content, interactionLanguage)
                        : ''

                    transcriptsAsChatMessages.push({
                        ...transcript,
                        content: message as SocialChatJsonMessage,
                    })
                })
            }

            dispatch(
                ContactHistoryActions.addInteractionTranscript({
                    attributeSearchName,
                    attributeSearchValue,
                    interactionId: interaction.ID,
                    transcript: transcriptsAsChatMessages,
                }),
            )
        } catch (error) {
            console.error('Error getting interaction transcript: ', error)
            dispatch(
                ContactHistoryActions.addInteractionTranscript({
                    attributeSearchName,
                    attributeSearchValue,
                    interactionId: interaction.ID,
                    transcript: [],
                }),
            )
        }
    }
}

export function fetchAutomatedRulesAudit(
    attributeSearchName: string,
    attributeSearchValue: string,
    interaction: IInteraction,
) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, ContactHistoryActions.ContactHistoryAction>,
        getState: () => RootState,
    ) => {
        const { app, auth } = getState()
        const companyID = app.ID
        const instanceID = app.instance?.ID
        const token = auth.token
        if (!instanceID || !token) return

        const emailMessage = interaction?.emailMessages?.find(
            (emailMessage) => emailMessage.messageId === interaction.messageId,
        )

        if (!emailMessage?.actionAuditId) {
            dispatch(
                ContactHistoryActions.addAutomatedRulesAudit({
                    attributeSearchName,
                    attributeSearchValue,
                    interactionId: interaction.ID,
                    automatedRulesAudit: [],
                }),
            )
            return
        }

        try {
            const response = await getActionAudit(
                companyID,
                instanceID,
                token,
                emailMessage.actionAuditId,
            )

            dispatch(
                ContactHistoryActions.addAutomatedRulesAudit({
                    attributeSearchName,
                    attributeSearchValue,
                    interactionId: interaction.ID,
                    automatedRulesAudit: response?.data || [],
                }),
            )
        } catch (error) {
            console.error('Error getting automated rules audit data: ', error)
            dispatch(
                ContactHistoryActions.addAutomatedRulesAudit({
                    attributeSearchName,
                    attributeSearchValue,
                    interactionId: interaction.ID,
                    automatedRulesAudit: [],
                }),
            )
        }
    }
}

export function fetchQueuedTasksAudit(
    attributeSearchName: string,
    attributeSearchValue: string,
    interaction: IInteraction,
) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, ContactHistoryActions.ContactHistoryAction>,
        getState: () => RootState,
    ) => {
        const { auth, app } = getState()
        const companyID = app.ID
        const instanceID = app.instance?.ID
        const token = auth.token
        if (!instanceID || !token) return

        try {
            const response = await getContactAudit(companyID, instanceID, token, interaction.ID)

            dispatch(
                ContactHistoryActions.addQueuedTasksAudit({
                    attributeSearchName,
                    attributeSearchValue,
                    interactionId: interaction.ID,
                    queuedTasksAudit: response?.data || [],
                }),
            )
        } catch (error) {
            console.error('Error getting queued tasks audit data: ', error)
            dispatch(
                ContactHistoryActions.addQueuedTasksAudit({
                    attributeSearchName,
                    attributeSearchValue,
                    interactionId: interaction.ID,
                    queuedTasksAudit: [],
                }),
            )
        }
    }
}

export function fetchAttachmentUrls(
    attributeSearchName: string,
    attributeSearchValue: string,
    attachments: IEmailAttachment[],
    messageId: string,
    threadId: string,
) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, ContactHistoryActions.ContactHistoryAction>,
        getState: () => RootState,
    ) => {
        const { auth, app } = getState()
        const instanceID = app.instance?.ID
        const token = auth.token
        if (!instanceID || !token) return

        try {
            const newAttachments: IEmailAttachment[] = await Promise.all(
                attachments.map(async (attachment) => {
                    const { s3Key, filename } = attachment
                    let url = ''
                    try {
                        url = (await getDownloadURL(threadId, messageId, encodeURI(s3Key))).url
                    } catch (error) {
                        console.error(`Error retrieving URL for ${s3Key}/${filename}: ${error}`)
                    }

                    return { ...attachment, s3URL: url }
                }),
            )
            dispatch(
                ContactHistoryActions.addTransformedAttachments({
                    attributeSearchName,
                    attributeSearchValue,
                    messageId,
                    transformedAttachments: newAttachments,
                }),
            )
        } catch (error) {
            console.error('Error getting attachment S3 urls:', error)
            dispatch(
                ContactHistoryActions.addTransformedAttachments({
                    attributeSearchName,
                    attributeSearchValue,
                    messageId,
                    transformedAttachments: attachments,
                }),
            )
        }
    }
}

export function fetchContactHistoryContact(
    attributeSearchName: string,
    attributeSearchValue: string,
    interaction: IInteraction,
) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, ContactHistoryActions.ContactHistoryAction>,
    ) => {
        try {
            const fullInteraction = await getContact(interaction.ID)
            dispatch(
                selectInteraction({
                    attributeSearchName,
                    attributeSearchValue,
                    selectedInteraction: { ...interaction, ...fullInteraction },
                }),
            )
        } catch (error) {
            console.error('Error getting interaction:', error)
            dispatch(
                selectInteraction({
                    attributeSearchName,
                    attributeSearchValue,
                    selectedInteraction: interaction,
                }),
            )
        }
    }
}

import { makeRequest } from 'services/api'
import { IAgentAssist } from 'views/Metrics/RealTimeMetrics/AgentMetrics/types'

export async function getAllUnresolvedAgentAssist(
    companyID: string,
    instanceID: string,
    token: string,
) {
    const response = await makeRequest<IAgentAssist[]>({
        url: `/agents-assist-service/companies/${companyID}/instances/${instanceID}`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })
    return response.data
}

export async function getAgentAssist(
    companyID: string,
    instanceID: string,
    agentAssistID: string,
    token: string,
) {
    const response = await makeRequest<IAgentAssist>({
        url: `/agents-assist-service/companies/${companyID}/instances/${instanceID}/agent-assist/${agentAssistID}`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })
    return response.data
}

export async function getAgentAssistByAgent(
    companyID: string,
    instanceID: string,
    agentID: string,
    token: string,
) {
    const response = await makeRequest<IAgentAssist>({
        url: `/agents-assist-service/companies/${companyID}/instances/${instanceID}/agent/${agentID}`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })
    return response.data
}

export async function addNewAgentAssist(
    companyID: string,
    instanceID: string,
    agentAssist: IAgentAssist,
    token: string,
) {
    const response = await makeRequest<number>({
        url: `/agents-assist-service/companies/${companyID}/instances/${instanceID}/agent-assist/${agentAssist.ID}`,
        method: 'post',
        headers: {
            'X-Amz-Security-Token': token,
        },
        data: agentAssist,
    })
    return response.data
}

export async function updateAgentAssist(
    companyID: string,
    instanceID: string,
    updatedAgentAssist: IAgentAssist,
    token: string,
) {
    const response = await makeRequest<number>({
        url: `/agents-assist-service/companies/${companyID}/instances/${instanceID}/agent-assist/${updatedAgentAssist.ID}`,
        method: 'put',
        headers: {
            'X-Amz-Security-Token': token,
        },
        data: updatedAgentAssist,
    })
    return response.data
}

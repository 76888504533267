var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { jsx, jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { PureComponent } from "react";
class Message extends PureComponent {
  render() {
    return /* @__PURE__ */ jsx("div", { className: classNames({
      "sa-message": true,
      [this.props.type]: true
    }), children: /* @__PURE__ */ jsxs("div", { className: "sa-message-container", children: [
      /* @__PURE__ */ jsx("div", { className: classNames({
        "sa-message-inner": true,
        [this.props.type]: true
      }), children: this.props.children }),
      this.props.time ? /* @__PURE__ */ jsx("span", { className: "sa-message-time", children: this.props.time }) : null
    ] }) });
  }
}
__publicField(Message, "defaultProps", {
  type: "local"
});
export {
  Message as default
};

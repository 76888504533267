import axios from 'axios'
import { SIPgatewayConfig } from 'store/app/app.state'
import { matchPattern } from 'utils'

export async function sipGateway(
    SIPgatewayConfig: SIPgatewayConfig,
    number: string,
    token: string,
) {
    const { url } = SIPgatewayConfig
    const formatGatewayNumber = (number: string) => {
        SIPgatewayConfig.conditions.forEach((v) => {
            if (matchPattern(v.regexPattern, number)) {
                if (v.replaceValue && v.replaceWith)
                    number = number.replace(v.replaceValue, v.replaceWith)
                if (v.prefix) number = v.prefix + number
            }
        })
        return number
    }

    const { data } = await axios({
        url,
        method: 'post',
        data: {
            dialledE164: formatGatewayNumber(number),
        },
        headers: {
            'X-Amz-Security-Token': token,
        },
    })
    return data
}

import classNames from 'classnames'
import styles from './UnreadMessagesIndicator.module.scss'

interface Props {
    unreadMessages: number
}

const UnreadMessagesIndicator = ({ unreadMessages }: Props) => (
    <div className={styles.wrapper}>
        <div
            data-test-id="unreadMessagesIndicator"
            className={classNames(styles.counter, {
                [styles['counter--low']]: unreadMessages >= 1,
                [styles['counter--med']]: unreadMessages > 4,
                [styles['counter--high']]: unreadMessages > 7,
            })}
        >
            <span>{unreadMessages}</span>
        </div>
    </div>
)

export default UnreadMessagesIndicator

import { Loader, RouteWithPermissionsCheck } from 'components'
import { AppFeatures } from 'hooks/useHasFeature'
import React, { Suspense, lazy, useMemo } from 'react'
import { RouteComponentProps, Switch } from 'react-router-dom'
import Chat from 'views/Chat'

interface ComponentProps {}

type Props = RouteComponentProps<any> & ComponentProps

const ChatRoutes: React.FC<Props> = () => {
    const OutboundSMS = useMemo(() => lazy(() => import('views/Chat/OutboundSMS')), [])
    return (
        <section className="row">
            <Suspense
                fallback={
                    <div className="lazy-loading">
                        <Loader />
                    </div>
                }
            >
                <Switch>
                    <RouteWithPermissionsCheck
                        path={'/chat'}
                        exact
                        component={Chat}
                        appFeature={AppFeatures.CHAT}
                    />
                    <RouteWithPermissionsCheck
                        path={'/chat/outbound-sms'}
                        component={OutboundSMS}
                        appFeature={AppFeatures.OUTBOUND_SMS}
                    />
                </Switch>
            </Suspense>
        </section>
    )
}

export default ChatRoutes

import type { CallConnection, MonitorInfo, MonitorStatus } from 'store/call/call.state'

export const getMonitorInfo = (connection: connect.VoiceConnection): MonitorInfo => {
    let status: MonitorStatus | undefined

    if (connection.getType() === connect.ConnectionType.MONITORING) {
        status = 'SILENT_MONITOR'
    } else {
        status = connection.getMonitorStatus() || undefined
    }

    return { status }
}

export const createConnection = (
    connection: connect.VoiceConnection,
    phoneNumber: string,
): CallConnection => {
    const connectionStatusType = connection.getStatus().type
    const connectionStatusStart = !connection.isConnecting()
        ? new Date(Date.now() - connection.getStatusDuration())
        : undefined

    return {
        initialConnection: connection.isInitialConnection(),
        id: connection.getConnectionId(),
        activeConnection: connection.isActive(),
        status: connectionStatusType,
        number: phoneNumber,
        name: getConnectionName(connection),
        hold: connectionStatusType === connect.ConnectionStateType.HOLD,
        start: connectionStatusStart,
    }
}

export const getConnectionName = (connection: connect.VoiceConnection): string | undefined => {
    const isInitialConnection = connection.isInitialConnection()

    if (isInitialConnection) return undefined

    const { phoneNumber } = connection.getEndpoint()

    const finalPhoneNumber = phoneNumber !== 'INTERNAL-TRANSFER' ? phoneNumber : undefined

    return connection.getQuickConnectName() || finalPhoneNumber
}

export const getNonYouConnections = (
    connections: connect.VoiceConnection[],
): connect.VoiceConnection[] =>
    connections.filter(
        (connection) =>
            connection.getType() !== connect.ConnectionType.AGENT &&
            connection.getStatus().type !== connect.ConnectionStateType.DISCONNECTED,
    )

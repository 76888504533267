var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { jsxs, jsx } from "react/jsx-runtime";
import { useState, useEffect } from "react";
const RangeSlider = ({
  disabled,
  step,
  title,
  value,
  prefix,
  suffix,
  min,
  max,
  onChange
}) => {
  const [tempValue, setTempValue] = useState(void 0);
  const formattedValue = prefix === "£" ? value.toFixed(2) : value;
  return /* @__PURE__ */ jsxs("div", { className: "rangeSlider", children: [
    /* @__PURE__ */ jsxs("div", { className: "slider-label", children: [
      /* @__PURE__ */ jsx("span", { className: "title", children: title }),
      /* @__PURE__ */ jsxs("div", { className: "value", children: [
        prefix,
        /* @__PURE__ */ jsx("label", { "aria-label": title, children: /* @__PURE__ */ jsx(
          "input",
          {
            disabled,
            value: tempValue ?? formattedValue,
            onChange: (e) => setTempValue(e.target.value),
            onBlur: (e) => {
              setTempValue(void 0);
              const val = Number(e.target.value);
              if (isNaN(val) || val < min || val > max) {
                return;
              }
              onChange(Number(e.target.value));
            }
          }
        ) }),
        /* @__PURE__ */ jsx("span", { children: suffix })
      ] })
    ] }),
    /* @__PURE__ */ jsx("label", { "aria-label": title, className: "range-slider-label", "aria-hidden": "true", children: /* @__PURE__ */ jsx(
      "input",
      {
        tabIndex: -1,
        disabled,
        type: "range",
        min,
        max,
        value,
        step: step ?? 1,
        onChange: (e) => {
          onChange(Number(e.target.value));
        }
      }
    ) })
  ] });
};
class Token {
  constructor(type, value) {
    __publicField(this, "type");
    __publicField(this, "value");
    this.type = type;
    this.value = value;
  }
}
class ExpressionParser {
  constructor(insertVals = {}) {
    __publicField(this, "insertVals");
    __publicField(this, "prec", {
      "+": 2,
      "-": 2,
      "*": 3,
      "/": 3
    });
    this.insertVals = insertVals;
  }
  precedence(t) {
    return this.prec[t];
  }
  peek(arr) {
    return arr[arr.length - 1];
  }
  isDigitOrChar(ch) {
    return /[a-zA-Z0-9]/.test(ch);
  }
  isOperator(ch) {
    return /\+|-|\*|\/|\^/.test(ch);
  }
  isLeftParenth(ch) {
    return ch === "(";
  }
  isRightParenth(ch) {
    return ch === ")";
  }
  isDecimalPoint(ch) {
    return ch === ".";
  }
  tokenise(expr) {
    if (!expr)
      return [];
    const tokens = [];
    let buffer = [];
    const emptyBuffer = () => {
      if (buffer.length) {
        tokens.push(new Token("Literal", buffer.join("")));
        buffer = [];
      }
    };
    expr.split("").forEach((ch) => {
      if (this.isDigitOrChar(ch)) {
        buffer.push(ch);
      } else if (this.isDecimalPoint(ch)) {
        buffer.push(ch);
      } else if (this.isOperator(ch)) {
        if (buffer.length || tokens.length && this.peek(tokens).type === "RightParenth") {
          emptyBuffer();
          tokens.push(new Token("Operator", ch));
        } else {
          buffer.push(ch);
        }
      } else if (this.isLeftParenth(ch)) {
        emptyBuffer();
        tokens.push(new Token("LeftParenth", ch));
      } else if (this.isRightParenth(ch)) {
        emptyBuffer();
        tokens.push(new Token("RightParenth", ch));
      }
    });
    emptyBuffer();
    return tokens.map((token) => token.value);
  }
  parse(expr) {
    const tokens = this.tokenise(expr);
    const operators = [];
    const literals = [];
    tokens.forEach((t) => {
      switch (t) {
        case "-":
        case "/":
        case "*":
        case "+":
          while (["+", "-", "*", "/"].includes(this.peek(operators))) {
            if (this.precedence(this.peek(operators)) < this.precedence(t)) {
              break;
            } else {
              literals.push(operators.pop());
            }
          }
          operators.push(t);
          break;
        case "(":
          operators.push(t);
          break;
        case ")":
          while (this.peek(operators) !== "(")
            literals.push(operators.pop());
          operators.pop();
          break;
        default:
          literals.push(String(this.insertVals[t] ?? t));
          break;
      }
    });
    return literals.concat(operators.reverse());
  }
  evaluate(expr) {
    const postfix = this.parse(expr);
    if (!postfix || !postfix.length)
      return NaN;
    const stack = [];
    try {
      postfix.forEach((token) => {
        const e = Number(token);
        if (e === e)
          return stack.push(e);
        const op1 = stack.pop();
        const op2 = stack.pop();
        if (op1 === void 0 || op2 === void 0)
          throw new Error("Expression Invalid");
        switch (token) {
          case "+":
            stack.push(op2 + op1);
            break;
          case "-":
            stack.push(op2 - op1);
            break;
          case "/":
            stack.push(op2 / op1);
            break;
          case "*":
            stack.push(op2 * op1);
            break;
          default:
            throw new Error("Expression Invalid");
        }
      });
    } catch (Error2) {
      return NaN;
    }
    return stack.pop();
  }
}
const toFixed = (value) => {
  if (!value)
    return "0.00";
  return Number.isInteger(value) ? String(value.toFixed(2)) : value.toFixed(2);
};
const PaymentSliderCard = ({
  ariaId,
  sliders,
  calculated,
  buttons,
  startDisabled,
  sendMessage
}) => {
  const [disabled, setDisabled] = useState(!!startDisabled);
  const [sliderValues, setSliderValues] = useState({});
  useEffect(() => {
    setSliderValues(
      sliders.reduce(
        (acc, sl) => ({
          ...acc,
          [sl.id]: sl.low,
          [sl.id === "upfrontIn" ? "maxPayment" : "maxDuration"]: sl.high
        }),
        {}
      )
    );
  }, [sliders]);
  const [calculatedValues, setCalculatedValues] = useState({});
  useEffect(() => {
    const parser = new ExpressionParser(sliderValues);
    setCalculatedValues(
      calculated.reduce(
        (acc, c) => ({
          ...acc,
          [c.id]: parser.evaluate(c.expr)
        }),
        {}
      )
    );
  }, [sliderValues, calculated]);
  const roundAccurately = (number) => {
    return Math.round((number + Number.EPSILON) * 100) / 100;
  };
  const formattedUpfrontPayment = (value) => {
    const { durationIn, maxPayment } = sliderValues;
    const roundedMonthlyPayments = roundAccurately(calculatedValues.monthlyPaymentsOut);
    const totalPaymentAfterCalculation = value + roundedMonthlyPayments * durationIn;
    const difference = roundAccurately(totalPaymentAfterCalculation - maxPayment);
    if (totalPaymentAfterCalculation > maxPayment) {
      return value -= Math.abs(difference);
    } else if (totalPaymentAfterCalculation < maxPayment) {
      return value += Math.abs(difference);
    } else {
      return value;
    }
  };
  return /* @__PURE__ */ jsxs("div", { id: ariaId, role: "region", "aria-label": "Payment options", className: "sa-slider-card", children: [
    /* @__PURE__ */ jsxs("div", { className: "sa-slider-card-sliders", children: [
      sliders.map((sl) => {
        var _a, _b, _c;
        return /* @__PURE__ */ jsx(
          RangeSlider,
          {
            disabled,
            title: sl.label,
            step: sl.step,
            min: sl.low,
            max: sl.high,
            prefix: ((_a = sl.unit) == null ? void 0 : _a.prefix) ? sl.unit.label : "",
            suffix: ((_b = sl.unit) == null ? void 0 : _b.prefix) ? "" : (_c = sl.unit) == null ? void 0 : _c.label,
            value: sliderValues[sl.id] ?? sl.low,
            onChange: (v) => setSliderValues((sv) => ({
              ...sv,
              [sl.id]: v
            }))
          },
          sl.id
        );
      }),
      calculated.map((calcul) => {
        var _a, _b, _c, _d;
        return /* @__PURE__ */ jsxs(
          "p",
          {
            className: "calculated-text",
            tabIndex: 0,
            "aria-label": `${calcul.label}, ${calcul.id === "upfrontOut" ? toFixed(formattedUpfrontPayment(calculatedValues == null ? void 0 : calculatedValues[calcul.id])) : toFixed(calculatedValues == null ? void 0 : calculatedValues[calcul.id])} ${((_a = calcul.unit) == null ? void 0 : _a.label) === "£" ? "pounds" : ""}`,
            children: [
              calcul.label,
              /* @__PURE__ */ jsxs("span", { className: "price", children: [
                !!((_b = calcul.unit) == null ? void 0 : _b.prefix) && calcul.unit.label,
                calcul.id === "upfrontOut" ? toFixed(formattedUpfrontPayment(calculatedValues == null ? void 0 : calculatedValues[calcul.id])) : toFixed(calculatedValues == null ? void 0 : calculatedValues[calcul.id]),
                !((_c = calcul.unit) == null ? void 0 : _c.prefix) && " " + ((_d = calcul.unit) == null ? void 0 : _d.label)
              ] })
            ]
          },
          calcul.id
        );
      })
    ] }),
    buttons.map((b, i) => /* @__PURE__ */ jsx(
      "button",
      {
        disabled,
        className: `btn-${i + 1}`,
        onClick: () => {
          const values = { ...sliderValues, ...calculatedValues };
          sendMessage(
            b.payload.split(" ").map((word) => {
              var _a;
              const placeholderWordRegex = /{(\w+)}/;
              const placeholderWordFound = (_a = word.match(placeholderWordRegex)) == null ? void 0 : _a[1];
              if (placeholderWordFound) {
                const replaceWith = toFixed(values[placeholderWordFound]) ?? word;
                return word.replace(
                  placeholderWordRegex,
                  String(replaceWith)
                );
              }
              return word;
            }).join(" ")
          );
          setDisabled(true);
        },
        children: b.label
      },
      `${b.label}-i`
    ))
  ] });
};
export {
  PaymentSliderCard as default
};

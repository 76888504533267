import { jsx } from "react/jsx-runtime";
import ReactMarkdown from "react-markdown";
const MarkdownWrapper = ({ children }) => {
  return /* @__PURE__ */ jsx(
    ReactMarkdown,
    {
      components: {
        a: ({ node, ...props }) => /* @__PURE__ */ jsx(
          "a",
          {
            target: "_blank",
            rel: "noopener noreferrer",
            ...props,
            children: props.children
          }
        )
      },
      children
    }
  );
};
export {
  MarkdownWrapper as M
};

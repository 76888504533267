import { jsxs, jsx } from "react/jsx-runtime";
import classNames from "classnames";
import "react";
const AlertIcon = "data:image/svg+xml,%3csvg%20width='20'%20height='20'%20viewBox='0%200%2020%2020'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20fill-rule='evenodd'%20clip-rule='evenodd'%20d='M10.7757%201.33241C10.4408%200.667842%209.55915%200.667842%209.22423%201.33241L0.109144%2018.5863C-0.212037%2019.2236%200.214153%2020.0007%200.884885%2020.0007H19.1151C19.7858%2020.0007%2020.212%2019.2236%2019.8909%2018.5863L10.7757%201.33241ZM9.64491%2014.6666C9.5348%2014.6666%209.44396%2014.634%209.37239%2014.5689C9.30082%2014.5038%209.26503%2014.4211%209.26503%2014.3209V6.95662C9.26503%206.84641%209.30082%206.76124%209.37239%206.70113C9.44396%206.64101%209.5348%206.61095%209.64491%206.61095H10.3551C10.4652%206.61095%2010.5561%206.64351%2010.6276%206.70864C10.6992%206.77377%2010.735%206.85643%2010.735%206.95662V14.3209C10.735%2014.4211%2010.6992%2014.5038%2010.6276%2014.5689C10.5561%2014.634%2010.4652%2014.6666%2010.3551%2014.6666H9.64491ZM9.22374%2017.0337C9.29531%2017.0988%209.38615%2017.1314%209.49626%2017.1314H10.4873C10.5974%2017.1314%2010.691%2017.0988%2010.768%2017.0337C10.8451%2016.9686%2010.8837%2016.8859%2010.8837%2016.7857V15.8689C10.8837%2015.7687%2010.8451%2015.6861%2010.768%2015.621C10.691%2015.5558%2010.5974%2015.5233%2010.4873%2015.5233H9.49626C9.38615%2015.5233%209.29531%2015.5558%209.22374%2015.621C9.15217%2015.6861%209.11638%2015.7687%209.11638%2015.8689V16.7857C9.11638%2016.8859%209.15217%2016.9686%209.22374%2017.0337Z'%20fill='white'/%3e%3c/svg%3e";
const TickIcon = "data:image/svg+xml,%3csvg%20width='20'%20height='20'%20viewBox='0%200%2020%2020'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M9.99992%200.833313C15.0625%200.833313%2019.1666%204.93737%2019.1666%209.99998C19.1666%2015.0626%2015.0625%2019.1666%209.99992%2019.1666C4.93731%2019.1666%200.833252%2015.0626%200.833252%209.99998C0.833252%204.93737%204.93731%200.833313%209.99992%200.833313ZM13.0723%206.8544L8.58842%2011.3383L6.92755%209.67739C6.67721%209.42706%206.27134%209.42706%206.021%209.67739C5.77067%209.92773%205.77067%2010.3336%206.021%2010.5839L8.58842%2013.1514L13.9788%207.76094C14.2292%207.51061%2014.2292%207.10473%2013.9788%206.8544C13.7285%206.60406%2013.3226%206.60406%2013.0723%206.8544Z'%20fill='white'/%3e%3c/svg%3e";
const getAlertBannerIcon = (type) => {
  switch (type) {
    case "info":
      return AlertIcon;
    case "error":
      return AlertIcon;
    case "success":
      return TickIcon;
    default:
      return AlertIcon;
  }
};
const alertTypeAltMapping = {
  info: "Information alert icon",
  success: "Success alert icon",
  error: "Error alert icon"
};
const AlertBanner = ({ type = "info", icon, iconAlt, children }) => {
  const alertIcon = icon ?? getAlertBannerIcon(type);
  const alertIconAlt = iconAlt ?? alertTypeAltMapping[type];
  const alertBannerIconClass = classNames("sa-alert-banner--icon", type);
  return /* @__PURE__ */ jsxs("div", { className: "sa-alert-banner", children: [
    /* @__PURE__ */ jsx("div", { className: alertBannerIconClass, children: /* @__PURE__ */ jsx("img", { src: alertIcon, alt: alertIconAlt }) }),
    /* @__PURE__ */ jsx("div", { role: "alert", className: "sa-alert-banner--content", children })
  ] });
};
export {
  AlertBanner as default
};

import { useSelector } from 'react-redux'
import RootState from 'store/state'

export default function usePermission() {
    const agentRoles = useSelector<RootState, string[]>((state) => state.auth.roles ?? [])
    const hasRole = (role: string | string[]): boolean => {
        return Array.isArray(role)
            ? !!role.find((r) => agentRoles.includes(r))
            : agentRoles.includes(role)
    }

    return hasRole
}

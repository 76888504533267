import { formatDateTime } from '@missionlabs/smartagent-app-components'
import type { SATableColumn } from '@missionlabs/smartagent-app-components/dist/Table'
import type { IAutomatedActionAudit } from '@missionlabs/smartagent-service-thread/dist/entities/action-audit'

export const automatedRulesColumns: SATableColumn[] = [
    {
        name: 'Rule Name',
        value: 'ruleTitle',
        sort: true,
    },
    {
        name: 'Action',
        value: '*',
        sort: true,
        render: (audit: IAutomatedActionAudit): string => {
            const actions: string[] = []
            if (audit.reply) actions.push('Reply')
            if (audit.move) actions.push('Move')
            if (audit.end) actions.push('End')
            return actions.join(', ') || 'No actions found'
        },
    },
    // In Contact History designs, but doesn't currently exist as of 08/02/23.
    // However, Neptune are working on this.
    // {
    //     name: 'New Queue',
    //     value: 'TODO',
    //     sort: true,
    // },
    {
        name: 'Date',
        value: 'created',
        sort: true,
        isDate: true,
        render: (timestamp: number): string =>
            formatDateTime(timestamp, { date: true, time: true }),
    },
]

export const queuedTasksColumns: SATableColumn[] = [
    {
        name: 'Action',
        value: 'actionType',
        sort: true,
    },
    {
        name: 'New Queue',
        value: 'queueName',
        sort: true,
    },
    {
        name: 'Agent Name',
        value: 'agentName',
        sort: true,
    },
    {
        name: 'Reason',
        value: 'reasonCode',
        sort: true,
        defaultValue: 'No reason found',
    },
    {
        name: 'Date',
        value: 'timestamp',
        sort: true,
        isDate: true,
        render: (timestamp: number): string =>
            formatDateTime(timestamp, { date: true, time: true }),
    },
]

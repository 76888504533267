import { MetricConfigData, MetricsGroups } from 'store/metrics/metrics.state'
import { secondsToTime } from 'utils'

import { SATableColumn } from '@missionlabs/smartagent-app-components/dist/Table'

export const mergeRealtimeConfigColumns = (
    metricsGroups: MetricsGroups[] = [],
): SATableColumn[] => {
    const columns: SATableColumn[] = [...metricsGroups]
        .sort((a, b) => a.groupOrder - b.groupOrder)
        .reduce<SATableColumn[]>(
            (acc, metricGroup) =>
                acc.concat(
                    [...metricGroup.metrics]
                        .sort((a, b) => (a.order > b.order ? 1 : 0))
                        .reduce(
                            (acc: SATableColumn[], metricConfig) =>
                                acc.concat({
                                    name: metricConfig?.clientMetricName,
                                    value: metricConfig?.connectConfig?.Name,
                                    sort: true,
                                    render: (metric: MetricConfigData) => {
                                        const value = metric?.Value || 0
                                        const formattedValue = metricConfig?.format
                                            ? metricValueFormat[metricConfig?.format](value)
                                            : value
                                        const status = metric?.Status
                                        return status ? (
                                            <span className={status}>{formattedValue}</span>
                                        ) : (
                                            formattedValue
                                        )
                                    },
                                }),
                            [],
                        ),
                ),
            [],
        )

    return [
        {
            name: 'Queue',
            value: 'queueName',
            sort: true,
        },
        ...columns,
    ]
}

type Formatter =
    | ((value: string | number) => string)
    | ((value: number) => string)
    | ((value: number) => number)

const metricValueFormat: Record<string, Formatter> = {
    '0': (value: number) => +value.toFixed(0),
    '0.0%': (value: number | string) =>
        typeof value === 'number' ? value.toFixed(1) + '%' : value,
    'hh:mm:ss': secondsToTime,
}

import { ContactPhoneNumber, TeamsContactStatus } from 'components'
import { IPhoneNumber } from 'store/directory/directory.state'
import { ITeamsRecord } from 'store/directory/teams/state'
import { constructJobInfo, constructName, isEmpty, isFunction, isNil } from 'utils'

import { ReactComponent as TeamsIcon } from '../../images/teams-icon.svg'
import { getFirstPhoneNumber } from '../ShortCardContainer/utils'
import styles from './styles.module.scss'

interface Props {
    firstName?: string
    lastName?: string
    role?: string
    department?: string
    phoneNumbers?: IPhoneNumber[]
    statusInfo?: ITeamsRecord['statusInfo']
    isDialButtonShown?: boolean
    isFullProfileExpanded?: boolean
    onClick?: () => void
    onKeyPress?: () => void
}

const TeamsShortCard = ({
    lastName,
    firstName,
    role,
    department,
    phoneNumbers = [],
    statusInfo,
    isDialButtonShown,
    isFullProfileExpanded,
    onClick,
    onKeyPress,
}: Props) => (
    <>
        {(!isNil(firstName) || !isNil(lastName)) && (
            <div
                tabIndex={0}
                role="button"
                className={styles.wrapper}
                onClick={isFunction(onClick) ? onClick : undefined}
                onKeyPress={isFunction(onKeyPress) ? onKeyPress : undefined}
            >
                <TeamsIcon className={styles.icon} />

                <div className={styles.contactInfo}>
                    <div className={styles.name}>{constructName(firstName, lastName)}</div>

                    {statusInfo?.availability && (
                        <div className={styles.status}>
                            <TeamsContactStatus statusInfo={statusInfo} />
                        </div>
                    )}

                    {(role || department) && (
                        <div className={styles.jobInfo}>
                            <span>{constructJobInfo(role, department)}</span>
                        </div>
                    )}

                    {phoneNumbers && !isEmpty(phoneNumbers) && (
                        <ContactPhoneNumber
                            phoneNumber={getFirstPhoneNumber(phoneNumbers)}
                            additionalNumbersAmount={phoneNumbers!.length - 1}
                            showMoreNumbersSign={!isFullProfileExpanded && phoneNumbers!.length > 1}
                            showButton={isDialButtonShown}
                        />
                    )}
                </div>
            </div>
        )}
    </>
)

export default TeamsShortCard

import React from 'react'

import pauseIcon from 'images/button-pause.svg'
import playIcon from 'images/button-play.svg'

type Props = {
    isOnHold: boolean
}

const HoldIcon: React.FC<Props> = ({ isOnHold }) => {
    const holdIconSrc = !isOnHold ? pauseIcon : playIcon
    const holdIconSize = !isOnHold ? 30 : 18
    const participantTitle = !isOnHold ? 'Hold participant' : 'Resume participant'

    return (
        <img
            src={holdIconSrc}
            alt={participantTitle}
            width={holdIconSize}
            height={holdIconSize}
            title={participantTitle}
        />
    )
}
export default HoldIcon

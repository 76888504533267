import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import KendraPluginState from './kendraPlugin.state'

const initialState: KendraPluginState = {
    searchText: '',
    selected: undefined,
    filters: {},
    documents: [],
}

export const kendraPluginSlice = createSlice({
    name: 'kendraPlugin',
    initialState,
    reducers: {
        setKendraPluginState(state, action: PayloadAction<KendraPluginState>) {
            return { ...state, ...action.payload }
        },
    },
})

export const { setKendraPluginState } = kendraPluginSlice.actions

export type KendraPluginAction = ReturnType<
    (typeof kendraPluginSlice.actions)[keyof typeof kendraPluginSlice.actions]
>

export default kendraPluginSlice.reducer

import { Button, Checkbox, Form, TriggeredPopup } from '@missionlabs/smartagent-app-components'
import iconFilter from 'images/icon-filter.svg'
import React, { useState } from 'react'

import { useSelector } from 'react-redux'
import { selectSortedUserQueues } from 'store/user/user.reducer'
import './queues-popup.scss'

interface Props {
    selected: string[]
    onChange(selected: string[]): void
}

const QueuesPopup: React.FC<Props> = ({ selected, onChange }) => {
    const queues = useSelector(selectSortedUserQueues)

    const [selectedQueues, setSelectedQueues] = useState(selected)

    return (
        <TriggeredPopup
            fromRight
            closeOnClickOutside
            className="sa-queues-popup"
            trigger={
                <Button styling="white" className="sa-queues-popup-trigger">
                    <span>
                        {selected.length === 0
                            ? 'All'
                            : selected.length === 1
                              ? queues?.find((q) => selected[0] === q.queueARN)?.name
                              : 'Multiple'}
                    </span>
                    <img src={iconFilter} alt="" />
                </Button>
            }
            render={(close: Function) => (
                <Form
                    className="sa-queues-popup-form"
                    onSubmit={() => {
                        onChange(selectedQueues)
                        close()
                    }}
                >
                    <div className="sa-queues-popup-checkboxes row column">
                        <Checkbox
                            square
                            onChange={(checked: boolean) =>
                                setSelectedQueues(checked ? [] : selected)
                            }
                            checked={selectedQueues.length === 0}
                            label="All"
                        />
                        {queues?.map((q) => (
                            <Checkbox
                                key={q.ID}
                                square
                                onChange={(checked: boolean) =>
                                    setSelectedQueues(
                                        checked
                                            ? [...selectedQueues, q.queueARN]
                                            : selectedQueues.filter((s) => s !== q.queueARN),
                                    )
                                }
                                checked={selectedQueues.includes(q.queueARN)}
                                label={q.name}
                            />
                        ))}
                        <Button styling="primary" type="submit">
                            Save
                        </Button>
                    </div>
                </Form>
            )}
        />
    )
}

export default QueuesPopup

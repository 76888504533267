import './index.scss'

import { formatDateTime } from '@missionlabs/smartagent-app-components'
import { isToday } from 'date-fns'
import { UserConversation } from 'graphql/types'
import React from 'react'
import { useDispatch } from 'react-redux'
import { selectConversation } from 'store/internal-messaging/internal-messaging.actions'
import { convertTime } from 'utils'

import { Button } from '@missionlabs/smartagent-app-components'

import Badge from '../../components/Badges/Badges'

interface Props {
    userConversations: UserConversation[] | undefined
}

const ChatList: React.FC<Props> = ({ userConversations }) => {
    const sortConversations = (a: UserConversation, b: UserConversation) => {
        return a.conversation.lastMessageTimestamp! > b.conversation.lastMessageTimestamp! ? -1 : 1
    }

    const dispatch = useDispatch()

    return (
        <div className="chat-list">
            {userConversations
                ?.filter((uc) => !!uc.conversation.lastMessageTimestamp)
                .sort(sortConversations)
                .map((uc) => {
                    const { conversationId, name, conversation } = uc
                    const timestamp = conversation.lastMessageTimestamp!
                    return (
                        <div className="agent-chat-view" key={conversationId}>
                            <Button
                                className="agent-chat"
                                key={conversationId}
                                onClick={() => dispatch(selectConversation(conversationId))}
                            >
                                <div className="agent-chat-left-column">
                                    <span className="agent-chat-left-column-name">{name}</span>
                                    {conversation?.lastMessageContent ? (
                                        <span className="agent-chat-left-column-message">
                                            <span className="agent-chat-left-column-chat">
                                                {conversation?.lastMessageContent}
                                            </span>
                                        </span>
                                    ) : null}
                                </div>
                                <div className="agent-chat-right-column-notification">
                                    {isToday(timestamp)
                                        ? convertTime(timestamp)
                                        : formatDateTime(timestamp)}
                                    {uc.unread > 0 ? <Badge number={uc.unread} /> : null}
                                </div>
                            </Button>
                            <div className="agent-chat-divider"></div>
                        </div>
                    )
                })}
        </div>
    )
}

export default ChatList

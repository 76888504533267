import { Dropdown } from '@missionlabs/smartagent-app-components'
import {
    SADropdownOption,
    SADropdownProps,
} from '@missionlabs/smartagent-app-components/dist/Dropdown'
import useSettings from 'hooks/redux/useSettings'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getRoutingProfiles } from 'store/settings/settings.actions'
import { IGroup } from 'store/settings/settings.state'

interface Props extends Omit<SADropdownProps, 'options' | 'onChange'> {
    onSelect: (routingProfile: IGroup | undefined) => void
    selectedRoutingProfileID?: string
    blankOption?: boolean
}

const RoutingProfileDropdown: React.FC<Props> = ({
    selectedRoutingProfileID,
    onSelect,
    blankOption,
    ...props
}) => {
    const dispatch = useDispatch()

    const { routingProfiles } = useSettings()

    useEffect(() => {
        if (!routingProfiles) dispatch(getRoutingProfiles())
    }, [routingProfiles, dispatch])

    const options: SADropdownOption[] =
        routingProfiles
            ?.map((rp) => ({
                label: rp.Name,
                data: rp,
            }))
            .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)) ?? []

    if (!routingProfiles) {
        options.unshift({
            label: `Loading routing profiles`,
            data: undefined,
        })
    } else if (blankOption) {
        options.unshift({
            label: '',
            data: undefined,
        })
    }

    return (
        <Dropdown
            {...props}
            options={options}
            label={props.label ?? 'Routing Profile'}
            title={props.label ?? 'Routing Profile'}
            onChange={(value: SADropdownOption<IGroup>) => onSelect(value.data)}
            value={(routingProfiles || []).find((r) => r.Id === selectedRoutingProfileID)?.Name}
        />
    )
}

export default RoutingProfileDropdown

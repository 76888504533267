export enum AppFeatures {
    AGENTS_DIRECTORY = 'agents-directory',
    REALTIME_METRICS = 'realtime-metrics',
    HISTORIC_METRICS = 'historic-metrics',
    QA = 'qa',
    DPA = 'dpa',
    CUSTOMER_INPUTS = 'customer-inputs',
    LIVE_MONITORING = 'live-monitoring',
    LINE_MANAGEMENT = 'line-management',
    CONNECT = 'connect',
    QUICKSIGHT = 'quicksight',
    AUDIO_SETTINGS = 'audio-settings',
    USER_MANAGEMENT = 'user-management',
    QUEUE_STATS = 'queue-stats',
    UPLOAD_ROTA = 'upload-rota',
    KEYWORD_MANAGEMENT = 'keyword-management',
    REPORT_ISSUE = 'report-issue',
    CALL_RECORDING = 'call-recording',
    CALL_TRANSCRIPTION = 'call-transcription',
    CALL_DETAILS = 'call-details',
    CHAT_DETAILS = 'chat-details',
    CALL_COMPREHENSION = 'call-comprehension',
    CALL_HISTORY = 'call-history',
    CHAT_TRANSCRIPTION = 'chat-transcription',
    CHAT = 'chat',
    INTERNAL_MESSAGING = 'internal-messaging',
    ANNOUNCEMENTS = 'announcements',
    CASE = 'case',
    EXTERNAL_DIRECTORY = 'external-directory',
    CALL_PARKING = 'call-parking',
    CALL_PLUCKING = 'call-plucking',
    AGENT_STATUS = 'agent-status',
    CAMPAIGN_MANAGEMENT = 'campaign-management',
    CUSTOM_MESSAGING = 'custom-messaging',
    CASE_MANAGEMENT = 'case-management',
    TASK_HISTORY = 'task-history',
    TASK_DETAILS = 'task-details',
    QUEUED_TASKS = 'queued-tasks',
    AUTOMATED_RULES = 'automated-rules',
    ALL = '*',
    PERMISSIONS = 'permissions',
    APP_CONFIGURATION = 'app-configuration',
    MS_TEAMS_DIRECTORY = 'ms-teams-directory',
    AUDIT_LOG = 'audit-log',
    WELCOME_MESSAGING = 'welcome-messaging',
    SUPPORT_WEBHID_CONTROL = 'support-webhid-control',
    CONTACT_LOGS = 'contact-logs',
    QUICK_REPLY_MANAGEMENT = 'quick-reply-management',
    QUEUES = 'manage-queue-settings',
    EMAIL_CC_REPLY = 'email-cc-reply',
    OUTBOUND_EMAIL = 'outbound-email',
    AGENT_ASSISTANCE = 'agent-assistance',
    SOCIAL_TASKS = 'social-tasks',
    COLLECT_ANALYTICS = 'collect-analytics',
    IDLE_TIMEOUT = 'idle-timeout',
    CUSTOM_REFRESH_TIMER = 'custom-refresh-timer',
    CHAT_CONTACT_SEARCH_OPTIONS = 'chat-contact-search-options',
    SOCIAL_MANAGEMENT = 'social-management',
    CONTACT_HISTORY = 'contact-history',
    CHANNEL_SETTINGS = 'channel-settings',
    DISABLE_FORCE_AGENT_OFFLINE = 'disable-force-agent-offline',
    THREAD_EMAIL_REDESIGNED = 'thread-email-redesigned',
    FORWARD_EMAIL = 'forward-email',
    FILTER_QUEUES = 'filter-queues',
    FILTER_QUICK_CONNECTS = 'filter-quick-connects',
    CONTACT_FLOW_PHRASES = 'contact-flow-phrases',
    WHATSAPP = 'whatsapp',
    SMS_MESSAGING = 'sms-messaging',
    OUTBOUND_SMS = 'outbound-sms',
    REALTIME_DATA_QUEUES_REDESIGNED = 'real-time-data-queues-redesigned',
    DISABLE_COOKIE_CHECK = 'disable-cookie-check',
    CRM_COMPANION = 'crm-companion',
    RESTRICT_QUEUE_ACCESS = 'restrict-queue-access',
    QUEUES_CLI_SELECTOR = 'queues-cli-selector',
    DATA_RETENTION = 'data-retention',
    REALTIME_DATA_AGENTS_REDESIGNED = 'real-time-data-agents-redesigned',
}

export enum AppSubFeatures {
    QA_EDIT = 'qa-edit',
    QA_CREATE = 'qa-create',
    QA_CALIBRATE = 'qa-calibrate',
    QA_READ = 'qa-read',
    QA_REPORTING = 'qa-reporting',
    QA_SELF_REPORTING = 'qa-self-reporting',
    QA_TEMPLATES = 'qa-templates',
    CALL_RECORDING_DOWNLOAD = 'call-recording-download',
    CALL_DETAILS_EDIT = 'call-details-edit',
    DIRECTORY_VIEW_SENSITIVE = 'external-directory-view-sensitive',
    DIRECTORY_VIEW_BLOCKED = 'external-directory-view-blocked',
    DIRECTORY_VIEW_DEPRIORITISED = 'external-directory-view-deprioritised',
    DIRECTORY_EDIT = 'external-directory-edit',
    ANNOUNCEMENTS_PUBLISH = 'announcements-publish',
    TASK_DETAILS_EDIT = 'task-details-edit',
    MANAGE_PERMISSIONS = 'manage-permissions',
    MOVE_CONTACTS = 'move-contacts',
    END_CONTACTS = 'end-contacts',
    REPLY_CONTACTS = 'reply-contacts',
    EXPORT_CONTACTS = 'export-contacts',
    ALLOW_BARGE = 'allow-barge',
    CHAT_HISTORY_TRANSCRIPT = 'chat-history-transcript',
    AGENT_ASSISTANCE_ACKNOWLEDGE = 'agent-assistance-acknowledgement',
    SOCIAL_MANAGEMENT_TWITTER = 'social-management-twitter',
    SOCIAL_MANAGEMENT_FACEBOOK = 'social-management-facebook',
    SOCIAL_MANAGEMENT_INSTAGRAM = 'social-management-instagram',
    CONTACT_HISTORY_VOICE = 'contact-history-voice',
    CONTACT_HISTORY_WEBCHAT = 'contact-history-webchat',
    CONTACT_HISTORY_TASK = 'contact-history-task',
    CONTACT_HISTORY_SOCIAL = 'contact-history-social',
    CONTACT_HISTORY_MESSAGING = 'contact-history-messaging',
    EMAIL_MANAGEMENT = 'email-management',
    SOCIAL_MANAGEMENT = 'social-management',
    MESSAGING_MANAGEMENT = 'messaging-management',
    WEBFORM_MANAGEMENT = 'webform-management',
    WEBCHAT_MANAGEMENT = 'webchat-management',
    QUEUED_TASKS_UPLOAD_ATTACHMENT = 'queued-tasks-upload-attachment',
    QUEUED_TASKS_UPLOAD_BULK_ATTACHMENT = 'queued-tasks-upload-bulk-attachment',
    REPLY_THEN_END = 'reply-then-end',
    REALTIME_DATA_QUEUES_REDESIGNED_v1_6 = 'real-time-data-queues-redesigned-v1.6',
    APP_CONFIGURATION_CHAT = 'app-configuration-chat',
    APP_CONFIGURATION_TASK = 'app-configuration-task',
    APP_CONFIGURATION_VOICE = 'app-configuration-voice',
    SOCIAL_CHAT_TRANSCRIPT = 'social-chat-transcript',
    DELETE_CUSTOMER_DATA = 'delete-customer-data',
}

export const DEPRECATED_FEATURE_FLAGS_WITH_PERMISSIONS = [
    AppFeatures.APP_CONFIGURATION,
    AppFeatures.TASK_DETAILS,
    AppFeatures.USER_MANAGEMENT,
    AppFeatures.TASK_HISTORY,
    AppFeatures.CONNECT,
    AppFeatures.CONTACT_LOGS,
    AppFeatures.PERMISSIONS,
    AppFeatures.AUDIT_LOG,
    AppSubFeatures.MANAGE_PERMISSIONS,
    AppFeatures.AGENT_ASSISTANCE,
    AppFeatures.CONTACT_HISTORY,
    AppFeatures.CONTACT_LOGS,
    AppFeatures.CALL_DETAILS,
    AppSubFeatures.CALL_DETAILS_EDIT,
    AppFeatures.AGENT_STATUS,
    AppFeatures.LIVE_MONITORING,
    AppSubFeatures.ALLOW_BARGE,
    AppFeatures.ANNOUNCEMENTS,
    AppFeatures.QUICK_REPLY_MANAGEMENT,
    AppSubFeatures.ANNOUNCEMENTS_PUBLISH,
    AppFeatures.CHANNEL_SETTINGS,
    AppSubFeatures.MESSAGING_MANAGEMENT,
    AppFeatures.CALL_HISTORY,
    AppFeatures.CALL_TRANSCRIPTION,
    AppFeatures.CALL_COMPREHENSION,
    AppFeatures.CALL_RECORDING,
    AppSubFeatures.CALL_RECORDING_DOWNLOAD,
    AppFeatures.REPORT_ISSUE,
    AppFeatures.AUDIO_SETTINGS,
]

export interface IFeature<T = AppFeatures> {
    ID: T
    config: FeatureConfig<T>
    subfeatures: AppSubFeatures[]
}

interface IDirectoryConfig {
    customFields?: (
        | { type: 'list'; name: string; label: string; options: string[] }
        | { type: 'text'; name: string; label: string }
    )[]
}

interface IChatConfig {
    chatPanelWidthPercent?: number
    onCustomerInactive?: IInactivityConfig[]
    onCustomerInitiallyInactive?: IInactivityConfig[]
    autoAnswer?: boolean
    onAgentInactive?: this['onCustomerInactive']
    canCopyChatTranscript?: boolean
    messageReceiptsActive?: boolean
    attachmentsConfig?: {
        enabled: boolean
        maxFileSizeMB: number
        acceptedFileTypes: string[]
        maxNumberOfFilesPerMessage: number
    }
}

interface IInactivityConfig {
    disconnect: boolean
    seconds: number
    message: string | Record<string, any>
}

interface IRealtimeDataAgentsRedesignedConfig {
    hideHistoricMetrics?: boolean
    hideQueuesByRoutingProfile?: string[]
}

interface IQueueStatsConfig {
    hideHistoricMetrics?: boolean
    hideQueuesByRoutingProfile?: string[]
}

interface ICallParkingConfig {
    quickConnectID?: string
}

interface IInternalMessagingConfig {
    appSyncURL: string
    appSyncRegion: string
    apiKey: string
}

interface ICustomMessagingLanguages {
    label: string
    data: string
}

interface ICustomMessagingConfig {
    messageLanguages?: ICustomMessagingLanguages[]
}

interface ILineManagement {
    showWelcomeMessage: boolean
}

interface IQueueStats {
    showQueueSummary: boolean
}

interface IQuickSightConfig {
    url: string
}

interface IAutomatedRulesConfig {
    fields: {
        name: string
        path: string
        type: string
        values: { value: string; label: string }[]
    }[]
}

interface IQueuedTasksConfig {
    contactLogGroup: string
}

interface IEmailCCReplyConfig {
    ccReplyChecked: boolean
    enableCCList: boolean
}

interface ITaskDetailsConfig {
    messages?: {
        enableReply: boolean
    }
    autoAnswer?: boolean
}

interface IQAConfig {
    immediateFailThresh: number
    failTypeHierarchy: string[]
}

interface IIdleTimeoutConfig {
    idleTimeoutSeconds: number
}

interface IFilterQueuesConfig {
    task: RegExp[]
}

export interface IFilterQuickConnectsConfig {
    task: RegExp[]
    voice: RegExp[]
    chat: RegExp[]
}

interface IForwardEmailConfig {
    maxForwards: number
}

interface ISMSMessagingConfig {
    messageCountThreshold: number
}

export interface ICampaignManagementConfig {
    customerStackRegion?: string
}

export interface IEmailRedesignedConfig {
    allowedAgents: string[]
}

export interface ICustomRefreshTimerConfig {
    refreshInterval?: number
}

type FeatureConfigMap = {
    [AppFeatures.EXTERNAL_DIRECTORY]: IDirectoryConfig | undefined
    [AppFeatures.QA]: IQAConfig | undefined
    [AppFeatures.CHAT]: IChatConfig | undefined
    [AppFeatures.QUEUE_STATS]: IQueueStatsConfig | undefined
    [AppFeatures.CALL_PARKING]: ICallParkingConfig | undefined
    [AppFeatures.INTERNAL_MESSAGING]: IInternalMessagingConfig
    [AppFeatures.LINE_MANAGEMENT]: ILineManagement
    [AppFeatures.REALTIME_METRICS]: IQueueStats
    [AppFeatures.QUICKSIGHT]: IQuickSightConfig
    [AppFeatures.CUSTOM_MESSAGING]: ICustomMessagingConfig | undefined
    [AppFeatures.AUTOMATED_RULES]: IAutomatedRulesConfig
    [AppFeatures.TASK_DETAILS]: ITaskDetailsConfig | undefined
    [AppFeatures.EMAIL_CC_REPLY]: IEmailCCReplyConfig | undefined
    [AppFeatures.QUEUED_TASKS]: IQueuedTasksConfig
    [AppFeatures.IDLE_TIMEOUT]: IIdleTimeoutConfig
    [AppFeatures.FORWARD_EMAIL]: IForwardEmailConfig
    [AppFeatures.FILTER_QUEUES]: IFilterQueuesConfig
    [AppFeatures.FILTER_QUICK_CONNECTS]: IFilterQuickConnectsConfig
    [AppFeatures.CAMPAIGN_MANAGEMENT]: ICampaignManagementConfig
    [AppFeatures.SMS_MESSAGING]: ISMSMessagingConfig
    [AppFeatures.THREAD_EMAIL_REDESIGNED]: IEmailRedesignedConfig
    [AppFeatures.CUSTOM_REFRESH_TIMER]: ICustomRefreshTimerConfig
    [AppFeatures.REALTIME_DATA_AGENTS_REDESIGNED]: IRealtimeDataAgentsRedesignedConfig
}
export type FeatureConfig<T> = T extends keyof FeatureConfigMap ? FeatureConfigMap[T] : never

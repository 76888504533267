import React from 'react'

import useStats from 'hooks/redux/useStats'
import useHasFeature, { AppFeatures } from 'hooks/useHasFeature'
import AgentStats from './AgentStats'
import './stats.scss'

import QueueMetrics from 'views/Metrics/RealTimeMetrics/QueueMetrics'
import RealTimeContacts from './RealTimeContacts'

interface Props {}

const Stats: React.FC<Props> = () => {
    useStats()

    const hasFeature = useHasFeature()
    const hasCallPluckingFeature = hasFeature(AppFeatures.CALL_PLUCKING)

    const defaultClass = 'stats'
    const cssClass = hasCallPluckingFeature ? `${defaultClass} no-flex` : defaultClass

    return (
        <div className={cssClass}>
            {hasCallPluckingFeature && <RealTimeContacts />}
            {hasFeature(AppFeatures.QUEUE_STATS) && (
                <>
                    <QueueMetrics />
                    <AgentStats />
                </>
            )}
        </div>
    )
}

export default Stats

import type {
    AllThreadsAndMessages,
    CreateMessageResponse,
    CreateThreadResponse,
    GetAttachmentURLResponse,
    GetThreadResponse,
    GetUploadURLResponse,
    NewThread,
    OutboundMessage,
    ThreadAndMessages,
} from '@missionlabs/smartagent-service-thread'
import { makeRequest } from '.'

export async function getThread(threadID: string) {
    const response = await makeRequest<GetThreadResponse>({
        url: `/thread-service/companies/{companyID}/instances/{instanceID}/threads/${threadID}`,
        method: 'get',
    })
    return response.data
}

export async function getThreads(threadIDs: string[]) {
    const response = await makeRequest<AllThreadsAndMessages[]>({
        url: `/thread-service/companies/{companyID}/instances/{instanceID}/all-threads`,
        method: 'post',
        data: threadIDs,
    })
    return response.data
}

export async function sendMessage(threadID: string, message: OutboundMessage) {
    const response = await makeRequest<CreateMessageResponse>({
        url: `/thread-service/companies/{companyID}/instances/{instanceID}/threads/${threadID}/messages`,
        method: 'post',
        data: message,
    })
    return response.data
}

export async function getDownloadURL(threadID: string, messageID: string, s3Key: string) {
    const response = await makeRequest<GetAttachmentURLResponse>({
        url: `/thread-service/companies/{companyID}/instances/{instanceID}/threads/${threadID}/messages/${messageID}/attachments`,
        method: 'get',
        params: {
            s3Key,
        },
    })
    return response.data
}

export async function getUploadURL(channel: string, filename: string) {
    const response = await makeRequest<GetUploadURLResponse>({
        url: `/thread-service/companies/{companyID}/instances/{instanceID}/upload-url?channel=${channel}&filename=${filename}`,
        method: 'get',
    })
    return response.data
}

export async function updateLastSeenMessage(threadID: string, messageID: string) {
    const response = await makeRequest({
        url: `/thread-service/companies/{companyID}/instances/{instanceID}/threads/last-seen/${threadID}`,
        method: 'put',
        data: { messageID },
    })
}

export async function getThreadsNewMessageCounts(threadIDs: string[]) {
    const threadIDsParam = threadIDs.join(',')
    const response = await makeRequest({
        url: `/thread-service/companies/{companyID}/instances/{instanceID}/threads/new-message-count?threadIDs=${threadIDsParam}`,
        method: 'get',
    })
    return response.data
}

export async function postThread(newThread: NewThread) {
    const response = await makeRequest<CreateThreadResponse>({
        url: `/thread-service/companies/{companyID}/instances/{instanceID}/threads`,
        method: 'post',
        data: newThread,
    })
    return response.data
}

export async function getParentAndChildThreads(parentThreadID: string) {
    const response = await makeRequest<ThreadAndMessages>({
        url: `/thread-service/companies/{companyID}/instances/{instanceID}/threads/parent/${parentThreadID}`,
        method: 'get',
    })
    return response.data
}

import { useSelector } from 'react-redux'
import RootState from 'store/state'

export default function useAppConfig() {
    const appConfig = useSelector<RootState, RootState['app']['appConfig']>(
        ({ app }) => app.appConfig,
    )

    return appConfig
}

import {
    FacebookOnboardRequest,
    InstagramOnboardRequest,
    PageConnectionStatus,
    TwitterLoginDetails,
    TwitterOnboardRequest,
    WhatsAppReadReceiptRequest,
} from 'store/channelSettings/social/social.state'

import {
    SocialPage,
    SocialPageConfigs,
    UpdateSocialPageRequestBody,
} from '@missionlabs/smartagent-service-social/dist/types/social-page'

import { makeRequest } from './'

export async function getSocialPages(): Promise<SocialPageConfigs> {
    const response = await makeRequest<{ [key: string]: [SocialPage] }>({
        url: `/social-service/companies/{companyID}/pages`,
        method: 'get',
    })
    return response.data
}

export async function getSocialPageConnectionStatus(
    pageID: string,
    channel: string,
): Promise<PageConnectionStatus> {
    const response = await makeRequest<PageConnectionStatus>({
        url: `/social-service/companies/{companyID}/instances/{instanceID}/connection-status/${pageID}`,
        method: 'post',
        data: { channel },
    })
    return response.data
}

export async function updateSocialPage(id: string, data: UpdateSocialPageRequestBody) {
    await makeRequest({
        url: `/social-service/companies/{companyID}/page/${id}`,
        method: 'put',
        data,
    })
}

export async function getTwitterAuthSetup(): Promise<TwitterLoginDetails> {
    const response = await makeRequest<TwitterLoginDetails>({
        url: `/twitter-service/companies/{companyID}/instances/{instanceID}/auth-setup`,
        method: 'get',
    })
    return response.data
}

export async function postTwitterOnboard(data: TwitterOnboardRequest) {
    await makeRequest({
        url: `/twitter-service/companies/{companyID}/instances/{instanceID}/onboard`,
        method: 'post',
        data,
    })
}

export async function postFacebookOnboard(data: FacebookOnboardRequest) {
    await makeRequest({
        url: `/facebook-service/companies/{companyID}/instances/{instanceID}/onboard`,
        method: 'post',
        data,
    })
}

export async function postInstagramOnboard(data: InstagramOnboardRequest) {
    await makeRequest({
        url: `/instagram-service/companies/{companyID}/instances/{instanceID}/onboard`,
        method: 'post',
        data,
    })
}

export async function sendWhatsAppReadReceipt(data: WhatsAppReadReceiptRequest) {
    await makeRequest({
        url: `/whatsapp-service/companies/{companyID}/instances/{instanceID}/send-read-receipt`,
        method: 'post',
        data,
    })
}

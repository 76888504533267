import { AppFeatures } from 'hooks/useHasFeature'

import { paramsToQueryString } from 'utils'

export const buildQueryStringParameters = (
    hasWebsocketFeature: (feature: AppFeatures) => boolean,
    companyID: string,
    tokenID: string,
) => {
    const authorisedWebsocketEnabledFeatures = Object.keys(AppFeatures).filter((feature) =>
        hasWebsocketFeature(AppFeatures[feature as keyof typeof AppFeatures]),
    )

    const services = authorisedWebsocketEnabledFeatures
        .map((feature) => `&service=${feature}`)
        .join('')
    const queryStringParameters = paramsToQueryString({ companyID, tokenID }) + services

    return queryStringParameters
}

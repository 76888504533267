import React from 'react'

import HistoryList from './HistoryList'

import './side-panel.scss'

const SidePanel: React.FC<{}> = () => {
    return (
        <div className="contact-history-side-panel">
            <HistoryList />
        </div>
    )
}

export default SidePanel

import { Button } from '@missionlabs/smartagent-app-components'
import Tick from 'images/tick.svg'
import React from 'react'
import { ClipLoader } from 'react-spinners'

interface Props {
    status: connect.AgentStateDefinition
    isLoading: boolean
    onClicked(status: connect.AgentStateDefinition): void
    currentStatusName: string
    onKeyDown: (evt: React.KeyboardEvent) => void
}

const UserStatusItem: React.FC<Props> = ({
    status,
    currentStatusName,
    isLoading,
    onClicked,
    onKeyDown,
}) => {
    return (
        <li key={status.name} className="row between middle">
            <Button
                onClick={() => onClicked(status)}
                onKeyDown={onKeyDown}
                className="row between middle stretch"
            >
                <span title={status.name}>{status.name}</span>
                {status.name === currentStatusName ? (
                    <div className="status-icon">
                        <img src={Tick} alt="Selected" title="Selected" />
                    </div>
                ) : isLoading ? (
                    <div className="status-icon">
                        <ClipLoader size={12} color="" />
                    </div>
                ) : null}
            </Button>
        </li>
    )
}

export default UserStatusItem

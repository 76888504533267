import { Tag, Tooltip } from '@missionlabs/smartagent-app-components'
import type { SATableColumn } from '@missionlabs/smartagent-app-components/dist/Table'
import type { Props as TagProps } from '@missionlabs/smartagent-app-components/dist/Tag'
import { IQueueMetricData } from 'store/metrics/metrics.state'
import { secondsToTime } from 'utils'

type TagType = TagProps['type']

const allQueueColumns: SATableColumn<any>[] = [
    {
        name: 'Queue',
        value: 'queueName',
        sort: true,
        className: 'queue-name',
        render: (queueName: string) => {
            return (
                <Tooltip text={queueName}>
                    <span className="clipped-field">{queueName}</span>
                </Tooltip>
            )
        },
    },
    {
        name: 'Online',
        value: 'online',
        sortField: 'online.Value',
        sort: true,
        tooltip: 'Count of agents in a status other than offline',
        render: (metric) => <span className={metric.Status ?? ''}>{metric.Value}</span>,
    },
    {
        name: 'Available',
        value: 'available',
        sortField: 'available.Value',
        sort: true,
        tooltip: 'Count of agents available for contacts',
        render: (metric) => <span className={metric.Status ?? ''}>{metric.Value}</span>,
    },
    {
        name: 'On Contact',
        value: 'onCall',
        sortField: 'onCall.Value',
        sort: true,
        tooltip: 'Count of agents on contact',
        render: (metric) => <span className={metric.Status ?? ''}>{metric.Value}</span>,
    },
    {
        name: 'In Queue',
        value: 'inQueue',
        sortField: 'inQueue.Value',
        sort: true,
        tooltip: 'Count of customers waiting in queue',
        render: (metric) => <span className={metric.Status ?? ''}>{metric.Value}</span>,
    },
    {
        name: 'Availability',
        value: 'availability',
        sortField: 'availability.Value',
        sort: true,
        tooltip: 'Count of available slots in queue',
        render: (metric) => <span className={metric.Status ?? ''}>{metric.Value}</span>,
    },
    {
        name: 'Oldest Time',
        value: 'oldest',
        sortField: 'oldest.Value',
        sort: true,
        tooltip: 'Age of contact that has been in queue for longest',
        isDate: true,
        render: (metric) => (
            <span className={metric.Status ?? ''}>{secondsToTime(metric.Value)}</span>
        ),
    },
    {
        name: 'NPT',
        value: 'npt',
        sortField: 'npt.Value',
        sort: true,
        className: 'small',
        tooltip: 'Count of agents in non productive status',
        render: (metric) => <span className={metric.Status ?? ''}>{metric.Value}</span>,
    },
    {
        name: 'ACW',
        value: 'acw',
        sortField: 'acw.Value',
        sort: true,
        className: 'small',
        tooltip: 'Count of agents in after contact work',
        render: (metric) => <span className={metric.Status ?? ''}>{metric.Value}</span>,
    },
    {
        name: 'Error',
        value: 'error',
        sortField: 'error.Value',
        sort: true,
        tooltip: 'Count of agents in error status',
        render: (metric) => <span className={metric.Status ?? ''}>{metric.Value}</span>,
    },
    {
        name: 'Queued',
        value: 'queued',
        sort: true,
        tooltip: 'Count of contacts placed into queue', // NEEDS TO BE CHANGED TO GET THE RIGHT DATA
    },

    {
        name: 'Handled',
        value: 'handled',
        sort: true,
        tooltip: 'Count of contacts handled by an agent',
    },
    {
        name: 'Abandoned',
        value: 'abandoned',
        sort: true,
        tooltip: 'Count of contacts that were disconnected while the customer was in queue',
    },
    {
        path: '*',
        key: '%abandoned',
        name: '% Abandoned',
        sortField: 'percentageAbandoned',
        sort: true,
        tooltip: 'Percentage of calls abandoned (Excludes short abandoned contacts)',
        render: (queue: IQueueMetricData) => {
            const { percentageAbandoned, percentageRejectedStatus } = queue
            return <span className={percentageRejectedStatus}>{percentageAbandoned}</span>
        },
    },
    {
        name: 'Scheduled',
        value: 'scheduled.Value',
        sortField: 'scheduled.Value',
        sort: true,
        tooltip: 'Count of customers scheduled for callback',
    },
    {
        name: 'AHT',
        path: '*',
        key: 'aht',
        sort: true,
        sortField: 'avgHandledTime',
        tooltip: 'Average handled time',
        className: 'small',
        render: (queue: IQueueMetricData) => {
            const { avgHandledTime, avgHandledTimeStatus } = queue
            return <span className={avgHandledTimeStatus}>{secondsToTime(avgHandledTime)}</span>
        },
    },
    {
        name: 'SLA',
        path: '*',
        key: 'sla',
        sortField: 'sla',
        position: 'right',
        tooltip: 'Service level of agreement',
        render: (queue: IQueueMetricData) => {
            const { sla, slaStatus } = queue
            const slaStatusColors: { [key: string]: TagType } = {
                ontarget: 'green',
                alert: 'red',
                warning: 'orange',
            }
            return (
                <Tag small type={slaStatusColors[slaStatus] ?? 'white'}>
                    {Math.round(sla * 10) / 10}%
                </Tag>
            )
        },
    },
]

export default allQueueColumns

import { TimeElapsed } from '@missionlabs/smartagent-app-components'
import React from 'react'

interface Props {
    beenInStateFor: any
}

const AN_HOUR = 3600
const A_DAY = AN_HOUR * 24

const TimeInState: React.FC<Props> = (props) => {
    const secondsSinceChange = Math.round(
        (new Date().getTime() - new Date(props.beenInStateFor).getTime()) / 1000,
    )
    const format =
        secondsSinceChange <= AN_HOUR ? 'mm:ss' : secondsSinceChange >= A_DAY ? 'dd:hh' : 'hh:mm'
    return (
        <TimeElapsed
            date={new Date(props.beenInStateFor)}
            hours={secondsSinceChange > AN_HOUR}
            days={secondsSinceChange > A_DAY}
            format={format}
            annotate
        />
    )
}

export default TimeInState

import { Agent } from 'components/Dropdowns/agents'
import { Queue } from 'components/Dropdowns/queues'
import { IEmailAttachment } from 'components/EmailMessage/types'
import { ICallContactAttributes } from 'store/contact/contact.state'
import { IInteraction } from 'views/Metrics/HistoricMetrics/types'

import { SADropdownOption } from '@missionlabs/smartagent-app-components/dist/Dropdown'
import { IContactAudit } from '@missionlabs/smartagent-service-contact/dist/lib/entities/contact-audit'
import { IAutomatedActionAudit } from '@missionlabs/smartagent-service-thread/dist/entities/action-audit'

export default interface IContactSearchState {
    formParams: IContactSearchForm
    selectedInteraction?: IInteraction
    appContactId?: string
    interactions: IInteraction[]
    interactionsLoading: boolean
}

export enum ContactInitiationMethod {
    INBOUND = 'INBOUND',
    OUTBOUND = 'OUTBOUND',
    TRANSFER = 'TRANSFER',
    CALLBACK = 'CALLBACK',
    API = 'API',
    QUEUE_TRANSFER = 'QUEUE_TRANSFER',
    MONITOR = 'MONITOR',
}

// To do - manage all of the possible channels in Contact Search from this enum
export enum ContactChannel {
    ALL = 'ALL',
    VOICE = 'VOICE',
    TASK = 'TASK',
    CHAT = 'CHAT',
    EMAIL = 'EMAIL',
    WEBFORM = 'WEBFORM',
}

export enum ChannelColumnGroup {
    ALL = 'ALL',
    VOICE = 'VOICE',
    TASK = 'TASK',
    CHAT = 'CHAT',
    EMAIL = 'EMAIL',
    SOCIAL = 'SOCIAL',
    MESSAGING = 'MESSAGING',
    WEBFORM = 'WEBFORM',
}

export type ContactChannelDropdown = SADropdownOption<ContactChannel> & {
    group: ChannelColumnGroup
}

export interface IContactSearchForm {
    contactID: string
    agent?: Agent | undefined
    queue?: Queue | undefined
    initiationMethod?: SADropdownOption<ContactInitiationMethod | undefined>
    phoneNumber: string
    externalID: string
    startDate: number
    startTime: string
    endDate: number
    endTime: string
    callDurationStartTime: string
    callDurationEndTime: string
    holdDurationStartTime: string
    holdDurationEndTime: string
    channel: ContactChannelDropdown
    socialHandle: string
    uniqueSocialId: string
    chatEmail: string
    chatOrderNumber: string
    email: string
    keyword?: string
    emailSearchType: SADropdownOption<string>
    bargedOnly: boolean
}

export interface IContactSearchOptions {
    initiation: SADropdownOption<string | undefined>[]
    channel: SADropdownOption<string | undefined>[]
    socialSearchType: SADropdownOption<string | undefined>[]
    chatSearchType: SADropdownOption<string | undefined>[]
    emailSearchType: SADropdownOption<string>[]
}

export enum SocialContactType {
    'Social Handle' = 'Social Handle',
    'Unique Social ID' = 'Unique Social ID',
}

export enum ChatContactType {
    'Email' = 'Email',
    'Order Number' = 'Order Number',
}

export enum EmailContactType {
    'All' = 'All',
    'To/From email address' = 'To/From email address',
    'Cc email address' = 'CC email address',
}

export interface togglePlaceholderTypes {
    startDate: string
    endDate?: string
    startTime?: string
    endTime?: string
    callDurationFrom?: string
    callDurationTo?: string
    holdDurationFrom?: string
    holdDurationTo?: string
}

export interface QueuedTasksAuditAction {
    interactionID: string
    queuedTasksAudit: IContactAudit[]
}

export interface AutomatedRulesAuditAction {
    interactionID: string
    automatedRulesAudit: IAutomatedActionAudit[]
}

export interface TransformedAttachmentsAction {
    interactionID: string
    transformedAttachments: IEmailAttachment[]
}

export interface InteractionAttributesAction {
    interactionID: string
    attributes: ICallContactAttributes
}

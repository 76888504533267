import { getAllQueueMetrics, getSpecificQueueMetrics } from 'services/api/api.queue'

import { IQueueMetrics, IQueueMetricsWithConfig } from './metrics.state'

export const getQueueMetricsSplitted = async (
    requestParams: {
        companyID: string
        instanceID: string
        token: string
    },
    dataTypes: string[],
    queueIDs: string[],
    tags: Record<string, string>,
): Promise<IQueueMetricsWithConfig> => {
    const QUEUE_STEP = 100

    const { companyID, instanceID, token } = requestParams

    const queueIDsSlices = []

    for (let i = 0; i < queueIDs.length; i += QUEUE_STEP) {
        const queueIDsSlice = queueIDs?.slice(i, i + QUEUE_STEP)
        queueIDsSlices.push(queueIDsSlice)
    }

    const metrics: IQueueMetrics[] = []

    const metricsResults = await Promise.all(
        queueIDsSlices.map((queueIDsSlice) =>
            getSpecificQueueMetrics({
                companyID,
                instanceID,
                token,
                dataTypes,
                queues: queueIDsSlice,
                tags,
            }),
        ),
    )

    const config = metricsResults[0].config

    metricsResults.forEach((batch) => {
        metrics.push(...batch.metrics)
    })

    return {
        metrics,
        config,
    }
}

export const getQueueMetricsV2 = async ({
    queueIDs,
    queuesNamesMap,
    requestParams,
    dataTypes,
    isRTMPage,
    tags,
}: {
    queueIDs: string[]
    queuesNamesMap: { [key: string]: string }
    requestParams: {
        companyID: string
        instanceID: string
        token: string
    }
    dataTypes: string[]
    isRTMPage: boolean
    tags: Record<string, string>
}) => {
    const REALTIME_METRICS_V2_MAPPER = {
        CONTACTS_QUEUED: 'received',
        CONTACTS_HANDLED: 'handled',
        CONTACTS_ABANDONED: 'rejected',
        AVG_HANDLE_TIME: 'avgHandledTime',
        SERVICE_LEVEL: 'sla',
        PERCENTAGE_REJECTED: 'percentageRejected',
    } as { [key: string]: string }

    const REALTIME_METRICS_V2_STATUS_MAPPER = {
        AVG_HANDLE_TIME: 'avgHandledTimeStatus',
        SERVICE_LEVEL: 'slaStatus',
        PERCENTAGE_REJECTED: 'percentageRejectedStatus',
    } as { [key: string]: string }

    const { companyID, instanceID, token } = requestParams

    let metricsResult

    if (isRTMPage) {
        metricsResult = await getAllQueueMetrics({ companyID, instanceID, token, dataTypes, tags })
    } else {
        metricsResult = await getQueueMetricsSplitted(requestParams, dataTypes, queueIDs, tags)
    }

    const queueMetricsBatches = metricsResult.metrics as IQueueMetrics[]
    const metricConfig = metricsResult?.config

    const queueMetricData = queueMetricsBatches?.map((metricData) => {
        const data = metricData.data || []
        const metricDataMap = data.reduce(
            (acc, metric) => {
                const value = metric.Value
                const status = metric.Status
                const name = metric.Metric.Name

                acc[name] = {
                    value,
                    status,
                }
                return acc
            },
            {} as { [key: string]: { value: number; status: string } },
        )

        const additionalFieldsCollection = Object.keys(REALTIME_METRICS_V2_MAPPER).reduce(
            (acc, metricKey) => {
                const key = REALTIME_METRICS_V2_MAPPER[metricKey]
                const value = metricDataMap[metricKey]?.value

                acc[key] = value || 0
                return acc
            },
            {} as { [key: string]: number },
        )

        const additionalStatusesCollection = Object.keys(REALTIME_METRICS_V2_STATUS_MAPPER).reduce(
            (acc, metricKey) => {
                const key = REALTIME_METRICS_V2_STATUS_MAPPER[metricKey]
                const status = metricDataMap[metricKey]?.status

                acc[key] = status || 'default'
                return acc
            },
            {} as { [key: string]: string },
        )

        const parsedMetricData = {
            ...metricData,
            ...additionalFieldsCollection,
            ...additionalStatusesCollection,
            queueID: metricData.ID,
            queueName: queuesNamesMap[metricData.ID],
            stats: metricData.data,
        }

        return parsedMetricData
    })

    return {
        metrics: queueMetricData,
        config: metricConfig,
    }
}

import { FullProfileViewFieldNames } from 'store/directory/directory.state'
import { isEmpty, isNilOrEmpty } from 'utils'

import { ContactPhoneNumber } from '../../components'
import styles from './styles.module.scss'

interface Props {
    contactData: ContactDataItem[]
}

export interface ContactDataItem {
    fieldName: string
    fieldValue?: string | null
}

const renderPhoneNumbersList = (phoneNumbersData: ContactDataItem[]) =>
    !isEmpty(phoneNumbersData) && (
        <ul className={styles.phonesList}>
            {phoneNumbersData.map(
                (phoneData, index) =>
                    !isNilOrEmpty(phoneData.fieldValue) && (
                        <li
                            key={phoneData.fieldValue || index}
                            className={styles['item-phoneNumber']}
                        >
                            <ContactPhoneNumber phoneNumber={phoneData.fieldValue} />
                        </li>
                    ),
            )}
        </ul>
    )

const renderOtherDataList = (data: ContactDataItem[]) => (
    <ul className={styles.otherData}>
        {data.map(
            (item, index) =>
                !isNilOrEmpty(item.fieldValue) && (
                    <li key={`${item.fieldName}_${index}`} className={styles.item}>
                        <span className={styles.field}>{item.fieldName}:</span>
                        &nbsp;
                        <span className={styles.value}>{item.fieldValue}</span>
                    </li>
                ),
        )}
    </ul>
)

const splitContactData = (contactData: ContactDataItem[]) =>
    contactData.reduce(
        (acc, item) => {
            if (item.fieldName === FullProfileViewFieldNames.PHONE_NUMBER) {
                const phones: ContactDataItem[] = acc[0]

                phones.push(item)

                return acc
            }

            const otherData: ContactDataItem[] = acc[1]

            otherData.push(item)

            return acc
        },
        [[], []],
    )

const FullProfileInfo = ({ contactData }: Props) => {
    const [phoneNumbers, otherData] = splitContactData(contactData)

    return (
        <div className={styles.wrapper}>
            {renderPhoneNumbersList(phoneNumbers)}
            {renderOtherDataList(otherData)}
        </div>
    )
}

export default FullProfileInfo

import type { PasswordPolicyType } from '@aws-sdk/client-cognito-identity-provider'

export const cognitoPasswordValidation = (
    value: string,
    policy: PasswordPolicyType | undefined,
) => {
    if (policy?.MinimumLength && value.length < policy.MinimumLength)
        return `Password must be at least ${policy.MinimumLength} characters long`
    else if (policy?.RequireLowercase && !/[a-z]/.test(value))
        return 'Password must contain at least one lowercase character'
    else if (policy?.RequireUppercase && !/[A-Z]/.test(value))
        return 'Password must contain at least one uppercase character'
    else if (policy?.RequireNumbers && !/[0-9]/.test(value))
        return 'Password must contain at least one number'
    else if (policy?.RequireSymbols && !/(?=.*?[#?!@$%^&*-])/.test(value))
        return 'Password must contain at least one symbol'
    else return ''
}

export const checkPasswordValidation = (password: string) => {
    const valid = {
        longPassword: false,
        lowercase: false,
        uppercase: false,
        digit: false,
        // specialCharacter: false
    }

    // if(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/g.test(password)) { // left for future use
    //     valid.specialCharacter = true;
    // } else valid.specialCharacter = false;

    if (/.{8,}/g.test(password)) {
        valid.longPassword = true
    } else valid.longPassword = false

    if (/[a-z]/g.test(password)) {
        valid.lowercase = true
    } else valid.lowercase = false

    if (/[A-Z]/g.test(password)) {
        valid.uppercase = true
    } else valid.uppercase = false

    if (/[0-9]/g.test(password)) {
        valid.digit = true
    } else valid.digit = false

    return valid
}

import gql from 'graphql-tag'

const document = gql(`
query listMessages($conversationId: String!, $nextToken: String) {
  listMessages(conversationId: $conversationId nextToken: $nextToken){
    __typename
    nextToken
    items {
      __typename
      user
      content
      id
      timestamp
      conversationId
    }
  }
}`)

export default document

export interface ListMessagesResponse {
    listMessages: {
        __typename: string
        items: {
            __typename: string
            id: string
            content: string
            user: string
            timestamp: number
            conversationId: string
        }[]
        nextToken?: string
    }
}

export const fetchMore = (conversationId: string, messages: ListMessagesResponse | undefined) => ({
    query: document,
    variables: {
        conversationId,
        nextToken: messages?.listMessages.nextToken,
    },
    updateQuery: (
        previousResult: ListMessagesResponse,
        { fetchMoreResult }: { fetchMoreResult?: ListMessagesResponse },
    ) => {
        if (!previousResult || !fetchMoreResult) return previousResult
        const previousEntry = previousResult.listMessages
        const newMessages = fetchMoreResult.listMessages.items

        if (!newMessages) return previousResult
        const newNextToken = fetchMoreResult.listMessages.nextToken

        return {
            listMessages: {
                nextToken: newNextToken,
                __typename: 'ListMessages',
                items: [...newMessages, ...previousEntry.items],
            },
            __typename: previousEntry.__typename,
        }
    },
})

const trapBrowserFocusToElement = (event, element) => {
  if (event.key !== "Tab")
    return;
  const focusableInnerElements = element.querySelectorAll(
    'a[href], area[href], button:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex="0"]'
  );
  if (!focusableInnerElements.length)
    return;
  const firstElement = focusableInnerElements[0];
  const lastElement = focusableInnerElements[focusableInnerElements.length - 1];
  if (!event.shiftKey && document.activeElement === lastElement) {
    firstElement.focus();
    event.preventDefault();
  }
  if (event.shiftKey && document.activeElement === firstElement) {
    lastElement.focus();
    event.preventDefault();
  }
};
export {
  trapBrowserFocusToElement as t
};

import { useState } from 'react'

const useDialButton = (initialState: boolean) => {
    const [isDialButtonShown, setIsDialButtonShown] = useState<boolean>(initialState)

    const showDialButton = () => {
        setIsDialButtonShown(true)
    }

    const hideDialButton = () => {
        setIsDialButtonShown(false)
    }

    return { isDialButtonShown, showDialButton, hideDialButton }
}

export default useDialButton

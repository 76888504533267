import useContact from 'hooks/redux/useContact'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    pauseContactRecording,
    resumeContactRecording,
    startContactRecording,
} from 'services/api/api.contact'
import { addError } from 'store/global/global.actions'

export default function useRecordingControl() {
    const contact = useContact()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const isCallRecordingOnInit =
        contact?.attributes?.['sa-call-recording-behaviour'] &&
        contact?.attributes?.['sa-call-recording-behaviour'] !== 'OFF'
    const [recordingState, setRecordingState] = useState<'on' | 'off' | undefined>(
        isCallRecordingOnInit ? 'on' : undefined,
    )

    useEffect(() => {
        if (isCallRecordingOnInit) setRecordingState('on')
    }, [isCallRecordingOnInit])

    const toggleRecording = useCallback(async () => {
        if (!contact) {
            console.log('Contact object missing')
            return
        }

        setLoading(true)
        try {
            if (!recordingState) {
                await startContactRecording(contact.ID, contact.initialContactID)
                setRecordingState('on')
            } else if (recordingState === 'on') {
                await pauseContactRecording(contact.ID, contact.initialContactID)
                setRecordingState('off')
            } else {
                await resumeContactRecording(contact.ID, contact.initialContactID)
                setRecordingState('on')
            }
        } catch (error) {
            console.log('toggle recording failed', error)
            dispatch(
                addError(
                    recordingState === 'on'
                        ? 'Could not pause recording.'
                        : 'Could not start recording.',
                ),
            )
        } finally {
            setLoading(false)
        }
    }, [dispatch, contact, recordingState])

    return {
        loading,
        toggleRecording,
        recordingStatus: recordingState,
    }
}

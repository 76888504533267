import { makeRequest } from 'services/api'
import {
    CalibrationStatus,
    EvaluationConfig,
    ICalibration,
    ICalibrationFull,
    IScore,
    IScoreDetails,
    IScoreSearch,
} from 'store/qa/qa.state'

export async function getScore(companyID: string, token: string, scoreID: string) {
    const response = await makeRequest<IScoreDetails>({
        url: `/qa-service/companies/${companyID}/scores/${scoreID}`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

export async function deleteScore(companyID: string, token: string, scoreID: string) {
    const response = await makeRequest<void>({
        url: `/qa-service/companies/${companyID}/scores/${scoreID}`,
        method: 'delete',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

export async function searchScores(companyID: string, token: string, queryString: string) {
    const response = await makeRequest<{ data: IScoreSearch[] }>({
        url: `/qa-service/companies/${companyID}/scores?${queryString}`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

export async function postScore(companyID: string, token: string, score: IScore) {
    const response = await makeRequest<IScoreDetails>({
        url: `/qa-service/companies/${companyID}/scores`,
        method: 'post',
        data: score,
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

export async function getFullCalibration(companyID: string, token: string, calibrationID: string) {
    const response = await makeRequest<ICalibrationFull>({
        url: `/qa-service/companies/${companyID}/calibrations/${calibrationID}`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

export async function getCalibrations(companyID: string, token: string, queryString: string) {
    const response = await makeRequest<{ data: ICalibration[] }>({
        url: `/qa-service/companies/${companyID}/calibrations?${queryString}`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

export async function putCalibrationStatus(
    companyID: string,
    token: string,
    calibrationID: string,
    calibrationStatus: CalibrationStatus,
) {
    const response = await makeRequest<ICalibration>({
        url: `/qa-service/companies/${companyID}/calibrations/${calibrationID}`,
        method: 'put',
        data: {
            calibrationStatus,
        },
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

export async function startCalibration(
    companyID: string,
    token: string,
    scoreID: string,
    evaluatorID: string,
) {
    const response = await makeRequest({
        url: `/qa-service/companies/${companyID}/calibrations`,
        method: 'post',
        data: {
            scoreID,
            evaluatorID,
        },
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

export async function postCalibrationScore(
    companyID: string,
    token: string,
    calibrationID: string,
) {
    const response = await makeRequest({
        url: `/qa-service/companies/${companyID}/calibrations/${calibrationID}/scores`,
        method: 'post',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

export async function getEvaluationConfig(
    companyID: string,
    token: string,
): Promise<EvaluationConfig> {
    const response = await makeRequest<{ companyID: string; config: EvaluationConfig }>({
        url: `/qa-service/companies/${companyID}/evaluation-config`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response.data.config
}

export async function getScoreExists(
    companyID: string,
    token: string,
    contactID: string,
    externalID?: string,
) {
    const qs = contactID ? `contactID=${contactID}` : `externalID=${externalID}`

    const response = await makeRequest<{ exists: boolean; scoreID?: string }>({
        url: `/qa-service/companies/${companyID}/scores/exist?${qs}`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

export async function putScore(companyID: string, token: string, score: NonNullable<IScore>) {
    const response = await makeRequest<IScoreDetails>({
        url: `/qa-service/companies/${companyID}/scores/${score.ID}`,
        method: 'put',
        data: score,
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

export async function runQAReport(
    companyID: string,
    token: string,
    reportID: string,
    data: any,
    toCSV?: boolean,
) {
    const response = await makeRequest<string>({
        url: `/qa-service/companies/${companyID}/reports/${reportID}${toCSV ? '?toCSV=true' : ''}`,
        headers: {
            'X-Amz-Security-Token': token,
        },
        method: 'post',
        data,
    })
    return response
}

export async function getUnreadScoresCount(companyID: string, token: string) {
    const response = await makeRequest<number>({
        url: `/qa-service/companies/${companyID}/scores/unread`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

import { PureComponent } from 'react'
import { connect } from 'react-redux'

import { Button } from '@missionlabs/smartagent-app-components'
import Join from './buttons/Join-Button.svg'
import Leave from './buttons/Leave-Button.svg'

import { toggleQuickConnects } from 'store/user/user.actions'
import Connection from './Connection'
import Controls from './Controls'

import * as callActions from 'store/call/call.actions'
import { setNumberDialling } from 'store/global/global.actions'
import RootState from 'store/state'

interface Props extends ReturnType<typeof mapStateToProps> {
    leaveCall: () => void
    toggleQuickConnects: () => void
    addConnection: typeof callActions.addConnection
    conferenceConnections: () => void
    setNumberDialling: typeof setNumberDialling
    transfer: (number: string, name?: string, endpoint?: any) => void
}

interface State {
    showConnects: boolean
    dial: boolean
    keypad: boolean
}

class InCall extends PureComponent<Props, State> {
    readonly state: State = {
        showConnects: false,
        dial: false,
        keypad: false,
    }
    componentDidUpdate(prevProps: Props) {
        if (this.props.call?.connections.length === 2) {
            this.setState({ dial: false })
        }
        // if (prevProps.call.hold && !this.props.call.hold) {
        //     this.setState({ dial: false });
        // }
        // if (prevProps.call.connection && !this.props.call.connection) {
        //     this.setState({ dial: false });
        // }
    }
    onTransfer = (connect: any) => {
        this.setState({ showConnects: false })
        // this.props.transfer(connect);
    }
    onKeypad() {
        this.setState({ keypad: !this.state.keypad })
    }
    onKeypadButton = (val: string) => {
        console.log('sending ' + val + '...')
        //this.props.sendDTMF(val);
    }
    onToggleConnects = () => {
        console.log('toggling connects')
        this.props.toggleQuickConnects()
    }
    onToggleDial = () => {
        this.props.setNumberDialling('')
        this.setState({ dial: !this.state.dial })
    }

    render() {
        const { call, contact, appConfig } = this.props

        return (
            <div className="dialler-incall_container">
                {call?.connections.map((connection, i) => {
                    if (call?.parking && i === 1) return null

                    return (
                        <div className={i > 0 ? 'dialler-connection' : ''} key={connection.id}>
                            {call?.connections.length === 2 &&
                            call?.connections[1].status === 'CONNECTED' &&
                            !call?.conferenced &&
                            !call?.parking ? (
                                <div className="join-button">
                                    <Button
                                        onClick={this.props.conferenceConnections}
                                        className="join-call dialler-button"
                                    >
                                        <img
                                            src={Join}
                                            alt="Join call"
                                            width={28}
                                            height={28}
                                            title="Join call"
                                        />
                                    </Button>
                                </div>
                            ) : null}
                            {call?.connections.length === 2 &&
                            call?.conferenced &&
                            !call?.parking ? (
                                <div className="join-button">
                                    <Button
                                        onClick={this.props.leaveCall}
                                        className="leave-call dialler-button"
                                    >
                                        <img
                                            src={Leave}
                                            alt="Leave call"
                                            width={28}
                                            height={28}
                                            title="Leave call"
                                        />
                                        <span className="leave-text">LEAVE</span>
                                    </Button>
                                </div>
                            ) : null}
                            <Connection
                                muted={call?.muted}
                                contactID={contact?.ID!}
                                conferenced={!!call?.conferenced}
                                onToggleDial={this.onToggleDial}
                                name={contact?.customer?.name}
                                noConnections={call?.connections.length}
                                connection={connection}
                                direction={call?.direction}
                                hideMuteButton={appConfig.hideMuteButton}
                                originNumber={connection.originNumber}
                                countryCode={appConfig.defaultCountryCode}
                            />
                        </div>
                    )
                })}
                {this.state.dial && call?.connections.length === 1 ? (
                    <div className="dialler-connection">
                        <Controls makeCall={this.props.transfer} />
                    </div>
                ) : null}
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    call: state.call,
    appConfig: state.app.appConfig,
    contact: state.contact,
})

export default connect(mapStateToProps, {
    ...callActions,
    toggleQuickConnects,
    setNumberDialling,
})(InCall)

import './menu.scss'

import classNames from 'classnames'
import Footer from 'components/Footer'
import Header from 'components/Header'
import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { toggleIframe } from 'store/app/app.actions'
import RootState from 'store/state'
import { isNil } from 'utils'
import Nav from './Nav'

interface Props extends ReturnType<typeof mapStateToProps> {
    toggleIframe: () => void
}

class Menu extends PureComponent<Props> {
    shouldShowCCPIcon<T>(valueInAppConfig: T, valueInAdminSettings: T): T | boolean {
        if (!isNil(valueInAdminSettings)) {
            return valueInAdminSettings
        }

        if (!isNil(valueInAppConfig)) {
            return !valueInAppConfig
        }

        return true
    }

    render() {
        const { disableCCP, showCCPIcon } = this.props

        return (
            <section
                className={classNames({
                    menu: true,
                    'after-call': this.props.afterCallWork,
                })}
            >
                <Header logoURL={this.props.logoURL} name={this.props.name} />
                <Nav />
                {this.shouldShowCCPIcon(disableCCP, showCCPIcon) && (
                    <Footer onClick={this.props.toggleIframe} />
                )}
            </section>
        )
    }
}

function mapStateToProps(state: RootState) {
    const allowCCPWindow =
        state.settings.settings?.appConfigurations?.general?.allowCCPWindow ??
        state.settings.settings?.appConfigurations?.allowCCPWindow
    return {
        name: state.app.name,
        logoURL: state.app.logoURL,
        afterCallWork: state.user?.status.name === 'AfterCallWork' || false,
        disableCCP: state.app.appConfig.disableCCP,
        allowAudioSettings: state.app.appConfig.allowAudioSettings,
        showCCPIcon: allowCCPWindow,
    }
}

export default connect(mapStateToProps, { toggleIframe })(Menu)

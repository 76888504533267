import { useMutation } from '@apollo/client'
import CREATE_CONVERSATION, {
    CreateConversationResponse,
    mutation as createConversationMutation,
} from 'graphql/mutations/createConversation'
import CREATE_USER_CONVERSATION, {
    CreateUserConversationResponse,
    mutation as createUserConversationMutation,
} from 'graphql/mutations/createUserConversation'
import { UserConversation } from 'graphql/types'
import React from 'react'
import { useSelector, useStore } from 'react-redux'
import RootState from 'store/state'
import Agents from './Agents'
import './agents.scss'

interface AgentsWrapperProps {
    userConversations?: UserConversation[]
}

export const AgentsWrapper: React.FC<AgentsWrapperProps> = ({ userConversations }) => {
    const agents = useSelector<RootState, RootState['agents']['agents']>(
        (state) => state.agents.agents,
    )
    const currentUser = useSelector((state: RootState) => state.user?.name)

    const username = useStore().getState().user.username

    const [createConversation] = useMutation<CreateConversationResponse>(CREATE_CONVERSATION)
    const [createUserConversation] =
        useMutation<CreateUserConversationResponse>(CREATE_USER_CONVERSATION)

    const onCreateConversation = async (otherUsers: string[]) => {
        const recipientList = [username, ...otherUsers]

        const getNameFromUsername = (lookupUsername: string) => {
            // Try to get name from agents state first
            const usernameFromAgentsState = agents.find(
                (agent) => agent.username === lookupUsername,
            )?.name
            if (usernameFromAgentsState) return usernameFromAgentsState

            // Otherwise, just use the users first name
            return currentUser ?? lookupUsername
        }

        // Create Conversation
        const conversationCreationResult = await createConversation(
            createConversationMutation(recipientList),
        )

        if (!conversationCreationResult.data) throw new Error('Failed to create conversation')

        const conversationId = conversationCreationResult.data!.createConversation.id

        // Create User Conversations
        await Promise.all(
            recipientList.map((user) => {
                const ucName = getNameFromUsername(
                    recipientList.filter((recipient) => recipient !== user)[0]!,
                )
                const userConversation = {
                    user,
                    conversationId,
                    unread: 0,
                    name: ucName,
                }
                return createUserConversation(
                    createUserConversationMutation(
                        user,
                        userConversation,
                        recipientList,
                        username,
                        conversationCreationResult.data!.createConversation,
                    ),
                )
            }),
        )

        return conversationId
    }

    return (
        <Agents userConversations={userConversations} onCreateConversation={onCreateConversation} />
    )
}

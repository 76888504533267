import { Button, Form, Input } from '@missionlabs/smartagent-app-components'
import DownArrow from 'images/icon-arrow-down-green.svg'
import UpArrow from 'images/icon-arrow-up-green.svg'
import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { reportIssue } from 'store/app/app.actions'

import './report-issue.scss'

interface Props {
    reportIssue: typeof reportIssue
}

class ReportIssue extends PureComponent<Props> {
    readonly state = {
        description: '',
        open: false,
    }
    onToggleOpen = () => {
        this.setState({ open: !this.state.open })
    }
    onValueChange = (description: any) => {
        this.setState({ description })
    }
    onSubmit = () => {
        this.props.reportIssue(this.state.description)
        this.setState({ open: false, description: '' })
    }
    render() {
        const { description, open } = this.state
        return (
            <aside className="report-issue">
                <Button className="report-issue-top" onClick={this.onToggleOpen}>
                    <h3 className="report-issue-title">Report an issue</h3>
                    <img
                        alt="open/close"
                        title="open/close"
                        width={12}
                        height={6}
                        src={open ? UpArrow : DownArrow}
                    />
                </Button>
                {open ? (
                    <Form onSubmit={this.onSubmit}>
                        <Input
                            placeholder="Description"
                            type="text"
                            textarea
                            onChange={this.onValueChange}
                            value={description}
                            id="report-issue-desc"
                        />
                        <div className="report-issue-submit xsm-mar-top">
                            <Button elastic small type="submit" styling="secondary">
                                Submit
                            </Button>
                        </div>
                    </Form>
                ) : null}
            </aside>
        )
    }
}

export default connect(null, { reportIssue })(ReportIssue)

import { useSelector } from 'react-redux'
import { AppFeatures, AppSubFeatures, FeatureConfig, IFeature } from 'store/app/app.features'
import { IPermissionFeature } from 'store/auth/auth.state'
import RootState from 'store/state'
import { canUserUseFeature } from 'utils'

export interface IConfigCheck {
    [key: string]: any
}

export { AppFeatures, AppSubFeatures }

export default function useHasFeature(config?: IConfigCheck) {
    const featurePermissions = useSelector<RootState, IPermissionFeature[]>(
        (state) => state.auth.features ?? [],
    )
    let appFeatures = useSelector<RootState, RootState['app']['features']>(
        (state) => state.app.features ?? [],
    )

    if (config) appFeatures = filterAppFeatures(appFeatures, config)

    const hasFeature = canUserUseFeature(appFeatures, featurePermissions)

    return hasFeature
}

const filterAppFeatures = (appFeatures: IFeature<AppFeatures>[], config: IConfigCheck) => {
    return appFeatures.filter((feature: any) => {
        if (!feature.config) return false

        const configCheckFailed = Object.keys(config)
            .map((fieldToCheck) => {
                return feature.config[fieldToCheck] === config[fieldToCheck]
            })
            .includes(false)

        return !configCheckFailed
    })
}

export function useCompanyHasFeature() {
    const appFeatures = useSelector<RootState, RootState['app']['features']>(
        (state) => state.app.features ?? [],
    )
    const featureExists = (feature: AppFeatures) => {
        return !!appFeatures.find((af) => af.ID === feature)
    }

    return featureExists
}

export function useGetFeatureConfig() {
    const appFeatures = useSelector<RootState, RootState['app']['features']>(
        (state) => state.app.features ?? [],
    )
    const getConfig = <T extends AppFeatures>(feature: T): FeatureConfig<T> => {
        return (appFeatures.find((af) => af.ID === feature)?.config || {}) as FeatureConfig<T>
    }

    return getConfig
}

import type { ThunkDispatch } from '@reduxjs/toolkit'
import WSCompanionClient from 'services/Companion/Websocket'
import {
    acceptCall,
    endConnection,
    hold,
    mute,
    rejectCall,
    resume,
    unmute,
} from 'store/call/call.actions'
import RootState from 'store/state'

import * as CallActions from '../call/call.reducer'
import * as WebsocketCompanionActions from './websocketCompanion.reducer'
import { WebSocketCompanionActions } from './websocketCompanion.state'

import type { IWebSocketParameters } from '@missionlabs/smartagent-lib-shared'

export function createWebSocketCompanionConnection(webSocketConfig: IWebSocketParameters) {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            CallActions.CallAction | WebsocketCompanionActions.WebsocketCompanionAction
        >,
    ) => {
        const socket = new WSCompanionClient(webSocketConfig)

        socket.addListener(WebSocketCompanionActions.ON_CALL_ANSWERED, () => {
            dispatch(acceptCall())
        })
        socket.addListener(WebSocketCompanionActions.ON_CALL_REJECTED, () => {
            dispatch(rejectCall())
        })
        socket.addListener(
            WebSocketCompanionActions.ON_CONNECTION_END,
            ({ detail }: { detail: { connectionId: string } }) => {
                dispatch(endConnection(detail.connectionId))
            },
        )
        socket.addListener(
            WebSocketCompanionActions.ON_CALL_HOLD,
            ({ detail }: { detail: { connectionId: string } }) => {
                dispatch(hold(detail.connectionId))
            },
        )
        socket.addListener(
            WebSocketCompanionActions.ON_CALL_RESUME,
            ({ detail }: { detail: { connectionId: string } }) => {
                dispatch(resume(detail.connectionId))
            },
        )
        socket.addListener(WebSocketCompanionActions.ON_CALL_MUTE, () => {
            dispatch(mute())
        })
        socket.addListener(WebSocketCompanionActions.ON_CALL_UNMUTE, () => {
            dispatch(unmute())
        })

        dispatch(WebsocketCompanionActions.createWebsocketCompanionConnection(socket))
    }
}

import { ContactItem, ContactName } from 'components'
import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import CallState from 'store/call/call.state'

import { setRedirect } from 'store/global/global.actions'
import { formatPhoneNumber } from 'utils'
import styles from './CallListItem.module.scss'

interface Props {
    call: CallState & { acw?: boolean }
    selected: boolean
    index: number
}

const CallListItem: React.FC<Props> = ({ call, selected, index }) => {
    const dispatch = useDispatch()

    const onSelectCall = () => {
        dispatch(setRedirect('/'))
    }

    const number = useMemo(() => formatPhoneNumber(call.number), [call.number])

    return (
        <ContactItem
            onSelect={onSelectCall}
            acw={call.acw}
            selected={selected}
            index={index}
            disabled={false}
            isMissed={false}
            bodyClassName={styles.body}
        >
            <div className={styles.wrapper}>
                <div className={styles.contactName}>
                    <ContactName className={styles.header} name={number} />
                </div>

                <p className={`${styles.description}`}>Call</p>
            </div>
        </ContactItem>
    )
}

export default CallListItem

import type { ThunkDispatch } from '@reduxjs/toolkit'
import { createNotification } from 'store/notification/notification.actions'
import { NotificationAction } from 'store/notification/notification.reducer'

import logger from '../../../logger'
import * as TeamsAPI from '../../../services/api/api.teams'
import RootState from '../../../store/state'
import { teamsParser, teamsStatusesParser } from '../teams/parsers'
import * as TeamsDirectoryActions from './reducers'

export const getTeamsContacts =
    () =>
    async (
        dispatch: ThunkDispatch<RootState, undefined, TeamsDirectoryActions.TeamDirectoryAction>,
        getState: () => RootState,
    ) => {
        const { directory } = getState()

        try {
            if (!directory.teams.isTeamsContactsLoading && directory.teams.contacts.length === 0) {
                const { app, auth } = getState()

                dispatch(TeamsDirectoryActions.teamsContactsLoading(true))

                const teamsData = await TeamsAPI.getContacts(app.ID, app.instance!.ID, auth.token!)

                const parsedTeamsContacts = teamsParser(teamsData ?? [])

                dispatch(TeamsDirectoryActions.getTeamsContacts(parsedTeamsContacts))
            }
        } catch (error) {
            dispatch(TeamsDirectoryActions.teamsContactsLoading(false))

            const errorMsg = 'Failed to fetch Teams contacts'

            logger.error('GET-TEAMS-CONTACTS', error as object)

            dispatch(TeamsDirectoryActions.teamsError(errorMsg))
        }
    }

export const getTeamsContactsStatuses =
    () =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            TeamsDirectoryActions.TeamDirectoryAction | NotificationAction
        >,
        getState: () => RootState,
    ) => {
        try {
            const { app, auth } = getState()

            dispatch(TeamsDirectoryActions.teamsStatusesLoading(true))

            const { teamsStatuses, updateTimeDelta } = await TeamsAPI.getContactsStatuses(
                app.ID,
                app.instance!.ID,
                auth.token!,
            )

            const teamsStatusesUpdateTimeThresholdMs = 300000

            // Show a notification warning if Teams statuses were updated 5 or more minutes ago
            if (updateTimeDelta >= teamsStatusesUpdateTimeThresholdMs) {
                dispatch(
                    createNotification({
                        header: 'Warning!',
                        text: 'There are temporary problems with extracting of Teams data. Information about Teams users and their statuses may be obsolete.',
                        type: 'warning',
                    }),
                )
            }

            const parsedTeamsStatuses = teamsStatusesParser(teamsStatuses.data)

            dispatch(TeamsDirectoryActions.getTeamsStatuses(parsedTeamsStatuses))
        } catch (error) {
            dispatch(TeamsDirectoryActions.teamsStatusesLoading(false))

            const errorMsg = 'Failed to fetch Teams statuses'

            logger.error('GET-TEAMS-STATUSES', error as object)

            dispatch(TeamsDirectoryActions.teamsError(errorMsg))
        }
    }

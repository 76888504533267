import type { ThunkDispatch } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import * as CompanyApi from 'services/api/api.company'
import * as QaApi from 'services/api/api.qa'
import { CTR } from 'store/contact/contact.state'
import { setRedirect } from 'store/global/global.actions'
import { GlobalAction } from 'store/global/global.reducer'
import { createNotification } from 'store/notification/notification.actions'
import { NotificationAction } from 'store/notification/notification.reducer'
import RootState from 'store/state'
import { logout } from 'store/user/user.actions'
import { TemplateForm_Form } from 'views/QA/Templates/TemplateForm/TemplateForm.types'

import * as QAActions from './qa.reducer'
import { CalibrationStatus, IScore } from './qa.state'

export const {
    updateScoreLoading,
    setUnreadScoresCount,
    scoreCalculate,
    setCurrentScore,
    setDeletingScore,
    getScoreLoading,
    setShowNewQAScore,
    clearCalibration,
    searchScoresLoading,
    clearScore,
    setScoreSearchParams,
    searchCalibrationsLoading,
} = QAActions

export function changeCalibrationStatus(calibrationID: string, status: CalibrationStatus) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, QAActions.QAAction>,
        getState: () => RootState,
    ) => {
        try {
            dispatch(QAActions.calibrationBusy(calibrationID))
            const companyID = getState().app.ID
            const token = getState().auth.token!

            const response = await QaApi.putCalibrationStatus(
                companyID,
                token,
                calibrationID,
                status,
            )

            dispatch(QAActions.changeCalibrationStatus(response.data))
        } catch (err) {
            console.log('error changing calibration status', err)
        }
    }
}

export function getFullCalibration(calibrationID: string) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, QAActions.QAAction>,
        getState: () => RootState,
    ) => {
        if (getState().qa.currentCalibration?.ID === calibrationID) return

        try {
            dispatch(QAActions.calibrationBusy(calibrationID))
            const companyID = getState().app.ID
            const token = getState().auth.token!

            const response = await QaApi.getFullCalibration(companyID, token, calibrationID)

            dispatch(QAActions.getFullCalibration(response.data))
        } catch (err) {
            console.log('error getting full calibration object', err)
        }
    }
}

export function postNewCalibrationScore(calibrationID: string, redirect?: boolean) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, QAActions.QAAction | GlobalAction>,
        getState: () => RootState,
    ) => {
        const companyID = getState().app.ID
        const token = getState().auth.token!

        dispatch(QAActions.calibrationBusy(calibrationID))

        const response = await QaApi.postCalibrationScore(companyID, token, calibrationID)

        dispatch(QAActions.setCurrentScore(response.data))
        dispatch(QAActions.calibrationBusy(undefined))

        if (redirect) dispatch(setRedirect(`/qa/scores/${response.data.ID}`))
    }
}

export function searchCalibrations(queryString: string) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, QAActions.QAAction>,
        getState: () => RootState,
    ) => {
        dispatch(QAActions.searchCalibrationsLoading())

        try {
            const companyID = getState().app.ID
            const token = getState().auth.token!

            const response = await QaApi.getCalibrations(companyID, token, queryString)
            dispatch(QAActions.searchCalibrations(response.data.data))
        } catch (err) {
            if (err.response?.status === 403) return dispatch(logout())
            else dispatch(QAActions.searchCalibrationsError())
        }
    }
}

export function startNewCalibration(scoreID: string, evaluatorID: string) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, QAActions.QAAction>,
        getState: () => RootState,
    ) => {
        try {
            const companyID = getState().app.ID
            const token = getState().auth.token!

            await QaApi.startCalibration(companyID, token, scoreID, evaluatorID)

            dispatch(QAActions.startCalibration({ scoreID, evaluatorID }))
        } catch (err) {
            console.log('error creating new calibration', err)
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())
        }
    }
}

export function newScore(score: IScore, contact?: CTR, redirect?: boolean) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, QAActions.QAAction | GlobalAction>,
        getState: () => RootState,
    ) => {
        dispatch(updateScoreLoading())

        if (!score.instanceID) {
            score.instanceID = getState().app.instance?.ID
        }

        try {
            const companyID = getState().app.ID
            const token = getState().auth.token!

            dispatch(QAActions.creatingScore())

            const newScore = await QaApi.postScore(companyID, token, score)

            dispatch(QAActions.newScore({ contact, newScore: newScore.data }))

            if (redirect) dispatch(setRedirect(`/qa/scores/${newScore.data.ID}`))
        } catch (err) {
            console.log('error updating score', err)
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())
        }
    }
}

export function updateScore(score: IScore) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, QAActions.QAAction>,
        getState: () => RootState,
    ) => {
        dispatch(updateScoreLoading())

        try {
            const companyID = getState().app.ID
            const token = getState().auth.token!

            const updatedScore = await QaApi.putScore(companyID, token, score)

            dispatch(QAActions.updateScore(updatedScore.data))
        } catch (err) {
            console.log('error updating score', err)
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())
        }
    }
}

export function deleteScore(scoreID: string) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, QAActions.QAAction | NotificationAction>,
        getState: () => RootState,
    ) => {
        dispatch(QAActions.deleteScoreLoading())

        try {
            const companyID = getState().app.ID
            const token = getState().auth.token!

            await QaApi.deleteScore(companyID, token, scoreID)

            dispatch(QAActions.deleteScore(scoreID))

            dispatch(
                createNotification({
                    type: 'success',
                    header: 'Evaluation Deleted',
                    text: `Evaluation was deleted successfully`,
                }),
            )
        } catch (err) {
            console.log('error getting score', err)
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())
        }
    }
}

export function getMyScores() {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, QAActions.QAAction>,
        getState: () => RootState,
    ) => {
        dispatch(QAActions.myScoresLoading())

        try {
            const companyID = getState().app.ID
            const token = getState().auth.token!
            const agentID = getState().user?.agentID
            const scores = await QaApi.searchScores(
                companyID,
                token,
                `agentID=${agentID}&status=!IN_PROGRESS`,
            )

            return dispatch(QAActions.getMyScores(scores.data.data))
        } catch (err) {
            console.log('error getting me scores', err)
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())

            dispatch(QAActions.myScoresError())
        }
    }
}

export function searchScores(queryString: string) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, QAActions.QAAction>,
        getState: () => RootState,
    ) => {
        dispatch(searchScoresLoading())

        try {
            const companyID = getState().app.ID
            const token = getState().auth.token!
            const scores = await QaApi.searchScores(companyID, token, queryString)

            return dispatch(QAActions.searchScores(scores.data.data))
        } catch (err) {
            console.log('error searching scores', err)
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())

            dispatch(QAActions.searchScoresError())
        }
    }
}

export function getScore(scoreID: string) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, QAActions.QAAction>,
        getState: () => RootState,
    ) => {
        dispatch(getScoreLoading(scoreID))

        try {
            const companyID = getState().app.ID
            const token = getState().auth.token!

            const score = await QaApi.getScore(companyID, token, scoreID)

            dispatch(setCurrentScore(score.data))
        } catch (err) {
            console.log('error getting score', err)
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())
        }
    }
}

export function getQATemplateList() {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, QAActions.QAAction>,
        getState: () => RootState,
    ) => {
        try {
            const companyID = getState().app.ID
            const token = getState().auth.token!
            const response = await CompanyApi.getFormTemplateList(companyID, token)
            dispatch(QAActions.getQATemplates(response.data))
        } catch (ex) {
            console.log('error getting qa template list', ex)
        }
    }
}

export function createQATemplate(template: TemplateForm_Form) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, QAActions.QAAction | NotificationAction>,
        getState: () => RootState,
    ) => {
        try {
            const companyID = getState().app.ID
            const token = getState().auth.token!
            await CompanyApi.createFormTemplate(companyID, template, token)
            dispatch(QAActions.createQATemplate())

            dispatch(
                createNotification({
                    type: 'success',
                    header: 'Template Saved',
                    text: `Template: ${template.name} was saved successfully`,
                }),
            )
        } catch (ex) {
            console.log('error creating qa template', ex)
        }
    }
}

export function editQATemplate(template: TemplateForm_Form) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, QAActions.QAAction | NotificationAction>,
        getState: () => RootState,
    ) => {
        try {
            const companyID = getState().app.ID
            const token = getState().auth.token!
            const templateID = template.ID!
            templateID && delete template.ID
            await CompanyApi.editFormTemplate(companyID, templateID, template, token)

            dispatch(QAActions.editQATemplate())

            dispatch(
                createNotification({
                    type: 'success',
                    header: 'Template Saved',
                    text: `Template: ${template.name} was saved successfully`,
                }),
            )
        } catch (ex) {
            console.log('error editing qa template', ex)
        }
    }
}

export function deleteQATemplate(formID: string) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, QAActions.QAAction | NotificationAction>,
        getState: () => RootState,
    ) => {
        try {
            const companyID = getState().app.ID
            const token = getState().auth.token!
            await CompanyApi.deleteFormTemplate(companyID, formID, token)

            dispatch(QAActions.deleteQATemplate())

            dispatch(
                createNotification({
                    type: 'success',
                    header: 'Template Deleted',
                    text: `Template deleted successfully`,
                }),
            )
        } catch (ex) {
            console.log('error deleting qa template', ex)
        }
    }
}

export function getEvaluationConfig() {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, QAActions.QAAction>,
        getState: () => RootState,
    ) => {
        const { app, auth } = getState()
        const companyID = app.ID
        const token = auth.token!

        dispatch(QAActions.getEvaluationConfigLoading(true))
        try {
            const evaluationConfig = await QaApi.getEvaluationConfig(companyID, token)
            dispatch(QAActions.getEvaluationConfig(evaluationConfig))
        } catch (err) {
            console.error('Error getting evaluation config:', err)
            const e = err as AxiosError

            if (e.response?.status === 403) return dispatch(logout())

            dispatch(QAActions.getEvaluationConfigError(true))
        }
    }
}

export function getUnreadScoresCount() {
    return async (
        dispatch: ThunkDispatch<RootState, void, QAActions.QAAction>,
        getState: () => RootState,
    ) => {
        const { auth, app } = getState()
        const companyID = app.ID
        const token = auth.token!

        try {
            const response = await QaApi.getUnreadScoresCount(companyID, token)
            response !== undefined && dispatch(setUnreadScoresCount(response.data))
        } catch (error) {
            console.error('Error getting unread scores count : ', error)
        }
    }
}

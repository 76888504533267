import InfoErrorIcon from 'images/info-error.svg'
import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { ClipLoader } from 'react-spinners'
import RootState from 'store/state'
import { forgotPassword, login } from 'store/user/user.actions'

import { Button, Form, Input } from '@missionlabs/smartagent-app-components'

import ForgotPassword from '../ForgotPassword'
import LoginSplash from '../LoginSplash'
import MFARequired from '../MFARequired'
import NewPasswordRequired from '../NewPasswordRequired'

interface Props extends ReturnType<typeof mapStateToProps> {
    login: (data: { username: string; password: string }) => void
    forgotPassword(): void
}

class LoginUsernamePassword extends PureComponent<Props> {
    readonly state = {
        user: '',
        pass: '',
        splash: true,
    }

    componentDidUpdate(prevProps: Props) {
        if (
            (prevProps.newPasswordRequired && !this.props.newPasswordRequired) ||
            (prevProps.userForgotPassword && !this.props.userForgotPassword)
        )
            this.setState({
                user: '',
                pass: '',
                splash: true,
            })
    }

    onTokenChange = (token: string) => {
        this.setState({ token })
    }

    onAuth = () => {
        const { user, pass } = this.state
        if (user && pass) {
            return this.props.login({ username: user, password: pass })
        }
    }
    onRetry = () => {
        // eslint-disable-next-line
        window.location.href = window.location.href
    }

    render() {
        const {
            authError,
            appError,
            authenticating,
            authenticated,
            newPasswordRequired,
            userForgotPassword,
            mfa,
        } = this.props
        return (
            <>
                {this.state.splash ? (
                    <LoginSplash
                        onClick={() => this.setState({ splash: false })}
                        loading={authenticating || authenticated}
                    />
                ) : newPasswordRequired ? (
                    <NewPasswordRequired username={this.state.user} />
                ) : userForgotPassword ? (
                    <ForgotPassword />
                ) : mfa ? (
                    <MFARequired />
                ) : appError ? (
                    <div aria-live="polite">
                        <div className="sa-login-text sa-login-error">
                            There was a problem logging you in, please try again
                        </div>
                        <Button
                            className="sa-login-button"
                            onClick={this.onRetry}
                            styling="primary"
                        >
                            Retry
                        </Button>
                    </div>
                ) : (
                    <Form
                        onSubmit={this.onAuth}
                        className="sa-login-form row column"
                        aria-labelledby="sa--login-username-password-title"
                    >
                        <div>
                            <h1 id="sa--login-username-password-title" className="title">
                                Log in to your account
                            </h1>
                            {authError && (
                                <div className="sa-login-error" aria-live="polite">
                                    <img alt="Error" src={InfoErrorIcon} /> {authError}
                                </div>
                            )}
                        </div>
                        <Input
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus
                            className="md-mar-top"
                            onChange={(user: any) => this.setState({ user })}
                            value={this.state.user}
                            required
                            label="Username"
                            name="username"
                        />
                        <Input
                            className="md-mar-vertical"
                            onChange={(pass: any) => this.setState({ pass })}
                            value={this.state.pass}
                            required
                            type="password"
                            label="Password"
                            name="password"
                        />

                        <div className="sa-login-text">
                            <Button
                                className="forgot-password"
                                type="button"
                                elastic
                                onClick={() => this.props.forgotPassword()}
                            >
                                Don't remember your password?
                            </Button>
                        </div>

                        <Button
                            round
                            large
                            className="sa-login-button md-mar-top"
                            styling="primary"
                        >
                            {authenticating || authenticated ? (
                                <ClipLoader color="#fff" size={20} />
                            ) : (
                                'Login'
                            )}
                        </Button>
                    </Form>
                )}
            </>
        )
    }
}

function mapStateToProps(state: RootState) {
    return {
        ...state.auth,
        appError: state.app.error,
    }
}

export default connect(mapStateToProps, { login, forgotPassword })(LoginUsernamePassword)

import classNames from 'classnames'
import React from 'react'
import { IChatMessage } from 'store/chat/chat.state'

const MetaChatMessage: React.FC<IChatMessage> = ({
    ContentType,
    DisplayName,
    ParticipantRole,
    content,
}) => {
    const classes = classNames({
        'sa-chat-message-meta': true,
    })

    const participantName = DisplayName ?? ParticipantRole

    return (
        <p className={classes}>
            {(() => {
                switch (ContentType) {
                    case 'application/vnd.amazonaws.connect.event.participant.left':
                        return `${participantName} left the chat.`
                    case 'application/vnd.amazonaws.connect.event.participant.joined':
                        return `${participantName} joined the chat.`
                    case 'application/vnd.amazonaws.connect.event.chat.ended':
                        return `Chat ended.`
                    case 'application/vnd.amazonaws.connect.event.transfer.succeeded':
                        return 'Chat Transferred Successfully'
                    case 'application/vnd.amazonaws.connect.event.transfer.failed':
                        return 'Chat Transfer Failed'
                    default:
                        console.warn('Unknown message type, displaying content...')

                        return (
                            (typeof content === 'string' ? content : content.contentType) ??
                            ContentType
                        )
                }
            })()}
        </p>
    )
}

export default MetaChatMessage

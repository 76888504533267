import { makeRequest } from 'services/api'
import { IContactLog, IContactLogPreview } from 'views/AdminSettings/ContactLogs/types'

export async function getAllContactLogs(companyID: string, instanceID: string, token: string) {
    const response = await makeRequest<IContactLogPreview[]>({
        url: `/contact-log-service/companies/${companyID}/instances/${instanceID}/contact-logs`,
        headers: {
            'X-Amz-Security-Token': token,
        },
        method: 'get',
    })
    return response.data
}

export async function getContactLog(
    companyID: string,
    instanceID: string,
    contactLogID: string,
    token: string,
    groupName?: string,
) {
    const groupNameQueryParam = groupName ? `?groupName=${groupName}` : ''
    const response = await makeRequest<IContactLog>({
        url: `/contact-log-service/companies/${companyID}/instances/${instanceID}/contact-logs/${contactLogID}${groupNameQueryParam}`,
        headers: {
            'X-Amz-Security-Token': token,
        },
        method: 'get',
    })
    return response.data
}

export async function getDefaultContactLog(companyID: string, instanceID: string, token: string) {
    const response = await makeRequest<IContactLog>({
        url: `/contact-log-service/companies/${companyID}/instances/${instanceID}/contact-logs?default=true`,
        headers: {
            'X-Amz-Security-Token': token,
        },
        method: 'get',
    })
    return response.data
}

export async function addNewContactLog(
    companyID: string,
    instanceID: string,
    contactLog: IContactLog,
    token: string,
) {
    // `isLogDefault` is configured to be a string on the back end because of SmartAgent mapper limitations
    contactLog.isLogDefault = contactLog.isLogDefault.toString()
    const response = await makeRequest<number>({
        url: `/contact-log-service/companies/${companyID}/instances/${instanceID}/contact-logs/${contactLog.ID}`,
        method: 'post',
        headers: {
            'X-Amz-Security-Token': token,
        },
        data: contactLog,
    })
    return response.data
}

export async function updateContactLog(
    companyID: string,
    instanceID: string,
    updatedContactLog: IContactLog,
    token: string,
) {
    // `isLogDefault` is configured to be a string on the back end because of SmartAgent mapper limitations
    updatedContactLog.isLogDefault = updatedContactLog.isLogDefault.toString()
    const response = await makeRequest<number>({
        url: `/contact-log-service/companies/${companyID}/instances/${instanceID}/contact-logs/${updatedContactLog.ID}`,
        method: 'put',
        headers: {
            'X-Amz-Security-Token': token,
        },
        data: updatedContactLog,
    })
    return response.data
}

export async function deleteContactLog(
    companyID: string,
    instanceID: string,
    contactLogID: string,
    token: string,
) {
    const response = await makeRequest<number>({
        url: `/contact-log-service/companies/${companyID}/instances/${instanceID}/contact-logs/${contactLogID}`,
        method: 'delete',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })
    return response.data
}

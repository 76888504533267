import type { ThunkDispatch } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { subHours } from 'date-fns'
import { getCallLog as APIGetCallLog } from 'services/api/api.contact'
import { ContactInitiationMethod } from 'store/contactSearch/contactSearch.state'
import {
    getSettingsAppConfigForChannel,
    getSettingsChannelFromPath,
} from 'store/settings/settings.utils'
import RootState from 'store/state'
import { logout } from 'store/user/user.actions'
import { secondsFrom } from 'utils'

import ContactState from '../contact/contact.state'
import * as CallLogActions from './callLog.reducer'
import { ICallLog } from './callLog.state'

export function addToCallLog(contact: ContactState): CallLogActions.CallLogAction {
    const agentInteractionDuration =
        contact.agentInteractionDuration ||
        (contact.connectedToAgentTimestamp ? secondsFrom(contact.connectedToAgentTimestamp) : 0)
    const disconnectTimestamp = contact.disconnectTimestamp || Date.now()
    return CallLogActions.addToCallLog({
        ...contact,
        source: 'LOCAL',
        agentInteractionDuration,
        disconnectTimestamp,
    })
}

const DEFAULT_HISTORY_HOURS = 72

export function getCallLog() {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, CallLogActions.CallLogAction>,
        getState: () => RootState,
    ) => {
        const {
            app: { ID, instance },
            auth,
            settings: { settings },
        } = getState()
        const { token } = auth
        const settingsChannel = getSettingsChannelFromPath(window.location.pathname)
        const callHistoryHoursValue =
            getSettingsAppConfigForChannel(settings?.appConfigurations, settingsChannel)
                ?.callHistoryHours ?? DEFAULT_HISTORY_HOURS
        const dateFrom = subHours(new Date(), callHistoryHoursValue)
        try {
            const response = await APIGetCallLog(ID, instance!.ID, token!, dateFrom.toISOString())
            const callLog = response
                .filter((c) =>
                    [
                        ContactInitiationMethod.CALLBACK,
                        ContactInitiationMethod.INBOUND,
                        ContactInitiationMethod.OUTBOUND,
                        ContactInitiationMethod.API,
                    ].includes(c.initiationMethod as ContactInitiationMethod),
                )
                .map(
                    (c) =>
                        ({
                            ...c,
                            source: 'API',
                            //DPA:getDPAState(c.attributes)
                        }) as ICallLog,
                )

            dispatch(
                CallLogActions.getCallLog({
                    callLog,
                    fromTime: dateFrom.getTime(),
                }),
            )
        } catch (error) {
            console.log(error)
            const e = error as AxiosError
            if (e.response?.status === 403) return dispatch(logout())
            dispatch(CallLogActions.flushOldCallLogs())
        }
    }
}

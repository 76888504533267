import { jsx, jsxs, Fragment } from "react/jsx-runtime";
import { useState } from "react";
import classNames from "classnames";
const isURL = (str) => /https?:\/\//.test(str);
const InnerButton = ({ iconURL, title }) => /* @__PURE__ */ jsxs(Fragment, { children: [
  !!iconURL && /* @__PURE__ */ jsx("img", { "aria-hidden": "true", src: iconURL, alt: `${title} icon` }),
  /* @__PURE__ */ jsx("h1", { children: title })
] });
const BigButtons = ({ ariaId, buttons, sendMessage }) => {
  const [disabled, setDisabled] = useState(false);
  return /* @__PURE__ */ jsx(
    "div",
    {
      id: ariaId,
      "aria-label": "Button list",
      className: classNames({
        "sa-chat-message-bigbuttons": true,
        disabled: !!disabled
      }),
      role: "region",
      children: /* @__PURE__ */ jsx("ul", { children: buttons.map((btn) => /* @__PURE__ */ jsx("li", { children: isURL(btn.payload) ? /* @__PURE__ */ jsx(
        "a",
        {
          "aria-label": btn.title,
          rel: "noopener noreferrer",
          target: "_blank",
          href: btn.payload,
          children: /* @__PURE__ */ jsx(InnerButton, { iconURL: btn.iconURL, title: btn.title })
        }
      ) : /* @__PURE__ */ jsx(
        "button",
        {
          "aria-label": btn.title,
          "aria-disabled": disabled,
          disabled,
          onClick: () => {
            setDisabled(true);
            sendMessage(btn.payload, btn.title);
          },
          children: /* @__PURE__ */ jsx(InnerButton, { iconURL: btn.iconURL, title: btn.title })
        }
      ) }, btn.title)) })
    }
  );
};
export {
  BigButtons as default
};

import { useSelector } from 'react-redux'
import RootState from 'store/state'
import { ITask } from 'store/tasks/tasks.state'

function useTaskConnections(status: 'incoming' | 'selected'): ITask | undefined
function useTaskConnections(): ITask[]
function useTaskConnections(status?: 'incoming' | 'selected') {
    const tasks = useSelector<RootState, ITask | ITask[] | undefined>(({ tasks, contact }) => {
        if (status === 'incoming')
            return tasks?.connections.find((c) => c.status === connect.ContactStateType.CONNECTING)
        if (status === 'selected')
            return contact?.ID ? tasks?.connections.find((c) => c.id === contact.ID) : undefined

        return tasks?.connections
    })

    return tasks
}

export default useTaskConnections

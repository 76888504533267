import WebsocketWrapper from '@missionlabs/smartagent-lib-shared/build/clients/websocket/websocket'

export const addBaseListeners = (websocket: WebsocketWrapper) => {
    websocket.addListener('connect', () => {
        console.log('Websocket has connected')
    })

    websocket.addListener('disconnect', (data: CloseEvent) => {
        console.log(`Websocket has disconnected, ${JSON.stringify(data, null, 2)}`)
    })

    websocket.addListener('error', (data: Event) => {
        console.error(`Websocket error, ${JSON.stringify(data, null, 2)}`)
    })
}

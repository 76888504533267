import { useSelector } from 'react-redux'
import RootState from 'store/state'

export default function useQueuedTasks(): RootState['queuedTasks'] {
    const queuedTasks = useSelector<RootState, RootState['queuedTasks']>(
        ({ queuedTasks }) => queuedTasks,
    )

    return queuedTasks
}

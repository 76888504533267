import type { Middleware } from '@reduxjs/toolkit'
import { makeCall } from 'store/call/call.actions'
import * as CallReducer from 'store/call/call.reducer'
import RootState from 'store/state'

import { isAnyAction } from 'utils'

const MESSAGE_TYPE = 'smartagent-api'

interface SAMessage {
    type: string
    event: string
    message: any
}

const parentMessengerMiddleware: Middleware<{}, RootState> = (store) => {
    //If not in iframe then ignore middleware
    if (window.parent === window) return (next) => (action) => next(action)

    window.addEventListener('message', (message: MessageEvent) => {
        if (!message.data || message.data.type !== MESSAGE_TYPE) return
        const saMessage = message.data as SAMessage
        switch (saMessage.event) {
            case 'make_call':
                return store.dispatch(makeCall(saMessage.message.number, saMessage.message.name))
            default:
                console.log('unknown message event', saMessage.event)
                return
        }
    })

    return (next) => async (action) => {
        if (!isAnyAction(action)) return

        switch (action.type) {
            case CallReducer.incomingCall.type:
                postMessageToParent('incoming_call', action.payload)
                return next(action)
            case CallReducer.makeCall.type:
                postMessageToParent('outgoing_call', action.payload)
                return next(action)
            default:
                return next(action)
        }
    }
}

const postMessageToParent = (event: string, message?: any) => {
    try {
        window.parent.postMessage(
            {
                type: MESSAGE_TYPE,
                event,
                message,
            },
            '*',
        )
    } catch (ex) {
        console.log('error posting message to parent', ex)
    }
}

export default parentMessengerMiddleware

import { CallLog } from 'components'
import PhoneLib from 'google-libphonenumber'
import { memo } from 'react'
import { ICallLog } from 'store/callLog/callLog.state'
import RootState from 'store/state'
import { formatPhoneNumber, getDirectoryContactByPhoneNumber } from 'utils'

interface Props {
    callLog: RootState['callLog']
    contact: RootState['contact']
    contacts: RootState['directory']['restructuredDirectoryContacts']['contactsWithKeyAsPhoneNumber']
    onClick: (contact: ICallLog) => void
}

const CallHistoryList = ({ contact, callLog, contacts, onClick }: Props) => (
    <>
        {!!callLog.length && (
            <section className="call-history">
                <ul>
                    {callLog.map((loggedContact, i) => {
                        const phoneNumber = formatPhoneNumber(
                            loggedContact.attributes['sa-dialled-number'] ||
                                loggedContact.customerEndpointAddress,
                            PhoneLib.PhoneNumberFormat.E164,
                        )

                        const directoryContact = getDirectoryContactByPhoneNumber(
                            contacts,
                            phoneNumber,
                        )

                        return (
                            <CallLog
                                key={i}
                                directoryContact={directoryContact}
                                selected={loggedContact.ID === contact?.ID}
                                onClick={onClick}
                                contact={loggedContact}
                            />
                        )
                    })}
                </ul>
            </section>
        )}
    </>
)

export default memo(CallHistoryList)

import classNames from 'classnames'
import React, { useState } from 'react'

import { Button, TriggeredPopup } from '@missionlabs/smartagent-app-components'

import HoldIcon from 'components/ParticipantList/MultiPartyControls/HoldIcon'

import styles from './multi-party-controls.module.scss'

import Keypad from 'components/Keypad'
import declineIcon from 'images/button-decline.svg'
import KeypadBtn from 'images/button-keypad.svg'
import unmuteIcon from 'images/button-sound-off.svg'
import muteIcon from 'images/button-sound-on.svg'
import transferIcon from 'images/button-transfer.svg'
import { getBodyWidth } from 'utils'

export type Props = {
    canMute?: boolean
    isMuted?: boolean
    onMute?: () => void
    canHold?: boolean
    isOnHold?: boolean
    onHold?: () => void
    canEnd?: boolean
    onEnd?: () => void
    endIcon?: string
    isTransferCallOpen?: boolean
    setIsTransferCallOpen?: (isTransferCallOpen: boolean) => void
    canTransfer?: boolean
    onKeypadButton?: (btn: string) => void
    canUseKeypad?: boolean
}

const MultiPartyControls: React.FC<Props> = ({
    isMuted = false,
    onMute,
    canMute = false,
    isOnHold = false,
    onHold,
    canHold = false,
    canEnd = false,
    onEnd,
    endIcon = declineIcon,
    isTransferCallOpen = false,
    setIsTransferCallOpen,
    canTransfer = false,
    canUseKeypad = false,
    onKeypadButton = () => {},
}) => {
    const { button, filled } = styles
    const [isKeypadOpen, setIsKeypadOpen] = useState(false)

    return (
        <>
            {canTransfer && (
                <Button
                    className={classNames(button, { [`${filled}`]: isTransferCallOpen })}
                    onClick={() => setIsTransferCallOpen?.(!isTransferCallOpen)}
                >
                    <img
                        src={transferIcon}
                        alt="Transfer call"
                        width={24}
                        height={24}
                        title="Transfer call"
                    />
                </Button>
            )}
            {canMute && (
                <Button className={styles.button} onClick={onMute}>
                    <img
                        src={!isMuted ? muteIcon : unmuteIcon}
                        alt={!isMuted ? 'Mute participant' : 'Unmute participant'}
                        width={24}
                        height={24}
                        title={!isMuted ? 'Mute participant' : 'Unmute participant'}
                    />
                </Button>
            )}
            {canHold && (
                <Button className={styles.button} onClick={onHold}>
                    <HoldIcon isOnHold={isOnHold} />
                </Button>
            )}
            {canUseKeypad && (
                <TriggeredPopup
                    noShadow
                    className={styles.keypadPopup}
                    fromRight={getBodyWidth() > 500}
                    closeOnClickOutside
                    trigger={
                        <Button
                            className={classNames(button, { [`${filled}`]: isKeypadOpen })}
                            styling="blank"
                            type="button"
                            onClick={() => setIsKeypadOpen((isOpen) => !isOpen)}
                        >
                            <img src={KeypadBtn} alt="Keypad" title="Keypad" />
                        </Button>
                    }
                >
                    <Keypad onKeypadButton={onKeypadButton} />
                </TriggeredPopup>
            )}
            {canEnd && (
                <Button onClick={onEnd}>
                    <img src={endIcon} alt="End call" width={40} height={40} title="End call" />
                </Button>
            )}
        </>
    )
}

export default MultiPartyControls

import { ICallContactAttributes } from 'store/contact/contact.state'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import QueuedTasksState, { ProcessStatuses, QuickReplyText } from './queuedTasks.state'

const initialState: QueuedTasksState = {
    queuedTasksContacts: [],
    queuedTasksReplyText: {},
    queuedTasksContactAttributes: {},
    queuedTasksProcessing: false,
    queuedTasksProcessingShowModal: false,
    queuedTasksProcessingContacts: [],
    queuedTasksProcessingStatuses: {
        completed: 0,
        processing: 0,
        errored: 0,
        actioned: 0,
    },
}

export const queuedTasksSlice = createSlice({
    name: 'queuedTasks',
    initialState,
    reducers: {
        updateACWAttributesQueuedTasks(
            state,
            action: PayloadAction<{ acwAttributes: ICallContactAttributes; IDs: string[] }>,
        ) {
            return {
                ...state,
                queuedTasksContactAttributes: {
                    ...state.queuedTasksContactAttributes,
                    ...action.payload.acwAttributes,
                },
                queuedTasksContacts: action.payload.IDs,
            }
        },
        clearACWAttributesQueuedTasks(state) {
            return {
                ...state,
                queuedTasksContactAttributes: {},
                queuedTasksContacts: [],
            }
        },
        setQuickReplyRichText(state, action: PayloadAction<QuickReplyText>) {
            return { ...state, quickReplyPlainText: {}, quickReplyRichText: action.payload }
        },
        setQuickReplyPlainText(state, action: PayloadAction<QuickReplyText>) {
            return { ...state, quickReplyRichText: {}, quickReplyPlainText: action.payload }
        },
        setQueuedTasksProcessing(state, action: PayloadAction<boolean>) {
            return { ...state, queuedTasksProcessing: action.payload }
        },
        setQueuedTasksProcessingShowModal(state, action: PayloadAction<boolean>) {
            return { ...state, queuedTasksProcessingShowModal: action.payload }
        },
        setQueuedTasksProcessingContacts(state, action: PayloadAction<string[]>) {
            return { ...state, queuedTasksProcessingContacts: action.payload }
        },
        setQueuedTasksProcessStatuses(state, action: PayloadAction<ProcessStatuses>) {
            return { ...state, queuedTasksProcessingStatuses: action.payload }
        },
    },
})

export const {
    updateACWAttributesQueuedTasks,
    clearACWAttributesQueuedTasks,
    setQuickReplyRichText,
    setQuickReplyPlainText,
    setQueuedTasksProcessing,
    setQueuedTasksProcessingShowModal,
    setQueuedTasksProcessingContacts,
    setQueuedTasksProcessStatuses,
} = queuedTasksSlice.actions

export type QueuedTasksAction = ReturnType<
    (typeof queuedTasksSlice.actions)[keyof typeof queuedTasksSlice.actions]
>

export default queuedTasksSlice.reducer

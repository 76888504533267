import { Box, Button } from '@missionlabs/smartagent-app-components'
import classNames from 'classnames'
import React from 'react'
import { ClipLoader } from 'react-spinners'
import useContact from '../../../hooks/redux/useContact'
import './callrecordingcontrol.scss'
import useRecordingControl from './useRecordingControl'

const CallRecordingControl: React.FC = () => {
    const { loading, toggleRecording, recordingStatus } = useRecordingControl()
    const contact = useContact()
    const callRecordingDisplayMode: string | undefined =
        contact?.attributes?.['sa-recording-button']
    const callRecordingBehaviour: string | undefined =
        contact?.attributes?.['sa-call-recording-behaviour']
    const shouldDisplayRecordingControl =
        callRecordingBehaviour && callRecordingDisplayMode && callRecordingDisplayMode === 'SHOW'

    const classes = classNames({
        'sa-callrecordingcontrol row': true,
        on: recordingStatus === 'on',
    })

    return (
        <>
            {shouldDisplayRecordingControl && (
                <Box>
                    <Button className={classes} onClick={toggleRecording}>
                        <div className="row">
                            <div className="sa-callrecordingcontrol-status">&nbsp;</div>
                            <span>
                                {!recordingStatus
                                    ? 'Start'
                                    : recordingStatus === 'off'
                                      ? 'Resume'
                                      : 'Pause'}{' '}
                                Recording{' '}
                            </span>
                        </div>
                        {loading && <ClipLoader size={14} color={'#fff'} />}
                    </Button>
                </Box>
            )}
        </>
    )
}

export default CallRecordingControl

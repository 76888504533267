import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'

export const baseURL =
    process.env.REACT_APP_ENV === 'prod'
        ? `services.smartagent.app`
        : process.env.REACT_APP_ENV === 'beta'
          ? `services.smartagent.app`
          : process.env.REACT_APP_ENV === 'uat'
            ? `uat-services.smartagent.app`
				: `dev-services.smartagent.app`

interface IMakeRequest {
    url: string
    base?: string
    data?: any
    headers?: any
    method?: 'get' | 'put' | 'post' | 'delete' | 'patch'
    params?: any
    signal?: AbortSignal
    ignoreToken?: boolean
}

export interface CustomAxiosConfig extends AxiosRequestConfig {
    ignoreToken?: boolean
}

interface CustomAxiosPromise<T = any> extends AxiosPromise<T> {
    config: CustomAxiosConfig
}

export function makeRequest<T = any>({
    url,
    method = 'get',
    data,
    headers,
    params,
    signal,
    base,
    ignoreToken = false,
}: IMakeRequest) {
    if (process.env.NODE_ENV === 'test') {
        const testName = (typeof expect !== "undefined") ? expect?.getState().testPath : undefined;
        console.warn(`A request was made in the tests to ${method.toUpperCase()} ${url} in test: ${testName}`)
	}

    const serviceRegion = localStorage.getItem('serviceRegion');
    const serviceRegionURL = serviceRegion ? serviceRegion + '.' : '';

	const serviceURL = base ?? 'https://' + serviceRegionURL + baseURL

    return axios({
        baseURL: serviceURL,
        url,
        method,
        data,
        headers,
        params,
        signal,
        ignoreToken,
    } as CustomAxiosConfig) as CustomAxiosPromise<T>
}

import { DirectorySourceTypes } from '../directory.state'
import { IExternalDirectoryRecord } from './state'

const directoryContactsParser = (contacts: IExternalDirectoryRecord[]) =>
    contacts.map((contact) => directoryContactParser(contact))

export const directoryContactParser = (
    contact: IExternalDirectoryRecord,
): IExternalDirectoryRecord => ({
    sourceType: DirectorySourceTypes.EXTERNAL_DIRECTORY,
    ...contact,
})

export default directoryContactsParser

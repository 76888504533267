import type { CallConnection } from 'store/callV2/callV2.state'

type ControlPermissions = {
    swap: boolean
    join: boolean
}

export const getActiveConnections = (connections: CallConnection[]): CallConnection[] => {
    return connections.filter(
        ({ status }) => status.type !== connect.ConnectionStateType.DISCONNECTED,
    )
}

export const getConnectionList = (connections: CallConnection[]): CallConnection[] => {
    const youConnection = connections.find(({ type }) => type === 'YOU')
    const otherConnections = connections.filter(
        ({ status, type }) =>
            type !== 'YOU' &&
            (status.type !== connect.ConnectionStateType.DISCONNECTED || type === 'CUSTOMER'),
    )

    return youConnection ? [youConnection, ...otherConnections] : otherConnections
}

export const getControlPermissions = (connections: CallConnection[]): ControlPermissions => {
    let swap: boolean = false
    let join: boolean = false

    const activeConnections = getActiveConnections(connections)

    const hasConnectingConnection = activeConnections.some(
        ({ status }) => status.type === connect.ConnectionStateType.CONNECTING,
    )
    if (hasConnectingConnection) return { swap, join }

    const areYouMonitoring: boolean = connections.some(
        ({ type, status }) =>
            type === 'YOU' && status.type === connect.ConnectionStateType.SILENT_MONITOR,
    )
    if (areYouMonitoring) return { swap, join }

    const hasNonYouBargerJoined: boolean = activeConnections.some(
        ({ type, isMonitoringOrBarging }) => isMonitoringOrBarging && type !== 'YOU',
    )
    if (hasNonYouBargerJoined) return { swap, join }

    const onHoldUsers = activeConnections.filter(
        ({ status }) => status.type === connect.ConnectionStateType.HOLD,
    )

    const isOtherParticipantOnHold: boolean = onHoldUsers.some(({ type }) => type !== 'YOU')

    const areMultipleUsersOnHold: boolean = onHoldUsers.length > 1

    const areYouOnHold: boolean = onHoldUsers.some(({ type }) => type === 'YOU')

    const isCustomerConnected = activeConnections.some(({ type }) => type === 'CUSTOMER')

    swap =
        activeConnections.length === 3 &&
        isCustomerConnected &&
        isOtherParticipantOnHold &&
        !areMultipleUsersOnHold

    join = isOtherParticipantOnHold && !areYouOnHold

    return { swap, join }
}

import type { ISettings } from '@missionlabs/smartagent-lib-shared/build/settings'
import { AxiosResponse } from 'axios'
import { makeRequest } from 'services/api'
import { InstanceSettings } from 'store/app/app.state'
import { IQATemplate } from 'store/qa/qa.state'
import { paramsToQueryString } from 'utils'

export async function getCompany(companyID: string) {
    const response = await makeRequest({
        url: `/company-service/companies/${companyID}`,
    })
    return response.data
}

export async function getCompanyGroup(groupID: string) {
    const response = await makeRequest({
        url: `/company-service/companies/?groupName=${groupID}`,
    })

    return response.data
}

export async function reportIssue(companyID: string, data: any) {
    return await makeRequest({
        url: `/company-service/companies/${companyID}/issues`,
        method: 'post',
        data,
    })
}

export async function getFormTemplate(
    companyID: string,
    formID: string,
    token: string,
    formGroup?: string,
) {
    const params = paramsToQueryString({ formGroup })
    const response = await makeRequest({
        url: `/company-service/companies/${companyID}/forms/${formID}?${params}`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })
    return response.data
}

export async function getFormTemplateList(companyID: string, token: string, type: string = 'qa') {
    const qs = type ? `?formType=${type}` : ''

    const response = await makeRequest({
        url: `/company-service/companies/${companyID}/forms/${qs}`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return { ...response, data: [...response.data.data] } as AxiosResponse<IQATemplate[]>
}

export async function createFormTemplate(companyID: string, template: any, token: string) {
    const response = await makeRequest({
        url: `/company-service/companies/${companyID}/forms`,
        method: 'post',
        headers: {
            'X-Amz-Security-Token': token,
        },
        data: {
            companyID,
            formType: 'qa',
            ...template,
        },
    })
    return response.data
}

export async function editFormTemplate(
    companyID: string,
    formID: string,
    template: any,
    token: string,
) {
    const response = await makeRequest({
        url: `/company-service/companies/${companyID}/forms/${formID}`,
        method: 'put',
        headers: {
            'X-Amz-Security-Token': token,
        },
        data: {
            companyID,
            formType: 'qa',
            ...template,
        },
    })
    return response.data
}

export async function deleteFormTemplate(companyID: string, formID: string, token: string) {
    const response = await makeRequest({
        url: `/company-service/companies/${companyID}/forms/${formID}`,
        method: 'delete',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })
    return response.data
}

export async function putInstanceSettings(
    companyID: string,
    token: string,
    instanceID: string,
    settings: Partial<InstanceSettings>,
) {
    const response = await makeRequest<InstanceSettings>({
        url: `/company-service/companies/${companyID}/instances/${instanceID}/settings`,
        method: 'put',
        data: settings,
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

export async function getSettings(companyID: string, token: string) {
    const response = await makeRequest<ISettings>({
        url: `/company-service/companies/${companyID}/settings`,
        headers: {
            'X-Amz-Security-Token': token,
        },
    })
    return response.data
}

export async function putSettings(companyID: string, token: string, data: Partial<ISettings>) {
    const response = await makeRequest<ISettings>({
        url: `/company-service/companies/${companyID}/settings`,
        method: 'put',
        headers: {
            'X-Amz-Security-Token': token,
        },
        data,
    })

    return response
}

import useApp from 'hooks/redux/useApp'
import useContact from 'hooks/redux/useContact'
import React from 'react'
import { Plugin } from 'store/app/app.state'
import PluginView from './PluginView'
import './plugins.scss'

export interface Props {
    type: 'call' | 'default' | 'contact-log' | 'nav' | 'chat' | 'task'
    showNone?: boolean
    selectedView?: string
    name?: string
    channel?: string
}

const Plugins: React.FC<Props> = ({ type, name, channel, selectedView, showNone }) => {
    const { plugins } = useApp()
    const contact = useContact()

    const filterPlugins = (plugin: Plugin) => {
        //First check type
        if (plugin.type !== type && plugin.type !== 'always') return false
        //Check name
        if (name && plugin.name !== name) return false
        //Check channel
        if (channel && plugin.channel && plugin.channel !== channel) return false
        return true
    }

    const filteredPlugins = plugins.filter(filterPlugins)

    return (
        <>
            {filteredPlugins.map((plugin, i) => {
                if (plugin.default) {
                    if (contact?.plugins?.[plugin.name] === 'hide') return null
                } else {
                    if (contact && contact.plugins?.[plugin.name] !== 'show') return null
                }
                return (
                    <PluginView
                        key={i}
                        plugin={plugin}
                        type={type}
                        showNone={showNone}
                        selectedView={selectedView}
                    />
                )
            })}
        </>
    )
}

export default Plugins

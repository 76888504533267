import { useSelector } from 'react-redux'
import { selectIsEnhancedMonitoringEnabled } from 'store/app/app.selectors'
import { selectActiveConnections } from 'store/callV2/callV2.reducer'

export interface UseMPCReturn {
    isMPC: boolean
}

export default function useMPC(): UseMPCReturn {
    const isEnhancedMonitoringEnabled = useSelector(selectIsEnhancedMonitoringEnabled)

    const activeConnections = useSelector(selectActiveConnections)

    const hasBargerJoined = activeConnections.some(
        ({ isMonitoringOrBarging }) => isMonitoringOrBarging,
    )

    return {
        isMPC: isEnhancedMonitoringEnabled && (hasBargerJoined || activeConnections.length > 2),
    }
}

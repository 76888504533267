import { PureComponent } from 'react'

import { Button } from '@missionlabs/smartagent-app-components'
import SmartAgent from './smart-agent-logo.png'

import packageJson from '../../../package.json'
import { isDev } from '../../utils'
import './footer.scss'

const ver = isDev() ? 'dev' : packageJson && packageJson.version

interface Props {
    onClick?: () => void
}

export default class Footer extends PureComponent<Props> {
    render() {
        const { onClick } = this.props
        const sa = <img title={`version ${ver}`} src={SmartAgent} alt="Smartagent" height="30" />
        return (
            <footer>
                {onClick ? (
                    <Button
                        aria-label="Smartagent: opens in a new window"
                        type="button"
                        onClick={onClick}
                    >
                        {sa}
                    </Button>
                ) : (
                    sa
                )}
            </footer>
        )
    }
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { MessagingConfig, MessagingConfigs, MessagingSettingsState } from './messaging.state'

const initialState: MessagingSettingsState = {
    messagingConfigs: {},
    messagingConfigsIsLoading: false,
    selectedMessagingConfig: undefined,
    pinpointNumbers: [],
    whatsappAccessCode: undefined,
}

export const messagingSettingsSlice = createSlice({
    name: 'messagingSettings',
    initialState,
    reducers: {
        setMessagingConfigs(state, action: PayloadAction<MessagingConfigs>) {
            return {
                ...state,
                messagingConfigs: action.payload,
            }
        },
        setMessagingConfigsLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                messagingConfigsIsLoading: action.payload,
            }
        },
        setSelectedMessagingConfig(state, action: PayloadAction<MessagingConfig | undefined>) {
            return {
                ...state,
                selectedMessagingConfig: action.payload,
            }
        },
        setMessagingPinpointNumbers(state, action: PayloadAction<string[]>) {
            return {
                ...state,
                pinpointNumbers: action.payload,
            }
        },
        setWhatsappAccessCode(state, action: PayloadAction<string | undefined>) {
            return {
                ...state,
                whatsappAccessCode: action.payload,
            }
        },
    },
})

export const {
    setMessagingConfigs,
    setMessagingConfigsLoading,
    setSelectedMessagingConfig,
    setMessagingPinpointNumbers,
    setWhatsappAccessCode,
} = messagingSettingsSlice.actions

export type MessagingSettingsAction = ReturnType<
    (typeof messagingSettingsSlice.actions)[keyof typeof messagingSettingsSlice.actions]
>

export default messagingSettingsSlice.reducer

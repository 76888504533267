import useHasFeature, { AppFeatures } from 'hooks/useHasFeature'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getTeamsContacts } from 'store/directory/teams/actions'

const loadTeamsContacts = () => {
    const dispatch = useDispatch()
    const hasFeature = useHasFeature()

    useEffect(() => {
        if (hasFeature(AppFeatures.MS_TEAMS_DIRECTORY)) {
            dispatch(getTeamsContacts())
        }
    }, [])
}

export default loadTeamsContacts

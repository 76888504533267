var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { jsx } from "react/jsx-runtime";
import { PureComponent } from "react";
class TimeCountdown extends PureComponent {
  constructor() {
    super(...arguments);
    __publicField(this, "interval");
    __publicField(this, "state", {
      time: this.props.startTime
    });
  }
  componentDidUpdate() {
    if (!this.interval) {
      this.setState({
        time: this.props.startTime
      }, this.componentDidMount);
    }
  }
  componentDidMount() {
    if (this.state.time === void 0)
      return;
    this.interval = setInterval(() => {
      const time = this.state.time - 1;
      this.setState({ time });
      this.props.onChange(time);
      if (time === 0) {
        if (!this.props.allowOverflow) {
          clearInterval(this.interval);
        }
        this.props.onZero && this.props.onZero();
      }
    }, 1e3);
  }
  componentWillUnmount() {
    if (this.interval)
      clearInterval(this.interval);
  }
  formatTime() {
    const { time } = this.state;
    let formatted;
    let minutes = Math.floor(Math.abs(time) / 60);
    let seconds = Math.floor(Math.abs(time) - minutes * 60);
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    if (this.props.format === "minutes-seconds") {
      formatted = `${minutes}m ${seconds}s`;
    }
    formatted = `${minutes}:${seconds}`;
    return time < 0 ? "-" + formatted : formatted;
  }
  render() {
    const { ariaLive } = this.props;
    const aLive = ariaLive === "custom" && Number(this.state.time) % 5 === 0 ? "assertive" : "off";
    return /* @__PURE__ */ jsx("time", { "aria-live": aLive, "aria-hidden": "true", "aria-label": `${String(this.state.time).split(":")[0]} seconds left`, children: this.formatTime() });
  }
}
export {
  TimeCountdown as default
};

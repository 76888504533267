import { useSelector } from 'react-redux'
import RootState from 'store/state'

function useChat() {
    const chat = useSelector<RootState, RootState['chat']>(({ chat }) => chat)

    return chat
}

export default useChat

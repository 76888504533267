import React from 'react'
import { connect } from 'react-redux'
import RootState from 'store/state'
import './queue-time.scss'

interface Props {
    contact: any
}

const QueueTime: React.FC<Props> = ({ contact }) => {
    const time = isNaN(contact.inQueueDuration)
        ? 'Unknown'
        : new Date(contact.inQueueDuration * 1000).toISOString().substr(11, 8)

    return (
        <div className="queue-time-wrapper">
            <h3>Time in queue: {time}</h3>
        </div>
    )
}

function mapStateToProps(state: RootState) {
    return {
        contact: state?.contact,
    }
}

export default connect(mapStateToProps, {})(QueueTime)

import useTeamsContacts from 'hooks/redux/useTeamsContacts'
import { useMemo } from 'react'
import { AppFeatures } from 'store/app/app.features'
import { IDirectoryRecord } from 'store/directory/directory.state'
import useExternalDirectoryContacts from './redux/useExternalDirectoryContacts'
import useHasFeature from './useHasFeature'
import useTeamsContactsWithFreshStatuses from './useTeamsContactsWithFreshStatuses'

const getHooksToLoadContacts = (withAdditionalInfo: boolean) => {
    return [
        {
            sourceName: AppFeatures.EXTERNAL_DIRECTORY,
            hook: useExternalDirectoryContacts,
        },
        {
            sourceName: AppFeatures.MS_TEAMS_DIRECTORY,
            hook: withAdditionalInfo ? useTeamsContactsWithFreshStatuses : useTeamsContacts,
        },
    ]
}

type Returns = [IDirectoryRecord[], boolean?]
type ArrayOfHooks = Array<typeof useTeamsContacts | typeof useExternalDirectoryContacts>

/*  
    this hook iterates over all available contacts sources and
    calls a hook that is responsible for loading and returning relevant
    data for each contacts source for Directory tab
*/

const useDirectoryContacts = (withAdditionalInfo: boolean = false) => {
    const hasFeature = useHasFeature()

    const directoryContactsSources = getHooksToLoadContacts(withAdditionalInfo)

    const hooks = useMemo(
        () =>
            directoryContactsSources.reduce((acc: ArrayOfHooks, source) => {
                if (hasFeature(source.sourceName)) {
                    return [...acc, source.hook]
                }

                return acc
            }, []),
        [],
    )

    const hooksData = hooks.map((hook) => hook())

    const contactsLoadingStatuses = hooksData.map((hookData) => hookData[1])

    const hasContactsFinishedLoading = () =>
        contactsLoadingStatuses.every((loadingStatus) => loadingStatus === false)

    const allContactsWithLoadingStatus = useMemo(() => {
        if (hasContactsFinishedLoading()) {
            const contacts = hooksData.reduce((acc: IDirectoryRecord[], hookData) => {
                return [...acc, ...hookData[0]]
            }, [])

            return [contacts, false]
        }

        return [[], true]
    }, [...contactsLoadingStatuses])

    // returns the final result of all hooks as array of merged contacts as the first index and loading status as the second
    return allContactsWithLoadingStatus as Returns
}

export default useDirectoryContacts

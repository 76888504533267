import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
const AcceptMessage = ({ ariaId, acceptMsg, sendMessage }) => {
  const [disabled, setDisabled] = useState(false);
  const { title, summary, buttons } = acceptMsg;
  const onAccept = (payload, buttonTitle) => {
    sendMessage(payload, buttonTitle);
    setDisabled(true);
  };
  return /* @__PURE__ */ jsx("div", { id: ariaId, className: "sa-chat-accept-message-wrapper", children: /* @__PURE__ */ jsxs("div", { className: "sa-chat-accept-message", children: [
    /* @__PURE__ */ jsx("div", { className: "title", tabIndex: 0, children: title }),
    /* @__PURE__ */ jsx("div", { className: "summary", tabIndex: 0, children: Array.isArray(summary) ? summary.map((paragraph, index) => /* @__PURE__ */ jsx("p", { children: paragraph }, index)) : summary }),
    /* @__PURE__ */ jsx("div", { className: "buttons", children: buttons.map(({ buttonType, buttonTitle, url, payload }, index) => {
      return /* @__PURE__ */ jsx("div", { children: url ? /* @__PURE__ */ jsx(
        "a",
        {
          href: url,
          target: "_blank",
          rel: "noopener noreferrer",
          className: `button button-${buttonType}-colour`,
          children: buttonTitle
        }
      ) : /* @__PURE__ */ jsx(
        "button",
        {
          disabled,
          onClick: () => onAccept(payload, buttonTitle),
          className: `button button-${buttonType}-colour`,
          children: buttonTitle
        }
      ) }, index);
    }) })
  ] }) });
};
export {
  AcceptMessage as default
};

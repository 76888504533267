import LIST_USER_CONVERSATIONS, {
    ListUserConversations,
} from 'graphql/queries/listUserConversations'
import {
    subscription,
    UpdateUserConversation,
} from 'graphql/subscriptions/onUpdateUserConversation'
import { UserConversation } from 'graphql/types'
import useHasFeature, { AppFeatures } from 'hooks/useHasFeature'
import Phonebook from 'images/icon-agent-directory.svg'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    newMessageNotification,
    openPhonebook,
    selectConversation,
} from 'store/internal-messaging/internal-messaging.actions'
import RootState from 'store/state'

import { useQuery } from '@apollo/client'
import { Button, TriggeredPopup } from '@missionlabs/smartagent-app-components'
import { Tab } from '@missionlabs/smartagent-app-components/dist/Tabs'

import ChatTabs from '../../components/Tabs/ChatTabsHooks'
import Agents from './Agents'
import { AgentsWrapper } from './AgentsWrapper'
import Conversations from './Conversations'

interface InnerProps {
    unreadMessages?: number
    userConversations?: ListUserConversations
}

export const PhoneBook: React.FC<InnerProps> = ({ unreadMessages, userConversations }) => {
    const hasFeature = useHasFeature()
    const shouldNotify = () => unreadMessages && unreadMessages > 0

    const dispatch = useDispatch()

    const internalMessaging = useSelector<RootState, RootState['internalMessaging']>(
        (state) => state.internalMessaging,
    )

    const onClose = () => {
        dispatch(openPhonebook(false))
    }
    const onOpen = () => {
        dispatch(openPhonebook(true))
    }

    const onSelect = (index: number) => {
        if (index === 0 && hasFeature(AppFeatures.INTERNAL_MESSAGING))
            // Clear selected conversation if user navigates to the Agents tab
            dispatch(selectConversation(undefined))
    }

    return (
        <div>
            <div className="phonebook-icon">
                <TriggeredPopup
                    active={!!internalMessaging.phoneBookOpen}
                    closeOnClickOutside
                    fromCenter
                    onClose={onClose}
                    onOpen={onOpen}
                    className="sa-agents-directory-popup"
                    trigger={
                        <div className="notification-icon">
                            <Button>
                                <img src={Phonebook} alt="Agents" title="Agents" width={16} />
                            </Button>

                            {shouldNotify() ? <span className="notification-circle"> </span> : null}
                        </div>
                    }
                >
                    <ChatTabs
                        unreadMessages={unreadMessages ?? 0}
                        selected={internalMessaging.selectedConversationId ? 1 : undefined}
                        onSelect={onSelect}
                    >
                        <Tab title="Agents">
                            {hasFeature(AppFeatures.INTERNAL_MESSAGING) ? (
                                <AgentsWrapper
                                    userConversations={
                                        userConversations?.listUserConversations?.items ?? []
                                    }
                                />
                            ) : (
                                <Agents />
                            )}
                        </Tab>
                        {hasFeature(AppFeatures.INTERNAL_MESSAGING) ? (
                            <Tab title="Messages">
                                <Conversations
                                    userConversations={
                                        userConversations?.listUserConversations?.items ?? []
                                    }
                                    selectedConversationId={
                                        internalMessaging.selectedConversationId
                                    }
                                />
                            </Tab>
                        ) : (
                            <></>
                        )}
                    </ChatTabs>
                </TriggeredPopup>
            </div>
        </div>
    )
}

export const PhoneBookWrapper: React.FC<{}> = () => {
    const { data: userConversations, subscribeToMore } =
        useQuery<ListUserConversations>(LIST_USER_CONVERSATIONS)

    const username = useSelector((state: RootState) => state.user?.username ?? '')
    const dispatch = useDispatch()

    useEffect(() => {
        const unsubscribe = subscribeToMore<UpdateUserConversation>(
            subscription(username, (uc: UserConversation) => {
                uc.conversation.lastMessageContent &&
                    uc.conversation.lastMessageUser &&
                    dispatch(
                        newMessageNotification({
                            content: uc.conversation.lastMessageContent,
                            user: uc.conversation.lastMessageUser,
                            conversationId: uc.conversationId,
                        }),
                    )
            }),
        )
        return () => unsubscribe()
        // eslint-disable-next-line
    }, [subscribeToMore, username])

    const unreadMessages = userConversations?.listUserConversations?.items.reduce((acc, uc) => {
        return (acc += uc.unread ? uc.unread : 0)
    }, 0)
    return <PhoneBook unreadMessages={unreadMessages} userConversations={userConversations} />
}

import { Button, TriggeredPopup } from '@missionlabs/smartagent-app-components'
import redArrowUp from 'images/icon-arrow-up-red.svg'
import React from 'react'

import './directory-popups.scss'

interface Props {
    className?: string
    triggerClassName?: string
    onSelect(priority?: boolean): void
    currentPriority?: boolean
}

const DirectoryPriorityPopup: React.FC<Props> = ({
    className,
    triggerClassName,
    currentPriority,
    onSelect,
}) => {
    return (
        <TriggeredPopup
            className={className ?? 'sa-directory-popup'}
            closeOnClick
            closeOnClickOutside
            autoPositioning
            trigger={
                <Button
                    className={triggerClassName ?? 'sa-directory-popup-button'}
                    small
                    elastic
                    styling="secondary"
                >
                    <div className="sa-directory-popup-button__content">
                        <span>Priority: </span>
                        <span>
                            {currentPriority ? 'High' : currentPriority === false ? 'None' : 'All'}
                        </span>
                    </div>
                </Button>
            }
        >
            <div className="row column">
                {[true, false, undefined].map((priority) => (
                    <Button key={String(priority)} onClick={() => onSelect(priority)}>
                        {priority ? (
                            <div className="row">
                                <img width="14" src={redArrowUp} alt="" />
                                <p>High</p>
                            </div>
                        ) : priority === false ? (
                            <p>None</p>
                        ) : (
                            <p>All</p>
                        )}
                    </Button>
                ))}
            </div>
        </TriggeredPopup>
    )
}

export default DirectoryPriorityPopup

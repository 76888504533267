import * as GlobalActions from './global.reducer'

export const {
    addError,
    addSuccess,
    addSoftphoneError,
    clearError,
    clearSuccess,
    clearRedirect,
    clearSoftphoneError,
    numberDialling: setNumberDialling,
    internetConnection: setInternetConnection,
    setSaIsAlreadyOpen,
} = GlobalActions

export function setRedirect(to: string, state?: any) {
    return GlobalActions.doRedirect({ to, state })
}

export function showNotification(
    type: 'success' | 'warning' | 'error',
    header: string,
    text: string,
) {
    return GlobalActions.showNotification({ type, header, text })
}

export function setChangingState(stateName?: string) {
    return GlobalActions.setChangingState({ stateName: stateName || '' })
}

import { Button } from '@missionlabs/smartagent-app-components'
import useChatConnections from 'hooks/redux/useChatConnections'
import useCopyChatTranscript from 'hooks/redux/useCopyChatTranscript'
import { AppFeatures, useGetFeatureConfig } from 'hooks/useHasFeature'
import React from 'react'
import { useDispatch } from 'react-redux'
import ChatReplier from './ChatReplier'
interface Props {}

const ChatMessengerControls: React.FC<Props> = () => {
    const chat = useChatConnections('selected')!
    const dispatch = useDispatch()
    const chatFeatures = useGetFeatureConfig()(AppFeatures.CHAT)
    const canCopyChatTranscript = chatFeatures?.canCopyChatTranscript
    const copyChatTranscript = useCopyChatTranscript()
    const chatAccepted = useChatConnections('selected')?.status === 'connected'

    return (
        <>
            {chat.acw ? (
                <>
                    {canCopyChatTranscript && (
                        <div className="row between sa-chat-acw-bottom sm-pad">
                            <Button
                                elastic
                                round
                                styling="blank"
                                onClick={() => copyChatTranscript?.()}
                            >
                                Copy Chat
                            </Button>
                        </div>
                    )}
                </>
            ) : (
                chatAccepted && <ChatReplier />
            )}
        </>
    )
}

export default ChatMessengerControls

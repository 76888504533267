import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getDirectoryOwners } from 'store/directory/owners/actions'
import RootState from 'store/state'

import useDirectory from './useDirectory'

type Returns = [
    RootState['directory']['owners']['data'],
    RootState['directory']['owners']['getOwnersLoading'],
]

export default function useDirectoryOwners() {
    const { owners } = useDirectory()

    const dispatch = useDispatch()

    useEffect(() => {
        if (!owners.data.length) {
            dispatch(getDirectoryOwners())
        }
    }, [owners.data, dispatch])

    return [owners.data, owners.getOwnersLoading] as Returns
}

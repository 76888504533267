import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import AgentsAssistanceState from './agentsAssistance.state'

const initialState: AgentsAssistanceState = {
    ID: '',
    agentID: '',
    instanceID: '',
    raisedAt: 0,
    acknowledgement: {
        supervisorID: '',
        acknowledgedAt: 0,
    },
    resolvedAt: 0,
}

export const agentsAssistanceSlice = createSlice({
    name: 'agentsAssistance',
    initialState,
    reducers: {
        setAgentsAssistanceState(state, action: PayloadAction<AgentsAssistanceState>) {
            return { ...state, ...action.payload }
        },
        resetAgentsAssistanceState() {
            return initialState
        },
    },
})

export const { setAgentsAssistanceState, resetAgentsAssistanceState } =
    agentsAssistanceSlice.actions

export type AgentsAssistanceAction = ReturnType<
    (typeof agentsAssistanceSlice.actions)[keyof typeof agentsAssistanceSlice.actions]
>

export default agentsAssistanceSlice.reducer

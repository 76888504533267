var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { jsxs, jsx } from "react/jsx-runtime";
import React, { PureComponent, Fragment } from "react";
import { findDOMNode } from "react-dom";
import Popup from "./Popup.mjs";
class Ref extends React.PureComponent {
  componentDidMount() {
    const { innerRef } = this.props;
    if (innerRef)
      innerRef(findDOMNode(this));
  }
  render() {
    const { children } = this.props;
    return React.Children.only(children);
  }
}
class TriggeredPopup extends PureComponent {
  constructor() {
    super(...arguments);
    __publicField(this, "TriggerEl");
    __publicField(this, "state", {
      active: this.props.active || false,
      triggeredPopup: {
        width: 0
      },
      pageY: 0
    });
    __publicField(this, "onClose", () => {
      var _a;
      this.closePopup();
      (_a = this.props) == null ? void 0 : _a.onClose();
    });
    __publicField(this, "onOpen", () => {
      var _a, _b;
      (_b = (_a = this.props).onOpen) == null ? void 0 : _b.call(_a);
    });
    __publicField(this, "setTriggerRef", (r) => {
      this.TriggerEl = r;
    });
    __publicField(this, "togglePopup", (e) => {
      if (e && e.stopPropagation) {
        e.stopPropagation();
      }
      if (this.props.disabled)
        return;
      this.setState({
        active: !this.state.active,
        pageY: e.pageY
      });
    });
    __publicField(this, "closePopup", () => {
      this.setState({ active: false }, () => {
        var _a;
        if (this.props.trigger) {
          (_a = this.TriggerEl) == null ? void 0 : _a.focus();
        }
      });
    });
    __publicField(this, "isAutoPositioningFromTop", () => {
      const { autoPositioningOffset } = this.props;
      return this.state.pageY <= document.body.clientHeight / 2 + (autoPositioningOffset || 0);
    });
    __publicField(this, "getTriggeredPopup", (popup) => {
      if (!popup)
        return;
      this.setState({
        triggeredPopup: popup == null ? void 0 : popup.getBoundingClientRect()
      });
    });
    __publicField(this, "getTriggerNode", () => {
      return this.TriggerEl;
    });
  }
  componentWillReceiveProps(nextProps) {
    const { active } = this.props;
    if (nextProps.active !== active) {
      this.setState({ active: !!nextProps.active });
    }
  }
  renderTrigger() {
    const triggerProps = {
      onClick: (e) => {
        var _a, _b;
        this.togglePopup(e);
        (_b = (_a = this.props.trigger.props).onClick) == null ? void 0 : _b.call(_a, e);
      }
    };
    return React.cloneElement(this.props.trigger, triggerProps);
  }
  isFromTop() {
    const { fromBottom, autoPositioning } = this.props;
    if (autoPositioning && !this.isAutoPositioningFromTop())
      return false;
    if (!autoPositioning && fromBottom)
      return false;
    return true;
  }
  getTop() {
    if (this.props.center || !this.isFromTop() || !this.TriggerEl)
      return;
    const triggerBounding = this.TriggerEl.getBoundingClientRect();
    return triggerBounding.top + triggerBounding.height;
  }
  getBottom() {
    if (this.props.center || this.isFromTop() || !this.TriggerEl)
      return;
    const triggerBounding = this.TriggerEl.getBoundingClientRect();
    return document.body.offsetHeight - triggerBounding.top;
  }
  getLeft() {
    const { center, fromRight, fromCenter } = this.props;
    if (fromRight || center || !this.TriggerEl)
      return;
    const triggerBounding = this.TriggerEl.getBoundingClientRect();
    if (fromCenter)
      return triggerBounding.left + triggerBounding.width / 2 - this.state.triggeredPopup.width / 2;
    return triggerBounding.left;
  }
  getRight() {
    const { center, fromRight, fromCenter } = this.props;
    if (!fromRight || center || fromCenter || !this.TriggerEl)
      return;
    const triggerBounding = this.TriggerEl.getBoundingClientRect();
    return document.body.offsetWidth - triggerBounding.right;
  }
  render() {
    const { trigger, render, children, ...others } = this.props;
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      trigger ? /* @__PURE__ */ jsx(Ref, { innerRef: this.setTriggerRef, children: this.renderTrigger() }, "R") : null,
      this.state.active ? /* @__PURE__ */ jsx(Popup, { ...others, getTriggeredPopup: this.getTriggeredPopup, getTriggerNode: this.getTriggerNode, onClose: this.onClose, onOpen: this.onOpen, top: this.getTop(), left: this.getLeft(), right: this.getRight(), bottom: this.getBottom(), children: render ? render(this.closePopup) : children }) : null
    ] });
  }
}
__publicField(TriggeredPopup, "defaultProps", {
  ...Popup.defaultProps,
  noShadow: true
});
export {
  TriggeredPopup as default
};

import './index.scss'

import classNames from 'classnames'
import { NoDataMessage } from 'components'
import { startOfDay, startOfMonth, startOfWeek, subDays, subWeeks } from 'date-fns'
import useAnnouncements from 'hooks/redux/useAnnouncements'
import useHasFeature, { AppFeatures } from 'hooks/useHasFeature'
import AnnouncementOutlineIcon from 'images/icon-announcement-outline.svg'
import { ReactComponent as AnnouncementIcon } from 'images/icon-announcement.svg'
import { ReactComponent as FilterIcon } from 'images/icon-filter.svg'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { UserAnnouncement } from 'store/announcement/announcement.state'
import { humanFriendlyDateTime } from 'utils'

import { Button, TriggeredPopup } from '@missionlabs/smartagent-app-components'

import {
    getMyUnreadUserAnnouncements,
    getMyUserAnnouncements,
    updateUserAnnouncement,
} from '../../store/announcement/announcement.actions'
import ViewUserAnnouncement from './user-announcement'

interface Props {}

enum FilterOptions {
    TODAY = 'TODAY',
    YESTERDAY = 'YESTERDAY',
    LW = 'LW',
    L2W = 'L2W',
    LM = 'LM',
}

const POLL_INTERVAL = 300000

const ViewAnnouncementWrapper: React.FC<any> = (props) => {
    const hasFeature = useHasFeature()
    if (!hasFeature(AppFeatures.ANNOUNCEMENTS)) return null
    else return ViewAnnouncement(props)
}

const ViewAnnouncement: React.FC<Props> = () => {
    const dispatch = useDispatch()

    const { announcementsOpen, userAnnouncements } = useAnnouncements()
    const sortedUserAnnouncements = (userAnnouncements?.slice() ?? []).sort(
        (a, b) => b.created - a.created,
    )

    const [userAnnouncement, setUserAnnouncement] = useState<UserAnnouncement | undefined>(
        undefined,
    )
    const [filter, setFilter] = useState<FilterOptions | undefined>(FilterOptions.TODAY)
    const [filterIsOpen, setFilterIsOpen] = useState(false)

    const filterPopup = useRef<TriggeredPopup>(null)
    const fetchUnreadAnnouncements = useCallback(() => {
        dispatch(getMyUnreadUserAnnouncements())

        setTimeout(() => {
            fetchUnreadAnnouncements()
        }, POLL_INTERVAL)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        fetchUnreadAnnouncements()
    }, [fetchUnreadAnnouncements])

    const fetchUserAnnouncements = useCallback(() => {
        dispatch(
            getMyUserAnnouncements({
                fromDate: filterFromDate(filter),
            }),
        )
        // eslint-disable-next-line
    }, [filter])

    useEffect(() => {
        fetchUserAnnouncements()
    }, [fetchUserAnnouncements, filter])

    useEffect(() => {
        if (!userAnnouncement) return
        if (!userAnnouncement.read) {
            dispatch(
                updateUserAnnouncement(userAnnouncement.ID, {
                    read: 1,
                }),
            )
        }
    }, [userAnnouncement, dispatch])

    const filterFromDate = (filter: FilterOptions | undefined) => {
        const d = new Date()
        let returnD: Date
        switch (filter) {
            case FilterOptions.TODAY:
                returnD = startOfDay(d)
                break
            case FilterOptions.YESTERDAY:
                returnD = subDays(startOfDay(d), 1)
                break
            case FilterOptions.LW:
                returnD = startOfWeek(d)
                break
            case FilterOptions.L2W:
                returnD = subWeeks(startOfWeek(d), 1)
                break
            case FilterOptions.LM:
                returnD = startOfMonth(d)
                break
            default:
                return undefined
        }
        return returnD.getTime().toString()
    }

    const onSetFilter = (filter: FilterOptions) => {
        setFilter(filter)
        filterPopup.current?.onClose()
    }

    const getFilterTitle = (filter: FilterOptions) => {
        switch (filter) {
            case FilterOptions.TODAY:
                return 'Today'
            case FilterOptions.YESTERDAY:
                return 'Yesterday'
            case FilterOptions.LW:
                return 'Last week'
            case FilterOptions.L2W:
                return 'Last two weeks'
            case FilterOptions.LM:
                return 'Last month'
        }
    }

    return (
        <div className="sa-user-announcements">
            <TriggeredPopup
                active={!!announcementsOpen}
                onOpen={() => fetchUserAnnouncements()}
                closeOnClickOutside={!filterIsOpen}
                fromCenter
                className="sa-user-announcements-popup"
                trigger={
                    <div className="sa-user-announcements-icon">
                        <Button>
                            <img
                                src={AnnouncementOutlineIcon}
                                alt="Announcements"
                                title="Announcements"
                                width={16}
                            />
                        </Button>
                    </div>
                }
            >
                {userAnnouncement ? (
                    <ViewUserAnnouncement
                        userAnnouncement={userAnnouncement}
                        onBack={() => setUserAnnouncement(undefined)}
                    />
                ) : (
                    <div>
                        <div className="sa-user-announcements-header">
                            {/* Title */}
                            <h3>Previous Announcements</h3>

                            {/* Filter */}
                            <TriggeredPopup
                                ref={filterPopup}
                                onClose={() => setFilterIsOpen(false)}
                                onOpen={() => setFilterIsOpen(true)}
                                closeOnClickOutside
                                className="sa-user-announcements-header-popup"
                                trigger={
                                    <div>
                                        <Button>
                                            <FilterIcon />
                                        </Button>
                                    </div>
                                }
                            >
                                <div className="sa-user-announcements-header-filter-options">
                                    {Object.values(FilterOptions).map((key) => (
                                        <Button
                                            key={key}
                                            className={classNames({
                                                'sa-user-announcements-header-filter-options-option-selected':
                                                    filter === FilterOptions[key],
                                            })}
                                            onClick={() => onSetFilter(FilterOptions[key])}
                                        >
                                            {getFilterTitle(FilterOptions[key])}
                                        </Button>
                                    ))}
                                </div>
                            </TriggeredPopup>
                        </div>

                        {/* Announcements */}
                        <div className="sa-user-announcements-ua-list">
                            {sortedUserAnnouncements?.length ? (
                                sortedUserAnnouncements?.map((ua) => (
                                    <div
                                        key={ua.ID}
                                        tabIndex={0}
                                        role="button"
                                        className="sa-user-announcements-ua-list-container"
                                        onClick={() => {
                                            setUserAnnouncement(ua)
                                        }}
                                        onKeyPress={(evt) => {
                                            evt.key === 'Enter' && setUserAnnouncement(ua)
                                        }}
                                    >
                                        <AnnouncementIcon />
                                        <div className="sa-user-announcements-ua-list-item">
                                            <div className="sa-user-announcements-ua-list-header">
                                                <div className="sa-user-announcements-ua-list-header-title">
                                                    {ua.title}
                                                </div>
                                                <div>
                                                    {humanFriendlyDateTime(new Date(ua.created))}
                                                </div>
                                            </div>
                                            <div className="sa-user-announcements-ua-list-msg">
                                                {ua.message}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="sa-user-announcements-ua-list-nodata">
                                    <NoDataMessage />
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </TriggeredPopup>
        </div>
    )
}

export default ViewAnnouncementWrapper

import { IChatQuickReply } from 'store/chat/chat.state'

const getAllReplies = (quickReplies: IChatQuickReply[]): IChatQuickReply[] =>
    quickReplies.reduce((acc: IChatQuickReply[], qr) => {
        if (!qr.children) {
            return [...acc, qr] as IChatQuickReply[]
        }

        return [...acc, ...getAllReplies(qr.children!)]
    }, [])

export default getAllReplies

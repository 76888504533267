import '../login.scss'

import Loader from 'components/Loader'
import useAppConfig from 'hooks/redux/useAppConfig'
import useAuth from 'hooks/redux/useAuth'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { login } from 'store/user/user.actions'

import { AlertBanner, Button } from '@missionlabs/smartagent-app-components'

import LoginSplash from '../LoginSplash'

const LoginExternal = () => {
    const { authError, authenticating, authenticated, logout } = useAuth()

    const { loginInfoBannerText } = useAppConfig()

    const [showLoginInfoBanner, setShowLoginInfoBanner] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        let timerId: NodeJS.Timeout

        if (!logout) {
            timerId = setTimeout(() => dispatch(login()), 1000)
        }

        return () => {
            if (timerId) {
                clearTimeout(timerId)
            }
        }
    }, [])

    useEffect(() => {
        let timerId: NodeJS.Timeout

        if (authenticating) {
            setTimeout(() => {
                setShowLoginInfoBanner(true)
            }, 7000)
        }

        return () => {
            timerId && clearTimeout(timerId)
        }
    }, [authenticating])

    const onRetry = () => {
        window.location.reload()
    }

    return (
        <>
            {authenticating || authenticated ? (
                <div className="row column row middle">
                    <div className="sa-login-text" aria-live="polite">
                        {showLoginInfoBanner && (
                            <AlertBanner type="info">
                                {loginInfoBannerText
                                    ? loginInfoBannerText
                                    : 'During peak times, authentication will take up to 2 minutes. Do not refresh your browser'}
                            </AlertBanner>
                        )}
                        {authenticating || authenticated ? (
                            <div className="sa-login-text-loader-wrapper">
                                <Loader loaderType="clip" className="sa-login-text-loader" />
                                <p>Authentication in progress...</p>
                            </div>
                        ) : (
                            "You're using external log in, please wait"
                        )}
                    </div>
                </div>
            ) : authError ? (
                <div className="row column row middle" aria-live="polite">
                    <div className="sa-login-text sa-login-error">{authError}</div>
                    <Button styling="primary" large round onClick={onRetry}>
                        Retry
                    </Button>
                </div>
            ) : (
                <LoginSplash onClick={() => dispatch(login())} loading={!logout} />
            )}
        </>
    )
}

export default LoginExternal

import { useCallback, useState } from 'react'
import useHasFeature from './useHasFeature'
import useInterval from './useInterval'

export default function useHasMic() {
    const [fails, setFails] = useState(0)

    const hasFeature = useHasFeature()

    useInterval(() => {
        //If not https this doesnt exist
        if (!navigator.mediaDevices) return
        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then(() => {
                if (fails !== 0) {
                    setFails(0)
                }
            })
            .catch(() => {
                setFails((fails) => fails + 1)
            })
    }, 1_000)

    const clearFails = useCallback(() => {
        setFails(0)
    }, [setFails])

    return {
        hasMic: fails < 10,
        clearFails,
    }
}

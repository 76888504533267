import { Button } from '@missionlabs/smartagent-app-components'
import React from 'react'
import './logout.scss'

interface Props {
    logout: () => void
    cancel: () => void
}

const Logout: React.FC<Props> = (props) => {
    return (
        <div className="sa-logout">
            <div className="sa-logout-title">Logging Out</div>
            <p className="sa-logout-body md-mar-vertical">Are you sure you want to log out?</p>
            <div className="sa-logout-buttons">
                <Button large round styling="secondary" onClick={props.cancel}>
                    Cancel
                </Button>
                <Button large round styling="primary" onClick={props.logout}>
                    Log Out
                </Button>
            </div>
        </div>
    )
}

export default Logout

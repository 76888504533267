import { ITeamsStatusInfo } from 'store/directory/teams/state'

import useDirectory from './useDirectory'
import loadTeamsContactsStatuses from './useLoadTeamsContactsStatuses'

type Returns = [ITeamsStatusInfo, boolean?]

const useTeamsContactsStatuses = (): Returns => {
    const { teams } = useDirectory()
    const { contactsStatuses, isTeamsStatusesLoading } = teams

    loadTeamsContactsStatuses()

    return [contactsStatuses, isTeamsStatusesLoading]
}

export default useTeamsContactsStatuses

import type { Middleware } from '@reduxjs/toolkit'
import {
    AuthenticationDetails,
    CognitoUser,
    CognitoUserPool,
    CognitoUserSession,
} from 'amazon-cognito-identity-js'
import { ccpLogout, initAppSuccess } from 'store/app/app.reducer'
import AppState, { CognitoType } from 'store/app/app.state'
import RootState from 'store/state'
import { authError, authenticateUser, mfaRequired } from 'store/user/user.actions'
import * as UserReducer from 'store/user/user.reducer'

import { isAnyAction } from 'utils'

const urlparams = new URLSearchParams(window.location.hash.replace('#', ''))

let attributes: any
let user: CognitoUser | null

const getCurrentUserToken = async (cognito: CognitoType): Promise<string | undefined> => {
    if (cognito.type !== 'in_app') return

    const pool = new CognitoUserPool({
        ClientId: cognito.client_id,
        UserPoolId: cognito.userPoolId,
    })
    user = pool.getCurrentUser()
    if (!user) return

    try {
        const session = await getUserSession(user)
        if (!session.isValid()) return

        const token = session.getAccessToken().getJwtToken()
        const idToken = session.getIdToken().getJwtToken()
        localStorage.setItem('oauthToken', idToken)
        return token
    } catch (ex) {
        return
    }
}

const getUserSession = (user: CognitoUser): Promise<CognitoUserSession> => {
    return new Promise((resolve, reject) => {
        user.getSession((err: any, session: any) => {
            if (err) return reject()
            return resolve(session)
        })
    })
}

const getCognitoUser = (username: string, cognito: CognitoType) => {
    if (cognito.type === 'in_app') {
        const pool = new CognitoUserPool({
            ClientId: cognito.client_id,
            UserPoolId: cognito.userPoolId,
        })

        return new CognitoUser({
            Username: username,
            Pool: pool,
        })
    }
}

function isCognito(identityManagement?: string) {
    return identityManagement === 'cognito'
}

// eslint-disable-next-line
const refreshToken = async () => {
    if (!user) return
    const session = await getUserSession(user)
    //Only refresh if due to expire in the next 30mins
    if (session.getIdToken().getExpiration() < Math.floor((Date.now() + 1000 * 60 * 30) / 1000))
        return

    user.refreshSession(session.getRefreshToken(), (err, session: CognitoUserSession) => {
        if (err || !session.isValid()) return
        const idToken = session.getIdToken().getJwtToken()
        localStorage.setItem('oauthToken', idToken)
    })
}

const cognitoMiddleware: Middleware<{}, RootState> = (store) => (next) => async (action) => {
    if (!isAnyAction(action)) return

    const { cognito, identityManagement } = store.getState().app

    switch (action.type) {
        case initAppSuccess.type: {
            const { identityManagement, cognito } = action.payload as AppState
            if (!isCognito(identityManagement) || !cognito) return next(action)

            next(action)
            if (cognito.type === 'redirect') {
                const token = urlparams.get('access_token')
                if (token) {
                    store.dispatch(authenticateUser(token, true) as any)
                    window.location.hash = ''
                }
            } else if (cognito.type === 'in_app') {
                getCurrentUserToken(cognito).then((token) => {
                    if (token) {
                        store.dispatch(authenticateUser(token, true) as any)
                    }
                })
            }
            return
        }
        case UserReducer.login.type: {
            const token = urlparams.get('access_token')
            if (isCognito(identityManagement) && cognito && !token) {
                console.log('logging in as cognito')

                if (
                    action.payload &&
                    cognito.type === 'in_app' &&
                    action.payload.password &&
                    action.payload.username
                ) {
                    store.dispatch(UserReducer.authenticatingUser())

                    const auth = new AuthenticationDetails({
                        Username: action.payload.username,
                        Password: action.payload.password,
                    })

                    user = getCognitoUser(action.payload.username, cognito)!

                    user &&
                        user.authenticateUser(auth, {
                            mfaRequired: (challangeName: any, challengeParameters: any) => {
                                console.log(challangeName, challengeParameters)
                                store.dispatch(
                                    mfaRequired({
                                        type: challengeParameters.CODE_DELIVERY_DELIVERY_MEDIUM,
                                        destination: challengeParameters.CODE_DELIVERY_DESTINATION,
                                    }),
                                )
                            },
                            onSuccess: (result) => {
                                const token = result.getAccessToken()
                                localStorage.setItem(
                                    'oauthToken',
                                    result.getIdToken().getJwtToken(),
                                )
                                store.dispatch(authenticateUser(token.getJwtToken(), true) as any)
                            },
                            onFailure: (err) => {
                                store.dispatch(
                                    authError(
                                        // dont pass error message for security
                                        'Incorrect username or password',
                                    ),
                                )
                            },
                            newPasswordRequired: (userAttributes, requiredAttributes) => {
                                // Required attributes generally set as an empty array, but if there exist any required attributes then set their values in attributes obj
                                Array.isArray(requiredAttributes) &&
                                    requiredAttributes.forEach((a) => {
                                        attributes[a] = userAttributes[a]
                                    })

                                return store.dispatch(UserReducer.newPasswordRequired())
                            },
                        })
                } else if (cognito.type === 'redirect') {
                    window.location.href = `${cognito.domain}/login?response_type=token&client_id=${cognito.client_id}&redirect_uri=${window.location.href}&scope=openid+profile+aws.cognito.signin.user.admin`
                }
            }
            return next(action)
        }
        case UserReducer.forgotPasswordGetCode.type:
            if (identityManagement === 'cognito' && cognito && cognito.type === 'in_app') {
                store.dispatch(UserReducer.authenticatingUser())

                user = getCognitoUser(action.payload, cognito)!

                user.forgotPassword({
                    onSuccess: () => {
                        next(action)
                    },
                    onFailure: (err) => {
                        if (err.name === 'UserNotFoundException') {
                            return store.dispatch(authError('User not found'))
                        }
                        return store.dispatch(authError(err.message))
                    },
                })
            }
            return

        case UserReducer.forgotPasswordConfirm.type:
            if (identityManagement === 'cognito' && cognito && cognito.type === 'in_app') {
                store.dispatch(UserReducer.authenticatingUser())

                const { verificationCode, newPassword } = action.payload

                user &&
                    user.confirmPassword(verificationCode, newPassword, {
                        onSuccess: () => {
                            return next(action)
                        },
                        onFailure: (err) => {
                            store.dispatch(authError(err.message))
                        },
                    })
            }
            return next(action)

        case UserReducer.newPasswordRequiredChange.type:
            if (identityManagement === 'cognito' && cognito && cognito.type === 'in_app') {
                const { newPassword } = action.payload

                store.dispatch(UserReducer.authenticatingUser())

                user &&
                    user.completeNewPasswordChallenge(newPassword, attributes, {
                        onSuccess: (result) => {
                            const token = result.getAccessToken()
                            store.dispatch(authenticateUser(token.getJwtToken(), true) as any)
                        },
                        onFailure: (err) => {
                            store.dispatch(authError(err.message))
                        },
                    })
            }
            return next(action)
        case UserReducer.submitMFA.type:
            if (identityManagement === 'cognito' && cognito && cognito.type === 'in_app') {
                store.dispatch(UserReducer.authenticatingUser())

                user &&
                    user.sendMFACode(action.payload, {
                        onSuccess: (result) => {
                            const token = result.getAccessToken()
                            store.dispatch(authenticateUser(token.getJwtToken(), true) as any)
                        },
                        onFailure: (err) => {
                            store.dispatch(authError(err.message))
                        },
                    })
            }
            return next(action)
        case ccpLogout.type:
        case UserReducer.logout.type:
            next(action)
            if (identityManagement === 'cognito' && cognito && cognito.type === 'redirect') {
                // if using the redirect cognito integration open a new tab with the logout url needed to logout of cognito and ADFS
                window.open(
                    `${cognito.domain}/logout?client_id=${cognito.client_id}&logout_uri=${cognito.logoutUrl}`,
                    '_blank',
                )
            }

            if (user) {
                user.signOut()
            }
            return
        default:
            return next(action)
    }
}

export default cognitoMiddleware

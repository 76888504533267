import classNames from 'classnames'
import React from 'react'
import { useDispatch } from 'react-redux'
import {
    downloadAttachment,
    downloadConnectAttachmentFromTranscript,
} from 'store/chat/chat.actions'

interface IAttachment {
    attachmentName: string
    attachmentId: string
    contactID?: string
}

const AttachmentChatMessage: React.FC<IAttachment> = ({
    attachmentName,
    attachmentId,
    contactID,
}) => {
    const classes = classNames({
        'sa-chat-message-attachment': true,
    })

    const dispatch = useDispatch()

    const getAttachment = () => {
        if (contactID) {
            dispatch(downloadConnectAttachmentFromTranscript({ attachmentId, contactID }))
        } else {
            dispatch(downloadAttachment({ attachmentId, attachmentName }))
        }
    }

    return (
        <p className={classes} onClick={getAttachment}>
            {attachmentName}
        </p>
    )
}

export default AttachmentChatMessage

import { jsx } from "react/jsx-runtime";
import { M as MessageUserType } from "./chat-message.types-V2YsTtfG.mjs";
import { getCustomerTranslationMessage } from "./utils.mjs";
const getAgentMessage = (active, language) => {
  if (!active)
    return "Auto translation turned off";
  return language ? `Auto translate to ${new Intl.DisplayNames(["en"], { type: "language" }).of(language)} started` : `Auto translate started`;
};
const Translation = ({ ariaId, language, active, clientType }) => {
  return /* @__PURE__ */ jsx("div", { id: ariaId, className: "sa-translation", tabIndex: 0, children: /* @__PURE__ */ jsx("p", { children: clientType === MessageUserType.AGENT ? getAgentMessage(active, language) : getCustomerTranslationMessage(active, language) }) });
};
export {
  Translation as default
};

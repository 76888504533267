import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ErrorsState {
    websocketError: WebsocketError
}

interface WebsocketError {
    websocketDisconnectedTime?: number
}

const errorsSlice = createSlice({
    name: 'errors',
    initialState: {
        websocketError: {
            websocketDisconnectedTime: undefined,
        },
    } as ErrorsState,
    reducers: {
        setWebsocketDisconnectedTime(state, action: PayloadAction<WebsocketError>) {
            state.websocketError.websocketDisconnectedTime =
                action.payload.websocketDisconnectedTime
        },
    },
})

export const { setWebsocketDisconnectedTime } = errorsSlice.actions

export default errorsSlice.reducer

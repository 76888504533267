import * as CallReducer from 'store/call/call.reducer'
import * as UserReducer from 'store/user/user.reducer'
import { secondsFrom } from 'utils'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import ContactState, {
    ContactUpdatedAction,
    CTR,
    CustomerState,
    DPACheck,
    DPAResult,
    UpdateACWAttributesAction,
} from './contact.state'
import { mergeAttributesIntoContact, removeAttributesFromContact } from './contact.utils'

type State = ContactState | null
const initialState = null as State

export const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        getContact(_, _action: PayloadAction<CTR>) {},
        setContact(_state, action: PayloadAction<ContactState | null>) {
            return action.payload ? { ...action.payload } : null
        },
        updateContact(state, action: PayloadAction<ContactState>) {
            //Only update for correct contact
            if (state && action.payload.ID !== state.ID) return state
            return state ? { ...state, ...action.payload } : null
        },
        updateContactAttributes(state, action: PayloadAction<ContactUpdatedAction>) {
            if (!state) return state
            return mergeAttributesIntoContact(state, action.payload.attributes)
        },
        removeContactAttributes(state, action: PayloadAction<ContactUpdatedAction>) {
            if (!state) return state
            return removeAttributesFromContact(state, action.payload.attributes)
        },
        customerUpdated(state, action: PayloadAction<CustomerState>) {
            return { ...state!, customer: { ...state!.customer!, ...action.payload } }
        },
        updateDPAResult(state, action: PayloadAction<DPAResult>) {
            if (!state || !state.DPA) return state
            return {
                ...state,
                DPA: {
                    ...state.DPA,
                    result: action.payload,
                },
            }
        },
        updateDPA(state, action: PayloadAction<DPACheck>) {
            if (!state || !state.DPA) return state
            return {
                ...state,
                DPA: {
                    ...state.DPA,
                    checks: state.DPA!.checks.map((check: any, i: number) => {
                        if (check.label === action.payload.label) return action.payload
                        else return check
                    }),
                },
            }
        },
        updateAgentDPA(state, action: PayloadAction<DPACheck>) {
            if (!state || !state.DPA) return state
            return {
                ...state,
                DPA: {
                    ...state.DPA,
                    agentCheck: action.payload,
                    result: action.payload.passed
                        ? DPAResult['agent-overall-passed']
                        : DPAResult['failed'],
                },
            }
        },
        parkCallContact(state, action: PayloadAction<number>) {
            return {
                ...state!,
                parkBay: action.payload,
            }
        },
        updateACWAttributes(state, action: PayloadAction<UpdateACWAttributesAction>) {
            if (!action.payload.ID) return state
            return {
                ...state!,
                acwAttributes: action.payload.acwAttributes,
            }
        },
        clearContact() {
            return null
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(CallReducer.incomingCall, (_, action) => {
            return action.payload.contact
        }),
            addCase(CallReducer.callbackCall, (_, action) => {
                return action.payload.contact
            }),
            addCase(CallReducer.outboundCall, (state, action) => {
                return { ...state!, ...action.payload.contact }
            }),
            addCase(CallReducer.makeCall, () => {
                return null
            }),
            addCase(CallReducer.connectionStarted, (state) => {
                if (!state) return state
                return {
                    ...state,
                    connectedToAgentTimestamp: state.connectedToAgentTimestamp || Date.now(),
                }
            }),
            addCase(CallReducer.callEnded, (state) => {
                if (!state) return state
                return {
                    ...state,
                    agentInteractionDuration:
                        state.agentInteractionDuration ||
                        secondsFrom(state.connectedToAgentTimestamp || state.initiationTimestamp),
                    disconnectTimestamp: Date.now(),
                }
            }),
            addCase(UserReducer.userAvailable, (state, action) => {
                return null
            }),
            addCase(UserReducer.logout, (state, action) => {
                return null
            })
    },
})

export const {
    setContact,
    getContact,
    updateContact,
    updateContactAttributes,
    removeContactAttributes,
    customerUpdated,
    updateDPAResult,
    updateDPA,
    updateAgentDPA,
    parkCallContact,
    updateACWAttributes,
    clearContact,
} = contactSlice.actions

export type ContactAction = ReturnType<
    (typeof contactSlice.actions)[keyof typeof contactSlice.actions]
>

export default contactSlice.reducer

import { makeRequest } from 'services/api'
import {
    IEmailConfig,
    IEmailConfigUpdateData,
    IEmailTemplate,
} from 'store/channelSettings/email/email.state'

const basePath = '/email-message-service/companies/{companyID}/instances/{instanceID}'

export const getEmailMessageConfigs = async (brand: string): Promise<IEmailConfig[]> => {
    const { data: emailConfigs } = await makeRequest({
        url: `${basePath}/brands/${brand}/email-configs`,
        method: 'get',
    })

    return emailConfigs
}

export const getEmailsForRoutingProfile = async (routingProfileId: string): Promise<string[]> => {
    const { data } = await makeRequest({
        url: `${basePath}/routing-profile/${routingProfileId}/emails`,
        method: 'get',
    })

    return data
}

export const getEmailTemplates = async (): Promise<IEmailTemplate[]> => {
    const { data } = await makeRequest({
        url: `${basePath}/email-templates`,
        method: 'get',
    })

    return data
}

export const getListOfEmails = async (): Promise<string[]> => {
    const { data } = await makeRequest({
        url: `${basePath}/emails`,
        method: 'get',
    })

    return data
}

export const updateEmailConfig = async (data: IEmailConfigUpdateData) => {
    const payload = {
        email: data.email,
        data: data.data,
    }

    await makeRequest({
        url: `${basePath}/email-config`,
        method: 'put',
        data: payload,
    })
}

export const addEmailConfig = async (data: IEmailConfigUpdateData) => {
    const payload = {
        email: data.email,
        data: data.data,
    }

    await makeRequest({
        url: `${basePath}/email-config`,
        method: 'post',
        data: payload,
    })
}

export const getEmailConfigForAddress = async (emailAddress: string): Promise<IEmailConfig> => {
    const { data: emailConfig } = await makeRequest({
        url: `${basePath}/email/${emailAddress}`,
        method: 'get',
    })

    return emailConfig
}

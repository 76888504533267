import { useSelector } from 'react-redux'
import RootState from 'store/state'

function useAnnouncements() {
    const announcements = useSelector<RootState, RootState['announcements']>(
        ({ announcements }) => announcements,
    )

    return announcements
}

export default useAnnouncements

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { PureComponent } from "react";
import classNames from "classnames";
const Pass = "data:image/svg+xml,%3c?xml%20version='1.0'%20encoding='UTF-8'?%3e%3csvg%20width='13px'%20height='9px'%20viewBox='0%200%2013%209'%20version='1.1'%20xmlns='http://www.w3.org/2000/svg'%20xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3c!--%20Generator:%20Sketch%2062%20(91390)%20-%20https://sketch.com%20--%3e%3ctitle%3ePass%3c/title%3e%3cdesc%3eCreated%20with%20Sketch.%3c/desc%3e%3cdefs%3e%3cpath%20d='M6.35230395,10.0645068%20C6.01435023,9.72655311%205.466419,9.72655311%205.12846529,10.0645068%20C4.79051157,10.4024605%204.79051157,10.9503918%205.12846529,11.2883455%20L8.59447923,14.7543594%20L15.8715347,7.47730395%20C16.2094884,7.13935023%2016.2094884,6.591419%2015.8715347,6.25346529%20C15.533581,5.91551157%2014.9856498,5.91551157%2014.6476961,6.25346529%20L8.59447923,12.3066821%20L6.35230395,10.0645068%20Z'%20id='path-1'%3e%3c/path%3e%3c/defs%3e%3cg%20id='Form'%20stroke='none'%20stroke-width='1'%20fill='none'%20fill-rule='evenodd'%3e%3cg%20id='Form-/-Checkbox-/-NoLabel-/-ID&amp;V-/-Pass'%20transform='translate(-4.000000,%20-6.000000)'%3e%3cmask%20id='mask-2'%20fill='white'%3e%3cuse%20xlink:href='%23path-1'%3e%3c/use%3e%3c/mask%3e%3cuse%20id='Icon'%20fill='%2300856f'%20fill-rule='nonzero'%20xlink:href='%23path-1'%3e%3c/use%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
const Fail = "data:image/svg+xml,%3c?xml%20version='1.0'%20encoding='UTF-8'?%3e%3csvg%20width='10px'%20height='10px'%20viewBox='0%200%2010%2010'%20version='1.1'%20xmlns='http://www.w3.org/2000/svg'%20xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3c!--%20Generator:%20Sketch%2062%20(91390)%20-%20https://sketch.com%20--%3e%3ctitle%3eFail%3c/title%3e%3cdesc%3eCreated%20with%20Sketch.%3c/desc%3e%3cdefs%3e%3cpath%20d='M8.57741113,0.244077662%20C8.90284802,-0.0813592207%209.43048546,-0.0813592207%209.75592234,0.244077662%20C10.0813592,0.569514545%2010.0813592,1.09715198%209.75592234,1.42258887%20L9.75592234,1.42258887%20L6.178,5%20L9.75592234,8.57741113%20C10.0542395,8.87572828%2010.0790992,9.34395005%209.83050162,9.67058463%20L9.75592234,9.75592234%20C9.43048546,10.0813592%208.90284802,10.0813592%208.57741113,9.75592234%20L8.57741113,9.75592234%20L5,6.178%20L1.42258887,9.75592234%20C1.12427172,10.0542395%200.656049947,10.0790992%200.329415366,9.83050162%20L0.244077662,9.75592234%20C-0.0813592207,9.43048546%20-0.0813592207,8.90284802%200.244077662,8.57741113%20L0.244077662,8.57741113%20L3.822,5%20L0.244077662,1.42258887%20C-0.0542394804,1.12427172%20-0.0790992423,0.656049947%200.169498376,0.329415366%20L0.244077662,0.244077662%20C0.569514545,-0.0813592207%201.09715198,-0.0813592207%201.42258887,0.244077662%20L1.42258887,0.244077662%20L5,3.822%20Z'%20id='path-1'%3e%3c/path%3e%3c/defs%3e%3cg%20id='Form'%20stroke='none'%20stroke-width='1'%20fill='none'%20fill-rule='evenodd'%3e%3cg%20id='Form-/-Checkbox-/-NoLabel-/-ID&amp;V-/-Fail'%20transform='translate(-5.000000,%20-5.000000)'%3e%3cg%20id='Form'%3e%3cg%20id='Icon'%20transform='translate(5.000000,%205.000000)'%3e%3cmask%20id='mask-2'%20fill='white'%3e%3cuse%20xlink:href='%23path-1'%3e%3c/use%3e%3c/mask%3e%3cuse%20fill='%23E41D00'%20fill-rule='nonzero'%20xlink:href='%23path-1'%3e%3c/use%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
const Mid = "data:image/svg+xml,%3c?xml%20version='1.0'%20encoding='UTF-8'?%3e%3csvg%20width='12px'%20height='2px'%20viewBox='0%200%2012%202'%20version='1.1'%20xmlns='http://www.w3.org/2000/svg'%20xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3c!--%20Generator:%20Sketch%2062%20(91390)%20-%20https://sketch.com%20--%3e%3ctitle%3eMid%3c/title%3e%3cdesc%3eCreated%20with%20Sketch.%3c/desc%3e%3cg%20id='Form'%20stroke='none'%20stroke-width='1'%20fill='none'%20fill-rule='evenodd'%3e%3cg%20id='Form-/-Checkbox-/-NoLabel-/-ID&amp;V-/-Mid'%20transform='translate(-4.000000,%20-9.000000)'%20fill='%23DE7300'%20fill-rule='nonzero'%3e%3crect%20id='icon'%20x='4'%20y='9'%20width='12'%20height='2'%3e%3c/rect%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
var CheckState = /* @__PURE__ */ ((CheckState2) => {
  CheckState2["checked"] = "checked";
  CheckState2["unChecked"] = "unChecked";
  CheckState2["pending"] = "pending";
  CheckState2["blank"] = "blank";
  return CheckState2;
})(CheckState || {});
class MultiStateCheckbox extends PureComponent {
  constructor() {
    super(...arguments);
    __publicField(this, "state", {
      focused: false
    });
    __publicField(this, "onClick", (ev) => {
      const { nextState, checkState, onChange, disabled, readOnly } = this.props;
      if (!disabled && !readOnly && onChange)
        onChange(nextState && nextState(checkState) || this.nextState(checkState), ev);
    });
    __publicField(this, "setFocus", () => {
      this.setState({
        focused: true
      });
    });
    __publicField(this, "unsetFocus", () => {
      this.setState({
        focused: false
      });
    });
    __publicField(this, "setAriaChecked", () => {
      const { checkState } = this.props;
      return checkState === "checked" ? "true" : checkState === "unChecked" ? "false" : checkState === "pending" ? "mixed" : void 0;
    });
    __publicField(this, "setChecked", () => {
      const { checkState } = this.props;
      return checkState === "checked" ? true : checkState === "unChecked" ? true : checkState === "pending" ? true : false;
    });
    __publicField(this, "setCheckState", () => {
      const { checkState, ariaCheckLabel } = this.props;
      const passed = `${ariaCheckLabel ? `${ariaCheckLabel} passed` : "Checked"}`;
      const failed = `${ariaCheckLabel ? `${ariaCheckLabel} failed` : "Fail"}`;
      const pending = `${ariaCheckLabel ? `${ariaCheckLabel} pending` : "Pending"}`;
      switch (checkState) {
        case "checked":
          return /* @__PURE__ */ jsx("img", { src: Pass, alt: passed, title: passed });
        case "unChecked":
          return /* @__PURE__ */ jsx("img", { src: Fail, alt: failed, title: failed });
        case "pending":
          return /* @__PURE__ */ jsx("img", { src: Mid, alt: pending, title: pending });
        default:
          return null;
      }
    });
  }
  nextState(checkState) {
    switch (checkState) {
      case "blank":
      case "pending":
      case "unChecked":
        return "checked";
      case "checked":
      default:
        return "unChecked";
    }
  }
  render() {
    const { checkState } = this.props;
    const checkboxClass = classNames("no-drag", {
      "checkbox": true,
      "focused": this.state.focused,
      "disabled": this.props.disabled,
      [`state-${checkState}`]: true,
      "square": !!this.props.square
    });
    const checkboxWrapperClass = classNames({
      "ms-wrapper": true,
      "label-right": this.props.labelRight
    });
    return /* @__PURE__ */ jsx("div", { className: "ms", children: /* @__PURE__ */ jsxs("label", { className: checkboxWrapperClass, children: [
      /* @__PURE__ */ jsx("span", { className: "checkbox-label", children: this.props.label }),
      /* @__PURE__ */ jsx(
        "input",
        {
          onFocus: this.setFocus,
          onBlur: this.unsetFocus,
          onClick: this.onClick,
          type: "checkbox",
          checked: this.setChecked()
        }
      ),
      /* @__PURE__ */ jsx("span", { "aria-checked": this.setAriaChecked(), "aria-label": checkState === "blank" ? "Blank" : "", title: checkState === "blank" ? "Blank" : "", className: checkboxClass, children: this.setCheckState() })
    ] }) });
  }
}
__publicField(MultiStateCheckbox, "defaultProps", {
  disabled: false,
  square: false,
  checkState: "blank"
  /* blank */
});
export {
  CheckState,
  MultiStateCheckbox as default
};

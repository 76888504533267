import { EditorState } from 'draft-js'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
    DeleteTaskAttachmentAction,
    ITask,
    SetIncludeCCAction,
    SetReplyTextAction,
    SetTaskAttachmentAction,
    TasksState,
    TransferTaskAction,
    UpdateConnectionCCAction,
    UpdateConnectionEditorAction,
} from './tasks.state'

const initialState: TasksState = {
    connections: [],
}

export const tasksSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        addTaskConnection(state, action: PayloadAction<ITask>) {
            return {
                ...state,
                connections: [...state.connections, action.payload],
            }
        },
        taskACW(state, action: PayloadAction<string>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload
                        ? {
                              ...c,
                              acw: true,
                              acwStartTimestamp: Date.now(),
                              status: connect.ContactStateType.ENDED,
                          }
                        : c,
                ),
            }
        },
        endTask(state, action: PayloadAction<string>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload ? { ...c, status: connect.ContactStateType.ENDED } : c,
                ),
            }
        },
        acceptTask(state, action: PayloadAction<string>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload ? { ...c, accepting: true } : c,
                ),
            }
        },
        taskConnected(state, action: PayloadAction<string>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload
                        ? {
                              ...c,
                              status: connect.ContactStateType.CONNECTED,
                              started: Date.now(),
                              accepting: undefined,
                          }
                        : c,
                ),
            }
        },
        taskDisconnected(state, action: PayloadAction<string>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload ? { ...c, status: connect.ContactStateType.ENDED } : c,
                ),
            }
        },
        removeTaskConnection(state, action: PayloadAction<string>) {
            return {
                ...state,
                connections: state.connections.filter((c) => c.id !== action.payload),
            }
        },
        // This case need for removing chat connection from store without following .destroy()
        // api call which present in listener on removeChatConnection action
        clearTaskConnection(state, action: PayloadAction<string>) {
            return {
                ...state,
                connections: state.connections.filter((c) => c.id !== action.payload),
            }
        },
        taskMissed(state, action: PayloadAction<string>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload ? { ...c, status: connect.ContactStateType.MISSED } : c,
                ),
            }
        },
        completeTaskACW(state, action: PayloadAction<string>) {
            return {
                ...state,
                connections: state.connections.filter((c) => c.id !== action.payload),
            }
        },
        recoverTasks(state, action: PayloadAction<ITask[]>) {
            return {
                ...state,
                connections: action.payload,
            }
        },
        setTaskReplyText(state, action: PayloadAction<SetReplyTextAction>) {
            return {
                ...state,
                connections: state.connections.map((c) => {
                    if (c.id !== action.payload.id) return c
                    return {
                        ...c,
                        reply: action.payload,
                    }
                }),
            }
        },
        clearTaskReplyText(state, action: PayloadAction<string>) {
            return {
                ...state,
                connections: state.connections.map((c) => {
                    if (c.id !== action.payload) return c
                    return {
                        ...c,
                        reply: undefined,
                    }
                }),
            }
        },
        updateConnectionEditorContent(state, action: PayloadAction<UpdateConnectionEditorAction>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload.id
                        ? { ...c, currentEditorContent: action.payload.content }
                        : c,
                ),
            }
        },
        updateConnectionCCAddressesList(state, action: PayloadAction<UpdateConnectionCCAction>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload.id
                        ? { ...c, currentCCAddressesList: action.payload.ccAddressesList }
                        : c,
                ),
            }
        },
        sendTaskAttachment(state, action: PayloadAction<string>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload
                        ? {
                              ...c,
                              currentEditorContent: EditorState.createEmpty(),
                              attachments: [],
                          }
                        : c,
                ),
            }
        },
        declineTask(_state, _action: PayloadAction<string>) {},
        setSelectedTask(_state, _action: PayloadAction<string | null>) {},
        transferTask(_state, _action: PayloadAction<TransferTaskAction>) {},
        setTaskAttachment(state, action: PayloadAction<SetTaskAttachmentAction>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload.id
                        ? {
                              ...c,
                              attachments: [...c.attachments, ...action.payload.attachments],
                          }
                        : c,
                ),
            }
        },
        deleteTaskAttachment(state, action: PayloadAction<DeleteTaskAttachmentAction>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload.id
                        ? {
                              ...c,
                              attachments: [
                                  ...c.attachments.filter((_, i) => action.payload.index !== i),
                              ],
                          }
                        : c,
                ),
            }
        },
        setIncludeCCInReply(state, action: PayloadAction<SetIncludeCCAction>) {
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload.id
                        ? { ...c, includeCCInReply: action.payload.includeCCInReply }
                        : c,
                ),
            }
        },
    },
})

export const {
    addTaskConnection,
    taskACW,
    endTask,
    acceptTask,
    taskConnected,
    taskDisconnected,
    removeTaskConnection,
    clearTaskConnection,
    taskMissed,
    completeTaskACW,
    recoverTasks,
    setTaskReplyText,
    clearTaskReplyText,
    transferTask,
    updateConnectionEditorContent,
    updateConnectionCCAddressesList,
    sendTaskAttachment,
    setTaskAttachment,
    declineTask,
    setSelectedTask,
    deleteTaskAttachment,
    setIncludeCCInReply,
} = tasksSlice.actions

export type TasksAction = ReturnType<(typeof tasksSlice.actions)[keyof typeof tasksSlice.actions]>

export default tasksSlice.reducer

import { EditorState } from 'draft-js'
import { DPACheck, DPAResult, DPAState } from 'store/contact/contact.state'

import { SAMessageType } from '@missionlabs/smartagent-chat-components-lib/dist/ChatMessage/chat-message.types'

import { MessageSources, SocialMediaPlatform } from './chat.utils'

import type { SocialChatJsonMessage } from '@missionlabs/smartagent-service-chat/dist/types/socialChatJsonMessage'

export interface ChatState {
    connections: IChat[]
    quickReplies?: { [key: string]: IChatQuickReply[] }
    quickRepliesLoading: boolean
    selectedChatId?: string
}

export interface IChatQuickReply {
    id: string | number
    children?: IChatQuickReply[]
    content?: string
    text: string
}

export type AttachmentLoadingState = 'waiting' | 'loading' | 'done'

export interface IChatAttachmentFile {
    file: File
    loadingState: AttachmentLoadingState
}

export interface IChat {
    id: string
    initialContactId: string
    status: connect.ContactStateType
    acw: boolean
    acwStartedAt: number | null
    acwExtendedAt: number | null
    queueName: string
    isTyping: boolean
    started: number
    customerName: string
    customerEmail?: string
    lastMessage?: number
    messages: Array<IChatMessage>
    unread: number
    reply: {
        text: string
        isProbablyJson: boolean
    }
    channel?: MessageSources
    DPA?: DPAState
    'sa-acw-notes'?: string
    'sa-notes'?: string
    currentEditorContent: EditorState
    attachments: {
        files: IChatAttachmentFile[]
    }
    socialMediaPlatform?: SocialMediaPlatform
    customerActive?: boolean
    sourceMessage?: SourceMessage
    translationLanguage?: string
    isLoadingTranscripts?: boolean
}

interface SourceMessage {
    content: string
    timestamp: number
    link: string
}

export interface IChatMessage extends connect.ChatTranscriptItem {
    content: string | SAMessageType
    readTimestamp?: string
    deliveredTimestamp?: string
    OriginalMessage?: string | SAMessageType
}

export interface ISocialChatMessage extends connect.ChatTranscriptItem {
    content: string | SocialChatJsonMessage
}

export enum ChatMessageEvent {
    TYPING = 'application/vnd.amazonaws.connect.event.typing',
    CONNECTION_ACKNOWLEDGED = 'application/vnd.amazonaws.connect.event.connection.acknowledged',
    MESSAGE_DELIVERED = 'application/vnd.amazonaws.connect.event.message.delivered',
    MESSAGE_READ = 'application/vnd.amazonaws.connect.event.message.read',
}

export interface UpdateConnectionEditorAction {
    id: string
    content: EditorState
}

export interface SendChatMessageAction {
    id: string
    msg: string
    clearEditor?: boolean
}

export interface ReceiveChatMessageAction {
    id: string
    msg: IChatMessage | IChatMessage[]
}

export interface SetUnreadChatsAction {
    id: string
    unread: number
}

export interface ChatContactTypingAction {
    id: string
    typing: boolean
}

export interface GetQuickRepliesAction {
    [key: string]: IChatQuickReply[]
}

export interface UpdateDPAChatAction {
    id: string
    check: DPACheck
}

export interface UpdateDPAResultChatAction {
    id: string
    result: DPAResult
}

export interface SetReplyTextAction {
    id: string
    text: string
    isProbablyJson: boolean
}

export interface UpdateNoteAction {
    id: string
    note: string
}

export interface SendAttachmentAction {
    id: string
    stringMessage: string
}

export interface SetAttachmentAction {
    id: string
    files: File[]
}

export interface SetAttachmentLoadingAction {
    id: string
    state: AttachmentLoadingState
    index?: number
}

export interface SetTimestampAction {
    chatId: string
    messageId: string
    timestamp: string
}

export interface DeleteAttachmentAction {
    id: string
    index: number
}

export interface DownloadAttachmentAction {
    attachmentId: string
    attachmentName: string
}

export interface DownloadConnectAttachmentAction {
    attachmentId: string
    contactID: string
}

export interface TransferChatAction {
    id: string
    endpoint: connect.Endpoint
}

export interface SetMessagesAction {
    id: string
    messages: Array<IChatMessage>
}

import { TimeRange, TimezoneDropdown, ToolBar } from 'components'
import useHasFeature from 'hooks/useHasFeature'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppFeatures } from 'store/app/app.features'
import { IFilters } from 'store/metrics/metrics.state'
import { editUser } from 'store/settings/settings.actions'
import { IQueue } from 'store/settings/settings.state'
import RootState from 'store/state'
import { Queue } from 'store/user/user.state'

import MetricFilterList from '../../MetricFilterList'
import { getToolbarOptions } from '../utils'

const timeRangeOptions = [
    { label: '0.5', data: 0.5 },
    { label: '1', data: 1 },
    { label: '2', data: 2 },
]

interface ComponentProps {
    queues: IQueue[] | undefined
    filters: IFilters
    onFilterChange: (filters: Partial<IFilters>) => void
    isRTMPage: boolean
    userQueues: Queue[] | undefined
}

type Props = ComponentProps

const QueueMetricsHeader: React.FC<Props> = ({
    queues,
    filters,
    onFilterChange,
    isRTMPage,
    userQueues,
}) => {
    const { timeRange, timeZone, queueIDs } = filters

    const dispatch = useDispatch()
    const agentID = useSelector((state: RootState) => state.user?.agentID)
    const routingProfile = useSelector((state: RootState) => state.user?.routingProfile)
    const hasFeature = useHasFeature()

    const onQueueChange = (queues: any) => {
        onFilterChange({
            queueIDs: queues ? queues.map((q: any) => q.value) : queues,
        })
    }

    const onTimezoneChange = (timeZone: string) => {
        dispatch(
            editUser({
                ID: agentID,
                timeZone: timeZone,
                routingProfileID: routingProfile?.ID.split('/').pop(),
            }),
        )
        onFilterChange({ timeZone })
    }

    const options = getToolbarOptions(queues, queueIDs, isRTMPage, userQueues)

    const TimezoneInfo = !hasFeature(AppFeatures.REALTIME_DATA_QUEUES_REDESIGNED) && (
        <div className="row middle" key="timezone-filter">
            <p className="sm-mar">
                <b>Timezone</b>
            </p>
            <TimezoneDropdown
                defaultValue={timeZone}
                onChange={(timeZone) => onTimezoneChange(timeZone)}
            />
        </div>
    )

    return (
        <ToolBar
            text="Queues"
            items={[
                options ? (
                    <MetricFilterList options={options} text="All" onSave={onQueueChange} />
                ) : null,
                TimezoneInfo,
                <TimeRange
                    key={timeRange}
                    timeRangeOptions={timeRangeOptions}
                    definedTime={timeRange}
                    setTimeRange={(timeRange) => onFilterChange({ timeRange })}
                />,
            ]}
        />
    )
}

export default QueueMetricsHeader

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import InternalMessagingState, { NewMessage } from './internal-messaging.state'

const initialState: InternalMessagingState = {
    selectedConversationId: undefined,
    phoneBookOpen: false,
}

export const internalMessagingSlice = createSlice({
    name: 'internalMessaging',
    initialState,
    reducers: {
        selectConversation(state, action: PayloadAction<string | undefined>) {
            return {
                ...state,
                selectedConversationId: action.payload,
            }
        },
        newMessageNotification(_state, _action: PayloadAction<NewMessage>) {},
        openPhonebook(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                phoneBookOpen: action.payload,
            }
        },
    },
})

export const { selectConversation, newMessageNotification, openPhonebook } =
    internalMessagingSlice.actions

export type InternalMessagingAction = ReturnType<
    (typeof internalMessagingSlice.actions)[keyof typeof internalMessagingSlice.actions]
>

export default internalMessagingSlice.reducer

import { formatDateTime } from '@missionlabs/smartagent-app-components'
import type { Attachment } from '@missionlabs/smartagent-service-thread'
import classnames from 'classnames'
import pdfIconDark from 'images/pdfDark.svg'
import pdfIconLight from 'images/pdfLight.svg'
import React from 'react'
import DownloadAttachment from 'views/Tasks/Task/Thread/components/DownloadAttachment'
import './thread-bubble.scss'

interface Props {
    type: string
    date: number
    content: string
    subject?: string
    attachments: Attachment[] | undefined
    messageID: string
    threadID: string
    name: string | undefined
    isAutoreply?: boolean
}

const ThreadBubble: React.FC<Props> = ({
    type,
    attachments,
    content,
    subject,
    threadID,
    messageID,
    name,
    date,
    isAutoreply,
}) => {
    return (
        <div className="thread-message-box">
            {type === 'OUTBOUND' && name && (
                <div className="thread-message-box-username">
                    <span>From:</span> {name}
                </div>
            )}
            {isAutoreply && (
                <div className="thread-message-box-username">
                    <span>Autoreply</span>
                </div>
            )}
            <div
                className={classnames({
                    'thread-bubble': true,
                    inbound: type === 'INBOUND',
                    outbound: type === 'OUTBOUND',
                })}
            >
                <div
                    dangerouslySetInnerHTML={{
                        __html: content ?? subject,
                    }}
                />
                <div
                    className={classnames({
                        'thread-bubble-items': true,
                        inbound: type === 'INBOUND',
                        outbound: type === 'OUTBOUND',
                    })}
                >
                    {attachments &&
                        attachments?.length > 0 &&
                        attachments.map((attachment: Attachment) => (
                            <div className="thread-bubble-items-attachments" key={attachment.s3Key}>
                                <img
                                    src={type === 'OUTBOUND' ? pdfIconLight : pdfIconDark}
                                    alt="pdf"
                                    title="pdf"
                                />
                                <DownloadAttachment
                                    threadID={threadID}
                                    messageID={messageID}
                                    attachment={attachment}
                                />
                            </div>
                        ))}
                    <span className="thread-bubble-items-date">
                        {formatDateTime(date, { date: true, time: true })}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default ThreadBubble

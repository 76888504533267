import Warning from 'images/icon-hazard-fill.svg'

import { useLocation } from 'react-router-dom'
import './error-popup.scss'

interface Props {
    online: boolean
    text: string
}

const ErrorPopup = (props: Props) => {
    const { online, text } = props
    const location = useLocation()
    const isRealTimeMetricsPage = location.pathname === '/metrics/real-time-wb'

    return (
        <div className={`error-popup-container ${!online ? 'show' : ''}`}>
            {!online ? (
                <div
                    aria-live="polite"
                    role="dialog"
                    title="Error"
                    aria-label={text}
                    className={'error-popup'}
                >
                    {isRealTimeMetricsPage ? (
                        <p className="text">
                            You are offline. Try{' '}
                            <button
                                className="button-link"
                                onClick={() => window.location.reload()}
                            >
                                refreshing the page
                            </button>{' '}
                            or check your internet connection. Once back online, please reset your
                            status.
                        </p>
                    ) : (
                        <>
                            <img src={Warning} width="22" title="Error" alt="Error" />
                            <p className="text">{text}</p>
                        </>
                    )}
                </div>
            ) : null}
        </div>
    )
}

export default ErrorPopup

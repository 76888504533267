import { createSelector } from 'reselect'
import RootState from 'store/state'

export const selectCallState = (state: RootState) => state.call

export const selectCallConnections = createSelector(selectCallState, (call) => call?.connections)

export const selectOnHoldConnections = createSelector(selectCallConnections, (connections) =>
    connections?.filter((connection) => connection.hold),
)

import * as ContactReducer from 'store/contact/contact.reducer'
import { isEmpty } from 'utils'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import ContactState from '../contact/contact.state'
import { mergeAttributesIntoContact } from '../contact/contact.utils'

const initialState: ContactState[] = []

export const contactsSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
        addContact(state, action: PayloadAction<ContactState>) {
            const isEqualObject = state.some((c) => c.ID === action.payload.ID)
            const newState = isEqualObject ? state : [...state, action.payload]
            return newState
        },
        filterContacts(state, action: PayloadAction<string>) {
            return state.filter((c) => c.ID !== action.payload)
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(ContactReducer.updateACWAttributes, (state, action) => {
            if (!action.payload.ID) return state
            const restState = state.filter((c) => c.ID !== action.payload.ID)
            const activeContact = state.find((c) => c.ID === action.payload.ID)
            return [
                ...restState,
                { ...activeContact!, acwAttributes: action.payload.acwAttributes },
            ]
        }),
            addCase(ContactReducer.updateContactAttributes, (state, action) => {
                if (!action.payload.ID) return state
                const restState = state.filter((c) => c.ID !== action.payload.ID)
                const activeContact = state.find((c) => c.ID === action.payload.ID)
                if (!activeContact || isEmpty(activeContact)) return state
                return [
                    ...restState,
                    mergeAttributesIntoContact(activeContact!, action.payload.attributes),
                ]
            }),
            addCase(ContactReducer.updateContact, (state, action) => {
                const restState = state.filter((c) => c.ID !== action.payload.ID)
                return [...restState, action.payload]
            })
    },
})

export const { addContact, filterContacts } = contactsSlice.actions

export type ContactsAction = ReturnType<
    (typeof contactsSlice.actions)[keyof typeof contactsSlice.actions]
>

export default contactsSlice.reducer

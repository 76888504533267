import { Loader } from 'components'
import React, { lazy, Suspense } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'

interface ComponentProps {}

type Props = RouteComponentProps<any> & ComponentProps

const NavPlugin = lazy(() => import('views/Menu/NavPlugin'))
const Task = lazy(() => import('views/Tasks/Task'))
const TaskMain = lazy(() => import('views/Tasks/TaskMain'))
const OutboundEmail = lazy(() => import('views/Tasks/OutboundEmail'))

/**
 * Wraps NavPlugin component in a div, otherwise plugins end up squashed into a
 * small box.
 */
const NavPluginWrapper = () => {
    return (
        <div className="panel grow alt">
            <NavPlugin />
        </div>
    )
}

const Tasks: React.FC<Props> = () => {
    return (
        <section className="row">
            <Suspense
                fallback={
                    <div className="lazy-loading">
                        <Loader />
                    </div>
                }
            >
                <Switch>
                    <Route path={`/task`} exact component={TaskMain} />
                    <Route path={`/task/outbound-email`} component={OutboundEmail} />
                    <Route path={`/task/:plugin`} component={NavPluginWrapper} />
                    <Route path={`/tasks`} component={Task} />
                </Switch>
            </Suspense>
        </section>
    )
}

export default Tasks

import type { ThunkDispatch } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { MS_IN_HOUR } from 'constants/index'
import * as AgentAPI from 'services/api/api.agent'
import * as AuthAPI from 'services/api/api.auth'
import * as ContactAPI from 'services/api/api.contact'
import { AppFeatures, IFeature } from 'store/app/app.features'
import { deleteSATabSession, getMidnight, getParameterByName, getTimeRangeString } from 'utils'
import { AgentQueueStats, IAgentQueueStatsV2 } from './user.state'

import { retryFunction } from '@missionlabs/smartagent-lib-shared/build/common'

import { setRedirect } from '../global/global.actions'
import * as GlobalActions from '../global/global.reducer'
import RootState from '../state'
import * as UserActions from './user.reducer'

export const {
    newPasswordRequiredChange: changePassword,
    userLoaded,
    mfaRequired,
    afterCallWorkEnd,
    setStatusControl,
    setAfterCallStatus,
    setTimeRange,
    tokenError,
    submitMFA,
    hideQuickConnects,
    getQuickConnects,
    userError,
    userAuthenticated,
    refresh,
    afterCallWork,
    showQuickConnects,
    toggleQuickConnects,
    userAvailable: setAvailable,
    userNotAvailable: setNotAvailable,
    userAuthenticationHasSet,
    callIssue,
    login,
    setShowNextStatus,
    forgotPassword,
    forgotPasswordGetCode,
    forgotPasswordConfirm,
    rememberedPassword,
    fetchAgentMetricsRedesigned,
} = UserActions

export function logout() {
    return (
        dispatch: ThunkDispatch<RootState, undefined, UserActions.UserAction>,
        getState: () => RootState,
    ) => {
        deleteSATabSession()
        const companyID = getState().app.ID
        const token = getState().auth.token
        const user = getState().user
        const instanceID = getState().app.instance!.ID

        if (!token || !user) {
            return dispatch(UserActions.logout())
        }

        AuthAPI.logout(companyID, token, user.username, instanceID)

        return dispatch(UserActions.logout())
    }
}

export function setState(state: connect.AgentStateDefinition) {
    return UserActions.userSetState({ state })
}

export function authError(error?: string) {
    return UserActions.userAuthError({ error: error || 'There was an error' })
}

export function authenticateExternalUser(credentials: any) {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, UserActions.UserAction>,
        getState: () => RootState,
    ) => {
        dispatch(
            UserActions.authExternalUserRequest({
                credentials,
                ccpURL: getState().app.instance!.ccpURL,
            }),
        )
    }
}

export function authenticateUser(token: string, tokenInBody: boolean = false) {
    return async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            UserActions.UserAction | GlobalActions.GlobalAction
        >,
        getState: () => RootState,
    ) => {
        dispatch(UserActions.authenticatingUser())

        const { app, user, auth } = getState()

        if (!app.instance) {
            if (app.instances?.length !== 1) {
                console.log(
                    'Unable to log user back in - multiple instances for client and instance not selected yet',
                )
                dispatch(authError(`Session expired, please login`))
                return
            }
        }

        const companyID = app.ID
        const username = user ? user.username : undefined
        const instanceID = app.instance?.ID || app.instances[0].ID

        try {
            const res = await retryFunction<AxiosError>({
                retryCount: 100000,
                waitTimeMs: app.appConfig.loginRequestRetryWaitTime ?? 5000,
                errorConditionToRetry: (error) => {
                    return error.code === '429'
                },
            })(
                async () =>
                    await AuthAPI.authenticateUser(
                        companyID,
                        token,
                        instanceID,
                        username,
                        tokenInBody,
                        getParameterByName('identityManagement') || undefined,
                    ),
            )

            const refreshToken = res!.headers['x-refresh-token']
            const authToken = res!.data

            if (authToken.credentials) {
                dispatch(authenticateExternalUser(authToken.credentials))
            }

            //If no refresh token then fresh login
            if (!auth.refreshToken) {
                dispatch(setRedirect('/'))
            }

            return dispatch(
                userAuthenticated({
                    token: authToken.ID,
                    refreshToken: refreshToken,
                    features: authToken.features,
                    agentID: authToken.userID,
                    roles: authToken.securityRoles,
                    hierarchyStructure: authToken.hierarchyStructure,
                }),
            )
        } catch (ex) {
            console.warn(ex)
            if (ex.response && ex.response.status === 404) {
                dispatch(authError(`User does not exist in connect`))
            } else {
                const errorMessage: string =
                    ex?.response?.data?.error || ex?.response?.data?.message || ''
                dispatch(authError(errorMessage))
            }
        }
    }
}

export function fetchAgentStats() {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, UserActions.UserAction>,
        getState: () => RootState,
    ) => {
        const user = getState().user!
        const companyID = getState().app.ID
        const token = getState().auth.token!
        const agentID = user.agentID
        const instanceID = getState().app.instance!.ID
        const timeRange = getState().metrics.filters.timeRange ?? user.timeRange
        const timeZone = getState().metrics.filters.timeZone

        const agentMetricsRedesigned = getState().app.features.find(
            (f) => f.ID === AppFeatures.REALTIME_DATA_AGENTS_REDESIGNED,
        ) as IFeature<AppFeatures.REALTIME_DATA_AGENTS_REDESIGNED>

        const from = agentMetricsRedesigned
            ? getTimeRangeString(timeRange)
            : timeRange === 'midnight'
              ? getMidnight(timeZone)
              : new Date(Date.now() - timeRange * 3600000).toISOString()

        const queueStatsFeature = getState().app.features.find(
            (f) => f.ID === AppFeatures.QUEUE_STATS,
        ) as IFeature<AppFeatures.QUEUE_STATS>

        if (queueStatsFeature?.config?.hideHistoricMetrics) return

        if (agentMetricsRedesigned?.config?.hideHistoricMetrics) return

        try {
            const agentMetrics = agentID
                ? agentMetricsRedesigned
                    ? await AgentAPI.getAgentMetricsV2({
                          companyID,
                          instanceID,
                          agentID,
                          token,
                          dataType: from!,
                      }).catch((err) => console.error('Error getting agentMetrics:', err))
                    : await ContactAPI.getQueueMetrics(companyID, instanceID, token, from!, {
                          agentID,
                      }).catch((err) => console.error('Error getting agentMetrics:', err))
                : []

            if (agentMetricsRedesigned) {
                return dispatch(
                    UserActions.fetchAgentMetricsRedesigned(agentMetrics as IAgentQueueStatsV2[]),
                )
            }

            return dispatch(UserActions.fetchAgentStats(agentMetrics as AgentQueueStats[]))
        } catch (err) {
            console.warn(err)
            const e = err as AxiosError

            if (e.response?.status === 403) {
                return dispatch(logout())
            }
        }
    }
}

export function refreshToken() {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, UserActions.UserAction>,
        getState: () => RootState,
    ) => {
        dispatch(UserActions.validatingToken())

        const companyID = getState().app.ID
        const username = getState().user!.username
        const refreshToken = getState().auth.refreshToken
        const instanceID = getState().app.instance!.ID || null
        try {
            const res = await AuthAPI.refreshToken(companyID, username, refreshToken!, instanceID!)
            const XRefreshToken = res.headers['x-refresh-token']
            const authToken = res.data

            return dispatch(
                userAuthenticated({
                    token: authToken.ID,
                    refreshToken: XRefreshToken,
                    features: authToken.features,
                    agentID: authToken.userID,
                    roles: authToken.securityRoles,
                    hierarchyStructure: authToken.hierarchyStructure,
                }),
            )
        } catch (err) {
            const { authenticated } = getState().auth
            const e = err as AxiosError

            if (e.response?.status === 401) {
                //If not authenticated then refresh token has expired and we can request another
                if (authenticated) {
                    dispatch(logout())
                } else {
                    dispatch(requestToken())
                }
            }
            console.log('error on refresh', err)
        }
    }
}

export function requestToken() {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, UserActions.UserAction>,
        getState: () => RootState,
    ) => {
        dispatch(UserActions.requestingToken())

        const companyID = getState().app.ID
        const username = getState().user!.username
        const instanceID = getState().app.instance!.ID
        try {
            await AuthAPI.requestToken(companyID, username, instanceID)

            dispatch(UserActions.tokenRequested())
        } catch (ex) {
            dispatch(UserActions.tokenError())
        }
    }
}

export function setLogoutCountdown() {
    return async (
        dispatch: ThunkDispatch<RootState, undefined, UserActions.UserAction>,
        getState: () => RootState,
    ) => {
        const companyID = getState().app.ID
        const agentID = getState().user!.agentID
        const instanceID = getState().app.instance!.ID
        const token = getState().auth.token!

        try {
            const agent = await AgentAPI.getSingleUser(companyID, instanceID, token, agentID)

            const loggedInDate = new Date(agent.lastLogin!).getTime()
            const loggedOutTime = new Date(loggedInDate).setTime(loggedInDate + 10 * MS_IN_HOUR)

            setInterval(() => {
                const now = Date.now()

                const diff = Math.floor(loggedOutTime - now)
                const min = Math.floor(diff / 60000)

                dispatch(UserActions.setLogoutCountdown(min))
            }, 60000)

            const { timeZone, tags = {} } = agent

            dispatch(UserActions.userLoaded({ timeZone, tags }))
        } catch (ex) {
            dispatch(UserActions.userError())
        }
    }
}

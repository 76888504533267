import type { ThunkDispatch } from '@reduxjs/toolkit'
import logger from 'logger'
import * as DirectoryAPI from 'services/api/api.externalDirectory'
import { IDirectoryFormState } from 'views/Settings/DirectoryManagement/DirectoryItemForm/useDirectoryFormReducer'

import RootState from '../../state'
import directoryContactsParser, { directoryContactParser } from './parsers'
import * as ExternalDirectoryActions from './reducers'

export const { setManageExternalDirectorySearchParams } = ExternalDirectoryActions

export const getExternalDirectoryContacts =
    () =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            ExternalDirectoryActions.ExternalDirectoryAction
        >,
        getState: () => RootState,
    ) => {
        const { app, directory, auth } = getState()

        if (
            (!directory.externalDirectory.contacts ||
                directory.externalDirectory.contacts.length === 0) &&
            !directory.externalDirectory.isLoading
        ) {
            try {
                dispatch(ExternalDirectoryActions.externalDirectoryLoading(true))

                const response = await DirectoryAPI.getContacts(app.ID, auth.token!)

                const parsedContacts = directoryContactsParser(response.data)

                dispatch(ExternalDirectoryActions.setExternalDirectory(parsedContacts))

                return parsedContacts
            } catch (error) {
                logger.error('GET-DIRECTORY-CONTACTS', error as object)

                dispatch(
                    ExternalDirectoryActions.externalDirectoryError(
                        `Failed to fetch Directory contacts`,
                    ),
                )
            } finally {
                dispatch(ExternalDirectoryActions.externalDirectoryLoading(false))
            }
        }

        return directory.externalDirectory.contacts
    }

export const addExternalDirectoryItem =
    (data: IDirectoryFormState) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            ExternalDirectoryActions.ExternalDirectoryAction
        >,
        getState: () => RootState,
    ) => {
        dispatch(ExternalDirectoryActions.editExternalDirectoryItemLoading(true))

        try {
            const { app, auth } = getState()

            const response = await DirectoryAPI.postContact(app.ID, auth.token!, data)

            dispatch(
                ExternalDirectoryActions.addExternalDirectoryItem(
                    directoryContactParser(response.data),
                ),
            )
        } catch (error) {
            logger.error('ADD-DIRECTORY-ITEM', error as object)
        }
    }

export const editExternalDirectoryItem =
    (id: string, data: IDirectoryFormState) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            ExternalDirectoryActions.ExternalDirectoryAction
        >,
        getState: () => RootState,
    ) => {
        try {
            dispatch(ExternalDirectoryActions.editExternalDirectoryItemLoading(true))

            const { app, auth } = getState()

            const response = await DirectoryAPI.putContactById(app.ID, auth.token!, id, data)

            dispatch(
                ExternalDirectoryActions.editExternalDirectoryItem(
                    directoryContactParser(response.data),
                ),
            )
        } catch (error) {
            logger.error('EDIT-DIRECTORY-ITEM', error as object)
        }
    }

export const deleteExternalDirectoryItem =
    (itemID: string) =>
    async (
        dispatch: ThunkDispatch<
            RootState,
            undefined,
            ExternalDirectoryActions.ExternalDirectoryAction
        >,
        getState: () => RootState,
    ) => {
        dispatch(ExternalDirectoryActions.deleteExternalDirectoryItemLoading(true))

        try {
            const { app, auth } = getState()

            await DirectoryAPI.deleteContactById(app.ID, auth.token!, itemID)

            dispatch(ExternalDirectoryActions.deleteExternalDirectoryItem(itemID))
        } catch (err) {
            logger.error('DELETE-DIRECTORY-ITEM', err as object)
        }
    }

import { IScoreSearchForm } from 'views/QA/ScoreSearch/ScoreSearch.types'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
    CalibrationStatus,
    EvaluationConfig,
    ICalibration,
    ICalibrationFull,
    IQATemplate,
    IScore,
    IScoreDetails,
    IScoreSearch,
    NewScoreAction,
    QAState,
    StartCalibrationAction,
} from './qa.state'

const initialState: QAState = {
    showNewScoreForm: false,
    unreadScoresCount: 0,
}

export const qaSlice = createSlice({
    name: 'qa',
    initialState,
    reducers: {
        searchScores(state, action: PayloadAction<IScoreSearch[]>) {
            return { ...state, scores: action.payload, searchScoresLoading: false }
        },
        searchScoresLoading(state) {
            return { ...state, searchScoresLoading: true }
        },
        searchScoresError(state) {
            return { ...state, searchScoresError: true, searchScoresLoading: false }
        },
        searchCalibrations(state, action: PayloadAction<ICalibration[]>) {
            return {
                ...state,
                calibrations: action.payload,
                searchCalibrationsError: false,
                searchCalibrationsLoading: false,
            }
        },
        searchCalibrationsLoading(state) {
            return { ...state, searchCalibrationsLoading: true }
        },
        searchCalibrationsError(state) {
            return { ...state, searchCalibrationsError: true }
        },
        startCalibration(state, action: PayloadAction<StartCalibrationAction>) {
            return {
                ...state,
                scores: state.scores?.map((s) => {
                    if (s.ID !== action.payload.scoreID) return s

                    return {
                        ...s,
                        calibrationStatus: CalibrationStatus.IN_PROGRESS,
                    }
                }),
            }
        },
        setCurrentlyCalibrating(state, action: PayloadAction<IScore>) {
            return { ...state, currentScore: action.payload as IScoreDetails }
        },
        changeCalibrationStatus(state, action: PayloadAction<ICalibration>) {
            return {
                ...state,
                calibrationsBusy: undefined,
                calibrations: state.calibrations?.map((c) => {
                    if (c.ID !== action.payload.ID) return c

                    return {
                        ...c,
                        calibrationStatus: action.payload.calibrationStatus,
                    }
                }),
                currentCalibration:
                    state.currentCalibration?.ID === action.payload.ID
                        ? {
                              ...state.currentCalibration,
                              calibrationStatus: action.payload.calibrationStatus,
                          }
                        : state.currentCalibration,
            }
        },
        getFullCalibration(state, action: PayloadAction<ICalibrationFull>) {
            return { ...state, currentCalibration: action.payload, calibrationsBusy: undefined }
        },
        clearCalibration(state) {
            return { ...state, currentCalibration: undefined }
        },
        calibrationBusy(state, action: PayloadAction<string | undefined>) {
            return { ...state, calibrationsBusy: action.payload }
        },
        setCurrentScore(state, action: PayloadAction<IScoreDetails>) {
            return { ...state, currentScore: action.payload, getScoreLoading: '' }
        },
        getScoreLoading(state, action: PayloadAction<string>) {
            return { ...state, getScoreLoading: action.payload }
        },
        clearScore(state) {
            return { ...state, currentScore: undefined, getScoreLoading: undefined }
        },
        updateScore(state, action: PayloadAction<IScoreDetails>) {
            return {
                ...state,
                updateScoreLoading: false,
                scoreCalculating: false,
                currentScore: {
                    ...state.currentScore,
                    ...action.payload,
                },
                scores: state.scores?.map((s) => {
                    if (s.ID !== action.payload.ID) return s

                    return {
                        ...s,
                        ...action.payload,
                    }
                }),
            }
        },
        updateScoreLoading(state) {
            return { ...state, updateScoreLoading: true }
        },
        newScore(state, action: PayloadAction<NewScoreAction>) {
            return {
                ...state,
                updateScoreLoading: false,
                scoreCalculating: false,
                currentScore: {
                    ...state.currentScore,
                    ...action.payload.newScore,
                    contact: action.payload.contact,
                },
                creatingScore: false,
                showNewScoreForm: false,
            }
        },
        creatingScore(state) {
            return { ...state, creatingScore: true }
        },
        scoreCalculate(state, action: PayloadAction<boolean>) {
            return { ...state, scoreCalculating: action.payload }
        },
        deleteScore(state, action: PayloadAction<string>) {
            return {
                ...state,
                deleteScoreLoading: false,
                deletingScore: '',
                scores: state.scores?.filter((s) => s.ID !== action.payload),
                currentScore:
                    state.currentScore?.ID !== action.payload ? state.currentScore : undefined,
            }
        },
        deleteScoreLoading(state) {
            return { ...state, deleteScoreLoading: true }
        },
        setDeletingScore(state, action: PayloadAction<string>) {
            return { ...state, deletingScore: action.payload }
        },
        setScoreSearchParams(state, action: PayloadAction<IScoreSearchForm>) {
            return { ...state, scoreSearchParams: action.payload }
        },
        setShowNewQAScore(state, action: PayloadAction<boolean>) {
            return { ...state, showNewScoreForm: action.payload }
        },
        getMyScores(state, action: PayloadAction<IScoreSearch[]>) {
            return {
                ...state,
                myScores: action.payload,
                myScoresLoading: false,
                myScoresError: false,
            }
        },
        myScoresLoading(state) {
            return { ...state, myScoresLoading: true, myScoresError: false }
        },
        myScoresError(state) {
            return { ...state, myScoresLoading: false, myScoresError: true }
        },
        getQATemplates(state, action: PayloadAction<IQATemplate[]>) {
            return { ...state, qaTemplates: action.payload }
        },
        createQATemplate(state) {
            return { ...state, qaTemplates: undefined }
        },
        editQATemplate(state) {
            return { ...state, qaTemplates: undefined }
        },
        deleteQATemplate(state) {
            return { ...state, qaTemplates: undefined }
        },
        getEvaluationConfigLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                evaluationConfig: {
                    evaluationConfigLoading: action.payload,
                    config: { channel: [], customerJourney: [] },
                    evaluationConfigError: false,
                },
            }
        },
        getEvaluationConfig(state, action: PayloadAction<EvaluationConfig>) {
            return {
                ...state,
                evaluationConfig: {
                    evaluationConfigLoading: false,
                    config: action.payload,
                    evaluationConfigError: false,
                },
            }
        },
        getEvaluationConfigError(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                evaluationConfig: {
                    evaluationConfigLoading: false,
                    config: { channel: [], customerJourney: [] },
                    evaluationConfigError: action.payload,
                },
            }
        },
        getUnreadScoresCount(state, action: PayloadAction<number>) {
            return {
                ...state,
                unreadScoresCount: action.payload,
            }
        },
        setUnreadScoresCount(state, action: PayloadAction<number>) {
            return {
                ...state,
                unreadScoresCount: action.payload,
            }
        },
    },
})

export const {
    searchScores,
    searchScoresLoading,
    searchScoresError,
    searchCalibrations,
    searchCalibrationsLoading,
    searchCalibrationsError,
    startCalibration,
    setCurrentlyCalibrating,
    changeCalibrationStatus,
    getFullCalibration,
    clearCalibration,
    calibrationBusy,
    setCurrentScore,
    getScoreLoading,
    clearScore,
    updateScore,
    updateScoreLoading,
    newScore,
    creatingScore,
    scoreCalculate,
    deleteScore,
    deleteScoreLoading,
    setDeletingScore,
    setScoreSearchParams,
    setShowNewQAScore,
    getMyScores,
    myScoresLoading,
    myScoresError,
    getQATemplates,
    createQATemplate,
    editQATemplate,
    deleteQATemplate,
    getEvaluationConfigLoading,
    getEvaluationConfig,
    getEvaluationConfigError,
    getUnreadScoresCount,
    setUnreadScoresCount,
} = qaSlice.actions

export type QAAction = ReturnType<(typeof qaSlice.actions)[keyof typeof qaSlice.actions]>

export default qaSlice.reducer

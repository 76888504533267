import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import { ReactComponent as Arrow } from '../../images/arrow-filled-grey.svg'
import styles from './styles.module.scss'

interface Props {
    isExpanded?: boolean
    onClick: () => void
    className?: string
    arrowClassName?: string
}

const ClickableLineWithArrow = ({
    onClick,
    isExpanded,
    className = '',
    arrowClassName = '',
}: Props) => {
    const [isArrowUp, setArrowOrientation] = useState(false)
    const [isControllable, setIsControllable] = useState(false)

    const arrowStyle = classnames(arrowClassName, {
        [styles['arrow--down']]: !isArrowUp,
    })

    useEffect(() => {
        // it means that the component is controlled from outside
        if (typeof isExpanded !== 'undefined') {
            setIsControllable(true)
            setArrowOrientation(isExpanded)
        }
    }, [isExpanded])

    const handleOnClick = () => {
        if (isControllable) {
            return onClick()
        }

        setArrowOrientation(!isArrowUp)
        onClick()
    }

    const handleKeyPress = (evt: React.KeyboardEvent) => {
        evt.key === 'Enter' && handleOnClick()
    }

    return (
        <div
            tabIndex={0}
            role="button"
            className={classnames(className, styles.wrapper)}
            onClick={handleOnClick}
            onKeyPress={handleKeyPress}
        >
            <Arrow className={arrowStyle} />
        </div>
    )
}

export default ClickableLineWithArrow

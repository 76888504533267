import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IDirectorySearchParams } from './state'

const initialState: IDirectorySearchParams = {}

export const directorySearchParamsSlice = createSlice({
    name: 'directorySearchParams',
    initialState,
    reducers: {
        setSearchParam(state, action: PayloadAction<Partial<IDirectorySearchParams>>) {
            return {
                ...state,
                ...action.payload,
            }
        },
    },
})

export const { setSearchParam } = directorySearchParamsSlice.actions

export type DirectorySearchParamsAction = ReturnType<
    (typeof directorySearchParamsSlice.actions)[keyof typeof directorySearchParamsSlice.actions]
>

export default directorySearchParamsSlice.reducer

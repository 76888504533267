import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
    IExternalDirectory,
    IExternalDirectoryRecord,
    IManageExternalDirectorySearchParams,
} from './state'

const initialState: IExternalDirectory = {
    contacts: [],
    isLoading: undefined,
    editContactLoading: false,
    deleteContactLoading: false,
    errors: [],
    manageExternalDirectorySearchParams: {},
}

export const externalDirectorySlice = createSlice({
    name: 'externalDirectory',
    initialState,
    reducers: {
        setExternalDirectory(state, action: PayloadAction<IExternalDirectoryRecord[]>) {
            return { ...state, contacts: action.payload }
        },
        externalDirectoryLoading(state, action: PayloadAction<boolean>) {
            return { ...state, isLoading: action.payload }
        },
        addExternalDirectoryItem(state, action: PayloadAction<IExternalDirectoryRecord>) {
            return {
                ...state,
                editContactLoading: false,
                contacts: [...state.contacts, action.payload],
            }
        },
        editExternalDirectoryItem(state, action: PayloadAction<IExternalDirectoryRecord>) {
            return {
                ...state,
                editContactLoading: false,
                contacts: state.contacts.map((contact) => {
                    return contact.id !== action.payload.id ? contact : action.payload
                }),
            }
        },
        editExternalDirectoryItemLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                editContactLoading: action.payload,
            }
        },
        deleteExternalDirectoryItem(state, action: PayloadAction<string>) {
            return {
                ...state,
                deleteContactLoading: false,
                contacts: state.contacts.filter((contact) => {
                    return contact.id !== action.payload
                }),
            }
        },
        deleteExternalDirectoryItemLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                deleteContactLoading: action.payload,
            }
        },
        setManageExternalDirectorySearchParams(
            state,
            action: PayloadAction<IManageExternalDirectorySearchParams>,
        ) {
            return {
                ...state,
                manageExternalDirectorySearchParams: {
                    ...state.manageExternalDirectorySearchParams,
                    ...action.payload,
                },
            }
        },
        externalDirectoryError(state, action: PayloadAction<string>) {
            return {
                ...state,
                errors: [...state.errors, action.payload],
            }
        },
    },
})

export const {
    setExternalDirectory,
    externalDirectoryLoading,
    addExternalDirectoryItem,
    editExternalDirectoryItem,
    editExternalDirectoryItemLoading,
    deleteExternalDirectoryItem,
    deleteExternalDirectoryItemLoading,
    setManageExternalDirectorySearchParams,
    externalDirectoryError,
} = externalDirectorySlice.actions

export type ExternalDirectoryAction = ReturnType<
    (typeof externalDirectorySlice.actions)[keyof typeof externalDirectorySlice.actions]
>

export default externalDirectorySlice.reducer

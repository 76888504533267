import { Button } from '@missionlabs/smartagent-app-components'
import React from 'react'
import { CSSTransition } from 'react-transition-group'
import { ReactComponent as DialButtonIcon } from '../../images/dial-button.svg'
import styles from './styles.module.scss'

interface Props {
    isDialButtonShown: boolean
    onClick: (evt: React.MouseEvent) => void
}

const DialButton = ({ isDialButtonShown, onClick }: Props) => (
    <CSSTransition
        in={isDialButtonShown}
        timeout={200}
        mountOnEnter
        unmountOnExit
        classNames={{
            enter: styles['dialButton--enter'],
            enterActive: styles['dialButton--enter-active'],
            enterDone: styles['dialButton--enter-done'],
            exitActive: styles['dialButton--exit-active'],
            exitDone: styles['dialButton--exit-done'],
        }}
    >
        <Button onClick={onClick} className={styles.dialButton}>
            <DialButtonIcon />
        </Button>
    </CSSTransition>
)

export default DialButton

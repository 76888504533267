import { _FormBuilder, Props, State } from '@missionlabs/smartagent-app-components/dist/FormBuilder'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'

interface ContactLogProps extends Props {
    contactID: string
}
interface ContactLogState extends State {
    contactID: string
}

class ContactLogFormBuilder extends _FormBuilder<ContactLogProps> {
    constructor(props: ContactLogProps) {
        super(props)
    }

    static getDerivedStateFromProps(newProps: ContactLogProps, current_state: ContactLogState) {
        const isEqualState = isEqual(current_state.values, newProps.defaultValues)
        const isNotEqualContact = current_state.contactID !== newProps.contactID
        const isCurrentStateEmpty = Object.values(current_state.values).every(
            (stateVal) => !stateVal || (Array.isArray(stateVal) && !stateVal.length),
        )
        if (isEmpty(newProps.defaultValues)) return null

        const values = { values: _FormBuilder.getInitialValuesFromProps(newProps) }

        if (!current_state.contactID) {
            return {
                contactID: newProps.contactID,
            }
        }
        if (!isEqualState) {
            if (isNotEqualContact) {
                return {
                    ...values,
                    contactID: newProps.contactID,
                }
            }
            if (isCurrentStateEmpty) {
                return values
            }
        }
        return null
    }
}

export default ContactLogFormBuilder

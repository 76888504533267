import { jsxs, jsx } from "react/jsx-runtime";
import { useState, useCallback, createElement } from "react";
import classNames from "classnames";
import { M as MarkdownWrapper } from "./MarkdownWrapper-BQ-Hbyxf.mjs";
const IconDown = "data:image/svg+xml,%3csvg%20width='14'%20height='8'%20viewBox='0%200%2014%208'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M2.5552%200.854776L6.97216%205.27125L11.4556%200.94228C11.8203%200.590054%2012.3975%200.596018%2012.7502%200.961249C13.1005%201.32395%2013.093%201.89944%2012.724%202.25574L7.64856%207.15702C7.431%207.36712%207.13785%207.44978%206.86184%207.40476C6.64186%207.39557%206.42394%207.30577%206.25318%207.13501L1.26407%202.1459C0.905532%201.78736%200.901422%201.21017%201.26044%200.851149C1.61698%200.494614%202.1925%200.492084%202.5552%200.854776Z'%20fill='%237E7E7E'/%3e%3c/svg%3e";
const IconUp = "data:image/svg+xml,%3csvg%20width='14'%20height='8'%20viewBox='0%200%2014%208'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M11.4448%207.14522L7.02784%202.72875L2.54441%207.05772C2.17967%207.40995%201.60249%207.40398%201.24979%207.03875C0.899534%206.67605%200.907048%206.10056%201.27601%205.74426L6.35144%200.842978C6.569%200.632878%206.86215%200.550223%207.13816%200.595242C7.35814%200.604429%207.57606%200.694232%207.74682%200.864986L12.7359%205.8541C13.0945%206.21264%2013.0986%206.78983%2012.7396%207.14885C12.383%207.50539%2011.8075%207.50792%2011.4448%207.14522Z'%20fill='%237E7E7E'/%3e%3c/svg%3e";
const thumbDown = "data:image/svg+xml,%3csvg%20width='17'%20height='15'%20viewBox='0%200%2017%2015'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M1.83561%209.68434C1.48839%209.68434%201.17589%209.54545%200.898112%209.26767C0.620334%208.98989%200.481445%208.67739%200.481445%208.33017V7.30934C0.481445%207.23989%200.48839%207.15989%200.502279%207.06934C0.516168%206.97934%200.537001%206.89267%200.564779%206.80934L2.85645%201.4135C2.96756%201.1635%203.15867%200.951558%203.42978%200.777669C3.70033%200.604336%203.98145%200.51767%204.27311%200.51767H12.4398V9.68434L7.21061%2014.851L6.83561%2014.476C6.76617%2014.4066%206.70367%2014.3093%206.64811%2014.1843C6.59256%2014.0593%206.56478%2013.9482%206.56478%2013.851V13.726L7.41894%209.68434H1.83561ZM13.2731%209.68434V0.51767H16.3148V9.68434H13.2731Z'%20fill='%233567F6'/%3e%3c/svg%3e";
const thumbUp = "data:image/svg+xml,%3csvg%20width='17'%20height='15'%20viewBox='0%200%2017%2015'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M12.5417%2014.851H4.375V5.68437L9.60417%200.5177L9.97917%200.8927C10.0486%200.962145%2010.1111%201.05937%2010.1667%201.18437C10.2222%201.30937%2010.25%201.42048%2010.25%201.5177V1.6427L9.39583%205.68437H14.9792C15.3264%205.68437%2015.6389%205.82326%2015.9167%206.10103C16.1944%206.37881%2016.3333%206.69131%2016.3333%207.03853V8.05937C16.3333%208.12881%2016.3264%208.20853%2016.3125%208.29853C16.2986%208.38909%2016.2778%208.47603%2016.25%208.55937L13.9583%2013.9552C13.8472%2014.2052%2013.6561%2014.4171%2013.385%2014.591C13.1144%2014.7644%2012.8333%2014.851%2012.5417%2014.851ZM3.54167%205.68437V14.851H0.5V5.68437H3.54167Z'%20fill='%233567F6'/%3e%3c/svg%3e";
const thumbUpWhite = "data:image/svg+xml,%3csvg%20width='17'%20height='15'%20viewBox='0%200%2017%2015'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M12.5417%2014.6667H4.375V5.50004L9.60417%200.333374L9.97917%200.708374C10.0486%200.777819%2010.1111%200.875041%2010.1667%201.00004C10.2222%201.12504%2010.25%201.23615%2010.25%201.33337V1.45837L9.39583%205.50004H14.9792C15.3264%205.50004%2015.6389%205.63893%2015.9167%205.91671C16.1944%206.19448%2016.3333%206.50698%2016.3333%206.85421V7.87504C16.3333%207.94449%2016.3264%208.02421%2016.3125%208.11421C16.2986%208.20476%2016.2778%208.29171%2016.25%208.37504L13.9583%2013.7709C13.8472%2014.0209%2013.6561%2014.2328%2013.385%2014.4067C13.1144%2014.58%2012.8333%2014.6667%2012.5417%2014.6667ZM3.54167%205.50004V14.6667H0.5V5.50004H3.54167Z'%20fill='white'/%3e%3c/svg%3e";
const thumbDownWhite = "data:image/svg+xml,%3csvg%20width='17'%20height='15'%20viewBox='0%200%2017%2015'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M1.83561%209.50001C1.48839%209.50001%201.17589%209.36112%200.898112%209.08334C0.620334%208.80557%200.481445%208.49307%200.481445%208.14584V7.12501C0.481445%207.05557%200.48839%206.97557%200.502279%206.88501C0.516168%206.79501%200.537001%206.70834%200.564779%206.62501L2.85645%201.22918C2.96756%200.979177%203.15867%200.767232%203.42978%200.593343C3.70033%200.42001%203.98145%200.333344%204.27311%200.333344H12.4398V9.50001L7.21061%2014.6667L6.83561%2014.2917C6.76617%2014.2222%206.70367%2014.125%206.64811%2014C6.59256%2013.875%206.56478%2013.7639%206.56478%2013.6667V13.5417L7.41894%209.50001H1.83561ZM13.2731%209.50001V0.333344H16.3148V9.50001H13.2731Z'%20fill='white'/%3e%3c/svg%3e";
const AccordionKB = ({ id, sendMessage, setQuickReplySent, updateContact, quickReplySent, feedback }) => {
  const [clicked, setClicked] = useState(null);
  const { positiveButton, negativeButton, text } = feedback;
  return /* @__PURE__ */ jsxs("div", { className: "sa-accordion-kb", children: [
    text.display && /* @__PURE__ */ jsx("p", { children: text.title }),
    positiveButton.display && /* @__PURE__ */ jsxs(
      "button",
      {
        disabled: quickReplySent,
        onClick: () => {
          updateContact == null ? void 0 : updateContact({ "sa-accordion-article-id": id }).then(() => {
            sendMessage(positiveButton.payload, positiveButton.title);
            setClicked("yes");
            setQuickReplySent();
          });
        },
        className: classNames("sa-accordion-kb-btn", {
          "sa-accordion-kb-btn-clicked": clicked === "yes"
        }),
        children: [
          /* @__PURE__ */ jsx("img", { src: clicked === "yes" ? thumbUpWhite : thumbUp, alt: "thumbs up" }),
          /* @__PURE__ */ jsx("span", { children: positiveButton.title })
        ]
      }
    ),
    negativeButton.display && /* @__PURE__ */ jsxs(
      "button",
      {
        disabled: quickReplySent,
        onClick: () => {
          updateContact == null ? void 0 : updateContact({ "sa-accordion-article-id": id }).then(() => {
            sendMessage(negativeButton.payload, negativeButton.title);
            setClicked("no");
            setQuickReplySent();
          });
        },
        className: classNames("sa-accordion-kb-btn", {
          "sa-accordion-kb-btn-clicked": clicked === "no"
        }),
        children: [
          /* @__PURE__ */ jsx("img", { src: clicked === "no" ? thumbDownWhite : thumbDown, alt: "thumbs down" }),
          /* @__PURE__ */ jsx("span", { children: negativeButton.title })
        ]
      }
    )
  ] });
};
const AccordionSection = ({ id, header, body, open, isKnowledgeBase, sendMessage, updateContact, quickReplySent, setQuickReplySent, onOpen, feedback }) => {
  function openOnEnter(e) {
    if (e.key === "Enter") {
      onOpen();
    }
  }
  return /* @__PURE__ */ jsxs("div", { className: "sa-accordion-section", children: [
    /* @__PURE__ */ jsxs("header", { onClick: onOpen, role: "button", tabIndex: 0, onKeyDown: openOnEnter, children: [
      header,
      /* @__PURE__ */ jsx("button", { tabIndex: -1, children: /* @__PURE__ */ jsx("img", { src: open ? IconUp : IconDown, alt: open ? "expanded" : "collapsed" }) })
    ] }),
    /* @__PURE__ */ jsxs("div", { className: classNames({
      "sa-accordion-section-body": true,
      "open": open
    }), tabIndex: 0, children: [
      /* @__PURE__ */ jsx(MarkdownWrapper, { children: body }),
      isKnowledgeBase && /* @__PURE__ */ jsx(
        AccordionKB,
        {
          id,
          sendMessage,
          updateContact,
          setQuickReplySent,
          quickReplySent,
          feedback
        }
      )
    ] })
  ] });
};
const Accordion = ({ ariaId, quickReply, sections, sendMessage, updateContact, isKnowledgeBase, feedback }) => {
  const [quickReplySent, setQuickReplySent] = useState(false);
  const [open, setOpen] = useState(null);
  const onOpen = useCallback(
    (i) => setOpen((open2) => i === open2 ? null : i),
    []
  );
  return /* @__PURE__ */ jsxs("div", { id: ariaId, className: "sa-accordion-wrapper", children: [
    /* @__PURE__ */ jsx("div", { className: "sa-accordion", children: sections.map((section, i) => /* @__PURE__ */ createElement(
      AccordionSection,
      {
        ...section,
        setQuickReplySent: () => setQuickReplySent(true),
        quickReplySent,
        sendMessage,
        updateContact,
        isKnowledgeBase,
        feedback,
        onOpen: () => onOpen(i),
        open: open === i,
        key: i
      }
    )) }),
    !!quickReply && /* @__PURE__ */ jsx(
      "button",
      {
        className: "sa-accordion-quick-reply",
        disabled: quickReplySent,
        onClick: () => {
          sendMessage(quickReply.payload, quickReply.title);
          setQuickReplySent(true);
        },
        children: quickReply.title
      }
    )
  ] });
};
export {
  Accordion as default
};

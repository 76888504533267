import { useSelector } from 'react-redux'

import { selectIsEnhancedMonitoringEnabled } from 'store/app/app.selectors'
import { selectMonitorInfo } from 'store/callV2/callV2.reducer'

import type RootState from 'store/state'

type Output = {
    isMonitoring: boolean
    isBarging: boolean
    isMonitoringOrBarging: boolean
    isEnhancedMonitoringEnabled: boolean
}

const getDefaultOutput = (isEnhancedMonitoringEnabled: boolean): Output => ({
    isMonitoring: false,
    isBarging: false,
    isMonitoringOrBarging: false,
    isEnhancedMonitoringEnabled,
})

export default function useMonitoring(): Output {
    const monitorInfo = useSelector(selectMonitorInfo)
    const legacyMonitorInfo = useSelector((state: RootState) => state.call?.monitorInfo)
    const isEnhancedMonitoringEnabled = useSelector(selectIsEnhancedMonitoringEnabled)

    const status = isEnhancedMonitoringEnabled ? monitorInfo?.status : legacyMonitorInfo?.status

    if (!status) return getDefaultOutput(isEnhancedMonitoringEnabled)

    const isMonitoring = status === 'SILENT_MONITOR'
    const isBarging = status === 'BARGE'

    const isMonitoringOrBarging = isMonitoring || isBarging

    return { isMonitoring, isBarging, isMonitoringOrBarging, isEnhancedMonitoringEnabled }
}

import { createSelector } from 'reselect'
import RootState from 'store/state'

const selectContactHistory = (state: RootState) => state.contactHistory

export const selectContactHistoryInstance = createSelector(
    selectContactHistory,
    (contactHistory) => {
        return contactHistory.find((item) => item.isSelected)
    },
)

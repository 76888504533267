import './task-widgets.scss'

import { EmailMessages, TaskAudit } from 'components'
import React, { useEffect } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import {
    fetchAttachmentUrls,
    fetchAutomatedRulesAudit,
    fetchQueuedTasksAudit,
    setViewPreviousEmailMessages,
} from 'store/contactHistory/contactHistory.actions'
import { selectContactHistoryInstance } from 'store/contactHistory/contactHistory.selectors'
import { IInteraction } from 'views/Metrics/HistoricMetrics/types'
import { attachmentsRequireTransformation } from 'views/Metrics/HistoricMetrics/utils'
import { ContactDetails } from 'widgets/ContactDetails'

interface Props {
    interaction: IInteraction
}

const TaskWidgets: React.FC<Props> = ({ interaction }) => {
    const dispatch = useDispatch()
    const { attributeSearchName, attributeSearchValue } =
        useSelector(selectContactHistoryInstance) ?? {}

    const {
        emailMessages,
        viewPreviousEmailMessages,
        messageId,
        automatedRulesAudit,
        queuedTasksAudit,
        hasAutoReply = false,
        attributes: { 'sa-threadID': threadId },
    } = interaction

    useEffect(() => {
        batch(() => {
            const attributesArePresent = attributeSearchName && attributeSearchValue
            if (!attributesArePresent) return

            if (!automatedRulesAudit) {
                dispatch(
                    fetchAutomatedRulesAudit(
                        attributeSearchName,
                        attributeSearchValue,
                        interaction,
                    ),
                )
            }
            if (!queuedTasksAudit) {
                dispatch(
                    fetchQueuedTasksAudit(attributeSearchName, attributeSearchValue, interaction),
                )
            }
        })
    }, [interaction])

    emailMessages?.forEach(({ attachments, messageId }) => {
        if (attachmentsRequireTransformation(attachments)) {
            if (attributeSearchName && attributeSearchValue) {
                dispatch(
                    fetchAttachmentUrls(
                        attributeSearchName,
                        attributeSearchValue,
                        attachments,
                        messageId,
                        threadId,
                    ),
                )
            }
        }
    })

    return (
        <div data-testid="task-widgets">
            <EmailMessages
                emailMessages={emailMessages}
                highlightedMessageId={messageId}
                viewPreviousEmailMessages={viewPreviousEmailMessages}
                setViewPreviousEmailMessages={() =>
                    messageId && dispatch(setViewPreviousEmailMessages({ messageId, value: true }))
                }
            />
            <div className="contact-history-task-widgets">
                <ContactDetails contact={interaction} widgetTitle="Additional info" />
                <TaskAudit
                    automatedRulesAudit={automatedRulesAudit}
                    queuedTasksAudit={queuedTasksAudit}
                    hasAutoReply={hasAutoReply}
                />
            </div>
        </div>
    )
}

export default TaskWidgets

import { ContactPhoneNumber } from 'components'
import { IExternalDirectoryRecord } from 'store/directory/externalDirectory/state'
import { constructJobInfo, constructName, isEmpty, isFunction, isNil } from 'utils'

import { ReactComponent as DirectoryIcon } from '../../images/directory-card-icon.svg'
import { ReactComponent as PriorityIcon } from '../../images/icon-arrow-up-red.svg'
import { ReactComponent as SensitivityIcon } from '../../images/icon-eye-outline.svg'
import { getFirstPhoneNumber } from '../ShortCardContainer/utils'
import styles from './styles.module.scss'

interface Props extends IExternalDirectoryRecord {
    isDialButtonShown?: boolean
    isFullProfileExpanded?: boolean
    onClick?: () => void
    onKeyPress?: () => void
}

const DirectoryShortCard = ({
    firstName,
    lastName,
    role,
    department,
    phoneNumbers = [],
    priority,
    isSensitive,
    isDialButtonShown,
    isFullProfileExpanded,
    onClick,
    onKeyPress,
}: Props) => (
    <>
        {(!isNil(firstName) || !isNil(lastName)) && (
            <div
                tabIndex={-1}
                role="button"
                className={styles.wrapper}
                onClick={isFunction(onClick) ? onClick : undefined}
                onKeyPress={isFunction(onKeyPress) ? onKeyPress : undefined}
            >
                <DirectoryIcon className={styles.directoryIcon} />

                <div className={styles.contactInfo}>
                    <div className={styles.contactName}>{constructName(firstName, lastName)}</div>

                    {priority && <PriorityIcon className={styles.priorityIcon} />}

                    {isSensitive && <SensitivityIcon className={styles.sensitivityIcon} />}

                    {(role || department) && (
                        <div className={styles.jobInfo}>
                            <span>{constructJobInfo(role, department)}</span>
                        </div>
                    )}

                    {phoneNumbers && !isEmpty(phoneNumbers) && (
                        <ContactPhoneNumber
                            phoneNumber={getFirstPhoneNumber(phoneNumbers)}
                            additionalNumbersAmount={phoneNumbers!.length - 1}
                            showMoreNumbersSign={!isFullProfileExpanded && phoneNumbers!.length > 1}
                            showButton={isDialButtonShown}
                        />
                    )}
                </div>
            </div>
        )}
    </>
)

export default DirectoryShortCard

import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import * as selectors from './callV2.selectors'
import type CallV2State from './callV2.state'
import {
    AddConnectionAction,
    CallConnection,
    InitialiseConnectionsAction,
    MonitorStatus,
    SendDTMFAction,
} from './callV2.state'
import {
    buildControlsMapping,
    createConnection,
    getInitiationMethod,
    getMonitorInfo,
    getTimestamp,
} from './callV2.utils'

const initialState: CallV2State = {
    connections: [],
    callStartTime: new Date().toISOString(),
    initiationMethod: 'unknown',
    monitorInfo: {},
}

export const callV2Slice = createSlice({
    name: 'callV2',
    initialState,
    reducers: {
        setConnections(state, action: PayloadAction<CallConnection[]>) {
            return { ...state, connections: action.payload }
        },
        initialiseConnections(_, action: PayloadAction<InitialiseConnectionsAction>) {
            const { contact, originalContact, originalAgent, handlers } = action.payload
            const { firstName, lastName } = originalAgent

            const connections = contact.getConnections() as connect.VoiceConnection[]
            const agentConnection = contact.getAgentConnection() as connect.VoiceConnection

            const agentUsername = `${firstName} ${lastName}`
            const controlsMapping = buildControlsMapping(connections, handlers)

            const newConnections = connections.map((connection, index) => {
                return createConnection(connection, controlsMapping[index], agentUsername)
            })

            return {
                connections: newConnections,
                callStartTime: getTimestamp(originalContact),
                monitorInfo: getMonitorInfo(agentConnection),
                initiationMethod: getInitiationMethod(originalContact.InitiationMethod),
            }
        },
        recoverCall(_, action: PayloadAction<CallV2State>) {
            return action.payload
        },
        setMonitorStatus(state, action: PayloadAction<MonitorStatus>) {
            return {
                ...state,
                monitorInfo: { ...state.monitorInfo, status: action.payload },
            }
        },
        addConnection(_state, _action: PayloadAction<AddConnectionAction>) {},
        endConnection(_state, _action: PayloadAction<string>) {},
        holdConnection(_state, _action: PayloadAction<string>) {},
        muteConnection(_state, _action: PayloadAction<string>) {},
        updateMonitorStatus(_state, _action: PayloadAction<MonitorStatus>) {},
        leaveCall() {},
        toggleConnections() {},
        conferenceConnections() {},
        resetCallState() {
            return initialState
        },
        sendDTMF(_state, _action: PayloadAction<SendDTMFAction>) {},
    },
    selectors,
})

export const {
    addConnection,
    conferenceConnections,
    endConnection,
    holdConnection,
    initialiseConnections,
    leaveCall,
    muteConnection,
    recoverCall,
    resetCallState,
    setConnections,
    setMonitorStatus,
    toggleConnections,
    updateMonitorStatus,
    sendDTMF,
} = callV2Slice.actions

export const {
    selectActiveConnections,
    selectAgentConnection,
    selectConnectedConnections,
    selectCustomerConnection,
    selectConnections,
    selectMonitorInfo,
    selectYouConnection,
    selectAreYouBeingBarged,
} = callV2Slice.selectors

export type CallV2Action = ReturnType<
    (typeof callV2Slice.actions)[keyof typeof callV2Slice.actions]
>

export default callV2Slice.reducer

import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client'
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev'
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'

if (process.env.NODE_ENV === 'development') {
    // Adds messages only in a dev environment
    loadDevMessages()
    loadErrorMessages()
}

interface Config {
    token: string
    apiKey: string // Non-sensitive API key, there is additional auth server side using user tokens
    url: string
    region: string
}

export default function createClient(config: Config) {
    const httpLink = createHttpLink({
        uri: config.url,
    })

    const awsOptions = {
        url: config.url,
        region: config.region,
        auth: {
            type: AUTH_TYPE.API_KEY,
            apiKey: config.apiKey,
        },
    } as const

    const awsLink = ApolloLink.from([
        createAuthLink(awsOptions),
        createSubscriptionHandshakeLink(awsOptions, httpLink),
    ])

    const middlewareLink = new ApolloLink((operation, forward) => {
        operation.setContext({
            headers: {
                'x-amz-security-token': config.token,
            },
        })
        return forward(operation)
    })

    return new ApolloClient({
        link: ApolloLink.from([middlewareLink, awsLink, httpLink]),
        cache: new InMemoryCache({
            dataIdFromObject: (object: any) => {
                switch (object.__typename) {
                    case 'UserConversation':
                        return object.conversationId
                    case 'onUpdateUserConversation':
                        return object.conversationId
                    default:
                        return object.id
                }
            },
        }),
    })
}

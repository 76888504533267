import type { Middleware } from '@reduxjs/toolkit'
import * as CallReducer from 'store/call/call.reducer'
import * as ChatReducer from 'store/chat/chat.reducer'
import { IChatMessage } from 'store/chat/chat.state'
import RootState from 'store/state'

import { isAnyAction } from 'utils'

let audioEl: HTMLMediaElement
let defaultAudioEl: HTMLMediaElement
let chatNewMessageAudio: HTMLMediaElement | undefined = undefined

export const audio: Middleware<{}, RootState> = (store) => (next) => (action) => {
    if (!isAnyAction(action)) return

    switch (action.type) {
        case CallReducer.callbackCall.type:
        case CallReducer.incomingCall.type: {
            const { settings, app } = store.getState()
            const { customerEndpointAddress } = action.payload.contact
            const ringingDevice = settings?.ringingDevice
            //ring on 'secondary' device if user picked one in settings
            if (ringingDevice && ringingDevice.deviceId !== 'default') {
                audioEl =
                    ringingDevice &&
                    new Audio(
                        'https://prod-cdn-12059162352.s3.eu-west-2.amazonaws.com/audio/ring.wav',
                    )
                //@ts-ignore setSinkId() is experimental so not in TS definitions
                audioEl.setSinkId(ringingDevice.deviceId)
                audioEl.loop = true
                audioEl.play()
            }

            if (app.callProvider === 'kumodi') {
                //For kumodi, the iframe itself doesn't 'ring ring' on the default device (connect does)
                //To keep behaviour consistent we need to 'ring ring' in our app on default device
                defaultAudioEl = new Audio(
                    'https://prod-cdn-12059162352.s3.eu-west-2.amazonaws.com/audio/ring.wav',
                )
                defaultAudioEl.loop = true
                defaultAudioEl.play()
            }

            return next(action)
        }
        case CallReducer.acceptCall.type:
        case CallReducer.rejectCall.type:
        case CallReducer.callEnded.type:
        case CallReducer.connectionConnecting.type:
        case CallReducer.connectionStarted.type:
            audioEl && audioEl.pause()
            defaultAudioEl && defaultAudioEl.pause()
            return next(action)
        case ChatReducer.receiveChatMessage.type:
            next(action)
            const messages: IChatMessage[] = Array.isArray(action.payload.msg)
                ? action.payload.msg
                : [action.payload.msg]
            const isParticipant = messages.some(
                (message) =>
                    message.ParticipantRole === 'CUSTOMER' || message.ParticipantRole === 'SYSTEM',
            )
            if (isParticipant && action.payload.id !== store.getState().contact?.ID) {
                if (!chatNewMessageAudio) {
                    chatNewMessageAudio = new Audio(
                        'https://prod-cdn-12059162352.s3.eu-west-2.amazonaws.com/audio/sa-chat-new-message.wav',
                    )
                }

                chatNewMessageAudio?.play()
            }
            return

        default:
            return next(action)
    }
}

export default audio

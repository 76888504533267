import { IExternalDirectoryRecord } from 'store/directory/externalDirectory/state'
import { IDirectoryRecordOwner } from 'store/directory/owners/state'
import { IDirectoryFormState } from 'views/Settings/DirectoryManagement/DirectoryItemForm/useDirectoryFormReducer'

import { makeRequest } from './'

// Converts a boolean value to binary. Required for hacked directory attributes.
const booleanToBinary = (value: boolean | 0 | 1) => (value ? 1 : 0)

export const getContacts = async (companyID: string, token: string) => {
    const response = await makeRequest<IExternalDirectoryRecord[]>({
        url: `directory-service/companies/${companyID}/directory/records`,
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

export const getContactById = async (companyID: string, token: string, itemID: string) => {
    const response = await makeRequest<IExternalDirectoryRecord>({
        url: `directory-service/companies/${companyID}/directory/record/${itemID}`,
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

export const postContact = async (companyID: string, token: string, data: IDirectoryFormState) => {
    data.isBlocked = booleanToBinary(data.isBlocked)
    data.isDeprioritised = booleanToBinary(data.isDeprioritised)

    const response = await makeRequest<IExternalDirectoryRecord>({
        url: `directory-service/companies/${companyID}/directory/records`,
        method: 'post',
        headers: {
            'X-Amz-Security-Token': token,
        },
        data,
    })

    return response
}

export const putContactById = async (
    companyID: string,
    token: string,
    id: string,
    data: IDirectoryFormState,
) => {
    data.isBlocked = booleanToBinary(data.isBlocked)
    data.isDeprioritised = booleanToBinary(data.isDeprioritised)

    const response = await makeRequest<IExternalDirectoryRecord>({
        url: `directory-service/companies/${companyID}/directory/record/${id}`,
        method: 'put',
        headers: {
            'X-Amz-Security-Token': token,
        },
        data,
    })

    return response
}

export const deleteContactById = async (companyID: string, token: string, itemID: string) => {
    const response = await makeRequest<void>({
        url: `directory-service/companies/${companyID}/directory/record/${itemID}`,
        method: 'delete',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

export const getDirectoryOwners = async (companyID: string, token: string) => {
    const response = await makeRequest<IDirectoryRecordOwner[]>({
        url: `directory-service/companies/${companyID}/directory/owners`,
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

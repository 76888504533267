import React, { useMemo } from 'react'

import { TimeElapsed } from '@missionlabs/smartagent-app-components'

import useCallV2 from 'hooks/redux/useCallV2'
import { getInitiationMethodIcon, getInitiationMethodText } from '../utils'
import styles from './call-summary.module.scss'

const CallSummary: React.FC = () => {
    const { initiationMethod, callStartTime } = useCallV2()

    const initiationMethodText = getInitiationMethodText(initiationMethod)

    const initiationMethodIcon = getInitiationMethodIcon(initiationMethod)

    const callStartDate = useMemo(() => {
        return new Date(callStartTime)
    }, [callStartTime])

    return (
        <div className={styles.container}>
            <div className={styles.details}>
                <img src={initiationMethodIcon} alt="" />
                <h3>{initiationMethodText || 'Call Direction: Unknown'}</h3>
            </div>
            <TimeElapsed date={callStartDate} />
        </div>
    )
}

export default CallSummary

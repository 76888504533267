import type { Channel } from '@missionlabs/smartagent-lib-shared/build/form-templates/form-template.schema'
import type { ISettings } from '@missionlabs/smartagent-lib-shared/build/settings'

import { AppFeatures } from 'hooks/useHasFeature'
import { IQueue } from 'store/settings/settings.state'
import RootState from 'store/state'
import { canUserUseFeature } from 'utils'

export const isAnEmail = (email: string) => /[^@]+@[^.]+\..+/.test(email)

export const getSettingsChannelFromPath = (path: string) => {
    switch (path) {
        case '/task':
            return 'TASK'
        case '/chat':
            return 'CHAT'
        default:
            return 'VOICE'
    }
}

export const getSettingsAppConfigForChannel = (
    appConfiguration?: ISettings['appConfigurations'],
    channel?: Channel,
) => {
    switch (channel) {
        case 'TASK':
            return appConfiguration?.task || appConfiguration
        case 'CHAT':
            return appConfiguration?.chat || appConfiguration
        default:
            return appConfiguration
    }
}

export const getRTMQueuesFromState = (state: RootState): [IQueue[] | undefined, boolean] => {
    const { settings, app, metrics, auth } = state

    const hasFeature = canUserUseFeature(app.features, auth.features ?? [])

    let queuesLoading = metrics?.queuesLoading || !settings?.queues
    let queues = settings?.queues

    if (hasFeature(AppFeatures.REALTIME_DATA_QUEUES_REDESIGNED)) {
        queues = settings?.enabledQueues
        queuesLoading = metrics?.queuesLoading || !settings?.enabledQueues
    }

    return [queues, queuesLoading]
}

import React from 'react'

import './bar-chart.scss'

interface ChartItem {
    color: string
    percent: number | undefined
    label: string
}

interface Props {
    data: ChartItem[]
    showKey?: boolean
}

const BarChart: React.FC<Props> = ({ data, showKey = false }: Props) => {
    if (!data) {
        return null
    }

    return (
        <div className="chart-container">
            <div className="bar-chart">
                {data.length &&
                    data.map((chartItem) => {
                        return (
                            <div
                                key={chartItem.label}
                                className={`bar-chart-item ${chartItem.color}`}
                                style={{ width: `${chartItem.percent}%` }}
                            ></div>
                        )
                    })}
            </div>

            <div className="chart-key">
                {showKey &&
                    data.map((keyItem) => {
                        return (
                            <div key={keyItem.label} className="chart-key-item">
                                <div className={`key-color ${keyItem.color}`}></div>
                                <div className="key-data">
                                    <div className="key-label">{keyItem.label}</div>
                                    <div className="key-percent">{keyItem.percent}%</div>
                                </div>
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}

export default BarChart

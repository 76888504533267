const parseToHTML = () => {
  const lineBreak = "<br />";
  const getLineBreak = () => lineBreak;
  return {
    getLineBreak,
    parse: getHTMLParser(lineBreak)
  };
};
const getHTMLParser = (lineBreak) => {
  return ({ title, details, options, buttons }) => [
    getHTMLTitle(lineBreak, title),
    getDetails(details),
    getHTMLList(options),
    getHTMLList(buttons)
  ].join("");
};
const getHTMLTitle = (lineBreak, title) => {
  return title ? `<b>${title}</b>${lineBreak}${lineBreak}` : "";
};
const getDetails = (details) => {
  return details ?? "";
};
const getHTMLList = (items) => {
  return items ? ['<ul style="margin-bottom:0">', ...items.map(getHTMLListItem), "</ul>"].join("") : "";
};
const getHTMLListItem = (item) => `<li>${item}</li><br />`;
const defaultTranslations = {
  detailsText: "Details",
  optionsText: "Options",
  buttonsText: "Buttons"
};
const parseToPlainText = ({
  translations = defaultTranslations
} = {}) => () => {
  const lineBreak = "\n";
  const getLineBreak = () => lineBreak;
  const parse = ({ title, details, options, buttons }) => `${title ? `${title}` : ""}${details ? `${lineBreak}${translations.detailsText}:${lineBreak}${details}${lineBreak}` : ""} ${options ? `${lineBreak}${translations.optionsText}:${lineBreak}${options.map((option, index) => `${index + 1}: ${option}`).join(lineBreak)}` : ""}${buttons ? `${lineBreak}${translations.buttonsText}:${lineBreak}${buttons.map((button, index) => `${index + 1} ${button}`).join(lineBreak)}` : ""}`;
  return {
    getLineBreak,
    parse
  };
};
const getCSSVar = (name) => {
  name = name.startsWith("--sa-") ? name : "--sa-" + name;
  return getComputedStyle(document.documentElement).getPropertyValue(name);
};
const parseChatMessage = (message, parser) => {
  const removeNewLine = (content) => content.replace(/\n/g, " ");
  const chosenParser = parser();
  const lineBreak = chosenParser.getLineBreak();
  try {
    const jsonParsed = typeof message === "string" ? JSON.parse(message) : message;
    const messageContent = jsonParsed[Object.keys(jsonParsed)[0]];
    let normalized = {};
    const { type } = messageContent;
    switch (type) {
      case "TEXT": {
        return messageContent.text;
      }
      case "TEXT_QUICKREPLIES": {
        const { quickReplies, text } = messageContent;
        const content = quickReplies.map(({ title }) => `${title}`);
        normalized = {
          title: text,
          options: content
        };
        break;
      }
      case "CARD": {
        const { cards } = messageContent;
        const getTitle = ({ title }) => `- ${title}`;
        const content = cards.map(({ title, buttons, summary }) => {
          return `${title ? `${title}${lineBreak}` : ""}${summary ? ` - ${removeNewLine(summary)}${lineBreak}` : ""}${buttons ? buttons.map(getTitle).join(lineBreak) : ""}`;
        });
        normalized = {
          options: content
        };
        break;
      }
      case "ACCEPT_MESSAGE": {
        const { title, summary } = messageContent;
        const parsedSummary = summary ? `${Array.isArray(summary) ? summary.map(removeNewLine).join(lineBreak) : removeNewLine(summary)}` : "";
        normalized = {
          title,
          details: parsedSummary
        };
        break;
      }
      case "ACCORDION": {
        const { sections } = messageContent;
        const content = sections.map(
          ({ header, body }) => `${header} - ${removeNewLine(body)}`
        );
        normalized = {
          options: content
        };
        break;
      }
      case "BIG_BUTTON": {
        const { buttons } = messageContent;
        const content = buttons.map(({ title }, index) => `${title}`);
        normalized = {
          options: content
        };
        break;
      }
      case "IFRAME": {
        const { title } = messageContent;
        normalized = {
          title: `${title}`
        };
        break;
      }
      case "DATE_PICKER": {
        const { title } = messageContent;
        normalized = {
          title: `${title}`
        };
        break;
      }
      case "HOLDING_STATUS": {
        const { text } = messageContent;
        normalized = {
          title: `${text}`
        };
        break;
      }
      case "RANGE_SLIDER": {
        const { buttons } = messageContent;
        const buttonContent = buttons.map(({ label }) => `${label}`);
        normalized = {
          buttons: buttonContent
        };
        break;
      }
      case "FILE_LINK": {
        const { label } = messageContent;
        normalized = {
          title: `Attachment: ${label}`
        };
        break;
      }
      case "TRANSLATION": {
        const { active, language } = messageContent;
        normalized = {
          title: getCustomerTranslationMessage(active, language)
        };
        break;
      }
      case "QUICK_REPLIES_MULTILINE":
      case "QUICK_REPLIES": {
        const { quickReplies } = messageContent;
        const content = quickReplies.map(({ title }) => `${title}`);
        normalized = {
          options: content
        };
        break;
      }
      case "STAR_RATING": {
        const { messages } = messageContent;
        const text = messages == null ? void 0 : messages.map(({ text: text2 }) => text2).join(lineBreak);
        normalized = {
          title: text ?? ""
        };
        break;
      }
      case "BATCH": {
        const { messages } = messageContent;
        const content = messages.map((item) => {
          const language = Object.keys(message)[0] ?? "en";
          const response = parseChatMessage(JSON.stringify({ [language]: item }), parser);
          return response;
        });
        return content;
      }
      case "WARNING_BANNER":
      case "INFORMATION_BANNER":
        break;
      default:
        console.log("Message type not found, returning original message: ", message);
        return message;
    }
    return chosenParser.parse(normalized);
  } catch (error) {
    console.log("Error", error);
    console.log("Unable to parse chat message: ", message);
    console.log("Returning original message");
    return message;
  }
};
const isJSONString = (str) => {
  if (typeof str !== "string")
    return false;
  try {
    const result = JSON.parse(str);
    const type = Object.prototype.toString.call(result);
    return type === "[object Object]" || type === "[object Array]";
  } catch (err) {
    return false;
  }
};
const translationMessages = {
  af: "Outomatiese vertaling na afrikaans begin",
  sq: "🇦🇱 Filloi përkthimi automatik në shqip",
  am: "ወደ አማርኛ በራስ ሰር መተርጎም ተጀመረ",
  ar: "بدأت الترجمة الآلية للعربية",
  hy: "Սկսվել է ավտոմատ թարգմանությունը հայերեն",
  az: "🇦🇿 azərbaycan dilinə avtomatik tərcümə başladı",
  bs: "🇧🇦 Počeo automatski prijevod na bosanski jezik",
  bg: "🇧🇬 Стартира автоматичен превод на български",
  ca: "S'ha iniciat la traducció automàtica al català",
  zh: "🇨🇳 开始自动翻译成中文",
  "zh-TW": "🇨🇳 開始自動翻譯成中文",
  hr: "🇭🇷 Započelo je automatsko prevođenje na hrvatski",
  cs: "🇨🇿 Automatický překlad do češtiny spuštěn",
  da: "🇩🇰 Automatisk oversættelse til dansk startet",
  nl: "🇳🇱 Automatische vertaling naar deens gestart",
  et: "🇪🇪 Automaattõlge eesti keelde alanud",
  fa: "ترجمه خودکار به فارسی شروع شد",
  tl: "🇵🇭 Nagsimula ang awtomatikong pagsasalin sa filipino",
  fi: "🇫🇮 Automaattinen käännös suomeen aloitettu",
  ka: "🇬🇪 ქართულად ავტომატური თარგმანი დაიწყო",
  el: "🇬🇷 Ξεκίνησε η αυτόματη μετάφραση στα ελληνικά",
  gu: "ગુજરાતીમાં આપોઆપ અનુવાદ શરૂ થયો",
  ht: "Tradiksyon otomatik an kreyòl ayisyen te kòmanse",
  ha: "An fara fassara ta atomatik zuwa Hausa",
  he: "התחיל תרגום אוטומטי לעברית",
  hi: "हिंदी में स्वचालित अनुवाद शुरू",
  hu: "🇭🇺 Elindult az automatikus magyar fordítás",
  is: "🇮🇸 Sjálfvirk þýðing á íslensku hafin",
  id: "🇮🇩 Terjemahan otomatis ke bahasa Indonesia dimulai",
  ga: "🇮🇪 Cuireadh tús le haistriúchán uathoibríoch go Gaeilge",
  ja: "🇯🇵 日本語への自動翻訳開始",
  kn: "ಕನ್ನಡಕ್ಕೆ ಸ್ವಯಂಚಾಲಿತ ಅನುವಾದ ಆರಂಭವಾಗಿದೆ",
  kk: "Қазақ тіліне автоматты аудару басталды",
  ko: "🇰🇷 한국어 자동 번역 시작",
  lv: "🇱🇻 Sākta automātiskā tulkošana latviešu valodā",
  lt: "🇱🇹 Pradėtas automatinis vertimas į lietuvių kalbą",
  mk: "🇲🇰 Започна автоматски превод на македонски",
  ms: "Terjemahan automatik ke bahasa Melayu bermula",
  ml: "മലയാളത്തിലേക്കുള്ള യാന്ത്രിക വിവർത്തനം ആരംഭിച്ചു",
  mt: "🇲🇹 Bdiet it-traduzzjoni awtomatika għall-Malti",
  mr: "मराठीत आपोआप अनुवाद सुरू झाला",
  mn: "Монгол хэл рүү автомат орчуулга хийж эхэллээ",
  no: "Automatisk oversettelse til norsk startet",
  ps: "پښتو ته اتومات ژباړه پیل شوه",
  pl: "🇵🇱 Rozpoczęto automatyczne tłumaczenie na polski",
  pt: "🇵🇹 A tradução automática para o português começou",
  "pt-PT": "🇵🇹 A tradução automática para o português começou",
  pa: "ਪੰਜਾਬੀ ਵਿੱਚ ਆਟੋਮੈਟਿਕ ਅਨੁਵਾਦ ਸ਼ੁਰੂ ਹੋ ਗਿਆ",
  ro: "🇷🇴 A început traducerea automată în română",
  ru: "🇷🇺 Запущен автоматический перевод на русский язык",
  sr: "🇷🇸 Почео аутоматски превод на српски",
  si: "ස්වයංක්‍රීය සිංහල පරිවර්තනය ආරම්භ විය",
  sk: "🇸🇰 Spustený automatický preklad do slovenčiny",
  sl: "🇸🇮 Začelo se je samodejno prevajanje v slovenščino",
  so: "Turjumaada tooska ah ee Soomaaliga ayaa bilaabatay",
  sw: "Tafsiri ya kiotomatiki kwa Kiswahili imeanza",
  ta: "தமிழில் தானியங்கி மொழிபெயர்ப்பு தொடங்கியது",
  te: "తెలుగులోకి స్వయంచాలక అనువాదం ప్రారంభమైంది",
  th: "🇹🇭 เริ่มการแปลอัตโนมัติเป็นภาษาไทย",
  tr: "🇹🇷 Türkçe'ye otomatik çeviri başladı",
  uk: "🇺🇦 Розпочато автоматичний переклад українською",
  ur: "اردو میں خودکار ترجمہ شروع ہوا۔",
  uz: "O'zbek tiliga avtomatik tarjima boshlandi",
  vi: "Đã bắt đầu dịch tự động sang tiếng Việt",
  cy: "🏴󠁧󠁢󠁷󠁬󠁳󠁿 Cychwyn cyfieithu awtomatig i'r Gymraeg",
  it: "🇮🇹 È iniziata la traduzione automatica in italiano",
  de: "🇩🇪 Automatische Übersetzung ins Deutsche gestartet",
  fr: "🇫🇷 La traduction automatique en français a commencé",
  es: "🇪🇸 Se inició la traducción automática al español",
  sv: "🇸🇪 Automatisk översättning till svenska påbörjad",
  en: "🇬🇧 Auto translate to english started"
};
const getCustomerTranslationMessage = (active, language) => {
  if (!active)
    return "Auto translation turned off";
  if (language === void 0 || language === null)
    return "Auto translate started";
  return translationMessages[language] ?? `Auto translate to ${language} started`;
};
const isJSONRepresentingStructure = (testStr, objectType) => {
  try {
    return compareJSONWithType(JSON.parse(testStr), objectType);
  } catch (e) {
    return false;
  }
};
const compareJSONWithType = (data, type) => {
  return type === "array" ? data instanceof Array : compareTypeofJSON(data, type);
};
const compareTypeofJSON = (data, type) => {
  return typeof data === type;
};
export {
  getCSSVar,
  getCustomerTranslationMessage,
  isJSONRepresentingStructure,
  isJSONString,
  parseChatMessage,
  parseToHTML,
  parseToPlainText
};

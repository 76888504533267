import { makeRequest } from 'services/api'
import {
    Announcement,
    AnnouncementReport,
    UserAnnouncement,
} from 'store/announcement/announcement.state'
import { IStrObj, paramsToQueryString } from 'utils'

export async function getMyUserAnnouncements(
    companyID: string,
    instanceID: string,
    token: string,
    filters?: IStrObj,
) {
    const response = await makeRequest<UserAnnouncement[]>({
        url: `/announcement-service/companies/${companyID}/instances/${instanceID}/user-announcements?${paramsToQueryString(filters)}`,
        headers: {
            'X-Amz-Security-Token': token,
        },
        method: 'get',
    })

    return response.data
}

export async function getAnnouncements(companyID: string, instanceID: string, token: string) {
    const response = await makeRequest<{ data: Announcement[] }>({
        url: `/announcement-service/companies/${companyID}/instances/${instanceID}/announcements`,
        headers: {
            'X-Amz-Security-Token': token,
        },
        method: 'get',
    })

    return response.data
}

export async function putAnnouncement(
    companyID: string,
    instanceID: string,
    announcement: Partial<Announcement>,
    token: string,
) {
    const response = await makeRequest<Announcement>({
        url: `/announcement-service/companies/${companyID}/instances/${instanceID}/announcements/${announcement.ID}`,
        headers: {
            'X-Amz-Security-Token': token,
        },
        data: announcement,
        method: 'put',
    })

    return response.data
}

export async function deleteAnnouncement(
    companyID: string,
    instanceID: string,
    announcementID: string,
    token: string,
) {
    await makeRequest<void>({
        url: `/announcement-service/companies/${companyID}/instances/${instanceID}/announcements/${announcementID}`,
        headers: {
            'X-Amz-Security-Token': token,
        },
        method: 'delete',
    })

    return true
}

export async function postAnnouncement(
    companyID: string,
    instanceID: string,
    announcement: Partial<Announcement>,
    token: string,
) {
    const response = await makeRequest<Announcement>({
        url: `/announcement-service/companies/${companyID}/instances/${instanceID}/announcements`,
        headers: {
            'X-Amz-Security-Token': token,
        },
        data: announcement,
        method: 'post',
    })

    return response.data
}

export async function getAnnouncementReport(
    companyID: string,
    instanceID: string,
    token: string,
    announcementID: string,
) {
    const response = await makeRequest<AnnouncementReport>({
        url: `/announcement-service/companies/${companyID}/instances/${instanceID}/announcements/${announcementID}/report`,
        headers: {
            'X-Amz-Security-Token': token,
        },
        method: 'get',
    })
    return response
}

export async function putUserAnnouncement(
    companyID: string,
    instanceID: string,
    ID: string,
    userAnnouncement: Partial<UserAnnouncement>,
    token: string,
) {
    const response = await makeRequest<UserAnnouncement>({
        url: `/announcement-service/companies/${companyID}/instances/${instanceID}/user-announcement/${ID}`,
        headers: {
            'X-Amz-Security-Token': token,
        },
        data: userAnnouncement,
        method: 'put',
    })

    return response.data
}

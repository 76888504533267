import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    fetchContactHistoryByChannel,
    fetchContactHistoryByCustomAttribute,
} from 'store/contactHistory/contactHistory.actions'
import {
    contactHistoryAttributeDisplayLabel,
    contactHistoryAttributeKey,
    contactHistoryAttributeValue,
} from 'store/contactHistory/contactHistory.constants'
import { selectContactHistoryInstance } from 'store/contactHistory/contactHistory.selectors'
import RootState from 'store/state'
import { getChannelType } from 'views/Metrics/HistoricMetrics/utils'

import { isChannelType } from '../../../utils'
import { constructDisplayName } from '../../utils'

import Tabs, { TabButtonInfo } from './Tabs'

import './tabs-container.scss'

const TabsContainer: React.FC<{}> = () => {
    const dispatch = useDispatch()
    const contact = useSelector((state: RootState) => state.contact)
    // get currently selected tab from the selected instance
    const { attributeSearchName, attributeSearchValue, displayLabel } =
        useSelector(selectContactHistoryInstance) ?? {}

    const currentlySelectedId = `${attributeSearchName}/${attributeSearchValue}`

    const {
        [contactHistoryAttributeKey]: attributeSearchNameFromContact,
        [contactHistoryAttributeValue]: attributeSearchValueFromContact,
        [contactHistoryAttributeDisplayLabel]: displayLabelFromContact,
    } = contact?.attributes ?? {}

    const handleTabClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault()
        if (!contact?.ID) {
            console.error('No contact ID found')
            return
        }

        const [newSearchName, newSearchValue] = e.currentTarget.id.split('/')

        const isChannel = isChannelType(newSearchName)

        if (isChannel) {
            dispatch(fetchContactHistoryByChannel(contact, newSearchName))
        } else {
            dispatch(fetchContactHistoryByCustomAttribute(newSearchName, newSearchValue))
        }
    }

    const generateTabs = (): TabButtonInfo[] => {
        const tabsToGenerate: TabButtonInfo[] = []

        const addTab = (id: string, text: string, shouldRender: boolean) => {
            if (shouldRender) {
                tabsToGenerate.push({ id, text, shouldRender })
            }
        }
        const channelType = getChannelType(contact)
        const contactTabId = contact ? `${channelType}/${contact.ID}` : ''
        const customerEndpointAddress =
            constructDisplayName(
                channelType,
                contact?.customerEndpointAddress,
                contact?.attributes,
            ) ?? 'Unknown endpoint'
        addTab(contactTabId, customerEndpointAddress, !!contact)

        if (attributeSearchName && !isChannelType(attributeSearchName) && attributeSearchValue) {
            addTab(
                `${attributeSearchName}/${attributeSearchValue}`,
                `${attributeSearchName}: ${displayLabel ?? attributeSearchValue}`,
                true,
            )
        }

        if (
            attributeSearchNameFromContact !== attributeSearchName &&
            attributeSearchValueFromContact !== attributeSearchValue
        ) {
            addTab(
                `${attributeSearchNameFromContact}/${attributeSearchValueFromContact}`,
                `${attributeSearchNameFromContact}: ${displayLabelFromContact ?? attributeSearchValueFromContact}`,
                !!(attributeSearchNameFromContact && attributeSearchValueFromContact),
            )
        }

        return tabsToGenerate
    }

    const tabsToGenerate = generateTabs()

    return (
        <div className="contact-history-tabs-container">
            {tabsToGenerate.length !== 0 && (
                <>
                    <span className="contact-history-tabs-container-text">Search by</span>
                    <Tabs
                        selectedTabId={currentlySelectedId}
                        tabsToGenerate={tabsToGenerate}
                        handleTabClick={handleTabClick}
                    />
                </>
            )}
        </div>
    )
}

export default TabsContainer

import '../historic-metrics.scss'

import {
    Box,
    Button,
    Checkbox,
    Dropdown,
    Form,
    Input,
    ReactDatePicker,
} from '@missionlabs/smartagent-app-components'
import AgentDropdown from 'components/Dropdowns/agents'
import QueueDropdown from 'components/Dropdowns/queues'
import useApp from 'hooks/redux/useApp'
import useContact from 'hooks/redux/useContact'
import useHasFeature, { AppFeatures, useCompanyHasFeature } from 'hooks/useHasFeature'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { ClipLoader } from 'react-spinners'
import { FeatureConfig } from 'store/app/app.features'
import {
    fetchInteractions,
    resetFormParams,
    setAgent,
    setAppContactId,
    setBargedOnly,
    setCallDurationEndTime,
    setCallDurationStartTime,
    setChannel,
    setChatEmail,
    setChatOrderNumber,
    setContactID,
    setCustomerPhone,
    setEmail,
    setEmailSearchType,
    setEndDate,
    setEndTime,
    setExternalID,
    setHoldDurationEndTime,
    setHoldDurationStartTime,
    setInitiationMethod,
    setKeyword,
    setQueue,
    setSocialHandle,
    setStartDate,
    setStartTime,
    setUniqueSocialID,
} from 'store/contactSearch/contactSearch.actions'
import {
    ContactChannel,
    EmailContactType,
    IContactSearchOptions,
    togglePlaceholderTypes,
} from 'store/contactSearch/contactSearch.state'
import {
    getContactSearchQueryString,
    optionsFactory,
} from 'store/contactSearch/contactSearch.utils'
import RootState from 'store/state'
import { isValidEmailAddress } from 'utils'

export interface Props {
    searching: boolean
}

// Time and Date do not have placeholders in HTML5. So
// this is a workaround to implement a placeholder functionality in them.
// The type switches between text and time/date for it to work.
const initialPlaceholder: togglePlaceholderTypes = { startDate: 'text' }
initialPlaceholder.callDurationFrom,
    initialPlaceholder.callDurationTo,
    initialPlaceholder.endDate,
    initialPlaceholder.startDate,
    initialPlaceholder.startTime,
    initialPlaceholder.endTime,
    initialPlaceholder.holdDurationFrom,
    (initialPlaceholder.holdDurationTo = 'text')

const ContactSearch: React.FC<Props> = ({ searching }) => {
    const appContact = useContact()
    const dispatch = useDispatch()
    const hasFeature = useHasFeature()
    const companyHasFeature = useCompanyHasFeature()

    const contactIDInput: React.RefObject<HTMLInputElement | null> | null | undefined = useRef(null)

    const hasAdvancedSearchParams = () => {
        return !!(
            contactID ||
            externalID ||
            phoneNumber ||
            callDurationStartTime ||
            callDurationEndTime ||
            holdDurationEndTime ||
            holdDurationStartTime ||
            phoneNumber ||
            initiationMethod ||
            bargedOnly
        )
    }

    const socialsSelected = () => {
        switch (channel.label) {
            case 'Instagram Comments':
            case 'Facebook Comments':
            case 'Twitter Tweets':
            case 'Instagram DM':
            case 'Twitter DM':
            case 'Facebook DM':
            case 'WhatsApp DM':
            case 'SMS DM':
                return true
            default:
                return false
        }
    }

    const appContactId = useSelector((state: RootState) => state.contactSearch?.appContactId)

    const formParams = useSelector((state: RootState) => state.contactSearch.formParams)
    let {
        contactID,
        initiationMethod,
        phoneNumber,
        externalID,
        callDurationStartTime,
        callDurationEndTime,
        holdDurationStartTime,
        holdDurationEndTime,
        bargedOnly,
    } = formParams
    const {
        agent,
        queue,
        startDate,
        startTime,
        endDate,
        endTime,
        channel,
        socialHandle,
        uniqueSocialId,
        email,
        emailSearchType,
        keyword,
    } = formParams

    const [typeDropdownValue, setTypeDropdownValue] = useState<string | undefined>(undefined)
    const [searchValue, setSearchValue] = useState<string>('')
    const [searchLabel, setSearchLabel] = useState<string>('')
    const [searchPlaceholder, setSearchPlaceholder] = useState<string>('')
    const [chatChannelSelected, setChatChannelSelected] = useState<boolean>(false)
    const [togglePlaceholder, setTogglePlaceholder] =
        useState<togglePlaceholderTypes>(initialPlaceholder)
    const [showAdvanced, setShowAdvanced] = useState<boolean>(hasAdvancedSearchParams())
    const [socialChannelSelected, setSocialChannelSelected] = useState<boolean>(socialsSelected)
    const [isEmailValid, setIsEmailValid] = useState<boolean>(
        channel.data === ContactChannel.EMAIL && isValidEmailAddress(email),
    )
    const [options, setOptions] = useState<IContactSearchOptions>({
        initiation: optionsFactory.initiation(),
        channel: optionsFactory.channel(),
        socialSearchType: optionsFactory.socialSearchType(),
        chatSearchType: optionsFactory.chatSearchType(),
        emailSearchType: optionsFactory.emailSearchType(),
    })

    const isWhatsAppChannelSelected = channel.label.includes('WhatsApp')
    const isSmsChannelSelected = channel.label.includes('SMS')
    const isWhatsAppOrSmsChannelSelected = isWhatsAppChannelSelected || isSmsChannelSelected

    const app = useApp()
    const hasRestrictQueueAccessFeature = !!app.features.find(
        (feature) => feature.ID === AppFeatures.RESTRICT_QUEUE_ACCESS,
    )

    const isSearchInvalid = useMemo(() => {
        return (
            searching ||
            !getContactSearchQueryString(formParams, hasRestrictQueueAccessFeature) ||
            !!(typeDropdownValue && !searchValue && !isWhatsAppOrSmsChannelSelected) ||
            (channel.data === ContactChannel.EMAIL &&
                email.length > 0 &&
                !isValidEmailAddress(email))
        )
    }, [formParams, typeDropdownValue, searchValue, searching])

    useEffect(() => {
        if (isSearchInvalid) return

        setTimeout(() => {
            contactIDInput?.current?.focus()
        }, 300)

        const getSocialChannelDropdownOption = (channel: string) => {
            return {
                label: channel,
                data: channel,
            }
        }

        setOptions((prevState) => {
            const newState = { ...prevState, channel: [...prevState.channel] }
            newState.channel.push(getSocialChannelDropdownOption('Facebook DM'))
            newState.channel.push(getSocialChannelDropdownOption('Instagram DM'))
            newState.channel.push(getSocialChannelDropdownOption('Facebook Comments'))
            newState.channel.push(getSocialChannelDropdownOption('Instagram Comments'))
            return newState
        })

        if (companyHasFeature(AppFeatures.WHATSAPP)) {
            setOptions((prevState) => ({
                ...prevState,
                channel: [...prevState.channel, getSocialChannelDropdownOption('WhatsApp DM')],
            }))
        }
        if (companyHasFeature(AppFeatures.SMS_MESSAGING)) {
            setOptions((prevState) => ({
                ...prevState,
                channel: [...prevState.channel, getSocialChannelDropdownOption('SMS DM')],
            }))
        }

        if (socialsSelected() && uniqueSocialId) {
            setTypeDropdownValue('Unique Social ID')
            setSearchLabel('Unique Social ID')
            setSearchValue(uniqueSocialId)
        } else if (socialsSelected() && socialHandle) {
            setTypeDropdownValue(isWhatsAppOrSmsChannelSelected ? 'Phone Number' : 'Social Handle')
            setSearchLabel(isWhatsAppOrSmsChannelSelected ? 'Phone Number' : 'Social Handle')
            setSearchValue(socialHandle)
        } else {
            setTypeDropdownValue(undefined)
            setSearchValue('')
        }

        onSubmit()
    }, [])

    useEffect(() => {
        switch (typeDropdownValue) {
            case 'Social Handle':
                dispatch(setSocialHandle(searchValue))
                break
            case 'Unique Social ID':
                dispatch(setUniqueSocialID(searchValue))
                break
            case 'Email':
                dispatch(setChatEmail(searchValue))
                break
            case 'Order Number':
                dispatch(setChatOrderNumber(searchValue))
                break
            default:
                resetSocialChatDropdownDispatch()
        }
        if (isWhatsAppOrSmsChannelSelected) dispatch(setSocialHandle(searchValue))
    }, [searchValue, searchLabel])

    useEffect(() => {
        if (channel.data === ContactChannel.EMAIL) {
            // switching into email channel - set default search type of All
            dispatch(
                setEmailSearchType({
                    data: EmailContactType['All'],
                    label: EmailContactType['All'],
                }),
            )
        } else {
            // switching away from email channel - clear email-related form parameters
            batch(() => {
                email && channel.data !== ContactChannel.WEBFORM && dispatch(setEmail(''))
                ;(emailSearchType.data || emailSearchType.label) &&
                    dispatch(setEmailSearchType({ data: '', label: '' }))
                keyword && dispatch(setKeyword(''))
            })
        }
    }, [channel])

    useEffect(() => {
        autoPopulateUniqueSocialIdOnWidgetLoad()
    }, [appContact?.ID])

    const autoPopulateUniqueSocialIdOnWidgetLoad = () => {
        if (!appContact) return
        const { attributes, ID } = appContact
        if (
            !attributes ||
            !attributes['sa-social-media-platform'] ||
            !attributes['sa-customer-endpoint-id'] ||
            appContactId === ID
        )
            return

        const uniqueSocialId = attributes['sa-customer-endpoint-id']
        setSocialChannelFromAttributes(attributes['sa-sub-channel'])
        setTypeDropdownValue('Unique Social ID')
        setSearchValue(uniqueSocialId)
        setSearchLabel('Unique Social ID')

        batch(() => {
            dispatch(fetchInteractions(`uniqueSocialId=${uniqueSocialId}`))
            dispatch(setAppContactId(ID))
        })
    }

    const setSocialChannelFromAttributes = (channel: string) => {
        setSocialChannelSelected(true)
        switch (channel) {
            case 'MESSENGER':
                dispatchChannelChange('Facebook DM')
                break
            case 'DM':
                dispatchChannelChange('Twitter DM')
                break
            case 'IG-DM':
                dispatchChannelChange('Instagram DM')
                break
            case 'POST':
                dispatchChannelChange('Facebook Comments')
                break
            case 'IG-COMMENT':
                dispatchChannelChange('Instagram Comments')
                break
            case 'TWEET':
                dispatchChannelChange('Twitter Tweets')
                break
            case 'WHATSAPP-DM':
                dispatchChannelChange('WhatsApp DM')
                break
            case 'SMS-DM':
                dispatchChannelChange('SMS DM')
                break
            default:
                setSocialChannelSelected(false)
        }
    }

    const dispatchChannelChange = (channel: string) =>
        dispatch(setChannel({ label: channel, data: channel }))

    const resetSocialChatDropdownDispatch = () => {
        batch(() => {
            dispatch(setUniqueSocialID(''))
            dispatch(setSocialHandle(''))
            dispatch(setChatEmail(''))
            dispatch(setChatOrderNumber(''))
        })
    }

    // TODO: Mars review this from merge
    // const socialSearchTypeOptions = useMemo(() => {
    //     return options.socialSearchType.map(option => {
    //         if (isWhatsAppChannelSelected && option.label === 'Social Handle') {
    //             return {
    //                 ...option,
    //                 label: 'Phone Number',
    //             };
    //         }
    //         return option;
    //     });
    // }, [isWhatsAppChannelSelected]);

    useEffect(() => {
        if (isWhatsAppOrSmsChannelSelected) {
            setSearchLabel('Phone Number')
            setTypeDropdownValue('Phone Number')
        }
    }, [isWhatsAppChannelSelected, isSmsChannelSelected])

    const onReset = () => dispatch(resetFormParams())

    const onSubmit = () =>
        dispatch(
            fetchInteractions(
                getContactSearchQueryString(formParams, hasRestrictQueueAccessFeature),
            ),
        )

    if (!showAdvanced) {
        contactID = ''
        externalID = ''
        phoneNumber = ''
        callDurationStartTime = ''
        callDurationEndTime = ''
        holdDurationStartTime = ''
        holdDurationEndTime = ''
        bargedOnly = false
        initiationMethod = undefined
    }

    const startDateObj = useMemo(() => (startDate ? new Date(startDate) : new Date()), [startDate])
    const endDateObj = useMemo(() => (endDate ? new Date(endDate) : new Date()), [endDate])

    const EmailInput = (
        <Input
            title="Email address"
            label="Email address"
            value={email}
            onChange={(payload) => dispatch(setEmail(payload))}
            onValidate={(value) => {
                const isValid = value.length > 0 ? isValidEmailAddress(value) : true
                setIsEmailValid(isValid)
                return !isValid ? 'Please enter a valid email address.' : ''
            }}
            showError
        />
    )

    return (
        <Box
            className="contact-search-box"
            alt
            boxLabel="Contact Search"
            header={<h1>Contact Search</h1>}
        >
            <Form className="contact-search-form" onReset={onReset} onSubmit={onSubmit}>
                <div className="row column">
                    <div className="row">
                        <ReactDatePicker
                            title="Select start date"
                            label="Start Date"
                            value={startDateObj}
                            handleChange={(date) => dispatch(setStartDate(date))}
                        />

                        <Input
                            title="Select start time"
                            label="Start Time"
                            value={startTime}
                            type={togglePlaceholder.startTime}
                            style={{ width: '96px' }}
                            onChange={(startTime) => dispatch(setStartTime(startTime))}
                            placeholder="00:00"
                            onFocus={() => {
                                setTogglePlaceholder((obj) => ({
                                    ...obj,
                                    startTime: 'time',
                                }))
                            }}
                            onBlur={() => {
                                setTogglePlaceholder((obj) => ({
                                    ...obj,
                                    startTime: 'text',
                                }))
                            }}
                        />

                        <ReactDatePicker
                            title="Select end date"
                            label="End Date"
                            value={endDateObj}
                            handleChange={(date) => dispatch(setEndDate(date))}
                        />

                        <Input
                            title="Select end time"
                            label="End Time"
                            type={togglePlaceholder.endTime}
                            value={endTime}
                            onChange={(endTime) => dispatch(setEndTime(endTime))}
                            placeholder="00:00"
                            style={{ width: '96px' }}
                            onFocus={() => {
                                setTogglePlaceholder((obj) => ({ ...obj, endTime: 'time' }))
                            }}
                            onBlur={() => {
                                setTogglePlaceholder((obj) => ({ ...obj, endTime: 'text' }))
                            }}
                        />

                        <QueueDropdown
                            blankOption
                            filter
                            selectedQueueID={queue?.ID}
                            onSelect={(queue) => dispatch(setQueue(queue))}
                        />

                        <AgentDropdown
                            blankOption
                            filter
                            selectedAgentID={agent?.ID}
                            onSelect={(agent) => dispatch(setAgent(agent))}
                        />
                    </div>
                    <div className="row bottom">
                        <Dropdown
                            title="Select channel"
                            filter
                            label="Channel"
                            value={channel.label}
                            options={options.channel}
                            onChange={(option) => {
                                switch (option.data) {
                                    case 'WhatsApp DM':
                                    case 'SMS DM':
                                    case 'Twitter DM':
                                    case 'Facebook DM':
                                    case 'Instagram DM':
                                    case 'Twitter Tweets':
                                    case 'Instagram Comments':
                                    case 'Facebook Comments':
                                        setSocialChannelSelected(true)
                                        setChatChannelSelected(false)
                                        break
                                    case 'CHAT':
                                        setChatChannelSelected(true)
                                        setSocialChannelSelected(false)
                                        break
                                    case 'WEBFORM':
                                        setChatChannelSelected(false)
                                        setSocialChannelSelected(false)
                                        break
                                    default:
                                        setChatChannelSelected(false)
                                        setSocialChannelSelected(false)
                                }
                                setSearchValue('')
                                setTypeDropdownValue(undefined)
                                return dispatch(setChannel(option))
                            }}
                        />
                        {(socialChannelSelected ||
                            (chatChannelSelected &&
                                hasFeature(AppFeatures.CHAT_CONTACT_SEARCH_OPTIONS))) &&
                            (isWhatsAppOrSmsChannelSelected ? (
                                <Input
                                    className="whatapp-search-input"
                                    label="Phone Number"
                                    placeholder="E.g. 447798765432"
                                    value={searchValue}
                                    onChange={setSearchValue}
                                />
                            ) : (
                                <>
                                    <Dropdown
                                        filter
                                        label="Search By"
                                        value={typeDropdownValue}
                                        options={
                                            chatChannelSelected
                                                ? options.chatSearchType
                                                : options.socialSearchType
                                        }
                                        onChange={(option) => {
                                            setSearchLabel(option.label)
                                            switch (option.label) {
                                                case 'Social Handle':
                                                    setSearchPlaceholder('E.g. @smartagent')
                                                    break
                                                case 'Phone Number':
                                                    setSearchPlaceholder('E.g. 447798765432')
                                                    break
                                                case 'Unique Social ID':
                                                    setSearchPlaceholder('E.g. 123 456 7890')
                                                    break
                                                case 'Email':
                                                    setSearchPlaceholder('E.g. user@smartagent.app')
                                                    break
                                                case 'Order Number':
                                                    setSearchPlaceholder('E.g. 123456789abc')
                                                    break
                                            }
                                            resetSocialChatDropdownDispatch()
                                            setTypeDropdownValue(option?.label)
                                        }}
                                    />
                                    {!typeDropdownValue && (
                                        <div className="contact-search-flex-fill" />
                                    )}
                                    {typeDropdownValue && (
                                        <Input
                                            label={searchLabel}
                                            placeholder={searchPlaceholder}
                                            value={searchValue}
                                            onChange={setSearchValue}
                                        />
                                    )}
                                </>
                            ))}
                        {channel.data === ContactChannel.WEBFORM && EmailInput && (
                            <>
                                {EmailInput}
                                <Input
                                    title="Keyword"
                                    label="Keyword"
                                    placeholder="Maximum of 30 characters"
                                    value={keyword ?? ''}
                                    onChange={(payload) => dispatch(setKeyword(payload))}
                                    showError
                                    maxLength={30}
                                />
                            </>
                        )}
                        {channel.data === ContactChannel.EMAIL && (
                            <>
                                <Dropdown
                                    title="Search by email type"
                                    filter
                                    label="Search By:"
                                    value={emailSearchType.label}
                                    options={options.emailSearchType}
                                    onChange={(option) => dispatch(setEmailSearchType(option))}
                                />
                                {EmailInput}
                                <Input
                                    title="Keyword"
                                    label="Keyword"
                                    placeholder="Maximum of 30 characters"
                                    value={keyword ?? ''}
                                    onChange={(payload) => dispatch(setKeyword(payload))}
                                    showError
                                    maxLength={30}
                                />
                            </>
                        )}

                        {showAdvanced && (
                            <>
                                <Input
                                    title="Enter contact ID"
                                    ref={contactIDInput}
                                    label="Contact ID"
                                    value={contactID}
                                    onChange={(contactID) => dispatch(setContactID(contactID))}
                                />

                                <Input
                                    title="Enter external ID"
                                    label="External ID"
                                    value={externalID}
                                    onChange={(externalID) => dispatch(setExternalID(externalID))}
                                />
                                <Input
                                    title="Enter customer phone number"
                                    label="Customer phone number"
                                    value={phoneNumber}
                                    onChange={(customerPhoneNumber) =>
                                        dispatch(setCustomerPhone(customerPhoneNumber))
                                    }
                                />

                                <Dropdown
                                    filter
                                    title="Select initiation Method"
                                    label="Initiation Method"
                                    value={initiationMethod?.label}
                                    options={options.initiation}
                                    onChange={(option) => dispatch(setInitiationMethod(option))}
                                />

                                <div className="double-input">
                                    <Input
                                        title="Select minimal call duration"
                                        label="Call Duration"
                                        value={callDurationStartTime}
                                        onChange={(callDurationStartTime) =>
                                            dispatch(
                                                setCallDurationStartTime(callDurationStartTime),
                                            )
                                        }
                                        type={togglePlaceholder.callDurationFrom}
                                        placeholder="From"
                                        step={30}
                                        onFocus={() => {
                                            setTogglePlaceholder((obj) => ({
                                                ...obj,
                                                callDurationFrom: 'time',
                                            }))
                                        }}
                                        onBlur={() => {
                                            setTogglePlaceholder((obj) => ({
                                                ...obj,
                                                callDurationFrom: callDurationStartTime
                                                    ? 'time'
                                                    : 'text',
                                            }))
                                        }}
                                    />
                                    <Input
                                        title="Select maximum call duration"
                                        label="‏‏‎ ‎"
                                        value={callDurationEndTime}
                                        onChange={(callDurationEndTime) =>
                                            dispatch(setCallDurationEndTime(callDurationEndTime))
                                        }
                                        type={togglePlaceholder.callDurationTo}
                                        placeholder="To"
                                        step={30}
                                        onFocus={() => {
                                            setTogglePlaceholder((obj) => ({
                                                ...obj,
                                                callDurationTo: 'time',
                                            }))
                                        }}
                                        onBlur={() => {
                                            setTogglePlaceholder((obj) => ({
                                                ...obj,
                                                callDurationTo: callDurationEndTime
                                                    ? 'time'
                                                    : 'text',
                                            }))
                                        }}
                                    />
                                </div>
                                <div className="double-input">
                                    <Input
                                        title="Select minimal hold duration"
                                        label="Hold Duration"
                                        value={holdDurationStartTime}
                                        onChange={(holdDurationStartTime) =>
                                            dispatch(
                                                setHoldDurationStartTime(holdDurationStartTime),
                                            )
                                        }
                                        type={togglePlaceholder.holdDurationFrom}
                                        placeholder="From"
                                        step={30}
                                        onFocus={() => {
                                            setTogglePlaceholder((obj) => ({
                                                ...obj,
                                                holdDurationFrom: 'time',
                                            }))
                                        }}
                                        onBlur={() => {
                                            setTogglePlaceholder((obj) => ({
                                                ...obj,
                                                holdDurationFrom: holdDurationStartTime
                                                    ? 'time'
                                                    : 'text',
                                            }))
                                        }}
                                    />
                                    <Input
                                        title="Select maximum hold duration"
                                        label="‏‏‎ ‎"
                                        value={holdDurationEndTime}
                                        placeholder="To"
                                        step={30}
                                        onChange={(holdDurationEndTime) =>
                                            dispatch(setHoldDurationEndTime(holdDurationEndTime))
                                        }
                                        type={togglePlaceholder.holdDurationTo}
                                        onFocus={() => {
                                            setTogglePlaceholder((obj) => ({
                                                ...obj,
                                                holdDurationTo: 'time',
                                            }))
                                        }}
                                        onBlur={() => {
                                            setTogglePlaceholder((obj) => ({
                                                ...obj,
                                                holdDurationTo: holdDurationEndTime
                                                    ? 'time'
                                                    : 'text',
                                            }))
                                        }}
                                    />
                                </div>

                                <Checkbox
                                    square
                                    checked={bargedOnly}
                                    label="Only show barged contacts"
                                    onChange={() => dispatch(setBargedOnly(!bargedOnly))}
                                />
                            </>
                        )}

                        <div className="contact-search-form-spacer" />

                        <div className=" contact-search-form-button">
                            <Button type="reset" title="Clear filters" styling="white">
                                Clear
                            </Button>

                            <Button
                                type="submit"
                                title="Search contacts"
                                disabled={isSearchInvalid}
                                styling="primary"
                            >
                                {searching ? (
                                    <div data-testid="contact-search-loading">
                                        <ClipLoader color="#fff" size={20} />
                                    </div>
                                ) : (
                                    'Search'
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
                <Checkbox
                    title="Use advanced search"
                    square
                    onChange={(checked) => {
                        setShowAdvanced(checked)
                        dispatch(
                            resetFormParams({
                                ...formParams,
                                contactID: '',
                                externalID: '',
                                phoneNumber: '',
                                callDurationStartTime: '',
                                callDurationEndTime: '',
                                holdDurationStartTime: '',
                                holdDurationEndTime: '',
                                bargedOnly: false,
                                initiationMethod: undefined,
                            }),
                        )
                    }}
                    checked={showAdvanced}
                    label="Advanced Search"
                />
            </Form>
        </Box>
    )
}

export default ContactSearch

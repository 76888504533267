import { useEffect, useState } from 'react'
import { mobileBreakpoint } from 'styles/breakpoints'

export default () => {
    const [isMobile, setIsMobile] = useState<boolean | undefined>(undefined)

    useEffect(() => {
        const mq = window.matchMedia(`(min-width: ${mobileBreakpoint})`)

        setIsMobile(!mq.matches)

        const callback = (event: MediaQueryListEvent) => {
            const result = event.matches

            setIsMobile(!result)
        }

        mq.addEventListener('change', callback)

        return () => {
            mq.removeEventListener('change', callback)
        }
    }, [])

    return [isMobile]
}

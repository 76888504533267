import Draft, { EditorState } from 'draft-js'
import useChat from 'hooks/redux/useChat'
import useChatConnections from 'hooks/redux/useChatConnections'
import { AppFeatures, useGetFeatureConfig } from 'hooks/useHasFeature'
import { markdownToDraft } from 'markdown-draft-js'
import React, { useCallback, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import {
    deleteAttachment,
    sendAttachment,
    setAttachment,
    setAttachmentLoading,
    updateConnectionEditorContent,
} from 'store/chat/chat.actions'
import { addError, addSuccess } from 'store/global/global.actions'

const ALLOWED_FILE_TYPES_DEFAULT = [
    'csv',
    'doc',
    'docx',
    'jpeg',
    'jpg',
    'pdf',
    'png',
    'ppt',
    'pptx',
    'txt',
    'wav',
    'xls',
    'xlsx',
    'deb',
]
const MAX_FILE_SIZE_MB_DEFAULT = 20
const MAX_NUMBER_OF_FILES_PER_MESSAGE_DEFAULT = 1

const useSendAttachment = (editorState: EditorState) => {
    const config = useGetFeatureConfig()(AppFeatures.CHAT)?.attachmentsConfig
    const { connections } = useChat()

    const dispatch = useDispatch()
    const currentChat = useChatConnections('selected')

    const inputRef = useRef<HTMLInputElement>(null)

    const numberOfFiles = useRef<{ [id: string]: number }>({})

    const addFiles = useCallback(
        (files: File[]) => {
            if (!config?.enabled) {
                return
            }
            if (!currentChat) return
            if (!numberOfFiles.current[currentChat.id]) {
                numberOfFiles.current[currentChat.id] = 0
            }

            const allowedFileTypes = (config?.acceptedFileTypes ?? ALLOWED_FILE_TYPES_DEFAULT).map(
                (fileType) => fileType.toLowerCase(),
            )
            const maxFileSizeMb = config?.maxFileSizeMB ?? MAX_FILE_SIZE_MB_DEFAULT
            const maxNumberOfFilesPerMessage =
                config?.maxNumberOfFilesPerMessage ?? MAX_NUMBER_OF_FILES_PER_MESSAGE_DEFAULT

            if (
                (files.length ?? 0) + numberOfFiles.current[currentChat.id] >
                maxNumberOfFilesPerMessage
            ) {
                console.log('fileNum err')
                return dispatch(
                    addError(
                        `You can only upload a maximum of ${maxNumberOfFilesPerMessage} file${maxNumberOfFilesPerMessage === 1 ? '' : 's'} per message`,
                    ),
                )
            }

            if (!files?.length) {
                console.log('noFiles err')
                return dispatch(addError(`You must upload at least one file`))
            }

            // validations
            if (
                !files.every((file) =>
                    allowedFileTypes.includes(file.name.split('.').pop()?.toLowerCase()!),
                )
            ) {
                console.log('filetype err')
                return dispatch(
                    addError(`Files must be one of the following: ${allowedFileTypes.join(', ')}`),
                )
            }

            if (!files.every((file) => file.size / 1024 / 1024 <= maxFileSizeMb)) {
                console.log('fileSize err')
                return dispatch(addError(`Files must be less than ${maxFileSizeMb}MB`))
            }

            if (files.length > maxNumberOfFilesPerMessage) {
                console.log('fileNum err')
                return dispatch(addError('You can only upload a maximum of two files per message'))
            }

            numberOfFiles.current[currentChat.id] = files.length

            dispatch(setAttachment({ id: currentChat.id, files }))
        },
        [dispatch, config, currentChat],
    )

    const dragEvents = useMemo(
        () => ({
            onDragEnter: (e: React.DragEvent) => {
                if (!config?.enabled) {
                    return
                }
                e.preventDefault()
                e.stopPropagation()
            },
            onDragOver: (e: React.DragEvent) => {
                if (!config?.enabled) {
                    return
                }
                e.preventDefault()
                e.stopPropagation()
            },
            onDrop: (e: React.DragEvent) => {
                if (!config?.enabled) {
                    return
                }
                e.preventDefault()
                e.stopPropagation()
                const files = Object.values(e.dataTransfer.files ?? {})
                addFiles(files)
            },
        }),
        [addFiles, currentChat],
    )

    const handleUpload = useCallback(
        ({ target }: React.ChangeEvent<HTMLInputElement>) => {
            if (!config?.enabled) {
                return
            }
            const files = Object.values(target.files ?? {})
            addFiles(files)
        },
        [addFiles, currentChat],
    )

    const deleteFile = useCallback(
        (i: number, fileName: string, currentContent: string, id: string) => {
            if (!config?.enabled) {
                return
            }
            if (!currentChat) return
            let newContent: string = ``

            if (numberOfFiles.current[id] === 1) {
                const rx = new RegExp(
                    `@SA_IMAGE\\\[([${escapeRegExp(fileName)}]+~[\\\w\\\\\\/]+~\\\w+(.*)+\\\])+`,
                    'gm',
                )
                const fileSpecificRegex = rx
                newContent = currentContent
                    .replace(fileSpecificRegex, '')
                    .replace(new RegExp('@SA_IMAGE', 'g'), '')
            } else if (numberOfFiles.current[id] === 2) {
                const regex = /@SA_IMAGE\[(.+?)\]/gm
                switch (i) {
                    case 0: {
                        newContent = currentContent.replace(regex, '@SA_IMAGE')
                        break
                    }
                    case 1:
                        const toKeep = currentContent.match(regex)
                        newContent = toKeep?.toString() || ''
                        break
                    default:
                        console.log('Too many files')
                        break
                }
            }
            const draft = markdownToDraft(newContent)
            const newEditorState = EditorState.createWithContent(Draft.convertFromRaw(draft))
            dispatch(deleteAttachment({ id, index: i }))
            dispatch(updateConnectionEditorContent({ id, content: newEditorState }))
            if (inputRef?.current) {
                inputRef.current.value = ''
            }
            numberOfFiles.current[id]--
        },
        [currentChat, addFiles, dispatch, config],
    )

    const uploadStagedAttachments = useCallback(
        (plainText: string) => {
            if (!config?.enabled) {
                return
            }
            if (!currentChat) return
            dispatch(setAttachmentLoading({ id: currentChat.id, state: 'loading' }))
            dispatch(
                sendAttachment({
                    id: currentChat.id,
                    stringMessage: plainText,
                }),
            )
            dispatch(addSuccess('File uploading...'))
            numberOfFiles.current[currentChat.id] = 0
        },
        [dispatch, currentChat],
    )

    return {
        inputRef,
        files: currentChat?.attachments.files,
        handleUpload,
        uploadStagedAttachments,
        deleteFile,
        dragEvents,
        attachmentEnabled: config?.enabled || false,
    }
}
const escapeRegExp = (s: string) => {
    return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
}

export default useSendAttachment

import { SocialChatJsonMessage } from '@missionlabs/smartagent-service-chat/dist/types/socialChatJsonMessage'
import React from 'react'
import { ISocialChatMessage } from 'store/chat/chat.state'
import { isSystemMessage } from 'store/chat/chat.utils'

interface Props {
    chatMessage: ISocialChatMessage
    previousChatMessage?: ISocialChatMessage | undefined
    customClassName?: string
}

const SenderNameGrouped: React.FC<Props> = ({
    chatMessage,
    previousChatMessage,
    customClassName,
}) => {
    if (
        isSystemMessage((chatMessage.content as SocialChatJsonMessage)?.type || chatMessage.Type) ||
        (!isSystemMessage(
            (previousChatMessage?.content as SocialChatJsonMessage)?.type ||
                previousChatMessage?.Type,
        ) &&
            chatMessage.ParticipantId === previousChatMessage?.ParticipantId)
    ) {
        return null
    }

    return (
        <div className={customClassName} data-testid="sender-name-grouped">
            {chatMessage.ParticipantRole === 'AGENT'
                ? 'Agent: '
                : chatMessage.ParticipantRole === 'CUSTOMER'
                  ? 'Customer: '
                  : ''}
            {chatMessage.DisplayName}
        </div>
    )
}

export default SenderNameGrouped

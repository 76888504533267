import type { Dispatch, Middleware, MiddlewareAPI } from '@reduxjs/toolkit'
import AnyAction from 'store/anyActions'
import { initAppSuccess } from 'store/app/app.reducer'
import { acceptCall, rejectCall } from 'store/call/call.actions'
import * as CallReducer from 'store/call/call.reducer'
import { acceptChat, declineChat } from 'store/chat/chat.actions'
import * as ChatReducer from 'store/chat/chat.reducer'
import RootState from 'store/state'
import { acceptTask, declineTask } from 'store/tasks/tasks.actions'
import * as TasksReducer from 'store/tasks/tasks.reducer'
import { isAnyAction } from 'utils'

const appChannel = new BroadcastChannel('smartagent-service-worker-out')

const findIncomingChat = (store: MiddlewareAPI<Dispatch<AnyAction>, RootState>) => {
    return store.getState().chat.connections.find((c) => c.status === 'connecting')?.id!
}

const findIncomingTask = (store: MiddlewareAPI<Dispatch<AnyAction>, RootState>) => {
    return store.getState().tasks.connections.find((t) => t.status === 'connecting')?.id!
}

const listenForMessages = async (store: MiddlewareAPI<Dispatch<AnyAction>, RootState>) => {
    await navigator.serviceWorker.ready

    appChannel.addEventListener('message', (event) => {
        switch (event.data.type) {
            case 'acceptCall':
                return store.dispatch(acceptCall())
            case 'rejectCall':
                return store.dispatch(rejectCall())
            case 'acceptChat':
                return store.dispatch(acceptChat(findIncomingChat(store)))
            case 'rejectChat':
                return store.dispatch(declineChat(findIncomingChat(store)))
            case 'acceptTask':
                return store.dispatch(acceptTask(findIncomingTask(store)))
            case 'rejectTask':
                return store.dispatch(declineTask(findIncomingTask(store)))
            default:
                return
        }
    })
}

const postMessageToSw = (type: string, data: any = {}) => {
    navigator.serviceWorker.ready.then((registration) => {
        registration.active?.postMessage({
            type,
            data,
        })
    })
}

const browserNotificationMiddleware: Middleware<{}, RootState> = (store) => (next) => {
    window.addEventListener('focus', () => {
        postMessageToSw('windowFocus', {})
    })

    return (action) => {
        if (!isAnyAction(action)) return

        // init listener
        if (action.type === initAppSuccess.type) {
            listenForMessages(store)
        }

        // Missed contact notifications
        switch (action.type) {
            case CallReducer.missedCall.type:
                postMessageToSw('missedContact', {
                    contactType: 'call',
                })
                break

            case ChatReducer.chatMissed.type:
                postMessageToSw('missedContact', {
                    contactType: 'chat',
                })
                break

            case TasksReducer.taskMissed.type:
                postMessageToSw('missedContact', {
                    contactType: 'email',
                })
                break
        }

        // incoming contact notifications
        switch (action.type) {
            case CallReducer.incomingCall.type:
                postMessageToSw('incomingCall', {
                    number: action.payload.number,
                    autoAcceptOn: !!store.getState().user?.softphoneAutoAccept,
                })
                break
            case ChatReducer.addChatConnection.type:
                postMessageToSw('incomingChat', {
                    customerName: action.payload.customerName,
                })
                break
            case TasksReducer.addTaskConnection.type:
                postMessageToSw('incomingTask', {
                    customerName: action.payload.name,
                })
                break

            case CallReducer.missedCall.type:
            case CallReducer.acceptCall.type:
            case CallReducer.rejectCall.type:
            case CallReducer.callStarted.type:
            case ChatReducer.chatMissed.type:
            case ChatReducer.acceptChat.type:
            case ChatReducer.declineChat.type:
            case TasksReducer.taskMissed.type:
            case TasksReducer.acceptTask.type:
            case TasksReducer.declineTask.type:
                postMessageToSw('contactOver')
                break

            default:
                break
        }

        return next(action)
    }
}

export default browserNotificationMiddleware

import { Checkbox } from '@missionlabs/smartagent-app-components'
import React from 'react'
import './checkbox-list.scss'

export interface Option {
    label: string
    checked?: boolean
    value: any
}

interface Props {
    onChange: (value: any, checked: boolean) => void
    options: Option[]
}

const CheckboxList: React.FC<Props> = (props) => {
    return (
        <div className="sa-checkbox-list">
            {props.options.map((option, i) => {
                return (
                    <div className="sm-mar" key={`option-${i}`} aria-label={option.label}>
                        <Checkbox
                            square
                            checked={option.checked}
                            label={option.label}
                            onChange={(checked) => props.onChange(option, checked)}
                        />
                    </div>
                )
            })}
        </div>
    )
}

export default CheckboxList

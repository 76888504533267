import { makeRequest } from 'services/api'
import { CallbackQueue, UpdateCallbackQueueBody } from 'views/Settings/Queues/types'

type ApiMethods = 'get' | 'put' | 'post' | 'delete' | 'patch'

const makeRequestWrapper = (url: string, token: string, method: ApiMethods, data?: any) => {
    return makeRequest({
        url,
        method,
        headers: {
            'X-Amz-Security-Token': token,
        },
        data,
    })
}

export async function getCallBackQueues(
    token: string,
    companyID: string,
    instanceID: string,
): Promise<CallbackQueue[]> {
    const callbackQueuesUrl = `sa-connect-api/companies/${companyID}/instances/${instanceID}/synchronized-queues`
    const { data } = await makeRequestWrapper(callbackQueuesUrl, token, 'get')
    return data
}
export async function updateCallBackQueue(
    token: string,
    companyID: string,
    instanceID: string,
    queueID: string,
    updateCallbackQueueBody: UpdateCallbackQueueBody,
): Promise<CallbackQueue> {
    const callbackQueuesUrl = `sa-connect-api/companies/${companyID}/instances/${instanceID}/synchronized-queues/${queueID}`
    const body = updateCallbackQueueBody
    const { data } = await makeRequestWrapper(callbackQueuesUrl, token, 'patch', body)
    return data
}

import { makeRequest } from 'services/api'
import { SaveQuickReplyRequest } from '../../views/AdminSettings/QuickReplyManagement/components/ManageQuickReplySlideout'

export async function getQuickRepliesWithHierarchy(
    companyID: string,
    instanceID: string,
    channels?: string,
    facia?: string,
) {
    return await makeRequest({
        url: `/quick-reply-service/companies/${companyID}/instances/${instanceID}/messages`,
        method: 'get',
        params: { channels, facia },
    })
}

export async function getQuickReplies(
    companyID: string,
    instanceID: string,
    facia?: string,
    language?: string,
) {
    return await makeRequest({
        url: `/quick-reply-service/companies/${companyID}/instances/${instanceID}/manage/messages`,
        method: 'get',
        params: { facia, language },
    })
}

export async function postQuickReply(
    quickReply: SaveQuickReplyRequest,
    companyID: string,
    instanceID: string,
) {
    return await makeRequest({
        url: `/quick-reply-service/companies/${companyID}/instances/${instanceID}/manage/messages`,
        method: 'post',
        data: quickReply,
    })
}

export async function getHierarchy(
    companyID: string,
    instanceID: string,
    facia?: string,
    language?: string,
) {
    return await makeRequest({
        url: `/quick-reply-service/companies/${companyID}/instances/${instanceID}/manage/hierarchy`,
        method: 'get',
        params: { facia, language },
    })
}

export async function deleteQuickReply(
    messageID: string,
    companyID: string,
    instanceID: string,
    facia?: string,
) {
    return await makeRequest({
        url: `/quick-reply-service/companies/${companyID}/instances/${instanceID}/manage/messages/${messageID}`,
        method: 'delete',
        params: { facia },
    })
}

export async function putQuickReply(
    quickReply: SaveQuickReplyRequest,
    messageID: string,
    companyID: string,
    instanceID: string,
) {
    return await makeRequest({
        url: `/quick-reply-service/companies/${companyID}/instances/${instanceID}/manage/messages/${messageID}`,
        method: 'put',
        data: quickReply,
    })
}

export async function patchHierarchy(companyId: string, instanceId: string, data: any) {
    // TODO add patch request type once its created in service-quick-replies
    return await makeRequest({
        url: `/quick-reply-service/companies/${companyId}/instances/${instanceId}/manage/hierarchy`,
        method: 'patch',
        data,
    })
}

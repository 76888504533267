import gql from 'graphql-tag'

export default gql`
    mutation createConversation($conversation: conversationInput) {
        createConversation(conversation: $conversation) {
            __typename
            id
            recipientList
        }
    }
`

export interface CreateConversationResponse {
    __typename: string
    createConversation: {
        id: string
        __typename: string
    }
}

export const mutation = (recipientList: string[]) => ({
    variables: {
        conversation: {
            recipientList,
        },
    },
    optimisticResponse: {
        __typename: 'Mutation',
        createConversation: {
            __typename: 'Conversation',
            id: '-1',
        },
    },
})

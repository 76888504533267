import React from 'react'
type Props = {
    value: number
    max: number
    marginRight: number
    width: number
    id: number
}

export const Blip: React.FC<Props> = ({ value, max, marginRight, width, id }) => {
    const getStyle = (): React.CSSProperties => {
        let height = (Math.abs(value) / max) * 100
        height = height > 90 ? 90 : height

        return {
            marginRight,
            width,
            height: height + '%',
            display: 'inline-block',
            verticalAlign: 'middle',
            backgroundColor: id === 1 ? '#4B647F' : '#3B83BF',
        }
    }

    return <span className="blip" style={getStyle()} />
}

import './index.scss'

import { formatDateTime } from '@missionlabs/smartagent-app-components'
import { isSameDay, isToday } from 'date-fns'
import CREATE_MESSAGE, {
    CreateMessageResponse,
    mutation as createMessageMutation,
} from 'graphql/mutations/createMessage'
import READ_USER_CONVERSATION, {
    mututation as readUserConversationMutation,
} from 'graphql/mutations/readUserConversation'
import LIST_MESSAGES, {
    ListMessagesResponse,
    fetchMore as fetchMoreMessages,
} from 'graphql/queries/listMessages'
import { OnNewMessageResponse, subscription } from 'graphql/subscriptions/onNewMessage'
import { Message as GQLMessage } from 'graphql/types'
import BackIcon from 'images/icon-arrow-left.svg'
import SendIcon from 'images/send-message.svg'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch, useStore } from 'react-redux'
import { newMessageNotification } from 'store/internal-messaging/internal-messaging.actions'
import { convertTime } from 'utils'

import { useMutation, useQuery } from '@apollo/client'
import { Button, Message } from '@missionlabs/smartagent-app-components'

import { addError } from '../../store/global/global.actions'

interface Props {
    conversationId: string
    onBack: () => void
    conversationName: string
    conversationUnread: number
}

const ChatWindow: React.FC<Props> = React.memo(({ conversationId, onBack, conversationName }) => {
    const [message, setMessage] = useState('')
    const [disabledButton, setDisabledButton] = useState(false)
    const [blockSroll, setBlockScroll] = useState(false)
    const [preFetchScrollHeight, setPreFetchScrollHeight] = useState<number | undefined>(undefined)

    const messagesEnd = useRef<HTMLDivElement>(null)
    const textAreaRef = useRef<HTMLTextAreaElement>(null)
    const messagesDiv = useRef<HTMLDivElement>(null)

    const dispatch = useDispatch()

    const username = useStore().getState().user.username

    const {
        data: messages,
        subscribeToMore,
        fetchMore,
    } = useQuery<ListMessagesResponse>(LIST_MESSAGES, {
        variables: { conversationId },
        fetchPolicy: 'network-only',
    })

    useEffect(() => {
        const unsubscribe = subscribeToMore<OnNewMessageResponse>(
            subscription(conversationId, (message: GQLMessage) => {
                dispatch(
                    newMessageNotification({
                        content: message.content,
                        user: message.user,
                        conversationId: message.conversationId,
                    }),
                )
            }),
        )
        return () => unsubscribe()
        // eslint-disable-next-line
    }, [subscribeToMore, conversationId])

    const [createMessage] = useMutation<CreateMessageResponse>(CREATE_MESSAGE)

    const [readUserConversation] = useMutation(READ_USER_CONVERSATION)

    useEffect(() => {
        if (messages?.listMessages?.items.length)
            readUserConversation(readUserConversationMutation(username, conversationId))
        // eslint-disable-next-line
    }, [messages?.listMessages?.items.length])

    useEffect(() => {
        const { current } = textAreaRef
        if (!current) return
        if (!message) {
            current.style.height = ''
        } else {
            current.style.height = current.scrollHeight + 'px'
        }
    }, [message])

    const onMessageChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(e.target.value)
    }

    const sendMessage = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setDisabledButton(true)
        await onCreateMessage(conversationId, message)
        setMessage('')
        setDisabledButton(false)
    }

    const onCreateMessage = async (conversationId: string, content: string) => {
        const createMessageInput = {
            conversationId,
            content,
        }

        try {
            const messageResult = await createMessage(
                createMessageMutation(createMessageInput, username),
            )
            if (!messageResult || !messageResult.data) {
                dispatch(addError('Unfortunately the message was not sent. Please try again.'))
                return
            }

            return messageResult.data?.createMessage
        } catch (error) {
            console.log('Error creating message:', error)
            dispatch(addError('Unfortunately the message was not sent. Please try again.'))
        }
    }

    const handleScroll = (ev: any) => {
        if (ev.nativeEvent.target.scrollTop === 0 && messages?.listMessages.nextToken) {
            setPreFetchScrollHeight(messagesDiv.current?.scrollHeight)

            setBlockScroll(true)
            fetchMore(fetchMoreMessages(conversationId, messages))
            setTimeout(() => {
                setBlockScroll(false)
            }, 500)
        }
    }

    useEffect(() => {
        if (!blockSroll) {
            messagesEnd.current?.scrollIntoView({ behavior: 'auto' })
        }
        if (preFetchScrollHeight) {
            messagesDiv.current?.scrollTo(
                0,
                messagesDiv.current?.scrollHeight - preFetchScrollHeight,
            )
            setPreFetchScrollHeight(undefined)
        }
        // eslint-disable-next-line
    }, [messages?.listMessages?.items])
    return (
        <div className="chat-window">
            <div className="chat-window-top">
                <Button onClick={onBack}>
                    <div className="chat-window-header">
                        <img src={BackIcon} alt="Back" title="Back" width="4%" />
                        <span className="chat-window-header-name">{conversationName}</span>
                    </div>
                </Button>
            </div>
            <div className="chat-window-messages" ref={messagesDiv} onScroll={handleScroll}>
                {messages?.listMessages?.items
                    .toSorted((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
                    .map((msg, i, arr) => (
                        <Fragment key={msg.id}>
                            {!isToday(msg.timestamp) &&
                            !isSameDay(msg.timestamp, arr[i - 1]?.timestamp ?? 0) ? (
                                <div className="chat-window-messages-day">
                                    {formatDateTime(msg.timestamp, {
                                        shortMonth: true,
                                        date: false,
                                    }).toUpperCase()}
                                </div>
                            ) : null}
                            {isToday(msg.timestamp) &&
                            !isSameDay(msg.timestamp, arr[i - 1]?.timestamp ?? 0) &&
                            i > 0 ? (
                                <div className="chat-window-messages-day">TODAY</div>
                            ) : null}
                            <div className="chat-window-messages-bubble">
                                <Message
                                    time={convertTime(msg.timestamp)}
                                    type={msg.user === username ? 'local' : 'remote'}
                                >
                                    {msg.content}
                                </Message>
                            </div>
                        </Fragment>
                    ))}
                <div ref={messagesEnd} />
            </div>
            <form className="chat-window-input" onSubmit={sendMessage}>
                <div className="chat-window-input-textarea">
                    <textarea
                        value={message}
                        onChange={onMessageChanged}
                        ref={textAreaRef}
                        placeholder="Send a message..."
                    />
                </div>
                <div className="chat-window-input-button-div">
                    <Button disabled={!message || disabledButton} type="submit">
                        <img src={SendIcon} alt="Send" title="Send" />
                    </Button>
                </div>
            </form>
        </div>
    )
})

export default ChatWindow

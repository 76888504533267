import classnames from 'classnames'
import { ClickableLineWithArrow } from 'components'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { IDirectoryRecord } from 'store/directory/directory.state'
import { isFunction, isNilOrEmpty } from 'utils'
import useDialButton from '../../hooks/useDialButton'
import FullProfileInfo, { ContactDataItem } from '../FullProfileInfo'
import styles from './styles.module.scss'

interface Props {
    openedCardId?: string | null
    ShortCard: React.FunctionComponent<any>
    contact: IDirectoryRecord
    mapperFn: (contact: IDirectoryRecord) => ContactDataItem[]
    onClick?: (id: string) => void
    measure?: () => void
}

const ShortCardContainer = ({
    openedCardId,
    ShortCard,
    contact,
    mapperFn,
    onClick,
    measure,
}: Props) => {
    const cardId = contact.id
    const [height, setHeight] = useState(0)
    const element = useRef<HTMLDivElement>(null)
    const [isExpanded, setIsExpanded] = useState(false)
    const expandableAreaClassName = classnames(styles.expandedArea, {
        [styles['expandedArea--expanded']]: isExpanded,
    })

    const getHeight = () => {
        if (isExpanded) {
            return height
        }

        return 0
    }

    useEffect(() => {
        if (element && element.current) {
            setHeight(element.current.getBoundingClientRect().height)
        }
    }, [])

    useEffect(() => {
        if (height > 0) {
            measure && measure()
        }
    }, [height])

    useEffect(() => {
        if (openedCardId && openedCardId === cardId) {
            return setIsExpanded(true)
        }

        return setIsExpanded(false)
    }, [openedCardId])

    const fullProfileData = useMemo(() => mapperFn(contact), [contact])

    const hasNoValuesForFullProfile = useMemo(
        () => fullProfileData.every((item) => isNilOrEmpty(item.fieldValue)),
        [fullProfileData],
    )

    const handleOnClick = () => {
        !hasNoValuesForFullProfile && isFunction(onClick) && onClick?.(cardId)
    }

    const { isDialButtonShown, showDialButton, hideDialButton } = useDialButton(false)

    const handleKeyPress = (evt: React.KeyboardEvent) => {
        evt.key === 'Enter' && handleOnClick()
    }

    const clickableClassName = classnames({
        [styles.clickable]: !hasNoValuesForFullProfile,
    })

    return (
        <>
            <div
                onMouseOver={showDialButton}
                onFocus={showDialButton}
                onMouseLeave={hideDialButton}
                className={clickableClassName}
            >
                <ShortCard
                    {...contact}
                    isFullProfileExpanded={isExpanded}
                    onClick={handleOnClick}
                    onKeyPress={handleKeyPress}
                    isDialButtonShown={isDialButtonShown}
                />
            </div>
            <div style={{ height: getHeight() }} className={expandableAreaClassName}>
                <div aria-hidden={!isExpanded} ref={element}>
                    <FullProfileInfo contactData={fullProfileData} />
                </div>
            </div>

            {!hasNoValuesForFullProfile && (
                <ClickableLineWithArrow isExpanded={isExpanded} onClick={handleOnClick} />
            )}
        </>
    )
}

export default ShortCardContainer

import { IChatQuickReply } from 'store/chat/chat.state'
import { isNilOrEmpty } from 'utils'
import QuickReplyItem from '../QuickReplyItem'
import styles from './PopupContent.module.scss'

interface Props {
    quickReplies?: {
        id: IChatQuickReply['id']
        text: IChatQuickReply['text']
        content?: IChatQuickReply['content']
        isCategory: boolean
    }[]
    onCategoryClick: (id: string | number) => void
    onReplyClick: (text: string, isProbablyJson: boolean) => void
    isSearchTriggered: boolean
}

const PopupContent = ({
    quickReplies,
    onCategoryClick,
    onReplyClick,
    isSearchTriggered,
}: Props) => {
    const handleReplyClick = (
        content: IChatQuickReply['content'],
        text: IChatQuickReply['text'],
    ) => {
        if (content) {
            return onReplyClick(content, true)
        }

        return onReplyClick(text, false)
    }

    const getEmptyResultText = () => {
        if (isSearchTriggered) {
            return 'No quick replies found'
        }

        return 'No quick replies configured'
    }

    return (
        <div className={styles.wrapper}>
            {isNilOrEmpty(quickReplies) ? (
                <p className={styles.noRepliesText}>{getEmptyResultText()}</p>
            ) : (
                <ul className={styles.list}>
                    {quickReplies?.map((quickReply, index) => {
                        const { text, content, id, isCategory } = quickReply

                        return (
                            <li className={styles.item} key={`${index}${text}`}>
                                <QuickReplyItem
                                    text={text}
                                    onClick={() =>
                                        isCategory
                                            ? onCategoryClick(id)
                                            : handleReplyClick(content, text)
                                    }
                                    isCategory={isCategory}
                                />
                            </li>
                        )
                    })}
                </ul>
            )}
        </div>
    )
}

export default PopupContent

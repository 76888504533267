export const formatQRWithJson = (quickReply: string): string => {
    let text
    try {
        const qr = JSON.parse(quickReply)
        const countryCode = Object.keys(qr)[0]
        const qrType = qr[countryCode]?.type
        if (qrType === 'CARD') {
            text =
                qr[countryCode]?.cards[0]?.title + ' ' + qr[countryCode]?.cards[0]?.buttons[0]?.url
        } else if (qrType === 'TEXT') {
            text = qr[countryCode]?.text
        }
    } catch (ex) {
        text = quickReply
    }
    return text
}

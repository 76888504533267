import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type NotificationState from 'store/notification/notification.state'

type State = NotificationState | null
const initialState = null as State

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        createNotification(state, action: PayloadAction<NotificationState>) {
            if (state) return state
            return action.payload ? { ...action.payload } : null
        },
        clearNotification() {
            return null
        },
    },
})

export const { createNotification, clearNotification } = notificationSlice.actions

export type NotificationAction = ReturnType<
    (typeof notificationSlice.actions)[keyof typeof notificationSlice.actions]
>

export default notificationSlice.reducer

import CallV2State, { CallConnection } from 'store/callV2/callV2.state'

export const selectAgentConnection = (callV2: CallV2State): CallConnection | undefined =>
    callV2.connections.find(({ type }) => type === 'AGENT')

export const selectCustomerConnection = (callV2: CallV2State): CallConnection | undefined =>
    callV2.connections.find(({ type }) => type === 'CUSTOMER')

export const selectYouConnection = (callV2: CallV2State): CallConnection | undefined =>
    callV2.connections.find(({ type }) => type === 'YOU')

export const selectConnections = (callV2: CallV2State) => {
    return callV2.connections
}

export const selectMonitorInfo = (callV2: CallV2State) => {
    return callV2.monitorInfo
}

export const selectConnectedConnections = (callV2: CallV2State) => {
    return callV2.connections.filter(
        (connection) =>
            connection.status.type !== connect.ConnectionStateType.CONNECTING &&
            connection.status.type !== connect.ConnectionStateType.DISCONNECTED,
    )
}

export const selectActiveConnections = (callV2: CallV2State) => {
    return callV2.connections.filter(
        ({ status }) => status.type !== connect.ConnectionStateType.DISCONNECTED,
    )
}

export const selectAreYouBeingBarged = (callV2: CallV2State) => {
    const youConnection = selectYouConnection(callV2)
    const hasBargedConnection = callV2.connections.some((connection) => connection.isBarge)
    return !!youConnection && !youConnection.isBarge && hasBargedConnection
}

import { Button, TriggeredPopup } from '@missionlabs/smartagent-app-components'
import Logout from 'components/Logout'
import React, { PureComponent, RefObject } from 'react'
import UserStatusItem from './userStatusItem'

interface Props {
    setUserState: (status: connect.AgentStateDefinition) => void
    logout: () => void
    changingState: string
    currentStatusName: string
    states: connect.AgentStateDefinition[]
    hideOfflineStatus?: boolean
    hideLogout?: boolean
}

export default class UserStatusOptions extends PureComponent<Props> {
    private logout: TriggeredPopup | null = null
    private stateList: RefObject<HTMLUListElement> | null | undefined = React.createRef()

    readonly state = {
        confirmLogout: false,
        focus: 0,
    }

    onClicked = (state: connect.AgentStateDefinition) => {
        const { setUserState, hideLogout } = this.props
        setUserState(state)
        if (!hideLogout) {
            this.logout!.closePopup()
        }
    }

    onOpenLogout = () => {
        this.setState({ confirmLogout: true })
        this.logout!.closePopup()
    }

    onCloseLogout = () => {
        this.logout!.closePopup()
    }

    onFocusChange = () => {
        const child = this.stateList?.current?.children[this.state.focus]
        child?.querySelector('button')?.focus()
    }

    onKeyDown = (evt: React.KeyboardEvent) => {
        const { focus } = this.state
        if (evt.key === 'ArrowUp') {
            if (focus === 0) return
            this.setState({ focus: focus - 1 }, this.onFocusChange)
        }

        if (evt.key === 'ArrowDown') {
            if (focus === this.stateList?.current?.children?.length! - 1) return
            this.setState({ focus: focus + 1 }, this.onFocusChange)
        }
    }

    render() {
        const { states, hideOfflineStatus, hideLogout } = this.props
        return (
            <ul ref={this.stateList} className="sa-status-list">
                {states.map((s) => {
                    if (s.name === 'Offline' && hideOfflineStatus) return null
                    return (
                        <UserStatusItem
                            isLoading={this.props.changingState === s.name}
                            key={s.name}
                            status={s}
                            onClicked={() => this.onClicked(s)}
                            onKeyDown={this.onKeyDown}
                            currentStatusName={this.props.currentStatusName}
                        />
                    )
                })}
                {hideLogout ? null : (
                    <TriggeredPopup
                        ref={(r) => (this.logout = r)}
                        noShadow={false}
                        center
                        trigger={
                            <li className="row between middle xsm-pad-top last">
                                <Button className="row stretch middle" onClick={this.props.logout}>
                                    Logout
                                </Button>
                            </li>
                        }
                    >
                        {this.state.confirmLogout ? (
                            <Logout logout={this.props.logout} cancel={this.onCloseLogout} />
                        ) : null}
                    </TriggeredPopup>
                )}
            </ul>
        )
    }
}

import { useEffect } from 'react'

import useAppConfig from 'hooks/redux/useAppConfig'
import useHasFeature, { AppFeatures } from 'hooks/useHasFeature'
import useInterval from 'hooks/useInterval'
import { useDispatch } from 'react-redux'
import { fetchAgentStats } from 'store/user/user.actions'
import useMetrics from './useMetrics'
import useUser from './useUser'

export default function useStats() {
    const { agentContactStatsByQueue } = useUser() ?? {}
    const appConfig = useAppConfig()
    const pollTime = appConfig.agentStatsPollTimeSeconds ? appConfig.agentStatsPollTimeSeconds : 60
    const {
        filters: { timeRange },
    } = useMetrics()
    const hasFeature = useHasFeature()
    const rtmAgentsRedesigned = hasFeature(AppFeatures.REALTIME_DATA_AGENTS_REDESIGNED)

    const dispatch = useDispatch()

    useInterval(() => dispatch(fetchAgentStats()), 1000 * pollTime, true)

    useEffect(() => {
        if (rtmAgentsRedesigned) {
            dispatch(fetchAgentStats())
        }
    }, [timeRange, fetchAgentStats, dispatch])

    return {
        agentContactStatsByQueue,
    }
}

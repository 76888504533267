import { Tag, Tooltip } from '@missionlabs/smartagent-app-components'
import { SATableColumn } from '@missionlabs/smartagent-app-components/dist/Table'
import { Props as TagProps } from '@missionlabs/smartagent-app-components/dist/Tag'

import { IMetricColumn, IMetricsConfig, MetricFormat } from 'store/metrics/metrics.state'
import { Metric } from 'store/user/user.state'
import { secondsToTime } from 'utils'

type TagType = TagProps['type']

const formatMetricValue = (value: number | null, format: MetricFormat) => {
    if (value === undefined || value === null || Number.isNaN(value)) {
        return 'N/A'
    }

    switch (format) {
        case MetricFormat.SECONDS:
            return secondsToTime(value)
        case MetricFormat.PERCENTAGE:
            return `${Math.round(value * 10) / 10}%`
        case MetricFormat.COUNT:
        default:
            return Math.round(value)
    }
}

const getQueueColsFromConfig = (metricsConfig: IMetricsConfig, isRTMPage: Boolean) => {
    const isColumnVisible = (column: IMetricColumn, isRTMPage: Boolean) =>
        column &&
        column.ID &&
        column.clientConfig &&
        column.clientConfig.name &&
        column.clientConfig.format &&
        column.clientConfig.page &&
        column.clientConfig.page.includes(isRTMPage ? 'RTM' : 'PersonalWidget')

    const configuredColumns = metricsConfig.columns
        ?.filter((column) => isColumnVisible(column, isRTMPage))
        .reduce((acc, column) => {
            const { ID, clientConfig } = column
            const { name, format, tooltip, sort, isHighlighted } = clientConfig

            const col = {
                name,
                value: ID,
                sortField: `${ID}.Value`,
                sort: sort !== undefined ? sort : true,
                tooltip,
                isDate: format === MetricFormat.SECONDS,
                className: format === MetricFormat.SECONDS ? 'time' : '',
                render: (metric: Metric) => {
                    const status = metric.Status
                    const value = metric.Value

                    const formattedValue = formatMetricValue(value, format)

                    if (isHighlighted) {
                        const statusColors: { [key: string]: TagType } = {
                            ontarget: 'green',
                            alert: 'red',
                            warning: 'orange',
                            none: 'white',
                            default: 'white',
                        }

                        return (
                            <Tag small type={statusColors[status ?? 'default']}>
                                {formattedValue}
                            </Tag>
                        )
                    }

                    return <span className={status ?? ''}>{formattedValue}</span>
                },
            }
            acc.push(col)

            return acc
        }, [] as SATableColumn[])

    if (!configuredColumns || !configuredColumns.length) {
        return []
    }

    return [
        {
            name: 'Queue',
            value: 'queueName',
            sort: true,
            className: 'queue-name',
            render: (queueName: string) => {
                return (
                    <Tooltip text={queueName}>
                        <span className="clipped-field">{queueName}</span>
                    </Tooltip>
                )
            },
        },
        ...configuredColumns,
    ] as SATableColumn[]
}

export { formatMetricValue, getQueueColsFromConfig }

import { formatPhoneNumber } from 'utils'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import CallState, {
    CallbackCallAction,
    ConnectionId,
    IncomingCallAction,
    MakeCallAction,
    OutboundCallAction,
    SendDTMFAction,
    SetHoldAction,
    UpdateCallStatusAction,
    UpdateConnectionsAction,
} from './call.state'
import { createConnection, getMonitorInfo } from './call.utils'

type State = CallState | null
const initialState = null as State

export const callSlice = createSlice({
    name: 'call',
    initialState,
    reducers: {
        incomingCall(_state, action: PayloadAction<IncomingCallAction>) {
            return {
                incoming: true,
                connections: [
                    {
                        id: action.payload.id,
                        number: action.payload.number,
                        activeConnection: true,
                        initialConnection: true,
                        status: 'INCOMING',
                    },
                ],
                direction: action.payload.direction,
                number: action.payload.number,
                monitorInfo: {
                    status: undefined,
                },
            }
        },
        updateConnections(state, action: PayloadAction<UpdateConnectionsAction>) {
            if (!state) return null

            const { number } = state
            const { connections } = action.payload

            return {
                ...state,
                connections: connections.map((connection) => createConnection(connection, number)),
            }
        },
        initiateLegacyMonitoring(state, action: PayloadAction<connect.Contact>) {
            const contact = action.payload

            const initialConnection = contact.getInitialConnection() as connect.VoiceConnection
            const agentConnection = contact.getAgentConnection() as connect.VoiceConnection
            const activeInitialConnection =
                contact.getActiveInitialConnection() as connect.VoiceConnection

            return {
                ...state!,
                monitorInfo: getMonitorInfo(agentConnection),
                inCall: true,
                connections: [
                    {
                        id: contact.contactId,
                        contact: contact,
                        activeConnection: true,
                        initialConnection: true,
                        number: activeInitialConnection?.getEndpoint().phoneNumber,
                        status: 'CONNECTED',
                        start: new Date(),
                    },
                ],
                direction: contact.isInbound() ? 'inbound' : 'outbound',
                number: 'Monitoring',
            }
        },
        callbackCall(_state, action: PayloadAction<CallbackCallAction>) {
            return {
                callback: true,
                incoming: true,
                connections: [
                    {
                        id: action.payload.id,
                        number: action.payload.number,
                        activeConnection: true,
                        initialConnection: true,
                        status: 'CALLBACK',
                    },
                ],
                direction: 'callback',
                number: action.payload.number,
            }
        },
        acceptCall(state) {
            return { ...state!, incoming: false }
        },
        connectionStarted(state, action: PayloadAction<ConnectionId>) {
            return {
                ...state!,
                incoming: false,
                connections: state
                    ? state.connections.map((c) =>
                          c.id === action.payload
                              ? { ...c, status: 'CONNECTED', start: c.start || new Date() }
                              : c,
                      )
                    : [],
                inCall: true,
                start: new Date(),
            }
        },
        connectionConnecting(state, action: PayloadAction<ConnectionId>) {
            //If we have a quick hangup this action still fires after the call has finished
            if (!state) return state
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.id === action.payload ? { ...c, status: 'CONNECTING' } : c,
                ),
            }
        },
        updateCallStatus(state, action: PayloadAction<UpdateCallStatusAction>) {
            return {
                ...state!,
                connections: state!.connections.map((c) =>
                    c.id === action.payload.connectionId
                        ? { ...c, status: action.payload.status }
                        : c,
                ),
            }
        },
        outboundCall(state, action: PayloadAction<OutboundCallAction>) {
            return {
                ...state!,
                connections: [
                    {
                        id: action.payload.id,
                        number: formatPhoneNumber(action.payload.number),
                        originNumber: action.payload.number,
                        status: 'RINGING',
                        activeConnection: true,
                        initialConnection: true,
                    },
                ],
            }
        },
        makeCall(state, action: PayloadAction<MakeCallAction>) {
            return {
                ...state!,
                number: action.payload.number!,
                direction: 'outbound',
                // eslint-disable-next-line
                connections: (state && state.connections) || [],
                outbound: true,
            }
        },
        setHold(state, action: PayloadAction<SetHoldAction>) {
            if (!state) return null
            const { connectionId, hold } = action.payload
            return {
                ...state!,
                connections: state!.connections.map((c) =>
                    c.id === connectionId ? { ...c, hold } : c,
                ),
            }
        },
        addConnection(state, action: PayloadAction<MakeCallAction>) {
            return {
                ...state!,
                connections: [
                    ...state!.connections.map((c) => {
                        return { ...c, activeConnection: false }
                    }),
                    {
                        ...action.payload,
                        status: 'RINGING',
                        activeConnection: true,
                        originNumber: action.payload.number!,
                    },
                ],
            }
        },
        toggleConnections(state) {
            if (!state || !state.connections.length) return state
            return {
                ...state,
                connections: state.connections.map((c) =>
                    c.activeConnection
                        ? { ...c, activeConnection: false, hold: true }
                        : { ...c, activeConnection: true, hold: false },
                ),
            }
        },
        endConnection(state, action: PayloadAction<ConnectionId>) {
            if (!state || !state.connections) return state
            //If call hasn't started then just clear
            if (!state.start) return null
            return {
                ...state!,
                conferenced: false,
                connections: state!.connections
                    .filter((c) => c.id !== action.payload)
                    .map((c) => ({ ...c, activeConnection: true })),
            }
        },
        conferenceConnections(state) {
            return {
                ...state!,
                connections: state!.connections.map((c) => ({
                    ...c,
                    activeConnection: true,
                    hold: false,
                })),
                conferenced: true,
            }
        },
        recoverCall(_state, action: PayloadAction<CallState>) {
            return action.payload
        },
        mute(state) {
            if (!state) return state
            return { ...state, muted: true }
        },
        unmute(state) {
            if (!state) return state
            return { ...state!, muted: false }
        },
        leaveCall() {},
        rejectCall() {},
        missedCall() {},
        callStarted() {},
        swapConnections() {},
        setSelectedCall() {},
        completeCallACW() {},
        hold(_, _action: PayloadAction<ConnectionId>) {},
        resume(_, _action: PayloadAction<ConnectionId>) {},
        transferCall(_, _action: PayloadAction<MakeCallAction>) {},
        sendDTMF(_, _action: PayloadAction<SendDTMFAction>) {},
        endCall() {},
        callEnded() {
            return null
        },
        parkCallContactTransfer(state) {
            return {
                ...state!,
                parking: true,
            }
        },
    },
})

export const {
    incomingCall,
    updateConnections,
    initiateLegacyMonitoring,
    callbackCall,
    acceptCall,
    connectionStarted,
    connectionConnecting,
    updateCallStatus,
    outboundCall,
    makeCall,
    setSelectedCall,
    setHold,
    resume,
    swapConnections,
    sendDTMF,
    addConnection,
    toggleConnections,
    endConnection,
    conferenceConnections,
    recoverCall,
    leaveCall,
    mute,
    unmute,
    hold,
    rejectCall,
    callStarted,
    completeCallACW,
    missedCall,
    transferCall,
    endCall,
    callEnded,
    parkCallContactTransfer,
} = callSlice.actions

export type CallAction = ReturnType<(typeof callSlice.actions)[keyof typeof callSlice.actions]>

export default callSlice.reducer

import { AxiosResponse } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import RootState from 'store/state'

type RequestError =
    | undefined
    | {
          data: {
              error?: string
              type?: string
              details?: {
                  missingFields?: string
                  format?: string
              }
          }
          status: number
      }

export default function useSmartAgentAPI<
    T,
    RS = RootState,
    ReturnT = AxiosResponse<T>,
    Args extends any[] = any[],
>(
    fn: (state: RS, ...args: Args) => Promise<ReturnT>,
    initialRequest: boolean = true,
    callback: (data?: ReturnT) => void = () => {},
) {
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<RequestError | undefined>(undefined)
    const [response, setResponse] = useState<ReturnT | undefined>(undefined)

    const state = useSelector<RS, RS>((state) => state)

    const getData = useCallback(
        async (...args: Args) => {
            setLoading(true)

            try {
                const response = await fn(state, ...args)
                setResponse(response)
                setError(undefined)
                callback(response)
            } catch (err: any) {
                setError(err.response)
                setResponse(undefined)
            } finally {
                setLoading(false)
                callback(response)
            }
        },
        // eslint-disable-next-line
        [fn],
    )

    useEffect(() => {
        if (initialRequest) (getData as () => Promise<void>)()
        // eslint-disable-next-line
    }, [])

    const clear = () => {
        setResponse(undefined)
        setError(undefined)
        setLoading(false)
    }

    return [error, loading, response, getData, clear] as [
        RequestError,
        boolean,
        ReturnT | undefined,
        (...args: Args) => void,
        () => void,
    ]
}

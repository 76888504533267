import { IInteraction } from 'views/Metrics/HistoricMetrics/types'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import IContactHistoryState, {
    AddAutomatedRulesAuditAction,
    AddContactHistoryAction,
    AddInteractionTranscriptAction,
    AddQueuedTasksAuditAction,
    AddTransformedAttachmentsAction,
    AttributeSearch,
    SelectInteractionsAction,
    SetInteractionsAction,
    SetViewPreviousEmailMessagesAction,
    Sort,
} from './contactHistory.state'
import { sortContactHistoryInteractions } from './contactHistory.utils'

const initialState: IContactHistoryState[] = []

export const contactHistorySlice = createSlice({
    name: 'contactHistory',
    initialState,
    reducers: {
        addContactHistory(state, action: PayloadAction<AddContactHistoryAction>) {
            const { attributeSearchName, attributeSearchValue } = action.payload

            const hasContactHistory = state.some(
                (item) =>
                    item.attributeSearchName === attributeSearchName &&
                    item.attributeSearchValue === attributeSearchValue,
            )
            const updatedState = hasContactHistory
                ? state
                : [...state, { ...action.payload, loaded: false }]

            return updatedState.map((item) => {
                const {
                    attributeSearchName: itemAttributeSearchName,
                    attributeSearchValue: itemAttributeSearchValue,
                } = item

                return {
                    ...item,
                    isSelected:
                        itemAttributeSearchName === attributeSearchName &&
                        itemAttributeSearchValue === attributeSearchValue,
                }
            })
        },
        removeContactHistory(state, action: PayloadAction<AttributeSearch>) {
            const { attributeSearchName, attributeSearchValue } = action.payload
            return state.filter(
                (item) =>
                    item.attributeSearchName !== attributeSearchName &&
                    item.attributeSearchValue !== attributeSearchValue,
            )
        },
        setInteractions(state, action: PayloadAction<SetInteractionsAction>) {
            const { attributeSearchName, attributeSearchValue, interactions } = action.payload

            return state.map((item) =>
                item.attributeSearchName === attributeSearchName &&
                item.attributeSearchValue === attributeSearchValue
                    ? { ...item, interactions: interactions as IInteraction[], loaded: true }
                    : item,
            )
        },
        selectInteraction(state, action: PayloadAction<SelectInteractionsAction>) {
            const { selectedInteraction, attributeSearchName, attributeSearchValue } =
                action.payload
            return state.map((item) =>
                item.attributeSearchName === attributeSearchName &&
                item.attributeSearchValue === attributeSearchValue
                    ? { ...item, selectedInteraction }
                    : item,
            )
        },
        setSelectedInstance(state, action: PayloadAction<AttributeSearch>) {
            const { attributeSearchName, attributeSearchValue } = action.payload

            return state.map((item) => {
                const {
                    attributeSearchName: itemAttributeSearchName,
                    attributeSearchValue: itemAttributeSearchValue,
                } = item

                return {
                    ...item,
                    isSelected:
                        itemAttributeSearchName === attributeSearchName &&
                        itemAttributeSearchValue === attributeSearchValue,
                }
            })
        },
        deselectContactHistoryInstance(state, action: PayloadAction<AttributeSearch>) {
            const { attributeSearchName, attributeSearchValue } = action.payload

            return state.map((item) => {
                const {
                    attributeSearchName: itemAttributeSearchName,
                    attributeSearchValue: itemAttributeSearchValue,
                } = item

                if (
                    itemAttributeSearchName === attributeSearchName &&
                    itemAttributeSearchValue === attributeSearchValue
                )
                    return { ...item, isSelected: false }

                return item
            })
        },
        addInteractionTranscript(state, action: PayloadAction<AddInteractionTranscriptAction>) {
            const { interactionId, transcript, attributeSearchName, attributeSearchValue } =
                action.payload
            return state.map((item) => {
                const {
                    interactions,
                    selectedInteraction,
                    attributeSearchName: itemCustomAttributeName,
                    attributeSearchValue: itemCustomAttributeValue,
                } = item

                if (
                    itemCustomAttributeName !== attributeSearchName ||
                    itemCustomAttributeValue !== attributeSearchValue
                )
                    return item

                const updatedInteractions = interactions.map((int) =>
                    int.ID === interactionId ? { ...int, transcript } : int,
                )
                const updatedSelected =
                    selectedInteraction?.ID === interactionId
                        ? { ...selectedInteraction, transcript }
                        : selectedInteraction

                return {
                    ...item,
                    interactions: updatedInteractions,
                    selectedInteraction: updatedSelected,
                }
            })
        },
        addAutomatedRulesAudit(state, action: PayloadAction<AddAutomatedRulesAuditAction>) {
            const {
                interactionId,
                automatedRulesAudit,
                attributeSearchName,
                attributeSearchValue,
            } = action.payload
            return state.map((item) => {
                const {
                    interactions,
                    selectedInteraction,
                    attributeSearchName: itemCustomAttributeName,
                    attributeSearchValue: itemCustomAttributeValue,
                } = item

                if (
                    itemCustomAttributeName !== attributeSearchName ||
                    itemCustomAttributeValue !== attributeSearchValue
                )
                    return item

                const updatedInteractions = interactions.map((int) =>
                    int.ID === interactionId ? { ...int, automatedRulesAudit } : int,
                )
                const updatedSelected =
                    selectedInteraction?.ID === interactionId
                        ? { ...selectedInteraction, automatedRulesAudit }
                        : selectedInteraction

                return {
                    ...item,
                    interactions: updatedInteractions,
                    selectedInteraction: updatedSelected,
                }
            })
        },
        addQueuedTasksAudit(state, action: PayloadAction<AddQueuedTasksAuditAction>) {
            const { interactionId, queuedTasksAudit, attributeSearchName, attributeSearchValue } =
                action.payload
            return state.map((item) => {
                const {
                    interactions,
                    selectedInteraction,
                    attributeSearchName: itemCustomAttributeName,
                    attributeSearchValue: itemCustomAttributeValue,
                } = item

                if (
                    itemCustomAttributeName !== attributeSearchName ||
                    itemCustomAttributeValue !== attributeSearchValue
                )
                    return item

                const updatedInteractions = interactions.map((int) =>
                    int.ID === interactionId ? { ...int, queuedTasksAudit } : int,
                )
                const updatedSelected =
                    selectedInteraction?.ID === interactionId
                        ? { ...selectedInteraction, queuedTasksAudit }
                        : selectedInteraction

                return {
                    ...item,
                    interactions: updatedInteractions,
                    selectedInteraction: updatedSelected,
                }
            })
        },
        sortInteractions(state, action: PayloadAction<Sort>) {
            const sort = action.payload
            return state.map((item) => {
                if (item.isSelected) {
                    const sortedInteractions = sortContactHistoryInteractions(
                        item.interactions,
                        sort,
                    )
                    return { ...item, interactions: [...sortedInteractions], sort }
                }

                return item
            })
        },
        setViewPreviousEmailMessages(
            state,
            action: PayloadAction<SetViewPreviousEmailMessagesAction>,
        ) {
            const { messageId, value } = action.payload

            return state.map((item) => {
                const { interactions, selectedInteraction } = item

                if (item.isSelected) {
                    const updatedInteractions = interactions.map((int) =>
                        int.messageId === messageId
                            ? { ...int, viewPreviousEmailMessages: value }
                            : int,
                    )
                    const updatedSelected =
                        selectedInteraction?.messageId === messageId
                            ? { ...selectedInteraction, viewPreviousEmailMessages: value }
                            : selectedInteraction
                    return {
                        ...item,
                        interactions: updatedInteractions,
                        selectedInteraction: updatedSelected,
                    }
                }
                return item
            })
        },
        addTransformedAttachments(state, action: PayloadAction<AddTransformedAttachmentsAction>) {
            const { messageId, transformedAttachments, attributeSearchName, attributeSearchValue } =
                action.payload

            return state.map((item) => {
                const {
                    interactions,
                    selectedInteraction,
                    attributeSearchName: itemCustomAttributeName,
                    attributeSearchValue: itemCustomAttributeValue,
                } = item

                if (
                    itemCustomAttributeName !== attributeSearchName ||
                    itemCustomAttributeValue !== attributeSearchValue
                )
                    return item

                const updatedInteractions = interactions.map((int) =>
                    int.messageId === messageId
                        ? {
                              ...int,
                              emailMessages: int.emailMessages?.map((emailMessage) =>
                                  emailMessage.messageId === messageId
                                      ? { ...emailMessage, attachments: transformedAttachments }
                                      : emailMessage,
                              ),
                          }
                        : int,
                )
                const updatedSelected = selectedInteraction
                    ? {
                          ...selectedInteraction,
                          emailMessages: selectedInteraction.emailMessages?.map((emailMessage) =>
                              emailMessage.messageId === messageId
                                  ? { ...emailMessage, attachments: transformedAttachments }
                                  : emailMessage,
                          ),
                      }
                    : selectedInteraction
                return {
                    ...item,
                    interactions: updatedInteractions,
                    selectedInteraction: updatedSelected,
                }
            })
        },
    },
})

export const {
    addContactHistory,
    removeContactHistory,
    setInteractions,
    selectInteraction,
    setSelectedInstance,
    deselectContactHistoryInstance,
    addInteractionTranscript,
    addAutomatedRulesAudit,
    addQueuedTasksAudit,
    sortInteractions,
    setViewPreviousEmailMessages,
    addTransformedAttachments,
} = contactHistorySlice.actions

export type ContactHistoryAction = ReturnType<
    (typeof contactHistorySlice.actions)[keyof typeof contactHistorySlice.actions]
>

export default contactHistorySlice.reducer

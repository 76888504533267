import useCall from 'hooks/redux/useCall'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { startParkCall } from 'store/call/call.actions'

export default function useParkCall() {
    const parking = useCall()?.parking ?? false

    const dispatch = useDispatch()

    const park = useCallback(() => {
        dispatch(startParkCall())
    }, [dispatch])

    return {
        park,
        parking,
    }
}

import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { completeCallACW } from 'store/call/call.actions'
import RootState from 'store/state'
import AfterCallWorkView from './afterCallWork'
import './afterCallWork.scss'

interface Props extends ReturnType<typeof mapStateToProps> {
    completeCallACW: () => void
}

class AfterCallWork extends PureComponent<Props> {
    readonly state = {
        start: new Date(),
    }
    componentDidCatch(ex: Error) {
        console.log('ACW caught exception:', ex)
    }
    render() {
        const { start } = this.state
        const { appConfig, completeCallACW, appConfigurations } = this.props
        return (
            <AfterCallWorkView
                start={start}
                appConfig={appConfig}
                appConfigurations={appConfigurations}
                onTimeout={() => completeCallACW()}
            />
        )
    }
}

function mapStateToProps(state: RootState) {
    const {
        app,
        settings: { settings },
    } = state
    return {
        appConfig: app.appConfig,
        appConfigurations: settings?.appConfigurations || {},
    }
}

export default connect(mapStateToProps, { completeCallACW })(AfterCallWork)

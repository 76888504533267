import useChatConnections from 'hooks/redux/useChatConnections'
import React from 'react'
import ChatMessages from '../ChatMessages'
import { useUnreadMessages } from '../ChatMessages/useUnreadMessages'

interface Props {}

const ActiveChatMessageView: React.FC<Props> = () => {
    const { messages, isTyping, unread, id } = useChatConnections('selected')!

    const { messagesContainer } = useUnreadMessages(id, messages, isTyping)

    return (
        <ChatMessages
            ref={messagesContainer}
            messages={messages ?? []}
            unread={unread}
            isTyping={isTyping}
        />
    )
}

export default ActiveChatMessageView

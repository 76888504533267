import { Button } from '@missionlabs/smartagent-app-components'
import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'
import styles from './ContactItem.module.scss'

interface Props extends PropsWithChildren {
    selected?: boolean
    index: number
    onSelect: () => void
    disabled?: boolean
    acw?: boolean
    CloseButton?: () => React.ReactNode
    isMissed: boolean
    bodyClassName?: string
    className?: string
}

const ContactItem: React.FC<Props> = ({
    onSelect,
    selected,
    index,
    disabled,
    acw,
    children,
    CloseButton,
    isMissed,
    className = '',
    bodyClassName = '',
}) => {
    const buttonClasses = classNames(className, {
        [styles.wrapper]: true,
        [styles.selected]: selected,
        [styles.acw]: acw,
        [styles.missedContact]: isMissed,
    })

    const onSelectItem = () => {
        if (disabled) return
        onSelect()
    }

    const bodyStyles = classNames(bodyClassName, styles.body, {
        [styles[`body--selected`]]: selected,
        [styles[`body--acw`]]: acw,
        [styles.disableClick]: disabled,
    })

    return (
        <Button disabled={disabled} className={buttonClasses} onClick={onSelectItem}>
            {!isMissed && (
                <div className={`${styles.label} row auto`}>
                    <span>{index}</span>
                </div>
            )}

            <div className={`${bodyStyles}`}>{children}</div>

            {isMissed && CloseButton && CloseButton()}
        </Button>
    )
}

export default ContactItem
